/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import React, { useState } from 'react';
import { validateEmail } from 'Component/Shared/utils';
import LoaderSpin from 'Component/Shared/Loader';
import { useSelector } from 'react-redux';
import { InputFeilds } from './InputBox';
import {
  ButtonPrimary,
  ButtonSecondary,
  FeildsWrapper,
  ShadowContainer,
} from '../styles';
import { addTeamMember } from '../TeamMemberLogic';

import RadioButtonsGroup from './FormGroup';

function AddTeamMember() {
  const { plan } = useSelector((state) => state?.user);

  const [member, setMember] = useState({
    name: '',
    email: '',
    scope: 'agency_client',
  });

  const [error, setErrorMessage] = useState({
    isActive: false,
    message: '',
  });
  const [success, setSuccessMessage] = useState({
    isActive: false,
    message: '',
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e, name) => {
    handleResetMessages();
    setMember({ ...member, [name]: e.target.value });
  };

  const isEmailValid = (email) => {
    if (validateEmail(member.email)) return true;

    setErrorMessage({
      isActive: true,
      message: 'Enter Valid Email',
    });
    setTimeout(() => {
      handleResetMessages();
    }, 3000);
    return false;
  };

  const handleResetMessages = () => {
    setErrorMessage({
      isActive: false,
      message: '',
    });
    setSuccessMessage({
      isActive: false,
      message: '',
    });
  };

  const handleResetFeilds = () => {
    setMember({
      name: '',
      email: '',
      scope: 'agency_client',
    });
    handleResetMessages();
  };

  const handleFailuire = (message) => {
    console.log(message);
    setErrorMessage({
      isActive: true,
      message: message || 'Something Went Wrong',
    });
    setTimeout(() => {
      handleResetMessages();
    }, 10000);
  };

  const handleAddTeamMember = () => {
    if (!isEmailValid(member.email)) return;
    setIsLoading(true);
    const { name, email, scope } = member;
    let _scope = null;

    if (plan === 'CrawlQ Agency Plan (Yearly)' || plan === 'CrawlQ Agency Plan (Monthly)') {
      _scope = scope;
    }

    addTeamMember(name, email, _scope)
      .then((data) => {
        console.log(data);
        if (data.error) {
          handleFailuire(data.message);
          return;
        }
        setSuccessMessage({
          isActive: true,
          message: 'Added User Successfully',
        });
      })
      .catch((data) => {
        console.log(data);

        handleFailuire(data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleScopeChange = (value) => {
    setMember({ ...member, scope: value });
  };

  return (
    <ShadowContainer flex="1.5">
      <FeildsWrapper>
        <InputFeilds
          name="Name"
          value={member.name}
          onChange={(e) => handleChange(e, 'name')}
        />
        {(plan === 'CrawlQ Agency Plan (Yearly)'
          || plan === 'CrawlQ Agency Plan (Monthly)') && (
          <RadioButtonsGroup
            value={member.scope}
            setValue={handleScopeChange}
          />
        )}
        <InputFeilds
          name="Email ID"
          value={member.email}
          onChange={(e) => handleChange(e, 'email')}
        />
      </FeildsWrapper>
      <FeildsWrapper>
        <ButtonSecondary onClick={handleResetFeilds}>
          Reset Fields
        </ButtonSecondary>
        <ButtonPrimary onClick={handleAddTeamMember}>
          Add Team Member
          {isLoading && <LoaderSpin width={25} height={25} color="#fff" />}
        </ButtonPrimary>
      </FeildsWrapper>
      <FeildsWrapper center>
        {error.isActive && (
          <small className="text-center text-danger">{error.message}</small>
        )}
        {success.isActive && (
          <small className="text-center text-success">{success.message}</small>
        )}
      </FeildsWrapper>
    </ShadowContainer>
  );
}

export default AddTeamMember;
