import React, { useState } from 'react';
import styles from '../style.module.css';

const DropdownOptions = ({ label, options, value, onChange }) => {
  const [otherValue, setOtherValue] = useState('');
  const [isInputActive, setIsInputActive] = useState(false);

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    onChange(selectedValue === 'Other' ? otherValue : selectedValue);
    setIsInputActive(selectedValue === 'Other');
  };

  const handleInputChange = (event) => {
    setOtherValue(event.target.value);
    onChange(event.target.value);
  };

  return (
    <div className={styles.dropdown}>
      <label>{label}</label>
      <select
        className={styles.select_box}
        value={value}
        onChange={handleSelectChange}
      >
        <option value="">Select an option</option>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
        <option value="Other">Other</option>
      </select>
      {isInputActive && (
        <input
          type="text"
          value={otherValue}
          onChange={handleInputChange}
          placeholder={label}
          className={styles.other_dropdown}
        />
      )}
    </div>
  );
};

export default DropdownOptions;
