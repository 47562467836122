/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */

import axios from 'axios';
// eslint-disable-next-line no-unused-vars
import React, { useContext, useState, useEffect } from 'react';

export const AiContent = React.createContext();

const EXCEPTION_HANDLE_DUMMY_DATA = {
  overview: {
    avg_headers: 0,
    avg_img: 0,
    avg_words: 0,
  },
  paragraphs: [],
  topics: [],
};

function AiContentProvider(props) {
  const [aiContent, setaiContent] = useState();

  const getAiContent = async () => {
    let aiContentdataAll = {};

    try {
      const body = {
        q: props.content,
        cc: props.lang,
      };
      const res = await axios.post(
        'https://apis.crawlq.ai/get-ai-content-sites',
        body,
      );
      aiContentdataAll = res.data.data;
      setaiContent({ ...aiContentdataAll });
    } catch (ex) {
      console.log('Error in ai-content-site', ex);
      setaiContent({ ...EXCEPTION_HANDLE_DUMMY_DATA });
    }
  };

  useEffect(() => {
    setaiContent({ ...EXCEPTION_HANDLE_DUMMY_DATA });
    if (!window.location.href.includes('/editor')) {
      getAiContent();
    }
  }, []);
  return (
    <AiContent.Provider value={{ aiContent, setaiContent }}>
      {props.children}
    </AiContent.Provider>
  );
}

export default AiContentProvider;
