export function formatFileSize(sizeInBytes) {
  const kiloByte = 1024;
  const megaByte = kiloByte * 1024;
  const gigaByte = megaByte * 1024;

  if (sizeInBytes < kiloByte) {
    return `${sizeInBytes} B`;
  }
  if (sizeInBytes < megaByte) {
    return `${(sizeInBytes / kiloByte).toFixed(2)} KB`;
  }
  if (sizeInBytes < gigaByte) {
    return `${(sizeInBytes / megaByte).toFixed(2)} MB`;
  }
  return `${(sizeInBytes / gigaByte).toFixed(2)} GB`;
}
