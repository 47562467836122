/* eslint-disable no-return-assign */
/* eslint-disable max-len */
import React, { useState, useEffect, useRef } from 'react';
import { ScaffoldCard } from 'components/common/scaffold-card';
import { Divider } from 'components/common/divider';
import {
  UpArrowIcon,
  DownArrowIcon,
  LoaderTailSpin,
} from 'components/common/index';
import { useGetMarketCategoryQuestion } from 'queries/market-research/get-market-category-questions';
import { Editor } from '@tinymce/tinymce-react';
import { useMutateMarketInsights } from 'queries/market-research/mutate-mrr-insight';
import { useMutateSaveMarketInsights } from 'queries/market-research/mutate-mrr-insight-save';

const AthenaLitsening = ({ categoryName, openInsights, openInsightsFn }) => {
  const editorRef = useRef(null);
  const {
    data: categoryData,
    isFetching: isLoading,
  } = useGetMarketCategoryQuestion(categoryName);

  const [Insights, setInsights] = useState(categoryData?.mrr_insight);
  useEffect(() => {
    const mrrInsights = categoryData?.mrr_insight;
    if (mrrInsights) {
      setInsights(mrrInsights);
    } else {
      setInsights('');
    }
  }, [categoryData]);

  const {
    mutateAsync: fetchMarketInsights,
    isLoading: isLoadingMarketInsights,
  } = useMutateMarketInsights();

  const { mutate: saveMarketInsight } = useMutateSaveMarketInsights();

  const handleGenerate = async (editor) => {
    editor.notificationManager.open({
      text: `Generating Your ${categoryName} insights.`,
      type: 'success',
      timeout: 5500,
    });

    await fetchMarketInsights({
      categoryName,
    }).then((data) => {
      // setInsights(Insights + data);
      setInsights(data);
    });

    saveMarketInsight({
      categoryName,
      insight: editor.getContent(),
    });
  };

  const handleSave = (editor) => {
    saveMarketInsight({
      categoryName,
      insight: editor.getContent(),
    });
    editor.notificationManager.open({
      text: `Saving Your ${categoryName} insights.`,
      type: 'success',
      timeout: 2000,
    });
  };

  const handleOnChangeEditor = (content) => {
    setInsights(content);
  };
  return (
    <ScaffoldCard
      className={openInsights ? 'h-margin-b-x2 market-research-info' : ''}
      style={{ flex: openInsights && 1 }}
    >
      <div className="market-research-info-header">
        <span className="market-research-info-header-text">
          Insights{' '}
          {isLoadingMarketInsights && (
            <LoaderTailSpin className={'h-margin-l-tiny'} width={20} />
          )}
        </span>

        <div className="market-research-info-right-header">
          {categoryName && (
            <span className="market-research-info-right-header-text">
              {categoryName.substring(0, 15)}...
            </span>
          )}
          {openInsights ? (
            <UpArrowIcon
              onClick={() => openInsightsFn((openInsights) => !openInsights)}
            />
          ) : (
            <DownArrowIcon
              onClick={() => openInsightsFn((openInsights) => !openInsights)}
            />
          )}
        </div>
      </div>
      {openInsights && <Divider />}
      {openInsights && !isLoading && (
        <div className="market-research-info-container">
          <div className="market-research-info-content-container">
            <Editor
              onInit={(event, editor) => (editorRef.current = editor)}
              ref={editorRef}
              selector="textarea"
              id="texteditor"
              state={Insights}
              content_css="./content-editor.css"
              init={{
                skin: 'borderless',
                height: '100%',
                width: '100%',
                menubar: false,
                icons: 'material',
                toolbar: 'saveButton generateButton advancedModeButton',
                content_style: `body { font-family:Montserrat; font-size:12px; color: white; }
                
                  h2 { color: white}`,

                setup(editor) {
                  editor.ui.registry.addButton('saveButton', {
                    icon: 'save',
                    tooltip: 'Save',
                    enabled: false,
                    onAction: () => handleSave(editor),
                  });

                  editor.ui.registry.addButton('generateButton', {
                    text: `${
                      isLoadingMarketInsights ? 'Generating' : 'Generate'
                    }`,
                    tooltip: 'Generate',
                    enabled: false,
                    onAction: () => handleGenerate(editor),
                  });
                },
              }}
              onEditorChange={handleOnChangeEditor}
              value={Insights}
            />
          </div>
        </div>
      )}
    </ScaffoldCard>
  );
};

export default AthenaLitsening;
