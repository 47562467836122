import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useMutateDeleteWorkspace } from 'queries/workspace/delete-workspace';
import { LoaderTailSpin } from 'components/common/Loader/Loader';
import { RemoveIcon } from '../../styles';
import './delete-action.css';

const DeleteAction = ({ workspace, toggleClose }) => {
  const {
    mutate,
    isLoading,
    isError,
    error,
    isSuccess,
  } = useMutateDeleteWorkspace();

  const handleDeleteClick = () => {
    mutate({
      name: workspace?.name,
    });

    toast.success(`${workspace?.name} deleted`, {
      position: 'bottom-center',
      theme: 'dark',
    });

    setTimeout(() => {
      toggleClose();
    }, 2000);
  };

  return (
    <div className="workspace-delete-main-container">
      <ToastContainer />
      <div className="workspace-delete-header-wrapper">
        <RemoveIcon />
        <div>{`Delete ${workspace?.name} Campaign ?`}</div>
      </div>

      <div className="workspace-delete-button-wrapper">
        <div className="workspace-delete-button-container delete-cancel">
          <button type="button" onClick={() => toggleClose()}>
            CANCEL
          </button>
        </div>

        <div className="workspace-delete-button-container delete-confirm">
          {isLoading ? (
            <LoaderTailSpin width={20} />
          ) : (
            <button type="button" onClick={handleDeleteClick}>
              CONFIRM
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default DeleteAction;
