/* eslint-disable no-prototype-builtins */
/* eslint-disable max-len */
import { ATTRACT_FIRST_CATEGORY } from 'constants/market-research/attract';
import React, { useState, useCallback } from 'react';
import { useGetMarketCategories } from 'queries/market-research/get-market-categories';
import { getModularizeMarketCategory } from 'queries/market-research/selectors';
import { MarketCategoriesLoading } from 'components/skeleton/market-research';
import { ScaffoldCard } from 'components/common/scaffold-card';
import { Divider } from 'components/common/divider';
import MarketQuestions from '../MarketQuestions';
import AthenaHelper from '../market-athena/AthenaHelper';
import AthenaLitsening from '../market-athena/AthenaLitsening/AthenaLitsening';
import { DoneIcon, ActiveCategoryIcon, IncompleteIcon } from '../styles';

const Attract = () => {
  const {
    data: marketCategories,
    isLoading: isGetMarketCategoriesLoading,
  } = useGetMarketCategories();
  const attractCategories =
    getModularizeMarketCategory(marketCategories, 0, 4) ?? [];

  const [SelectedCategory, setSelectedCategory] = useState(
    ATTRACT_FIRST_CATEGORY,
  );

  const [OpenInsights, setOpenInsights] = useState(false);

  const handleCategoryClick = useCallback(
    (category) => {
      setSelectedCategory(category);
      setOpenInsights(false);
    },
    [SelectedCategory],
  );

  return (
    <div className="market-research-wrapper">
      <ScaffoldCard className="market-research-left">
        {isGetMarketCategoriesLoading && <MarketCategoriesLoading />}

        {attractCategories &&
          attractCategories.map((attractCategory, index) => (
            <div
              className="market-categories-wrapper"
              key={attractCategory?.id}
            >
              {index > 0 && <Divider />}
              <div
                className="market-category-container"
                onClick={() => handleCategoryClick(attractCategory)}
              >
                <div className="market-category-selected">
                  {' '}
                  {SelectedCategory?.category === attractCategory?.category && (
                    <ActiveCategoryIcon />
                  )}
                </div>
                <div className="market-category-name">
                  {attractCategory?.category}
                </div>
                <div className="market-category-status">
                  {' '}
                  {attractCategory?.completed ? (
                    <DoneIcon />
                  ) : (
                    <IncompleteIcon />
                  )}
                </div>
              </div>
            </div>
          ))}
      </ScaffoldCard>

      <MarketQuestions
        categoryName={SelectedCategory?.category}
        IsMinAnsweredCheck
      />

      <aside className="market-research-aside">
        <AthenaHelper categoryName={SelectedCategory?.category} />

        <AthenaLitsening
          categoryName={SelectedCategory?.category}
          openInsights={OpenInsights}
          openInsightsFn={setOpenInsights}
        />
      </aside>
    </div>
  );
};

export default Attract;
