/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
import { PanelInfos } from 'constants/main-menu';
import React from 'react';
import './styles.css';
import AppHeaderWrapper from '../AppHeader/AppHeaderWrapper';

function MainMenu() {
  return (
    <AppHeaderWrapper className="main-menu-wrapper">
      <div className="main-menu-container">
        <div className="feature-list-container-wrapper">
          {PanelInfos.map((panelInfo) => (
            <div className="feature-list-container" key={panelInfo.panel_id}>
              <div className="feature-box-description-container">
                <div className="feature-box-description">
                  <div className="feature-box-description-header">
                    <panelInfo.panel_icon style={{ width: '22px', marginRight: '0.25rem' }} />
                    <span>{panelInfo?.panel_header}</span>
                  </div>
                  <span className="global-fs-secondary">{panelInfo?.panel_subheader}</span>
                </div>
              </div>
              <div className="feature-detail-container">
                <panelInfo.panel_component />
              </div>
            </div>
          ))}
        </div>
        <div>
          <h5>Learn</h5>
        </div>
      </div>
    </AppHeaderWrapper>
  );
}

export default MainMenu;
