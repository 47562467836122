import React from 'react';
import Switch from '@mui/material/Switch';
import { ChatAthenaModelNameEnum } from 'libs/chat-athena-constants';
import { useSelector } from 'react-redux';
import LoaderSpin from 'Component/Shared/Loader';
import CustomTooltip from 'Component/Shared/CustomTooltip';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import styles from './model-selector.module.css';

export const ImageModelSelector = ({
  icon,
  isProActivated,
  handleImageModelSelect,
  handleUpgrade,
  loadingId,
}) => {
  const user = useSelector((state) => state?.user);

  const getDalleToolTip = () => {
    if (user?.is_anonymous === true) {
      return 'Upgrade to CrawlQ paid plan first to subscribe to Dall-E 3';
    }

    return 'Upgrade to pro to use Dall-E 3';
  };

  if (!isProActivated) {
    return (
      <CustomTooltip title={getDalleToolTip()} arrow placement="top">
        {loadingId === ChatAthenaModelNameEnum.dalle ? (
          <div className={styles.model_option}>
            <LoaderSpin />
          </div>
        ) : (
          <div
            className={styles.model_option}
            onClick={() => (user.is_anonymous === true ? {} : handleUpgrade())}
          >
            <img style={{ height: '25px' }} src={icon} alt="img" />
            Dall-E-3
            <LockRoundedIcon />
          </div>
        )}
      </CustomTooltip>
    );
  }

  return (
    <div className={styles.img_model_selector}>
      <img style={{ height: '25px' }} src={icon} alt="DALL_E" />
      <Switch
        defaultChecked={false}
        onChange={(e, val) =>
          val
            ? handleImageModelSelect(ChatAthenaModelNameEnum.dalle)
            : handleImageModelSelect(null)
        }
      />
    </div>
  );
};
