/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import Topbar from 'Component/Shared/Topbar/Topbar';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import AiContentProvider from '../Content Automation/contexts/ai_content';
import EditorProvider from '../Content Automation/contexts/summary_Context';
import TextEditor from '../Content Automation/AIContentWriter/text_editor_component';

function AIContentWriter(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const [searchQuery, setsearchQuery] = useState(
    location && location.state.query,
  );
  const [lang, setlang] = useState(location.state && location.state.lang);
  const [flag, setFlag] = useState(location.state && location.state.flag);
  const [GoogleQuery, setGoogleQuery] = useState(location.state.GoogleQuery);
  const [generatedOutputs, setgeneratedOutputs] = useState(
    location.state && location.state.generatedOutputs,
  );
  const [save, setSave] = useState(location.state && location.state.save);

  return (
    <div className="App">
      <div className="app_body">
        <div
          style={{
            position: 'fixed',
            left: 0,
            top: 0,
            right: 0,
            zIndex: '11',
            background: '#fff',
          }}
        >
          <Topbar />
        </div>

        <AiContentProvider
          content={flag === 'sales' ? searchQuery : GoogleQuery}
          lang={lang}
        >
          <EditorProvider
            query={searchQuery}
            save={save}
            generatedText={generatedOutputs}
            lang={lang}
            flag={flag}
            regenerating={location.state.regenerating}
          >
            <div className="row modify-row mt-2">
              <div
                className="col"
                style={{ flex: 1.3, maxWidth: 'unset', paddingTop: '3rem' }}
              >
                <TextEditor
                  query={searchQuery}
                  topic={GoogleQuery}
                  blogId={location.state.blogId}
                />
              </div>
            </div>
          </EditorProvider>
        </AiContentProvider>
      </div>
    </div>
  );
}
export default AIContentWriter;
