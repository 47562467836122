import React from 'react';
import styles from './index.module.css';
import { LoadingPlaceholder } from '../LoadingPlaceholder';

export const MarketMessageArticleLoading = () => (
  <div className={styles.loading_wrapper}>
    {Array(4)
      .fill(null)
      .map((_, index) => (
        <div className={styles.loading_list} key={index}>
          <LoadingPlaceholder
            width="230px"
            height="120px"
            marginBottom="5px"
            borderRadius="0rem 0.4rem 0rem 0.2rem"
          />
          <LoadingPlaceholder
            width="200px"
            height="10px"
            marginBottom="4px"
            borderRadius="0.4rem"
            marginTop="4px"
            marginLeft="4px"
          />
          <LoadingPlaceholder
            width="180px"
            height="10px"
            marginBottom="80px"
            borderRadius="0.4rem"
            marginLeft="4px"
          />
          <LoadingPlaceholder
            width="150px"
            height="10px"
            borderRadius="0.4rem"
            marginLeft="4px"
          />
        </div>
      ))}
  </div>
);
