/* eslint-disable indent */
import React from 'react';
import { AnswerComponent } from './components/AnswerComponent/AnswerComponent';
import { QuestionComponent } from './QuestionComponent';
import { LikeDislikeComponent } from './LikeDislikeComponent';

const ChatBoxComponent = React.memo(
  ({
    index,
    answerId,
    isDarkMode,
    isMobile,
    element,
    selectedAnswer,
    selectAnswer,
  }) => (
    <div
      key={`${index}-pair-${element.question}-${element.answer}-${selectedAnswer.index}`}
      className="w-100"
    >
      <QuestionComponent
        isDarkMode={isDarkMode}
        isMobile={isMobile}
        element={element}
      />
      <AnswerComponent
        index={index}
        answerId={answerId}
        isDarkMode={isDarkMode}
        element={element}
        selectAnswer={selectAnswer}
      >
        <LikeDislikeComponent
          answerId={answerId}
          rating={element.rating_scale}
        />
      </AnswerComponent>
    </div>
  ),
);

export default ChatBoxComponent;
