import { baseAPIsUrl } from 'Config/Api';
import axios from 'axios';
import { useQuery } from 'react-query';

const fetchAllFiveTranApps = async () => {
  try {
    const { data } = await axios.get(`${baseAPIsUrl}/get-connectors-metadata`);
    if (data.statusCode === '200') return data.data;
    throw new Error('Error in fetching fivetran apps');
  } catch (ex) {
    console.log('Error in fetching fivetran apps', ex);
    throw ex;
  }
};

// Keep Query Value as File Name to maintain consistency and uniqueness in query index
const QUERY_KEY = 'get-all-fivetran-apps';

// Query Config Options
const queryOptions = {
  staleTime: 10 * 60 * 1000,
  // 10 mins
};

// QUERY
export const useGetAllFiveTranApps = () =>
  useQuery([QUERY_KEY], () => fetchAllFiveTranApps(), queryOptions);
