/* eslint-disable max-len */
import createInstance from 'Config/AxiosConfig';
import { useQuery } from 'react-query';

export const getMarketHelper = async (categoryName) => {
  const payload = {
    category_name: categoryName,
  };

  const instance = await createInstance();
  const { data, status } = await instance.post('athena-helper-text', payload);

  if (status === 200) {
    return data?.data;
  }

  throw new Error('Unable to fetch Athena Helper');
};

// Keep Query Value as File Name to maintain consistency and uniqueness in query index
const QUERY_KEY = 'get-market-helper';

// Query Config Options
const queryOptions = {
  staleTime: 90000,
};

// QUERY
export const useGetMarketHelper = (categoryName) =>
  useQuery([QUERY_KEY, categoryName], () => getMarketHelper(categoryName), queryOptions);
