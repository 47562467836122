export const CONVERT_CATEGORIES = [
  {
    id: 1,
    name: 'Current Environment and Trends',
  },
  {
    id: 2,
    name: 'Activities and UUIs',
  },
  {
    id: 3,
    name: 'Promise',
  },
  {
    id: 4,
    name: 'Case Study',
  },
  {
    id: 5,
    name: 'Benefits and Outcome',
  },
  {
    id: 6,
    name: 'How it Works and Features',
  },
  {
    id: 7,
    name: 'Offer and Call to Action',
  },
  {
    id: 8,
    name: 'Testimonials',
  },
  {
    id: 9,
    name: 'Offer and Bonus Stacking Together',
  },
  {
    id: 10,
    name: 'Core Concept',
  },
];
