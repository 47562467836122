import React, { useState } from 'react';
import Button, { TabSwitcheWrapper, TabContainer } from './styles';

function TabSwitcher({ tabs, setSwitcher, outerClass, activeTab = 0 }) {
  const [currentTab, setCurrentTab] = useState(activeTab);
  const handleTabSwitch = (tab) => {
    setCurrentTab(tab);

    if (setSwitcher) {
      setSwitcher(tab);
    }
  };

  return (
    <TabContainer className={outerClass}>
      <TabSwitcheWrapper>
        {tabs.map((tab, index) => (
          <Button
            key={`${tab}${index}}`}
            onClick={() => handleTabSwitch(index)}
            active={currentTab === index}
          >
            {tab}
          </Button>
        ))}
      </TabSwitcheWrapper>
    </TabContainer>
  );
}

export default TabSwitcher;
