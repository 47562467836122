import React, { useMemo } from 'react';
import { useContentContext } from 'context/content/ContentContext';
import { useGetTopicsAndParapgraphs } from 'queries/content/content-tools/get-topics-paragraphs';
import { getParagraphs, getOverview } from 'queries/content/content-tools/selectors';
import Overview from './overview/Overview';
import SerpHeaders from './serp-headers/SerpHeaders';
import styles from './serp.module.css';

function SERP() {
  const { country, topic } = useContentContext();

  const [Topic] = topic;
  const [Country] = country;

  const { data, isLoading } = useGetTopicsAndParapgraphs(Topic, Country);

  const paragraphList = useMemo(() => getParagraphs(data), [data]);

  const overviewInfo = useMemo(() => getOverview(data), [data]);

  if (isLoading) return (<>Loading</>);
  return (
    <div className={styles.wrapper}>
      <Overview
        overviewInfo={overviewInfo}
      />
      {paragraphList && paragraphList.map((serp) => (
        <div className={styles.container}>
          <SerpHeaders serp={serp} />
        </div>
      ))}
    </div>
  );
}

export default SERP;
