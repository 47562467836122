import { useMutation } from 'react-query';
import createInstance from 'Config/AxiosConfig';
import { toast } from 'react-toastify';

const WORKSPACE_EXIST_ERROR = 'Project Name already exists';

const shareWorkspace = async (payload) => {
  const instance = await createInstance();

  const response = await instance.post('copy-project', payload);

  if (response?.data?.body === WORKSPACE_EXIST_ERROR) {
    throw new Error('campaigns name exists');
  }

  return response;
};

export const useMutateShareWorkspace = () =>
  useMutation(shareWorkspace, {
    onError: (error) => {
      toast.error(`${error.message}`, {
        position: 'bottom-center',
        theme: 'dark',
      });
    },
  });
