/* eslint-disable consistent-return */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import OpenAI from 'openai-api';
import DropdownArrow from '../../../../assets/images/dropdown-arrow.svg';
import DropupArrow from '../../../../assets/images/dropup-arrow.svg';
import LoaderSpin from '../../../Shared/Loader';
import CreditUpdater from '../../../Shared/CreditManager';
import { getTranslatedTextEnglish } from '../../../Shared/TranshelperStatic';

export default function TabResults(props) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [gptResult, setGptResult] = useState([]);
  const [showInsights, setShowInsights] = useState(true);
  const [keyWord, setKeyWord] = useState(props.keyWord);
  const [questionArr, setQuestionArr] = useState([]);

  const handleExpand = (index) => {
    if (!isExpanded) {
      setIsExpanded([]);
    } else if (isExpanded.includes(index)) {
      return setIsExpanded((prev) => prev.filter((item) => item !== index));
    }

    setIsExpanded((prev) => [...prev, index]);
  };

  const IncreaseCredit = async () => {
    const { success } = await CreditUpdater(30);

    if (success === 'true') {
      return { credit_result: 'Pass' };
    }

    return { credit_result: 'Fail' };
  };

  const getInsights = async (keyword) => {
    const wordCount = 0;

    const { credit_result } = await IncreaseCredit();
    if (credit_result === 'Pass') {
      const translated_keyword = await getTranslatedTextEnglish(keyword);
      const OPEN_AI_API_KEY = 'sk-2pyUqUMnLB9pvXoVokG7KGlpQBRxXz9jcK9PkAP8';
      const openai = new OpenAI(OPEN_AI_API_KEY);

      const prompt1 = `Here is my main topic of interest ${translated_keyword}. I want to understand what questions and answers my target audience will have around their desires, problems, pain points, fears, suspicions, roadblocks, questions about this ${keyword}`;

      const q1 = `Q: What idea would my target audience have about ${translated_keyword}?`;

      const q2 = `Q: What benefits would my target audience have from ${translated_keyword}?`;

      const q3 = `Q: What suspicions would my target audience have about ${translated_keyword}?`;

      const q4 = `Q: What mistakes would my target audience incur about ${translated_keyword}?`;

      const q5 = `Q: What questions would my target audience have about ${translated_keyword}?`;

      const queArr = [q1, q2, q3, q4, q5];
      setQuestionArr([...queArr]);
      queArr.forEach(async (question) => {
        setIsLoading(true);
        try {
          const gptResponse = await openai.complete({
            engine: 'curie',
            prompt: `${prompt1}<br>${question}<br>A:`,
            maxTokens: 150,
            temperature: 0.9,
            frequency_penalty: 0.7,
            presence_penalty: 0.7,
            topP: 1,
            stop: ['\nQ:', '\n'],
          });

          // let response = gptResponse.data.choices[0].text
          // let plainText = response.replace(/<[^>]+>/g, '');
          // let matches = plainText.match(/\S+/g);
          // wordCount =  matches.length
          // wordCount = Math.floor(wordCount*0.9)

          setGptResult((prev) => {
            const res = [...prev, gptResponse.data.choices[0].text];
            return res;
          });
        } catch (ex) {
          console.log('Error in getting Questions From OpenAI : ', ex);
        }

        setIsLoading(false);
      });
    } else {
      console.log('Credit Over');
    }
  };

  const handleSeach = () => {
    getInsights(keyWord);
  };

  if (props.results === null) {
    return <></>;
  }

  const handleRedirect = (term_url) => {
    window.open(
      term_url,
      'mywindow',
      'menubar=1,resizable=1,width=950,height=700',
    );
  };

  function Insights() {
    return (
      <div className="spy-result-container">
        <div
          className="spy-flex-insights"
          onClick={() => {
            if (gptResult.length) setShowInsights((prev) => !prev);
          }}
        >
          <div>
            Insights
            {!!gptResult.length && (
              <img
                className="mx-2"
                height={18}
                src={!showInsights ? DropdownArrow : DropupArrow}
              />
            )}
          </div>
          <button className="spy-button mt-0" onClick={handleSeach}>
            {isLoading ? (
              <LoaderSpin height="20px" width="20px" />
            ) : (
              <div>
                <span className="font-normal">Unlock Insights</span>
              </div>
            )}
          </button>
        </div>
        {gptResult.length >= questionArr.length && showInsights && (
          <div className="qna">
            {questionArr.map((question, index) => (
              <div>
                <div className="answers">
                  <p className="q">{question}</p>
                  <p className="a">{ReactHtmlParser(gptResult[index])}</p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="spy-tabresults-container">
      {!!props.showUnlock && Insights()}
      {props.results
        ? props.results.map((result, index) => (
          <div className="spy-result-container">
            <div
              onClick={() => handleExpand(index)}
              className="spy-result-header"
            >
              <div className="spy-result-header-left">
                <img className="mr-2" src={result.src} width="25" />
                <p>{result.title}</p>
              </div>
              <div>
                <img
                  style={{ width: '15px' }}
                  src={
                    isExpanded && isExpanded.includes(index)
                      ? DropupArrow
                      : DropdownArrow
                  }
                />
              </div>
            </div>
            {isExpanded && isExpanded.includes(index) ? (
              <div className="spy-result-detail-container">
                <div className="spy-result-detail">
                  {result.results.map((res) => (
                    <a
                      href={res.url}
                      target="_blank"
                      className="spy-terms"
                      onClick={() => handleRedirect(res.url)}
                      rel="noreferrer"
                    >
                      {res.term}
                    </a>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        ))
        : null}
    </div>
  );
}
