import createInstance from 'Config/AxiosConfig';
import { store } from 'redux/store';
import { useMutation, useQueryClient } from 'react-query';
import { getWorkspaceQuery } from './get-users-all-workspaces';

export async function updateWorkspace(updateData) {
  const { username } = store.getState()?.user;

  try {
    const updatedWorkspace = {
      ...updateData,
      username,
    };

    const instance = await createInstance();
    const { data } = await instance.post('edit-project', updatedWorkspace);
    if (data.statusCode === '200') {
      return updatedWorkspace;
    }
    throw new Error('Failed to update workspace');
  } catch (error) {
    throw new Error('Failed to update workspace');
  }
}

export const useEditWorkspaceMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(updateWorkspace, {
    onSuccess: (updatedWorkspace) => {
      queryClient.setQueryData(getWorkspaceQuery, (prev) => {
        const data = prev.map((workspace) =>
          workspace.name === updatedWorkspace.name
            ? updatedWorkspace
            : workspace,
        );
        return data;
      });
    },
  });
};
