import 'assets/constants.css';
import styled, { css } from 'styled-components';

export const ScaffoldCard = styled.div`
    background-color: var(--card-background-color);
    border-radius: var(--card-border-radius);
    border: var(--card-border);
    padding: 0.5rem;
`;

export const ScaffoldFlexCard = styled.div`
 background-color: var(--card-background-color);
    border-radius: var(--card-border-radius);
    border: var(--card-border);
    padding: 0.5rem;

${(props) =>
    props?.flex &&
    css`
        display:flex;
        align-items: center;
    `}

`;
