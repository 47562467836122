/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable import/no-unresolved */
import axios from 'axios';
import { store } from 'redux/store';

const getLanguageEngine = () => {
  const state = store.getState();
  const { language_engine } = state.workspace;
  return language_engine;
};
const getLanguage = () => {
  const state = store.getState();
  const { language } = state.workspace;
  return language;
};

export default function reTranslateEntirePage() {
  const iframe = document.querySelector('#\\:1\\.container');
  if (iframe) {
    iframe.contentWindow.document.querySelector('#\\:1\\.restore').click();
    iframe.contentWindow.document.querySelector('#\\:1\\.confirm').click();
  }
}

export const getTranslatedText = async (text) => {
  const target_lang = getLanguage();
  if (target_lang === 'en') return text;
  try {
    if (text === '') return '';
    const body = {
      text,
      target_lang,
      engine: getLanguageEngine(),
    };
    const { data } = await axios.post(
      'https://apis.crawlq.ai/lang-translate',
      body,
    );

    return data.answer.trans_text[0];
  } catch (ex) {
    console.log('Error in translating in English ', ex);
    return text;
  }
};

export const getTranslatedTextForHTML = async (text) => {
  const target_lang = getLanguage();
  if (target_lang === 'en') return text;
  if (text === '') return '';
  const body = {
    text,
    type: 'html',
    target_lang,
    engine: getLanguageEngine(),
  };
  const { data } = await axios.post(
    'https://apis.crawlq.ai/lang-translate',
    body,
  );

  return data.answer.trans_text[0];
};

export const getTranslatedTextEnglish = async (text) => {
  const target_lang = getLanguage();
  if (target_lang === 'en') return text;
  try {
    if (text === '') return '';
    const body = {
      text,
      target_lang: 'en',
      engine: getLanguageEngine(),
    };
    const { data } = await axios.post(
      'https://apis.crawlq.ai/lang-translate',
      body,
    );

    return data.answer.trans_text[0];
  } catch (ex) {
    console.log('Error in translating in English ', ex);
    return text;
  }
};
