/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import styled from 'styled-components';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteBinIcon from '@mui/icons-material/Delete';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowLeft from '@mui/icons-material/ArrowForwardIos';
import ArrowRight from '@mui/icons-material/ArrowBackIosNew';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import UserIcon from '@mui/icons-material/Person';
import FileCopyRoundedIcon from '@mui/icons-material/FileCopyRounded';
import SharingIcon from '@mui/icons-material/Share';
import EditsIcon from '@mui/icons-material/Edit';
import PublicsIcon from '@mui/icons-material/Public';
import LinksIcon from '@mui/icons-material/Link';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FacebookIcon from '@mui/icons-material/Facebook';
import LoginIcon from '@mui/icons-material/Login';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import YouTubeIcon from '@mui/icons-material/YouTube';
import DrawIcon from '@mui/icons-material/Draw';
import PeopleIcon from '@mui/icons-material/People';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import CachedIcon from '@mui/icons-material/Cached';
import DownloadingRoundedIcon from '@mui/icons-material/DownloadingRounded';
import Email from '@mui/icons-material/Email';
import SyncIcon from '@mui/icons-material/Sync';

import CloudSyncIcon from '@mui/icons-material/CloudSync';
import InsightsIcon from '@mui/icons-material/Insights';

export const Insights360Icon = styled(InsightsIcon)`
  width: ${(props) => props.width ?? '22px'} !important;
  height: ${(props) => props.height ?? '20px'} !important;
  margin-left: ${(props) => props.marginLeft ?? '0px'} !important;
  margin-right: ${(props) => props.marginRight ?? '0px'} !important;
  color: ${(props) =>
    props.primary
      ? 'var(--primary-color)'
      : props?.active
      ? 'var(--active-color)'
      : props?.danger
      ? 'var(--danger-color)'
      : 'var(--default-text-color)'};
`;

export const MailIcon = styled(Email)`
  width: ${(props) => props.width ?? '22px'} !important;
  height: ${(props) => props.height ?? '20px'} !important;
  margin-left: ${(props) => props.marginLeft ?? '0px'} !important;
  margin-right: ${(props) => props.marginRight ?? '0px'} !important;
  color: ${(props) =>
    props.primary
      ? 'var(--primary-color)'
      : props?.active
      ? 'var(--active-color)'
      : props?.danger
      ? 'var(--danger-color)'
      : 'var(--default-text-color)'};
`;

export const LoopArrowIcon = styled(CachedIcon)`
  width: ${(props) => props.width ?? '22px'} !important;
  height: ${(props) => props.height ?? '20px'} !important;
  margin-left: ${(props) => props.marginLeft ?? '0px'} !important;
  color: ${(props) =>
    props.primary
      ? 'var(--primary-color)'
      : props?.active
      ? 'var(--active-color)'
      : props?.danger
      ? 'var(--danger-color)'
      : 'var(--default-text-color)'};
`;

export const UploadDoneIcon = styled(CloudDoneIcon)`
  width: ${(props) => props.width ?? '22px'} !important;
  height: ${(props) => props.height ?? '20px'} !important;
  margin-left: ${(props) => props.marginLeft ?? '0px'} !important;
  color: ${(props) =>
    props.primary
      ? 'var(--primary-color)'
      : props?.active
      ? 'var(--active-color)'
      : props?.danger
      ? 'var(--danger-color)'
      : 'var(--default-text-color)'};
`;

export const FilePinIcon = styled(AttachFileIcon)`
  width: ${(props) => props.width ?? '24px'} !important;
  height: ${(props) => props.height ?? '24px'} !important;
  color: ${(props) =>
    props.primary
      ? 'var(--primary-color)'
      : props?.active
      ? 'var(--active-color)'
      : props?.secondary
      ? 'var(--secondary-color)'
      : 'var(--default-text-color)'};
`;

export const GroupNameIcon = styled(GroupWorkIcon)`
  width: ${(props) => props.width ?? '24px'} !important;
  height: ${(props) => props.height ?? '24px'} !important;
  color: ${(props) =>
    props.primary ? 'var(--primary-color)' : 'var(--white-color)'};
`;

export const CommunityIcon = styled(PeopleIcon)`
  width: ${(props) => props.width ?? '24px'} !important;
  color: var(--primary-color);
`;

export const TestimonialIcon = styled(DrawIcon)`
  width: ${(props) => props.width ?? '24px'} !important;
  color: '#ff0000';
`;

export const YoutubeIcon = styled(YouTubeIcon)`
  width: ${(props) => props.width ?? '24px'} !important;
  color: '#ff0000' !important;
`;

export const ChatIcon = styled(ChatBubbleOutlineIcon)`
  width: ${(props) => props.width ?? '24px'} !important;
  color: var(--primary-color) !important;
`;

export const OnboardIcon = styled(LoginIcon)`
  width: ${(props) => props.width ?? '24px'} !important;
  color: var(--primary-color) !important;
`;

export const FBIcon = styled(FacebookIcon)`
  width: ${(props) => props.width ?? '24px'} !important;
  color: '#0866ff' !important;
`;

export const CampaignListIcon = styled(FormatListBulletedIcon)`
  width: ${(props) => props.width ?? '24px'} !important;
  height: ${(props) => props.height ?? '24px'} !important;
  color: ${(props) =>
    props.primary ? 'var(--primary-color)' : 'var(--white-color)'};
`;

export const LinkIcon = styled(LinksIcon)`
  width: ${(props) => props.width ?? '22px'} !important;
  height: ${(props) => props.height ?? '22px'} !important;
  color: var(--primary-color);
  cursor: pointer;
`;

export const PublicIcon = styled(PublicsIcon)`
  width: ${(props) => props.width ?? '22px'} !important;
  height: ${(props) => props.height ?? '22px'} !important;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  color: ${(props) =>
    props.disabled ? 'var(--secondary-color)' : 'var(--primary-color)'};
`;

export const EditIcon = styled(EditsIcon)`
  width: ${(props) => props.width ?? '22px'} !important;
  height: ${(props) => props.height ?? '24px'} !important;
  color: ${(props) =>
    props.disabled ? 'var(--secondary-color)' : 'var(--primary-color)'};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

export const ShareIcon = styled(SharingIcon)`
  width: ${(props) => props.width ?? '20px'} !important;
  height: ${(props) => props.height ?? '20px'} !important;
  color: ${(props) =>
    props.primary
      ? 'var(--primary-color)'
      : props?.active
      ? 'var(--active-color)'
      : props?.secondary
      ? 'var(--secondary-color)'
      : 'var(--default-text-color)'};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`;

export const CopyFileIcon = styled(FileCopyRoundedIcon)`
  width: ${(props) => props.width ?? '24px'} !important;
  height: ${(props) => props.height ?? '20px'} !important;
  margin-left: ${(props) => props.marginLeft ?? '0px'} !important;
  color: ${(props) =>
    props.primary
      ? 'var(--primary-color)'
      : props?.active
      ? 'var(--active-color)'
      : props?.secondary
      ? 'var(--secondary-color)'
      : 'var(--default-text-color)'};
  cursor: pointer;
`;

export const AIPenIcon = styled(AutoFixHighIcon)`
  width: ${(props) => props.width ?? '38px'} !important;
  height: ${(props) => props.height ?? '20px'} !important;
  color: var(--primary-color);
`;

export const ExpandIcon = styled(ArrowDropDownIcon)`
  width: ${(props) => props.width ?? '16px'} !important;
  height: ${(props) => props.height ?? '16px'} !important;
  color: var(--primary-color);
`;

export const CollapseIcon = styled(ArrowDropUpIcon)`
  width: ${(props) => props.width ?? '16px'} !important;
  height: ${(props) => props.height ?? '16px'} !important;
  color: var(--primary-color);
`;
export const InfoIcon = styled(InfoOutlinedIcon)`
  width: ${(props) => props.width ?? '16px'} !important;
  height: ${(props) => props.height ?? '16px'} !important;
  color: var(--primary-color);
`;

export const GoBackIcon = styled(ArrowBackIcon)`
  width: ${(props) => props.width ?? '18px'} !important;
  height: ${(props) => props.height ?? '18px'} !important;
  color: var(--primary-color);
  cursor: pointer;
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex: ${(props) => (props.flex ? props.flex : 'none')};
`;

export const UpArrowIcon = styled(KeyboardArrowUpIcon)`
  width: ${(props) => props.width ?? '24px'} !important;
  height: ${(props) => props.height ?? '24px'} !important;
  color: ${(props) =>
    props.primary
      ? 'var(--primary-color)'
      : props?.active
      ? 'var(--active-color)'
      : props?.secondary
      ? 'var(--secondary-color)'
      : 'var(--default-text-color)'};
  cursor: pointer;
`;
export const DownArrowIcon = styled(KeyboardArrowDownIcon)`
  width: ${(props) => props.width ?? '24px'} !important;
  height: ${(props) => props.height ?? '24px'} !important;
  color: ${(props) =>
    props.primary
      ? 'var(--primary-color)'
      : props?.active
      ? 'var(--active-color)'
      : props?.secondary
      ? 'var(--secondary-color)'
      : 'var(--default-text-color)'};
  cursor: pointer;
`;

export const PlusIcon = styled(AddIcon)`
  width: ${(props) => props.width ?? '16px'} !important;
  height: ${(props) => props.height ?? '16px'} !important;
  color: ${(props) =>
    props.primary
      ? 'var(--primary-color)'
      : props?.active
      ? 'var(--active-color)'
      : props?.secondary
      ? 'var(--secondary-color)'
      : 'var(--default-text-color)'};
  cursor: pointer;
`;

export const MinusIcon = styled(RemoveIcon)`
  width: ${(props) => props.width ?? '16px'} !important;
  height: ${(props) => props.height ?? '16px'} !important;
  color: var(--primary-color);
  cursor: pointer;
`;

export const ArrowForward = styled(ArrowLeft)`
  width: ${(props) => props.width ?? '16px'} !important;
  height: ${(props) => props.height ?? '16px'} !important;
  color: ${(props) =>
    props.primary
      ? 'var(--primary-color)'
      : props?.active
      ? 'var(--active-color)'
      : 'var(--default-text-color)'};
  cursor: pointer;
`;

export const ArrowBackward = styled(ArrowRight)`
  width: ${(props) => props.width ?? '16px'} !important;
  height: ${(props) => props.height ?? '16px'} !important;
  margin-right: ${(props) => props.marginRight ?? '0px'} !important;
  color: ${(props) =>
    props.primary
      ? 'var(--primary-color)'
      : props?.active
      ? 'var(--active-color)'
      : 'var(--default-text-color)'};
  cursor: pointer;
`;

export const CrossIcon = styled(CancelIcon)`
  width: ${(props) => props.width ?? '20px'} !important;
  height: ${(props) => props.height ?? '20px'} !important;
  margin-left: ${(props) => props.marginLeft ?? '0px'} !important;
  margin-right: ${(props) => props.marginRight ?? '0px'} !important;
  color: ${(props) =>
    props.primary
      ? 'var(--primary-color)'
      : props?.active
      ? 'var(--active-color)'
      : props?.danger
      ? 'var(--danger-color)'
      : 'var(--default-text-color)'};
  cursor: pointer;
`;

export const TickIcon = styled(CheckCircleIcon)`
  width: ${(props) => props.width ?? '20px'} !important;
  height: ${(props) => props.height ?? '20px'} !important;
  color: var(--active-color);
  cursor: pointer;
`;

export const DeleteIcon = styled(DeleteBinIcon)`
  color: ${(props) =>
    props.disabled ? 'var(--secondary-color)' : 'var(--danger-color)'};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  width: 20px !important;
  height: 20px !important;
`;

export const PersonIcon = styled(UserIcon)`
  color: ${(props) =>
    props.primary ? 'var(--primary-color)' : 'var(--white-color)'};
  width: 22px !important;
  height: 22px !important;
  cursor: pointer;
`;

export const ImportIcon = styled(DownloadingRoundedIcon)`
  color: ${(props) =>
    props.primary ? 'var(--primary-color)' : 'var(--white-color)'};
  width: 22px !important;
  height: 22px !important;
  cursor: pointer;
`;

export const SyncingIcon = styled(SyncIcon)`
  color: ${(props) =>
    props.primary ? 'var(--primary-color)' : 'var(--white-color)'};
  width: 22px !important;
  height: 22px !important;
  cursor: pointer;
`;

export const IntegrationsIcon = styled(CloudSyncIcon)`color: ${(props) =>
    props.primary ? 'var(--primary-color)' : 'var(--white-color)'};
  width: 30px !important;
  height: 30px !important;
  cursor: pointer;`;
