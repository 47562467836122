/* eslint-disable max-len */
export const updateRecentWorkspace = (recentWorkspaces, selectedWorkspace) => {
  const doesNewWorkspaceAlreadyExist = recentWorkspaces.some((workspace) => workspace?.name === selectedWorkspace?.name);

  if (doesNewWorkspaceAlreadyExist) {
    return recentWorkspaces;
  }

  if (recentWorkspaces.length === 5) {
    recentWorkspaces.shift();
    recentWorkspaces.push(selectedWorkspace);
    return recentWorkspaces;
  }

  recentWorkspaces.push(selectedWorkspace);
  return recentWorkspaces;
};
