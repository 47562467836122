/* eslint-disable react/jsx-indent-props */
/* eslint-disable no-plusplus */
/* eslint-disable react/button-has-type */
/* eslint-disable no-return-assign */
/* eslint-disable no-use-before-define */

import { Button, Col, Form, Row, Input, Select, Switch, Steps } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  CaretRightOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import AthenaLogo from 'assets/images/Athena3.png';

const { Option } = Select;

const FlowSteps = (props) => {
  const { currentStep, setIndex } = props;
  return (
    <div
      style={{
        height: '10vh',
        // backgroundColor: 'green',
        display: 'flex',
        // justifyContent: 'center',
        alignItems: 'center',
        width: '80vw',
      }}
    >
      <Row
        align="middle"
        justify="space-between"
        style={{ width: '100%', marginLeft: '45px' }}
      >
        <Col span={1}>
          <span
            style={{
              backgroundColor: currentStep === 0 ? '#5887FB' : 'lightGray',
              borderRadius: '50%',
              width: '200px',
              height: '100px',
              padding: '10px',
              cursor: 'pointer',
            }}
            onClick={() => setIndex(1)}
          >
            1
          </span>
        </Col>
        <Col span={1} offset={1}>
          <CaretRightOutlined
            style={{
              // borderRadius: '50%',
              // width: '200px',
              // height: '100px',
              // padding: '10px',
              padding: '10px',
              color: 'white',
              fontSize: '30px',
            }}
          />
        </Col>
        <Col span={1} offset={1}>
          <span
            style={{
              backgroundColor: currentStep === 1 ? '#5887FB' : 'lightGray',
              borderRadius: '50%',
              width: '100px',
              height: '100px',
              padding: '10px',
              cursor: 'pointer',
            }}
            onClick={() => setIndex(2)}
          >
            2
          </span>
        </Col>
        <Col span={1} offset={1}>
          <CaretRightOutlined
            style={{
              color: 'white',
              fontSize: '30px',
            }}
          />
        </Col>
        <Col span={1} offset={1}>
          <span
            style={{
              backgroundColor: currentStep === 2 ? '#5887FB' : 'lightGray',
              borderRadius: '50%',
              width: '100px',
              height: '100px',
              padding: '10px',
              cursor: 'pointer',
            }}
            onClick={() => setIndex(3)}
          >
            3
          </span>
        </Col>
      </Row>
    </div>
  );
};

export default FlowSteps;
