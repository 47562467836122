/* eslint-disable react/no-danger */
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { selectTextAndCopy } from 'Component/Shared/copyToClipBoard';
import { Button, Col, Popconfirm, Row, Tooltip } from 'antd';
import AthenaLogo from 'assets/chat-athena/athena-logo.png';
import React, { useEffect, useState } from 'react';
import { useIsMobile } from 'Component/hooks/useIsMobile';
import { renderAnswerWithImages } from 'Component/Pages/ChatAthena/utils';
import cx from 'classnames';
import { ShareOutlined } from '@mui/icons-material';
import CampaignIcon from '@mui/icons-material/Campaign';
import LoaderSpin from 'Component/Shared/Loader';
import AvatarGenerator from 'components/common/AvatarGenerator/AvatarGenerator';
import { useMutateUpdateChatHistory } from 'queries/chat-athena/mutate-update-chat';
import { useMutateDeleteChatHistory } from 'queries/chat-athena/mutate-delete-chat';
import SnippetCodeComponent from './SnippetCodeComponent';
import styles from './index.module.css';
import {
  generateShareUrl,
  getSpeechUrlFromText,
  getUpdatedAudioURL,
} from './answer.service';
import { AudioPlayer } from './AudioPlayer';

export function AnswerComponent({
  index,
  isDarkMode,
  element,
  selectAnswer,
  children,
  answerId,
}) {
  const { mutate: updateAnswerMutation } = useMutateUpdateChatHistory();
  const {
    mutate: deleteAnswerMutation,
    isLoading: isDeletingMessage,
  } = useMutateDeleteChatHistory();

  const isMobile = useIsMobile();

  const [audioUrl, setAudioUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isGeneratingBlogLink, setIsGeneratingBlogLink] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState({
    id: null,
    answer: null,
  });

  const handleTextToSpeech = async () => {
    try {
      setIsLoading(true);
      const url = await getSpeechUrlFromText(index);
      setAudioUrl(url);
      setIsLoading(false);
    } catch (ex) {
      console.log(ex);
      await getUpdatedAudioURL(index);
    }
  };

  const handleDeleteMessage = async () => {
    deleteAnswerMutation({ answerId });
  };

  const handleShareBlog = async () => {
    setIsGeneratingBlogLink(true);
    await generateShareUrl(
      element?.question,
      element.answer,
      element.images?.[0]?.image_id || '',
      element?.speech_file_id,
    );
    setIsGeneratingBlogLink(false);
  };

  const handleUpdateAnswer = () => {
    updateAnswerMutation({
      id: selectedAnswer.id,
      updateAnswer: selectedAnswer.answer,
    });
    setSelectedAnswer({ id: null, answer: null });
  };

  useEffect(() => {
    if (element.speech_file_url) {
      setAudioUrl(element.speech_file_url);
    }
  }, []);

  return (
    <div className={styles.answer_wrapper}>
      <div>
        {Object?.keys(element?.avatar_props ?? {}).length > 0 ? (
          <AvatarGenerator width={'40px'} avatarProp={element.avatar_props} />
        ) : (
          <img src={AthenaLogo} alt="Athena Logo" className={styles.avatar} />
        )}
      </div>
      <Row justify="start" align="bottom" style={{ width: '100%' }}>
        <Col span={isMobile ? 24 : 20}>
          {answerId !== selectedAnswer.id && (
            <SnippetCodeComponent
              selectedAnswer={selectedAnswer}
              element={element}
              index={index}
              answerId={answerId}
              isDarkMode={isDarkMode}
              selectAnswer={selectAnswer}
            />
          )}

          {answerId === selectedAnswer.id && (
            <div
              key={`answer-${answerId}`}
              contentEditable={answerId === selectedAnswer.id}
              id={`answer-${answerId}`}
              onInput={(e) => {
                setSelectedAnswer({
                  id: answerId,
                  answer: e.currentTarget.innerHTML,
                });
              }}
              className={styles.selected_answer}
              {...renderAnswerWithImages(element, isMobile)}
            />
          )}
          {answerId === selectedAnswer.id && (
            <>
              <Button
                style={{ marginRight: '10px' }}
                onClick={() => {
                  setSelectedAnswer({
                    id: null,
                    answer: null,
                  });
                }}
              >
                Cancel
              </Button>
              <Button onClick={handleUpdateAnswer} type="primary">
                Save
              </Button>
            </>
          )}
        </Col>
        <div className={styles.answer_wrapper_audio}>
          <AudioPlayer url={audioUrl} />
          <div className={styles.icons_group}>
            <Tooltip title="Hear Response" color="blue">
              {isLoading && <LoaderSpin width={'40px'} height={'40px'} />}
              {!audioUrl && !isLoading && (
                <CampaignIcon
                  onClick={handleTextToSpeech}
                  className={styles.icons}
                />
              )}
            </Tooltip>
            <Tooltip title="Copy response" color="blue">
              <ContentCopyIcon
                onClick={() => selectTextAndCopy(`answer-${answerId}`)}
                className={styles.icons}
              />
            </Tooltip>
            <Tooltip
              title="Improve Athena's responses by editing here"
              color="blue"
              key={`edit-${index}`}
            >
              <EditRoundedIcon
                onClick={() =>
                  setSelectedAnswer({
                    id: answerId,
                    answer: element.answer,
                  })
                }
                className={styles.icons}
              />
            </Tooltip>
            {children}
            {isGeneratingBlogLink ? (
              <LoaderSpin width={'30px'} height={'20px'} />
            ) : (
              <Tooltip
                title="Generate Blog URL to share the document"
                color="blue"
                key={`publish-${index}`}
                placement="bottom"
              >
                <ShareOutlined
                  onClick={handleShareBlog}
                  className={styles.icons}
                />
              </Tooltip>
            )}
            {isDeletingMessage ? (
              <LoaderSpin width={'30px'} height={'20px'} />
            ) : (
              <Tooltip
                title="Delete this responses by clicking here"
                color="blue"
                key={`delete-${index}`}
                placement="bottom"
              >
                <Popconfirm
                  title="Delete the response"
                  description="Are you sure to delete this response ?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={handleDeleteMessage}
                  style={{
                    background: isDarkMode ? '#444653' : 'white',
                  }}
                >
                  <DeleteOutlineRoundedIcon
                    className={cx([styles.icons], [styles.danger])}
                  />
                </Popconfirm>
              </Tooltip>
            )}
          </div>
        </div>
      </Row>
    </div>
  );
}
