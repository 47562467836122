/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const InputCustom = styled.input`
  width: 100%;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  font-size: 0.9rem;
  flex: 1;
`;

export const FromGroup = styled.div`
  display: flex;
  gap: 2rem;
`;

export const CheckBoxWraper = styled.div`
  display: flex;
  gap: 0.5rem;
  font-size: 0.8rem;
  align-items: center;
  input {
    width: 20px;
    height: 20px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  flex: 1;
`;

export const TeamMemberWrapper = styled.div`
  flex: 1;
  display: grid;
  grid-template: auto/1fr 1fr;
  column-gap: 2rem;
  row-gap: 1rem;
`;
