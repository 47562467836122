/* eslint-disable max-len */
import {
  DISPLAY_GENERATE_BLOGS_TABLE_HEADER,
  TABS,
} from 'constants/market-message/generate-blogs';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userWorkspaceCountUpdate } from 'redux/index';
import { TableHeader, TableRow } from 'components/common/Table/Table';
import { ScaffoldCard } from 'components/common/scaffold-card';

import { useGetUserBlogTemplate } from 'queries/market-message/get-user-blog-template';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { generateAndPublishBlogs } from 'queries/market-message/generate-and-publish-blogs';
import TabSwitcher from 'components/common/TabSwitcher/TabSwitcher';
import SearchInput from 'components/common/SearchInput';
import { MarketMessageBlogsLoading } from 'components/skeleton/market-message/BlogsListLoding';
import { ROUTES } from 'constants/routes';
import styles from './generate-blog.module.css';
import market_message_common_styles from '../market-message.module.css';

const GenerateBlogs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { email } = useSelector((state) => state?.user);
  const { data: userBlogTemplates, isLoading } = useGetUserBlogTemplate(email);

  const [searchKey, setSearchKey] = useState('');
  const [selectedTemplates, setSelectedTemplates] = useState({});
  const [currentTab, setCurrentTab] = useState(0);

  const userBlogTemplatesLength = userBlogTemplates
    ? userBlogTemplates.length
    : 0;

  const handleToggleSelectTemplates = (template) => {
    const copyOfSelectedTemplates = { ...selectedTemplates };
    if (template.template_id in copyOfSelectedTemplates) {
      delete copyOfSelectedTemplates[template.template_id];
    } else {
      copyOfSelectedTemplates[template.template_id] = {
        template_id: template.template_id,
        from_support: Boolean(template.from_support),
        template_type: template.from_support ? 'public' : 'private',
      };
    }
    setSelectedTemplates({ ...copyOfSelectedTemplates });
  };

  const applySearchFilter = (template) => {
    const key = searchKey.trim()?.toLowerCase();
    if (key === '') return true;

    const { template_name, template_description } = template;

    return (
      template_name?.toLowerCase().includes(key) ||
      template_description?.toLowerCase().includes(key)
    );
  };

  const applyTypeFilter = (template) => {
    const isPublic = template?.from_support;
    return currentTab === 0 ? isPublic : !isPublic;
  };

  const handleCreateBlogs = async () => {
    if (Object.keys(selectedTemplates).length === 0) return;

    const templates = Object.values(selectedTemplates);

    const isCreatedBlogs = await generateAndPublishBlogs(templates);
    if (isCreatedBlogs) {
      navigate(ROUTES.DIGITAL_ASSETS_BLOGS);
    }
  };

  useEffect(() => {
    dispatch(userWorkspaceCountUpdate(userBlogTemplatesLength));
  }, [userBlogTemplatesLength]);

  return (
    <div className={market_message_common_styles.child_wrapper}>
      <div className={market_message_common_styles.left_child}>
        <div className={styles.buttons_wrapper}>
          <div className={styles.tabs_wrapper}>
            <TabSwitcher
              outerClass={styles.tab_container}
              tabs={[TABS.RESEARCH, TABS.WRITING]}
              setSwitcher={setCurrentTab}
            />
          </div>
          <div className={styles.placeholder_div} />
          <div className={styles.search_wrapper}>
            <SearchInput
              placeholder="Search Templates"
              onChange={(e) => setSearchKey(e.target.value)}
              value={searchKey}
            />
          </div>
          <div className={styles.button_container}>
            <button
              onClick={handleCreateBlogs}
              type="button"
              disabled={Object.keys(selectedTemplates).length === 0}
            >
              Create Blogs
            </button>
          </div>
        </div>
        <TableHeader headers={DISPLAY_GENERATE_BLOGS_TABLE_HEADER} />

        {isLoading && <MarketMessageBlogsLoading />}
        <div className={styles.table_container}>
          {userBlogTemplates &&
            userBlogTemplates.length > 0 &&
            userBlogTemplates
              .filter(applyTypeFilter)
              .filter(applySearchFilter)
              .map((template) => (
                <ScaffoldCard
                  key={`${template?.name} ${template.template_id}`}
                  className={`${styles.blogs_table_content} h-margin-b-small`}
                >
                  <TableRow
                    icon={
                      template.template_id in selectedTemplates ? (
                        <CheckBoxIcon />
                      ) : (
                        <CheckBoxOutlineBlankIcon />
                      )
                    }
                    outerClass={styles.table_checkbox}
                    innerClass="m-w-t-c-name"
                    onClick={() => handleToggleSelectTemplates(template)}
                  />

                  <TableRow
                    value={template?.template_name}
                    outerClass={styles.table_name}
                    innerClass="m-w-t-c-name"
                  />

                  <TableRow
                    value={template?.template_description}
                    outerClass={styles.table_description}
                    innerClass="m-w-t-c-name"
                  />
                </ScaffoldCard>
              ))}
        </div>
      </div>
      <aside className={market_message_common_styles.aside_wrapper}>
        {/* {Array(5)
          .fill(null)
          .map((index) => (
            <ScaffoldCard className="market-research-info-container">
              <div>Cars</div>
            </ScaffoldCard>
          ))} */}
      </aside>
    </div>
  );
};

export default GenerateBlogs;
