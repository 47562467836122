import React, { useState } from 'react';
import styles from './input-text-feild.module.css';

export function InputTextFeild({ value, label, description, onChange }) {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = (e) => {
    if (e.target.value.trim() === '') {
      setIsFocused(false);
    }
  };

  return (
    <div className={styles.container}>
      <label
        className={`${styles.label} ${isFocused ? styles.labelFocused : ''}`}
      >
        {label}
      </label>
      <input
        className={styles.input}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={onChange}
        value={value}
      />
      {description && <small>{description}</small>}
    </div>
  );
}
