/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { LoaderTailSpin } from 'Component/Shared/Loader';
import moment from 'moment';
import { AudioPlayer } from 'Component/Pages/Content Automation/AIContentWriter/ChatAthenaComponents/components/AnswerComponent/AudioPlayer';
// import notfoundimg from '../../../../assets/img/not_found.svg';
import style from 'react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark';
import styles from './blog-share.module.css';

function BlogShare() {
  const [blog, setBlog] = useState({});
  const [blogReadTime, setBlogReadTime] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);

  const handleGetBlog = async (blog_id) => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `https://apis.crawlq.ai/publish-blog?blog_id=${blog_id}`,
      );
      setIsLoading(false);
      if (data.statusCode === '500') {
        setNotFound(true);
      }
      return data.body;
    } catch (error) {
      console.error('Error while fetching blog:', error);
    }
  };

  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  const handleEstimateReadTime = (blog) => {
    try {
      const result_document = blog.text.replaceAll(/<\/?[^>]+(>|$)/g, '');
      const wpm = 225;
      let time = 0;
      if (result_document.trim() !== '') {
        const words = result_document.trim().split(/\s+/).length;
        time = Math.ceil(words / wpm);
      }
      setBlogReadTime(time);
      console.log('Estimated read time:', time);
    } catch (ex) {
      console.error('Error in Estimating Time', ex);
    }
  };

  const blogHTML = `${blog.text}<meta property="og:image" content=${blog.image_url}/>`;

  useEffect(() => {
    const blog_id = params.id;
    handleGetBlog(blog_id).then(({ blog }) => {
      setBlog(blog);
      handleEstimateReadTime(blog);
    });
  }, []);

  // if (notFound) {
  //   return (
  //     <div className={styles.blog_loader}>
  //       <img src={notfoundimg} width={500} alt="Blog not found" />
  //       <b style={{ fontSize: '2rem', marginTop: '1.5rem' }}>Blog Not found</b>
  //     </div>
  //   );
  // }

  if (!isLoading) {
    return (
      <div className={styles.container}>
        <div>
          <b>Author: </b>
          <span>{blog.fullname}</span>
          <br />
          <div>
            <span>{moment(blog.date_published).format('MMM Do YYYY')}</span>
            <span className="mx-4">
              •{blogReadTime}
              <span className="text-muted">min read</span>
            </span>
          </div>
          {blog.speech_file_url && (
            <div
              style={{
                marginTop: '10px',
                marginLeft: '-40px',
                marginBottom: '10px',
              }}
            >
              <AudioPlayer url={blog.speech_file_url} />
            </div>
          )}
        </div>
        <hr style={style.divider} />
        {blog.speech_file_url && (
          <img
            src={blog.image_url}
            width={'50%'}
            style={{ marginTop: '5px', marginBottom: '10px' }}
            alt=""
          />
        )}
        <div dangerouslySetInnerHTML={{ __html: blogHTML }} />
      </div>
    );
  }
  return (
    <div className={styles.blog_loader}>
      <LoaderTailSpin />
    </div>
  );
}

export default BlogShare;
