/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable radix */
/* eslint-disable import/extensions */

import { baseAPIsUrl } from 'Config/Api';
import { store } from 'redux/store';
import setGoogleLanguageCookie from '../../../../Shared/Cookie_custom';
import UpdateCreditLimit, {
  CreditManage,
} from '../../../../Shared/CreditManager';

import createInstance from '../../../../../Config/AxiosConfig.js';

const getUserFromRedux = () => {
  const state = store.getState();
  return state.user;
};

// TO Check if user has enough credits
// eslint-disable-next-line no-unused-vars
const checkIfEnoughCreditExist = async () => {
  const data = await UpdateCreditLimit(0);
  if (data.user_credit + 1000 <= data.total_credit) {
    return {
      error: false,
    };
  }
  return {
    error: true,
  };
};

const createNewWorkspace = async (body) => {
  let CREDITS_TO_BE_CHECKED = 959;
  try {
    if (body.source_lang !== 'en') {
      CREDITS_TO_BE_CHECKED += 1000;
    }
    const { success } = await CreditManage(CREDITS_TO_BE_CHECKED, true);
    // if (!success) {
    //   return {
    //     error: true,
    //     errorMessage: 'Credit Limit Exceeds',
    //     data: null,
    //   };
    // }

    // Google Translate set new language as per new workspace language
    setGoogleLanguageCookie(`/en/${body.source_lang}/`);

    const instance = await createInstance();
    const { data } = await instance.post('create-project', body);

    console.log(data);
    if (data.statusCode === '500') {
      // Workspace already exists
      if (data.body === 'Project Name already exists') {
        return {
          error: true,
          errorMessage: 'Workspace already exists !',
          data: null,
        };
      }
      // status code 500 exists
      return {
        data: null,
        error: true,
        errorMessage: 'Oops! Try Again.',
      };
    }

    return { error: false, data: data.data, errorMessage: '' };
  } catch (error) {
    console.log('Error in creating new workspace', error);
    return {
      data: null,
      errorMessage: 'Oops! Try Again.',
      error: true,
    };
  }
};

export const getAllWorkspaceNames = async () => {
  const username = getUserFromRedux()?.username;
  const instance = await createInstance();
  const { data } = await instance.get(
    `${baseAPIsUrl}/get-projects-names?username=${username}`,
  );
  return data;
};

export default createNewWorkspace;
