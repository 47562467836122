import React, { useState } from 'react';
import CreateNewWorkspace from 'Component/Pages/Workspaces/Workspace/CreateNewWorkspace/CreateNewWorkspace';

function CreateNewWorkspaceHolder() {
  // const navigate = useNavigate();
  // const [ActiveInnerTab, setActiveInnerTab] = useState(0);

  return (
    <CreateNewWorkspace />

  );
}

export default CreateNewWorkspaceHolder;
