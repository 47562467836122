/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import axios from 'axios';

export const exportAsCSV = (data, category) => {
  let allQA = [];

  Object.keys(data).map((key) => {
    const tempObj = {
      question: key,
      answer: data[key],
    };
    allQA = [...allQA, tempObj];
  });
  const headers = {
    // remove commas to avoid errors
    question: 'Question',
    answer: 'Answer',
  };

  const itemsNotFormatted = [...allQA];

  const itemsFormatted = [];

  // format the data
  itemsNotFormatted.forEach((item) => {
    itemsFormatted.push({
      question: item.question.replace(',', '').replace(/\n/g, ''),
      answer: item.answer
        .toString()
        .replace(',', '')
        .replace(/\n/g, ''),
    });
  });

  const fileTitle = category; // or 'my-unique-title'

  exportCSVFile(headers, itemsFormatted, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
};

export const exportAllAsCSV = async () => {
  const { data } = await getAllData();
  // console.log(data)
  let qas = [];
  data.map((oneData) => {
    let cleanQA = [];
    oneData.qa.map((oneQA) => {
      oneQA.section = oneData.section;
      cleanQA = [...cleanQA, oneQA];
    });
    qas = [...qas, ...cleanQA];
  });
  const headers = {
    // remove commas to avoid errors
    section: 'Section',
    question: 'Question',
    answer: 'Answer',
  };

  const itemsNotFormatted = [...qas];

  const itemsFormatted = [];

  // format the data
  itemsNotFormatted.forEach((item) => {
    itemsFormatted.push({
      section: item.section.replace(',', '').replace(/\n/g, ''),
      question: item.question.replace(',', '').replace(/\n/g, ''),
      answer: item.answer
        .toString()
        .replace(',', '')
        .replace(/\n/g, ''),
    });
  });

  const fileTitle = localStorage.getItem('category'); // or 'my-unique-title'

  exportCSVFile(headers, itemsFormatted, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
};

const getAllData = async () => {
  const url = `https://api.crawlq.ai/Market-Research/load/questions/all?username=${localStorage.getItem('username')}&name=${localStorage.getItem('name')}&section=${localStorage.getItem(
    'category',
  )}`;
  const { data } = await axios.get(url);
  return data;
};

function convertToCSV(objArray) {
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  let str = '';

  array.map((inf) => {
    if (inf.section) {
      // this is for Download All
      str
        += `${inf.section.replace(',', '')
        },${
          inf.question.replace(',', '')
        },${
          inf.answer.replace(',', '').replace(/\n/g, '')
        }\r\n`;
    } else {
      // this is for single download in subsection
      str
        += `${inf.question.replace(',', '')
        },${
          inf.answer.replace(',', '').replace(/\n/g, '')
        }\r\n`;
    }
  });

  return str;
}

function exportCSVFile(headers, items, fileTitle) {
  if (headers) {
    items.unshift(headers);
  }

  // Convert Object to JSON
  const jsonObject = JSON.stringify(items);

  const csv = convertToCSV(jsonObject);

  const exportedFilenmae = `${fileTitle}.csv` || 'export.csv';

  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', exportedFilenmae);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
