import React from 'react';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import styles from './index.module.css';

const SearchInput = ({ placeholder, onChange, value, onClickSearch }) => (
  <div className={styles.search_wrapper}>
    <input
      className={styles.input}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
    />
    <div className={styles.search_icon_wrapper}>
      <SearchRoundedIcon onClick={onClickSearch} />
    </div>
  </div>
);

export default SearchInput;
