/* eslint-disable max-len */
import createInstance from 'Config/AxiosConfig';
import { useQuery } from 'react-query';

export const getQuestions = async (topic) => {
  const payload = {
    q: topic,
  };

  const instance = await createInstance();
  const { data } = await instance.post('ai-content-questions', payload);

  if (data.statusCode === '200' || data.status === 'success') {
    return data?.data?.questions;
  }

  throw new Error('Unable to fetch Questions');
};

// Keep Query Value as File Name to maintain consistency and uniqueness in query index
const QUERY_KEY = 'get-questions';

// Query Config Options
const queryOptions = {
  staleTime: 10000,
};

// QUERY
export const useGetQuestions = (topic) =>
  useQuery([QUERY_KEY, topic], () => getQuestions(topic), queryOptions);
