import React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import LoaderSpin from 'Component/Shared/Loader';
import styles from '../style.module.css';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  backgroundColor: 'var(--background-color)',
  color: 'var(--white-color)',
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: 'var(--white-color)',
  },

  '& .css-yw020d-MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },

  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const CustomizedAccordions = ({
  panels,
  expanded,
  handleChange,
  descriptionData,
  isLoading,
}) => (
  <div>
    {panels.map((panel) => (
      <Accordion
        expanded={expanded?.name === panel.name}
        onChange={handleChange(panel)}
      >
        <AccordionSummary
          style={{
            color: '#fff',
          }}
        >
          <h6>{panel.label}</h6>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.table_container}>
            {!isLoading ? (
              <table>
                <thead>
                  <tr>
                    <th className={styles.brand_voice_desc_thead}>Category</th>
                    <th className={styles.brand_voice_desc_thead}>
                      Description
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {(descriptionData ?? []).map((item, index) => (
                    <tr key={index}>
                      <td>{item.category}</td>
                      <td>{item.description}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <LoaderSpin />
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    ))}
  </div>
);

export default CustomizedAccordions;
