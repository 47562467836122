import styled from 'styled-components';
import PublicIcon from '@mui/icons-material/Public';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import OfflineBoltIcon from '@mui/icons-material/OfflineBolt';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';

export const AddToListIcon = styled(PlaylistAddIcon)`
  color: var(--active-color);
  width: 20px !important;
  height: 20px !important;
  cursor: pointer;
`;

export const CartIconEnabled = styled(ShoppingCartIcon)`
  color: var(--primary-color);
  width: 20px !important;
  height: 20px !important;
  cursor: pointer;
`;

export const CartIconDisabled = styled(ShoppingCartIcon)`
  color: var(--secondary-color);
  width: 20px !important;
  height: 20px !important;
  cursor: pointer;
`;
export const NotReadyIcon = styled(OfflineBoltIcon)`
  color: var(--secondary-color);
  width: 20px !important;
  height: 20px !important;
  cursor: pointer;
`;

export const ReadyIcon = styled(OfflineBoltIcon)`
  color: var(--active-color);
  width: 20px !important;
  height: 20px !important;
  cursor: pointer;
`;

export const WebsiteIcon = styled(PublicIcon)`
  color: var(--primary-color);
  width: 16px !important;
  height: 16px !important;
  cursor: pointer;
`;

export const RemoveIcon = styled(DeleteIcon)`
  color: var(--danger-color);
  width: 20px !important;
  height: 20px !important;
  cursor: pointer;
`;

export const LikeIcon = styled(FavoriteRoundedIcon)`
  color: var(--danger-color);
  width: 20px !important;
  height: 20px !important;
  cursor: pointer;
`;

export const UnLikeIcon = styled(FavoriteBorderRoundedIcon)`
  color: var(--default-text-color);
  width: 20px !important;
  height: 20px !important;
  cursor: pointer;
`;

export const CopyIcon = styled(FileCopyIcon)`
  color: var(--active-color);
  width: 18px !important;
  height: 18px !important;
  cursor: pointer;
`;

export const MoreOptionIcon = styled(MoreVertIcon)`
  color: var(--primary-color);
  width: 20px !important;
  height: 20px !important;
  cursor: pointer;
`;

export const OpenIcon = styled(OpenInNewIcon)`
  color: var(--primary-color);
  width: 18px !important;
  height: 18px !important;
  cursor: pointer;
`;
