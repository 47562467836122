/* eslint-disable default-param-last */
import {
  USER_LOGIN_SUCCESS,
  USER_WORKSPACE_COUNT_UPDATE,
  USER_PLAN_UPDATE,
  USER_LOGOUT,
  USER_WORKSPACE_SET_ATHENA_SOURCES,
  USER_EMAIL_UPDATE,
} from './UserTypes';

const initialState = {
  name: '', // name
  username: '', // username
  email: '', // username
  plan: '', // Plan
  admin: '',
  verified: false,
  group: '',
  workspace_count: '',
  workspace_limit: '',
  member_count: '',
  member_limit: '',
  creation_date: '',
  modified_date: '',
  athena_sources: '',
  scope: '',
};

const userLoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };

    case USER_WORKSPACE_COUNT_UPDATE:
      return {
        ...state,
        workspace_count: action.payload,
      };

    case USER_PLAN_UPDATE:
      return {
        ...state,
        plan: action.payload.plan_name,
        purchase_date: action.payload.purchase_date,
      };

    case USER_WORKSPACE_SET_ATHENA_SOURCES:
      return {
        ...state,
        athena_sources: action.payload,
      };

    case USER_LOGOUT:
      return {
        ...initialState,
      };

    case USER_EMAIL_UPDATE:
      return {
        ...state,
        email: action.payload,
        username: action.payload,
      };

    default:
      return state;
  }
};

export default userLoginReducer;
