/* eslint-disable no-sequences */
/* eslint-disable indent */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-restricted-syntax */
export const getModularizeMarketCategory = (object, start, end) => {
  const result = [];

  if (object) {
    for (const [index, [key, value]] of Object.entries(object).entries()) {
      result.push({
        id: index,
        category: key,
        completed: value === 'y',
      });
    }
  }

  if (start || end) return result.slice(start, end);

  return result;
};

export const isMinTwoQuestionsAnswered = (QuestionAnswers) => {
  let minAnsweredCount = 0;
  if (QuestionAnswers) {
    for (const value of Object.values(QuestionAnswers)) {
      if (value !== '') {
        if (minAnsweredCount >= 2) break;
        minAnsweredCount += 1;
      }
    }
  }
  if (minAnsweredCount >= 2) return true;

  return false;
};

export const clearAnswer = (QuestionAnswers) => {
  if (QuestionAnswers) {
    Object.keys(QuestionAnswers).forEach((item) => {
      QuestionAnswers[item] = '';
    });
  }

  return QuestionAnswers;
};

export const formatAnswersToSave = (QuestionAnswers) => {
  /*  From QuestionSet extracts question as key and check if users has a changed answer or not
  if yes , set it as the value of key else use the previous answer
  WORST CASE : empty string */
  const formattedResponse = {};
  if (QuestionAnswers) {
    Object.keys(QuestionAnswers).forEach((item) => {
      formattedResponse[item] = [QuestionAnswers[item]];
    });
  }

  return formattedResponse;
};

export const formatQuestionAnswers = (QuestionAnswers, QuestionsSet) => {
  const formattedArray = [];

  if (QuestionsSet) {
    for (const [key, value] of Object.entries(QuestionsSet)) {
      const answerBe =
        QuestionAnswers[value?.question] ?? (value?.answer || '');
      formattedArray.push({
        question: value?.question,
        answer: answerBe,
      });
    }
  }

  return formattedArray;
};

export const formatFilledQuestionAnswer = (FillWithAIResponse) => {
  const formattedResponse = {};
  if (FillWithAIResponse) {
    for (const [key, value] of Object.entries(FillWithAIResponse)) {
      formattedResponse[value?.question] = value?.ai_suggestion;
    }
  }

  return formattedResponse;
};

// Format Question Answer from load-question endpoint
export const formatCategoryQuestionAnswer = (questionAndAnswer) => {
  const formattedResponse = {};
  if (questionAndAnswer) {
    for (const [key, value] of Object.entries(questionAndAnswer)) {
      formattedResponse[value?.question] = value?.trans_answer;
    }
  }

  return formattedResponse;
};
