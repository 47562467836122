import React, { useState } from 'react';
import { useGetMarketHelper } from 'queries/market-research/get-market-helper';
import { Divider } from 'components/common/divider';
import ReactPlayer from 'react-player';
import { ScaffoldCard } from 'components/common/scaffold-card';
import { DownArrowIcon, UpArrowIcon } from 'components/common/index';
import { TextIcon, VideoIcon } from '../styles';

const AthenaHelper = ({ categoryName }) => {
  const { data: marketHelperData } = useGetMarketHelper(categoryName);

  const { athena_video_link, helper_text_athena } = marketHelperData ?? {};

  const [ShowInfo, setShowInfo] = useState(true);
  const [IsTextView, setIsTextView] = useState(false);

  return (
    <ScaffoldCard
      className={
        ShowInfo ? 'h-margin-b-x2 market-research-info' : 'h-margin-b-x2'
      }
    >
      <div className="market-research-info-header">
        <span
          className="market-research-info-header-text"
          onClick={() => setShowInfo((ShowInfo) => !ShowInfo)}
        >
          Market Insights
        </span>

        <div className="market-research-info-right-header">
          <TextIcon
            active={IsTextView}
            className="h-margin-r-x2"
            onClick={() => {
              setIsTextView(true);
              setShowInfo(true);
            }}
          />
          <VideoIcon
            active={!IsTextView}
            className="h-margin-r-xlarge"
            onClick={() => {
              setIsTextView(false);
              setShowInfo(true);
            }}
          />
          {ShowInfo ? (
            <UpArrowIcon onClick={() => setShowInfo((ShowInfo) => !ShowInfo)} />
          ) : (
            <DownArrowIcon
              onClick={() => setShowInfo((ShowInfo) => !ShowInfo)}
            />
          )}
        </div>
      </div>
      {ShowInfo && <Divider />}
      {ShowInfo && (
        <div className="market-research-info-container">
          <div className="market-research-info-content-container">
            {!IsTextView && (
              <ReactPlayer
                url={athena_video_link}
                width="100%"
                height="100%"
                controls
                loop
                config={{
                  file: { attributes: { controlsList: 'nodownload' } },
                }}
              />
            )}

            {IsTextView && (
              <div className="market-research-helper-text">
                {helper_text_athena}
              </div>
            )}
          </div>
        </div>
      )}
    </ScaffoldCard>
  );
};

export default React.memo(AthenaHelper);
