import {
  CREATE_TEMPLATES_PAGE_HEADER,
  DISPLAY_TEMPLATES_PAGE_DESCRIPTION,
  DISPLAY_TEMPLATES_PAGE_HEADER,
  EDIT_TEMPLATES_PAGE_DESCRIPTION,
  EDIT_TEMPLATES_PAGE_HEADER,
} from 'constants/manage-templates/display-tempates';
import { ROUTES } from 'constants/routes';
import React from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import AppHeaderWrapper from 'components/common/AppHeader/AppHeaderWrapper';
import { ScaffoldCard } from 'components/common/scaffold-card';
import styles from './manage-templates.module.css';

const ManageTemplates = () => {
  const { pathname } = useLocation();

  return (
    <AppHeaderWrapper className={styles.wrapper}>
      <ScaffoldCard className={styles.container}>
        <header className={styles.header_container}>
          <h3>
            {pathname === ROUTES.MANAGE_TEMPLATES &&
              DISPLAY_TEMPLATES_PAGE_HEADER}
            {pathname === ROUTES.CREATE_TEMPLATES &&
              CREATE_TEMPLATES_PAGE_HEADER}
            {pathname === ROUTES.EDIT_TEMPLATES && EDIT_TEMPLATES_PAGE_HEADER}
          </h3>
          <span>
            {pathname === ROUTES.MANAGE_TEMPLATES &&
              DISPLAY_TEMPLATES_PAGE_DESCRIPTION}
            {pathname === ROUTES.CREATE_TEMPLATES &&
              DISPLAY_TEMPLATES_PAGE_DESCRIPTION}
            {pathname === ROUTES.EDIT_TEMPLATES &&
              EDIT_TEMPLATES_PAGE_DESCRIPTION}
          </span>
        </header>
      </ScaffoldCard>
      <div className={styles.outlet_container}>
        <Outlet />
      </div>
    </AppHeaderWrapper>
  );
};

export default ManageTemplates;
