/* eslint-disable max-len */
import createInstance from 'Config/AxiosConfig';
import { useQuery } from 'react-query';
import { INSIGHTS_360_ENDPOINTS } from 'constants/services';

export const getGeneratedSQL = async (dataSetId, linkedId) => {
  const instance = await createInstance();
  const response = await instance.get(
    `${INSIGHTS_360_ENDPOINTS?.GET_GENERATED_SQL}?dataset_id=${dataSetId}&id=${linkedId}`,
  );

  console.log('get-generated-sql', response);

  if (response?.data?.statusCode !== '404') {
    return response?.data?.data;
  }
  throw new Error('Failed to fetch the sql ');
};

// Keep Query Value as File Name to maintain consistency and uniqueness in query index
export const GET_GENERATED_SQL = 'get-generated-sql';

// Query Config Options
const queryOptions = {
  staleTime: 1 * 60 * 1000,
  enabled: false,
  retry: 10,
};

// QUERY
export const useGetGeneratedSQL = (dataSetId, linkedId) =>
  useQuery(
    [GET_GENERATED_SQL, dataSetId, linkedId],
    () => getGeneratedSQL(dataSetId, linkedId),
    queryOptions,
  );
