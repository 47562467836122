/* eslint-disable react/react-in-jsx-scope */
import { useNavigate } from 'react-router-dom';
import { ScaffoldCard } from 'components/common/scaffold-card';
import { Divider } from 'components/common/divider';
import RuleOutlinedIcon from '@mui/icons-material/RuleOutlined';
import ChecklistOutlinedIcon from '@mui/icons-material/ChecklistOutlined';
import { useDashboardContext } from 'context/dashboard/DashboardContext';
import styles from './market-card.module.css';

function MarketCardResearchInfo() {
  const navigate = useNavigate();
  const { mrrProgress } = useDashboardContext();

  return (
    <ScaffoldCard className={styles.marketcard_container}>
      <span className={styles.marketcard_header}>Market Research Info</span>
      <Divider />
      {mrrProgress &&
        mrrProgress.map((category) => (
          <div className={styles.market_level_container}>
            <span
              className={styles.category_name}
              onClick={() => navigate(category?.link)}
            >
              {category?.key} - <b>{category?.value}%</b>
            </span>
            {category?.value === 100 ? (
              <ChecklistOutlinedIcon style={{ color: 'var(--active-color)' }} />
            ) : (
              <RuleOutlinedIcon style={{ color: 'var(--danger-color)' }} />
            )}
          </div>
        ))}
    </ScaffoldCard>
  );
}

export default MarketCardResearchInfo;
