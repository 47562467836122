/* eslint-disable no-unreachable */
import { useNavigate, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import fetchAllTemplates from 'Component/Pages/ManageTemplates/manageTemplatesLogic';
import {
  ButtonPrimary,
  ButtonSecondary,
  Divider,
  Flex,
  HeadingDesc,
  HeadingName,
} from 'Component/Shared/GlobalStyles';
import { Input } from 'antd';
import { LoaderWrapper } from 'Component/Pages/ManageTemplates/styles';
import LoaderSpin from 'Component/Shared/Loader';
import { toast, ToastContainer } from 'react-toastify';
import { sleep } from 'Component/Shared/utils';
import MinifiedLayout from 'Component/Shared/BaseLayout/MinifiedLayout';
import { CreditManage } from 'Component/Shared/CreditManager';
import { showNotification } from 'libs/notification';
import { MANAGE_TEMPLATES_FILTER_OPTIONS } from 'components/constants';
import { updateWorkspaceDetails } from '../../../../../redux';
import createNewWorkspace from '../CreateNewWorkspace/CreateNewWorkspaceLogic';
import { startOnboarding } from './confuguration.service';
import styles from './index.module.css';
import { sortTemplates } from './template-selector-helper';
import TemplateCard from './components/TemplateCard';

const PRE_SELECTED_TEMPLATE_INDEX = [0, 1, 2, 3, 4];

function Tab({ tab, setTab, tabName }) {
  return (
    <small
      style={{
        fontSize: '14px',
        color: tab === tabName ? '#fff' : '#000',
        fontWeight: tabName === 'Hypnotic-NLP' ? 'bold' : 'normal',
      }}
      className={`btn btn-sm btn-outline-secondary ${
        tab === tabName ? 'active' : ''
      }`}
      onClick={() => setTab(tabName)}
    >
      {tabName}
    </small>
  );
}

const { Search } = Input;

const TEMPLATE_COST = 800;

const TableColumn = ({
  index,
  name,
  description,
  selectedIndexes,
  handleTemplateSelect,
}) => (
  <TemplateCard
    name={name}
    descrption={description}
    onBtnClick={() => {
      handleTemplateSelect((prev) => {
        if (!selectedIndexes.includes(index)) {
          return [...prev, index];
        }
        return prev.filter((val) => val !== index);
      });
    }}
    isSelected={selectedIndexes.includes(index)}
  />
);

const TemplateSelector = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [privateTemplates, setPrivateTemplates] = useState([]);
  const [publicTemplates, setPublicTemplates] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [privateTemplatesIndexs, setPrivateTemplatesIndexs] = useState([
    ...PRE_SELECTED_TEMPLATE_INDEX,
  ]);
  const [publicTemplatesIndexs, setPublicTemplatesIndexs] = useState([]);
  const [isLoadingTemplates, setIsLoadingTemplates] = useState(true);
  const [isCreatingWorkspace, setIsCreatingWorkspace] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [tab, setTab] = useState('');

  const ErrorToastProps = {
    autoClose: 3000,
    position: 'bottom-center',
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    theme: 'colored',
  };

  const loadTemplates = () => {
    fetchAllTemplates()
      .then((data) => {
        if (data.error) {
          toast.error("Couldn't Load Private Templates", ErrorToastProps);
          return;
        }
        if (data.length > 0) {
          const sortedTemplates = sortTemplates([...data]);
          setPrivateTemplates([...sortedTemplates]);
        }
      })

      .finally(() => setIsLoadingTemplates(false));
  };

  const handleCreatePersona = async (default_templates) => {
    const { success } = await CreditManage(
      TEMPLATE_COST * default_templates.length,
      true,
    );

    if (!success) {
      showNotification('Error', 'Credits Expired');
      return;
    }

    setIsCreatingWorkspace(true);
    const {
      newWorkspaceData,
      onboardingData,
      workpaceDetails,
    } = location.state;

    if (location?.state?.newWorkspace) {
      const data = await createNewWorkspace(newWorkspaceData);
      if (data.error) return;
    }
    startOnboarding({ ...onboardingData, default_templates });

    dispatch(
      updateWorkspaceDetails({
        workspaces: {
          _currentWorkspace: {},
        },
        [newWorkspaceData.name]: workpaceDetails,
      }),
    );
    await sleep(10 * 1000);
    setIsCreatingWorkspace(false);
    CreditManage(TEMPLATE_COST * default_templates.length);
    navigate(
      `/build-workspace-status-detailed?workspace=${newWorkspaceData.name}`,
    );
  };

  const handleTemplateSelect = () => {
    const publicTemp = publicTemplatesIndexs.map((temp) => ({
      template_id: publicTemplates[temp].id,
      template_type: 'public',
    }));

    const privateTemp = privateTemplatesIndexs.map((temp) => ({
      template_id: privateTemplates[temp].template_id,
      template_type: 'private',
      from_support:
        privateTemplates[temp].username === 'support@quantamixsolutions.com',
    }));
    const default_templates = [...privateTemp, ...publicTemp];

    if (default_templates.length === 0) {
      toast.error('Select Atleast One Template', ErrorToastProps);
      return;
    }

    if (default_templates.length > 10) {
      toast.error('Select Upto 10 Templates', ErrorToastProps);
      return;
    }

    handleCreatePersona([...privateTemp, ...publicTemp]);
  };

  const handleTabFilter = (template) => {
    const key = tab.trim().toLowerCase();

    if (key === '' || key === 'All') return true;

    if (
      !!template.blog_name &&
      template.blog_name.toLowerCase().includes(key)
    ) {
      return true;
    }

    if (
      !!template.info_description &&
      template.info_description.toLowerCase().includes(key)
    ) {
      return true;
    }

    if (
      !!template.template_name &&
      template.template_name.toLowerCase().includes(key)
    ) {
      return true;
    }

    if (
      !!template.template_description &&
      template.template_description.toLowerCase().includes(key)
    ) {
      return true;
    }

    return false;
  };

  const handleFilter = (template) => {
    const key = searchKey.trim().toLowerCase();
    if (key === '') return true;

    if (
      !!template.blog_name &&
      template.blog_name.toLowerCase().includes(key)
    ) {
      return true;
    }

    if (
      !!template.info_description &&
      template.info_description.toLowerCase().includes(key)
    ) {
      return true;
    }

    if (
      !!template.template_name &&
      template.template_name.toLowerCase().includes(key)
    ) {
      return true;
    }

    if (
      !!template.template_description &&
      template.template_description.toLowerCase().includes(key)
    ) {
      return true;
    }

    return false;
  };

  const handleSearch = (e) => {
    setSearchKey(e.target.value);
  };

  useEffect(() => {
    if (!location?.state) {
      navigate('new-workspace-configuration');
      return;
    }
    loadTemplates();
  }, []);

  return (
    <MinifiedLayout>
      <ToastContainer />
      <HeadingName>
        {location?.state?.newWorkspaceData?.name} - Select Templates
      </HeadingName>
      <HeadingDesc>
        Please select one or more components to use in creating your new
        workspace.
      </HeadingDesc>
      <Divider />
      <Flex
        direction={'column'}
        className={`${styles.parent} container shadow`}
        gap={'0.5rem'}
      >
        <Flex direction={'column'}>
          <Flex direction={'column'}>
            <Flex className="my-1" align={'center'}>
              {/* <ControlPointRoundedIcon /> */}
              <span className={styles.sub_head_desc}>
                Select one or more templates
              </span>
            </Flex>
          </Flex>
          <Flex
            direction={'column'}
            style={{
              height: '75vh',
              overflow: 'scroll',
            }}
          >
            <Flex flex={1} direction={'column'}>
              <Flex
                gap={'0.5rem'}
                className={styles.table_header}
                align={'center'}
              >
                <Flex flex={2} />
                <Flex flex={1}>
                  <Search
                    placeholder="Search Templates"
                    onChange={handleSearch}
                    value={searchKey}
                  />
                </Flex>
              </Flex>
            </Flex>

            <Flex justify={'center'} style={{ marginTop: '10px' }}>
              <div
                className={'btn-group btn-group-toggle'}
                style={{
                  padding: '0 1rem',
                  flexWrap: 'wrap',
                }}
              >
                {MANAGE_TEMPLATES_FILTER_OPTIONS.map((TAB, tabIndex) => (
                  <Tab
                    key={tabIndex + TAB}
                    tab={tab}
                    setTab={setTab}
                    tabIndex={tabIndex}
                    tabName={TAB}
                  />
                ))}
              </div>
            </Flex>

            {isLoadingTemplates && (
              <LoaderWrapper>
                <LoaderSpin />
              </LoaderWrapper>
            )}

            <Flex justify={'flex-end'}>
              <Flex flex={1} />
            </Flex>
            {currentTab === 0 && (
              <Flex
                flex={8}
                wrap={'wrap'}
                gap={'0.5rem'}
                justify={'center'}
                className={styles.card_container}
              >
                {privateTemplates
                  .filter(handleFilter)
                  .filter(handleTabFilter)
                  .map((template, index) => (
                    <TableColumn
                      name={template.template_name}
                      description={template.template_description}
                      index={template.index}
                      key={index}
                      selectedIndexes={privateTemplatesIndexs}
                      handleTemplateSelect={setPrivateTemplatesIndexs}
                    />
                  ))}
              </Flex>
            )}
            {currentTab === 1 && (
              <Flex
                flex={8}
                wrap={'wrap'}
                gap={'0.5rem'}
                justify={'center'}
                className={styles.card_container}
              >
                {publicTemplates
                  .filter(handleFilter)
                  .filter(handleTabFilter)
                  .map((template, index) => (
                    <TableColumn
                      name={template.blog_name}
                      description={template.info_description}
                      index={template.index}
                      key={index}
                      selectedIndexes={publicTemplatesIndexs}
                      handleTemplateSelect={setPublicTemplatesIndexs}
                    />
                  ))}
              </Flex>
            )}

            <Flex flex={1}>
              <Flex
                flex={1}
                className="py-3"
                justify={'space-between'}
                align={'center'}
                style={{
                  height: '60px',
                }}
              >
                <ButtonSecondary onClick={() => navigate(-1)}>
                  Back
                </ButtonSecondary>
                <Flex direction={'column'} align={'center'}>
                  <p className="font-sm font-primary mb-0">Credits</p>
                  <h6 className="font-sm font-primary">
                    {TEMPLATE_COST *
                      (publicTemplatesIndexs.length +
                        privateTemplatesIndexs.length)}
                  </h6>
                </Flex>
                {!isCreatingWorkspace ? (
                  <ButtonPrimary onClick={handleTemplateSelect}>
                    <span className="mr-2"> Create Persona</span>
                  </ButtonPrimary>
                ) : (
                  <ButtonPrimary>
                    <LoaderSpin height={'30px'} width={'50px'} />
                  </ButtonPrimary>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </MinifiedLayout>
  );
};

export default TemplateSelector;
