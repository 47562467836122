/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SignUpFreeTrial.css';
import ReactPlayer from 'react-player';

// assets
import OfferLogo from 'assets/offer.png';
import AmazonLogo from 'assets/images/amazonLogo.png';
import CrawlQLogo from 'assets/images/crawlQ.png';
import DynataLogo from 'assets/images/dynataLogo.png';
import GartnerLogo from 'assets/images/gartner.svg';
import Googlelogo from 'assets/images/googleLogo.svg';
import IqviaLogo from 'assets/images/iqviaLogo.png';
import KabbaxLogo from 'assets/images/KabbaxLogo.png';
import LeanCaseLogo from 'assets/images/leancaseLogo.png';
import LoaderSpin from 'Component/Shared/Loader';

// Logic Fn
import RegisterUserRequest from './SignUpFreeTrialLogic';

function FreeTrialRegister() {
  const navigate = useNavigate();
  const passwordRegex = /(?=^.{8,}$)(?=.{0,}[A-Z])(?=.{0,}[a-z])(?=.{0,}\W)(?=.{0,}\d)/g;

  const [users, setUsers] = useState({
    email: '',
    cpassword: '',
    password: '',
    code: '',
    firstName: '',
    lastName: '',
  });

  const [UsersDetailsEmpty, setUsersDetailsEmpty] = useState(false);
  const [IsError, setIsError] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState('');
  const [IsRegsitering, setIsRegsitering] = useState(false);
  const [SignUpLoader, setSignUpLoader] = useState(false);
  const [SignUpSuccessfull, setSignUpSuccessfull] = useState(false);

  const handleInput = (e) => {
    setIsError(false);
    setErrorMessage();
    setUsersDetailsEmpty(false);
    setIsRegsitering(false);

    const { name, value } = e.target;

    setUsers({
      ...users,
      [name]: value,
    });
  };

  const handleRegisterUserCheck = async () => {
    setSignUpLoader(true);
    setIsRegsitering(true);

    if (
      users.firstName === '' ||
      users.lastName === '' ||
      users.email === '' ||
      users.password === '' ||
      users.cpassword === ''
    ) {
      setUsersDetailsEmpty(true);
      setSignUpLoader(false);
    } else if (users.password !== users.cpassword) {
      setIsError(true);
      setErrorMessage('Passwords do not match');
      setSignUpLoader(false);
    } else if (!passwordRegex.test(users.password)) {
      setIsError(true);
      setErrorMessage(
        'Password should be atleast of 8 characters with atleast a 1 uppercase, 1 special characters and 1 digit',
      );
      setSignUpLoader(false);
    } else {
      setIsError(false);
      setUsersDetailsEmpty(false);
      setErrorMessage('');

      const name = `${users.firstName} ${users.lastName}`;
      const { email } = users;
      const { password } = users;

      await handleRegsiterUser(name, email, password);
    }
  };

  const handleRegsiterUser = async (name, email, password) => {
    const result = await RegisterUserRequest(name, email, password);

    if (result.error === true) {
      setIsError(true);
      setErrorMessage(result.message);
      setSignUpLoader(false);
    } else {
      localStorage.setItem('group_name', result.group_name);

      // Loader stops and signup successful to open verify email
      setSignUpLoader(false);
      localStorage.setItem('username', email);
      navigate('/verify-email', {
        state: { email: users.email, password: users.password },
      });
    }
  };

  return (
    <div className="signup-free-container">
      <div className="signup_left_container">
        <div className="left_container_box">
          <div className="logo-container">
            <img className="logo" src={CrawlQLogo} alt="CrawlQ Logo" />
            <h5>Unlock 6 Months of Research in Just 3 Minutes</h5>
            <span>The Ultimate Solution for High-RoI Business Outcomes</span>
          </div>

          <div className="input_container">
            <h3>
              <b>Create an account </b>
            </h3>
            <div className="double-box">
              <div className="input_field_container">
                <input
                  type="text"
                  name="firstName"
                  className="input"
                  value={users.firstName}
                  onChange={handleInput}
                  placeholder="First Name"
                />
              </div>
              <div className="input_field_container">
                <input
                  type="text"
                  name="lastName"
                  className="input"
                  value={users.lastname}
                  onChange={handleInput}
                  placeholder="Last Name"
                />
              </div>
            </div>

            <div className="single-box">
              <div className="input_field_container">
                <input
                  type="text"
                  name="email"
                  className="input"
                  value={users.email}
                  onChange={handleInput}
                  placeholder="Email Address"
                />
              </div>
            </div>

            <div className="double-box">
              <div className="input_field_container">
                <input
                  type="password"
                  name="password"
                  className="input"
                  value={users.password}
                  onChange={handleInput}
                  placeholder="Password"
                />
              </div>
              <div className="input_field_container">
                <input
                  type="password"
                  name="cpassword"
                  className="input"
                  value={users.cpassword}
                  onChange={handleInput}
                  placeholder="Confirm Password"
                />
              </div>
            </div>

            {SignUpLoader ? (
              <button style={{ backgroundColor: 'white' }}>
                <LoaderSpin width="20" height="20" />
              </button>
            ) : (
              <button className="signup-btn" onClick={handleRegisterUserCheck}>
                Sign Up
              </button>
            )}

            {IsError && ErrorMessage ? (
              <p className="error-message">{ErrorMessage}</p>
            ) : (
              <p> </p>
            )}
            {IsRegsitering && UsersDetailsEmpty ? (
              <p className="error-message">Fill All Details </p>
            ) : (
              <p> </p>
            )}

            <span className="account-exists">
              Already have an account ?<a href="/">Login</a>
            </span>
          </div>
        </div>
      </div>

      <div className="signup_right_container">
        <div className="signup-right-headers">
          <h3>Discover Subconscious Mindstates Of Your Audience</h3>
          <h3>Activate Your 7-Day Free Trial</h3>
        </div>

        {/* <img src={OfferLogo} /> */}
        {/* <div className="logo-containers">
          <img src={Googlelogo} alt="" />
          <img style={{ height: '5rem', width: '7rem' }} src={DynataLogo} alt="" />
          <img style={{ height: '5rem', width: '9rem' }} src={IqviaLogo} alt="" />
          <img style={{ height: '6rem', width: '9rem' }} src={KabbaxLogo} alt="" />

          <img style={{ height: '5rem', width: '10rem' }} src={LeanCaseLogo} alt="" />
          <img style={{ height: '5rem', width: '10rem' }} src={GartnerLogo} alt="" />
          <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" alt="" />
          <img style={{ height: '3rem', width: '10rem' }} src={AmazonLogo} alt="" />

        </div> */}
        <ReactPlayer
          className="signup-video"
          url="https://general-crawlq-videos.s3.us-east-2.amazonaws.com/Athena-Advance+Ai+Assistant.mp4"
          playing
          controls
          loop
          muted
          config={{ file: { attributes: { controlsList: 'nodownload' } } }}
        />

        <div className="reviews-container">
          <div className="review-box">
            <div className="review-box-top">
              <img
                className="review-img"
                src="https://i0.wp.com/crawlq.ai/wp-content/uploads/2023/08/1643815209108.jpeg?fit=800%2C800&ssl=1"
                alt="reviewer"
              />
              <div className="review-box-top-info">
                <div className="review-box-top-info-name">
                  Jennifer Anastasi
                </div>
                <div className="review-box-top-info-pro">
                  Brand and Marketing Strategist
                </div>
              </div>
            </div>

            <div className="review-box-text">
              I made more progress in 30 minutes than i&apos;ve made in the last
              5 years.
            </div>
          </div>

          <div className="review-box">
            <div className="review-box-top">
              <img
                className="review-img"
                src="https://i0.wp.com/crawlq.ai/wp-content/uploads/2023/08/1517412003060-1.jpeg?fit=281%2C281&ssl=1"
                alt="reviewer"
              />
              <div className="review-box-top-info">
                <div className="review-box-top-info-name">Sam Broom</div>
                <div className="review-box-top-info-pro">
                  Experienced Digital Marketing Manager & Strategist,Agriconnect
                </div>
              </div>
            </div>

            <div className="review-box-text">
              This tool has masses of potential...you will pretty much get an
              entire content marketing strategy and brand identity kit.
            </div>
          </div>

          <div className="review-box">
            <div className="review-box-top">
              <img
                className="review-img"
                src="https://i0.wp.com/crawlq.ai/wp-content/uploads/2023/08/749b076603b12abea38fbc0a961de232.jpeg?fit=100%2C100&ssl=1"
                alt="reviewer"
              />
              <div className="review-box-top-info">
                <div className="review-box-top-info-name">Elma Pena</div>
                <div className="review-box-top-info-pro">
                  Marketing Strategist | Business Start-Up and Scale-Up | Online
                  Reputation Management
                </div>
              </div>
            </div>

            <div className="review-box-text">
              CrawlQ is the only app that I know that helps me do market
              research. For someone like me who used to spend $$$$$ on market
              research, this is the best that can replace that.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FreeTrialRegister;
