import CustomTooltip from 'Component/Shared/CustomTooltip';
import {
  LoaderTailSpin,
  PrimaryButton,
  ShareIcon,
  SecondaryButton,
} from 'components/common';
import React, { useState } from 'react';
import Modal from 'react-modal';
import { showNotification } from 'libs/notification';
import { validateEmail } from 'utils/helpers';
import styles from './model-styles.module.css';
import { shareAssistantWithEmail } from '../assistant.service';

const customStyles = {
  content: {
    width: '30rem',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const ShareAssistant = ({ isPublic, assistantId }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [sharingAssistant, setSharingAssistant] = useState(false);
  const [otherUserEmail, setOtherUserEmail] = useState('');

  const handleChange = (e) => {
    setOtherUserEmail(e.target.value);
  };

  const handleShare = async () => {
    if (isPublic) return;
    if (!validateEmail(otherUserEmail)) {
      showNotification('error', 'Invalid Email Id.');
      return;
    }
    setSharingAssistant(true);
    const isShared = await shareAssistantWithEmail(assistantId, otherUserEmail);
    if (isShared) {
      showNotification('success', 'Assistant Shared Successfully.');
      setModalIsOpen(false);
    }
    setSharingAssistant(false);
  };

  const handleModalOpen = () => {
    if (isPublic) return;
    setModalIsOpen(true);
  };

  return (
    <div>
      <CustomTooltip
        placement="top"
        title={
          isPublic
            ? 'Assistant is already shared with publicly'
            : 'Share Assistant'
        }
        arrow
      >
        {isPublic ? (
          <ShareIcon disabled />
        ) : (
          <ShareIcon onClick={handleModalOpen} primary="true" />
        )}
      </CustomTooltip>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={customStyles}
      >
        <div className={styles.modal_container}>
          <h5>Share Assistant</h5>
          <input
            onChange={handleChange}
            className={styles.input}
            value={otherUserEmail}
            placeholder="Enter Email Id CrawlQ User"
          />
          <div className={styles.buttons_group}>
            <SecondaryButton
              onClick={() => setModalIsOpen(false)}
              className={styles.button}
            >
              Close
            </SecondaryButton>
            <PrimaryButton className={styles.button} onClick={handleShare}>
              Share
              {sharingAssistant && <LoaderTailSpin height={20} width={20} />}
            </PrimaryButton>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ShareAssistant;
