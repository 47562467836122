import React from 'react';
import FlyoutModal from 'Component/Shared/FlyoutModal';
import DualActionView from 'components/common/DualActionView';
import { HeadingText, PrimaryBtnText, SecondaryBtnText } from 'Component/Pages/ContentAutomation/PublishedArticles/components/constant';

export function DeleteBlogModal({
  isDeleteModalOpen,
  handleCloseDeleteModal,
  handleDelete,
  blogName,
  processing = false,
}) {
  return (
    <FlyoutModal
      isOpen={isDeleteModalOpen}
      requestClose={handleCloseDeleteModal}
    >
      <DualActionView
        headingText={HeadingText}
        secondaryBtnText={SecondaryBtnText}
        primaryBtnText={PrimaryBtnText}
        primaryAction={handleDelete}
        secondaryAction={handleCloseDeleteModal}
        processing={processing}
      >
        <p>Following blog will be deleted</p>
        <ul>
          <li>{blogName}</li>
        </ul>
      </DualActionView>
    </FlyoutModal>
  );
}
