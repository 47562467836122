import React from 'react';
import './table.css';

export const TableHeader = ({ headers }) => (
  <div className="table-header-container h-margin-b-small">
    {headers.map((header) => (
      <span
        key={header}
        className={`table-header ${header}`}
        onClick={() => console.log(header)}
      >
        {header}
      </span>
    ))}
  </div>
);

export const TableRow = ({
  value,
  icon,
  outerClass = '',
  innerClass = '',
  children,
  onClick,
}) => (
  <div className={`table-content-box ${outerClass}`} onClick={onClick}>
    {icon}
    {value && <span className={innerClass}>{value}</span>}
    {children}
  </div>
);
