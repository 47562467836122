/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */

/* eslint-disable import/prefer-default-export */

import { SET_ACTIVE_TAB, ADD_NEW_TAB, REMOVE_SELECTED_TAB, SET_TABS_EMPTY } from './TabManagerTypes';

export const addNewTab = (newTab) => ({
  type: ADD_NEW_TAB,
  payload: newTab,
}

// payload : {
//   id:'unique id'
//   name: 'tab-name',
//   workspaceName: 'active-workspace-name-for-that-tab', OPTIONAL
//   path:'component-route',
// }
);

export const setActiveTab = (activeTab) => ({
  type: SET_ACTIVE_TAB,
  payload: activeTab,
});

export const removeTab = (selectedTabId) => ({
  type: REMOVE_SELECTED_TAB,
  payload: selectedTabId,
});

export const setTabsEmpty = () => ({
  type: SET_TABS_EMPTY,
});
