import React, { useState, useEffect } from 'react';
import Button, { TabSwitcheWrapper, TabContainer } from './styles';

function TabSwitcher({ tab1, tab2, setSwitcher, outerClass, currentActiveTab }) {
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    if (currentActiveTab) {
      setCurrentTab(currentActiveTab);
    }
  }, []);

  const handleTabSwitch = (tab) => {
    setCurrentTab(tab);
    setSwitcher(tab);
  };
  return (
    <TabContainer className={outerClass}>
      <TabSwitcheWrapper>
        <Button onClick={() => handleTabSwitch(0)} active={currentTab === 0}>
          {tab1}
        </Button>
        <Button onClick={() => handleTabSwitch(1)} active={currentTab === 1}>
          {tab2}
        </Button>
      </TabSwitcheWrapper>
    </TabContainer>
  );
}

export default TabSwitcher;
