/* eslint-disable default-case */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState, createContext } from 'react';
import {
  CATEGORY_DESCRIPTION,
  NEW_BRAND_VOICE,
  SAVED_BRAND_VOICES,
  TABS,
} from 'components/constants';
import { TabContentWrapper, TagStatus } from './brand-voice-elements/styles';
// eslint-disable-next-line import/no-cycle
import NewBrandVoice from './brand-voice-elements/NewBrandVoice';
import SavedBrandVoices from './brand-voice-elements/SavedBrandVoices';
import CategoryDescription from './brand-voice-elements/CategoryDescription';

export const TabContext = createContext();

function SwitchChoosenTab({ choosenTab }) {
  switch (choosenTab) {
    case NEW_BRAND_VOICE:
      return <NewBrandVoice />;
    case SAVED_BRAND_VOICES:
      return <SavedBrandVoices />;
    case CATEGORY_DESCRIPTION:
      return <CategoryDescription />;
  }
}

function BrandVoice() {
  const [choosenTab, setChoosenTab] = useState(TABS[0]);

  const StatusTab = () =>
    TABS.map((status) => (
      <TagStatus
        active={choosenTab === status ? 'active-tag' : ''}
        key={status}
        onClick={() => setChoosenTab(status)}
      >
        {status}
      </TagStatus>
    ));

  return (
    <TabContext.Provider value={setChoosenTab}>
      <div className="global-d-flex-col">
        <div className="global-d-flex-row h-margin-b-small h-padding-b-small">
          <StatusTab />
        </div>

        <div style={{ height: '85vh' }} className="global-d-flex-row">
          <TabContentWrapper style={{ flex: 1, padding: '1rem' }}>
            <SwitchChoosenTab choosenTab={choosenTab} />
          </TabContentWrapper>
        </div>
      </div>
    </TabContext.Provider>
  );
}

export default BrandVoice;
