/* eslint-disable radix */
/* eslint-disable no-redeclare */
/* eslint-disable brace-style */
/* eslint-disable block-scoped-var */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-useless-escape */
/* eslint-disable no-promise-executor-return */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import React, { useEffect, useState } from 'react';
import { FormControl, Select } from '@mui/material';
import { ThumbUpAltOutlined } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import axios from 'axios';
import Modal from 'react-modal';
import ReactTooltip from 'react-tooltip';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import { AddOn } from 'Component/Shared/AddOnModal/AddonModal';
import { CreditManage } from 'Component/Shared/CreditManager';
import createInstance from 'Config/AxiosConfig';
import { useDispatch, useSelector } from 'react-redux';
import { userWorkspaceSetAthenaSources, workspaceSelect } from 'redux/index';
import coin from '../../../../assets/images/coins.svg';
import Trainer from '../../../../assets/images/train.gif';
import CreditUpdater from '../../../Shared/CreditManager';
import LoaderSpin from '../../../Shared/Loader';
import { getListOfCountries } from '../../ContentAutomation/AIcontentWriter/AIcontentLogic';
// import ThumbUpAltIcon from '';

import CustomTooltip, { CustomTooltipMTI } from '../../../Shared/CustomTooltip';
import UpgradeEntryplan from '../../../Shared/UpdagradeEntryPlan/UpgradeEntryplan';
import { getAthenaSources } from '../dashboardLogic';
import LearnMore from '../LearnMore/LearnMore';
import TrainedURLs from '../TrainedURL';
import './TrainAthena.css';

import { TRAIN_ATHENA_COST } from 'Component/Shared/featuresCreditsCost';
import moment from 'moment';
import HelperVideoModalPopup from 'Component/Shared/HelperVideoPopUp/HelperVideoPop';
import { useGetUsersAddOns } from 'queries/user-account/services/get-user-addons';

const TrainAthenaAPI_URL = 'https://apis.crawlq.ai/test-semantic';

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function TrainAthena({
  heading,
  smallDesc,
  showStatus,
  progressPercentage = 150, // kept it more than hundred(150) for case of calling tain athena modal from mrr after completing top 4
  search_tooltip,
}) {
  const axiosConfig = {
    multiValueHeaders: {
      'Content-Type': ['application/json'],
      'Access-Control-Allow-Origin': ['*'],
      'Access-Control-Allow-Headers': [
        'Content-Type',
        'X-Amz-Date',
        'Authorization',
        'X-Api-Key',
        'X-Amz-Security-Token',
      ],
    },
  };

  const customStyles = {
    content: {
      width: '600px',
      height: 'max-content',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const customStylesLearnMore = {
    content: {
      height: 'fit-content',
      maxWidth: '900px',
      maxHeight: '700px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      overflowY: 'auto',
    },
  };

  const customStylesTrainURL = {
    content: {
      width: '600px',
      height: '300px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const dispatch = useDispatch();

  const {
    data: addOnData,
    isLoading: isLoadingAddonData,
  } = useGetUsersAddOns();

  const CURRENT_WORKSPACE = useSelector((state) => state.workspace);
  const [AddUrl, setAddUrl] = useState(false);
  const [urlValue, seturlValue] = useState('');
  const [UrlList, setUrlList] = useState([]);
  const [urlError, seturlError] = useState(false);
  const [IsTraining, setIsTraining] = useState(false);
  const [modalPop, setmodalPop] = useState(false);
  const [TrainSuccess, setTrainSuccess] = useState(false);
  const [TrainFail, setTrainFail] = useState(false);
  const [TrainYes, setTrainYes] = useState(false);
  const [CreditFail, setCreditFail] = useState(false);
  const [SearchIntentCheck, setSearchIntentCheck] = useState(false);
  const [quoraCheck, setQuoraCheck] = useState(false);
  const [redditCheck, setRedditCheck] = useState(false);
  const [SerpResultCounter, setSerpResultCounter] = useState(1);
  const [Countries, setCountries] = useState();
  const [SelectedCountry, setSelectedCountry] = useState('US');
  const [UrlSuccessFail, setUrlSuccessFail] = useState([]);
  const [UrlListLength, setUrlListLength] = useState(1);
  const [failedFeilds, setFailedFeilds] = useState([]);
  const [hasTrainComplete, setHasTrainComplete] = useState(false);
  const [learnMoreOpen, setLearnMoreOpen] = useState(false);
  const [showTrainUrls, setShowTrainUrls] = useState(false);
  const [isEntryPlan, setIsEntryPlan] = useState(false);
  const userPlan = useSelector((state) => state.user.plan);
  const user = useSelector((state) => state.user);
  const workspace = useSelector((state) => state.workspace);
  const [addOnLevel, setAddOnLevel] = useState(0);
  const [isLoadingQuora, setIsLoadingQuora] = useState(false);
  const [isLoadingReddit, setIsLoadingReddit] = useState(false);
  const [AthenaTrainedTime, setAthenaTrainedTime] = useState('');

  const athenaLastTrainedTime = CURRENT_WORKSPACE?.last_trained;
  const local_moment_time = moment.utc(athenaLastTrainedTime).local();

  useEffect(() => {
    const plansToBeBlocked = [
      'CrawlQ Basic Plan (Monthly)',
      'CrawlQ Basic Plan (Yearly)',
      'CrawlQ Entry Plan (Monthly)',
      'CrawlQ Entry Plan (Yearly)',
      'CrawlQ Free Trial',
    ];
    if (plansToBeBlocked.includes(userPlan)) {
      setIsEntryPlan(true);
    }
    FetchCountries();
  }, []);

  useEffect(() => {
    if (hasTrainComplete) {
      const current_time = moment().format('LLLL');
      const current_utc_time = moment.utc().format('LLLL');
      setAthenaTrainedTime(current_time);
      dispatch(
        workspaceSelect({
          ...CURRENT_WORKSPACE,
          last_trained: current_utc_time,
        }),
      );
    } else {
      setAthenaTrainedTime(local_moment_time.format('LLLL'));
    }
  }, [TrainSuccess, hasTrainComplete]);

  const FetchCountries = async () => {
    const result = await getListOfCountries();
    setCountries(result);
    setSearchIntentCheck(true);
  };

  const handleRedditCheck = () => {
    setAddOnLevel(0);
    if (!isLoadingAddonData && addOnData) {
      if (addOnData['Reddit AddOn']?.status) {
        setRedditCheck((prev) => !prev);
        return;
      }
      const element = document.getElementById('addon_purchase');
      element.click();
    }
  };

  const handleQuoraCheck = () => {
    setAddOnLevel(1);
    if (!isLoadingAddonData && addOnData) {
      if (addOnData['Quora AddOn']?.status) {
        setQuoraCheck((prev) => !prev);
        return;
      }
      const element = document.getElementById('addon_purchase');
      element.click();
    }
  };

  const handleWithURL = () => {
    if (isEntryPlan) return;
    setUrlSuccessFail();
    const expression = /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/;
    const regex = new RegExp(expression);

    if (urlValue.match(regex)) {
      setUrlList((UrlList) => [...UrlList, urlValue]);
      seturlValue('');
      setUrlListLength((UrlListLength) => UrlListLength + 1);
    } else {
      seturlError(true);
    }
  };

  const handleURLInputBox = () => {
    if (isEntryPlan) {
      document.getElementById('upgradePlan-popup').click();
      return;
    }
    seturlValue('');
    seturlError(false);
    setAddUrl((AddUrl) => !AddUrl);
  };

  const handleInputURLChange = (e) => {
    seturlError(false);
    seturlValue(e.target.value);
  };

  const setAthenaSourcesInReduxStore = async () => {
    const sources = await getAthenaSources();

    dispatch(userWorkspaceSetAthenaSources(sources));
    if (!!sources && !!sources.train_option) {
      dispatch(workspaceSelect({ ...CURRENT_WORKSPACE, is_trained: true }));
    }
  };

  const handleTrainPop = async () => {
    const { success } = await CreditManage(TRAIN_ATHENA_COST, true);

    if (success) {
      setmodalPop(!modalPop);
      setTrainSuccess(false);
      setUrlSuccessFail([]);
      setTrainFail(false);
    } else {
      setCreditFail(true);
    }
  };

  const handleModal = () => {
    setmodalPop(!modalPop);
  };

  const handleSERPCounter = (e) => {
    if (e.target.value <= 1) {
      setSerpResultCounter(1);
      return;
    }
    if (e.target.value >= 40) {
      setSerpResultCounter(40);
      return;
    }
    setSerpResultCounter(e.target.value);
  };

  const ExtractSuccessURL = (urls) => {
    const successURLs = [];
    for (const key in urls) {
      console.log(urls[key]);
      if (urls[key] === 'Success') {
        successURLs.push(key);
      }
      if (urls[key] === 'Failed') {
        setFailedFeilds((prev) => {
          if (!prev.includes('URL')) {
            return [...prev, 'URL'];
          }
          return [...prev];
        });
      }
    }
    console.log(successURLs);
    return successURLs;
  };

  function URLFailOrPass() {
    if (UrlSuccessFail) {
      return (
        <div style={{ marginLeft: '0.3rem' }}>
          {/* <span style={{ fontSize: '0.9rem', marginBottom: '1rem' }}>URL Status</span> */}
          {UrlSuccessFail.map((url_values) => (
            <div>
              {url_values.status === 'Success' ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <ReactTooltip multiline />
                  <span
                    data-tip={`${url_values.status} <br/> ${url_values.url}`}
                    className="success-url"
                  >
                    {url_values.url}
                  </span>
                  <CheckCircleIcon style={{ color: '#3efd48' }} />
                </div>
              ) : (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <ReactTooltip multiline />
                  <span
                    data-tip={`${url_values.status} <br/> ${url_values.url}`}
                    className="fail-url"
                  >
                    {url_values.url}
                  </span>
                  <CancelIcon
                    style={{ color: '#ff6565', marginRight: '0.3rem' }}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      );
    }
    return <div>"no"</div>;
  }

  const ExtractSuccessfulUrl = (trained_url) => {
    let urls = [];
    console.log('logger', trained_url);
    for (const url in trained_url) {
      console.log('url', url);

      urls = [
        ...urls,
        {
          url,
          status: trained_url[url],
        },
      ];
    }

    console.log('urls', urls);
    setUrlSuccessFail(urls);
  };

  const CheckSearchIntentAndURLS = async (extractedURL) => {
    const body = {
      username: localStorage.getItem('username'),
      name: localStorage.getItem('name'),
      url: extractedURL,
      num: SerpResultCounter,
      cc: SelectedCountry,
    };

    const result2 = await axios.post(
      'https://apis.crawlq.ai/check_generator',
      body,
      axiosConfig,
    );

    const api_response = result2.data.body;

    // ExtractSuccessfulUrl(result2.data.body.url_status)

    if (api_response.data == true && api_response.serp_status == 'Success') {
      setTrainSuccess(true);
    } else {
      setTrainFail(true);
      const { success } = await CreditManage(-TRAIN_ATHENA_COST);
    }
    if (api_response.data === false) {
      setFailedFeilds((prev) => [...prev, 'Market Research']);
    }
    if (api_response.serp_status === 'Failed') {
      setFailedFeilds((prev) => [...prev, 'Search Intent']);
    }
  };

  const CheckSearchIntent = async () => {
    const body = {
      username: localStorage.getItem('username'),
      name: localStorage.getItem('name'),
      num: SerpResultCounter,
      cc: SelectedCountry,
    };

    const result2 = await axios.post(
      'https://apis.crawlq.ai/check_generator',
      body,
      axiosConfig,
    );

    const api_response = result2.data.body;

    if (api_response.data === true && api_response.serp_status === 'Success') {
      setTrainSuccess(true);
    } else {
      const { success } = await CreditManage(-TRAIN_ATHENA_COST);
      setTrainFail(true);
    }

    if (api_response.data === false) {
      setFailedFeilds((prev) => [...prev, 'Market Research']);
    }

    if (api_response.serp_status === 'Failed') {
      setFailedFeilds((prev) => [...prev, 'Search Intent']);
    }
  };

  const CheckURLS = async (extractedURL) => {
    const body = {
      username: localStorage.getItem('username'),
      name: localStorage.getItem('name'),
      url: extractedURL,
    };
    const result2 = await axios.post(
      'https://apis.crawlq.ai/check_generator',
      body,
      axiosConfig,
    );

    // console.log(result2)
    // console.log(result2.data.body.url_status)

    // ExtractSuccessfulUrl(result2.data.body.url_status)

    const api_response = result2.data.body;

    if (api_response.data === true) {
      setTrainSuccess(true);
    } else {
      setTrainFail(true);
    }
  };

  const CheckMarket = async () => {
    const body = {
      username: localStorage.getItem('username'),
      name: localStorage.getItem('name'),
    };

    const result2 = await axios.post(
      'https://apis.crawlq.ai/check_generator',
      body,
      axiosConfig,
    );

    const api_response = result2.data.body;

    if (api_response.data == true) {
      setTrainSuccess(true);
    } else {
      setTrainFail(true);
      const { success } = await CreditUpdater(-TRAIN_ATHENA_COST);
    }

    if (api_response.data === false) {
      setFailedFeilds((prev) => [...prev, 'Market Research']);
    }
  };

  const handleYes = async () => {
    const { success } = await CreditManage(TRAIN_ATHENA_COST);

    if (success) {
      setTrainYes(true);
      setFailedFeilds([]);

      //  Search Intent URLs and Market(default)
      if (SearchIntentCheck && UrlList.length > 0) {
        let serp_source = ['serp'];
        if (quoraCheck) {
          serp_source = [...serp_source, 'quora'];
        }

        if (redditCheck) {
          serp_source = [...serp_source, 'reddit'];
        }

        try {
          const url_body = {
            urls: UrlList,
            username: user.username,
            name: workspace.name,
          };
          console.log('heyya', url_body);

          const instance = await createInstance();
          const url_train_result = await instance.post(
            'url-qa-generator',
            url_body,
          );

          var extractedURL = ExtractSuccessURL(url_train_result.data.body);

          ExtractSuccessfulUrl(url_train_result.data.body);

          const body = {
            username: localStorage.getItem('username'),
            name: localStorage.getItem('name'),
            url: extractedURL,
            num: SerpResultCounter,
            cc: SelectedCountry,
            serp_source,
          };
          // console.log('body', body)

          const result = await axios.post(
            TrainAthenaAPI_URL,
            body,
            axiosConfig,
          );
          // const result = await axios.post(`https://apis.crawlq.ai/test-semantic`, body, axiosConfig)

          const api_response = result.data.body;
          // var extractedURL = ExtractSuccessURL(url_train_result.data.body)

          if (
            api_response.data === true &&
            api_response.serp_status === 'Success'
          ) {
            setTrainSuccess(true);
          } else {
            setTrainFail(true);
          }
          if (api_response.data === false) {
            setFailedFeilds((prev) => [...prev, 'Market Research']);
          }

          if (api_response.serp_status === 'Failed') {
            setFailedFeilds((prev) => [...prev, 'Search Intent']);
          }
        } catch (err) {
          console.log(err);
          try {
            await sleep(30 * 1000);
            console.log('waiting for 30sec');
            await CheckSearchIntentAndURLS(extractedURL);
          } catch (ex) {
            try {
              await sleep(30 * 1000);
              console.log('waiting for 30sec');

              await CheckSearchIntentAndURLS(extractedURL);
            } catch (ex) {
              try {
                await sleep(30 * 1000);
                console.log('waiting for 30sec');

                await CheckSearchIntentAndURLS(extractedURL);
              } catch (ex) {
                try {
                  await sleep(30 * 1000);
                  console.log('waiting for 30sec');

                  await CheckSearchIntentAndURLS(extractedURL);
                } catch (ex) {
                  setFailedFeilds((prev) => [
                    ...failedFeilds,
                    'Market Research',
                    'Search Intent',
                  ]);
                }
              }
            }
          }
        } finally {
          if (!showStatus) {
            setmodalPop(!modalPop);
          }
          await setAthenaSourcesInReduxStore();

          setTrainYes(false);
          setUrlList([]);
          setUrlListLength(1);
          setHasTrainComplete(true);
        }
      }
      // Search Intent and Market(default)
      else if (SearchIntentCheck) {
        let serp_source = ['serp'];
        if (quoraCheck) {
          serp_source = [...serp_source, 'quora'];
        }

        if (redditCheck) {
          serp_source = [...serp_source, 'reddit'];
        }
        try {
          const body = {
            username: user.username,
            name: workspace.name,
            num: SerpResultCounter,
            cc: SelectedCountry,
            serp_source,
            serp: true,
          };

          console.log(body);

          const result = await axios.post(
            TrainAthenaAPI_URL,
            body,
            axiosConfig,
          );

          const api_response = result.data.body;

          if (
            api_response.data === true &&
            api_response.serp_status === 'Success'
          ) {
            setTrainSuccess(true);
          } else {
            setTrainFail(true);
          }

          if (api_response.data === false) {
            setFailedFeilds((prev) => [...prev, 'Market Research']);
          }

          if (api_response.serp_status === 'Failed') {
            setFailedFeilds((prev) => [...prev, 'Search Intent']);
          }
        } catch (err) {
          console.log('timeout', err);
          try {
            await CheckSearchIntent();
          } catch (ex) {
            try {
              await CheckSearchIntent();
            } catch (ex) {
              try {
                await CheckSearchIntent();
              } catch (ex) {
                try {
                  await CheckSearchIntent();
                } catch (ex) {
                  setFailedFeilds((prev) => [
                    ...failedFeilds,
                    'Market Research',
                    'Search Intent',
                  ]);
                }
              }
            }
          }
        } finally {
          if (!showStatus) {
            setmodalPop(!modalPop);
          }
          await setAthenaSourcesInReduxStore();

          setTrainYes(false);
          setUrlListLength(1);
          setHasTrainComplete(true);
        }
      }
      // Market (default ) and URLs
      else if (UrlList.length > 0) {
        try {
          const url_body = {
            urls: UrlList,
            username: user.username,
            name: workspace.name,
          };

          const instance = await createInstance();
          const url_train_result = await instance.post(
            'url-qa-generator',
            url_body,
          );

          var extractedURL = ExtractSuccessURL(url_train_result.data.body);

          ExtractSuccessfulUrl(url_train_result.data.body);

          const body = {
            username: localStorage.getItem('username'),
            name: localStorage.getItem('name'),
            url: extractedURL,
          };

          const result = await axios.post(
            TrainAthenaAPI_URL,
            body,
            axiosConfig,
          );
          // const result = await axios.post(`https://apis.crawlq.ai/test-semantic`, body, axiosConfig)

          const api_response = result.data.body;

          if (api_response.data === true) {
            setTrainSuccess(true);
          } else {
            setTrainFail(true);
          }
        } catch (err) {
          try {
            await CheckURLS(extractedURL);
          } catch (ex) {
            try {
              await CheckURLS(extractedURL);
            } catch (ex) {
              try {
                await CheckURLS(extractedURL);
              } catch (ex) {
                try {
                  await CheckURLS(extractedURL);
                } catch (ex) {
                  setFailedFeilds((prev) => [
                    ...failedFeilds,
                    'Market Research',
                    'Search Intent',
                  ]);
                }
              }
            }
          }
        } finally {
          if (!showStatus) {
            setmodalPop(!modalPop);
          }
          await setAthenaSourcesInReduxStore();

          setTrainYes(false);
          setUrlList([]);
          setUrlListLength(1);
          setHasTrainComplete(true);
        }
      }
      // training only with market research
      else {
        try {
          const body = {
            username: user.username,
            name: workspace.name,
          };

          console.log(body);
          const result = await axios.post(
            TrainAthenaAPI_URL,
            body,
            axiosConfig,
          );
          // const result = await axios.post(`https://apis.crawlq.ai/test-semantic`, body, axiosConfig)

          const api_response = result.data.body;

          if (api_response.data == true) {
            setTrainSuccess(true);
          } else {
            setTrainFail(true);
            const { success } = await CreditManage(-TRAIN_ATHENA_COST);
          }
          if (api_response.data === false) {
            setFailedFeilds((prev) => [...prev, 'Market Research']);
          }
        } catch (err) {
          try {
            await CheckMarket();
          } catch (ex) {
            try {
              await CheckMarket();
            } catch (ex) {
              try {
                await CheckMarket();
              } catch (ex) {
                setFailedFeilds((prev) => [...failedFeilds, 'Market Research']);
              }
            }
          }
        } finally {
          if (!showStatus) {
            setmodalPop(!modalPop);
          }
          await setAthenaSourcesInReduxStore();

          setTrainYes(false);
          setUrlListLength(1);
          setHasTrainComplete(true);
        }
      }
    } else {
      CreditFail(true);
    }
  };

  const handleNo = () => {
    setmodalPop(!modalPop);
  };

  function TrainModal() {
    return (
      <Modal
        isOpen={modalPop}
        onRequestClose={handleModal}
        style={customStyles}
        ariaHideApp={false}
      >
        {!TrainSuccess ? (
          <div
            className="train__athena__pop"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <span
              className="font-accent mt-1"
              style={{ fontSize: '1.5rem', color: '#2a9df5' }}
            >
              {heading || 'Train Athena'}
            </span>
            {!!smallDesc && (
              <small
                className="font-accent mt-1"
                style={{
                  fontSize: '0.75rem',
                  padding: '0 50px',
                }}
              >
                {smallDesc}
              </small>
            )}
            {!!heading && (
              <div className="mt-4">
                {AddUrl ? (
                  ' '
                ) : (
                  <div
                    className="athena__addurl__container"
                    onClick={handleWithURL}
                    data-tip="Add links to blogs/articles related to workspace topic"
                  >
                    <ReactTooltip multiline />
                    <AddIcon style={{ color: '#2a9df5' }} />
                    <span>Add URL</span>
                  </div>
                )}

                {AddUrl ? (
                  <div className="athena__input_container">
                    <input
                      placeholder="Enter URL"
                      value={urlValue}
                      onChange={(e) => handleInputURLChange(e)}
                    />
                    <CloseIcon
                      style={{ color: '#2a9df5' }}
                      onClick={handleWithURL}
                    />
                  </div>
                ) : (
                  ' '
                )}
                {urlError ? <span>Not a valid URL</span> : null}
              </div>
            )}
            <div
              style={{ display: 'flex', alignItems: 'center' }}
              className="my-3 font-accent"
            >
              <img
                style={{ width: '35px', height: '35px', marginRight: '5px' }}
                src={coin}
              />
              <p>Cost : 600 credits</p>
            </div>

            <div style={{ marginTop: '1.5rem', display: 'flex' }}>
              {TrainYes ? (
                <p
                  style={{
                    backgroundColor: '#ccffd8',
                    color: '#2daf13',
                    padding: '6px 18px',
                    borderRadius: '5px',
                    marginRight: '20px',
                  }}
                >
                  <LoaderSpin width="20" height="20" />{' '}
                </p>
              ) : (
                <button
                  style={{
                    backgroundColor: '#ccffd8',
                    color: '#2daf13',
                    padding: '6px 18px',
                    borderRadius: '5px',
                    marginRight: '20px',
                  }}
                  onClick={handleYes}
                >
                  Yes
                </button>
              )}
              <button
                onClick={handleNo}
                style={{
                  backgroundColor: '#faa8a2',
                  color: '#e02e22',
                  padding: '6px 18px',
                  borderRadius: '5px',
                }}
              >
                {' '}
                No
              </button>
            </div>
            {TrainYes ? (
              <img style={{ padding: '0.3rem' }} src={Trainer} />
            ) : null}
          </div>
        ) : (
          <div
            className="train__athena__pop"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <span
              className="font-accent mt-3"
              style={{ fontSize: '1.7rem', color: '#2a9df5' }}
            >
              Bravo ! Athena trained successfully
            </span>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <span className="txt-primary">
                <ThumbUpAltOutlined fontSize="large" />
              </span>
            </div>
            <button
              onClick={handleNo}
              style={{
                backgroundColor: '#ccffd8',
                color: '#2daf13',
                padding: '6px 18px',
                borderRadius: '5px',
              }}
            >
              Close
            </button>
          </div>
        )}
      </Modal>
    );
  }

  function LearnMoreModal() {
    return (
      <Modal
        isOpen={learnMoreOpen}
        onRequestClose={() => setLearnMoreOpen(false)}
        style={customStylesLearnMore}
        ariaHideApp={false}
      >
        <LearnMore close={setLearnMoreOpen} />
      </Modal>
    );
  }

  function TrainedURLsModal() {
    return (
      <Modal
        isOpen={showTrainUrls}
        onRequestClose={() => setShowTrainUrls(false)}
        style={customStylesTrainURL}
        ariaHideApp={false}
      >
        <TrainedURLs close={setShowTrainUrls} />
      </Modal>
    );
  }

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };

  const handleURLClearAll = () => {
    setUrlList([]);

    setUrlListLength(1);
  };

  function UrlChipCard() {
    const handleRemoveURL = (url) => {
      setUrlList(UrlList.filter((item) => item !== url));
      setUrlListLength((UrlListLength) => UrlListLength - 1);
    };

    return (
      <div className="chip_url_container">
        {UrlList &&
          UrlList.map((url, index) => (
            <div key={index}>
              <div className="single_chip_container">
                <ReactTooltip multiline />
                <span data-tip={url} className="chip_url">
                  {url}
                </span>
                <CloseIcon
                  style={{
                    color: 'black',
                    width: '18px',
                    height: '18px',
                    flex: '1',
                  }}
                  onClick={() => handleRemoveURL(url)}
                />
              </div>
            </div>
          ))}
        {UrlList.length > 0 ? (
          <span className="clear_btn" onClick={() => handleURLClearAll()}>
            Clear all
          </span>
        ) : null}
      </div>
    );
  }

  const checkIfTrainedURLExits = () => {
    if (!user.athena_sources) return false;
    if (Array.isArray(user.athena_sources)) return false;
    if (user.athena_sources.success_urls.length < 1) return false;
    return true;
  };

  return (
    <div className="athena__body">
      <UpgradeEntryplan />
      <AddOn level={addOnLevel} />
      <div className="athena__head">
        <ReactTooltip multiline />
        <div className="global-d-flex-row">
          <h2
            className="font-primary "
            data-tip='Train Athena, A.I Assistant Helps you to get highly relevant data in “CrawlQ Search"'
          >
            Train Athena
          </h2>
          <HelperVideoModalPopup
            tooltipTitle="How to train Athena? Create deep research about your target audience and their needs!"
            videoLink="https://athena-helper-videos.s3.us-east-2.amazonaws.com/Video+6+-+Athena+Training.mp4"
          />
        </div>
        {checkIfTrainedURLExits() && (
          <span
            className="font-accent txt-primary cursor-pointer"
            onClick={() => setShowTrainUrls(true)}
          >
            Trained URLs
          </span>
        )}
        <span
          className="font-accent txt-primary cursor-pointer"
          onClick={() => setLearnMoreOpen(true)}
        >
          <HelpOutlineRoundedIcon />
        </span>
        {IsTraining ? <img src={Trainer} /> : null}
      </div>

      <div className="athena__input">
        {CreditFail ? null : TrainModal()}
        {LearnMoreModal()}
        {TrainedURLsModal()}
        <div>
          <div className="market_research_checkbox_container">
            <ReactTooltip multiline />
            <input
              type="checkbox"
              id="market_research"
              value="market research"
              checked="true"
            />

            <span htmlFor="market_research">Niche</span>
          </div>
        </div>

        <fieldset className="train_athena_feildset">
          <legend>&nbsp;Optional&nbsp; </legend>
          <div className="search_intent_checkbox_container">
            <div className="search_intent_checkbox">
              <ReactTooltip multiline />
              <input
                type="checkbox"
                id="search_intent"
                value="search intent"
                checked={SearchIntentCheck}
                onChange={(e) =>
                  setSearchIntentCheck(
                    (SearchIntentCheck) => !SearchIntentCheck,
                  )
                }
              />

              <span className="search_intent_title" htmlFor="search_intent">
                Search Intent
              </span>
              <CustomTooltipMTI
                title={
                  search_tooltip !== ''
                    ? `It will use "${search_tooltip}", your Main Topic of Interest, as the base search query, will generate multiple new queries around it and then fetch google serp data between starting and ending rank. The ending rank is fixed to starting rank + 10 to always fetch 10 results per query`
                    : 'Main topic of interest is empty.'
                }
                arrow
                placement="right-end"
              >
                <InfoOutlinedIcon
                  style={{ fontSize: '1rem' }}
                  className="txt-primary mx-1"
                />
              </CustomTooltipMTI>
            </div>

            {SearchIntentCheck ? (
              <div
                style={{
                  width: '25rem',
                  marginLeft: '1.5rem',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <div>
                    <input
                      type="checkbox"
                      id="search_intent"
                      value="search intent"
                      checked
                    />

                    <span
                      className="search_intent_title mx-1"
                      htmlFor="search_intent"
                    >
                      Google
                    </span>
                  </div>

                  <div>
                    {!isLoadingReddit ? (
                      <>
                        <input
                          type="checkbox"
                          id="search_intent"
                          value="search intent"
                          checked={redditCheck}
                          onChange={handleRedditCheck}
                        />

                        <span
                          className="search_intent_title mx-1"
                          htmlFor="search_intent"
                        >
                          Reddit
                        </span>
                      </>
                    ) : (
                      <LoaderSpin />
                    )}
                  </div>
                  <div>
                    {!isLoadingQuora ? (
                      <>
                        <input
                          type="checkbox"
                          id="search_intent"
                          value="search intent"
                          checked={quoraCheck}
                          onChange={handleQuoraCheck}
                        />
                        <span
                          className="search_intent_title mx-1"
                          htmlFor="search_intent"
                        >
                          Quora
                        </span>
                      </>
                    ) : (
                      <LoaderSpin />
                    )}
                  </div>
                </div>

                <div className="search_intent_inner_div">
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      Starting Rank
                      <FormControl
                        variant="outlined"
                        style={{
                          width: '3.5rem',
                          marginLeft: '0.8rem',
                          color: 'var(--default-text-color)',
                        }}
                      >
                        <Select
                          style={{ color: 'var(--default-text-color)' }}
                          native
                          onChange={handleSERPCounter}
                          value={SerpResultCounter}
                        >
                          {[...Array(40).keys()].map((num) => (
                            <option
                              style={{
                                color: 'var(--default-text-color)',
                                backgroundColor: 'var(--background-color)',
                              }}
                              key={num}
                              value={num + 1}
                            >
                              {num + 1}
                            </option>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '0.2rem',
                      }}
                    >
                      Ending Rank
                      <FormControl
                        variant="outlined"
                        style={{ width: '3.5rem', marginLeft: '0.8rem' }}
                      >
                        <Select
                          style={{ color: 'var(--default-text-color)' }}
                          native
                          onChange={handleSERPCounter}
                          value={parseInt(SerpResultCounter) + 9}
                        >
                          <option
                            style={{
                              color: 'var(--default-text-color)',
                              backgroundColor: 'var(--background-color)',
                            }}
                            value={parseInt(SerpResultCounter) + 9}
                          >
                            {parseInt(SerpResultCounter) + 9}
                          </option>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>

                <FormControl
                  variant="outlined"
                  style={{
                    width: '100%',
                    marginTop: '1rem',
                  }}
                >
                  <Select
                    style={{ color: 'var(--default-text-color)' }}
                    native
                    onChange={(e) => handleCountryChange(e)}
                    value={SelectedCountry}
                  >
                    {Countries.map((country, index) => (
                      <option
                        style={{
                          color: 'var(--default-text-color)',
                          backgroundColor: 'var(--background-color)',
                        }}
                        key={index}
                        value={country.alpha2Code}
                      >
                        {country.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </div>
            ) : null}
          </div>
          <div className="athena__addurl">
            {AddUrl ? (
              ' '
            ) : (
              <div
                className={
                  !isEntryPlan
                    ? 'athena__addurl__container'
                    : 'athena__addurl__container athena_addurl__container_disabled '
                }
                onClick={handleURLInputBox}
              >
                <ReactTooltip multiline />
                {!isEntryPlan ? (
                  <input
                    type="checkbox"
                    id="add_url_checkbox"
                    value="search intent"
                    checked={AddUrl}
                    onChange={() => {
                      setAddUrl(true);
                    }}
                  />
                ) : (
                  <input
                    type="checkbox"
                    id="add_url_checkbox"
                    disabled
                    value={false}
                  />
                )}
                <span>Add URL</span>
                {/* <InfoOutlinedIcon
                data-tip="Train Athena on webpages (blogs / articles) relevant to your workspace. "
                style={{ width: '17px', height: '17px', marginLeft: '0.2rem' }}
              /> */}
              </div>
            )}

            {AddUrl ? (
              <div className="athena__input_container">
                <ReactTooltip />
                <div
                  style={{
                    backgroundColor: 'var(--background-secondary-color)',
                    marginRight: '0.2rem',
                  }}
                >
                  <input
                    placeholder="Enter URL"
                    value={urlValue}
                    onChange={(e) => handleInputURLChange(e)}
                  />
                  <CancelIcon
                    style={{
                      backgroundColor: 'var(--background-secondary-color)',
                      color: '#ff6565',
                      marginRight: '0.3rem',
                    }}
                    onClick={handleURLInputBox}
                  />
                </div>
                {UrlListLength >= 6 ? (
                  <CheckCircleIcon
                    style={{ color: 'grey' }}
                    data-tip="URL limit is 5"
                  />
                ) : (
                  <CheckCircleIcon
                    style={{ color: '#3efd48' }}
                    onClick={handleWithURL}
                  />
                )}
                {urlError ? (
                  <span style={{ color: '#ff6565', fontSize: '0.8rem' }}>
                    Not a valid URL
                  </span>
                ) : null}
              </div>
            ) : (
              ' '
            )}

            <UrlChipCard />

            {UrlSuccessFail ? <URLFailOrPass /> : null}
          </div>
        </fieldset>
        {CreditFail ? (
          <button className="athena-credit-over">Credits Over</button>
        ) : (
          <div
            className="train-athena-container-btn"
            style={!hasTrainComplete ? { justifyContent: 'flex-end' } : {}}
          >
            {hasTrainComplete && (
              <b>
                {failedFeilds.length === 0 && (
                  <p className="text-success">Training Successful</p>
                )}
                {failedFeilds.length === 1 && (
                  <p className="text-success">
                    Training succeeded partially,{' '}
                    <span className="text-danger">
                      {failedFeilds[0]} failed
                    </span>
                  </p>
                )}
                {failedFeilds.length === 2 && (
                  <p className="text-success">
                    Training succeeded partially,{' '}
                    <span className="text-danger">
                      {failedFeilds[0]} and
                      {failedFeilds[1]} failed
                    </span>
                  </p>
                )}
                {failedFeilds.length === 3 && (
                  <p className="text-danger">Training failed, try again!</p>
                )}
              </b>
            )}
            {athenaLastTrainedTime && (
              <div style={{ fontSize: '12px', marginRight: '80px' }}>
                Last Trained: {AthenaTrainedTime}
              </div>
            )}

            {progressPercentage === 100 ? (
              <button className="train-athena" onClick={handleTrainPop}>
                Power Up
              </button>
            ) : (
              <CustomTooltip
                arrow
                placement="top"
                title="Uh-Oh! Looks like you have not completed Level-1 of Market Research. Go ahead, do that and unlock this awesome feature."
              >
                <button className="btn btn-secondary">Power Up</button>
              </CustomTooltip>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default TrainAthena;
