/* eslint-disable react/no-unescaped-entities */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import React from 'react';
import learnmoreImg from '../../../../assets/img/learn-more.png';
import spySSImg from '../../../../assets/img/spyScreenshot.png';
import searchIntentSSImg from '../../../../assets/img/searchIntentScreenshot.png';
import './Learnmore.css';
import Trainer from '../../../../assets/images/train.gif';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

function LearnMore({ close }) {
  return (
    <div className="learn-modal-body">
      <div style={{ alignSelf: 'flex-end', padding: '0 1rem', flex: '1' }}>
        <CloseRoundedIcon
          onClick={() => close(false)}
          className="cursor-pointer"
        />
      </div>

      <div className="learn-container">
        <img className="learn-img" src={learnmoreImg} />
        <a
          href="/static/media/learn-more.b6135899.png"
          target="_blank"
          style={{ alignSelf: 'flex-end', margin: '0 1rem' }}
          className="btn btn-secondary"
        >
          View Image In FullScreen
        </a>
        <hr />
        <div className="learn-text-container">
          <p>
            Train our in-house AI Assistant “Athena” to get 100% niche-specific
            data for all your content needs
          </p>
          <img style={{ padding: '0.3rem' }} src={Trainer} />
          <p>
            While creating CrawlQ, we have envisioned a ladder of three steps.
            After completing these steps you will have awesome curated content
            with you available to be shared with your niche.
            <br />
            These steps are:
          </p>
          <ol>
            <li>Market Research </li>
            <li>Train Athena</li>
            <li>Create Content </li>
          </ol>
          <h2 className="learn-step">
            Step-1: Complete Level-1 of “Market Research”
          </h2>
          <p>
            The Market Research data is very useful for guiding Athena when you
            create content. Your answers to Niche, Main topic of Interest,
            Single Big Desire, Single Big Problem, Single Big Problem-Solution
            etc helps Athena to understand your target audience and their pain
            points. This way the sales copy that you create will be highly
            specific and will strongly appeal to your audience.
          </p>
          <h2 className="learn-step">Step-2: Train Athena</h2>
          <p>
            You can train our AI on your data and make it your personal AI.
            Train “Athena” on following :-
          </p>
          <ol>
            <li>Market Research inputs</li>
            <li>Insights you have generated</li>
            <li>
              Search Intent based on your “Main Topic of Interest ''&nbsp;
            </li>
            <li>
              The URL of your competitor’s or your own to inject facts, figures
              and numbers and make Athena smarter
            </li>
          </ol>
          <p>
            When you select “Market Research” for training from Dashboard,
            Athena will also be trained on the Insights that you have saved in
            Market Research and all the scripts you have created and saved in
            Sales Copywriting. If you create more scripts later on, you may need
            to re-train Athena to learn from those scripts.
            <br />
            {' '}
            <br />
            {' '}
            Additionally, you can train Athena on Search-Intent.
            For this, select the number of top SERP results to be fed to Athena
            and the country code from the options available in Dashboard. This
            will take the “Main Topic of Interest” (from Define your Niche
            Sematics in Market Research) as a search query, create additional
            search queries around it like trending topics, dreams, benefits etc.
            and fetch top SERP results for those search queries. Our AI
            discovers all relevant search intent from desires, suspicions,
            roadblocks to all questions users are asking on Google. This will
            make hundreds of search queries for your single Main Topic of
            Interest and depending on the number you have selected for SERP
            results from Dashboard, our AI will automatically be trained on
            thousands of top SERP data. To get a possible idea of all the search
            queries we are using, go to Spying Wizard and Search Intent
            Discovery and enter your Main topic of Interest in the keyword topic
            search box. For example, if your main topic of interest is “3D
            printing”, the following images shows some possible search queries
            that are weaved around 3D printing.
          </p>
          <div>
            <img src={spySSImg} />
            <br />
            <hr />
            <br />
            <img src={searchIntentSSImg} />
          </div>
          <p>
            But that’s not it! You can feed your own or your competitor’s data
            to Athena as well. For this, you can select the URL option for
            training and add webpages(blogs/articles) relevant to your workspace
          </p>
          <h2 className="learn-step">Step-3: Create Content</h2>
          <p>
            So much closer to the goal now! The last step is the most FUN! You
            can now start creating content by going to “Content Automation”.
            There you can create targeted sales copy in Sales Copywriting or
            appealing blogs in AI Content Writer. We will make it easy for you
            by creating an initial draft which you can then work upon, edit and
            format by using the plethora of features available inside the
            editor.
            <br />
            {' '}
            <br />
            At any stage you can go back and complete Level 2 of Market Research
            and re-train Athena.
          </p>
          <p>
            <strong>
              <i>
                This is your CrawlQ journey. We hope you have fun along this
                journey and in case of any issue, feel free to reach out to us.
                We are always there to help you.
              </i>
            </strong>
          </p>
        </div>
      </div>
    </div>
  );
}

export default LearnMore;
