/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
// Component
import FileCopyRoundedIcon from '@mui/icons-material/FileCopyRounded';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userWorkspaceCountUpdate } from 'redux/index';
import LoaderSpin from '../../../../../Shared/Loader';

// Logic Functions
import { fetchUserWorkspace } from '../../WorkspaceLogic';
import './DemoWorkspaceClone.css';
import DemoCloneWorkspaceRequest from './DemoWorkspaceCloneLogic';

function DemoWorkspaceClone({ workspace_detail, close_clone_demo_modal }) {
  const dispatch = useDispatch();
  const { workspace_count } = useSelector((state) => state?.user);

  const [IsDemoCloneConfirming, setIsDemoCloneConfirming] = useState(false);
  const [IsDemoCloning, setIsDemoCloning] = useState(false);
  const [DemoWorkspaceExists, setDemoWorkspaceExists] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState('');
  const [isCopiedSucessfully, setIsCopiedSucessfully] = useState(false);

  useEffect(() => {
    CheckWorkspaceExistsInUserWorkspace();
  }, []);

  //   const updateWorkspaceCount = () => {
  //     console.log('unmount', isCopiedSucessfully)
  //     if (isCopiedSucessfully) {
  //       dispatch(userWorkspaceCountUpdate(workspace_count + 1))
  //     }
  //   }

  const CheckWorkspaceExistsInUserWorkspace = async () => {
    const { result } = await fetchUserWorkspace();

    const existingProjectsName = result.map(
      (existingProject) => existingProject.name,
    );

    if (existingProjectsName.includes(workspace_detail.workspace_name)) {
      setDemoWorkspaceExists(true);
    } else {
      setDemoWorkspaceExists(false);
    }
  };

  const handleDemoClone = async (name, domain) => {
    setIsDemoCloneConfirming(true);

    setIsDemoCloning(true);

    const result = await DemoCloneWorkspaceRequest([name], [domain]);

    if (result.error === true) {
      setErrorMessage('Some workspace(s) cannot be copied!');
      setIsDemoCloning(false);
    } else {
      localStorage.setItem('name', name);
      localStorage.setItem('url', domain);
      localStorage.removeItem('processPercentage');

      // let { destination } = await IsMinMarketResearchDone(name)

      // history.push(destination)
      setIsDemoCloneConfirming(false);
      setIsDemoCloning(false);
      setIsCopiedSucessfully(true);
    }
  };

  return (
    <div className="workspace-demo-clone-main-container">
      <div className="workspace-clone-icon-wrapper">
        <FileCopyRoundedIcon
          style={{ color: '#2a9df5', width: '5rem', height: '5rem' }}
        />
      </div>
      <h5>
        Add
        {' '}
        <span>
          {' '}
          {workspace_detail.workspace_name}
          {' '}
        </span>
        {' '}
        to My Workspaces
      </h5>

      {isCopiedSucessfully && (
        <div className="text-success text-center text-strong">
          Workspace Copied Successfully
        </div>
      )}
      {DemoWorkspaceExists ? (
        <span className="demo-clone-workspace-error-message">
          Workspace already exists in your workspace !
        </span>
      ) : null}
      {IsDemoCloneConfirming && ErrorMessage ? (
        <span className="demo-clone-workspace-error-message">
          {ErrorMessage}
        </span>
      ) : null}

      {!isCopiedSucessfully && (
        <div
          className="workspace-demo-clone-button-wrapper"
          style={DemoWorkspaceExists ? { justifyContent: 'center' } : null}
        >
          <button onClick={close_clone_demo_modal}>CANCEL</button>

          {IsDemoCloning ? (
            <div>
              <LoaderSpin width="25" height="25" />
            </div>
          ) : DemoWorkspaceExists ? null : (
            <button
              onClick={() => handleDemoClone(
                workspace_detail.workspace_name,
                workspace_detail.workspace_domain,
              )}
            >
              CONFIRM
            </button>
          )}
        </div>
      )}
    </div>
  );
}

export default DemoWorkspaceClone;
