import React from 'react';
import { Flex } from 'Component/Shared/GlobalStyles';
import { SectionData, SectionDataHeading } from '../styles';

const sourceMappedData = {
  mrr: 'Market Research',
  mrr_insight: 'Market Insights',
  script: 'Sales Copywriting',
  serp: 'Google',
  quora: 'Quora',
  reddit: 'Reddit',
  website: 'URL',
};

const SourceDisplay = ({ heading, sourceData }) => (
  <Flex direction={'column'} gap={'0.2rem'}>
    <SectionDataHeading>{heading}</SectionDataHeading>
    <Flex gap={'0.4rem'}>
      {sourceData &&
        sourceData.map((source) => (
          <SectionData className="px-2">
            {sourceMappedData[source] ? sourceMappedData[source] : source}
          </SectionData>
        ))}
    </Flex>
  </Flex>
);

export default SourceDisplay;
