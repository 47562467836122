/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import './semantic.css';
import { useSelector } from 'react-redux';
import MenuListComposition from 'Component/Shared/MenuList/Index';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CustomTooltip from 'Component/Shared/CustomTooltip';
import copyClipboard from 'Component/Shared/copyToClipBoard';
import { notification } from 'antd';

function SemanticEngine(props) {
  const athena_sources = useSelector(
    (state) => state.user.athena_sources?.train_option,
  );

  const username = useSelector((state) => state.user?.username);
  const workspace_name = useSelector((state) => state.workspace?.name);

  const [userInputQuery, setuserInputQuery] = useState('');
  const [Response, setResponse] = useState([]);
  const [ResponseEmpty, setResponseEmpty] = useState(false);
  const [IsTrained, setIsTrained] = useState(true);
  const [loader, setLoader] = useState(false);
  const [HasAnswer, setHasAnswer] = useState();
  const [menuPop, setmenuPop] = useState(false);
  const [filterOptions, setFilterOptions] = useState([
    { key: 'mrr', value: 'Market Research', checked: false },
    { key: 'mrr_insight', value: 'Market Insights', checked: false },
    { key: 'script', value: 'Sales Copywriting', checked: false },
    { key: 'chat_athena', value: 'Chat Athena', checked: false },
    { key: 'serp', value: 'Google', checked: false },
    { key: 'quora', value: 'Quora', checked: false },
    { key: 'reddit', value: 'Reddit', checked: false },
    { key: 'website', value: 'URL', checked: false },
  ]);

  useEffect(() => {
    setuserInputQuery(props.query);
    setFilterOptions([...props.source]);
    if (props.query) {
      const source_name = props.source
        .filter((option) => option.checked)
        .map((option) => option.key);
      const body = {
        query: props.query,
        source_name,
      };
      semanticSearch(body);
    }
  }, [props]);

  //  handle user input field
  function handleInput(e) {
    // disables loader if user focuses on the input field
    setLoader(false);

    setuserInputQuery(e.target.value);
  }

  // handle on click button Search
  function handleSearch() {
    console.log(`user input : ${userInputQuery}`);
    const source_name = filterOptions
      .filter((option) => option.checked)
      .map((option) => option.key);
    const body = {
      query: userInputQuery,
      source_name,
    };
    semanticSearch(body);
  }

  // handle search on press Enter Button
  function handleEnterSearch(e) {
    if (e.key === 'Enter') {
      console.log(userInputQuery);
      const source_name = filterOptions
        .filter((option) => option.checked)
        .map((option) => option.key);
      const body = {
        query: userInputQuery,
        source_name,
      };
      semanticSearch(body);
    }
  }

  // Copy Fn
  // function copyClipboard(text) {
  //   const el = document.createElement('textarea');
  //   el.value = text;
  //   document.body.appendChild(el);
  //   el.select();
  //   document.execCommand('copy');
  //   document.body.removeChild(el);
  // }

  // semantic search operation endpoint :
  function semanticSearch(body) {
    body = {
      username,
      name: workspace_name,
      ...body,
    };
    console.log(body);
    setResponseEmpty(false);

    setLoader(true);

    Axios.post('https://apis.crawlq.ai/crawlq-search', body)

      .then((res) => {
        console.log(res.data.body.data);

        // setting answers into state : response
        setResponse(res.data.body.data);

        checkException(res.data.body);
        // todo: make exceptions for empty answeers or edge cases
      })

      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        // stopping loader once answer is retrieved.
        setLoader(false);
      });

    function checkException(result) {
      console.log(result);
      if (!result.is_indexed) {
        setIsTrained(false);
      }
      result = result.data;
      if (result.length === 0) {
        console.log('emtpy results');
        setHasAnswer(false);
        setResponseEmpty(true);
        return;
      }
      setHasAnswer(true);
      sortWithDscore(result);
    }
  }

  const sortWithDscore = (searchResults) => {
    searchResults.sort((a, b) => a.D - b.D);
  };

  const handleCloseFilter = () => {
    const clickCheck = (event) => {
      const concernedElement = document.querySelector(
        '.top-bar-dropdown_options_container',
      );
      if (!concernedElement) return;
      if (!concernedElement.contains(event.target)) {
        setmenuPop(false);
        document.removeEventListener('mousedown', clickCheck);
      }
    };
    if (!menuPop) {
      document.addEventListener('mousedown', clickCheck);
      return;
    }
    document.addEventListener('mousedown', clickCheck);
  };

  const handleFilterChange = (index) => {
    const filterOptionsArray = [...filterOptions];
    filterOptionsArray[index].checked = !filterOptionsArray[index].checked;
    setFilterOptions([...filterOptionsArray]);
  };

  const handleMenuPop = (event) => {
    setmenuPop((menuPop) => !menuPop);
    document.addEventListener('click', () => {
      setmenuPop(false);
    });
    // handleCloseFilter()
  };

  function SearchFilter() {
    return (
      <MenuListComposition>
        <div className="top-bar-dropdown_option_active">
          {filterOptions.map((option, index) => (
            <>
              {athena_sources && athena_sources.includes(option.key) ? (
                <div
                  style={{
                    display: 'flex',
                    flex: '1',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  key={index}
                  onClick={() => {
                    handleFilterChange(index);
                  }}
                >
                  <input
                    type="checkbox"
                    value={option.key}
                    checked={option.checked}
                    className="tool-tip"
                  />
                  <div className="train_options ml-2">{option.value}</div>
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    flex: '1',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  key={index}
                >
                  <input type="checkbox" value={option.key} checked={false} />
                  <div className="train_options ml-2 text-muted">
                    {option.value}
                  </div>
                </div>
              )}
            </>
          ))}
        </div>
      </MenuListComposition>
    );
  }

  const htmlDecode = (content) => {
    const e = document.createElement('div');
    e.innerHTML = content;
    return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
  };

  const manageHashtags = (response) => {
    const hashtag_list = response.hashtags;
    const _new_hashtag_list = [];
    let output = '@mention';
    if (hashtag_list && hashtag_list.length) {
      for (const _k in hashtag_list) {
        const hashtag = hashtag_list[_k];
        _new_hashtag_list.push(`#${hashtag}`);
      }
    }
    output = _new_hashtag_list.join(', ');
    return output;
  };

  const manageMentions = (response) => {
    const mention_list = response.mentions;
    const _new_mention_list = [];
    let output = '@hashtag';
    if (mention_list && mention_list.length) {
      for (const _k in mention_list) {
        const mention = mention_list[_k];
        _new_mention_list.push(`@${mention}`);
      }
    }
    output = _new_mention_list.join(', ');
    return output;
  };

  const renderData = (response) => {
    if (response?.source === 'mrr_insight') {
      return (
        <div className="answer-list">
          {' '}
          <strong>Answer: </strong>
          <div dangerouslySetInnerHTML={{ __html: response?.answer }} />
        </div>
      );
    }
    return (
      <>
        <div className="answer-list">
          {' '}
          <strong>Question: </strong> {response?.question}
          <div className="copy-box">
            <span
              className="copy-text-question"
              onClick={() => handleCopy(response?.question, 'Question')}
            >
              Copy
            </span>
          </div>
        </div>
        <div className="answer-list" style={{ marginTop: '10px' }}>
          {' '}
          <strong>Answer: </strong>
          <div dangerouslySetInnerHTML={{ __html: response?.answer }} />
        </div>

        {response.hashtags && (response.hashtags, []).length > 0 && (
          <div className="answer-list">
            {' '}
            <strong>Hashtags: </strong> {manageHashtags(response)}
          </div>
        )}

        {response.mentions && (response.mentions, []).length > 0 && (
          <div className="answer-list">
            {' '}
            <strong>Mentions: </strong> {manageMentions(response)}
          </div>
        )}
      </>
    );
  };

  const handleFilterChangeWithCross = (currentOption) => {
    const newFilter = filterOptions.map((filter) => {
      if (filter.key === currentOption.key) {
        return { ...filter, checked: false };
      }
      return { ...filter };
    });
    setFilterOptions(newFilter);
  };

  const showNotification = (type, message, description) => {
    notification.open({
      type,
      message,
      description,
      placement: 'bottomRight',
      duration: 5,
    });
  };

  const handleCopy = (text, message) => {
    copyClipboard(text);
    showNotification('success', 'Copied', `${message} copied successfully !`);
  };

  return (
    <div>
      <div className="static-search-wrapper">
        <img className="search-logo" src="/img/crawlQ.png" />

        <div className="semantic-search-wrapper">
          <div className="input-wrapper">
            <img className="search-img" src="/img/search.svg" />
            <input
              className="semantic-input"
              onChange={(e) => handleInput(e)}
              placeholder="CrawlQ Search"
              value={userInputQuery}
              onKeyPress={(e) => handleEnterSearch(e)}
              style={{
                backgroundColor: props.darkMode ? '#343641' : 'white',
                color: props.darkMode ? 'white' : '343641',
              }}
            />
            <SearchFilter />
          </div>
          {loader ? (
            ''
          ) : (
            <button className="semantic-search" onClick={handleSearch}>
              Search
            </button>
          )}

          {loader ? (
            <Loader
              type="Rings"
              color="#00BFFF"
              height={40}
              width={40}
              visible="true"
              // 3 secs
            />
          ) : (
            ''
          )}
        </div>
      </div>
      <div className="semantic-seach-filter-list">
        {filterOptions.map((option) => (
          <>
            {option.checked && (
              <div>
                {option.value}
                <CustomTooltip arrow placement="top" title="Remove Filter">
                  <CancelRoundedIcon
                    className="tool-tip"
                    onClick={() => handleFilterChangeWithCross(option)}
                  />
                </CustomTooltip>
              </div>
            )}
          </>
        ))}
      </div>
      <div className="answer-box">
        <ul
          className={
            HasAnswer
              ? 'list-answers-wrapper-answer'
              : 'list-answers-wrapper-noanswer'
          }
        >
          {!Response ? (
            ''
          ) : HasAnswer ? (
            Response.map((response, index) => (
              <div className="semantic-answer-card-container" key={index}>
                <div>
                  {response?.url && (
                    <div className="truncate">
                      {' '}
                      <a href={response?.url}> {response?.url}</a>
                    </div>
                  )}
                  {renderData(response)}

                  <div className="copy-box">
                    <span
                      className="copy-text"
                      onClick={() => handleCopy(response.answer, 'Answer')}
                    >
                      Copy
                    </span>
                  </div>
                </div>
                <div className="answer-details">
                  <div className="semantic-relevancy">
                    {/* <span>D-Score : {" "}</span>
                                            <p>{response.D}</p> */}
                  </div>
                  <div className="semantic-source">
                    <span>Sources : </span>
                    <p>{response.source === 'mrr' ? 'Market Research' : ''}</p>
                    <p>{response.source === 'serp' ? 'Search Intent' : ''}</p>
                    <p>{response.source === 'website' ? 'URL' : ''}</p>
                    {response.source === 'quora' ? <p>Quora</p> : null}
                    {response.source === 'reddit' ? <p>Reddit</p> : null}
                    {response.source === 'chat_athena' ? (
                      <p>Chat Athena</p>
                    ) : null}
                    <p>
                      {response.source === 'mrr_insight'
                        ? 'Market Insights'
                        : ''}
                    </p>
                    <p>
                      {response.source === 'script' ? 'Sales copywriting' : ''}
                    </p>

                    <p>{response.source !== 'script' || 'mrr' ? null : null}</p>
                  </div>
                </div>
              </div>
            ))
          ) : ResponseEmpty ? (
            <div className="no-result-wrapper">
              <img src="/img/no-result.svg" />
              <p>
                {IsTrained ? (
                  <p>
                    <b>No Results</b> , Please Try Another Keyword
                  </p>
                ) : (
                  <p>
                    Looks like you haven’t trained Athena yet. Go to the
                    Dashboard and Train Athena to get the best results.
                  </p>
                )}
              </p>
            </div>
          ) : null}
        </ul>
      </div>
    </div>
  );
}

export default SemanticEngine;
