/* eslint-disable react/no-unescaped-entities */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
// Internal Imports
import { LoaderTailSpin } from 'Component/Shared/Loader';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { userWorkspaceCountUpdate, workspaceSelect } from 'redux/index';
import notFoundIcon from 'Component/../assets/landingpage-imgs/notfountTemplate.svg';

// Components - External
import DemoWorkspacePreview from 'Component/Pages/Workspaces/Workspace/DemoWorkspace/DemoWorkspacePreview/DemoWorkspacePreview';

// Logic Fns
import DemoCloneWorkspaceRequest from 'Component/Pages/Workspaces/Workspace/DemoWorkspace/DemoWorkspaceClone/DemoWorkspaceCloneLogic';
import setGoogleLanguageCookie from 'Component/Shared/Cookie_custom';

import { ButtonPrevious } from 'Component/Shared/ButtonNext';
import { userWorkspaceSetAthenaSources } from '../../../../../../redux';
import fetchDemoWorkspace from '../../NewUserLandingPageLogic';
import { TemplateSearchNoResult } from './styles';

function WorkspaceTemplate({ closer }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [DemoWorkspaces, setDemoWorkspaces] = useState([]);
  const [BusinessInput, setBusinessInput] = useState('');
  const [GetStartedIndexLoading, setGetStartedIndexLoading] = useState(-1);
  const [ShowPreview, setShowPreview] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [isTemplateNotFound, setIsTemplateNotFound] = useState(false);
  const [existingWorkspaceName, setExistingWorkspaceName] = useState(null);

  useEffect(() => {
    getDemoWorkspaces();
  }, []);

  const getDemoWorkspaces = async () => {
    setIsLoading(true);
    let { result } = await fetchDemoWorkspace();
    if (result.length) {
      result = (result || []).filter((workspace) => workspace.cost === 0);
      setDemoWorkspaces(result);
    }
    setIsLoading(false);
  };

  const handleBusinessInput = (e) => {
    setBusinessInput(e.target.value);
    if (isLoading) return;
    const doesTemplateExist = DemoWorkspaces.some((workspace) =>
      workspace.name.toLowerCase().includes(e.target.value.toLowerCase()),
    );
    setIsTemplateNotFound(!doesTemplateExist);
    setExistingWorkspaceName(null);
  };

  const handleGetStarted = async (
    name,
    domain,
    language,
    language_engine,
    index,
    is_trained,
  ) => {
    setGetStartedIndexLoading(index);

    const result = await DemoCloneWorkspaceRequest([name], [domain]);

    if (result.error === true) {
      setExistingWorkspaceName(name);
      setGetStartedIndexLoading(-1);
    } else {
      setGoogleLanguageCookie(`/en/${language}/`);
      localStorage.setItem('name', name);
      localStorage.setItem('url', domain);
      localStorage.removeItem('processPercentage');
      localStorage.setItem('project_name', name);

      // set workspace details in redux-store
      dispatch(
        workspaceSelect({
          name,
          domain,
          language,
          language_engine,
          is_trained,
          mti: null,
        }),
      );
      dispatch(
        userWorkspaceSetAthenaSources({
          success_urls: [],
          failed_urls: [],
          train_option: DemoWorkspaces[index].train_sources,
        }),
      );
      navigate('/chat-athena');
      setGetStartedIndexLoading(-1);
      dispatch(userWorkspaceCountUpdate(1));
    }
  };

  const handlePreview = (index) => {
    if (index === ShowPreview) {
      setShowPreview(-1);
    } else {
      setShowPreview(index);
    }
  };

  return (
    <div className="new-user-workspace-container">
      <div className="new-user-workspace-main-container">
        <div className="new-user-workspace-select-wrapper">
          <div className="new-user-workspace-select-search-wrapper">
            <div>
              <ButtonPrevious buttonName="Back" onClick={() => closer(false)} />
            </div>
            <h5 className="text-center">
              <b>Enter Your Business Idea and Press Button Get Started</b>
            </h5>
            <p>
              <small>
                <ol>
                  <li>
                    Enter a business idea and CrawlQ's AI Athena will give you a
                    jump-start to your marketing strategy with a pre-researched
                    Audience Persona.
                  </li>
                  <li>
                    Publish your first content in under 2 minutes and come back
                    later to audience research to A/B test your content and
                    research strategies.
                  </li>
                  <li>
                    You can also clone the initial audience persona and tweak it
                    to your business goals and objectives.
                  </li>
                </ol>
              </small>
            </p>
            <div className="d-flex">
              <input
                className="new-user-select-search-data-input"
                placeholder="Enter Business Idea"
                value={BusinessInput}
                name="business-name"
                onChange={(e) => handleBusinessInput(e)}
              />
            </div>
            <div className="new-user-select-search-data">
              {BusinessInput &&
                !isLoading &&
                DemoWorkspaces.map((workspace, index) => (
                  <>
                    {workspace.name
                      .toLowerCase()
                      .includes(BusinessInput.toLowerCase()) && (
                      <div className="new-user-select-workspace">
                        <span className="new-user-select-workspace-name">
                          {workspace.name}
                        </span>
                        <span className="new-user-select-workspace-site">
                          {workspace.site}
                        </span>

                        <div className="new-user-select-workspace-preview">
                          <button
                            className={
                              ShowPreview === index
                                ? 'close-preview'
                                : 'show-preview'
                            }
                            onClick={() => handlePreview(index)}
                          >
                            {ShowPreview === index
                              ? 'Close Preview'
                              : 'Show Preview'}
                            {/* <PlayCircleFilledWhiteIcon /> */}
                          </button>
                        </div>

                        {GetStartedIndexLoading === index ? (
                          <LoaderTailSpin width="20" height="20" />
                        ) : (
                          <div className="new-user-select-workspace-button">
                            <button
                              onClick={() =>
                                handleGetStarted(
                                  workspace.name,
                                  workspace.site,
                                  workspace.Lang,
                                  workspace.lang_engine,
                                  index,
                                  !!workspace.is_trained,
                                )
                              }
                              disabled={
                                existingWorkspaceName === workspace.name
                              }
                            >
                              Get Started
                            </button>
                          </div>
                        )}
                      </div>
                    )}
                    {existingWorkspaceName === workspace.name && (
                      <p className="text-center text-danger">
                        Workspace with name{' '}
                        <strong>"{existingWorkspaceName}"</strong>
                        already Exists.
                      </p>
                    )}
                    {ShowPreview === index ? (
                      <DemoWorkspacePreview workspace_name={workspace.name} />
                    ) : null}
                  </>
                ))}

              {isLoading && (
                <div className="mt-3 text-center">
                  <LoaderTailSpin />
                  <div className="mt-2">Fetching Template...</div>
                </div>
              )}
              {!isLoading && BusinessInput.length > 0 && isTemplateNotFound && (
                <TemplateSearchNoResult>
                  <div className="text-center">
                    <img width={300} src={notFoundIcon} />
                  </div>
                  <code>
                    <span>
                      Your idea is quite&nbsp;
                      <b>unique...</b>
                    </span>
                    <br /> Go ahead and build from scratch to keep it secret
                    from others !
                  </code>
                </TemplateSearchNoResult>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WorkspaceTemplate;
