import LoaderSpin from 'Component/Shared/Loader';
import React, { useState } from 'react';
import { SearchInput } from 'components/global/SearchInput';
import { useGetConnectedFivetranApps } from 'queries/chat-athena/fivetran/get-connected-apps';
import styles from './index.module.css';
import { ConnetedApps } from './Card/ConnectedApps';

export const ListConnectedApps = () => {
  const {
    data: connectedFivetranApps,
    isLoading,
    isError,
    error,
    isFetching,
  } = useGetConnectedFivetranApps();

  const [searchKey, setSearchKey] = useState('');

  if (isLoading || isFetching) {
    return (
      <div className={styles.parent_loader}>
        <LoaderSpin />
        <p>Loading Available Apps...</p>
      </div>
    );
  }

  if (connectedFivetranApps?.length === 0) {
    return null;
  }

  return (
    <div className={styles.all_apps}>
      <div className={styles.stats_bar}>
        <b>{connectedFivetranApps?.length} apps connected</b>
        <SearchInput
          value={searchKey}
          onChange={(e) => setSearchKey(e.target.value)}
        />
      </div>
      <div className={styles.apps_list_wapper}>
        {connectedFivetranApps
          ?.filter((app) =>
            app.display_name?.toLowerCase().includes(searchKey?.toLowerCase()),
          )
          .map((app) => (
            <ConnetedApps app={app} key={app.connector_id} />
          ))}
      </div>
      {isError && <p className="text-danger">Error in Loading App: {error}</p>}
    </div>
  );
};
