import { ROUTES } from 'constants/routes';
import React, { useState } from 'react';
import Modal from 'react-modal';
import { useGetBrandVoiceData } from 'queries/brand-voice/brand-voice-data';
import LoaderSpin from 'Component/Shared/Loader';
import { ButtonPrimary, Flex } from 'Component/Shared/GlobalStyles';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useIsMobile } from 'Component/hooks/useIsMobile';
import { BRAND_VOICE_OPEN_QUERY_KEY } from 'libs/constants';
import { PromptCard } from './PromptCard';
import styles from '../chat-athena.module.css';

const customStyles = {
  height: '700px',
  top: '50%',
  left: '50%',
  right: 'auto',
  bottom: 'auto',
  marginRight: '-50%',
  transform: 'translate(-50%, -50%)',
  width: '85vw',
  maxWidth: '1200px',
  maxHeight: '85vh',
};

const BrandVoice = ({
  darkMode,
  show,
  setBrandVoice,
  closeModal: onCloseModal,
}) => {
  const [modalOpen, setModalOpen] = useState(show);
  const { data: brandVoices, isLoading } = useGetBrandVoiceData();
  const isMobile = useIsMobile();

  const closeModal = () => {
    setModalOpen(false);
    onCloseModal();
  };

  const handleCreateNewBrandVoice = () => {
    const { origin } = window.location;
    const route = `${ROUTES.DASHBOARD}?${BRAND_VOICE_OPEN_QUERY_KEY}=true`;
    window.open(origin + route, '_blank');
  };

  const renderPromptCards = () => {
    if (!brandVoices || brandVoices.length === 0) {
      return null;
    }

    return brandVoices.map((brand) => (
      <PromptCard
        darkMode={darkMode}
        name={brand.brand_voice_name}
        onUse={() => {
          setBrandVoice(brand.description);
          closeModal();
        }}
        prompt={brand.description}
        key={brand.brand_voice_name}
      />
    ));
  };

  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={closeModal}
      contentLabel="Search Prompts Modal"
      style={{
        content: {
          ...customStyles,
          backgroundColor: darkMode ? '#343641' : 'white',
          color: darkMode ? 'white' : '343641',
        },
      }}
    >
      <h2 style={{ textAlign: 'center' }}>Brand Voices</h2>

      {brandVoices && brandVoices.length > 0 && (
        <div className={styles.new_brand_voice}>
          <ButtonPrimary
            disabled={isMobile}
            onClick={handleCreateNewBrandVoice}
          >
            <span className="mx-2">New Brand Voice</span>
            <AddRoundedIcon />
          </ButtonPrimary>
        </div>
      )}
      <div className={styles.brand_voice_wrapper}>
        {renderPromptCards()}
        {isLoading && (
          <Flex justify="center" style={{ width: '100%' }}>
            <LoaderSpin />
          </Flex>
        )}
      </div>
      {!isLoading && brandVoices.length === 0 && (
        <div className={styles.not_found_message}>
          <h4>You have not created any brand voice.</h4>
          <ButtonPrimary
            disabled={isMobile}
            onClick={handleCreateNewBrandVoice}
          >
            <span className="mx-2">New Brand Voice</span>
            <AddRoundedIcon />
          </ButtonPrimary>
        </div>
      )}
    </Modal>
  );
};

export default BrandVoice;
