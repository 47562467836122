export const DISPLAY_TEMPLATES_TABLE_HEADER = [
  'Name',
  'Description',
  'Author',
  'Created On',
  'Actions',
];

export const DISPLAY_TEMPLATES_PAGE_HEADER = 'Template Manager';
export const DISPLAY_TEMPLATES_PAGE_DESCRIPTION =
  'Create, Edit, Share, and Organize Your Research Templates';

export const CREATE_TEMPLATES_PAGE_HEADER = 'Create a new Template';
export const CREATE_TEMPLATES_PAGE_DESCRIPTION =
  'Contextually collaborate with your team and clients using Templatized Content Briefs.';

export const EDIT_TEMPLATES_PAGE_HEADER = ' Edit Your Template';
export const EDIT_TEMPLATES_PAGE_DESCRIPTION = '';
