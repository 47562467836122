/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable import/no-unresolved */
/* eslint-disable prefer-regex-literals */
import axios from 'axios';
import createInstance from 'Config/AxiosConfig';

export const getNameInitials = (name) => {
  const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

  let initials = [...name.matchAll(rgx)] || [];

  initials = (
    (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
  ).toUpperCase();

  return initials;
};

export const redeemAppSumoCode = async (username, group_name, code) => {
  try {
    const body = { username, group_name, code };
    const result = await axios.post(
      'https://apis.crawlq.ai/appsumo-stacking',
      body,
    );
    if (result.data.message) {
      // getGroupName()
      return {
        error: false,
        message: result.data.message,
      };
    }
    return {
      error: true,
      message: result.data.body,
    };
  } catch (err) {
    return {
      error: true,
      message: 'Try Again',
    };
  }
};
export const redeemPitchGroundCode = async (username, group_name, code) => {
  try {
    const body = { username, group_name, code };
    const { data } = await axios.post(
      'https://apis.crawlq.ai/pitchground-upgrade',
      body,
    );
    if (data.message) {
      // getGroupName()
      if (data.statusCode === '500') {
        return {
          error: true,
          message: data.message,
        };
      }
      return {
        error: false,
        message: data.message,
      };
    }
    return {
      error: true,
      message: data.body,
    };
  } catch (err) {
    return {
      error: true,
      message: 'Try Again',
    };
  }
};

export const getUpdateduserPlan = async (username) => {
  try {
    const optionsToFomatDate = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    const { data } = await axios.get(
      `https://apis.crawlq.ai/dashboard-userplan?username=${username}`,
    );
    const modified_date = new Date(
      data.data[0].LastModifiedDate,
    ).toLocaleDateString('en-US', optionsToFomatDate);
    const plan_name = data.data[0].Description;
    const { users } = data.data[0];
    const { workspaces } = data.data[0];
    return {
      error: false,
      modified_date,
      plan_name,
      users,
      workspaces,
    };
  } catch (ex) {
    console.log('Error in getting Plan', ex);
    return {
      error: true,
      modified_date: null,
      plan_name: null,
      users: null,
      workspaces: null,
    };
  }
};

export const cancelAddon = async (addon) => {
  const instance = await createInstance();
  const data = await instance.post('/addon-cancel', {
    plan: addon,
  });
  return data.data.statusCode === '200';
};
