/* eslint-disable max-len */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetMarketCategories } from 'queries/market-research/get-market-categories';
import { getModularizeMarketCategory } from 'queries/market-research/selectors';
import { MarketCategoriesLoading } from 'components/skeleton/market-research';
import GoBack from 'components/common/GoBack/GoBack';
import { Divider } from 'components/common/divider';
import { ScaffoldCard } from 'components/common/scaffold-card';
import MarketQuestions from '../MarketQuestions';
import AthenaHelper from '../market-athena/AthenaHelper';
import { ActiveCategoryIcon, DoneIcon, IncompleteIcon } from '../styles';
import AthenaLitsening from '../market-athena/AthenaLitsening/AthenaLitsening';

const Convert = () => {
  const { category } = useParams();

  const {
    data: marketCategories,
    isLoading: isGetMarketCategoriesLoading,
  } = useGetMarketCategories(category);
  const convertCategories =
    getModularizeMarketCategory(marketCategories, 0, 4) ?? [];

  const firstCategory = convertCategories[0]?.category;
  const [SelectedCategory, setSelectedCategory] = useState(
    convertCategories[0],
  );

  const [OpenInsights, setOpenInsights] = useState(false);
  return (
    <>
      <GoBack currentLocation={category} />
      <div className="market-research-convert-wrapper">
        <ScaffoldCard className="market-research-left">
          {isGetMarketCategoriesLoading && <MarketCategoriesLoading />}

          {convertCategories &&
            convertCategories.map((convertCategory, index) => (
              <div
                className="market-categories-wrapper"
                key={convertCategory?.id}
              >
                {index > 0 && <Divider />}
                <div
                  className="market-category-container"
                  onClick={() => setSelectedCategory(convertCategory)}
                >
                  <div className="market-category-selected">
                    {(SelectedCategory?.category || firstCategory) ===
                      convertCategory?.category && <ActiveCategoryIcon />}
                  </div>
                  <div className="market-category-name">
                    {convertCategory?.category}
                  </div>
                  <div className="market-category-status">
                    {' '}
                    {convertCategory?.completed ? (
                      <DoneIcon />
                    ) : (
                      <IncompleteIcon />
                    )}
                  </div>
                </div>
              </div>
            ))}
        </ScaffoldCard>

        <MarketQuestions
          categoryName={SelectedCategory?.category ?? firstCategory}
          section={category}
          IsMinAnsweredCheck={false}
        />

        <aside className="market-research-aside">
          <AthenaHelper
            categoryName={SelectedCategory?.category ?? firstCategory}
          />

          <AthenaLitsening
            categoryName={SelectedCategory?.category ?? firstCategory}
            openInsights={OpenInsights}
            openInsightsFn={setOpenInsights}
          />
        </aside>
      </div>
    </>
  );
};

export default Convert;
