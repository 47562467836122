/* eslint-disable max-len */
import {
  getReduxUserState,
  getReduxWorkspaceState,
} from 'utils/helpers/redux-selectors';
import createInstance from 'Config/AxiosConfig';
import { useQuery } from 'react-query';
import { MARKET_RESEARCH_ENDPOINTS } from 'constants/services';

export const getMarketCategoryQuestions = async (subCategory) => {
  const { email } = getReduxUserState();
  const { name } = getReduxWorkspaceState();

  const instance = await createInstance(true);
  const { data, status } = await instance.get(
    `${MARKET_RESEARCH_ENDPOINTS.GET_MARKET_CATEGORY_QUESTION}=${subCategory}&username=${email}&name=${name}`,
  );

  if (status === 200) return data;

  throw new Error('Unable to fetch Questions, please try opening in new tab.');
};

// Keep Query Value as File Name to maintain consistency and uniqueness in query index
export const GET_MARKET_CATEGORY_QUESTION_QUERY_KEY =
  'get-market-category-questions';

// Query Config Options
const queryOptions = {
  staleTime: 10000,
  cacheTime: 10000,
};

// QUERY
export const useGetMarketCategoryQuestion = (subCategory) =>
  useQuery(
    [GET_MARKET_CATEGORY_QUESTION_QUERY_KEY, subCategory],
    () => getMarketCategoryQuestions(subCategory),
    {
      staleTime: 120000,
      cacheTime: 120000,
    },
  );
