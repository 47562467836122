/* eslint-disable max-len */
import React from 'react';
import './styles.css';
import { ScaffoldCard } from 'components/common/scaffold-card';
import { Divider } from 'components/common/divider';
import { CloudBox } from 'components/common/cloud-box';
import { getMarketSourceByLevel } from 'queries/workspace/selectors';

const PersonaDetail = ({ personaName, personaTarget, personaInfo }) => {
  const marketAttractSources = getMarketSourceByLevel(personaInfo?.configurations?.level1?.sources ?? []);
  const marketConvertSources = getMarketSourceByLevel(personaInfo?.configurations?.level2?.sources ?? []);
  const marketDesireSources = getMarketSourceByLevel(personaInfo?.configurations?.level3?.sources ?? []);
  const marketTags = getMarketSourceByLevel(personaInfo?.tags ?? []);

  return (
    <ScaffoldCard>
      <div className="persona-detail-header-wrapper">
        <span className="persona-detail-header">Persona Details</span>
        <span>Language : <b>{personaInfo?.Lang}</b></span>
      </div>
      <Divider />

      <section className="persona-detail-info-wrapper h-margin-t-xlarge">

        <div className="h-margin-b-x2">
          <div className="persona-detail-info-head">Main Topic Of Interest</div>
          <div className="persona-detail-info-value">{personaName}</div>
        </div>

        <div className="h-margin-b-x2">
          <div className="persona-detail-info-head">Target Persona</div>
          <div className="persona-detail-info-value">{personaTarget}</div>
        </div>

        <div className="h-margin-b-x2">
          <div className="persona-detail-info-head">Domain</div>
          <div className="persona-detail-info-value">{personaInfo?.site}</div>
        </div>

        <div className="h-margin-b-x2">
          <div className="persona-detail-info-head h-margin-b-tiny">Attract</div>
          <div className="persona-detail-info-value">
            {marketAttractSources && marketAttractSources.map((source, index) =>
              <CloudBox key={index}>{source}</CloudBox>)}
          </div>
        </div>

        <div className="h-margin-b-x2">
          <div className="persona-detail-info-head h-margin-b-tiny">Convert</div>
          <div className="persona-detail-info-value ">
            {marketConvertSources && marketAttractSources.map((source, index) =>
              <CloudBox key={index}>{source}</CloudBox>)}
          </div>
        </div>

        <div className="h-margin-b-x2">
          <div className="persona-detail-info-head h-margin-b-tiny">Desire</div>
          <div className="persona-detail-info-value ">
            {marketDesireSources && marketAttractSources.map((source, index) =>
              <CloudBox key={index}>{source}</CloudBox>)}
          </div>
        </div>

        <div className="h-margin-b-x2">
          <div className="persona-detail-info-head">Founder Name</div>
          <div className="persona-detail-info-value">{personaInfo?.founder_name}</div>
        </div>

        <div className="h-margin-b-x2">
          <div className="persona-detail-info-head">Product Name</div>
          <div className="persona-detail-info-value">{personaInfo?.product_name}</div>
        </div>

        <div className="h-margin-b-x2">
          <div className="persona-detail-info-head h-margin-b-tiny">Tags</div>
          <div className="persona-detail-info-value ">
            {marketTags && marketTags.map((source, index) =>
              <CloudBox key={index}>{source}</CloudBox>)}
          </div>
        </div>

      </section>
    </ScaffoldCard>
  );
};

export default PersonaDetail;
