/* eslint-disable consistent-return */
/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import Axios from 'axios';
import Tree from 'react-d3-tree';

const getSearch = async (val) => {
  if (val === '') return { error: true };
  const data = {};
  data.term = val;
  const res = await Axios.post('https://apis.crawlq.ai/search-intent', data);
  return res.data;
};

const getGraph = (data) => (
  <div className="custom-container">
    <div id="treeWrapper" style={{ width: '80rem', height: '50rem' }}>
      <Tree
        data={data || ''}
        rootNodeClassName="node__root"
        branchNodeClassName="node__branch"
        leafNodeClassName="node__leaf"
      />
    </div>
  </div>
);

export const getTabs = (data, inputQuery, tabs) => {
  if (tabs === 0) {
    return getGraph(data['questions graph']);
  } if (tabs === 1) {
    return getGraph(data['comparison graph']);
  } if (tabs === 2) {
    return getGraph(data['preposition graph']);
  } if (tabs === 3) {
    return (
      <div className="suggetion-result">
        <a
          target="_blank"
          href={`https://www.google.com/search?q=${inputQuery}`}
          rel="noreferrer"
        >
          {inputQuery}
        </a>
        <a
          target="_blank"
          href={`https://www.google.com/search?q=What is ${inputQuery}`}
          rel="noreferrer"
        >
          What is
          {' '}
          {inputQuery}
        </a>
        <a
          target="_blank"
          href={`https://www.google.com/search?q=How does ${inputQuery} work`}
          rel="noreferrer"
        >
          How does
          {' '}
          {inputQuery}
          {' '}
          work
        </a>
        <a
          target="_blank"
          href={`https://www.google.com/search?q=How do I use ${inputQuery}`}
          rel="noreferrer"
        >
          How do I use
          {' '}
          {inputQuery}
        </a>
        <a
          target="_blank"
          href={`https://www.google.com/search?q=Meaning of ${inputQuery}`}
          rel="noreferrer"
        >
          Meaning of
          {' '}
          {inputQuery}
        </a>
      </div>
    );
  } if (tabs === 4) {
    return (
      <div className="suggetion-result">
        <a
          target="_blank"
          href={`https://www.google.com/search?q=Affordable ${inputQuery}`}
          rel="noreferrer"
        >
          Affordable
          {' '}
          {inputQuery}
        </a>
        <a
          target="_blank"
          href={`https://www.google.com/search?q=Best ${inputQuery}`}
          rel="noreferrer"
        >
          Best
          {' '}
          {inputQuery}
        </a>
        <a
          target="_blank"
          href={`https://www.google.com/search?q=Cheapest ${inputQuery}`}
          rel="noreferrer"
        >
          Cheapest
          {' '}
          {inputQuery}
        </a>
        <a
          target="_blank"
          href={`https://www.google.com/search?q=Top ${inputQuery}`}
          rel="noreferrer"
        >
          Top
          {' '}
          {inputQuery}
        </a>
        <a
          target="_blank"
          href={`https://www.google.com/search?q=Order ${inputQuery}`}
          rel="noreferrer"
        >
          Order
          {' '}
          {inputQuery}
        </a>
        <a
          target="_blank"
          href={
            `https://www.google.com/search?q=Order ${inputQuery} online`
          }
          rel="noreferrer"
        >
          Order
          {' '}
          {inputQuery}
          {' '}
          online
        </a>
        <a
          target="_blank"
          href={
            `https://www.google.com/search?q=How much does ${
              inputQuery
            } cost`
          }
          rel="noreferrer"
        >
          How much does
          {' '}
          {inputQuery}
          {' '}
          cost
        </a>
      </div>
    );
  } if (tabs === 5) {
    return (
      <div className="suggetion-result">
        <a
          target="_blank"
          href={`https://www.google.com/search?q=Compare ${inputQuery}`}
          rel="noreferrer"
        >
          Compare
          {' '}
          {inputQuery}
        </a>
        <a
          target="_blank"
          href={`https://www.google.com/search?q=Leading ${inputQuery}`}
          rel="noreferrer"
        >
          Leading
          {' '}
          {inputQuery}
        </a>
        <a
          target="_blank"
          href={`https://www.google.com/search?q=${inputQuery} Reviews`}
          rel="noreferrer"
        >
          {inputQuery}
          {' '}
          Reviews
        </a>
        <a
          target="_blank"
          href={`https://www.google.com/search?q=Top 10 ${inputQuery}`}
          rel="noreferrer"
        >
          Top 10
          {' '}
          {inputQuery}
        </a>
        <a
          target="_blank"
          href={`https://www.google.com/search?q=Why ${inputQuery}`}
          rel="noreferrer"
        >
          Why
          {' '}
          {inputQuery}
        </a>
        <a
          target="_blank"
          href={`https://www.google.com/search?q=${inputQuery} Discount`}
          rel="noreferrer"
        >
          {inputQuery}
          {' '}
          Discount
        </a>
        <a
          target="_blank"
          href={`https://www.google.com/search?q=${inputQuery} Offer`}
          rel="noreferrer"
        >
          {inputQuery}
          {' '}
          Offer
        </a>
        <a
          target="_blank"
          href={`https://www.google.com/search?q=${inputQuery} Price`}
          rel="noreferrer"
        >
          {inputQuery}
          {' '}
          Price
        </a>
        <a
          target="_blank"
          href={
            `https://www.google.com/search?q=${inputQuery} Alternatives`
          }
          rel="noreferrer"
        >
          {inputQuery}
          {' '}
          Alternatives
        </a>
      </div>
    );
  }
};

export default getSearch;
