module.exports = {
  AI_CONTENT_REWRITER_COST: 80,
  ATHENA_WRITE_ME_COST: 60,
  CONTENT_COACH_COST: 80,
  PRODUCT_PITCH_COST: 300,
  PRODUCT_STORY_COST: 300,
  TEXT_SUMMARIZER_COST: 80,
  TLDR_COST: 120,
  SALES_COPY_WRITING_COST: 800,
  FILL_WITH_AI_COST: 150,
  MARKET_INSIGHTS_COST: 200,
  MARKET_SPYING_WIZARD_COST: 60,
  ONBOARDING_LEVEL_1_COST: 1000,
  ONBOARDING_FILLWITH_AI_CREDITS: 4265,
  ONBOARDING_FILLWITH_AI_CREDITS_LEVEL_3: 4265,
  CREDITS_FOR_NEW_WORKSPACE: 959,
  HEADLINE_INTRO_COST: 60,
  GENERATE_ANSWER_COST: 80,
  TRAIN_ATHENA_COST: 600,
};
