/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable import/no-unresolved */
import createInstance from 'Config/AxiosConfig';
import { store } from 'redux/store';

const getLanguageEngine = () => {
  const state = store.getState();
  const { language_engine } = state.workspace;
  return language_engine;
};

const getLanguage = () => {
  const state = store.getState();
  const { language } = state.workspace;
  return language;
};

export const getTranslatedText = async (text) => {
  const workspace_lang = getLanguage();
  if (workspace_lang === 'en') return text;
  if (text === '') return '';
  const body = {
    text,
    target_lang: workspace_lang,
    engine: getLanguageEngine(),
    source_lang: 'en',
  };
  const instance = await createInstance();
  const { data } = await instance.post('lang-translate', body);
  return data.answer.trans_text[0];
};

export const getTranslatedTextArray = async (texts) => {
  try {
    const workspace_lang = getLanguage();
    if (workspace_lang === 'en') return texts;
    if (texts.length < 1) return [''];
    const body = {
      text: [...texts],
      target_lang: workspace_lang,
      engine: getLanguageEngine(),
      source_lang: 'en',
    };
    const instance = await createInstance();
    const { data } = await instance.post('lang-translate', body);
    return data.answer.trans_text;
  } catch (ex) {
    console.log('Error in english translation', ex);
    return texts;
  }
};

export const getTranslatedTextEnglish = async (text) => {
  const workspace_lang = getLanguage();
  if (workspace_lang === 'en') return text;
  try {
    if (text === '') return '';
    const body = {
      text,
      target_lang: 'en',
      engine: getLanguageEngine(),
      source_lang: workspace_lang,
    };
    const instance = await createInstance();
    const { data } = await instance.post('lang-translate', body);
    return data.answer.trans_text[0];
  } catch (ex) {
    console.log('Error in english translation', ex);
    return text;
  }
};

export const getTranslatedTextArrayEnglish = async (texts) => {
  const workspace_lang = getLanguage();
  if (workspace_lang === 'en') return texts;
  try {
    if (texts.length < 1) return [''];
    const body = {
      text: [...texts],
      target_lang: 'en',
      engine: getLanguageEngine(),
      source_lang: workspace_lang,
    };
    const instance = await createInstance();
    const { data } = await instance.post('lang-translate', body);
    return data.answer.trans_text;
  } catch (ex) {
    console.log('Error in english translation', ex);
    return texts;
  }
};

const fillWithAILangTrans = async (result) => {
  let texts = [];
  for (let index = 0; index < result.length; index += 1) {
    texts = [...texts, result[index].ai_suggestion];
  }

  const translatedTexts = await getTranslatedTextArray(texts);
  for (let index = 0; index < result.length; index += 1) {
    result[index].ai_suggestion = translatedTexts[index];
  }
  return result;
};

export default fillWithAILangTrans;
