import { ScaffoldCard } from 'components/common/scaffold-card';
import React from 'react';
import { LoadingPlaceholder } from '../LoadingPlaceholder';

export const WorkspaceContentLoading = () => (
  <div>
    {Array(5)
      .fill(null)
      .map((val, index) => (
        <ScaffoldCard
          className="my-workspace-table-content h-margin-b-small"
          key={index}
        >
          <div className="table-content-box m-w-t-c-name-container">
            <LoadingPlaceholder
              width="100px"
              height="15px"
              marginBottom="8px"
            />
            <LoadingPlaceholder width="140px" height="12px" marginTop="4px" />
          </div>
          <div className="table-content-box m-w-t-c-language-container">
            <LoadingPlaceholder width="30px" height="15px" marginBottom="8px" />
          </div>
          <div className="table-content-box m-w-t-c-creation-container">
            <LoadingPlaceholder
              width="100px"
              height="15px"
              marginBottom="8px"
            />
          </div>

          <div className="table-content-box m-w-t-c-action-container">
            <LoadingPlaceholder width="20px" height="15px" marginBottom="8px" />
            <LoadingPlaceholder width="20px" height="15px" marginBottom="8px" />
            <LoadingPlaceholder width="20px" height="15px" marginBottom="8px" />
            <LoadingPlaceholder width="20px" height="15px" marginBottom="8px" />
            <LoadingPlaceholder width="20px" height="15px" marginBottom="8px" />
          </div>
        </ScaffoldCard>
      ))}
  </div>
);
