/* eslint-disable no-unused-vars */
/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { useState } from 'react';
import { CopyFileIcon } from 'components/common';
import { LoaderTailSpin } from 'components/common/Loader/Loader';
import { GET_USER_TEMPLATES_QUERY_KEY } from 'queries/manage-templates/get-users-templates';
import { useQueryClient } from 'react-query';
import { showNotification } from 'libs/notification';
import { cloneTemplateWithId } from '../../template.service';

import styles from './clone.module.css';

function CloneTemplate({ templateId, closeModal }) {
  const [IsError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [NewTemplateName, setNewTemplateName] = useState('');

  const queryClient = useQueryClient();

  const handleChange = (e) => {
    setNewTemplateName(e.target.value);
  };

  const handleClone = async () => {
    setIsLoading(true);
    const isCloned = await cloneTemplateWithId(templateId, NewTemplateName);
    if (isCloned) {
      setIsError(false);
      setIsLoading(false);
      queryClient.invalidateQueries(GET_USER_TEMPLATES_QUERY_KEY);
      showNotification('success', 'Template cloned successfully');
      return;
    }
    setIsLoading(false);
    setIsError(true);
    showNotification('error', 'Error in Cloining template');
  };

  return (
    <div className={styles.container}>
      <div className={styles.header_container}>
        <CopyFileIcon width="32px" height="36px" />
        <div className={styles.header}>Clone Template</div>
      </div>
      <div className={styles.content}>
        <input
          onChange={handleChange}
          className={styles.input}
          value={NewTemplateName}
          placeholder="Enter New Template Name"
        />

        <div className={styles.buttons_container}>
          <div className={styles.button_wrapper}>
            {isLoading ? (
              <LoaderTailSpin width={20} />
            ) : (
              <button
                disabled={!(NewTemplateName.length > 0)}
                className={styles.submit}
                type="button"
                onClick={handleClone}
              >
                Clone
              </button>
            )}
          </div>
          <div className={styles.button_wrapper}>
            <button
              className={styles.cancel}
              type="button"
              onClick={() => closeModal()}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CloneTemplate;
