/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import LoaderSpin from 'Component/Shared/Loader';
import React, { useState } from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import LayersClearIcon from '@mui/icons-material/LayersClear';
import { CustomTooltipMTI } from 'Component/Shared/CustomTooltip';
import { getQueryFromURLParams } from 'Component/Shared/utils';
import { deleteMRRLevelAnswers } from './insightsLogic';

const customStyles = {
  content: {
    width: '500px',
    height: '300px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const modalContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  height: '100%',
};
function ClearAllButtons() {
  const navigate = useNavigate();
  const [modalPop, setModalPop] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const handleDelete = async () => {
    let level = 1;
    const levelInURL = getQueryFromURLParams('level');
    if (levelInURL === '2') {
      level = 2;
    }
    if (levelInURL === '3' && localStorage.getItem('category') !== 'Niche') {
      level = 3;
    }
    setIsDeleting(true);
    const { error } = await deleteMRRLevelAnswers(level);
    setIsDeleting(false);
    if (!error) {
      console.log('deleted');
      reloadProperly();
    }
  };

  const reloadProperly = () => {
    const currentPage = window.location.pathname + window.location.search;
    navigate('/reload');
    setTimeout(() => {
      navigate(currentPage, { replace: true });
    });
  };
  function DeletePopup() {
    return (
      <Modal
        isOpen={modalPop}
        onRequestClose={() => setModalPop(!modalPop)}
        style={customStyles}
        ariaHideApp={false}
      >
        <div style={modalContainerStyle}>
          <DeleteRoundedIcon
            style={{ color: '#ff4e4e', width: '5rem', height: '5rem' }}
          />
          <p>Are you sure you want to remove all the data from this level?</p>
          <div className="workspace-delete-button-wrapper w-100 px-4">
            <button
              onClick={() => {
                setModalPop(false);
              }}
            >
              CANCEL
            </button>
            {isDeleting ? (
              <LoaderSpin />
            ) : (
              <button onClick={handleDelete}>DELETE</button>
            )}
          </div>
        </div>
      </Modal>
    );
  }
  return (
    <>
      <DeletePopup />
      <CustomTooltipMTI title="Clear All and Save" placement="top" arrow>
        <button
          style={{
            background: '#fff',
            padding: '0.3rem',
            borderRadius: '0.4rem',
            color: '#2a9df5',
            border: '1px solid #fd7272',
          }}
          className="mb-2"
          onClick={() => {
            setModalPop(true);
          }}
        >
          <LayersClearIcon />
        </button>
      </CustomTooltipMTI>
    </>
  );
}

export default ClearAllButtons;
