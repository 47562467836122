/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { Flex } from '../GlobalStyles';

export const ChildrenWrapper = styled.div`
  height: calc(100vh - 55px);
  padding-left: 7rem;
  display: flex;
  flex-direction: column;
  ${({ isMobile }) =>
    isMobile &&
    `
    padding: 0 1rem;
  `}
`;

export const ChatGPTButtonWrapper = styled(Flex)`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 1000;
  button {
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    border-color: #74aa9c;
  }
`;
