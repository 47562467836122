/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable default-case */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import React, {
  useEffect, useState, useCallback, useRef,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ClearIcon from '@mui/icons-material/Clear';
import { LoaderTailSpin } from 'Component/Shared/Loader';
import { saveKeywordAction } from 'redux/index';
import TrendingTopics from './component/TrendingTopics';
import CompetingTopics from './component/CompetingTopics';
import IntentQuestion from './component/IntentQuestion';
import KeywordResearch from './component/KeywordResearch';
import FinalList from './component/FinalList';
import { getSeoAthenaKeywords, getCrawlqSearch } from './utils/index';
import {
  TABS, TRENDING_TOPICS, COMPETING_TOPICS, AUDIENCE_VOICE, INTENT_QUESTION, KEYWORD_RESEARCH,
} from './component/constant';
import {
  TagStatus, TabContentWrapper, InputWrapper, Input, SearchButton, ResultWrapper, ResultBox, Text, QAWrapper, Filters, DropdownWrapper, CloudWordWrapper, WordWrapper,
} from './component/styles';

function AuthorityBuilder({ handleSaveAuthorityBuilder }) {
  const athenaSources = useSelector((state) => state.user.athena_sources?.train_option);
  const focusDiv = useRef();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user);
  const active_workspace = useSelector((state) => state?.workspace);
  const workspaceName = active_workspace?.name;
  const existingKeywords = useSelector((state) => state.authorityKeyword[workspaceName]) ?? [];

  const [LimitReached, setLimitReached] = useState(false);
  const [selectedKeywords, setselectedKeywords] = useState(existingKeywords);
  const [SeoAthenaResponse, setSeoAthenaResponse] = useState();
  const [AthenaSearchResponse, setAthenaSearchResponse] = useState([]);
  const [AthenaSearchLoading, setAthenaSearchLoading] = useState(false);
  const [ChoosenTab, setChoosenTab] = useState(TABS[0]);
  const [FILTER_OPTIONS, setFILTER_OPTIONS] = useState([
    { key: 'mrr', value: 'Market Research', checked: false },
    { key: 'mrr_insight', value: 'Market Insights', checked: false },
    { key: 'script', value: 'Sales Copywriting', checked: false },
    { key: 'serp', value: 'Google', checked: false },
    { key: 'quora', value: 'Quora', checked: false },
    { key: 'reddit', value: 'Reddit', checked: false },
    { key: 'website', value: 'URL', checked: false },
  ]);

  const [IsFilterOpen, setIsFilterOpen] = useState(false);
  const [AudienceUserInput, setAudienceUserInput] = useState('');

  useEffect(async () => {
    fetchSeoAthenaKeywords();
  }, []);

  useEffect(() => {
    LimitChecker();
  }, [selectedKeywords]);

  const LimitChecker = () => {
    if (selectedKeywords.length >= 50) {
      setLimitReached(true);
    } else {
      setLimitReached(false);
    }
  };

  useEffect(async () => {
    if (focusDiv.current) focusDiv.current.focus();
  }, [focusDiv]);

  const StatusTab = () => TABS.map((status, index) => (
    <TagStatus
      active={ChoosenTab === status ? 'active-tag' : ''}
      key={index}
      onClick={() => setChoosenTab(status)}
    >
      {status}
    </TagStatus>
  ));

  function SwitchChoosenTab() {
    switch (ChoosenTab) {
      case AUDIENCE_VOICE:
        return (
          <AudienceVoiceResultComponent
            audienceInput={AudienceUserInput}
          />
        );

      case TRENDING_TOPICS:
        return (
          <TrendingTopics
            limitReached={LimitReached}
            selectedKeywords={selectedKeywords}
            searchIntentKeyword={SeoAthenaResponse?.search_intent_keywords || []}
            onChangeCheckbox={(event) => handleCheckbox(event)}
          />
        );

      case COMPETING_TOPICS:
        return (
          <CompetingTopics
            limitReached={LimitReached}
            selectedKeywords={selectedKeywords}
            topicKeyword={SeoAthenaResponse?.topic_keywords || []}
            onChangeCheckbox={(event) => handleCheckbox(event)}
          />
        );

      case INTENT_QUESTION:
        return (
          <IntentQuestion
            selectedKeywords={selectedKeywords}
            limitReached={LimitReached}
            intentQuestion={SeoAthenaResponse?.questions_graph || {}}
            onChangeCheckbox={(event) => handleCheckbox(event)}
          />
        );

      case KEYWORD_RESEARCH:
        return (
          <KeywordResearch
            limitReached={LimitReached}
            addToFinalList={handleAddCustomKeywords}
          />
        );
    }
  }

  const isChecked = (item) => (!!selectedKeywords.includes(item));

  const handleFilterToggle = () => setIsFilterOpen((IsFilterOpen) => !IsFilterOpen);

  const getSources = async () => {
    const sources = [];

    FILTER_OPTIONS.forEach((option) => {
      if (option.checked) {
        sources.push(option.key);
      }
    });

    return sources;
  };

  const handleAthenaSearch = async () => {
    setAthenaSearchLoading(true);
    const sources = await getSources();
    const response = await getCrawlqSearch(AudienceUserInput, sources);
    setAthenaSearchResponse(response);
    setAthenaSearchLoading(false);
  };

  function FilterDropDown() {
    return (
      <DropdownWrapper>
        {FILTER_OPTIONS.map((option, index) => (athenaSources && athenaSources.includes(option.key)
          ? (
            <div
              className="global-f-bold"
              style={{
                display: 'flex',
                flex: '1',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              key={index}
            >
              <input

                type="checkbox"
                value={option.key}
                checked={option.checked}
                onChange={() => handleFilterChange(index)}
              />
              <div className="train_options ml-2">{option.value}</div>
            </div>
          )
          : (
            <div
              style={{
                display: 'flex',
                flex: '1',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              key={index}
            >
              <input
                type="checkbox"
                value={option.key}
                checked={false}
              />
              <div className="train_options ml-2 text-muted">
                {option.value}
              </div>
            </div>
          )))}
      </DropdownWrapper>
    );
  }

  function SelectedFilterComponent() {
    return (
      <div className="global-d-flex-row">
        {FILTER_OPTIONS.map((option, index) => (
          <div>
            {option.checked && (
              <CloudWordWrapper className="global-fs-secondary" key={index}>
                <WordWrapper>
                  <div>{option.value}</div>
                  <ClearIcon
                    onClick={() => console.log('remove')}
                    style={{ width: '18px', marginLeft: '6px' }}
                  />
                </WordWrapper>
              </CloudWordWrapper>
            )}
          </div>
        ))}
      </div>
    );
  }

  function AudienceVoiceResultComponent({ audienceInput }) {
    const handleSearchByEnter = async (event) => {
      if (event.key === 'Enter') {
        handleAthenaSearch(AudienceUserInput);
      }
    };

    return (
      <div className="h-padding-a-xsmall">
        <InputWrapper>
          <Input
            ref={focusDiv}
            type="text"
            placeholder="CrawlQ Search.."
            value={audienceInput}
            onChange={(event) => setAudienceUserInput(event.target.value)}
            onKeyPress={handleSearchByEnter}
            autoFocus
          />

          <div>
            <Filters onClick={handleFilterToggle}>
              Filters
              {' '}
              {IsFilterOpen ? <ArrowDropUpIcon style={{ width: '16px' }} /> : <ArrowDropDownIcon style={{ width: '16px' }} />}
            </Filters>
            {IsFilterOpen && <FilterDropDown />}
          </div>

          <SearchButton onClick={handleAthenaSearch}>
            {AthenaSearchLoading ? 'Searching' : 'Search'}
          </SearchButton>

        </InputWrapper>

        <SelectedFilterComponent />

        <ResultWrapper>
          {AthenaSearchResponse && AthenaSearchResponse.map((response) => (
            <ResultBox>
              <Text className="global-f-bold global-fc-accent global-fs-primary">
                <span>Q : </span>
                {response.question}
                {' '}
                ?
              </Text>
              <QAWrapper>
                <input
                  className="h-margin-t-xsmall"
                  type="checkbox"
                  defaultChecked={isChecked(response.answer)}
                  disabled={LimitReached}
                  value={response.answer}
                  onChange={(event) => handleCheckbox(event)}
                />
                <span className="h-margin-l-xsmall">{response.answer}</span>
              </QAWrapper>
            </ResultBox>
          ))}
        </ResultWrapper>
      </div>
    );
  }

  const handleFilterChange = (index) => {
    const filterOptionsArray = [...FILTER_OPTIONS];
    filterOptionsArray[index].checked = !filterOptionsArray[index].checked;
    setFILTER_OPTIONS([...filterOptionsArray]);
  };

  const fetchSeoAthenaKeywords = async () => {
    const response = await getSeoAthenaKeywords(user?.email, workspaceName, active_workspace?.mti);
    setselectedKeywords(response?.keywords_to_use);
    setSeoAthenaResponse(response);
    dispatch(saveKeywordAction([workspaceName, response?.keywords_to_use]));
  };

  const handleAddCustomKeywords = (value) => {
    const choosenKeywords = [...selectedKeywords];
    const isPresent = choosenKeywords.includes(value);
    if (isPresent) {
      const newChoosenKeywords = choosenKeywords.filter((item) => item !== value);
      setselectedKeywords(newChoosenKeywords);
    } else {
      choosenKeywords.push(value);
      setselectedKeywords(choosenKeywords);
    }
  };

  const handleRemoveKeywords = (value) => {
    const choosenKeywords = [...selectedKeywords];

    const newChoosenKeywords = choosenKeywords.filter((item) => item !== value);
    const workspaceName = localStorage.getItem('name');
    setselectedKeywords(newChoosenKeywords);
    dispatch(saveKeywordAction([workspaceName, newChoosenKeywords]));
  };

  const handleCheckbox = useCallback((event) => {
    const choosenKeywords = [...selectedKeywords];

    const isPresent = choosenKeywords.includes(event.target.value);

    if (isPresent) {
      const newChoosenKeywords = choosenKeywords.filter((item) => item !== event.target.value);
      setselectedKeywords(newChoosenKeywords);
    } else {
      choosenKeywords.push(event.target.value);
      setselectedKeywords(choosenKeywords);
    }
  });

  if (SeoAthenaResponse) {
    return (
      <div className="global-d-flex-col">
        <div className="global-d-flex-row h-margin-b-small">
          <StatusTab />
        </div>

        <div style={{ height: '85vh' }} className="global-d-flex-row">
          <TabContentWrapper style={{ flex: 0.7 }}>
            <SwitchChoosenTab />
          </TabContentWrapper>
          {(selectedKeywords.length > 0)
            && (
              <div style={{ flex: 0.3 }}>
                <FinalList
                  choosenList={selectedKeywords}
                  removeWordFromList={handleRemoveKeywords}
                  limitReached={LimitReached}
                  handleSaveAuthorityBuilder={handleSaveAuthorityBuilder}
                />
              </div>
            )}
        </div>

      </div>
    );
  }

  return (
    <div className="global-d-flex-col" style={{ height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
      <LoaderTailSpin />
    </div>

  );
}

export default AuthorityBuilder;
