/* eslint-disable no-plusplus */
import createInstance from 'Config/AxiosConfig';
import { ROUTES } from 'constants/routes';
import { useQuery } from 'react-query';
import {
  getReduxUserState,
  getReduxWorkspaceState,
} from 'utils/helpers/redux-selectors';

export const getMarketProgressPercentage = async () => {
  const user = getReduxUserState();
  const workspace = getReduxWorkspaceState();

  try {
    const body = {
      name: workspace?.name,
      username: user?.username,
    };
    const instance = await createInstance();
    const response = await instance.post('dashboard-mrr-progress', body);

    if (response?.data?.statusCode !== '200') {
      throw new Error('Could not fetch');
    }

    const mrrProgress = [];
    const mrrObject = Object.values(response?.data?.data);
    const mrrKeys = ['Attract', 'Desire', 'Convert'];
    const mrrLinks = [
      ROUTES.MARKET_RESEARCH,
      ROUTES.MARKET_RESEARCH_LEVEL_2,
      ROUTES.MARKET_RESEARCH_LEVEL_3,
    ];

    for (let i = 0; i < mrrObject.length; i++) {
      const response = {};
      response.key = mrrKeys[i];
      response.link = mrrLinks[i];
      response.value = mrrObject[i];
      mrrProgress.push(response);
    }

    return mrrProgress;
  } catch (ex) {
    console.log({ ex });
    return ['-', '-', '-'];
  }
};

export const useMRRProgress = () =>
  useQuery([], getMarketProgressPercentage, { staleTime: 30 * 1000 });
