import {
  MARKET_RESEARCH,
  MARKET_RESEARCH_DESIRE,
  MARKET_RESEARCH_CONVERT,
} from 'constants/market-research';
import React from 'react';
import './market-research.css';
import { Link, useLocation, Outlet } from 'react-router-dom';
import { MarketResearchProvider } from 'context/market-research/MarketResearchContext';
import AppHeaderWrapper from 'components/common/AppHeader/AppHeaderWrapper';
import { HeaderCard } from 'components/common/HeaderCard/HeaderCard';

const MarketResearch = () => {
  const { pathname } = useLocation();

  return (
    <MarketResearchProvider>
      <AppHeaderWrapper className="market-research">
        <HeaderCard
          className="market-research-container"
          title="Market Research"
          description="Tailor your Market Research from data sources around the globe"
        >
          <div className="market-research-links-wrapper">
            <Link
              to={MARKET_RESEARCH}
              className={
                pathname === MARKET_RESEARCH
                  ? 'market-research-link-container-active'
                  : 'market-research-link-container'
              }
            >
              <span className="market-research-link">Attract</span>
            </Link>

            <Link
              to={MARKET_RESEARCH_DESIRE}
              replace
              className={
                pathname.includes(MARKET_RESEARCH_DESIRE)
                  ? 'market-research-link-container-active'
                  : 'market-research-link-container'
              }
            >
              <span className="market-research-link">Desire</span>
            </Link>

            <Link
              to={MARKET_RESEARCH_CONVERT}
              replace
              className={
                pathname.includes(MARKET_RESEARCH_CONVERT)
                  ? 'market-research-link-container-active'
                  : 'market-research-link-container'
              }
            >
              <span className="market-research-link">Convert</span>
            </Link>
          </div>
        </HeaderCard>

        <div className="market-research-outlet-container">
          <Outlet />
        </div>
      </AppHeaderWrapper>
    </MarketResearchProvider>
  );
};

export default MarketResearch;
