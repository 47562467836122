import React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Label } from '../styles';

export default function RadioButtonsGroup({ value, setValue }) {
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">
        <Label>User&apos;s Scope</Label>
      </FormLabel>
      <RadioGroup row value={value} onChange={handleChange}>
        <FormControlLabel
          value="agency_client"
          control={<Radio color="primary" />}
          label="Client"
        />
        <FormControlLabel
          value="agency_user"
          control={<Radio color="primary" />}
          label="User"
        />
      </RadioGroup>
    </FormControl>
  );
}
