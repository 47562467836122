/* eslint-disable no-unused-vars */
/* eslint-disable no-return-assign */
/* eslint-disable func-names */
/* eslint-disable space-before-function-paren */
/* eslint-disable new-cap */
export const exportAsPDF = (category, questionAnswerObject) => {
  let htmlStr = `
    <title style={display:none}>${category}</title><style>
    table{
     font-size:9px
    }
    table{margin:auto} h2{margin-bottom:10px}</style><h2>${category}</h2><table>
    <tr><th>Question</th><th>Answer</th></tr>`;

  Object.keys(questionAnswerObject).map(
    (qna) =>
      (htmlStr += `<tr><td>${qna}</td><td>${questionAnswerObject[qna]}</td></tr>`),
  );

  console.log(htmlStr);
  const pdf = new window.jsPDF();
  const specialElementHandlers = {
    '#editor': function(element, renderer) {
      return true;
    },
  };
  pdf.setFontSize(2);
  pdf.fromHTML(htmlStr, 30, 15, {
    elementHandlers: specialElementHandlers,
  });
  pdf.save(`${category}.pdf`);
};

const loadScript = async (src) => {
  const tag = document.createElement('script');
  tag.async = false;
  tag.src = src;
  const body = document.getElementsByTagName('body')[0];
  body.appendChild(tag);
};

export const loadPdfJS = async () => {
  await loadScript(
    'https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.3.2/jspdf.debug.js',
  );
};
