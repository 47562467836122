import { baseAPIsUrl } from 'Config/Api';
import createInstance from 'Config/AxiosConfig';
import { useQuery } from 'react-query';

const fetchAllTemplates = async () => {
  try {
    const instance = await createInstance();
    const { data } = await instance.post(
      'https://p8dapk4yf9.execute-api.us-east-2.amazonaws.com/prod/get-user-blog-template',
      {
        include_all: true,
      },
    );
    if (data.statusCode === '200') return data.templates;
    throw new Error('Error in fetching user blog template');
  } catch (ex) {
    return { error: true, data: [] };
  }
};

// Keep Query Value as File Name to maintain consistency and uniqueness in query index
const QUERY_KEY = 'get-all-templates';

// Query Config Options
const queryOptions = {
  staleTime: 10 * 60 * 1000,
  // 10 mins
};

// QUERY
export const useGetAllTemplates = () =>
  useQuery([QUERY_KEY], () => fetchAllTemplates(), queryOptions);
