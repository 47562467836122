import React from 'react';
import { useDashboardContext } from 'context/dashboard/DashboardContext';
import HelpCard from '../dashboard-elements/HelpCard/HelpCard';
import VideoTuts from '../dashboard-elements/VideoTuts/VideoTuts';
import styles from './dashboard-right.module.css';
import Insights360 from '../dashboard-elements/Insights360/Insights360';

function DashboardRight() {
  const { videoState } = useDashboardContext();

  const [VideoLink] = videoState;

  return (
    <div className={styles.dashboard_right_container}>
      <HelpCard />
      {VideoLink && <VideoTuts link={VideoLink} />}
      <Insights360 />
    </div>
  );
}

export default DashboardRight;
