import React, { useState, useEffect } from 'react';
import AppHeaderWrapper from 'components/common/AppHeader/AppHeaderWrapper';
import { DashboardProvider } from 'context/dashboard/DashboardContext';
import { HeaderCard } from 'components/common/HeaderCard/HeaderCard';
import Flyout from 'components/common/FlyOut/FlyOut';
import BrandVoice from 'components/brand-voice/BrandVoice';
import { getQueryFromURLParams } from 'Component/Shared/utils';
import { BRAND_VOICE_OPEN_QUERY_KEY } from 'libs/constants';
import { ToastContainer } from 'react-toastify';
import DashboardLeft from './dashboard-left/DashboardLeft';
import DashboardCenter from './dashboard-center/DashboardCenter';
import DashboardRight from './dashboard-right/DashboardRight';
import styles from './dashboard.module.css';

function Dashboard() {
  const [isBrandVoiceBuilderOpen, setIsBrandVoiceBuilderOpen] = useState(false);

  useEffect(() => {
    const openBrandVoice = getQueryFromURLParams(BRAND_VOICE_OPEN_QUERY_KEY);
    if (openBrandVoice === 'true') {
      setIsBrandVoiceBuilderOpen(true);
    }
  }, []);

  return (
    <AppHeaderWrapper className={styles.dashboard_container}>
      <DashboardProvider>
        <ToastContainer />
        <Flyout
          width="80vw"
          open={isBrandVoiceBuilderOpen}
          onClose={() => setIsBrandVoiceBuilderOpen(false)}
          title="Brand Voice"
          subtitle="Beta"
        >
          <BrandVoice />
        </Flyout>
        <HeaderCard
          className={styles.dashboard_header_container}
          title="Dashboard"
          description=" We've rounded up some information for you which will help you
              achieve your goals and objectives quickly."
        />

        <main className={styles.dashboard_main_container}>
          <div className={styles.dashboard_left_container}>
            <DashboardLeft />
          </div>
          <div className={styles.dashboard_center_container}>
            <DashboardCenter />
          </div>
          <div className={styles.dashboard_right_container}>
            <DashboardRight />
          </div>
        </main>
      </DashboardProvider>
    </AppHeaderWrapper>
  );
}

export default Dashboard;
