import { useMutation, useQueryClient } from 'react-query';
import { store } from 'redux/store';
import createInstance from 'Config/AxiosConfig';
import { BRAND_VOICE_QUERY_KEY } from './brand-voice-data';

const deleteBrandVoiceData = async (brandVoiceName) => {
  const state = store.getState();
  const active_workspace = state.workspace;
  const { user } = state;

  const body = {
    name: active_workspace?.name,
    brand_voice_name: brandVoiceName,
    username: user?.username,
  };

  const instance = await createInstance();

  const response = await instance.post('/delete-brand-voice', body);
  if (response.data.statusCode === '200') {
    return response.data.data;
  }
  throw new Error('Failed to delete brand voice data');
};

export const useDeleteBrandVoice = () => {
  const queryClient = useQueryClient();
  return useMutation((brandVoiceName) => deleteBrandVoiceData(brandVoiceName), {
    onSuccess: () => {
      queryClient.invalidateQueries(BRAND_VOICE_QUERY_KEY);
    },
  });
};
