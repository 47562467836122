import { useMutation, useQueryClient } from 'react-query';
import createInstance from 'Config/AxiosConfig';

const WORKSPACE_EXIST_ERROR = 'Project Name already exists';

const cloneWorkspace = async (payload) => {
  const instance = await createInstance();

  const response = await instance.post('clone-project', payload);

  if (response?.data?.body === WORKSPACE_EXIST_ERROR) throw new Error('Workspace name exists');

  return response;
};

export const useMutateCloneWorkspace = () => {
  const queryClient = useQueryClient();
  return useMutation(cloneWorkspace, {

    onSuccess: () => queryClient.invalidateQueries('get-users-all-workspaces'),
  });
};
