import { LoadingPlaceholder } from 'components/skeleton/LoadingPlaceholder';
import React from 'react';

export const AnswerLoading = () => (
  <div>
    <LoadingPlaceholder height="15px" width="100%" marginTop="5px" />
    <LoadingPlaceholder height="15px" width="70%" marginTop="3px" />
    <LoadingPlaceholder height="15px" width="60%" marginTop="3px" />
  </div>
);
