import React, { useState } from 'react';
import Modal from 'react-modal';
import ReactPlayer from 'react-player';
import VideocamRoundedIcon from '@mui/icons-material/VideocamRounded';
import CustomTooltip from '../CustomTooltip';

const ModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width: '850px',
    height: '480px',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'hidden',
  },
};

function HelperVideoModal({ isOpen, modalCloser, videoLink }) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => modalCloser(false)}
      style={ModalStyles}
      ariaHideApp={false}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '850px',
          height: '480px',
        }}
      >
        <ReactPlayer
          url={videoLink}
          // playing={true}
          width="100%"
          height="100%"
          controls
          loop
          muted
          config={{ file: { attributes: { controlsList: 'nodownload' } } }}
        />
      </div>
    </Modal>
  );
}

function HelperVideoModalPopup({ tooltipTitle, videoLink }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <HelperVideoModal
        isOpen={isModalOpen}
        modalCloser={setIsModalOpen}
        videoLink={videoLink}
      />
      <CustomTooltip title={tooltipTitle} placement="bottom" arrow>
        <div
          className="custom_center"
          style={{
            height: '40px',
            width: '40px',
          }}
        >
          <VideocamRoundedIcon
            style={{
              height: '30px',
              width: '30px',
            }}
            onClick={setIsModalOpen}
          />
        </div>
      </CustomTooltip>
    </>
  );
}

export default HelperVideoModalPopup;
