import createInstance from 'Config/AxiosConfig';
import { showNotification } from 'libs/notification';
import { useMutation, useQueryClient } from 'react-query'; // Import queryClient
import { GET_ASSISTANTS_QUERY_KEY } from './get-assistants';

export const useCloneAssistant = () => {
  const queryClient = useQueryClient();

  const cloneAssistantById = async (id) => {
    try {
      const instance = await createInstance();
      const { data } = await instance.post('copy-assistant-from-url', {
        url: `https://app.crawlq.ai/assistant/?from=support@quantamixsolutions.com&assistant_id=${id}`,
      });

      if (data?.statusCode === '200') {
        return data;
      }
      throw new Error('Assistant could not be cloned. Please try again!');
    } catch (err) {
      throw new Error('Assistant could not be cloned. Please try again!');
    }
  };

  return useMutation((id) => cloneAssistantById(id), {
    onSuccess: (data) => {
      showNotification(
        'success',
        'Assistant Clone',
        'Now it is ready to be used',
      );
      queryClient.invalidateQueries([GET_ASSISTANTS_QUERY_KEY]);
    },
    onError: (error) => {
      showNotification('error', 'Cloning Error', error.message);
    },
  });
};
