/* eslint-disable consistent-return */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import ButtonNext, { Button } from 'Component/Shared/ButtonNext';
import { CreditManage } from 'Component/Shared/CreditManager';
import CustomTooltip from 'Component/Shared/CustomTooltip';
import { ONBOARDING_FILLWITH_AI_CREDITS_LEVEL_3 } from 'Component/Shared/featuresCreditsCost';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import '../onBoarding.css';
import { onBoardingFillWithAILevel3 } from './Level3Logic';
import Progressbar from './Progressbar';

function LevelThreePopUpBody({ answers }) {
  const navigate = useNavigate();
  const athena_sources = useSelector(
    (state) => state.user.athena_sources?.train_option,
  );

  const [isErrorInSubmission, setIsErrorInSubmission] = useState(false);
  const [savedSuccessfully, setSavedSuccessfully] = useState();
  const [showProgressbar, setShowProgressbar] = useState(false);
  const [apiFullfilledSucessfully, setApiFullfilledSucessfully] = useState(
    false,
  );
  const [filterOptions, setFilterOptions] = useState([
    { key: 'mrr', value: 'Market Research', checked: true },
    { key: 'mrr_insight', value: 'Market Insights', checked: true },
    { key: 'script', value: 'Sales Copywriting', checked: true },
    { key: 'serp', value: 'Search Intent', checked: true },
    { key: 'website', value: 'URL', checked: true },
    { key: 'Quora', value: 'Quora', checked: true },
    { key: 'Reddit', value: 'Reddit', checked: true },
  ]);
  const [isCreditsOver, setIsCreditsOver] = useState(false);
  const [savingError, setSavingError] = useState(false);

  const handleSubmit = async () => {
    setSavedSuccessfully(false);
    setShowProgressbar(true);
    const sources = filterOptions
      .map((option) => {
        if (
          option.checked
          && !!athena_sources
          && athena_sources.includes(option.key)
        ) return option.key;
      })
      .filter((option) => !!option);
    const { success } = await CreditManage(
      ONBOARDING_FILLWITH_AI_CREDITS_LEVEL_3,
      true,
    );
    if (!success) {
      setIsCreditsOver(true);
      return;
    }
    const response = await onBoardingFillWithAILevel3(sources);
    if (!response || response.error) {
      setSavingError('Something went wrong! Please try again..');
    } else {
      setApiFullfilledSucessfully(true);
      CreditManage(ONBOARDING_FILLWITH_AI_CREDITS_LEVEL_3);
    }
    setShowProgressbar(false);
  };

  const handleSourceFilterSelect = (index) => {
    if (showProgressbar) return;
    const filters = [...filterOptions];
    filters[index].checked = !filters[index].checked;
    setFilterOptions([...filterOptions]);
  };

  function SourceFilterSelecter() {
    return (
      <div>
        <div className="question text-black d-flex">
          <span className="mx-1">Athena Sources</span>
          <CustomTooltip
            title="Athena will use the following sources on which it is trained to search and fill the answers."
            placement="top"
            arrow
          >
            <InfoOutlinedIcon color="grey" fontSize="small" />
          </CustomTooltip>
        </div>
        {athena_sources ? (
          <div className="onboarding-qna-source-filter">
            {filterOptions.map(
              (option, index) => athena_sources.includes(option.key) && (
              <span
                onClick={() => handleSourceFilterSelect(index)}
                key={index}
              >
                {option.checked ? (
                  <CheckCircleOutlineRoundedIcon />
                ) : (
                  <RadioButtonUncheckedRoundedIcon />
                )}
                {option.value}
              </span>
              ),
            )}
          </div>
        ) : (
          <div className="text-center">
            <b className="text-warning">
              No sources selected as Athena is not trained
            </b>
          </div>
        )}
      </div>
    );
  }

  useEffect(() => {
    if (answers) {
      console.log(answers);
    }
  }, [answers]);

  return (
    <div
      style={{
        width: '100%',
        boxShadow: 'none',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-evenly',
      }}
    >
      <div className="onBoarding_level2_header">
        <h3 className="text-center font-primary" style={{ flex: '1' }}>
          Onboarding: Bottom of the funnel
        </h3>
      </div>
      <div className="page-container" style={{ height: '100%' }}>
        <div
          className="que-container"
          style={{
            width: '100%',
            boxShadow: 'none',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'space-evenly',
          }}
        >
          <SourceFilterSelecter />

          {apiFullfilledSucessfully ? (
            <div className="onboarding_api_fullfill_container">
              <span>✅ Completed</span>
              <ButtonNext
                onClick={() => {
                  navigate('/Market-Resonance-Level-3');
                }}
              />
            </div>
          ) : (
            <div
              className="onboading_submit_container"
              style={showProgressbar ? { justifyContent: 'center' } : {}}
            >
              {isErrorInSubmission && (
                <span className="mr-2 text-danger">
                  *All fields are compulsory
                </span>
              )}
              <div className="onBoading_save_submit_container">
                {showProgressbar ? (
                  <div style={{ width: '500px' }}>
                    <Progressbar />
                  </div>
                ) : (
                  <Button name="Get Started" onClick={handleSubmit} />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default LevelThreePopUpBody;
