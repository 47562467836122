/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import React, { useState, useContext, useEffect } from 'react';
import '../css/score_OverviewSection.css';
import OverviewscoreSection from './score_Card';
import { AiContent } from '../contexts/ai_content';

export function ScoreOverviewsection(props) {
  const [headlines, setHeadlines] = useState();

  const { aiContent, setaiContent } = useContext(AiContent);

  useEffect(() => {
    window.scrollTo(0, 0);
    handleEstimateReadTime();
  }, []);

  // Calculates Read Time Only First time
  const handleEstimateReadTime = () => {
    try {
      const iframe = document.querySelector('#texteditor_ifr');
      const result_document = iframe.contentWindow.document.getElementById(
        'tinymce',
      ).innerText;
      const wpm = 225;
      const words = result_document.trim().split(/\s+/).length;
      const time = Math.ceil(words / wpm);
      document.getElementById('read_time').innerText = time;
    } catch (ex) {
      console.log('Error in Estimating Time', ex);
    }
  };

  if (typeof aiContent === 'undefined') {
    return <div>Show loader</div>;
  }

  return (
    <div className="overview border-rounded-light no-pad">
      <div className="overivew__Score m-2">
        <div className="oveview__ScoreLeft">
          <div className="score-box">
            <div>
              <div className="avg_score font-accent">
                <span id="read_time">0</span>
                <span className="read_time_min">min</span>
              </div>
              <div className="score_name text-primary font-accent">
                Read
                <br />
                Time
              </div>
            </div>
          </div>
        </div>
        <div className="oveview__ScoreLeft">
          <OverviewscoreSection
            score_name="Average Headers"
            id="header-count"
            avg_score={aiContent.overview.avg_headers}
          />
        </div>
        <div className="oveview__ScoreRight">
          <OverviewscoreSection
            score_name="Average Images"
            id="img-count"
            avg_score={aiContent.overview.avg_img}
          />
        </div>
        <div className="oveview__ScoreLeft">
          <OverviewscoreSection
            score_name="Average Words"
            id="word-count"
            avg_score={aiContent.overview.avg_words}
          />
        </div>
        <div className="oveview__ScoreRight">
          <OverviewscoreSection
            score_name="Average Topic&nbsp;Score"
            id="topic_score"
            avg_score={100}
          />
        </div>
      </div>
    </div>
  );
}
export default ScoreOverviewsection;
