/* eslint-disable indent */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-redeclare */
/* eslint-disable no-empty */
/* eslint-disable block-scoped-var */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
/* eslint-disable no-plusplus */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import Axios from 'axios';
import { CreditManage } from 'Component/Shared/CreditManager';
import { FILL_WITH_AI_COST } from 'Component/Shared/featuresCreditsCost';
import MRR_TabSwitcher from 'Component/Shared/TabSwitcher/ThreeTabMRR';
import AppHeaderWrapper from 'components/common/AppHeader/AppHeaderWrapper';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// SVG Imports
import RightArrow from '../../../../../assets/images/right-arrow.svg';
import GreeenTick from '../../../../../assets/images/tick-green.svg';
import GreyTick from '../../../../../assets/images/tick-grey.svg';
import {
  baseAPIsUrl,
  marketResearchQuestion,
  marketResearchSave,
  marketResearchSubCategory,
} from 'Config/Api';
import CreditUpdater from '../../../../Shared/CreditManager';
import {
  exportAllAsCSV,
  exportAsCSV,
} from '../../../../Shared/CSVDownload/csvDownload';
// -------------
import LoaderSpin from '../../../../Shared/Loader';
import PdfDownload, {
  exportAllAsPdf,
  loadPdfJS,
} from '../../../../Shared/PdfDownload/pdfdownload';
import Topnav from '../../../../Shared/Sidenav/Topnav';
import Topbar from '../../../../Shared/Topbar/Topbar';
import { getTranslatedTextArray } from './fILLAiLangTrans';
import { fillWithAILevel1, fillWithAILevel2 } from './fillwithAILogic';
import Insights from './insights/Insights';
import './MarketResearchCategory.css';
import {
  convertAnswerIntoPostFormat,
  modelClearAnswer,
  modelExampleAnswer,
} from './MarketResearchHelper';
import TrainAthenaMRR from './TrainAthenaInMRR/TrainAthenaMRR';
import { useSelector } from 'react-redux';

function MarketResearchCategory(props) {
  const navigate = useNavigate();

  const { username } = useSelector((state) => state?.user);
  const { name } = useSelector((state) => state?.workspace);

  const [CategoryKey, setCategoryKey] = useState([]);
  const [CategoryName, setCategoryName] = useState('');
  const [IsCategoryKeyFetched, setIsCategoryKeyFetched] = useState(false);
  const [ActiveTabSubCategory, setActiveTabSubCategory] = useState(0);
  const [IsCategoryDone, setIsCategoryDone] = useState([]);
  const [CategoryFormData, setCategoryFormData] = useState();
  const [IsCategoryFormDataFetched, setIsCategoryFormDataFetched] = useState(
    false,
  );

  const [SubCategoryName, setSubCategoryName] = useState('');
  const [isActiveExampleFilled, setIsActiveExampleFilled] = useState(false);
  const [IsMinQueAnswered, setIsMinQueAnswered] = useState(true);
  const [SubCategoryAnswers, setSubCategoryAnswers] = useState({});
  const [IsFillAILoading, setIsFillAILoading] = useState(false);
  const [IsSaving, setIsSaving] = useState(false);

  const [FromSave, setFromSave] = useState(false);
  const [IsCreditFail, setIsCreditFail] = useState(false);
  const [currentLevel, setCurrentLevel] = useState(1);
  const [trainAthena, setTrainAthena] = useState({
    isLoading: false,
    isError: false,
  });
  const [CategoryInsightsData, setCategoryInsightsData] = useState();

  useEffect(() => {
    loadPdfJS();

    // Fetch Sub Category of MRR
    handleFetchCategory();
    checkTopFourDone();
    setCurrentLevelFromQuery();
  }, []);

  const getQueryFromURLParams = (query) => {
    const urlString = window.location.href;
    const url = new URL(urlString);
    return url.searchParams.get(query);
  };

  const setCurrentLevelFromQuery = () => {
    const mrr_level = getQueryFromURLParams('level');
    if (mrr_level === '2') {
      setCurrentLevel(2);
    } else if (mrr_level === '3') {
      setCurrentLevel(3);
      validateLevelURL();
    }
  };

  const validateLevelURL = () => {
    const subCategoryQuery = getQueryFromURLParams('subcategory');
    console.log('validateLevelURL', subCategoryQuery);
    if (!subCategoryQuery) {
      navigate('/Market-Resonance', { replace: true }); // Change to better url
    }
  };

  const checkTopFourDone = async () => {
    const { data } = await Axios.get(
      `${baseAPIsUrl}/subcategory?section=Niche&username=${username}&name=${name}`,
    );
    const doneFourCategories = Object.values(data.flags).splice(0, 4);
    const nextCategories = Object.values(data.flags).splice(4, 100);
    if (doneFourCategories.includes('n') && !nextCategories.includes('y')) {
      navigate(
        '/MRR-Get-Started',
        { replace: true },
        { state: { getStarted: true } },
      );
    }
  };

  // Subcategory of MRR fetch on Page Load
  const handleFetchCategory = async () => {
    let isOnLEVEL2 = false;
    let section = 'Niche';
    if (getQueryFromURLParams('level') === '2') {
      isOnLEVEL2 = true;
      section = 'Niche';
    }
    if (getQueryFromURLParams('level') === '3') {
      section = getQueryFromURLParams('subcategory');
    }
    try {
      const result = await Axios.get(
        `${marketResearchSubCategory}?section=${section}&username=${username}&name=${name}`,
      );
      if (result.data.statusCode === '500') {
        navigate('/Market-Resonance', { replace: true }); //  Change to better url
      }
      let category_name = Object.keys(result.data.flags);
      let category_done = Object.values(result.data.flags);

      if (isOnLEVEL2) {
        // Removing TOP in Level 2 as they are part of level 1
        category_name = [...category_name.slice(4)];
        category_done = [...category_done.slice(4)];
      }

      // Keys : CategoryName
      setCategoryKey((prevVal) => category_name);

      // Value : Category Pending or Done => y / n
      setIsCategoryDone((prevVal) => category_done);

      const mrr_level = getQueryFromURLParams('level');
      let index = 0;
      if (mrr_level === '2') {
        index = 1;
      }

      console.log(index);

      handleFetchCategoryQuestion(category_name[index], index);
    } catch (err) {
      console.log(err);
    } finally {
      // Flag to render categories when fetched data properly
      setIsCategoryKeyFetched(true);
    }
  };

  // On Category Save , Reload
  const handleFetchCategoryOnSave = async (showPopup = false) => {
    if (showPopup) setTrainAthena((prev) => ({ ...prev, isLoading: true }));
    const TOPFOUR_SUBCATEGORIES_Before_Save = IsCategoryDone.slice(0, 4);
    let hasAllFourSavedAlready = true;
    (TOPFOUR_SUBCATEGORIES_Before_Save ?? []).map((flag) => {
      if (flag === 'n') {
        hasAllFourSavedAlready = false;
      }
    });
    if (hasAllFourSavedAlready) {
      console.log('NOT TRAIN');
    }
    console.log('TRAIN', hasAllFourSavedAlready);
    try {
      const result = await Axios.get(
        `${marketResearchSubCategory}?section=${localStorage.getItem(
          'category',
        )}&username=${username}&name=${name}`,
      );

      // Value : Category Pending or Done => y / n
      setIsCategoryDone((prevVal) => Object.values(result.data.flags));
      const TOPFOUR_SUBCATEGORIES = Object.values(result.data.flags).slice(
        0,
        4,
      );

      let hasAllFourSaved = true;
      TOPFOUR_SUBCATEGORIES?.map((flag) => {
        if (flag !== 'y') {
          hasAllFourSaved = false;
        }
      });
      console.log('saving', hasAllFourSaved, showPopup);
      if (hasAllFourSaved && showPopup) {
        document.getElementsByClassName('train-athena')[0].click();
      } else if (showPopup) setTrainAthena({ ...trainAthena, isError: true });
    } catch (err) {
      console.log(err);
    } finally {
      // Flag to render categories when fetched data properly
      setIsCategoryKeyFetched(true);
      setTrainAthena((prev) => ({ ...prev, isLoading: false }));
    }
  };

  const handleFetchCategoryQuestion = async (category_name, index) => {
    setTrainAthena((prev) => ({ ...prev, isError: false }));
    // sethasDescription(false)
    setCategoryName((prevData) => category_name);

    // reset IsActiveExample to False
    setIsActiveExampleFilled(false);

    // cleans question answer component
    setIsCategoryFormDataFetched(false);
    setActiveTabSubCategory(index);
    setSubCategoryName(category_name);

    try {
      const result = await Axios.get(
        `${marketResearchQuestion}?subcategory=${category_name}&username=${username}&name=${name}`,
      );

      let mrr_insights = result.data.mrr_insight.replaceAll('\n', '<br>');

      if (!mrr_insights) mrr_insights = ' ';

      setCategoryInsightsData(result.data.mrr_insight.replaceAll('\n', '<br>'));

      modularizeQuestionAnswer(result.data.data);

      setCategoryFormData((prevData) => result.data.data);

      modelExampleAnswer(result.data.data);

      modelClearAnswer(result.data.data);
    } catch (err) {
      console.log(err);
    } finally {
      setIsCategoryFormDataFetched(true);
    }
  };

  const modularizeQuestionAnswer = (responseData) => {
    let InputData = {};
    for (let i = 0; i < responseData.length; i++) {
      let answer = '';
      if (responseData[i].trans_answer) {
        answer = responseData[i].trans_answer;
      } else {
        answer = responseData[i].answer;
      }
      InputData = {
        ...InputData,
        [responseData[i].question]: answer,
      };
    }

    setSubCategoryAnswers(InputData);
  };

  const handleFillWithExample = () => {
    try {
      const result = modelExampleAnswer(CategoryFormData);
      setSubCategoryAnswers(result);
    } catch (err) {
      console.log(err);
    }
  };

  const handleCheckFillWithAI = () => {
    setIsFillAILoading(true);
    const MINIMUM_QUES_ANSWERED = 2;
    let answer_count = 0;
    for (const [key, value] of Object.entries(SubCategoryAnswers)) {
      if (answer_count === MINIMUM_QUES_ANSWERED) {
        break;
      }
      if (value.length > 2) {
        answer_count += 1;
      }
    }
    if (answer_count === MINIMUM_QUES_ANSWERED || currentLevel !== 1) {
      handleFillWithAI();
    } else {
      setIsFillAILoading(false);
      setIsMinQueAnswered(false);
    }
  };

  const getTotalWordCountIncreaseCredit = async () => {
    const { success } = await CreditUpdater(96);

    if (success === 'true') {
      return { credit_result: 'Pass' };
    }
    return { credit_result: 'Fail' };
  };

  const handleFillWithAI = async () => {
    const { success } = await CreditManage(FILL_WITH_AI_COST, true);

    if (success) {
      try {
        const mrr_answers = await convertAnswerIntoPostFormat(
          SubCategoryAnswers,
        );

        const body = {
          subcategory: SubCategoryName,
          mrr_data: mrr_answers,
        };

        let result = null;
        if (currentLevel !== 1) {
          result = await fillWithAILevel2(SubCategoryName);
        } else {
          result = await fillWithAILevel1(body);
        }

        if (result.data.statusCode === '200') {
          const { success } = await CreditManage(FILL_WITH_AI_COST);

          if (success) {
            await fillTextAreaWithAIAnswers(result.data.script_output);
          } else {
            setIsCreditFail(true);
            setIsFillAILoading(false);
          }
        } else {
          setIsFillAILoading(false);
          console.log('error curating ai answers');
        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsFillAILoading(false);
      }
    } else {
      setIsCreditFail(true);
    }
  };

  const fillTextAreaWithAIAnswers = async (ai_output) => {
    const setAnswer = {};
    let rawTexts = [];
    for (var i = 0; i < ai_output.length; i++) {
      rawTexts = [...rawTexts, ai_output[i].ai_suggestion];
    }
    const translatedTexts = await getTranslatedTextArray(rawTexts);
    for (var i = 0; i < ai_output.length; i++) {
      if (SubCategoryAnswers[ai_output[i].question].trim() !== '') {
        setAnswer[ai_output[i].question] =
          SubCategoryAnswers[ai_output[i].question];
      } else {
        setAnswer[ai_output[i].question] = translatedTexts[i];
      }
    }
    setIsFillAILoading(false);
    setSubCategoryAnswers(setAnswer);
  };

  const handleClear = () => {
    try {
      const result = modelClearAnswer(CategoryFormData);
      setSubCategoryAnswers(result);
    } catch (err) {
      console.log('Is Clear could not be set true');
    }
  };

  const handleAthenaTraining = async () => {
    const axiosConfig = {
      multiValueHeaders: {
        'Content-Type': ['application/json'],
        'Access-Control-Allow-Origin': ['*'],
        'Access-Control-Allow-Headers': [
          'Content-Type',
          'X-Amz-Date',
          'Authorization',
          'X-Api-Key',
          'X-Amz-Security-Token',
        ],
      },
    };

    const body = {
      username,
      name,
      num: 1,
      cc: 'US',
      is_train: true,
      serp: true,
      overwrite: false,
    };
    await Axios.post(`${baseAPIsUrl}/test-semantic`, body, axiosConfig);
  };

  const handleSave = async () => {
    setFromSave((FromSave) => !FromSave);
    setIsSaving(true);

    const save_model_answer = modelAnswerForSave();

    try {
      const req_data = {};
      req_data.username = username;
      req_data.name = name;
      req_data.section = localStorage.getItem('category');
      req_data.subcategory = SubCategoryName;
      req_data.data = save_model_answer;

      const result = await Axios.post(marketResearchSave, req_data);
      handleAthenaTraining();
    } catch (err) {
      console.log(err);
    } finally {
      setIsSaving(false);
      handleFetchCategoryOnSave();
      if (!props.getStarted || ActiveTabSubCategory < 3) {
        if (CategoryKey[ActiveTabSubCategory + 1]) {
          handleFetchCategoryQuestion(
            CategoryKey[ActiveTabSubCategory + 1],
            ActiveTabSubCategory + 1,
          );
        }
      } else {
        console.log(ActiveTabSubCategory);
        // Will Do Something Here
      }
    }
  };

  const modelAnswerForSave = () => {
    const setAnswer = {};
    for (const [key, value] of Object.entries(SubCategoryAnswers)) {
      // let trans_ans = await getTranslatedTextEnglish(value)
      setAnswer[key] = [value];
    }

    return setAnswer;
  };

  const handleInputForAnswer = (e, question) => {
    setTrainAthena((prev) => ({ ...trainAthena, isError: false }));
    setIsMinQueAnswered(true);

    try {
      setSubCategoryAnswers({
        ...SubCategoryAnswers,
        [question]: e.target.value,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleBackToMRR = () => {
    navigate('/Market-Resonance');
  };

  const handleSelectSubCateogory = () => {
    navigate('/Market-Resonance-Level-3');
  };

  const getInsightsWordCountAndIncreaseCredit = async () => {
    const { success } = await CreditUpdater(128);

    if (success === 'true') {
      return { credit_result: 'Pass' };
    }
    return { credit_result: 'Fail' };
  };

  const handleCSVDownload = () => {
    exportAsCSV(SubCategoryAnswers, SubCategoryName);
  };

  const handlePDFDownload = () => {
    PdfDownload(SubCategoryAnswers, SubCategoryName);
  };

  const invalidCategories = [
    'Name the Slack Groups your Niche Belong To',
    'Name the Facebook Groups and Pages your Niche Likes',
    'Name the YouTube Channels your Niche Watch',
    'Name the Forums your Niche Browse',
    'Identify where fun and frequent your Niche',
    'Identify Company Interests of your Niche',
    'Define your Association Map with your Niche',
    'Define your Niche Filter',
  ];

  function CategoryCard({ category_name, index }) {
    if (props.getStarted && index > 3) {
      return <></>;
    }

    const lowercaseCategory = category_name.toLowerCase();
    const isInValidCategory = invalidCategories.some(
      (category) => category.toLowerCase() === lowercaseCategory,
    );

    if (isInValidCategory) {
      return <></>;
    }

    return (
      <div
        className={
          localStorage.getItem('category') === 'Niche' && currentLevel === 1
            ? ActiveTabSubCategory === index
              ? 'mrr-category-card active-sub-category'
              : 'mrr-category-card'
            : ActiveTabSubCategory === index
            ? 'mrr-category-card active-sub-category '
            : 'mrr-category-card '
        }
        onClick={() => handleFetchCategoryQuestion(category_name, index)}
        key={index}
      >
        <div className="mrr-category-card-left">{category_name}</div>
        <div className="mrr-category-card-right">
          {IsCategoryDone[index] === 'y' ? (
            <img
              className="mrr-category-status-image"
              src={GreeenTick}
              alt="Done"
            />
          ) : (
            <img
              className="mrr-category-status-image"
              src={GreyTick}
              alt="Pending"
            />
          )}
        </div>
      </div>
    );
  }

  const handleAllPdfDownload = () => {
    exportAllAsPdf();
  };

  const handleAllCSVDownload = () => {
    exportAllAsCSV();
  };

  function NavFeatureItems({ feature, url, isActive }) {
    return (
      <span
        onClick={() => navigate(`${url}`, { replace: true })}
        className={`font-accent ${
          isActive ? 'mrr-navfeature-active' : 'mrr-navfeature'
        }`}
      >
        {feature}
      </span>
    );
  }

  return (
    <AppHeaderWrapper>
      <div className="mrr-category-container">
        <div className="mrr-category-nav-container">
          <div className="mrr-category-heading">
            <div className="font-secondary">Market Research</div>
          </div>
          <MRR_TabSwitcher />
          <div className="heading-options">
            {/* <Link to="/create-drafts">Market Message</Link> */}
            <Link to="/chat-athena">Market Message</Link>
          </div>
        </div>
        <div className="mrr-category-main">
          <div className="mrr-category-main-left">
            {IsCategoryKeyFetched ? (
              <div className="mrr-category-main-left-parent">
                <div className="mrr-category-main-left-cards">
                  {(CategoryKey ?? []).map((value, index) => (
                    <CategoryCard category_name={value} index={index} />
                  ))}
                </div>
                <div className="mrr-category-main-train-athena">
                  <TrainAthenaMRR
                    trainAthena={trainAthena}
                    setTrainAthena={setTrainAthena}
                    level={currentLevel}
                  />
                </div>
              </div>
            ) : (
              <div className="data-load-wait-display">
                <LoaderSpin height="50px" width="50px" />
                <p>Curating Market Research Categories</p>
              </div>
            )}
          </div>
          <div className="mrr-category-main-middle">
            <div className="mrr-category-main-middle-top">
              {IsCreditFail ? (
                <button className="mrr-mmt-credit-over">Credits Over</button>
              ) : IsFillAILoading ? (
                <LoaderSpin width="20" height="20" />
              ) : (
                <span
                  className="mrr-mmt-fill-ai-btn"
                  onClick={handleCheckFillWithAI}
                >
                  Fill With AI
                </span>
              )}

              <span className="mrr-mmt-clear-btn" onClick={handleClear}>
                Clear
              </span>
            </div>

            <div className="mrr-category-main-middle-center">
              {IsMinQueAnswered ? null : (
                <div className="error-message">
                  Note : Curate First Two Answers for CrawlQ
                </div>
              )}

              {IsCategoryFormDataFetched ? (
                (CategoryFormData ?? []).map(
                  (value, index) =>
                    value.question !== 'Your own Role/Title' && (
                      <div className="mrr-category-question-box">
                        <p className="mrr-category-question-box-question">
                          {value.question}
                        </p>

                        <textarea
                          className="mrr-category-question-box-answer"
                          value={
                            isActiveExampleFilled
                              ? value['Example Input']
                              : SubCategoryAnswers[value.question]
                          }
                          onChange={(e) =>
                            handleInputForAnswer(e, value.question)
                          }
                        />
                        <label className="mrr-category-question-box-helper">
                          Helper : {value['Primary Inputs Helper']}
                        </label>
                      </div>
                    ),
                )
              ) : (
                <div className="data-load-wait-display">
                  <LoaderSpin height="50px" width="50px" />
                  <p>Curating Questions</p>
                </div>
              )}
            </div>

            <div className="mrr-category-main-middle-bottom">
              {IsSaving ? (
                <LoaderSpin height="30px" width="30px" />
              ) : (
                <span className="mrr-mmb-save-btn" onClick={handleSave}>
                  Save
                </span>
              )}

              <div className="mrr-mmb-download-container">
                <span onClick={handleCSVDownload}>CSV</span>
                <span onClick={handlePDFDownload}>PDF</span>
              </div>
            </div>
          </div>
          <div className="mrr-category-main-right">
            <Insights
              subCategoryName={SubCategoryName}
              mrr_insight={CategoryInsightsData}
              showLoader={IsCategoryFormDataFetched}
              setInsightsData={(value) => setCategoryInsightsData(value)}
            />
          </div>
        </div>
      </div>
    </AppHeaderWrapper>
  );
}

export default MarketResearchCategory;
