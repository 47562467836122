/* eslint-disable max-len */
/* eslint-disable react/react-in-jsx-scope */
// import CheckpointMessage from '../dashboard-elements/CheckpointMessage/CheckpointMessage';
// import MarketCard from '../dashboard-elements/MarketCard/MarketCard';
// import ProduceCard from '../dashboard-elements/ProduceCard/ProduceCard';
// import PublishCard from '../dashboard-elements/PublishCard/PublishCard';
// import SummaryCard from '../dashboard-elements/SummaryCard/SummaryCard';
// import TrainAthenaCard from '../dashboard-elements/TrainAthenaCard/TrainAthenaCard';
import WorkspaceCreationActivityHeatMap from '../dashboard-elements/WorkspaceCreationHeatMap/WorkspaceCreationHeatMap';
// import WorkspacePlayGroundCard from '../dashboard-elements/WorkspacePlayCard/WorkspacePlayGroundCard';
import OnboardingViewCard from '../dashboard-elements/OnboardingViewCard/OnboardingViewCard';
import QueriesGraph from '../dashboard-elements/QueriesGraph/QueriesGraph';

function DashboardCenter() {
  return (
    <div>
      {/* <SummaryCard /> */}
      {/* <WorkspacePlayGroundCard /> */}
      <OnboardingViewCard />

      {/* <MarketCard />   // Might not add here but will think about in future.
      <ProduceCard />
      <PublishCard /> */}
      {/* <TrainAthenaCard /> */}
      {/* <CheckpointMessage /> */}
      <WorkspaceCreationActivityHeatMap />
      <QueriesGraph />
    </div>
  );
}

export default DashboardCenter;
