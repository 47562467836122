import React from 'react';
import { Divider } from 'components/common/divider';
import { ScaffoldCard } from 'components/common/scaffold-card';
import { useDashboardContext } from 'context/dashboard/DashboardContext';
import styles from './onboarding-view-card.module.css';

const ONBOARDING_VIEW_DATA = [
  {
    title: "Tailoring Content Strategy: Elma's Guide to CrawlQ’s Power",
    descriptions: [
      'How CrawlQ can help with audience research.',
      'Focus on the most relevant platforms for your marketing efforts.',
      'Create content that is relevant to the business, main topic of interest, and target audience.',
    ],
    id: 1,
    link: 'https://www.youtube.com/watch?v=i0Dow6II8-c',
  },
  {
    title: 'CrawlQ Onboarding: Refining Your Digital Marketing Tactics',
    descriptions: [
      "Optimize content for target audience with CrawlQ's onboarding.",
      'Craft engaging landing pages and descriptions with CrawlQ AI.',
      'Emphasize strategic campaign planning and digital asset use.',
    ],
    id: 1,
    link: 'https://www.youtube.com/watch?v=c4HtqvIW2-Y',
  },
  {
    title: 'How CrawlQ Delivers Clarity in Market Strategy Development',
    descriptions: [
      'How learnings from a book or webinars can be applied to your business.',
      'How to identify pain points, needs, touch points, and location of your ideal audience.',
      'How to analyze and enchance your current landing page with CrawlQ.',
    ],
    id: 1,
    link: 'https://www.youtube.com/watch?v=4OqCgDL2xiI',
  },
];

function OnboardingViewCard() {
  const { videoState } = useDashboardContext();

  const [VideoLink, setVideoLink] = videoState;

  const handleSetVideoLink = (link) => {
    if (link === VideoLink) {
      setVideoLink('');
      return;
    }
    setVideoLink(link);
  };

  return (
    <ScaffoldCard className={styles.onboarding_view_container}>
      <div className={styles.oboarding_header_container}>
        <div className={styles.header}>Get Started With CrawlQ</div>
        <span className={styles.subheader}>Start Your Journey</span>
      </div>
      <Divider />
      <div className={styles.onboarding_view_content_container}>
        {ONBOARDING_VIEW_DATA.map((onboarding) => (
          <div className={styles.onboarding_content}>
            <div className={styles.onboarding_content_title}>
              {onboarding?.title}
            </div>
            <ul className={styles.onboarding_content_description_container}>
              {onboarding?.descriptions.map((points) => (
                <li className={styles.onboarding_content_description_points}>
                  {points}
                </li>
              ))}
            </ul>

            <div
              className={
                onboarding?.link === VideoLink
                  ? styles.active_cta_video
                  : styles.cta_video
              }
              onClick={() => {
                handleSetVideoLink(onboarding?.link);
              }}
            >
              {onboarding?.link === VideoLink ? 'Hide Video' : 'View Video'}
            </div>
          </div>
        ))}
      </div>
    </ScaffoldCard>
  );
}

export default OnboardingViewCard;
