/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import Topbar from 'Component/Shared/Topbar/Topbar';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import HelperVideoModalPopup from 'Component/Shared/HelperVideoPopUp/HelperVideoPop';
import DoneForYouModal from './components/DoneForYouModal';
import NewWorkspaceModal from './components/NewWorkspaceModal';
import OptionsCards from './components/OptionsCards';

import {
  LandingPageCards,
  LandingPageContent,
  LandingPageHeading,
  LandingPageWrapper,
  LandingPageDescription,
  LandingPageOptions,
  DividerLine,
  HelperVideoContainer,
} from './components/styles';

import WorkspaceTemplate from './components/WorkspaceTemplate';

import scratchIcon from '../../../../../assets/landingpage-imgs/scratch-icon.svg';
import templateIcon from '../../../../../assets/landingpage-imgs/template-icon.svg';
import dfyIcon from '../../../../../assets/landingpage-imgs/dfy-icon.svg';
import HelperVideoModal from './components/HelperVideoModal';
import { useNavigate } from 'react-router-dom';
import { HeadingDesc, HeadingName } from 'Component/Shared/GlobalStyles';

const StepsDescriptionsAndDetails = [
  {
    name: 'Start From Scratch ',
    description:
      "You don't have to spend hours, days, and weeks discovering what your customers want. Our AI Athena is trained to do the hard job for you!",
    timeRequired: '5 min',
    imgSrc: scratchIcon,
    headline:
      'Take it easy! Start from scratch if you know your stuff and what you are doing...',
    videoLink:
      'https://athena-helper-videos.s3.us-east-2.amazonaws.com/Option+-+1.mp4',
  },
  {
    name: 'Choose a Template',
    description:
      'Enter a business idea to jump-start with a Pre-researched Audience Persona Template. You can publish your first content in under 1 minute.',
    timeRequired: '30 sec',
    imgSrc: templateIcon,
    headline: '30 Seconds Jump-Start! Grab and Go with Pre-set Templates',
    videoLink:
      'https://athena-helper-videos.s3.us-east-2.amazonaws.com/Option+-+2.mp4',
  },
  {
    name: 'Order DFY Research ',
    description:
      'Sit back and relax while our team of experts picks up "Done For You Research. Please enter a few business details, and our team will create perfect research and the first draft for you within 2 business days.',
    timeRequired: '2 days',
    imgSrc: dfyIcon,
    headline:
      'Damn, Just Do It For Me! Order DFY Content Uber at your Doorsteps!',
    videoLink:
      'https://athena-helper-videos.s3.us-east-2.amazonaws.com/Option+-+3.mp4',
  },
];

function WaysToGetStarted() {
  const navigate = useNavigate();
  const GoToCreateNewWorkspacePage = () => {
    navigate('/New-Workspace');
  };
  const [isNewWorkspaceModalOpen, setIsNewWorkspaceModalOpen] = useState(false);
  const [isWorkspaceTemplateOpen, setIsWorkspaceTemplateOpen] = useState(false);
  const [isDFYModalOpen, setIsDFYModalOpen] = useState(false);
  const buttonActions = [
    GoToCreateNewWorkspacePage,
    setIsWorkspaceTemplateOpen,
    setIsDFYModalOpen,
  ];

  const user = useSelector((state) => state.user);
  return (
    <LandingPageWrapper>
      <NewWorkspaceModal
        isOpen={isNewWorkspaceModalOpen}
        modalCloser={setIsNewWorkspaceModalOpen}
      />
      <DoneForYouModal
        isOpen={isDFYModalOpen}
        modalCloser={setIsDFYModalOpen}
      />
      <div className="flex-1">
        <Topbar />
      </div>

      <LandingPageContent>
        <HeadingName>
          Build Your Business With Real-Time Customer Insights
        </HeadingName>
        <HeadingDesc
          style={{
            fontSize: '1.2rem',
          }}
        >
          Build your Audience and Marketing Message Using Our AI-powered Social
          Listening Engine In Under minutes
        </HeadingDesc>
        {isWorkspaceTemplateOpen ? (
          <div style={{ flex: 5 }}>
            <WorkspaceTemplate closer={setIsWorkspaceTemplateOpen} />
          </div>
        ) : (
          <LandingPageOptions>
            <LandingPageDescription>
              {user.name}, Choose any to create your &nbsp;
              <span>Unique Audience Persona</span>
            </LandingPageDescription>
            <HelperVideoContainer>
              <HelperVideoModalPopup
                tooltipTitle={`Thank you for signing up! We think you're going to love CrawlQ.
Click here to activate your AI Assistant Athena`}
                videoLink="https://athena-helper-videos.s3.us-east-2.amazonaws.com/Athena+Welcome+Video.mp4"
              />
            </HelperVideoContainer>
            <DividerLine />
            <LandingPageCards>
              {StepsDescriptionsAndDetails.map((step, index) => (
                <OptionsCards
                  name={step.name}
                  description={step.description}
                  timeRequired={step.timeRequired}
                  imgSrc={step.imgSrc}
                  videoLink={step.videoLink}
                  headline={step.headline}
                  buttonAction={buttonActions[index]}
                />
              ))}
            </LandingPageCards>
          </LandingPageOptions>
        )}
      </LandingPageContent>
    </LandingPageWrapper>
  );
}

export default WaysToGetStarted;
