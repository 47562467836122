import createInstance from 'Config/AxiosConfig';
import { FALLBACK_ACTIVE_WORKSPACE } from 'libs/chat-athena-constants';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { CHAT_HISTORY_QUERY_KEY, useGetChatHistory } from './get-chat-history';

const DEFAULT_PAGE_SIZE = 5;

export const useMutateDeleteChatHistory = () => {
  const user = useSelector((state) => state?.user);
  const workspace = useSelector((state) => state?.workspace);
  const queryClient = useQueryClient();
  const workspaceName = workspace?.name ?? FALLBACK_ACTIVE_WORKSPACE?.name;

  const currentMessage = queryClient.getQueryData([
    CHAT_HISTORY_QUERY_KEY,
    workspaceName,
  ]);

  const [currentPageSize, setCurrentPageSize] = useState(
    currentMessage?.pairs?.length ?? DEFAULT_PAGE_SIZE,
  );

  const { refetch: refetchChatHistory } = useGetChatHistory(currentPageSize);

  async function deleteChatHistoryById({ answerId }) {
    const payload = {
      username: user?.username,
      name: workspaceName,
      id: answerId,
    };
    const instance = await createInstance();
    const response = await instance.post('delete-chat-history', payload);

    if (response.data?.statusCode !== '200') {
      throw new Error('Error in Deleting answer');
    }
  }

  return useMutation(deleteChatHistoryById, {
    onMutate: async (variables) => {
      const previousMessages = queryClient.getQueryData([
        CHAT_HISTORY_QUERY_KEY,
        workspaceName,
      ]);

      queryClient.setQueryData(
        [CHAT_HISTORY_QUERY_KEY, workspaceName],
        (oldData) => {
          // updating page size by one as one is deleted
          setCurrentPageSize(oldData?.pairs?.length + 1);
          return {
            ...oldData,
            pairs: oldData.pairs.filter(
              (message) => message.id !== variables.answerId,
            ),
          };
        },
      );

      return previousMessages;
    },
    onError: (error, variables, previousMessages) => {
      queryClient.setQueryData(
        [CHAT_HISTORY_QUERY_KEY, workspaceName],
        previousMessages,
      );
      toast.error(error.message, {
        position: 'bottom-center',
        theme: 'dark',
      });
    },

    onSuccess: () => {
      refetchChatHistory();
    },
  });
};
