/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable import/no-unresolved */
/* eslint-disable camelcase */

import axios from 'axios';
import { mrr_data_fill_with_ai } from 'Config/Api';
import createInstance from 'Config/AxiosConfig';
import { store } from 'redux/store';

const getReduxStore = () => store.getState();

export const fillWithAILevel1 = async (body) => {
  const reduxStore = getReduxStore();
  body = {
    ...body,
    username: reduxStore.user.username,
    name: reduxStore.workspace.name,
  };
  try {
    const result = await axios.post(mrr_data_fill_with_ai, body);
    return result;
  } catch (ex) {
    console.log('Cannot Fill With AI Level 1', ex);
  }
};

export const fillWithAILevel2 = async (subCategoryName) => {
  const reduxStore = getReduxStore();
  try {
    const body = {
      username: reduxStore.user.username,
      name: reduxStore.workspace.name,
      subcategory: subCategoryName,
      // product: 'software',
      // "source": <source>
    };

    const instance = await createInstance();
    const result = await instance.post('mrr-ai-fill-level2', body);
    result.data.script_output = result.data.output;
    return result;
  } catch (ex) {
    console.log('Cannot Fill With AI Level 1', ex);
  }
};
