/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import Slider from '@mui/material/Slider';
import { withStyles } from '@mui/styles';
import axios from 'axios';
import React, { useState } from 'react';
import {
  getTranslatedText,
  getTranslatedTextEnglish,
} from '../../../../Shared/TranshelperStatic';
import { CreditManage } from 'Component/Shared/CreditManager';
import CustomTooltip from 'Component/Shared/CustomTooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import './Text-summerizer.css';
import { TEXT_SUMMARIZER_COST } from 'Component/Shared/featuresCreditsCost';
import LoaderSpin from '../../../../Shared/Loader';

const PrettoSlider = withStyles({
  root: {
    color: '#2a9df5',
    height: 8,
    width: 220,
    marginLeft: 40,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: 0,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

function TextSummerizer() {
  const [values, setValues] = useState({
    string: '',
    content_length: 20,
    result: '',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [hasCreditOver, setHasCreditOver] = useState(false);
  const [isError, setIsError] = useState(false);

  useState(() => {
    if (localStorage.getItem('sliderLength')) {
      setValues({
        ...values,
        content_length: localStorage.getItem('sliderLength'),
      });
    }
  }, []);

  const handleChange = (e) => {
    if (
      e.target.value.trim().split(' ').length > 250
      || e.target.value.trim().split(' ').length < 30
    ) {
      setIsError(true);
    } else {
      setIsError(false);
    }
    setValues({ ...values, string: e.target.value });
  };
  const handleSliderChange = (val) => {
    if (val < 20) return;
    setValues({ ...values, content_length: val });
    localStorage.setItem('sliderLength', val);
  };

  const handleSummerizer = async () => {
    if (values.string.trim() === '' || isError) return;

    setIsLoading(true);
    const { success } = await CreditManage(TEXT_SUMMARIZER_COST, true);

    if (!success) {
      setHasCreditOver(true);
      setIsLoading(false);
    } else {
      const body = {
        text: await getTranslatedTextEnglish(values.string),
        maxTokens: values.content_length,
      };

      const data = await axios.post('https://apis.crawlq.ai/ai-summarize', body);

      const output_result = await getTranslatedText(data.data.output);

      const { success } = await CreditManage(TEXT_SUMMARIZER_COST);

      if (success) {
        setValues({ ...values, result: output_result });
        setIsLoading(false);
      } else {
        setHasCreditOver(true);
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <div className="summerizer-container">
        <div className="summerizer-header">
          <h4 style={{ padding: '0 10px' }} className="text-center">
            Summarizer
            <CustomTooltip
              placement="top"
              title="Enter your text in the text box below and click Generate button to get a concise version of your text. Use the word length slider to set an approximate number of words you want in the summary"
              arrow
            >
              <InfoOutlinedIcon className="mb-2 ml-1" />
            </CustomTooltip>
          </h4>
          {!isLoading ? (
            <>
              {hasCreditOver ? (
                <button
                  className=" p-1 px-2 rounded"
                  style={{
                    background: 'rgb(255 0 0 / 21%)',
                    color: 'rgb(224 2 2 / 81%)',
                  }}
                >
                  Credits Over
                </button>
              ) : (
                <button
                  onClick={handleSummerizer}
                  className="text-white p-1 px-2 rounded mb-1"
                  style={{ backgroundColor: 'rgb(99, 165, 240)' }}
                >
                  Generate
                </button>
              )}
            </>
          ) : (
            <div className="d-flex justify-content-around">
              <LoaderSpin />
            </div>
          )}
        </div>

        <div className="summerizer-input-container">
          <div>
            <textarea
              onChange={(e) => handleChange(e)}
              className="ts-textarea-box"
              values={values.string}
              placeholder="Write Content Here ..."
            />
            {isError && (
              <p className="text-center text-danger">
                *Enter Words between 30 to 250
              </p>
            )}
          </div>
          <div className="summerizer-slider">
            <div className="ts-slider">
              <PrettoSlider
                onChange={(e, val) => handleSliderChange(val)}
                valueLabelDisplay="auto"
                value={values.content_length}
                min={20}
                max={50}
              />
            </div>
            <div className="ts-slider-count" style={{ marginLeft: '90px' }}>
              <span className="font-accent">Content Length
                <p className="font-secondary" style={{ marginLeft: '50px' }}>{values.content_length}</p>
              </span>
            </div>
          </div>
        </div>
      </div>

      {values.result !== '' && (
        <p className="mt-2 text-center">{values.result}</p>
      )}
    </>
  );
}

export default TextSummerizer;
