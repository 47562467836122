import React from 'react';
import { useSelector } from 'react-redux';
import { Divider } from 'components/common/divider';

import styles from './recent-workspace.module.css';
import WorkspaceCard from './WorkspaceCard';

function RecentWorkspaces({ onClose }) {
  const recentWorkspaces = useSelector(
    (state) => state?.recentWorkspaces?.workspaces,
  );

  return (
    <div className={styles.recent_workspace_container}>
      <div className={styles.recent_workspace_header}>Recent Campaigns</div>
      <Divider />
      <div className={styles.recent_workspace_list_wrapper}>
        {recentWorkspaces &&
          recentWorkspaces.map((workspace) => (
            <WorkspaceCard workspace={workspace} onClose={onClose} />
          ))}
      </div>
    </div>
  );
}

export default RecentWorkspaces;
