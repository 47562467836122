/* eslint-disable indent */
/* eslint-disable quotes */
/* eslint-disable quote-props */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable no-plusplus */
/* eslint-disable react/button-has-type */
/* eslint-disable no-return-assign */
/* eslint-disable no-use-before-define */

import { Button, Col, Form, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import { getCountries, languageData } from './utils';
import './languagesStyles.css';

const LanguageDesktopSelector = (props) => {
  const { data, setData } = props;
  return (
    <Row
      align="middle"
      justify="space-between"
      style={{
        width: '100%',
      }}
      id="language1X"
    >
      <Col span={11} style={{ width: '100%', color: 'white' }}>
        <ReactFlagsSelect
          placeholder="Select A Country"
          searchable
          selected={data.targetCountry}
          onSelect={(code) => setData({ ...data, targetCountry: code })}
          style={{ width: '50px', color: 'white', backgroundColor: '!white' }}
          selectedSize={15}
          fullWidth={false}
        />
      </Col>
      <Col span={11} style={{ color: 'white' }}>
        <ReactFlagsSelect
          className="custom-flag-select"
          placeholder="Select A Country"
          searchable
          selected={data.language}
          countries={getCountries()}
          customLabels={languageData}
          onSelect={(code) => {
            setData({ ...data, languageCode: code, language: code });
          }}
          style={{ width: '50px', color: 'white', backgroundColor: '!white' }}
          selectedSize={15}
          fullWidth={false}
        />
      </Col>
    </Row>
  );
};

export default LanguageDesktopSelector;
