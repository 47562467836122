import Modal from 'react-modal';
import React, { useEffect, useState } from 'react';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import CustomTooltip from 'Component/Shared/CustomTooltip';
import SourceRoundedIcon from '@mui/icons-material/SourceRounded';
import { Checkbox } from '@mui/material';
import { useGetUsersAddOns } from 'queries/user-account/services/get-user-addons';
import { PlanType } from 'libs/enums/planType.enum';
import { LoaderTailSpin } from 'Component/Shared/Loader';
import { Slider, Space } from 'antd';
import { useSelector } from 'react-redux';
import { LockRounded } from '@mui/icons-material';
import createInstance from 'Config/AxiosConfig';
import { addOnCheckout } from 'utils/get-add-on-checkout';
import styles from '../../chat-athena-components.module.css';
import { saveConfigurations } from '../services/chat-athena-configurations';
import { CREATIVE_TOOLS, DATA_SOURCES, ModalStyles } from './constants';

export function ChatAthenaConfigurations({
  selectedSources,
  setSelectedSources,
  setAddOnLevel,
  selectedCreativeTool,
  setSelectedCreativeTool,
  selectedTemperature,
  setSelectedTemperature,
  documentMode,
}) {
  const workspace = useSelector((state) => state?.workspace);

  const { data: plans, isLoading: isLoadingPlans } = useGetUsersAddOns();

  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => {
    setIsOpen(false);
    saveConfigurations({
      selectedTemperature,
      selectedSources,
      selectedCreativeTool,
      workspaceData: workspace,
    });
  };

  const handleSourceSelection = (status, source, planRequired) => {
    if (planRequired && !plans[planRequired].status) {
      if (planRequired === PlanType.quora) {
        setAddOnLevel(1);
      } else if (planRequired === PlanType.reddit) {
        setAddOnLevel(0);
      }
      return;
    }
    const sources = new Set(selectedSources);
    if (status) {
      sources.add(source);
    } else {
      sources.delete(source);
    }
    setSelectedSources([...sources]);
  };

  const handleToolSelection = (status, blogType) => {
    if (status) {
      setSelectedCreativeTool(blogType);
    } else {
      setSelectedCreativeTool(null);
    }
  };

  useEffect(() => {
    setSelectedSources(['mrr']);
  }, []);

  if (isLoadingPlans) {
    <LoaderTailSpin />;
  }

  const isPlanEnabled = (planName) => {
    if (planName === 'Reddit AddOn' || planName === 'Quora AddOn') {
      if (plans && plans[planName]?.status === true) {
        return true;
      }
      return false;
    }
    return true;
  };

  const handleAddOnCheckout = async () => {
    const _plans = [PlanType.reddit, PlanType.quora];

    await addOnCheckout(_plans);
  };

  const renderBuyAddOnMessage = (planName) => (
    <div>
      <div> {'Please subscribe to Reddit & Quora AddOns to unlock'}</div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <button
          type="button"
          className="profile-btn"
          onClick={handleAddOnCheckout}
          style={{ marginTop: '10px', boxShadow: '0px 0px 0px !important' }}
        >
          {'Subscribe'}
        </button>
      </div>
    </div>
  );

  return (
    <div>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={ModalStyles}
        ariaHideApp={false}
      >
        <div className={styles.configurations}>
          <span className={styles.heading}>Configurations</span>
          <div className={styles.data_group}>
            <div className={styles.data_sources}>
              <div>
                <div className={styles.subheadig}>
                  <SourceRoundedIcon />
                  <span>Select Data Sources</span>
                </div>

                <div className={styles.souces_list}>
                  {DATA_SOURCES.map((source) => (
                    <div className={styles.option} key={source.value}>
                      <Checkbox
                        onChange={(e, status) =>
                          handleSourceSelection(
                            status,
                            source?.value,
                            source?.planRequired,
                          )
                        }
                        style={{ color: '#fff' }}
                        checked={selectedSources.includes(source?.value)}
                        disabled={!isPlanEnabled(source?.planRequired)}
                      />
                      <span>{source?.name}</span>
                      {source && !isPlanEnabled(source?.planRequired) && (
                        <CustomTooltip
                          title={renderBuyAddOnMessage(source?.planRequired)}
                          arrow
                          placement="right"
                        >
                          <LockRounded />
                        </CustomTooltip>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {!documentMode && (
              <div className={styles.tools_group}>
                <div>
                  <div className={styles.subheadig}>
                    <SourceRoundedIcon />
                    <span>Creative Tools</span>
                  </div>
                  <div className={styles.souces_list}>
                    {CREATIVE_TOOLS.map((blogType) => (
                      <div className={styles.option} key={blogType.value}>
                        <Checkbox
                          checked={selectedCreativeTool === blogType.value}
                          style={{ color: '#fff' }}
                          onChange={(e, status) =>
                            handleToolSelection(status, blogType.value)
                          }
                        />
                        <span>{blogType.name}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

            <div className={styles.tools_group}>
              <div>
                <div className={styles.subheadig}>
                  <SourceRoundedIcon />
                  <span>Temperature</span>
                </div>
                <div
                  className={styles.souces_list}
                  style={{ marginTop: '8px' }}
                >
                  <Space>
                    <div>Precise</div>
                    <Slider
                      style={{ width: '80px' }}
                      defaultValue={selectedTemperature}
                      min={0.0}
                      max={1.0}
                      step={0.01}
                      onChange={(val) => setSelectedTemperature(val)}
                    />
                    <div>Creative</div>
                  </Space>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <CustomTooltip title="Configurations" arrow placement="top">
        <SettingsSuggestRoundedIcon onClick={openModal} />
      </CustomTooltip>
    </div>
  );
}
