import { baseAPIsUrl } from 'Config/Api';
import axios from 'axios';
import { useQuery } from 'react-query';

const fetchFiveTranAppConnectorParams = async (appId) => {
  try {
    const { data } = await axios.get(
      `${baseAPIsUrl}/get-connector-params?connector_name=${appId}`,
    );
    if (data.statusCode === '200') {
      return data.data;
    }
    throw new Error('Error in fetching connector params');
  } catch (ex) {
    console.log(ex);
    throw ex;
  }
};

// Keep Query Value as File Name to maintain consistency and uniqueness in query index
const QUERY_KEY = 'get-all-fivetran-connector-params';

// Query Config Options
const queryOptions = {
  staleTime: 10 * 60 * 1000,
  // 10 mins
};

// QUERY
export const useGetFiveTranAppConnectorParams = (appId) =>
  useQuery(
    [QUERY_KEY, appId],
    () => fetchFiveTranAppConnectorParams(appId),
    queryOptions,
  );
