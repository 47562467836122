import React from 'react';
import { BeanHead } from 'beanheads';
import { LoopArrowIcon } from 'components/common';
import { AvatarContainer } from './styled';
import styles from './avatar-generator.module.css';

const AvatarGeneratorPlaceholder = ({
  avatarProp,
  handleRandomGeneration,
  editable,
  width,
}) => (
  <AvatarContainer width={width}>
    <BeanHead {...avatarProp} />
    {editable && (
      <div className={styles.loop_container}>
        <LoopArrowIcon onClick={handleRandomGeneration} />
      </div>
    )}
  </AvatarContainer>
);

export default React.memo(AvatarGeneratorPlaceholder);
