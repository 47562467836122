import { useMutation, useQueryClient } from 'react-query';
import createInstance from 'Config/AxiosConfig';
import { toast } from 'react-toastify';

export const generateQuery = async ({ query, dataSetId, id }) => {
  const payload = {
    query,
    dataset_id: dataSetId,
    id,
  };

  try {
    const instance = await createInstance();

    const response = await instance.post('generate-sql', payload);
    return true;
  } catch (error) {
    return true;
  }
};

export const useMutateGenerateQuery = () =>
  useMutation(generateQuery, {
    onError: (error) => {
      toast.error(`${error.message}`, {
        position: 'bottom-center',
        theme: 'dark',
      });
    },
    onMutate: () => {
      toast.info('Generating SQL Query', {
        position: 'bottom-center',
        theme: 'dark',
      });
    },
    retryDelay: 7 * 1000, // 7sec
    retry: 10,
  });
