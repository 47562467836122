import React from 'react';
import { LoadingPlaceholder } from 'components/skeleton/LoadingPlaceholder';
import { FlexCard } from 'components/common';

export const TopicLoading = () => (
  <FlexCard flex wrap className="h-margin-t-x2">
    <LoadingPlaceholder width="20%" height="35px" marginRight="8px" marginBottom="16px" />
    <LoadingPlaceholder width="30%" height="35px" marginRight="8px" marginBottom="16px" />
    <LoadingPlaceholder width="10%" height="35px" marginRight="8px" marginBottom="16px" />
    <LoadingPlaceholder width="20%" height="35px" marginRight="8px" marginBottom="16px" />
    <LoadingPlaceholder width="30%" height="35px" marginRight="8px" marginBottom="16px" />
    <LoadingPlaceholder width="20%" height="35px" marginRight="8px" marginBottom="16px" />
    <LoadingPlaceholder width="10%" height="35px" marginRight="8px" marginBottom="16px" />
    <LoadingPlaceholder width="30%" height="35px" marginRight="8px" marginBottom="16px" />
    <LoadingPlaceholder width="20%" height="35px" marginRight="8px" marginBottom="16px" />
    <LoadingPlaceholder width="20%" height="35px" marginRight="8px" marginBottom="16px" />
    <LoadingPlaceholder width="30%" height="35px" marginRight="8px" marginBottom="16px" />
    <LoadingPlaceholder width="10%" height="35px" marginRight="8px" marginBottom="16px" />
    <LoadingPlaceholder width="10%" height="35px" marginRight="8px" marginBottom="16px" />
    <LoadingPlaceholder width="20%" height="35px" marginRight="8px" marginBottom="16px" />
    <LoadingPlaceholder width="30%" height="35px" marginRight="8px" marginBottom="16px" />
    <LoadingPlaceholder width="20%" height="35px" marginRight="8px" marginBottom="16px" />
    <LoadingPlaceholder width="10%" height="35px" marginRight="8px" marginBottom="16px" />
    <LoadingPlaceholder width="30%" height="35px" marginRight="8px" marginBottom="16px" />
    <LoadingPlaceholder width="20%" height="35px" marginRight="8px" marginBottom="16px" />
    <LoadingPlaceholder width="30%" height="35px" marginRight="8px" marginBottom="16px" />
    <LoadingPlaceholder width="10%" height="35px" marginRight="8px" marginBottom="16px" />
    <LoadingPlaceholder width="20%" height="35px" marginRight="8px" marginBottom="16px" />
    <LoadingPlaceholder width="30%" height="35px" marginRight="8px" marginBottom="16px" />
    <LoadingPlaceholder width="30%" height="35px" marginRight="8px" marginBottom="16px" />
    <LoadingPlaceholder width="20%" height="35px" marginRight="8px" marginBottom="16px" />
    <LoadingPlaceholder width="10%" height="35px" marginRight="8px" marginBottom="16px" />
    <LoadingPlaceholder width="30%" height="35px" marginRight="8px" marginBottom="16px" />
    <LoadingPlaceholder width="20%" height="35px" marginRight="8px" marginBottom="16px" />
    <LoadingPlaceholder width="30%" height="35px" marginRight="8px" marginBottom="16px" />
    <LoadingPlaceholder width="10%" height="35px" marginRight="8px" marginBottom="16px" />
    <LoadingPlaceholder width="20%" height="35px" marginRight="8px" marginBottom="16px" />
  </FlexCard>

);
