import MinifiedLayout from 'Component/Shared/BaseLayout/MinifiedLayout';
import { Divider, HeadingName } from 'Component/Shared/GlobalStyles';
import React from 'react';
import { useIsMobile } from 'Component/hooks/useIsMobile';
import CreateNewWorkspace from '../CreateNewWorkspace/CreateNewWorkspace';

const NewWorkspcePage = () => {
  const isMobile = useIsMobile();
  return (
    <MinifiedLayout>
      <HeadingName isMobile={isMobile}>
        Create Your Own Unique Audience Persona
      </HeadingName>
      <Divider />
      <div
        className="container shadow mt-3"
        style={{
          height: 'fit-content',
        }}
      >
        <CreateNewWorkspace />
      </div>
    </MinifiedLayout>
  );
};

export default NewWorkspcePage;
