/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import { css } from '@emotion/core';
import { withStyles } from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';
import React, { useContext, useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import TLDR from 'Component/Pages/ContentAutomation/ResultSection/TLDR/TLDR';
import ProductStory from 'Component/Pages/ContentAutomation/ResultSection/ProductStory/ProductStory';
import DocSimilarityScore from '../../ContentAutomation/ResultSection/DocumentScore/DocScore';
import NewsTab from '../../ContentAutomation/ResultSection/NewsTab/News';
import TextSummerizer from '../../ContentAutomation/ResultSection/Text-summerizer/TextSummerizer';
import { AiContent } from '../contexts/ai_content';
import '../css/result_Section/result_Section.css';
import TopicClusters from './ai_topic_cluster';
import HeadlinesCollection from './headlines_Collection';
import HeadLineIntors from './headlines_intro';
import AI_MRR from './mrr.js';
import QuestionCollections from './question_Collections';
import TopicCollections from './topic_Collections';
import AuthorityKeyword from 'Component/Pages/ContentAutomation/ResultSection/AuthorityKeyword/AuthorityKeyword';
import { useSelector } from 'react-redux';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { getSeoAthenaKeywords } from 'Component/Pages/ContentAutomation/ResultSection/AuthorityBuilder/utils';

const override = css`
  position: absolute;
  top: 50%;
  left: 50%;
  border-color: #f5b848;
  z-index: 8;
`;

const WhiteonBlackTooltip = withStyles({
  tooltip: {
    color: 'white',
    backgroundColor: 'rgb(47 47 47 / 91%)',
    fontSize: '12px',
    fontWeight: 'normal',
    letterSpacing: '1px',
    padding: '10px 15px',
    textAlign: 'justify',
  },
})(Tooltip);

export function ResultSection(props) {
  const [AuthorityOpen, setAuthorityOpen] = useState(false);
  const [AuthorityKeywords, setAuthorityKeywords] = useState([]);

  const user = useSelector((state) => state?.user);
  const active_workspace = useSelector((state) => state?.workspace);

  useEffect(() => {
    getAuthorityKeywords();
  }, []);

  const getAuthorityKeywords = async () => {
    const response = await getSeoAthenaKeywords(user?.email, active_workspace?.name, props?.blogTopic);
    setAuthorityKeywords([...response?.keywords_to_use, ...AuthorityKeywords]);
  };

  return (
    <div className="result__Section result__Section_right">
      <div className="result__body">
        <div className="tab-box pills" data-tab-anima="fade-left">
          <ReactTooltip />
          <ul className="nav nav-pills lirelative mt-1">
            <li className="active">
              <div className="nav-tab  mt-1">Overview</div>
            </li>

            <li>
              <div className="nav-tab  mt-1">Market</div>
            </li>

            <li>
              <div className="nav-tab  mt-1">SERP</div>
            </li>

            <li>
              <div className="nav-tab  mt-1">Topic</div>
            </li>
            <li>
              <div className="nav-tab  mt-1">Questions</div>
            </li>
            <li>
              <div className="nav-tab  mt-1">News</div>
            </li>
          </ul>
          <div className="panel active p-0">
            <br />
            <div
              className="border-rounded-light mt-3"
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div style={{ flex: 1 }}>
                <h4 style={{ marginLeft: '50px', marginBottom: '15px' }}>
                  Audience Persona Empathy Score
                  <sup>&trade;</sup>
                </h4>
                <WhiteonBlackTooltip title="hey" placement="top">
                  <DocSimilarityScore />
                </WhiteonBlackTooltip>
              </div>
            </div>
            {AuthorityKeywords.length > 0 && (
              <div className="border-rounded-light mt-3 mb-3 global-d-flex-col">
                <div
                  style={{
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                  className="global-d-flex-row"
                  onClick={() => setAuthorityOpen((val) => !val)}
                >
                  <h4>
                    Brand Authority Topics
                    <span
                      style={{
                        fontSize: '14px',
                        color: '#13d613',
                        fontWeight: '700',
                      }}
                    >
                      Beta
                    </span>
                  </h4>
                  <KeyboardArrowDownIcon />
                </div>

                {AuthorityOpen && (
                  <div>
                    {AuthorityKeywords.length > 0 && (
                      <AuthorityKeyword blogData={props} keywords={AuthorityKeywords} />
                    )}
                  </div>
                )}
              </div>
            )}

            <div
              className="border-rounded-light mt-3 mb-3 d-flex"
              style={{ alignItems: 'end' }}
            >
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                }}
              >
                <ProductStory />
              </div>
            </div>

            <div
              className="border-rounded-light mt-3 mb-3 d-flex"
              style={{ alignItems: 'end' }}
            >
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                }}
              >
                <TLDR />
              </div>
            </div>

            <div
              className="border-rounded-light mt-3 mb-3 d-flex"
              style={{ alignItems: 'end', justifyContent: 'center' }}
            >
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <HeadLineIntors query={props.query} />
              </div>
            </div>
            <div
              className="border-rounded-light mt-3 mb-3 d-flex"
              style={{ alignItems: 'end' }}
            >
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <TextSummerizer />
              </div>
            </div>
          </div>
          <div className="panel">
            <AI_MRR />
          </div>
          <div className="panel">
            <HeadlinesCollection />
          </div>
          <div className="panel">
            <TopicCollections />
          </div>
          <div className="panel">
            <QuestionCollections />
          </div>
          <div className="panel">
            <br />
            <NewsTab country={props.country} />
          </div>
          <div className="panel">
            <TopicClusters />
          </div>
        </div>
      </div>
    </div>
  );
}
export default ResultSection;
