/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-return-assign */
/* eslint-disable no-redeclare */
/* eslint-disable no-plusplus */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import React, { useEffect, useRef, useState } from 'react';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.min.css';
import { useSelector } from 'react-redux';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import 'froala-editor/js/plugins/align.min.js';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import '../../css/text_editor.css';
import '../../css/text_Editor_Components/text_Editor_Components.css';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Editor as TinyEditor } from '@tinymce/tinymce-react';
import axios from 'axios';
import '../ModesDropdown.css';
import LoaderSpin from 'Component/Shared/Loader';
import {
  seoMeAthenaHelper,
  socialMeAthenaHelper,
  writeMeAthenaHelper,
  outlineMeAthenaHelper,
} from 'Component/Pages/ContentAutomation/AIcontentWriter/AIcontentLogic';
import handleSummerizerForEditor from 'Component/Pages/ContentAutomation/ResultSection/Text-summerizer/TextSummerizerForEditor';

export function AdvancedTextEditor(props) {
  let choosenText = 'car';
  const athena_valid_sources = useSelector(
    (state) => state.user.athena_sources?.train_option,
  );
  let aiContent;
  const [saving, setSaving] = useState(false);
  const editorRef = useRef(null);

  const [WriteMeAthenaCreditsFail, setWriteMeAthenaCreditsFail] = useState(
    false,
  );
  const [isAthenaWriting, setIsAthenaWriting] = useState(false);
  const [Sources, setSources] = useState(athena_valid_sources);
  const [menuPop, setmenuPop] = useState(false);
  const [SelectedSources, setSelectedSources] = useState(['General']);
  const [SourceArray, setSourceArray] = useState([
    { key: 'General', value: 'General', checked: true },
    { key: 'mrr', value: 'Market Research', checked: false },
    { key: 'mrr_insight', value: 'Market Insights', checked: false },
    { key: 'script', value: 'Sales Copywriting', checked: false },
    { key: 'quora', value: 'Quora', checked: false },
    { key: 'reddit', value: 'Reddit', checked: false },
    { key: 'serp', value: 'Google', checked: false },
    { key: 'website', value: 'URL', checked: false },
  ]);

  const arr = [];
  const preSaveContent = '';
  useEffect(() => {
    // Interval inorder to prevent from slowness of contexts re renders.
    // const interval = setInterval(() => {
    //   // setglobalEditor(html)
    // }, 3000)
    if (!window.location.pathname.includes('MRR-Get-Started')) {
      //   clearInterval(interval)
    }
    return () => {
      //   clearInterval(interval)
    };
  }, []);

  if (typeof aiContent !== 'undefined' && aiContent !== 'error') {
    for (let i = 0; i < aiContent.topics?.length; i++) {
      arr.push({
        keyword: aiContent.topics[i].keyword,
        avg_mention: aiContent.topics[i]['Avg. Mentions'],
      });
    }
  }

  const writeMeAthena = (engine_type) => {
    handleWriteMeAthena(engine_type);
  };

  function update_ngrams_topics(editor_html, topics) {
    const span = document.createElement('span');
    span.innerHTML = editor_html;
    const get_innerText = span.innerText;
    const sum_topics_number = _.sumBy(topics, (o) => o.avg_mention);

    if (typeof aiContent !== 'undefined' && aiContent !== 'error') {
      for (let i = 0; i < aiContent.topics.length; i++) {
        arr.push({
          keyword: aiContent.topics[i].keyword,
          avg_mention: aiContent.topics[i]['Avg. Mentions'],
        });
      }
    }
  }
  function update_ngrams_topics(editor_html, topics) {
    const span = document.createElement('span');
    span.innerHTML = editor_html;
    const get_innerText = span.innerText;
    const sum_topics_number = _.sumBy(topics, (o) => o.avg_mention);
    let count_keywords = 0;
    for (let i = 0; i < topics.length; i++) {
      const count_topics = get_innerText.match(
        new RegExp(topics[i].keyword.toLowerCase(), 'g'),
      );
      let val = 0;

      if (count_topics !== null) {
        if (count_topics.length <= topics[i].avg_mention) {
          val = count_topics.length;
        } else {
          val = topics[i].avg_mention;
        }
        count_keywords += val;
      }
    }
    const score = (count_keywords / sum_topics_number) * 100;
    document.getElementById('topic_score').innerHTML = Math.round(score);
  }

  function counter_scores(html) {
    const span = document.createElement('span');
    span.innerHTML = html;
    const text = span.textContent || span.innerText;

    // update values from editor to the result section
    if (document.getElementById('word-count') !== null) {
      document.getElementById('word-count').innerHTML = text.split(' ').length;
      document.getElementById('header-count').innerHTML = span.getElementsByTagName('h1').length
        + span.getElementsByTagName('h2').length;
      document.getElementById(
        'img-count',
      ).innerHTML = span.getElementsByTagName('img').length;
    }
  }

  function currentData(model) {
    // console.log(model)
    if (typeof aiContent !== 'undefined') {
      update_ngrams_topics(model, arr);
      counter_scores(model);
      // var myContent = Editor.get('myTextarea').getContent();
      // console.log(myContent)
    }
  }

  const getSelectedText = () => {
    const input = window.tinyMCE.activeEditor.selection.getContent();
    console.log(input);
    choosenText = input;
  };

  const setSelectedData = () => {
    window.tinymce.activeEditor.execCommand(
      'mceInsertContent',
      false,
      `${choosenText} `,
    );
  };

  const handleSocialMeAthena = (_engine_type, _sources) => {
    const input = window.tinyMCE.activeEditor.selection.getContent();

    if (input.length <= 1) {
      window.tinyMCE.activeEditor.notificationManager.open({
        text: 'Please highlight a sentence or word for Athena',
        type: 'error',
        timeout: 3000,
      });
      setIsAthenaWriting(false);
    } else {
      socialMeAthenaHelper(input, SelectedSources).then(
        ({ credits, data, error }) => {
          if (!credits) {
            setWriteMeAthenaCreditsFail(true);
          } else if (!error) {
            window.tinymce.activeEditor.execCommand(
              'mceInsertContent',
              false,
              `${input} `,
            );
            // typeWriter(data)
            window.tinymce.activeEditor.execCommand(
              'mceInsertContent',
              false,
              data,
            );
            setIsAthenaWriting(false);

            window.tinyMCE.activeEditor.notificationManager.open({
              text: 'CrawlQ Athena curated Niche related content.',
              type: 'success',
              timeout: 1000,
            });
          }
        },
      );
    }
  };

  const handleOutlineMeAthena = () => {
    const input = window.tinyMCE.activeEditor.selection.getContent();

    const mainTopic = '';

    if (input.length <= 1) {
      window.tinyMCE.activeEditor.notificationManager.open({
        text: 'Please highlight a sentence or word for Athena',
        type: 'error',
        timeout: 3000,
      });
      setIsAthenaWriting(false);
    } else {
      outlineMeAthenaHelper(input, mainTopic).then(
        ({ credits, data, error }) => {
          if (!credits) {
            setWriteMeAthenaCreditsFail(true);
          } else if (!error) {
            window.tinymce.activeEditor.execCommand(
              'mceInsertContent',
              false,
              `${input} `,
            );
            // typeWriter(data)
            window.tinymce.activeEditor.execCommand(
              'mceInsertContent',
              false,
              data,
            );
            setIsAthenaWriting(false);

            window.tinyMCE.activeEditor.notificationManager.open({
              text: 'CrawlQ Athena curated Niche related content.',
              type: 'success',
              timeout: 1000,
            });
          }
        },
      );
    }
  };

  const handleSeoMeAthena = (_engine_type, _sources) => {
    const input = window.tinyMCE.activeEditor.selection.getContent();
    console.log('jjhj');
    if (input.length <= 1) {
      window.tinyMCE.activeEditor.notificationManager.open({
        text: 'Please highlight a sentence or word for Athena',
        type: 'error',
        timeout: 3000,
      });
    } else {
      seoMeAthenaHelper(input, SelectedSources).then(
        ({ credits, data, error }) => {
          if (!credits) {
            setWriteMeAthenaCreditsFail(true);
          } else if (!error) {
            window.tinymce.activeEditor.execCommand(
              'mceInsertContent',
              false,
              `${input} `,
            );
            // typeWriter(data)
            window.tinymce.activeEditor.execCommand(
              'mceInsertContent',
              false,
              data,
            );

            window.tinyMCE.activeEditor.notificationManager.open({
              text: 'CrawlQ Athena curated Niche related content.',
              type: 'success',
              timeout: 1000,
            });
          }
        },
      );
    }
  };

  const handleWriteMeAthena = (engine_type, _sources) => {
    setIsAthenaWriting(true);
    const input = window.tinyMCE.activeEditor.selection.getContent();

    if (input.length <= 1) {
      window.tinyMCE.activeEditor.notificationManager.open({
        text: 'Please highlight a sentence or word for Athena',
        type: 'error',
        timeout: 3000,
      });
      setIsAthenaWriting(false);
    } else {
      writeMeAthenaHelper(input, engine_type, SelectedSources).then(
        ({ credits, data, error }) => {
          if (!credits) {
            setWriteMeAthenaCreditsFail(true);
          } else if (!error) {
            window.tinymce.activeEditor.execCommand(
              'mceInsertContent',
              false,
              `${input} `,
            );
            // typeWriter(data)
            window.tinymce.activeEditor.execCommand(
              'mceInsertContent',
              false,
              data,
            );
            setIsAthenaWriting(false);

            window.tinyMCE.activeEditor.notificationManager.open({
              text: 'CrawlQ Athena curated Niche related content.',
              type: 'success',
              timeout: 1000,
            });
          }
        },
      );
    }
  };

  const handleSave = async () => {
    setSaving(true);
    await props.handleSave();
    setSaving(false);
  };

  const handleDownload = () => {
    window.tinymce.activeEditor.plugins.export.download('clientpdf', {});
  };

  const handleMenuPop = (_event) => {
    setmenuPop((menuPop) => !menuPop);
  };

  const handleSelectedOption = (e) => {
    if (e.target.value === 'General') {
      if (SelectedSources.includes(e.target.value)) {
        setSelectedSources([]);
        localStorage.setItem('sources', JSON.stringify([]));
      } else {
        setSelectedSources(['General']);
        localStorage.setItem('sources', JSON.stringify([]));
      }
    } else if (SelectedSources.includes(e.target.value)) {
      const filtered1 = SelectedSources.filter((Source) => Source !== 'General');
      const filtered = filtered1.filter((Source) => Source !== e.target.value);
      setSelectedSources(filtered);
      localStorage.setItem('sources', JSON.stringify(filtered));
    } else {
      const filtered1 = SelectedSources.filter((Source) => Source !== 'General');
      const filtered2 = filtered1.concat(e.target.value);
      setSelectedSources(filtered2);
      localStorage.setItem('sources', JSON.stringify(filtered2));
    }
  };

  // Calculates Read Time
  const handleEstimateReadTime = () => {
    try {
      const iframe = document.querySelector('#texteditor_ifr');
      const result_document = iframe.contentWindow.document.getElementById(
        'tinymce',
      ).innerText;
      const wpm = 225;
      let time = 0;
      if (result_document !== '\n') {
        const words = result_document.trim().split(/\s+/).length;
        time = Math.ceil(words / wpm);
      }
      document.getElementById('read_time').innerText = time;
    } catch (ex) {
      console.log('Error in Estimating Time', ex);
    }
  };

  return (
    <div className="editor__main">
      <div
        className="editor__nav_div"
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '5px',
          padding: '4px',
          borderRadius: '6px',
          justifyContent: 'space-between',
        }}
      >
        <div
          style={{
            flex: 0.6,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <img
            style={{
              flex: 0.1,
              filter: 'drop-shadow( 0px 5px 5px #e2e2e2',
              cursor: 'pointer',
              width: '24px',
              height: '20px',
              objectFit: 'contain',
              paddingRight: '5px',
            }}
            src="../img/download.png"
            onClick={handleDownload}
          />

          {WriteMeAthenaCreditsFail ? (
            <span
              style={{
                textAlign: 'center',
                flex: 0.3,
                cursor: 'pointer',
                fontSize: '0.9rem',
                letterSpacing: '1px',
                background: 'rgb(255 0 0 / 21%)',
                color: 'rgb(224 2 2 / 81%)',
                marginLeft: '10px',
                padding: '5px 10px',
                borderRadius: '5px',
                boxShadow: '0px 5px 5px 0px rgba(119, 119, 119, 0.2)',
              }}
            >
              Credits Over
            </span>
          ) : isAthenaWriting ? (
            <LoaderSpin height="25" width="25" />
          ) : (
            <span
              style={{
                textAlign: 'center',
                flex: 0.2,
                cursor: 'pointer',
                fontSize: '14px',
                letterSpacing: '1px',
                backgroundColor: '#2a9df5',
                color: 'white',
                marginLeft: '10px',
                padding: '5px 10px',
                borderRadius: '5px',
                boxShadow: '0px 5px 5px 0px rgba(119, 119, 119, 0.2)',
              }}
              onClick={() => handleWriteMeAthena(0)}
            >
              Write Me
            </span>
          )}

          {saving ? (
            <LoaderSpin height="25" width="25" />
          ) : (
            <span
              style={{
                flex: 0.2,
                textAlign: 'center',
                cursor: 'pointer',
                fontSize: '14px',
                letterSpacing: '1px',
                backgroundColor: '#2a9df5',
                color: 'white',
                marginLeft: '10px',
                padding: '5px 10px',
                borderRadius: '5px',
                boxShadow: '0px 5px 5px 0px rgba(119, 119, 119, 0.2)',
              }}
              onClick={() => handleSave()}
            >
              {' '}
              {saving ? 'Saving' : 'Save'}
            </span>
          )}

          <div style={{ flex: '0.4' }}>
            <div className="dropdown_container">
              <span
                className="dropdown_label"
                id="demo-simple-select-label"
                onClick={() => handleMenuPop()}
              >
                Mode
                {' '}
                {menuPop ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </span>

              <div
                className="dropdown_options_container"
                style={menuPop ? { display: 'block' } : { display: 'none' }}
              >
                {SourceArray
                  && SourceArray.map((option) => ((Sources && Sources.includes(option.key))
                      || option.key === 'General' ? (
                        <div className="dropdown_option_active">
                          {/* // Source equal General or Trained Sources */}
                          {option.key === 'General' ? (
                            <div
                              style={{ display: 'flex', flexDirection: 'column' }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  flex: '1',
                                  alignItems: 'center',
                                }}
                              >
                                <input
                                  type="checkbox"
                                  value={option.key}
                                  checked={SelectedSources.includes(option.key)}
                                  onChange={(e) => handleSelectedOption(e)}
                                />
                                <span className="train_options" htmlFor={option.key}>
                                  {option.value}
                                </span>
                              </div>
                              <hr
                                style={{
                                  backgroundColor: 'rgb(245 245 245)',
                                  height: 0.1,
                                  width: '390px',
                                  marginTop: '4px',

                                  margin: '0px',
                                }}
                              />
                            </div>
                          ) : (
                          // Trained Sources but not General
                            <div
                              style={{
                                display: 'flex',
                                flex: '1',
                                alignItems: 'center',
                              }}
                            >
                              <input
                                type="checkbox"
                                value={option.key}
                                checked={SelectedSources.includes(option.key)}
                                onChange={(e) => handleSelectedOption(e)}
                              />
                              <span className="train_options" htmlFor={option.key}>
                                {option.value}
                              </span>
                              <span className="train_now"> </span>
                            </div>
                          )}
                        </div>
                    ) : (
                  // Non Trained Sources
                      <div className="dropdown_option_disabled">
                        <ReactTooltip multiline />
                        <input type="checkbox" disabled value={option} />
                        <span className="train_options">
                          {option.value}
                          {' '}
                        </span>
                        <span
                          className="train_now"
                          data-tip={
                            option.value === 'Market Research'
                              ? 'Enable this mode by training on Niche Data'
                              : option.value === 'Market Insights'
                                ? 'Enable this mode by training on Niche Data'
                                : option.value === 'Sales Copywriting'
                                  ? 'Enable this mode by training on Niche Data'
                                  : `Enable this mode by training on ${option.value}`
                          }
                        >
                          {' '}
                          <InfoOutlinedIcon
                            style={{ width: '17px', height: '17px' }}
                          />
                        </span>
                      </div>
                    )))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <TinyEditor
        onInit={(_evt, editor) => (editorRef.current = editor)}
        id="texteditor-insights"
        tinymceScriptSrc="%PUBLIC_URL%/tinymce/js/tinymce/tinymce.min.js"
        initialValue={props.text}
        init={{
          skin: 'borderless',
          menubar: false,
          height: 1000,
          menubar_location: 'bottom',
          toolbar: false,
          selector: 'textarea#premiumskinsandicons-borderless',
          contextmenu_never_use_native: true,
          plugins: [
            'quickbars',
            'contextmenu',
            ' autosave noneditable permanentpen pageembed tinycomments quickbars linkchecker emoticons advtable',
            'mediaembed  advlist checklist tinymcespellchecker powerpaste',
            'casechange importcss tinydrive searchreplace autosave advcode',
            ' autolink lists link image charmap print preview hr anchor pagebreak',
            'searchreplace wordcount visualblocks visualchars  fullscreen',
            'insertdatetime media nonbreaking save table contextmenu directionality',
            'emoticons template textcolor colorpicker textpattern imagetools codesample toc help',
            'ExportToDoc  export',
          ], // note the comma at the end of the line!
          contextmenu: 'CustomPaste  | cell row column deletetable',

          statusbar: false,

          forced_root_block: 'p',

          menu: {
            tc: {
              title: 'TinyComments',
              items: 'addcomment showcomments deleteallconversations',
            },
            media: {
              title: 'Media',
              items: 'insertfile image media pageembed template faicons',
            },
            more: {
              title: 'Features',
              items: 'casechange code',
            },
          },
          // menubar:
          //   'file edit view insert  format tools table tc media more   help customInsertButton',
          browser_spellcheck: true,
          quickbars_selection_toolbar:
            'bold italic underline | Car | formatselect image | bullist numlist quicklink | forecolor backcolor | writeMe |  SeoAthena | SocialAthena |summerizer | CompleteAthena ',
          powerpaste_googledocs_import: 'prompt',
          // toolbar: 'customInsertButton',
          // toolbar4: 'alignleft aligncenter alignright alignjustify ',
          // toolbar2: '  link anchor | codesample export  language | spellcheckdialog restoredraft | fullscreen  preview save print |  casechange permanentpen | pagebreak charmap emoticons ',
          // toolbar3: ' numlist bullist checklist | forecolor backcolor | outdent indent  ',
          autosave_ask_before_unload: true,
          tinycomments_mode: 'embedded',
          etd_file_name: 'tinymce-content',

          valid_children: '+body[style]',

          spellchecker_language: 'en',
          // // PowerPaste
          powerpaste_allow_local_images: true,
          powerpaste_word_import: 'prompt',
          powerpaste_html_import: 'prompt',
          // //PowerPaste-end
          save_enablewhendirty: true,
          autosave_ask_before_unload: false,
          fixed_toolbar_container: '#css3-selector',

          setup(ed) {
            ed.on('change', (_e) => {
              handleEstimateReadTime();
            });

            ed.ui.registry.addMenuItem('CustomPaste', {
              text: 'Paste',
              icon: 'Paste',
              onAction() {
                setSelectedData();
              },
            });

            ed.ui.registry.addIcon('triangleUp', '?');

            ed.ui.registry.addButton('basicDateButton', {
              text: 'Save',
              tooltip: 'Save Your Content',
              onAction() { },
            });

            ed.ui.registry.addButton('writeMe', {
              text: 'Write Me Athena',
              tooltip: 'Complete Athena',
              icon: 'edit-block',

              onAction() {
                writeMeAthena(0);
              },
            });

            // ed.ui.registry.addButton('SeoAthena', {
            //   text: 'Seo Me Athena',
            //   icon: 'edit-block',
            //   tooltip: 'Write SEO Post',

            //   onAction: function() {
            //     handleSeoMeAthena()
            //   },
            // })
            ed.ui.registry.addButton('SocialAthena', {
              text: 'Social Me Athena',
              icon: 'edit-block',
              tooltip: 'Write Social Post',

              onAction() {
                handleSocialMeAthena();
              },
            });

            ed.ui.registry.addButton('OutlineMeAthena', {
              text: 'Outline Me Athena',
              icon: 'edit-block',
              tooltip: 'Generate Outline',

              onAction() {
                handleOutlineMeAthena();
              },
            });

            ed.ui.registry.addButton('summerizer', {
              text: 'Summarize',
              tooltip: 'Text Summerizer',

              async onAction() {
                const input = window.tinymce.activeEditor.selection.getContent();
                console.log(input.trim().split(' ').length);
                if (
                  input.trim().split(' ').length > 250
                  || input.trim().split(' ').length < 30
                ) {
                  window.tinyMCE.activeEditor.notificationManager.open({
                    text: 'Please Select Words between 30 to 250.',
                    type: 'error',
                    timeout: 2000,
                  });
                  return;
                }
                const output = await handleSummerizerForEditor(input);
                console.log(input + output);
                window.tinymce.activeEditor.execCommand(
                  'mceInsertContent',
                  false,
                  `${input} <p> ${output} </p>`,
                );
                window.tinyMCE.activeEditor.notificationManager.open({
                  text: 'Text Summerized Sucessfully.',
                  type: 'success',
                  timeout: 1000,
                });
              },
            });

            ed.ui.registry.addButton('Car', {
              text: 'Copy',
              icon: 'Copy',
              tooltip: 'Copy',

              onAction() {
                getSelectedText();
              },
            });

            ed.ui.registry.addButton('CompleteAthena', {
              text: 'Ask Me Athena',
              icon: 'triangleUp',
              tooltip: 'Instruct Athena',

              onAction() {
                writeMeAthena(1);
              },
            });
          },
        }}
        onEditorChange={currentData}
      />
    </div>
  );
}

export default AdvancedTextEditor;
