import createInstance from 'Config/AxiosConfig';
import { FALLBACK_ACTIVE_WORKSPACE } from 'libs/chat-athena-constants';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { CHAT_HISTORY_QUERY_KEY } from './get-chat-history';

export const useMutateUpdateChatHistory = () => {
  const user = useSelector((state) => state?.user);
  const workspace = useSelector((state) => state?.workspace);
  const queryClient = useQueryClient();

  const workspaceName = workspace?.name ?? FALLBACK_ACTIVE_WORKSPACE?.name;

  async function updateChatHistoryById(newAnswerData) {
    const { id, updateAnswer, rating } = newAnswerData;
    const payload = {
      username: user?.username,
      name: workspaceName,
      id,
    };

    if (updateAnswer !== undefined && updateAnswer !== null) {
      payload.answer = updateAnswer;
    }

    if (rating !== undefined && rating !== null) {
      payload.rating_scale = rating;
    }

    const instance = await createInstance();
    const response = await instance.post('update-chat-response', payload);
    if (response.data?.statusCode !== '200') {
      throw new Error('Error in Updating answer');
    }
  }

  return useMutation(updateChatHistoryById, {
    onMutate: (variables) => {
      const workspaceName = workspace?.name ?? FALLBACK_ACTIVE_WORKSPACE?.name;

      const currentMessage = queryClient.getQueryData([
        CHAT_HISTORY_QUERY_KEY,
        workspaceName,
      ]);
      const newMessages = {
        ...currentMessage,
        pairs: currentMessage.pairs.map((pair) =>
          pair.id === variables.id
            ? {
              ...pair,
              answer: variables?.updateAnswer ?? pair.answer,
              rating_scale: variables?.rating ?? pair.rating_scale,
            }
            : pair,
        ),
      };
      queryClient.setQueryData(
        [CHAT_HISTORY_QUERY_KEY, workspaceName],
        newMessages,
      );
      return currentMessage;
    },
    onError: (error, variables, previousMessages) => {
      const workspaceName = workspace?.name ?? FALLBACK_ACTIVE_WORKSPACE?.name;
      queryClient.setQueryData(
        [CHAT_HISTORY_QUERY_KEY, workspaceName],
        previousMessages,
      );
      toast.error(error.message, {
        position: 'bottom-center',
        theme: 'dark',
      });
    },
  });
};
