import React from 'react';
import PropTypes from 'prop-types';

import './style.css';
import { LoaderTailSpin } from 'Component/Shared/Loader';

function DualActionView({
  headingText,
  children,
  secondaryBtnText,
  primaryBtnText,
  secondaryAction,
  primaryAction,
  processing,
}) {
  return (
    <div className="dual-action-view-wrapper">
      <div className="dual-action-view-header">{headingText}</div>
      <hr className="dual-action-view-divider" />
      <div className="dual-action-view-body">{children}</div>
      <div className="dual-action-view-actions">
        <button
          className="dual-action-view-secondary-btn"
          onClick={secondaryAction}
          type="button"
        >
          {secondaryBtnText}
        </button>
        <button
          className="dual-action-view-primary-btn"
          onClick={primaryAction}
          type="button"
        >
          {!processing ? (
            primaryBtnText
          ) : (
            <LoaderTailSpin height={25} width={25} />
          )}
        </button>
      </div>
    </div>
  );
}

DualActionView.propTypes = {
  headingText: PropTypes.string.isRequired,
  secondaryBtnText: PropTypes.string.isRequired,
  primaryBtnText: PropTypes.string.isRequired,
  secondaryAction: PropTypes.func.isRequired,
  primaryAction: PropTypes.func.isRequired,
  processing: PropTypes.bool.isRequired,
};

export default DualActionView;
