import React from 'react';
import { useNavigate } from 'react-router-dom';
import { userLogOut } from 'redux/index';
import { Auth } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import { WORKSPACE, WORKSPACES } from 'libs/constants';
import WorkspaceIcon from '../../../assets/images/project-outline.svg';
import NewWorkspaceIcon from '../../../assets/images/new-workspace.svg';

export default function DropDownBox() {
  const { plan, admin } = useSelector((state) => state?.user);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const goToChangePasswordPage = () => {
    navigate('/ChangePassword');
  };

  const handlelogout = async () => {
    dispatch(userLogOut());
    localStorage.clear();
    await Auth.signOut();
    navigate('/');
  };

  const goToProfile = () => {
    navigate('/ProfilePage');
  };

  const goToTeamMemberpage = () => {
    navigate('/TeamMember');
  };

  const goToWorkspacePage = () => {
    navigate('/Workspace');
  };

  return (
    <div className="topbar-dropdown-box-container">
      <div className="topbar-dropdown-item-container">
        <div className="topbar-item" onClick={goToProfile}>
          <AccountCircleRoundedIcon />
          <div>Profile</div>
        </div>
        {/* <div className="topbar-item" onClick={goToWorkspacePage}>
          <img src={WorkspaceIcon} alt="icon" width={30} />
          <div>All {WORKSPACES}</div>
        </div> */}
        {!plan.includes('Clients') && admin === 'True' && (
          <div className="topbar-item" onClick={goToTeamMemberpage}>
            <img src={NewWorkspaceIcon} alt="icon" width={30} />
            <div>Collaborate and Scale With Your Team & Clients</div>
          </div>
        )}
        {/* <div className="topbar-item" onClick={goToChangePasswordPage}>
          <LockRoundedIcon />
          <div> Change Password </div>
        </div> */}
        <div className="topbar-item" onClick={handlelogout}>
          <LogoutRoundedIcon />
          <div>Logout</div>
        </div>
      </div>
    </div>
  );
}
