import AppHeaderWrapper from 'components/common/AppHeader/AppHeaderWrapper';
import { HeaderCard } from 'components/common/HeaderCard/HeaderCard';
import React from 'react';
import { InsightsProvider } from 'context/assistants/InsightsContext';
import Insights from 'components/assistants/assistants-actions/insights-360/Insights';
import styles from './insights.module.css';

function Insights360AI() {
  return (
    <AppHeaderWrapper className={styles.container}>

      <HeaderCard
        className={styles.header_container}
        title="Insights 360ai"
        description=""
      />

      <div className={styles.main_container}>
        <InsightsProvider>
          <Insights />
        </InsightsProvider>
      </div>
    </AppHeaderWrapper>
  );
}

export default Insights360AI;
