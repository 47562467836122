import { MARKET_TABS } from 'constants/content/content-tools/Market';
import React, { useState } from 'react';
import FredMarketResearch from './fred-market-research/FredMarketResearch';
import MarketInsights from './market-insights/MarketInsights';
import styles from './market.module.css';

const SelectedTab = ({ tab }) => {
  // Refer constant HEADERS to know order of the tabs
  switch (tab) {
    case MARKET_TABS[0]:
      return <FredMarketResearch />;

    case MARKET_TABS[1]:
      return <MarketInsights />;

    default:
      return <FredMarketResearch />;
  }
};

function Market() {
  const x = '';

  const [Tab, setTab] = useState(MARKET_TABS[0]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.tab_container}>
        {MARKET_TABS.map((tab) => (
          <span
            key={tab}
            className={Tab === tab ? `${styles.tab_active} ${styles.tab}` : `${styles.tab_inactive} ${styles.tab}`}
            onClick={() => setTab(tab)}
          >
            {tab}
          </span>
        ))}
      </div>

      <div className={styles.content_container}>
        <SelectedTab tab={Tab} />
      </div>
    </div>
  );
}

export default Market;
