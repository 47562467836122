import React, { useCallback, useState } from 'react';
import {
  Divider,
  ScaffoldCard,
  FilePinIcon,
  CrossIcon,
  UploadDoneIcon,
  LoaderTailSpin,
  PrimaryButton,
} from 'components/common';
import { useMutateCreateAssistant } from 'queries/assistants/mutate-create-assistant';
import { useMutateUploadDocument } from 'queries/upload-document/upload-document';
import { useMutateProcessUploadedDocument } from 'queries/process-uploaded-file';
import {
  getProcessedAndUploadedFileIds,
  getSelectedActiveSources,
  getSelectedActiveTools,
  setUploadingForSelectedFile,
  validateCreateAssistantInputs,
} from 'queries/assistants/helpers';
import { getRandomOptions } from 'components/common/AvatarGenerator/helper';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AvatarGeneratorPlaceholder from 'components/common/AvatarGenerator/AvatarGenerator';
import { ASSISTANTS_VALID_FILES_TYPE } from 'constants/assistants';
import ExistingFiles from '../assistants-actions/existing-files/ExistingFiles';
import styles from './create-assistant.module.css';

const CreateAssistant = () => {
  // Create Assistant Hook
  const {
    mutateAsync: mutateCreateAssistant,
    isLoading: isCreatingAssistant,
  } = useMutateCreateAssistant();

  // Upload Docs Hook
  const { mutateAsync: mutateAsyncUploadDocument } = useMutateUploadDocument();

  // Process Docs Hook
  const {
    mutateAsync: mutateAsyncProcessUploadedDocument,
  } = useMutateProcessUploadedDocument();

  const [AssistantInputInfo, setAssistantInputInfo] = useState({
    name: '',
    description: '',
    instruction: '',
  });
  const [SelectedFiles, setSelectedFiles] = useState([]);

  const [ProcessedAndUploadedFiles, setProcessedAndUploadedFiles] = useState(
    [],
  );
  const [AssistantAvatarProps, setAssistantAvatarProps] = useState(
    getRandomOptions(), // initilizing it with random avatar
  );
  const [AssistantTools, setAssistantTools] = useState([
    {
      name: 'Retrieval',
      value: 'retrieval',
      checked: false,
    },
    {
      name: 'Code Interpreter',
      value: 'code_interpreter',
      checked: false,
    },
  ]);

  const [AssistantSources, setAssistantSources] = useState([
    {
      name: 'Market Research',
      value: 'mrr',
      checked: false,
    },
    {
      name: 'Market Insights',
      value: 'mrr_insights',
      checked: false,
    },
    {
      name: 'Google',
      value: 'serp',
      checked: false,
    },
  ]);

  // Responsible for assistant name, instruction and description inputs
  const handleAssistantInputInfo = (event) => {
    setAssistantInputInfo({
      ...AssistantInputInfo,
      [event.target.name]: event.target.value,
    });
  };

  // Responsbile for file selection from device
  const handleFileSelection = (event) => {
    const file = event.target.files[0];

    // Adding Selected Files Into Files Array
    if (file) {
      setSelectedFiles([
        ...SelectedFiles,
        { name: event.target.files[0]?.name, file, uploading: false },
      ]);
    }
  };

  // Responsible for uploading the selected document/file
  const handleUploadFile = async (selectedDocument) => {
    // setting the selected file 'uploading' status to true
    const updatedUploadingStateOfSelectedDocument = setUploadingForSelectedFile(
      SelectedFiles,
      selectedDocument,
    );

    setSelectedFiles(updatedUploadingStateOfSelectedDocument);

    // uploading the file/document
    await mutateAsyncUploadDocument({
      fileName: selectedDocument?.name,
      file: selectedDocument?.file,
    }).then(async (uploadedFileId) => {
      // if fileId received, its means file uploaded successfully.
      if (uploadedFileId) {
        // process the uploaded document
        mutateAsyncProcessUploadedDocument({
          name: selectedDocument?.name,
          file_id: uploadedFileId,
        }).then(() => {
          // SelectedFiles state needs to filter the uploaded file
          const nonProcessedAndUploadedFileList = SelectedFiles.filter(
            (file) => file?.name !== selectedDocument?.name,
          );
          setSelectedFiles(nonProcessedAndUploadedFileList);

          // Updated the ProcessedAndUploaded state with the new uploaded file
          setProcessedAndUploadedFiles([
            ...ProcessedAndUploadedFiles,
            {
              name: selectedDocument?.name,
              fileId: uploadedFileId,
              state: true,
            },
          ]);
        });
      }
    });
  };

  // Responsible for removing the selected file
  const handleFilterFile = (toRemoveFile) => {
    const filteredFiles = SelectedFiles.filter(
      (file) => file?.name !== toRemoveFile,
    );

    setSelectedFiles(filteredFiles);
  };

  const handleFilterProcessedFile = (toRemoveFile) => {
    const filteredFiles = ProcessedAndUploadedFiles.filter(
      (file) => file?.name !== toRemoveFile,
    );

    setProcessedAndUploadedFiles(filteredFiles);
  };

  // Responsible for toggling checked state of the selected tool
  const handleToolSelection = (selectedTool) => {
    // Find The Index of selected Tool
    const selectedToolIdx = AssistantTools.findIndex(
      (tool) => tool?.name === selectedTool?.name,
    );

    // Create Copy of Assistant Tool
    const AssistantToolsCopy = [...AssistantTools];

    // Toogle the Tool boolean value in Copy of Assistant Tool
    AssistantToolsCopy[selectedToolIdx].checked = !AssistantTools[
      selectedToolIdx
    ].checked;

    // Set the copy of Assistant Tool
    setAssistantTools(AssistantToolsCopy);
  };

  // Responsible for toggling checked state of the selected source
  const handleSourceSelection = (selectedSource) => {
    // Find The Index of selected Source
    const selectedSourceIdx = AssistantSources.findIndex(
      (source) => source?.name === selectedSource?.name,
    );

    const AssistantSourcesCopy = [...AssistantSources];

    // Toogle the Source boolean value in Copy of Assistant Sources
    AssistantSourcesCopy[selectedSourceIdx].checked = !AssistantSources[
      selectedSourceIdx
    ]?.checked;

    setAssistantSources(AssistantSourcesCopy);
  };

  // Responsible for validating input and hitting the create assistant endpoint
  const handleCreateAssistant = async () => {
    const { fail, errorMessage } = validateCreateAssistantInputs(
      AssistantInputInfo,
      AssistantAvatarProps,
    );

    if (fail) {
      toast.error(errorMessage, {
        position: 'bottom-center',
        theme: 'dark',
      });
      return;
    }

    await mutateCreateAssistant({
      assistant_name: AssistantInputInfo?.name,
      description: AssistantInputInfo?.description,
      instructions: AssistantInputInfo?.instruction,
      tools: await getSelectedActiveTools(AssistantTools),
      sources: await getSelectedActiveSources(AssistantSources),
      avatar_props: AssistantAvatarProps,
      file_ids: await getProcessedAndUploadedFileIds(ProcessedAndUploadedFiles),
    });
  };

  const handleAvatarPropertiesGenerator = useCallback(() => {
    const avatarProp = getRandomOptions();
    setAssistantAvatarProps(avatarProp);
  }, [AssistantAvatarProps, setAssistantAvatarProps]);

  return (
    <div className={styles.create_assistant_parent_container}>
      <ScaffoldCard className={styles.create_assistant_wrapper}>
        <div className={styles.create_assistant_content}>
          <div className={styles.section_left}>
            <AvatarGeneratorPlaceholder
              avatarProp={AssistantAvatarProps}
              handleRandomGeneration={handleAvatarPropertiesGenerator}
              editable
            />

            <div className={styles.input_container}>
              <label className={styles.input_label}>Name</label>
              <input
                name="name"
                className={styles.input_box}
                value={AssistantInputInfo?.name}
                placeholder="Give your AI Assistant a friendly name"
                onChange={(event) => handleAssistantInputInfo(event)}
              />
            </div>
            <div className={styles.input_container}>
              <label className={styles.input_label}>Descrption</label>
              <textarea
                name="description"
                className={styles.input_box}
                value={AssistantInputInfo?.description}
                placeholder="Provide a brief description of your AI Assistant"
                onChange={(event) => handleAssistantInputInfo(event)}
              />
            </div>
            <div className={styles.input_container}>
              <label className={styles.input_label}>Instruction</label>
              <textarea
                name="instruction"
                className={`${styles.input_box} ${styles.instruction_textarea}`}
                value={AssistantInputInfo?.instruction}
                placeholder="Give detailed instructions to your AI Assistant"
                onChange={(event) => handleAssistantInputInfo(event)}
              />
            </div>
          </div>
          <div className={styles.section_right}>
            <div className={styles.input_container}>
              <label className={styles.input_label}>Tools</label>
              <Divider />
              {AssistantTools?.map((tool) => (
                <label className={styles.checkbox_container}>
                  <input
                    className={styles.checkbox_input}
                    type="checkbox"
                    value={tool?.value}
                    checked={tool?.checked}
                    onChange={() => handleToolSelection(tool)}
                  />
                  {tool?.name}
                </label>
              ))}
            </div>

            <div className={styles.input_container}>
              <label className={styles.input_label}>Data Sources</label>
              <Divider />
              {AssistantSources?.map((source) => (
                <label className={styles.checkbox_container}>
                  <input
                    className={styles.checkbox_input}
                    type="checkbox"
                    value={source?.value}
                    checked={source?.checked}
                    onChange={() => handleSourceSelection(source)}
                  />
                  {source?.name}
                </label>
              ))}
            </div>

            <div className={styles.input_container}>
              <label className={styles.input_label}>
                <span>Files</span>
                <div
                  className={styles.file_select_container}
                  htmlFor="inputFile"
                >
                  <input
                    id="inputFile"
                    className={styles.input_file}
                    type="file"
                    accept={ASSISTANTS_VALID_FILES_TYPE}
                    onChange={handleFileSelection}
                  />
                  <FilePinIcon primary width="16px" />{' '}
                  <span className={styles.add_text}>Add</span>
                </div>
              </label>
              <Divider />

              {SelectedFiles?.map((file) => (
                <div className={styles.selectedfile_wrapper}>
                  <div className={styles.selectedfile_container}>
                    <FilePinIcon active width="16px" />
                    <span className={styles.selectedfile_text}>
                      {file?.name}
                    </span>
                  </div>
                  <CrossIcon
                    danger
                    marginLeft="0.4rem"
                    marginRight="0.4rem"
                    width="16px"
                    onClick={() => handleFilterFile(file?.name)}
                  />
                  {!file?.uploading && (
                    <button
                      className={styles.upload_file_button}
                      type="button"
                      onClick={() => handleUploadFile(file)}
                    >
                      Upload
                    </button>
                  )}
                  {file?.uploading && (
                    <LoaderTailSpin className={'h-margin-l-tiny'} width={18} />
                  )}
                </div>
              ))}

              {ProcessedAndUploadedFiles?.map((file) => (
                <div className={styles.selectedfile_wrapper}>
                  <div className={styles.selectedfile_container}>
                    <FilePinIcon active width="16px" />
                    <span className={styles.selectedfile_text}>
                      {file?.name}
                    </span>
                  </div>
                  <UploadDoneIcon active marginLeft="8px" />
                  <CrossIcon
                    danger
                    marginLeft="0.4rem"
                    marginRight="0.4rem"
                    width="16px"
                    onClick={() => handleFilterProcessedFile(file?.name)}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.create_action_container}>
          <PrimaryButton
            disabled={isCreatingAssistant}
            onClick={handleCreateAssistant}
          >
            Create Assistant
            {isCreatingAssistant && <LoaderTailSpin width={18} />}
          </PrimaryButton>
        </div>
      </ScaffoldCard>
      <ExistingFiles
        processedFiles={ProcessedAndUploadedFiles}
        setProcessedFiles={setProcessedAndUploadedFiles}
      />
    </div>
  );
};

export default CreateAssistant;
