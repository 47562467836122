/* eslint-disable consistent-return */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { LoaderTailSpin } from 'Component/Shared/Loader';
import { Link } from 'react-router-dom';
import { Button } from 'Component/Shared/ButtonNext';
import notFoundIcon from '../../../assets/img/search.svg';
import { marketResearchSaveInsights } from 'Config/Api';
import TopNav from '../../Shared/Sidenav/Topnav';
import Topbar from '../../Shared/Topbar/Topbar';
import AdvancedTextEditor from '../Content Automation/AIContentWriter/AdvancedTextEditorInsights';
import style from './insights_style.module.css';

function InsightsGlobal() {
  const [insights, setInshights] = useState([]);
  const [insightsLoading, setInshightsLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [isSavingInsights, setIsSavingInsights] = useState(false);
  const [hasSaved, setHasSaved] = useState(false);

  useEffect(() => {
    getInsightsNotes();
  }, []);

  const getEditorElement = () => {
    const iframe = document.querySelector('#texteditor-insights_ifr');
    if (!iframe) return;
    const editor = iframe.contentWindow.document.getElementById('tinymce');
    if (editor) return editor;
    return null;
  };

  const handleOpenEditor = (value, index) => {
    const editor = getEditorElement();
    editor.innerHTML = value.replaceAll('\n', '<br>');
    setCurrentIndex(index);
  };

  const handleSaveInsights = () => {
    const editor = getEditorElement();
    const newInsights = editor.innerHTML;
    saveInsights(insights[currentIndex].category, newInsights);
    setInshights((prev) => {
      prev[currentIndex].value = newInsights;
      return prev;
    });
  };

  const getInsightsNotes = async () => {
    try {
      setInshightsLoading(true);
      const body = {
        username: localStorage.getItem('username'),
        name: localStorage.getItem('name'),
      };
      const { data } = await axios.post(
        'https://apis.crawlq.ai/insights-global',
        body,
      );
      setInshights(data.data.insights);
    } catch (ex) {
      console.log('Error in notes API', ex);
      setInshights([]);
    } finally {
      setInshightsLoading(false);
    }
  };

  const removeDatesfromHTML = (html) => {
    const div = document.createElement('div');
    div.innerHTML = html;
    const elements = div.getElementsByTagName('blockquote');
    while (elements[0]) elements[0].parentNode.removeChild(elements[0]);
    const repl = div.innerHTML;
    return repl;
  };

  const getFilteredInsightsValue = (html, char_count) => {
    const filteredHTML = removeDatesfromHTML(html);
    const div = document.createElement('div');
    div.innerHTML = filteredHTML;
    const text = div.textContent || div.innerText || '';
    if (text.length > 111) return `${text.slice(0, char_count)}...`;
    return text;
  };

  function InsightsCard(props, index) {
    return (
      <div className={`col-md-4 col-6 ${style.insights_card_container}`}>
        <div
          className={style.insights_card_wrapper}
          data-toggle="modal"
          data-target="#exampleModal"
          type="button"
          onClick={() => handleOpenEditor(props.value, index)}
        >
          <strong className="h-padding-b-x2 text-dark">
            {getFilteredInsightsValue(props.category, 70)}
          </strong>
          <p className={style.insights_description}>
            {getFilteredInsightsValue(props.value, 100)}
          </p>
        </div>
        {/* Modal */}
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{
              height: '300px',
            }}
          >
            <div className="modal-content">
              <div className="modal-body">
                <div className="d-flex justify-content-between">
                  <h3>Saved Insights</h3>
                  {hasSaved && (
                    <h6 className="text-success">Saved Successfully</h6>
                  )}
                </div>
                <div
                  style={{
                    height: '60vh',
                    overflow: 'hidden',
                  }}
                >
                  <AdvancedTextEditor text="" onChange={() => 'hello'} />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <Button
                  name="Save Changes"
                  onClick={handleSaveInsights}
                  showLoader={isSavingInsights}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const saveInsights = async (subcategory, newInsight) => {
    const url = marketResearchSaveInsights;

    if (newInsight.trim() === '') return;

    const body = {
      username: localStorage.getItem('username'),
      name: localStorage.getItem('project_name'),
      subcategory,
      insight: newInsight,
    };
    setIsSavingInsights(true);
    const { data } = await axios.post(url, body);

    if (data.statusCode === '200' && data.status === 'success') {
      setIsSavingInsights(false);
      setHasSaved(true);
      setTimeout(() => setHasSaved(false), 3000);
    }
  };

  return (
    <>
      <TopNav />
      <Topbar />
      <div className="main-content" id="panel">
        <div className="px-3">
          <div className="profile-header">
            <b>Social Listening</b>
            <span className="font-accent txt-primary">
              Take your Audience Research to the next level with the advanced AI
              Social Listening
            </span>
          </div>
          <div className={`row ${style.cards_container}`}>
            {!insightsLoading ? (
              <>
                {insights.map((insight, index) => InsightsCard(insight, index))}
              </>
            ) : (
              <div className="text-center w-100">
                <LoaderTailSpin />
                Fetching Saved Insights...
              </div>
            )}
          </div>
          {!insightsLoading && !insights.length && (
            <div className="text-center">
              <img src={notFoundIcon} width={350} />
              <h4 className="mt-4">
                Try Saving Insights in
                <Link to="/MRR-Get-Started"> Market Research</Link>
              </h4>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default InsightsGlobal;
