export const PROMPTS = [
  {
    template_name: '1. Create Customer Decision Journey (Market Research)',
    updated_at: '08/30/2023, 17:20:12',
    stage: 'complete',
    created_at: '11/28/2022, 21:49:37',
    template_id: '9c2b9a1dc48b496aa3395b54e72ca75b',
    username: 'support@quantamixsolutions.com',
    sections: [
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          '5 drivers to trigger the awareness stage of my Ideal Prospect',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          '5 barriers to trigger the awareness stage of my Ideal Prospect',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          '5 solutions to trigger the awareness stage of my Ideal Prospect',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          '5 take-out actions to trigger the awareness stage of my Ideal Prospect',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          '5 drivers to trigger the desire stage of my Ideal Prospect',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          '5 barriers to trigger the desire stage of my Ideal Prospect',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          '5 solutions to trigger the desire stage of my Ideal Prospect',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          '5 take-out actions to trigger the desire stage of my Ideal Prospect',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          '5 drivers to trigger the initial purchase consideration stage of my Ideal Prospect',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          '5 barriers to trigger the initial purchase consideration stage of my Ideal Prospect',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          '5 solutions to trigger the initial purchase consideration stage of my Ideal Prospect',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          '5 take-out actions to trigger the initial purchase consideration stage of my Ideal Prospect',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          '5 drivers to trigger the active purchase evaluation stage of my Ideal Prospect',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          '5 barriers to trigger the active purchase evaluation stage of my Ideal Prospect',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          '5 solutions to trigger the active purchase evaluation stage of my Ideal Prospect',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          '5 take-out actions to trigger the active purchase evaluation stage of my Ideal Prospect',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          '5 drivers to trigger the final purchase decision stage of my Ideal Prospect',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          '5 barriers to trigger the final purchase decision stage of my Ideal Prospect',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          '5 solutions to trigger the final purchase decision stage of my Ideal Prospect',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          '5 take-out actions to trigger the final purchase decision stage of my Ideal Prospect',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          '5 drivers to trigger the post-purchase reward decision stage of my Ideal Prospect',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          '5 barriers to trigger the post-purchase reward decision stage of my Ideal Prospect',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          '5 solutions to trigger the post-purchase reward decision stage of my Ideal Prospect',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          '5 take-out actions to trigger the post-purchase reward decision stage of my Ideal Prospect',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          '5 drivers to trigger the post-purchase brand loyalty decision stage of my Ideal Prospect',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          '5 barriers to trigger the post-purchase brand loyalty decision stage of my Ideal Prospect',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          '5 solutions to trigger the post-purchase brand loyalty decision stage of my Ideal Prospect',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          '5 take-out actions to trigger the post-purchase brand loyalty decision stage of my Ideal Prospect',
      },
    ],
    template_description:
      'This template builds the market messaging hierarchy based on the different stages of the customer decision journey. ',
    author_name: 'Harish Kumar',
    index: 0,
  },
  {
    template_name: '2. Ideal Prospect Persona Summary',
    updated_at: '08/30/2023, 17:23:21',
    stage: 'complete',
    created_at: '02/02/2023, 11:14:10',
    template_id: '0eeb87559d7346c3ab7a687e9441cc72',
    username: 'support@quantamixsolutions.com',
    sections: [
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt: 'Name of my Ideal Prospect',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt: 'Describe Role/Title Decision Maker',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt: 'Top 5 Areas of Current Activity/Responsibility',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt: 'Top 5 Pain Points',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt: 'Top 5 Needs Statements',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt: 'Top 5 Customer Touchpoints or Watering Holes',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt: 'Top 5 Market Geography/Locations',
      },
    ],
    template_description:
      "This template provides a quick summary of your persona's key pain points, needs, desires, job roles, and market demographics. ",
    author_name: 'Harish Kumar',
    index: 1,
  },
  {
    template_name: '3. Create Campaign Brief',
    updated_at: '08/30/2023, 17:27:02',
    stage: 'complete',
    created_at: '12/13/2022, 14:53:20',
    template_id: 'ad16aa1b4ebb4a439c7fcffe2b8f4253',
    username: 'support@quantamixsolutions.com',
    sections: [
      {
        sources: [
          'mrr',
          'mrr_insight',
          'script',
          'serp',
          'quora',
          'reddit',
          'website',
        ],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          '1. How this campaign will help us achieving the key ”How to Win” goals of our business?',
      },
      {
        sources: [
          'mrr',
          'mrr_insight',
          'script',
          'serp',
          'quora',
          'reddit',
          'website',
        ],
        method: 'write_me',
        show_prompt: true,
        template_text_prompt:
          'This campaign will unlock new value spaces in our core portfolio using',
      },
      {
        sources: [
          'mrr',
          'mrr_insight',
          'script',
          'serp',
          'quora',
          'reddit',
          'website',
        ],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt: '2. What is the key challenge?',
      },
      {
        sources: [
          'mrr',
          'mrr_insight',
          'script',
          'serp',
          'quora',
          'reddit',
          'website',
        ],
        method: 'write_me',
        show_prompt: true,
        template_text_prompt:
          'High awareness and low consideration challenge e.g., declining sales ',
      },
      {
        sources: [
          'mrr',
          'mrr_insight',
          'script',
          'serp',
          'quora',
          'reddit',
          'website',
        ],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          '3. What are the Objectives & KPIs of this campaign?',
      },
      {
        sources: [
          'mrr',
          'mrr_insight',
          'script',
          'serp',
          'quora',
          'reddit',
          'website',
        ],
        method: 'write_me',
        show_prompt: true,
        template_text_prompt: 'Increased penetration of the market by x%',
      },
      {
        sources: [
          'mrr',
          'mrr_insight',
          'script',
          'serp',
          'quora',
          'reddit',
          'website',
        ],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          '4. Who is our core audience for this campaign and why?',
      },
      {
        sources: [
          'mrr',
          'mrr_insight',
          'script',
          'serp',
          'quora',
          'reddit',
          'website',
        ],
        method: 'write_me',
        show_prompt: true,
        template_text_prompt:
          'Convert ‘Emerging Approval Seekers’ because XX% are dissatisfied with their current product',
      },
      {
        sources: [
          'mrr',
          'mrr_insight',
          'script',
          'serp',
          'quora',
          'reddit',
          'website',
        ],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          '5. What do these audience think & do today with respect to our product(not lifestyle info)',
      },
      {
        sources: [
          'mrr',
          'mrr_insight',
          'script',
          'serp',
          'quora',
          'reddit',
          'website',
        ],
        method: 'write_me',
        show_prompt: true,
        template_text_prompt: 'Perpetual belief and behavioral belief',
      },
      {
        sources: [
          'mrr',
          'mrr_insight',
          'script',
          'serp',
          'quora',
          'reddit',
          'website',
        ],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          '6. What do we want our audience to think or do differently?',
      },
      {
        sources: [
          'mrr',
          'mrr_insight',
          'script',
          'serp',
          'quora',
          'reddit',
          'website',
        ],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          '7. What is the innovation or superiority differentiation and where are we better than the competition? ',
      },
      {
        sources: [
          'mrr',
          'mrr_insight',
          'script',
          'serp',
          'quora',
          'reddit',
          'website',
        ],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          '8. What are our weaknesses and where is the competition better than us?',
      },
      {
        sources: [
          'mrr',
          'mrr_insight',
          'script',
          'serp',
          'quora',
          'reddit',
          'website',
        ],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          '9. Who are the key competitors – direct & category disruptors?',
      },
    ],
    template_description:
      'This template creates a persona-focused campaign brief based on the key business goals and objectives. ',
    author_name: 'Harish Kumar',
    index: 2,
  },
  {
    template_name: '4. Attractive High-Impact Content Topics',
    updated_at: '08/30/2023, 17:46:09',
    stage: 'complete',
    created_at: '01/14/2023, 11:49:08',
    template_id: '0d494c310cd84a6e9466b248389df4b6',
    username: 'support@quantamixsolutions.com',
    sections: [
      {
        sources: ['mrr', 'mrr_insight', 'serp', 'quora', 'reddit'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          'Identify 5 current problems of my Ideal Prospect',
      },
      {
        sources: ['mrr', 'quora', 'serp'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          'Identify 10 Quora questions my Ideal Prospect would ask',
      },
      {
        sources: ['mrr', 'mrr_insight', 'serp', 'quora', 'reddit'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          'Identify 10 blog titles my Ideal Prospect will want to read',
      },
      {
        sources: ['mrr', 'mrr_insight', 'serp', 'reddit'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          'Identify 10 reddit questions my Ideal Prospect would ask',
      },
    ],
    template_description:
      'This template Identifies high-impact attractive content ideas, blog titles, and Reddit and Quora questions to inspire your content strategy.',
    author_name: 'Mark Draper',
    index: 3,
  },
  {
    template_name: '5. Attitude Intelligence Customer Segment Analysis',
    updated_at: '08/30/2023, 17:48:15',
    stage: 'complete',
    created_at: '12/04/2022, 21:06:50',
    template_id: '500ca943cb0c424eb54fb06e7ea575eb',
    username: 'support@quantamixsolutions.com',
    sections: [
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          'Engaged and Enthusiastic: 5 "I like to appear stylish..." statements of my Ideal Prospect',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          'Achieving and Modern Alpha: 5 "I like to stand out..." statements of my Ideal Prospect',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          'Emerging and Insecure: 5 "I wish I didn\'t need to spend..." statements of my Ideal Prospect',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          'Confident and Laid Back: 5 "I like to look..." statements of my Ideal Prospect',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          'Competent and Pragmatic: 5 "I am who I am..." statements of my Ideal Prospect',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          'Understanding and Play-it-safe: 5 "My style is easy..." statements of my Ideal Prospect',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          'Disengaged and Routine: 5 "I don\'t follow trends because..." statements of my Ideal Prospect',
      },
    ],
    template_description:
      'This template drives customer segment analysis based on attitude intelligence.',
    author_name: 'Harish Kumar',
    index: 4,
  },
  {
    template_name: '6. Creative Content Brief',
    updated_at: '08/30/2023, 17:50:54',
    stage: 'complete',
    created_at: '12/19/2022, 23:45:59',
    template_id: 'a416b83646744752be52d4fb57b42a4d',
    username: 'support@quantamixsolutions.com',
    sections: [
      {
        sources: ['mrr', 'mrr_insight'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          'What are the goal and the scope of this creative content brief?',
      },
      {
        sources: ['mrr', 'mrr_insight'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt: '5 prospective target audience segments',
      },
      {
        sources: ['mrr', 'mrr_insight'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          'What is the single-minded message we want our ideal prospect to understand?',
      },
      {
        sources: ['mrr', 'mrr_insight'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          "What is the single most important thing our Company offers to make our ideal prospect's life easy?",
      },
      {
        sources: ['mrr', 'mrr_insight'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt: 'What does my ideal prospect need to know?',
      },
      {
        sources: ['mrr', 'mrr_insight'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt: 'What does my ideal prospect need to feel?',
      },
      {
        sources: ['mrr', 'mrr_insight'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          'Why would my ideal prospect care about my product?',
      },
      {
        sources: ['mrr', 'mrr_insight'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          "5 key barriers to the top-of-the funnel awareness stage of my customer's purchase journey",
      },
      {
        sources: ['mrr', 'mrr_insight'],
        method: 'headline',
        show_prompt: true,
        template_text_prompt:
          'Powerful attention grabbing headline of 60 characters for the key feature in the top-of-the funnel awareness stage of customer decision journey',
      },
      {
        sources: ['mrr', 'mrr_insight'],
        method: 'headline',
        show_prompt: true,
        template_text_prompt:
          'Powerful attention grabbing Call to Action headline of 60 characters for the key feature in the top-of-the funnel awareness stage of customer decision journey',
      },
      {
        sources: ['mrr', 'mrr_insight'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          "5 key barriers to the middle-of-the funnel desire stage of my customer's purchase journey",
      },
      {
        sources: ['mrr', 'mrr_insight'],
        method: 'headline',
        show_prompt: true,
        template_text_prompt:
          'Powerful attention grabbing headline of 60 characters for the key feature in the middle-of-the funnel desire stage of customer decision journey',
      },
      {
        sources: ['mrr', 'mrr_insight'],
        method: 'headline',
        show_prompt: true,
        template_text_prompt:
          'Powerful attention grabbing Call to Action headline of 60 characters for the key feature in the middle of the funnel desire stage of customer decision journey',
      },
      {
        sources: ['mrr', 'mrr_insight'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          "5 key barriers to the bottom-of-the funnel convert stage of my customer's purchase journey",
      },
      {
        sources: ['mrr', 'mrr_insight'],
        method: 'headline',
        show_prompt: true,
        template_text_prompt:
          'Powerful attention grabbing headline of 60 characters for the key feature in the bottom-of-the funnel convert stage of customer decision journey',
      },
      {
        sources: ['mrr', 'mrr_insight'],
        method: 'headline',
        show_prompt: true,
        template_text_prompt:
          'Powerful attention grabbing Call-to-Action headline of 60 characters for the key feature in the bottom-of-the funnel convert stage of customer decision journey',
      },
      {
        sources: ['mrr', 'mrr_insight'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt: 'What does our product do?',
      },
      {
        sources: ['mrr', 'mrr_insight'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt: 'What is the main end benefit of our product?',
      },
      {
        sources: ['mrr', 'mrr_insight'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt: 'How does our product works?',
      },
      {
        sources: ['mrr', 'mrr_insight'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt: 'What are the key innovations?',
      },
      {
        sources: ['mrr', 'mrr_insight'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt: 'What are the unique and useful insights?',
      },
      {
        sources: ['mrr', 'mrr_insight'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt: '3 top features of our product',
      },
      {
        sources: ['mrr', 'mrr_insight'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          'Describe our product visually, how does it look like?',
      },
      {
        sources: ['mrr', 'mrr_insight'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          '5 key lifestyle moments of our ideal prospect for a creative storyboard',
      },
      {
        sources: ['mrr', 'mrr_insight'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          'Brand photographic requirements showing our product in use',
      },
      {
        sources: ['mrr', 'mrr_insight'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          'Image style requirements to resonate with our ideal prospect',
      },
      {
        sources: ['mrr', 'mrr_insight'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          'Make-up and hair/beard styling and requirements to resonate with our ideal prospect',
      },
      {
        sources: ['mrr', 'mrr_insight'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          'Outdoor activities requirements to resonate with our ideal prospect',
      },
      {
        sources: ['mrr', 'mrr_insight'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          'Indoor activities requirements to resonate with our ideal prospect',
      },
      {
        sources: ['mrr', 'mrr_insight'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          'Food and drinks style requirements to resonate with our ideal prospect',
      },
      {
        sources: ['mrr', 'mrr_insight'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          'Fashion and costume style requirements to resonate with our ideal prospect',
      },
    ],
    template_description:
      'This template builds a creative content brief that provides your team with a single source of information for all customer touchpoints and decision journey stages.',
    author_name: 'Harish Kumar',
    index: 5,
  },
  {
    template_name: '7. Buyer Persona Research',
    updated_at: '08/30/2023, 17:53:46',
    stage: 'complete',
    created_at: '11/13/2022, 11:03:36',
    template_id: '3074a09fe4fc4567a44648ce3b9c9c4c',
    username: 'support@quantamixsolutions.com',
    sections: [
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt: 'What is the name of my Ideal Prospect?',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt: '3 strong personal traits of my Ideal Prospect',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          '3 most crucial interests or hobbies of my Ideal Prospect ',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt: '3 niche demographic factors',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          "Describe my Ideal Prospect's attitude toward the main topic of interest",
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          'Identify 3 current dilemmas of my Ideal Prospect ',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          'Identify 3 current daily routines of my Ideal Prospect',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          'Identify top 5 inspirations my Ideal Prospect is seeking',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt: 'Identify 5 triggers ',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt: 'Identify 5 barriers ',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          'Identify 5 likely convert touch points to buying decisions ',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          'Identify 5 likely purchase intent touch points to buying decisions',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          'Identify 5 essential product features priorities to make a buying decisions',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          'Identify 5 inner driven attitudes of my Ideal Prospect',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          'Identify 5 outer driven attitudes of my Ideal Prospect',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          'Identify 5 Fluidity & Openness to change factors of my Ideal Prospect',
      },
      {
        sources: ['mrr'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          'Identify 5 Consistency & Routine factors of my Ideal Prospect',
      },
      {
        sources: ['mrr'],
        method: 'write_me',
        show_prompt: true,
        template_text_prompt:
          'Ideal Prospect Segment 1: Engaged and Enthusiastic: My appearance & style',
      },
      {
        sources: ['mrr'],
        method: 'write_me',
        show_prompt: true,
        template_text_prompt:
          'Ideal Prospect Segment 2: Achieving & Modern Alpha: I like to stand out',
      },
      {
        sources: ['mrr'],
        method: 'write_me',
        show_prompt: true,
        template_text_prompt:
          "Ideal Prospect Segment 3: Emerging & Insecure: I wish I didn't need to spend",
      },
      {
        sources: ['mrr'],
        method: 'write_me',
        show_prompt: true,
        template_text_prompt:
          'Ideal Prospect Segment 4: Confident & Laid back: I like to look',
      },
      {
        sources: ['mrr'],
        method: 'write_me',
        show_prompt: true,
        template_text_prompt:
          'Ideal Prospect Segment 5: Competent & Pragmatic: I am who I am',
      },
      {
        sources: ['mrr'],
        method: 'write_me',
        show_prompt: true,
        template_text_prompt:
          "Ideal Prospect Segment 6: Understanding & Play-It-Safe: I'm easy going",
      },
      {
        sources: ['mrr'],
        method: 'write_me',
        show_prompt: true,
        template_text_prompt:
          "Ideal Prospect Segment 7: Disengaged & Routine: I don't follow trends because",
      },
    ],
    template_description:
      'This template provides their interests, demographics, attitudes, dilemmas, current struggles, future inspirations, triggers, converts, and purchase touch points. ',
    author_name: 'Harish Kumar',
    index: 6,
  },
  {
    template_name: '8. Analyze Market Trends as a Growth Consultant',
    updated_at: '08/30/2023, 17:55:46',
    stage: 'complete',
    created_at: '12/25/2022, 11:15:00',
    template_id: '2463bf42989c4a92bd446204af03eb40',
    username: 'support@quantamixsolutions.com',
    sections: [
      {
        sources: ['mrr', 'serp'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          'What are the key drivers of growth in the industry?',
      },
      {
        sources: ['mrr', 'serp'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          'What are the key challenges and opportunities facing businesses in the industry?',
      },
      {
        sources: ['mrr', 'serp'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          'What are the key trends impacting the industry, and how are they expected to evolve in the future?',
      },
      {
        sources: ['mrr', 'serp'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          'Who are the key players in the industry, and what are their market positions and strategies?',
      },
      {
        sources: ['mrr', 'serp'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          'What are the key customer segments in the industry, and how are they evolving?',
      },
      {
        sources: ['mrr', 'serp'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          'What are the key technology and product trends in the industry, and how are they impacting business models and growth opportunities?',
      },
      {
        sources: ['mrr', 'serp'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          'What are the key regulatory and policy developments impacting the industry, and how are they likely to affect growth?',
      },
      {
        sources: ['mrr', 'serp'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          'How is the competitive landscape evolving in the industry, and what are the implications for businesses?',
      },
      {
        sources: ['mrr', 'serp'],
        method: 'ask_me',
        show_prompt: true,
        template_text_prompt:
          'How are businesses in the industry using data and analytics to inform their growth strategies?',
      },
    ],
    template_description:
      "This template builds a deeper understanding of the market trends and industry developments shaping your clients' growth opportunities.",
    author_name: 'Harish Kumar',
    index: 7,
  },
  {
    template_name: '9. Smart Market Diagnosis Questions by Dan S. Kennedy',
    updated_at: '08/30/2023, 17:57:59',
    stage: 'complete',
    created_at: '02/21/2023, 19:32:46',
    template_id: '2ee411c0f4ea48e0877732acd2404233',
    username: 'support@quantamixsolutions.com',
    sections: [
      {
        method: 'headline',
        sources: [],
        show_prompt: false,
        template_text_prompt:
          'Write an attention grabbing Headline about my Niche.',
      },
      {
        method: 'headline',
        sources: [],
        show_prompt: false,
        template_text_prompt:
          'Write Headline about getting rid of problem that keeps them awake at night?.',
      },
      {
        method: 'ask_me',
        sources: [],
        show_prompt: true,
        template_text_prompt:
          'Describe in detail what keeps them awake at night?',
      },
      {
        method: 'headline',
        sources: [],
        show_prompt: false,
        template_text_prompt:
          'Write shocking headline about what they are afraid of.',
      },
      {
        method: 'ask_me',
        sources: [],
        show_prompt: true,
        template_text_prompt: 'Describe in detail What are they afraid of?',
      },
      {
        method: 'headline',
        sources: [],
        show_prompt: false,
        template_text_prompt:
          'Write hook and catchy headline about what they are angry about.',
      },
      {
        method: 'write_me',
        sources: [],
        show_prompt: true,
        template_text_prompt: 'Describe in detail 5 things they angry about? ',
      },
      {
        method: 'headline',
        sources: [],
        show_prompt: false,
        template_text_prompt:
          'Write an attention grabbing hook headline about who are they angry at.',
      },
      {
        method: 'ask_me',
        sources: [],
        show_prompt: true,
        template_text_prompt: 'Describe in detail who are they angry at?',
      },
      {
        method: 'headline',
        sources: [],
        show_prompt: false,
        template_text_prompt:
          'Write action-oriented headline about their daily frustrations.',
      },
      {
        method: 'ask_me',
        sources: [],
        show_prompt: true,
        template_text_prompt:
          'Describe in detail thier top 3 daily frustrations?',
      },
      {
        method: 'headline',
        sources: [],
        show_prompt: false,
        template_text_prompt:
          'Write catchy headline about a trend that will occur in their business or lives.',
      },
      {
        method: 'ask_me',
        sources: [],
        show_prompt: true,
        template_text_prompt:
          'Describte in detail the top 3 trends that will occur in their businesses or lives?',
      },
      {
        method: 'headline',
        sources: [],
        show_prompt: false,
        template_text_prompt:
          'Write catchy headline about a What do they secretly, ardently desire most.',
      },
      {
        method: 'ask_me',
        sources: [],
        show_prompt: true,
        template_text_prompt:
          'Describe in detail what do they secretly, ardently desire most?',
      },
      {
        method: 'headline',
        sources: [],
        show_prompt: false,
        template_text_prompt:
          'Write catchy headline about biases in the way they make key decisions.',
      },
      {
        method: 'ask_me',
        sources: [],
        show_prompt: true,
        template_text_prompt:
          'Describe in detail if there biases in the way they make key decisions?',
      },
      {
        method: 'headline',
        sources: [],
        show_prompt: false,
        template_text_prompt:
          'Write catchy headline about important of brand voice and tone.',
      },
      {
        method: 'ask_me',
        sources: [],
        show_prompt: true,
        template_text_prompt:
          'Describte in detail if they do have their own brand voice and tone?',
      },
      {
        method: 'headline',
        sources: [],
        show_prompt: false,
        template_text_prompt:
          'Write catchy headline about who else is selling something similar to them, and how.',
      },
      {
        method: 'ask_me',
        sources: [],
        show_prompt: true,
        template_text_prompt:
          'Describe in detail who else is selling something similar to them, and how?',
      },
      {
        method: 'headline',
        sources: [],
        show_prompt: false,
        template_text_prompt:
          'Write catchy headline about who else has tried selling them something similar, and how has that effort failed.',
      },
      {
        method: 'ask_me',
        sources: [],
        show_prompt: true,
        template_text_prompt:
          'Describte in detail who else has tried selling them something similar, and how has that effort failed?',
      },
    ],
    template_description:
      'These templates diagnose your market with 10 Smart Market Questions designed by Dan S. Kennedy.',
    general: true,
    author_name: 'Harish Kumar',
    index: 8,
  },
];
