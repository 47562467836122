/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-unresolved */
import createInstance from 'Config/AxiosConfig';
import { store } from 'redux/store';

export const getProgressPercentageOfMRRLevels = async () => {
  const reduxState = store.getState();
  try {
    const body = {
      username: reduxState.user.username,
      name: reduxState.workspace.name,
    };
    const instance = await createInstance();
    const result = await instance.post('dashboard-mrr-progress', body);
    return result.data.data;
  } catch (ex) {
    console.log(`Cannot load ProgressPercentage ${ex}`);
    return {
      percentage: 0,
      percentage2: 0,
      percentage3: 0,
    };
  }
};
