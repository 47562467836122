import { baseAPIsUrl } from 'Config/Api';
import { useQuery } from 'react-query';
import createInstance from 'Config/AxiosConfig';
import { getReduxUserState } from 'utils/helpers/redux-selectors';

const fetchAllAssistants = async () => {
  const user = getReduxUserState();
  try {
    const instance = await createInstance();
    const { data } = await instance.get(
      `${baseAPIsUrl}/get-assistants?username=${user.username}&include_all=false&page_num=0&page_size=10000`,
    );

    if (data.statusCode === '200') return data.data;
    throw new Error('Error in fetching assistants');
  } catch (ex) {
    return { error: true, data: [] };
  }
};

// Keep Query Value as File Name to maintain consistency and uniqueness in query index
export const GET_ASSISTANTS_QUERY_KEY = 'get-all-assistants';

// Query Config Options
const queryOptions = {
  staleTime: 10 * 60 * 1000,
  // 10 mins
};

// QUERY
export const useGetAllAssistants = () =>
  useQuery(
    [GET_ASSISTANTS_QUERY_KEY],
    () => fetchAllAssistants(),
    queryOptions,
  );
