export const ARTICLES_STATUS = ['All', 'Drafts', 'In Progress', 'Completed'];

export const HeadingText = 'Are you sure ?';
export const SecondaryBtnText = 'Cancel';
export const PrimaryBtnText = 'Confirm';

export const BLOG_PATH = {
  SALES: 'Sales Copywriter',
  NON_SALES: '',
};
