import { useMutation, useQueryClient } from 'react-query';
import createInstance from 'Config/AxiosConfig';
import { toast } from 'react-toastify';
import { GET_ASSISTANTS_QUERY_KEY } from './get-assistants';

export const useMutateDeleteAssistant = () => {
  const queryClient = useQueryClient();

  const deleteAssistant = async (assistantId) => {
    const instance = await createInstance();

    const { data } = await instance.post('delete-assistant', {
      assistant_id: assistantId,
    });

    if (data?.statusCode === '200') {
      return true;
    }

    throw new Error('Assistant could not be deleted. Please try again!');
  };

  return useMutation(deleteAssistant, {
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [GET_ASSISTANTS_QUERY_KEY],
      });
      toast.success('Assistant deleted successfully', {
        position: 'bottom-center',
        theme: 'dark',
      });
    },
    onError: (error) => {
      toast.error(`${error.message}`, {
        position: 'bottom-center',
        theme: 'dark',
      });
    },
  });
};
