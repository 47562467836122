import React from 'react';
import { LoadingPlaceholder } from 'components/skeleton/LoadingPlaceholder';
import { Divider, FlexCard } from 'components/common';

export const NewsLoading = () => (
  <>
    <FlexCard flex column className="h-margin-t-x2 h-margin-b-x2">
      <FlexCard flex>
        <LoadingPlaceholder width="7%" height="15px" marginRight="6px" />
        <LoadingPlaceholder width="30%" height="15px" />
      </FlexCard>
      <LoadingPlaceholder width="80%" height="20px" marginTop="4px" />
    </FlexCard>
    <Divider />
    <FlexCard flex column className="h-margin-t-x2 h-margin-b-x2">
      <FlexCard flex>
        <LoadingPlaceholder width="7%" height="15px" marginRight="6px" />
        <LoadingPlaceholder width="30%" height="15px" />
      </FlexCard>
      <LoadingPlaceholder width="80%" height="20px" marginTop="4px" />
      <LoadingPlaceholder width="20%" height="20px" marginTop="2px" />
    </FlexCard>
    <Divider />
    <FlexCard flex column className="h-margin-t-x2 h-margin-b-x2">
      <FlexCard flex>
        <LoadingPlaceholder width="7%" height="15px" marginRight="6px" />
        <LoadingPlaceholder width="30%" height="15px" />
      </FlexCard>
      <LoadingPlaceholder width="80%" height="20px" marginTop="4px" />
    </FlexCard>
    <Divider />
    <FlexCard flex column className="h-margin-t-x2 h-margin-b-x2">
      <FlexCard flex>
        <LoadingPlaceholder width="7%" height="15px" marginRight="6px" />
        <LoadingPlaceholder width="30%" height="15px" />
      </FlexCard>
      <LoadingPlaceholder width="90%" height="20px" marginTop="4px" />
    </FlexCard>
    <Divider />
    <FlexCard flex column className="h-margin-t-x2 h-margin-b-x2">
      <FlexCard flex>
        <LoadingPlaceholder width="7%" height="15px" marginRight="6px" />
        <LoadingPlaceholder width="30%" height="15px" />
      </FlexCard>
      <LoadingPlaceholder width="80%" height="20px" marginTop="4px" />
    </FlexCard>
  </>

);
