/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
import axios from 'axios';
import { store } from 'redux/store';

const saveBasicAnswers = async (questions) => {
  try {
    questions = questions.map((question) => ({
      que: question.que,
      answer: question.answer,
    }));

    const state = store.getState(); // Redux state
    const body = {
      username: state.user.username,
      name: state.workspace.name,
      data: questions,
      source_lang: state.workspace.language,
    };
    const { data } = await axios.post(
      'https://apis.crawlq.ai/mrr-onboarding',
      body,
    );
    return data.statusCode === '200';
  } catch (ex) {
    console.log('Error in MRR-Onboarding', ex);
    return false;
  }
};

export const getSavedAnswers = async () => {
  const state = store.getState(); // Redux state
  try {
    const body = {
      username: state.user.username,
      name: state.workspace.name,
    };
    const { data } = await axios.post(
      'https://apis.crawlq.ai/check-onboarding',
      body,
    );

    if (data.statusCode === '500') {
      return { data: {}, error: true };
    }
    return { data: data.mrqna, error: false };
  } catch (ex) {
    console.log('Error in Loading answers', ex);
    return { data: {}, error: true };
  }
};

export default saveBasicAnswers;
