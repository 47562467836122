import {
  COST_OF_SECTION,
  SECTION_DEFAULT_DATA,
  SOURCE_MAP,
  TEXT_DEFAULT_DATA,
} from 'constants/manage-templates/create-templates';
import { ROUTES } from 'constants/routes';
import React, { useState } from 'react';
import { LoaderTailSpin } from 'Component/Shared/Loader';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import { useNavigate, useLocation } from 'react-router-dom';
import buildTemplate, {
  updateTemplate,
} from 'Component/Pages/TemplateBuilder/templateBuilderLogic';
import { useQueryClient } from 'react-query';
import { GET_USER_TEMPLATES_QUERY_KEY } from 'queries/manage-templates/get-users-templates';
import { ButtonWrapper } from '../CreateTemplate/styles';
import styles from './template-selector.module.css';

export default function FormButtonWrapper({
  formData,
  sectionData,
  setSectionData,
  setIsError,
  setFormData,
  isUpdating,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();

  const [isLoading, setIsLoading] = useState(false);

  const handleResetFeilds = () => {
    if (!isUpdating) {
      setFormData({
        template_name: '',
        template_description: '',
      });
      setSectionData([{ ...SECTION_DEFAULT_DATA }]);
    } else {
      navigate(ROUTES.MANAGE_TEMPLATES);
    }
  };

  const handleSection = () => {
    setSectionData([...sectionData, { ...SECTION_DEFAULT_DATA }]);
    setIsError(false);
  };

  const handleUpdate = async (body) => {
    const success = await updateTemplate(body);
    if (!success) {
      setIsError(true);
      return;
    }
    queryClient.invalidateQueries(GET_USER_TEMPLATES_QUERY_KEY);

    navigate(ROUTES.MANAGE_TEMPLATES);
  };

  const handleFormSubmit = async () => {
    if (formData.template_name.trim() === '') return;
    if (formData.template_description.trim() === '') return;
    setIsLoading(true);
    const sectionDataMod = sectionData.map((data) => ({
      ...data,
      sources: data.sources.map((source) => {
        const mappedSource = SOURCE_MAP[source];
        if (mappedSource) return mappedSource;
        return source;
      }),
    }));

    let body = {
      ...formData,
      sections: sectionDataMod,
      credits: COST_OF_SECTION * sectionDataMod.length,
    };

    if (isUpdating) {
      body = {
        template_id: location.state.template_id,
        ...body,
      };
      handleUpdate(body);
      return;
    }
    const success = await buildTemplate(body);
    if (!success) {
      setIsError(true);
      return;
    }
    queryClient.invalidateQueries(GET_USER_TEMPLATES_QUERY_KEY);

    navigate(ROUTES.MANAGE_TEMPLATES);
  };

  return (
    <ButtonWrapper>
      <button
        className={styles.button_secondary}
        type="button"
        onClick={handleResetFeilds}
      >
        Cancel
      </button>
      <button
        className={styles.button_secondary}
        type="button"
        onClick={handleSection}
      >
        <AddBoxRoundedIcon /> Add Section
      </button>
      {!isLoading ? (
        <button
          className={styles.button_primary}
          type="button"
          onClick={handleFormSubmit}
        >
          {isUpdating ? 'Update Template' : 'Create Template'}
        </button>
      ) : (
        <button className={styles.button_primary} type="button">
          <LoaderTailSpin width="20" height="20" />
        </button>
      )}
    </ButtonWrapper>
  );
}
