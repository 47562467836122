/* eslint-disable no-return-await */
/* eslint-disable consistent-return */
/* eslint-disable no-console */
import axios from 'axios';
import CreditUpdater from '../../../../Shared/CreditManager';
import {
  getTranslatedText,
  getTranslatedTextEnglish,
} from '../../../../Shared/TranshelperStatic';

const handleSummerizerForEditor = async (input) => {
  let maxTokens = 15;
  if (localStorage.getItem('sliderLength')) {
    maxTokens = localStorage.getItem('sliderLength');
  }
  const body = {
    text: await getTranslatedTextEnglish(input),
    maxTokens,
  };
  const { success } = await CreditUpdater(60);

  if (success !== 'true') {
    console.log('Credits Over');
    return;
  }

  const data = await axios.post('https://apis.crawlq.ai/ai-summarize', body);
  return await getTranslatedText(data.data.output);
};
export default handleSummerizerForEditor;
