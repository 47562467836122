export const MY_WORKSPACES = 'My Campaigns';
export const MARKETPLACE = 'Marketplace';
export const MY_PURCHASES = 'My Purchases';
export const DFY_WORKSPACES = 'DFY Workspaces';

export const WORKSPACE_TABS = [
  { id: 1, name: MY_WORKSPACES },
  // { id: 2,
  //   name: MY_PURCHASES,
  // },
  // { id: 3,
  //   name: MARKETPLACE,
  // },
  // { id: 4,
  //   name: DFY_WORKSPACES,
  // },
];
