/* eslint-disable no-console */
/* eslint-disable import/no-unresolved */
/* eslint-disable camelcase */
import createInstance from 'Config/AxiosConfig';
import { store } from 'redux/store';

const getUsersEmail = () => {
  const state = store.getState();
  const { username } = state.user;
  return username;
};

const AddDFY = async (workspace_name, user_alotted) => {
  try {
    const instance = await createInstance();

    const req_body = {
      from_account: getUsersEmail(),
      to_account: user_alotted,
      workspace: workspace_name,
    };

    const response = await instance.post('add-dfy', req_body);

    if (response.data.statusCode === '200') {
      return {
        success: true,
        message: `<b>${workspace_name}</b> is assigned to <b>${user_alotted}</b>`,
      };
    }

    return {
      success: false,
      message: 'Please try again.',
    };
  } catch (err) {
    console.log(err);
    return {
      success: false,
      message: 'Please try again.',
    };
  }
};

export default AddDFY;
