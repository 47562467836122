/* eslint-disable no-console */
import axios from 'axios';

const fetchDemoWorkspace = async () => {
  try {
    const { data } = await axios.get('https://apis.crawlq.ai/projects-all?username=support@quantamixsolutions.com');

    if (data.statusCode === '200') {
      return ({
        result: data.data.data,

      });
    }
    return ({ result: false });
  } catch (ex) {
    console.log('Error in Display Table', ex);
    return ({ result: false });
  }
};

export default fetchDemoWorkspace;
