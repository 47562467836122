/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable consistent-return */
/* eslint-disable no-unreachable */
/* eslint-disable no-param-reassign */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/destructuring-assignment */

import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import { addNewTab } from 'redux/index';
// import { useSelector, useDispatch } from 'react-redux';
// import { getStatusOfStage, STATUS } from './helpers';
import {
  DividerBold,
  GreenTick,
  LoadingIcon,
  ProgressBox,
  RedCross,
} from '../styles';

const GetCurrentSatatusIcon = ({ data, level, openMRR, fromstatusPage }) => {
  for (const key of data) {
    if (key.stage === level.toLowerCase()) {
      if (key.current_status === 'complete') {
        if (
          fromstatusPage !== 'true' &&
          key.stage === 'triage' &&
          window.location.pathname === '/build-workspace-status-detailed'
        ) {
          openMRR();
          window.location.assign('/chat-athena?new_workspace=true');
        }
        return (
          <>
            <DividerBold isCompleted />
            <ProgressBox>
              <GreenTick />
              <b>{level}</b>
            </ProgressBox>
          </>
        );
      }
      if (key.current_status === 'in_progress') {
        return (
          <>
            <DividerBold />
            <ProgressBox>
              <LoadingIcon />
              <b>{level}</b>
            </ProgressBox>
          </>
        );
      }
      if (key.current_status === 'failed') {
        return (
          <>
            <DividerBold />
            <ProgressBox>
              <RedCross />
              <b>{level}</b>
            </ProgressBox>
          </>
        );
      }
    }
  }
};

export default GetCurrentSatatusIcon;
