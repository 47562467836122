export const onBoardingSteps = {
  Seed: 'Seed',
  Attract: 'Attract',
  Listen: 'Listen',
  Desire: 'Desire',
  Learn: 'Learn',
  Convert: 'Convert',
  Triage: 'Triage',
};

export default onBoardingSteps;
