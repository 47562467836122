import { LoadingPlaceholder } from 'components/skeleton/LoadingPlaceholder';
import { ScaffoldFlexCard } from 'components/common';
import { QuestionIcon } from 'components/content/content-tools/styles';
import React from 'react';

export const QuestionLoading = () => (

  <>
    {Array(7).fill(null).map((index) => (
      <ScaffoldFlexCard key={index} flex className="h-margin-t-xsmall">
        <QuestionIcon className="h-margin-r-tiny" />
        <LoadingPlaceholder height="15px" width="100%" />
      </ScaffoldFlexCard>

    ))}
  </>

);
