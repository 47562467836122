import React from 'react';
import { Divider } from 'components/common/divider';
import { formatForCSV } from 'utils/export/export-csv';
import { exportAsPDF, loadPdfJS } from 'utils/export/export-pdf';
import styles from './download-research.module.css';
import { DownloadResearchIcon, CrossIcon } from '../styles';

const DownloadResearch = ({
  toggleClose,
  selectedCategory,
  questionAnswers,
}) => {
  const handleExcelClick = () => {
    formatForCSV(selectedCategory, questionAnswers);
    toggleClose();
  };

  const handlePDFClick = async () => {
    await loadPdfJS();
    exportAsPDF(selectedCategory, questionAnswers);
    toggleClose();
  };

  return (
    <div className={styles.download_research_wrapper}>
      <div className={styles.header_wrapper}>
        <div className={styles.header}>
          <DownloadResearchIcon />
          <div className={styles.header_text}>Download Research Data</div>
        </div>
        <CrossIcon onClick={toggleClose} />
      </div>

      <section className={styles.section_wrapper}>
        <div className={styles.section_header}>Selected Category</div>
        <span className={styles.section_header_value}>{selectedCategory}</span>
      </section>

      <Divider className={styles.divider} />
      <section className={styles.action_button_wrapper}>
        <div className={styles.button_container_csv}>
          <button
            type="button"
            className={styles.action_button}
            onClick={handleExcelClick}
          >
            CSV
          </button>
        </div>
        <div className={styles.button_container_pdf}>
          <button
            type="button"
            className={styles.action_button}
            onClick={handlePDFClick}
          >
            PDF
          </button>
        </div>
      </section>
    </div>
  );
};

export default DownloadResearch;
