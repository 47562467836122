import React, { useState } from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import ViewSidebarRoundedIcon from '@mui/icons-material/ViewSidebarRounded';
import WorkspaceList from './WorkspaceList';

export default function WorkspaceListDrawer(props) {
  const [state, setState] = useState(false);
  const { setStarterFlow } = props;

  const toggleDrawer = (open) => {
    setState(open);
  };

  const list = () => (
    <WorkspaceList fromDrawer setStarterFlow={setStarterFlow} />
  );

  return (
    <div>
      <ViewSidebarRoundedIcon onClick={() => toggleDrawer(true)} />
      <SwipeableDrawer
        open={state}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
      >
        {list()}
      </SwipeableDrawer>
    </div>
  );
}
