import { ROUTES } from 'constants/routes';
import React from 'react';
import { Link } from 'react-router-dom';

export function CreditOverToast() {
  return (
    <div>
      Credits are over. <Link to={ROUTES.DASHBOARD}> Buy Few </Link>
    </div>
  );
}
