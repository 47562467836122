import createInstance from 'Config/AxiosConfig';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { getLocationDetails } from 'utils/location';

const US_DOLLAR = 'USD';

export const useMutateAddons = () => {
  async function addOnCheckout(plans) {
    const instance = await createInstance();

    const location = await getLocationDetails();

    const { data } = await instance.post('/addon-checkout', {
      plans,
      currency: location?.currency || US_DOLLAR,
    });

    if (data?.url) {
      window.open(data.url, '_blank');
      return;
    }

    throw new Error('Error in Updating addon');
  }

  return useMutation(addOnCheckout, {
    onError: (error) => {
      toast.error(`${error.message}`, {
        position: 'bottom-center',
        theme: 'dark',
      });
    },
  });
};
