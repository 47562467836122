import styled from 'styled-components';

export const LandingPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;
export const LandingPageContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0.2rem 1.5rem;
`;
export const LandingPageHeading = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  h2 {
    font-size: 1rem !important;
  }
  span {
    color: #2a9df5;
    font-size: 2.5rem;
    font-weight: 600;
  }
`;

export const LandingPageOptions = styled.div`
  flex: 5;
  display: flex;
  flex-direction: column;
  width: 85vw;
  align-self: center;
`;

export const LandingPageCards = styled.div`
  flex: 4;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
`;

export const LandingPageDescription = styled.b`
  flex: 1;
  display: flex;
  align-items: center;
  margin-bottom: 0.4rem;
  justify-content: center;
  font-size: 1rem;
  span {
    color: #2a9df5;
  }
`;

export const CardWrapper = styled.div`
  height: 375px;
  width: 275px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
`;
export const CardIcon = styled.img`
  flex: 1;
  background: #fff;
  overflow-y: hidden;
`;

export const CardDetails = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 0.5rem;
`;

export const OptionHeading = styled.div`
  flex: 1;
  font-size: 20px;
  display: flex;
  align-items: center;
  font-weight: 600;
`;
export const OptionDescription = styled.div`
  flex: 2;
  font-size: 15px;
  display: flex;
  align-items: center;
  text-align: justify;
`;

export const OptionAction = styled.div`
  flex: 1;
  font-size: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;
export const OptionActionButton = styled.span`
  font-size: 20px;
  color: #2a9df5;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 600;
`;

export const DividerLine = styled.hr`
  border: 1px dashed #d9d9d9;
  width: 50%;
  margin-bottom: 2rem;
  margin-top: 0;
`;

export const TemplateSearchNoResult = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding-top: 1.5rem;
  code {
    color: darkgray !important;
  }
`;
export const HelperVideoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
  cursor: pointer;
`;
