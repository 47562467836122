import React, { useState } from 'react';
import AceEditor from 'react-ace';
import Spreadsheet from 'react-spreadsheet';
import { ArrowBackward, LoaderTailSpin } from 'components/common';
import { useInsightsContext } from 'context/assistants/InsightsContext';
import { generateQuery } from 'queries/insights-360/mutate-generate-sql';
import { useGetBigQueryData } from 'queries/insights-360/get-big-query-data';
import {
  formatBigQueryJsonForCSV,
  getFormmattedQueryResponse,
  getHeaders,
} from 'utils/export/export-bigquery-to-csv';
import { useGetGeneratedSQL } from 'queries/insights-360/get-sql';
import { toast } from 'react-toastify';
import 'ace-builds/src-noconflict/mode-mysql';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/ext-language_tools';
import { formatSQL } from 'utils/helpers';
import CustomTooltip from 'Component/Shared/CustomTooltip';
import styles from './custom-query.module.css';

const CustomQuery = () => {
  const { useSelectedConnectorInfo } = useInsightsContext();
  const {
    SelectedConnectorInfo,
    setSelectedConnectorInfo,
  } = useSelectedConnectorInfo;

  const [UserQuery, setUserQuery] = useState('');
  const [RandomId, setRandomId] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [GeneratedQueryResponse, setGeneratedQueryResponse] = useState({
    response: '',
    error: false,
  });

  const {
    refetch: fetchGeneratedQuery,
    isSuccess: isSQLGeneratedSuccess,
  } = useGetGeneratedSQL(SelectedConnectorInfo?.dataSetId, RandomId);

  const {
    data: bigQueryResponse,
    refetch,
    isLoading: isLoadingBigQuery,
    isSuccess: isBigQuerySuccess,
  } = useGetBigQueryData(formatSQL(GeneratedQueryResponse?.query));

  const handleSubmitQuery = async () => {
    if (UserQuery === '') {
      toast.error('Please add your query', {
        position: 'bottom-center',
        theme: 'dark',
      });
      return;
    }
    const id = (Math.random() + 1).toString(36).substring(2);
    setRandomId(id);

    setIsProcessing(true);
    const result = await generateQuery({
      query: UserQuery,
      dataSetId: SelectedConnectorInfo?.dataSetId,
      id,
    });

    if (result) {
      await fetchGeneratedQuery().then((response) => {
        const generatedQuery = response?.data;
        if (
          generatedQuery?.sql_query !== '' ||
          generatedQuery?.sql_query !== 'None'
        ) {
          setGeneratedQueryResponse({
            ...GeneratedQueryResponse,
            query: generatedQuery?.sql_query,
            answer: generatedQuery?.answer,
            step: generatedQuery?.intermediate_steps,
            error: false,
            errorMessage: '',
          });

          setIsProcessing(false);
          return;
        }
        setGeneratedQueryResponse({
          ...GeneratedQueryResponse,
          response: '',
          error: true,
          errorMessage: 'Oops ! Recheck your query to get appropriate results.',
        });
        setIsProcessing(false);
      });
    }
  };

  return (
    <div className={styles.container}>

      <div className={styles.left_container}>

        <div
          className={styles.go_back_container}
          onClick={() => setSelectedConnectorInfo('')}
        >
          <ArrowBackward /> Go Back
        </div>

        <div className={styles.selected_connector_container}>

          <div className={styles.connector_name}>
            <CustomTooltip
              title="Connector Name"
              arrow
              placement="top"
            >

              {SelectedConnectorInfo?.connectorName}
            </CustomTooltip>
          </div>
          <div className={styles.dataset_id}>
            <CustomTooltip
              title="Dataset Id"
              arrow
              placement="top"
            >
              {SelectedConnectorInfo?.dataSetId}
            </CustomTooltip>

          </div>
        </div>
        <div className={styles.input_container}>
          <label className={styles.input_label}>Query</label>
          <textarea
            name="name"
            className={styles.input_box}
            value={UserQuery}
            placeholder="Your Query"
            onChange={(event) => {
              setGeneratedQueryResponse('');
              setUserQuery(event.target.value);
            }}
          />

          {!isProcessing ? (
            <button
              className={styles.question_submit_button}
              type="button"
              onClick={handleSubmitQuery}
            >
              Generate SQL
            </button>
          ) : (
            <button className={styles.question_loading_button} type="button">
              <LoaderTailSpin width="24px" color="var(--active-color)" />
            </button>
          )}

          {
            GeneratedQueryResponse?.step && (
              <div className={styles.inter_step_container}>
                <span className={styles.input_label}>Intermediate Results</span>
                <div className={styles.step_container}>
                  {GeneratedQueryResponse?.step.map((inter_step) => (
                    <div className={styles.inter_step_wrapper}>
                      <div>
                        Log:{' '}
                        <span className={styles.step_log}>{inter_step?.log}</span>
                      </div>
                      <div>
                        Output:{' '}
                        <span className={styles.step_log}>
                          {inter_step?.output}
                        </span>
                      </div>
                      <div>
                        Tool:{' '}
                        <span className={styles.step_log}>{inter_step?.tool}</span>
                      </div>
                      <div>
                        tool_input:
                        <span className={styles.step_log}>
                          {inter_step?.tool_input}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )
          }

          {bigQueryResponse?.length <= 0 && (
            <span className={styles.error_message}>
              Oops! Recheck SQL. The query failed to matching info!
            </span>
          )}

          {GeneratedQueryResponse?.error && (
            <span className={styles.error_message}>
              {GeneratedQueryResponse?.errorMessage}
            </span>
          )}
        </div>

      </div>

      <div className={styles.right_container}>

        {/* {GeneratedQueryResponse?.query && ( */}
        <div className={styles.query_container}>
          <span className={styles.input_label}>Execute SQL</span>
          <span className={styles.query_response_note}>
            Note: SQL Query is editable
          </span>

          <AceEditor
            className={styles.insights360ai_editor}
            mode="mysql"
            theme="monokai"
            highlightActiveLine
            onChange={(value) => {
              setGeneratedQueryResponse({
                ...GeneratedQueryResponse,
                query: value,
              });
            }}
            value={GeneratedQueryResponse?.query}
            name="insights-360ai-editor"
            editorProps={{ $blockScrolling: true }}
          />

          <button
            className={styles.fetch_file_button}
            type="button"
            onClick={() => refetch(GeneratedQueryResponse?.query)}
          >
            {isLoadingBigQuery ? (
              <LoaderTailSpin width="24px" color="var(--active-color)" />
            ) : (
              'Fetch Data'
            )}
          </button>
        </div>

        {
          bigQueryResponse && (
            <div className={styles.query_result_container}>
              <label className={styles.input_label}>Your Data </label>
              <div className={styles.query_result_viewer}>
                <Spreadsheet
                  data={getFormmattedQueryResponse(bigQueryResponse)}
                  columnLabels={getHeaders(bigQueryResponse)}
                />
              </div>
            </div>
          )
        }

        {
          isBigQuerySuccess && bigQueryResponse?.length > 0 && (
            <button
              className={styles.download_data_button}
              type="button"
              onClick={() =>
                formatBigQueryJsonForCSV(UserQuery, bigQueryResponse)
              }
            >
              Download File
            </button>
          )
        }

      </div>
    </div>
  );
};

export default CustomQuery;
