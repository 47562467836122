/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import axios from 'axios';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';
import { CreditManage } from 'Component/Shared/CreditManager';
import LoaderSpin from '../Loader';
import './QueAns.css';
import CreditUpdater from '../CreditManager';
import ReactTooltip from 'react-tooltip';
import { getTranslatedText } from '../TranshelperStatic';
import { addInEditor, selectTextAndCopyWithElement } from '../copyToClipBoard';
import { getTranslatedTextArrayEnglish } from '../../Pages/MarketReasearch/MarketResearchAndResonance/MarketResearchCategory/fILLAiLangTrans';
import { GENERATE_ANSWER_COST } from '../featuresCreditsCost';

const WhiteonBlackTooltip = withStyles({
  tooltip: {
    color: 'white',
    backgroundColor: 'rgb(47 47 47 / 91%)',
    fontSize: '12px',
    fontWeight: 'normal',
    letterSpacing: '1px',
    padding: '10px 15px',
    textAlign: 'justify',
  },
})(Tooltip);

// this function is to resize text-area
function autosize() {
  const el = this;
  setTimeout(() => {
    // el.style.cssText = 'height:auto; padding:0'
    // for box-sizing other than "content-box" use:
    el.style.cssText = '-moz-box-sizing:content-box';
    el.style.cssText = `height:${el.scrollHeight}px`;
  }, 0);
}

function QueAns({ question }) {
  const [answer, setAnswer] = useState('');
  const [Loading, setLoading] = useState(false);
  const [Question, setQuestion] = useState();
  const context = localStorage.getItem('context');
  const [IsCreditFail, setIsCreditFail] = useState(false);
  const [questionChanged, setQuestionChanged] = useState(false);

  const getQueAnswers = async () => {
    setLoading(true);
    console.log('yai hit hora');
    const { success } = await CreditManage(GENERATE_ANSWER_COST, true);
    if (success) {
      const Controlledquestion = questionChanged ? Question : question;
      const translatedTexts = await getTranslatedTextArrayEnglish([
        Controlledquestion.replaceAll('\n', ''),
        context,
      ]);
      const body = {
        question: translatedTexts[0],
        context: translatedTexts[1],
        username: localStorage.getItem('username'),
        name: localStorage.getItem('name'),
      };

      try {
        const { data } = await axios.post(
          'https://apis.crawlq.ai/question-answer',
          body,
        );

        setAnswer(data.answer);
        const { success } = await CreditManage(GENERATE_ANSWER_COST);
      } catch (ex) {
        console.log('Error in Que', ex);
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(false);
      setIsCreditFail(true);
    }
  };

  const getWordCountAndIncreaseCredit = () => {
    // let answer = data.answer.match(/\S+/g);

    // console.log(answer.length);
    // let wordCount = Math.floor((answer.length)*0.9)
    const { success } = CreditUpdater(40);
  };

  const handleChange = (e) => {
    setQuestionChanged(true);
    console.log(e.target.value);
    setQuestion((Question) => e.target.value);
    e.target.addEventListener('keydown', autosize);
  };

  return (
    <div className="w-100">
      <div className="question-answer-container">
        <span
          className="mr-1 pt-1 notranslate"
          style={{ fontWeight: '700', color: ' #2B5C98' }}
        >
          Q:
        </span>
        <textarea
          onChange={handleChange}
          className="QueAns-input"
          value={questionChanged ? Question : question}
          row="1"
        />

        <ReactTooltip />
        {IsCreditFail ? (
          <span
            style={{
              fontSize: '0.9rem',
              backgroundColor: '#f346465e',
              color: '#a50a0a',
              borderRadius: '0.5rem',
              display: 'flex',
              textAlign: 'center',
              alignItems: 'center',
            }}
          >
            Credits Over
          </span>
        ) : (
          <span
            data-tip="Generate Answers"
            onClick={getQueAnswers}
            style={{ cursor: 'pointer' }}
            className="ml-3"
          >
            <img
              style={{
                height: '20px',
                width: '20px',
                objectFit: 'contain',
              }}
              src="../img/answer-pen.png"
            />
          </span>
        )}
        {/* )} */}
        <FileCopyOutlinedIcon
          style={{ cursor: 'pointer', height: '18px' }}
          onClick={(e) => {
            const ques = questionChanged ? Question : question;
            const newContent = `<b>${ques}</b>
              <br />
              ${answer}
            `;
            addInEditor(newContent);
          }}
          className="ml-1"
        />
      </div>
      <br />

      {answer !== '' && Loading == false ? (
        <div className="d-flex justify-content-start">
          <div className="d-flex">
            <span className="mr-1 no-select"> A: </span>
            <span
              className="text-justify"
              style={{ color: '#2e2e2e', fontWeight: '400' }}
            >
              {answer}
            </span>
          </div>
          <div />
        </div>
      ) : Loading === true ? (
        <LoaderSpin width="30" height="30" />
      ) : null}

      <br />
    </div>
  );
}

export default QueAns;
