import React, { useEffect, useState } from 'react';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import createConnector, { useCreateConnectorMutation } from 'queries/chat-athena/fivetran/mutate-fivetran-apps';
import {
  LoaderTailSpin,
  PrimaryButton,
  InputTextFeild,
} from 'components/common';
import { useGetFiveTranAppConnectorParams } from 'queries/chat-athena/fivetran/get-fivetran-app-connector';
import { FivetranTab } from 'libs/chat-athena-constants';
import styles from './index.module.css';

function changeConfigParamsToObject(configParams) {
  if (!Array.isArray(configParams) || configParams.length < 1) return [];

  const newObject = configParams.map((params) => ({
    name: params.name,
    value: '',
    label: params.title,
    description: params.description,
  }));
  return newObject;
}

export const CreateFivetranConnector = ({ app, resetSelectedApp, setTab }) => {
  const [label, setLabel] = useState('');
  const [connectersParams, setConnectersParams] = useState([]);
  const [isCreatingConnector, setIsCreatingConnector] = useState(false);

  // const {
  //   mutate: createConnector,
  //   isLoading: isCreatingConnector,
  //   isSuccess: hadConnectedSuccessfully,
  // } = useCreateConnectorMutation();

  const {
    data: connecterConfig,
    isLoading: isLoadingConnectorConfig,
  } = useGetFiveTranAppConnectorParams(app.id);

  const handleBackToSelectionPage = () => {
    resetSelectedApp();
  };

  const handleCreateConnection = async () => {
    // if (isCreatingConnector) return;
    setIsCreatingConnector(true);
    const config = connectersParams.reduce((acc, param) => {
      acc[param.name] = param.value;
      return acc;
    }, {});

    const response = await createConnector({ id: app.id, label, config });
    if (response && response.uri) {
      window.open(response.uri, '__blank');
    }
    setIsCreatingConnector(false);
  };

  const handleInputChange = (name, value) => {
    setConnectersParams((prevParams) =>
      prevParams.map((param) =>
        param.name === name ? { ...param, value } : param,
      ),
    );
  };

  useEffect(() => {
    if (isLoadingConnectorConfig) return;
    const modifiedParams = changeConfigParamsToObject(connecterConfig);
    setConnectersParams(modifiedParams);
  }, [connecterConfig]);

  // useEffect(() => {
  //   if (hadConnectedSuccessfully) {
  //     setTab(FivetranTab['Connected Apps']);
  //   }
  // }, [hadConnectedSuccessfully]);

  return (
    <div className={styles.form_container}>
      <div className={styles.back_button} onClick={handleBackToSelectionPage}>
        <ArrowBackIosNewRoundedIcon />
        <span>Select App</span>
      </div>
      <div className={styles.form_component}>
        <h4>
          <b>{app.name}</b>
        </h4>
        {isLoadingConnectorConfig ? (
          <LoaderTailSpin />
        ) : (
          <div className={styles.form_group}>
            <InputTextFeild
              label={'Display Name'}
              value={label}
              onChange={(e) => setLabel(e.target.value)}
            />
            {connectersParams.map((param) => (
              <InputTextFeild
                key={param.label}
                label={param.label}
                value={param.value}
                description={param.description}
                onChange={(e) => handleInputChange(param.name, e.target.value)}
              />
            ))}
            <PrimaryButton
              disabled={isCreatingConnector}
              onClick={handleCreateConnection}
            >
              {isCreatingConnector ? (
                <LoaderTailSpin height={'25px'} />
              ) : (
                <span> Save</span>
              )}
            </PrimaryButton>
          </div>
        )}
      </div>
    </div>
  );
};
