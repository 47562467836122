/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import CreditUpdater from '../../../Shared/CreditManager';
import './SearchIntent.css';
import CustomTooltip from 'Component/Shared/CustomTooltip';
import { Button } from 'Component/Shared/ButtonNext';
import copyClipboard from 'Component/Shared/copyToClipBoard';
import { useSelector } from 'react-redux';
import getSearch, { getTabs } from './SearchIntentLogic';
import LoaderSpin, { LoaderTailSpin } from '../../../Shared/Loader';

function SearchIntentDiscovery() {
  const navigate = useNavigate();

  const workspace_name = useSelector((state) => state.workspace.name);
  const MarketSearchIntentCreditCost = 0;
  const [searchQuery, setSearchQuery] = useState('');
  const [error, setError] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [results, setResults] = useState();
  const [activeTab, setActiveTab] = useState();
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [isCreditFail, setIsCreditFail] = useState(false);
  const tabs = {
    Question: 0,
    Comparision: 1,
    Preposition: 2,
    Information: 3,
    Transaction: 4,
    Commercial: 5,
  };
  const handleChange = (e) => {
    setError(false);
    setSearchQuery(e.target.value);
  };

  // handle search on press Enter Button
  function handleEnterSearch(e) {
    if (e.key === 'Enter') {
      handleSearch();
    }
  }

  // Goolge Search
  const handleGoogleSearch = (keyword) => {
    window.open(`http://google.com/search?q=${keyword}`);
  };

  const handleSearch = async () => {
    setIsSearching(true);

    const res = await getSearch(searchQuery);
    if (res && res.error) {
      setError(true);
      setIsSearching(false);
      return;
    }
    setResults(res);
    setActiveTab(Object.keys(tabs)[0]);
    setIsSearching(false);
  };

  const TabsPanel = () => Object.keys(tabs).map((tab, index) => (
    <div
      className={
              `font-accent font-normal${
                tab === activeTab ? ' spy-display-tab-active ' : ''}`
            }
      key={index}
      onClick={() => setActiveTab(tab)}
    >
      {tab}
    </div>
  ));

  const handleCopyAllKeyWords = (e) => {
    copyClipboard(results.keywords.join(',\n'));
    e.target.innerText = 'Copied';
    setTimeout(() => {
      e.target.innerText = 'Copy';
    }, 3000);
  };

  return (
    <div className="spy-container">
      <div className="spy-nav-container">
        <span className="font-primary txt-primary spy-sub-head-active-name">
          <div>Start Here</div>
          {!!workspace_name && (
          <a
            href="/AI-Insights"
            target="_blank"
            className="overlay-ai-insights-link"
          >
            <img width={25} src="/img/notes-icon.svg" />
            <CustomTooltip
              title="Organise Your Notes Here!"
              arrow
              placement="top"
            >
              <div className="mx-2"> AI Insights</div>
            </CustomTooltip>
          </a>
          )}
        </span>
      </div>

      <div className="spy-results-container">
        <div className="search-results">
          <div className="spy-search-box ">
            <span className="font-accent">What is your Seed Topic?</span>
            <div className="spy-input-box-wrapper">
              <input
                className={
                    `spy-input-search font-accent${
                      error ? ' spy-error-input' : ''}`
                  }
                onChange={(e) => handleChange(e)}
                onKeyPress={(e) => handleEnterSearch(e)}
                value={searchQuery}
                placeholder="Brand Authority"
              />

              {isCreditFail ? (
                <span className="credit-over">Credits Over</span>
              ) : (
                <button className="spy-button" onClick={handleSearch}>
                  {isSearching ? (
                    <LoaderSpin height="20px" width="20px" />
                  ) : (
                    <div>
                      <span className="font-normal">Search</span>
                    </div>
                  )}
                </button>
              )}
            </div>
          </div>
          {isSearching && (
          <div className="text-center">
            <LoaderTailSpin />
          </div>
          )}
          <div className="spy-panel-wrapper">
            {!!results && (
            <div className="spy-result-panel-left">
              <div className="spy-display-tab-panel">{TabsPanel()}</div>
            </div>
            )}
            {!!results && (
            <div
              className="spy-result-panel-right"
              style={{
                overflowX: 'hidden',
              }}
            >
              <div
                className={
                      `search-display-tab-panel mt-0 ${
                        showFullScreen ? 'search-fullScreen' : ''}`
                    }
              >
                {results && (
                <div>
                  {getTabs(results, searchQuery, tabs[activeTab])}
                </div>
                )}
                {tabs[activeTab] < 3 && ( // to dispaly fullscreen button only when it is graph
                <div>
                  <button
                    className="search-fullScreen-button"
                    onClick={() => setShowFullScreen((prev) => !prev)}
                  >
                    {!showFullScreen ? (
                      <FullscreenIcon />
                    ) : (
                      <FullscreenExitIcon />
                    )}
                  </button>
                </div>
                )}
              </div>
            </div>
            )}
          </div>
          {!!results && (
          <div className="spy-related-topic-panel">
            <div className="spy-related-topic-panel-head font-secondary">
              Related Trending Topics
            </div>
            <div className="d-flex justify-content-end mt-1">
              <Button name="Copy" onClick={handleCopyAllKeyWords} />
            </div>
            <div className="spy-related-words-container">
              {results.keywords.map((keyword) => (
                <span className="spy-related-words font-accent">
                  <div>
                    {keyword}
                    <CustomTooltip
                      title={`Seach ${keyword} on Goolgle`}
                      arrow
                    >
                      <img
                        className="tool-tip mx-2"
                        width={15}
                        src="/img/icons8-google.svg"
                        onClick={() => handleGoogleSearch(keyword)}
                      />
                    </CustomTooltip>
                  </div>
                </span>
              ))}
            </div>
          </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SearchIntentDiscovery;
