/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FlyoutModal from 'Component/Shared/FlyoutModal/index.js';

// Styled && Components
import PersonIcon from '@mui/icons-material/Person';
import DeleteIcon from '@mui/icons-material/Delete';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {
  ArticleCardWrapper,
  ImageSection,
  BodySection,
  AuthorDetails,
  BlogReadingTime,
  SubTitle,
} from './styles.jsx';
import { TruncateString } from './helpers';
import { LoaderTailSpin } from 'Component/Shared/Loader.js';
import DualActionView from 'components/common/DualActionView/index';

// Logic Functions && Helpers
import { openBlog } from './helpers';
import {
  BLOG_PATH,
  HeadingText,
  SecondaryBtnText,
  PrimaryBtnText,
} from './constant.js';

function DeleteBlogModal({
  IsDeleteModalOpen,
  handleCloseDeleteModal,
  handleDelete,
  blogName,
}) {
  return (
    <FlyoutModal
      isOpen={IsDeleteModalOpen}
      requestClose={handleCloseDeleteModal}
    >
      <DualActionView
        headingText={HeadingText}
        secondaryBtnText={SecondaryBtnText}
        primaryBtnText={PrimaryBtnText}
        primaryAction={handleDelete}
        secondaryAction={handleCloseDeleteModal}
      >
        <p>Following blog will be deleted</p>
        <ul>
          <li>{blogName}</li>
        </ul>
      </DualActionView>
    </FlyoutModal>
  );
}

function ArticleCard({
  blogName,
  blogImage,
  blogAuthor,
  blogTime,
  blogScript,
  handleDelete,
  from,
  id,
}) {
  const navigate = useNavigate();
  const [BlogOpening, setBlogOpening] = useState(false);
  const [IsDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const getPathName = async () => {
    if (from === BLOG_PATH.SALES) {
      return '/sales-ai-content-writer/editor';
    }
    return '/ai-content-writer/editor';
  };

  // const handleOnClickBlog = async (docName) => {
  //   setBlogOpening(true);
  //   const { data, error } = await openBlog(docName, from);

  //   if (data) {
  //     setBlogOpening(false);
  //     localStorage.setItem('questionTopic', data.goolgeQuery);
  //     localStorage.setItem('initialHeading', data.query);
  //     localStorage.setItem('context', data.goolgeQuery);
  //     localStorage.setItem('save-doc-name-sales', data.query);
  //    navigate(await getPathName(), {
  //       state: {
  //         GoogleQuery: data.goolgeQuery,
  //         query: data.query,
  //         generatedOutputs: data.output,
  //         flag: 'ai-writer',
  //         lang: data.lang,
  //         save: data.save,
  //         blogId: data.blogId,
  //       },
  //     });
  //   } else {
  //     setBlogOpening(false);
  //   }
  // };

  // const handleOnClickBlog = async (docName) => {
  //   setBlogOpening(true);
  //   navigate('/chat-athena', {
  //     state: { blog_name: docName },
  //   });
  // };

  const handleOnClickBlog = async () => {
    window.open(`https://app.crawlq.ai/Blog?id=${id}`, '_blank');
  };

  const handleCloseDeleteModal = () => setIsDeleteModalOpen(false);

  const handleBlogDelete = () => {
    handleDelete(blogName, from);
    setIsDeleteModalOpen(false);
  };

  const title = (blogName && blogName.length) ? blogName : blogScript;
  // const subTitle = (blogScript && blogScript.length) ? blogName : '';

  return (
    <ArticleCardWrapper>
      <DeleteBlogModal
        blogName={blogName}
        IsDeleteModalOpen={IsDeleteModalOpen}
        handleDelete={handleBlogDelete}
        from={from}
        handleCloseDeleteModal={handleCloseDeleteModal}
      />

      <ImageSection src={blogImage} id={id} />

      <BodySection>
        <div
          data-title={title}
          className="global-f-bold"
          style={{ flex: 0.2, cursor: 'pointer' }}
          onClick={handleOnClickBlog}
        >
          {TruncateString(title)}
        </div>
        {/* {subTitle && <SubTitle style={{ flex: 0.2 }}>{subTitle}</SubTitle>} */}
        <div
          style={{
            display: 'flex',
            flex: 0.8,
            justifyContent: 'space-between',
            alignItems: 'end',
          }}
        >
          {blogAuthor && (
            <AuthorDetails>
              <PersonIcon style={{ color: '#2a9df5', width: '22px' }} />
              <span>
                {blogAuthor}
              </span>
            </AuthorDetails>
          )}
          <BlogReadingTime>
            {BlogOpening ? (
              <LoaderTailSpin width="22px" height="22px" />
            ) : (
              blogTime && (
                <AccessTimeIcon style={{ color: '#2a9df5', width: '22px' }} />
              )
            )}
            <span className="global-fc-primary global-fs-secondary h-margin-l-tiny h-margin-t-tiny">
              {blogTime}
            </span>
          </BlogReadingTime>
          <DeleteIcon
            style={{
              color: '#ff8d8d',
              width: '20px',
              marginLeft: '2px',
              cursor: 'pointer',
            }}
            onClick={() => setIsDeleteModalOpen(true)}
          />
        </div>
      </BodySection>
    </ArticleCardWrapper>
  );
}

export default ArticleCard;
