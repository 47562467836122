import { baseAPIsUrl } from 'Config/Api';
import axios from 'axios';
import { useIsUserAnonymus } from 'hooks/useIsUserAnonymus';
import {
  CHATATHENA_QUERY_CREDIT_COST,
  ChatAthenaModelNameEnum,
} from 'libs/chat-athena-constants';
import { useMutateCredits } from 'queries/mutate-credits';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { CHAT_HISTORY_QUERY_KEY } from './get-chat-history';
import { useMutateAthenaTraining } from './mutate-athena-training';
import { useMutateBlog } from './mutate-publish-blog';

export function useRetryAddChatHistory() {
  const { isUserAnonymus } = useIsUserAnonymus();
  const queryClient = useQueryClient();
  const { mutateAsync: deductCredits } = useMutateCredits();
  const { mutateAsync: publishAsBlog } = useMutateBlog();
  const { mutate: trainAthena } = useMutateAthenaTraining();

  const { username } = useSelector((state) => state?.user);
  const workspace = useSelector((state) => state?.workspace);
  const workspaceName = workspace?.name;

  async function reSendMessage() {
    try {
      const response = await axios.get(
        `${baseAPIsUrl}/get-recent-answer/?username=${username}&name=${workspaceName}`,
      );

      if (response.data.statusCode !== '200') {
        throw new Error('Something went wrong! Cannot Generate Response');
      }

      const resultData = response?.data?.response?.data;
      if (Object.keys(resultData).length === 0) {
        throw new Error('Something went wrong! Cannot Generate Response');
      }
      const answer = {
        question: resultData.question,
        answer: resultData?.answer || '',
        images: resultData?.images,
        created_at: resultData?.created_at || '',
        rating_scale: resultData?.rating_scale || '',
        input_media: resultData?.input_media,
        speech_file_url: resultData?.speech_file_url,
        avatar_props: resultData?.avatar_props,
        id: resultData?.id,
        index: resultData?.index,
        source: resultData?.source,
        model_name: resultData?.model_name,
      };

      return answer;
    } catch (err) {
      throw new Error(`Cannot generate response ${err}`);
    }
  }

  function handleSuccess(answer) {
    const currentMessage = queryClient.getQueryData([
      CHAT_HISTORY_QUERY_KEY,
      workspaceName,
    ]);
    const newMessage = {
      ...currentMessage,
      pairs: [...currentMessage.pairs, answer],
    };
    queryClient.setQueryData(
      [CHAT_HISTORY_QUERY_KEY, workspaceName],
      newMessage,
    );
    if (
      !isUserAnonymus &&
      (answer.model_name === ChatAthenaModelNameEnum.gpt4 ||
        answer.model_name === ChatAthenaModelNameEnum.claude)
    ) {
      deductCredits(CHATATHENA_QUERY_CREDIT_COST);
    }
    publishAsBlog();

    if (newMessage.pairs.length && newMessage.pairs.length % 5 === 0) {
      trainAthena();
    }
  }

  return useMutation(reSendMessage, {
    onSuccess: handleSuccess,
    onError: (error) => {
      toast.error(error.message, {
        position: 'bottom-center',
        theme: 'dark',
      });
    },
    retryDelay: 7 * 1000, // 7sec
    retry: 10,
  });
}
