import React from 'react';
import HelperVideoModalPopup from 'Component/Shared/HelperVideoPopUp/HelperVideoPop';
import styles from './chat-athena-components.module.css';

const HelperVideo = () => (
  <div className={styles.helper_video}>
    <HelperVideoModalPopup
      tooltipTitle={'Get Started Here'}
      videoLink="https://www.youtube.com/playlist?list=PLQD-WFAGuYO9KZa--y_mwj3uhENQgWRuP"
    />
  </div>
);

export default HelperVideo;
