/* eslint-disable no-lone-blocks */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import './styles.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveTab, addNewTab, removeTab } from 'redux/index';
// assets
import appLogo from 'assets/images/app-logo.png';
import {
  CampaignListIcon,
  LogoContainer,
  ArrowForward,
  ArrowBackward,
} from 'components/common';
import { AccountProfileIcon } from './styles';
// components
import FlyOut from '../FlyOut/FlyOut';
import RecentWorkspace from '../QuickAccess/RecentWorkspace';
import { QuickNavigation } from '../QuickNavigation/QuickNavigation';

function AppHeader() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tabs = useSelector((state) => state?.tabManager?.tabs);
  const activeWorkspaceName = useSelector((state) => state?.workspace?.name);

  const [OnTab, setOnTab] = useState(false);
  const [TabId, setTabId] = useState('');
  const [ActiveTabExpand, setActiveTabExpand] = useState(false);
  const [IsQuickAccessActive, setIsQuickAccessActive] = useState(false);

  const handleAddNewTab = () => {
    dispatch(
      addNewTab({
        id: tabs.length + 1,
        name: 'Workspaces',
        path: '/campaigns',
      }),
    );

    navigate('/campaigns');
  };

  const handleSetActiveTab = (tab) => {
    dispatch(setActiveTab(tab));
    navigate(`${tab.path}`);
  };

  const handleRemoveTab = (tab) => {
    dispatch(removeTab(tab.id));
    navigate('/home');
  };

  const handleHoverTab = (tab) => {
    setOnTab(true);
    setTabId(tab?.id);
  };

  const handleRecentCampaignFlyOutClose = () => {
    setActiveTabExpand(false);
  };

  return (
    <div className="app-header-container">
      <FlyOut
        title="Recent Campaigns"
        open={ActiveTabExpand}
        onClose={() => handleRecentCampaignFlyOutClose()}
      >
        <RecentWorkspace onClose={handleRecentCampaignFlyOutClose} />
      </FlyOut>

      <FlyOut
        title="Quick Navigations"
        open={IsQuickAccessActive}
        onClose={() => setIsQuickAccessActive()}
      >
        <QuickNavigation />
      </FlyOut>

      <div className="app-header-left-container">
        <LogoContainer className="logo-container h-margin-r-x2">
          <img src={appLogo} alt="CrawlQ Logo" className="img-logo" />
        </LogoContainer>
        <LogoContainer>
          <CampaignListIcon onClick={handleAddNewTab} />
        </LogoContainer>
        <div className="app-header-quick-access-container">
          <div
            onClick={() =>
              setIsQuickAccessActive(
                (IsQuickAccessActive) => !IsQuickAccessActive,
              )
            }
          >
            <span className="app-header-quick-access-text">Quick Access</span>
            {IsQuickAccessActive ? <ArrowBackward /> : <ArrowForward />}
          </div>
        </div>
        <div className="app-header-left-current-workspace-wrapper">
          {activeWorkspaceName && (
            <div className="app-header-current-workspace-container">
              <div className="app-header-current-workspace-name">
                {activeWorkspaceName}
              </div>
              {ActiveTabExpand ? (
                <ArrowBackward
                  active
                  onClick={() =>
                    setActiveTabExpand((ActiveTabExpand) => !ActiveTabExpand)
                  }
                />
              ) : (
                <ArrowForward
                  active
                  onClick={() =>
                    setActiveTabExpand((ActiveTabExpand) => !ActiveTabExpand)
                  }
                />
              )}
            </div>
          )}
        </div>
      </div>
      {/* <div className="app-header-tab-container">
        <div className="tab-container-wrapper">
          {tabs &&
            tabs.map((tab) => (
              <div
                className={`tab-container ${
                  activeTab.id === tab.id ? 'active-tab' : 'inactive-tab'
                }`}
                key={tab.id}
                onMouseEnter={() => handleHoverTab(tab)}
                onMouseLeave={() => setOnTab(false)}
              >
                {tab.name === 'Home' ? (
                  <HomeIcon className="tab-logo-icon" />
                ) : (
                  <LayersIcon className="tab-logo-icon" />
                )}
                <div
                  className={tab.id === 1 ? 'tab-name-default' : 'tab-name'}
                  key={tab.id}
                  onClick={() => handleSetActiveTab(tab)}
                >
                  {tab.name}
                </div>
                {tab.id !== 1 && (
                  <CloseIcon
                    className="tab-cross-icon"
                    onClick={() => handleRemoveTab(tab)}
                  />
                )}
                {OnTab && TabId === tab?.id && tab?.workspaceName && (
                  <ActiveTabDetail activeWorkspace={tab?.workspaceName} />
                )}
              </div>
            ))}
        </div>

        <AddIcon className="add-new-tab" onClick={handleAddNewTab} />
      </div> */}
      <div className="app-header-right-container">
        {/* <ThemeToggler /> */}
        {/* <LogoContainer className="h-margin-r-small">
          <AddCircleOutlineIcon />
        </LogoContainer> */}

        <AccountProfileIcon
          className="header-profile-icon"
          onClick={() => navigate('/user-profile')}
        />
      </div>
    </div>
  );
}

export default AppHeader;

{
  /* <div className="app-tab-component-container">
        {activeTab && activeTab.component}
      </div> */
}
