/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import MarketResearchCategory from './MarketResearchCategory';

function GetStarted() {
  localStorage.setItem('category', 'Niche');
  return <MarketResearchCategory getStarted />;
}

export default GetStarted;
