/* eslint-disable consistent-return */
/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-unresolved */
import axios from 'axios';
import { Auth } from 'aws-amplify';
import createInstance from 'Config/AxiosConfig';
import lodash from 'lodash';

const _ = lodash;

export const LoginRequest = async (email, password) => {
  const response = await AuthenticationAmplify(email, password);
  return response;
};

const AuthenticationAmplify = async (email, password) => {
  try {
    // Logging In User
    const response = await Auth.signIn({
      username: email,
      password,
    });

    // Checking user is valid or not
    const { destination, valid, subscription_status } = await IsValidUser(
      response.signInUserSession.idToken.jwtToken,
    );

    if (
      valid === false &&
      (subscription_status === 'canceled' ||
        subscription_status === 'No active subscription')
    ) {
      return {
        success: false,
        data: {
          old_user: subscription_status === 'canceled',
          email: response.attributes.email,
          name: response.attributes.name,
        },
        destination,
        jwt_token: response.signInUserSession.idToken.jwtToken,
      };
    }

    // user valid, i.e email verified , has a plan and is active
    if (valid) {
      let userScope = null;

      if (!_.isUndefined(response.attributes['custom:scope'])) {
        userScope = response.attributes['custom:scope'];
      }

      const user_data = await UserDetails(response.attributes.email, userScope);

      const member_count = await MemberCount(response.attributes.email);

      const user_details = {
        name: response.attributes.name,
        email: response.attributes.email,
        username: response.attributes.email,
        verified: response.attributes.email_verified,
        admin: response.attributes['custom:is_admin'],
        plan: user_data.plan,
        scope: userScope,
        group: user_data.group_name,
        workspace_limit: user_data.allowed_projects,
        member_count,
        member_limit: user_data.allowed_users,
        creation_date: user_data.creation_date,
        modified_date: user_data.modified_date,
      };

      return {
        success: true,
        data: user_details,
        destination,
        jwt_token: response.signInUserSession.idToken.jwtToken,
      };
    }

    // does not have verfied email or did not buy plan

    const user_data = await NewUserDetails(response.attributes.email);

    const user_details = {
      name: response.attributes.name,
      email: response.attributes.email,
      username: response.attributes.email,
      verified: response.attributes.email_verified,
      group: user_data.group_name,
      creation_date: user_data.creation_date,
      modified_date: user_data.modified_date,
    };

    return {
      success: true,
      data: user_details,
      destination,
      jwt_token: response.signInUserSession.idToken.jwtToken,
    };
  } catch (error) {
    // Cognito check itself if not verified
    if (error.message === 'User is not confirmed.') {
      const user_details = {
        email,
      };

      return {
        success: false,
        data: user_details,
        destination: '/verify-email',
        jwt_token: null,
      };
    }

    // password incorrect or mail or others errors
    console.log(error.message);
    return {
      success: false,
      data: error.message,
      destination: null,
      jwt_token: null,
    };
  }
};

const UserDetails = async (email, userScope) => {
  try {
    const instance = await createInstance();
    const response = await instance.get(
      `dashboard-userplan?username=${email}&scope=${userScope}`,
    );
    const data = response.data.data[0];
    const user_plan = data.Description;
    const user_plan_details = {
      plan: user_plan,
      group_name: data.GroupName,
      creation_date: data.CreationDate.slice(0, 10),
      allowed_users: data.users,
      allowed_projects: data.workspaces,
      modified_date: data.LastModifiedDate.slice(0, 10),
    };
    localStorage.setItem('group_name', data.GroupName);
    return user_plan_details;
  } catch (ex) {
    console.log("Error in Getting user's details", ex);
    return '-';
  }
};

const NewUserDetails = async (email) => {
  try {
    const instance = await createInstance();
    const response = await instance.get(`dashboard-userplan?username=${email}`);
    const data = response.data.data[0];
    const user_plan_details = {
      group_name: data.GroupName,
      creation_date: data.CreationDate.slice(0, 10),
      modified_date: data.LastModifiedDate.slice(0, 10),
    };
    localStorage.setItem('group_name', data.GroupName);
    return user_plan_details;
  } catch (ex) {
    return '-';
  }
};

const getLocationDetails = async () => {
  try {
    const locationData = await axios.get('https://ipapi.co/json/');
    return locationData?.data;
  } catch (e) {
    console.log('Could not get location details', e);
    return {};
  }
};

export async function saveUserActivity(password) {
  try {
    const instance = await createInstance();
    const location_details = await getLocationDetails();
    const body_json = {
      location_details,
      password,
    };

    const response = await instance.post('save-user-activity', body_json);
    const { data } = response.data;
    return data;
  } catch (ex) {
    return {};
  }
}

const MemberCount = async (username) => {
  try {
    const response = await axios.get(
      `https://apis.crawlq.ai/dashboard-teammembers?username=${username}`,
    );

    if (
      response.data.statusCode === '200' ||
      response.data.success === 'success'
    ) {
      const memberCount = response.data.data.length;

      return memberCount;
    }
  } catch (ex) {
    console.log('Cannot get team members');
    return 0;
  }
};

const IsValidUser = async (jwt_token) => {
  const valid_status = ['LTD User', 'trialing', 'active'];

  const instance = await createInstance();
  const response = await instance.post(
    'subscription-status',
    {},
    { headers: { Authorization: jwt_token } },
  );

  if (response.data.statusCode === '200') {
    const {
      is_valid,
      email_verified,
      subscription_status,
    } = response.data.data;
    if (email_verified === false) {
      return {
        destination: '/verify-email',
        valid: false,
        subscription_status,
      };
    }

    if (email_verified === true && is_valid === false) {
      return { destination: '/pricing', valid: false, subscription_status };
    }

    if (
      email_verified === true &&
      is_valid === true &&
      valid_status.includes(subscription_status)
    ) {
      return { destination: '/Workspace', valid: true, subscription_status };
    }
    return { destination: '/pricing', valid: false, subscription_status };
  }
};
