import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// Individual Reducers
import userLoginReducer from './User/UserReducers';
import activeWorkspaceReducer from './ActiveWorkspace/ActiveWorkspaceReducers';
import keywordReducer from './Keywords/KeywordReducer';
import tabManagerReducer from './TabsManager/TabManagerReducer';
import newWorkspaceDetailsReducer from './WorkspaceConfiguration/WorkspaceReducer';
import recentWorkspaceReducer from './RecentWorkspaces/RecentWorkspacesReducers';

const rootReducer = combineReducers({
  tabManager: tabManagerReducer,
  user: userLoginReducer,
  workspace: activeWorkspaceReducer,
  authorityKeyword: keywordReducer,
  updateWorkspaceConfiguration: newWorkspaceDetailsReducer,
  recentWorkspaces: recentWorkspaceReducer,
});

// WhiteList those reducers who needs to persisted
const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'tabManager',
    'user',
    'workspace',
    'updateWorkspaceConfiguration',
    'recentWorkspaces',
  ],
};

export default persistReducer(persistConfig, rootReducer);
