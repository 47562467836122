import { useQuery } from 'react-query';
import { store } from 'redux/store';
import createInstance from 'Config/AxiosConfig';

const getUploadedFilesLists = async () => {
  const state = store.getState();
  const active_workspace = state.workspace;
  const { user } = state;

  const instance = await createInstance();
  const { data } = await instance.get(
    `/get-files?username=${user?.username}&name=${active_workspace?.name}`,
  );
  if (data.statusCode === '200') {
    return data.data;
  }
  throw new Error('Failed to Fetch Documents List');
};

export const GET_UPLOADED_FILES_LISTS_KEY = 'get-uploaded-files-lists';

// Query Config Options
const queryOptions = {
  staleTime: 90000,
};

export const useGetUploadedFilesLists = () => {
  const queryKey = [GET_UPLOADED_FILES_LISTS_KEY];
  return useQuery(queryKey, getUploadedFilesLists, queryOptions);
};
