/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import Topbar from '../../Shared/Topbar/Topbar';
import TopNav from '../../Shared/Sidenav/Topnav';

function Workflow() {
  return (
    <>
      <TopNav />
      <div className="main-content" id="panel">
        <Topbar />
        <div className="text-center">
          <iframe
            src="https://lucid.app/documents/embeddedchart/c179cbc1-2a7b-45f2-84c0-07dd77f3f64a"
            title="W3Schools Free Online Web Tutorials"
            height="800"
            width="1400"
          />
        </div>
      </div>
    </>
  );
}

export default Workflow;
