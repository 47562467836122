import styled, { css } from 'styled-components';

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 16px !important;
`;
const centeredDiv = styled(Div)`
  justify-content: center;
  align-items: center;
`;
export const TemplateBuilderWrapper = styled(centeredDiv)`
  height: 100%;
  justify-content: flex-start;
`;

export const TeamplateContainer = styled(Div)`
  min-width: 800px;
  border-radius: 5px;
  padding: 1rem;
  flex: 1;
  width: 100%;
`;
export const FormWrapper = styled(Div)`
  gap: 1rem;
`;

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  cursor: pointer;
  width: 150px;
`;

const commonInputStyle = css`
  border-radius: 5px;
  box-shadow: 0px 0px 2px var(--shadow-color);
  border: 0px;
  padding: 5px;
  font-size: 16px;
  background-color: var(--background-secondary-color);
  &:focus {
    outline: none;
    border: 1px solid var(--primary-color);
  }
`;

export const CheckboxWrapper = styled(centeredDiv)`
  border: 1px solid var(--secondary-color);
  width: 194px;
  height: 50px;
  border-radius: 0.25rem;
  flex-direction: row;
  color: var(--secondary-color);
  height: 47px;
  width: 178px;
`;

export const Textarea = styled.textarea`
  ${commonInputStyle}
  resize: vertical;
  height: 70px;
`;
export const FormGroupWrapper = styled(Div)`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: 10px;
`;
export const ButtonWrapper = styled(Div)`
  justify-content: space-between;
  flex-direction: row;
`;
export const SectionWrapper = styled(Div)`
  padding: 0 2px;
`;
export const Input = styled.input`
  ${commonInputStyle}
  height:40px;
`;
