/* eslint-disable indent */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import React, { Fragment, useEffect, useRef, useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import { useSelector } from 'react-redux';
import smallLogo from '../../../assets/images/crawlQ-transparent-favicon.png';
import CustomTooltip, { CustomTooltipMTI } from '../CustomTooltip';
import { LoaderTailSpin } from '../Loader';
import AthenaLogo from 'assets/images/Athena3.png';
import './Topbar.css';
import Flyout from '../Flyout/Flyout';
import SemanticEngine from 'Component/Pages/Content Automation/AIContentWriter/SemanticSearch/semantic_engine';
import MarketSpyingWizard from 'Component/Pages/MarketReasearch/MarketSpyingWizard/MarketSpyingWizard';
import SearchIntentDiscovery from 'Component/Pages/MarketReasearch/SearchIntent/SearchIntent';
import HelperVideoModalPopup from 'Component/Shared/HelperVideoPopUp/HelperVideoPop';
import { ClickAwayListener } from '@mui/material';
import DropDownBox from './Dropdownbox';
import {
  BulbOutlined,
  WechatOutlined,
  SketchOutlined,
} from '@ant-design/icons';
import TextImageComponent from 'Component/Pages/Content Automation/AIContentWriter/text_image_component';
import store from 'redux/store';
import ChatAthenaComponent from 'Component/Pages/Content Automation/AIContentWriter/chat_athena_component';
import ChatAthena from 'Component/Pages/ChatAthena';
import { WORKSPACE } from 'libs/constants';
import AuthorityBuilder from 'Component/Pages/ContentAutomation/ResultSection/AuthorityBuilder/AuthorityBuilder';
import { notification } from 'antd';
import BrandVoice from 'components/brand-voice/BrandVoice';
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded';
import { getQueryFromURLParams } from '../utils';

function Topnav(props) {
  const CrawlQInputBox = useRef();

  const { name: workspace_name, mti } = useSelector((state) => state.workspace);
  const athena_sources = useSelector(
    (state) => state.user.athena_sources?.train_option,
  );
  const [OpenDropdown, setOpenDropdown] = useState(false);

  const [enableDeepL, setEnableDeepL] = useState(false);
  const [SearchQuery, setSearchQuery] = useState('');
  const [IsNotTrained, setIsNotTrained] = useState(false);
  const [NoResponse, setNoResponse] = useState(false);
  const [ResponseData, setResponseData] = useState();
  const [CrawlqLoading, setCrawlqLoading] = useState(false);
  const [CloseSearch, setCloseSearch] = useState(false);
  const [menuPop, setmenuPop] = useState(false);
  const [filterOptions, setFilterOptions] = useState([
    {
      key: 'mrr',
      value: 'Market Research',
      checked: false,
    },
    {
      key: 'mrr_insight',
      value: 'Market Insights',
      checked: false,
    },
    {
      key: 'script',
      value: 'Sales Copywriting',
      checked: false,
    },
    { key: 'chat_athena', value: 'Chat Athena', checked: false },
    { key: 'serp', value: 'Google', checked: false },
    { key: 'quora', value: 'Quora', checked: false },
    { key: 'reddit', value: 'Reddit', checked: false },
    { key: 'website', value: 'URL', checked: false },
  ]);

  const [openFlyout, setOpenFlyout] = useState(false);
  const [openSearchIntent, setOpenSearchIntent] = useState(false);
  const [openSpyWizard, setOpenSpyWizard] = useState(false);
  const [textImageWindow, setTextImageWindow] = useState(false);
  const [chatWindow, setChatWindow] = useState(false);
  const [IsAuthorityBuilderOpen, setIsAuthorityBuilderOpen] = useState(false);
  const [isBrandVoiceBuilderOpen, setIsBrandVoiceBuilderOpen] = useState(false);

  useEffect(() => {
    const openBrandVoice = getQueryFromURLParams('openBrandVoice');
    if (openBrandVoice === 'true') {
      setIsBrandVoiceBuilderOpen(true);
    }
    handleSelectLanguage();
  }, []);

  const handleCloseFilter = () => {
    const clickCheck = (event) => {
      const concernedElement = document.querySelector(
        '.top-bar-dropdown_options_container',
      );
      if (!concernedElement) return;
      if (!concernedElement.contains(event.target)) {
        setmenuPop(false);
        document.removeEventListener('mousedown', clickCheck);
      }
    };
    if (!menuPop) {
      document.addEventListener('mousedown', clickCheck);
      return;
    }
    document.addEventListener('mousedown', clickCheck);
  };

  const handleSelectLanguage = (e) => {
    const selectedlang = localStorage.getItem('target_lang');
    const deepL_support_lang = [
      'bg',
      'cs',
      'da',
      'de',
      'el',
      'en',
      'es',
      'et',
      'fi',
      'fr',
      'hu',
      'it',
      'ja',
      'lt',
      'lv',
      'nl',
      'pl',
      'pt',
      'ro',
      'ru',
      'sk',
      'sl',
      'sv',
      'zh',
    ];
    if (deepL_support_lang.includes(selectedlang)) {
      setEnableDeepL(true);
    }
  };

  const handleCrawlQSearchWithEnter = async (e) => {
    if (e.key === 'Enter') {
      setOpenFlyout(true);
    }
  };

  const handleCrawlQSearch = async () => {
    setOpenFlyout(true);
  };

  const manageHashtags = (response) => {
    const hashtag_list = response.hashtags;
    const _new_hashtag_list = [];
    let output = '';
    if (hashtag_list && hashtag_list.length) {
      for (const _k in hashtag_list) {
        const hashtag = hashtag_list[_k];
        _new_hashtag_list.push(`#${hashtag}`);
      }
    }
    output = _new_hashtag_list.join(', ');
    return output;
  };

  const manageMentions = (response) => {
    const mention_list = response.mentions;
    const _new_mention_list = [];
    let output = '';
    if (mention_list && mention_list.length) {
      for (const _k in mention_list) {
        const mention = mention_list[_k];
        _new_mention_list.push(`@${mention}`);
      }
    }
    output = _new_mention_list.join(', ');
    return output;
  };

  const renderData = (response) => {
    console.log('herehereherehereherehereherehereherehere');
    if (response?.source === 'mrr_insight') {
      return (
        <div className="answer-list">
          {' '}
          <strong>Answer: </strong>
          <div
            dangerouslySetInnerHTML={{
              __html: response?.answer,
            }}
          />
        </div>
      );
    }
    return (
      <>
        <div className="answer-list">
          {' '}
          <strong>Question: </strong> {response?.question}
        </div>
        <div className="answer-list">
          {' '}
          <strong>Answer: </strong>
          <div
            dangerouslySetInnerHTML={{
              __html: response?.answer,
            }}
          />
        </div>

        <div className="answer-list">
          {' '}
          <strong>Hashtags: </strong>
          <div
            dangerouslySetInnerHTML={{
              __html: manageHashtags(response),
            }}
          />
        </div>

        <div className="answer-list">
          {' '}
          <strong>Mentions: </strong>
          <div
            dangerouslySetInnerHTML={{
              __html: manageMentions(response),
            }}
          />
        </div>
      </>
    );
  };

  function CrawlQAnswerModal() {
    return (
      <>
        {ResponseData && (
          <div className="crawlq-search-answers-container">
            <div className="crawlq-search-answers-topbar">
              <span>CrawlQ Search Results</span>
              <CloseIcon
                style={{ color: '#2a9df5' }}
                onClick={() => setResponseData()}
              />
            </div>
            <div className="crawlq-search-answers">
              {ResponseData.map((response, index) => (
                <div className="search-answer-wrapper" key={index}>
                  <div className="answer-list-wrapper">
                    {response?.url && (
                      <div className="truncate">
                        {' '}
                        <a href={response?.url}> {response?.url}</a>
                      </div>
                    )}
                    {renderData(response)}
                    <div className="answer-source-wrapper">
                      {response.source === 'mrr' ? (
                        <p>Market Research</p>
                      ) : null}
                      {response.source === 'serp' ? <p>Google</p> : null}
                      {response.source === 'website' ? <p>URL</p> : null}
                      {response.source === 'quora' ? <p>Quora</p> : null}
                      {response.source === 'reddit' ? <p>Reddit</p> : null}
                      {response.source === 'mrr_insight' ? (
                        <p>Market Insights</p>
                      ) : null}
                      {response.source === 'script' ? (
                        <p>Sales copywriting</p>
                      ) : null}
                      {response.source !== 'script' || 'mrr' ? null : null}
                      {response.source === 'chat_athena' ? (
                        <p>Chat Athena</p>
                      ) : null}
                    </div>
                  </div>
                </div>
              ))}
              {NoResponse ? (
                <div className="no-result-wrapper">
                  <img src="/img/no-result.svg" />
                  <p>
                    {!IsNotTrained ? (
                      <p>
                        Your search did not match any documents. Try a different
                        keyword.
                      </p>
                    ) : (
                      <p>
                        Looks like you haven’t trained Athena yet. Power her up
                        to get search results..
                      </p>
                    )}
                  </p>
                </div>
              ) : null}
            </div>
          </div>
        )}
      </>
    );
  }

  const handleEmptySearchInput = () => {
    setCloseSearch(false);
    setSearchQuery('');
    CrawlQInputBox.current.focus();
  };

  const handleMenuPop = (event) => {
    setmenuPop((menuPop) => !menuPop);
    handleCloseFilter();
  };

  const handleFilterChange = (index) => {
    const filterOptionsArray = [...filterOptions];
    filterOptionsArray[index].checked = !filterOptionsArray[index].checked;
    setFilterOptions([...filterOptionsArray]);
  };

  function SearchFilter() {
    return (
      <div className="topbar-filter-btn">
        <div className="dropdown_container " style={{ width: '101px' }}>
          <span
            id="demo-simple-select-label"
            className="top-bar-search-filter-label"
            onClick={handleMenuPop}
          >
            Filter {menuPop ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </span>
          <div
            className="dropdown_options_container top-bar-dropdown_options_container"
            style={menuPop ? { display: 'block' } : { display: 'none' }}
          >
            <div className="top-bar-dropdown_option_active">
              {filterOptions.map((option, index) => (
                <Fragment key={index}>
                  {athena_sources && athena_sources.includes(option.key) ? (
                    <div
                      style={{
                        display: 'flex',
                        flex: '1',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      key={index}
                    >
                      <input
                        type="checkbox"
                        value={option.key}
                        checked={option.checked}
                        onChange={() => handleFilterChange(index)}
                      />
                      <div className="train_options ml-2">{option.value}</div>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        flex: '1',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      key={index}
                    >
                      <input
                        type="checkbox"
                        value={option.key}
                        checked={false}
                      />
                      <div className="train_options ml-2 text-muted">
                        {option.value}
                      </div>
                    </div>
                  )}
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  const showNotification = (type, message, description) => {
    notification.open({
      type,
      message,
      description,
      placement: 'bottomRight',
      duration: 6,
    });
  };

  const handleSaveAuthorityBuilder = () => {
    setIsAuthorityBuilderOpen(false);
    showNotification(
      'success',
      'SUCCESS',
      'Brand Authority Keywords Saved Successfully',
    );
  };

  return (
    <>
      <Flyout
        open={textImageWindow}
        onClose={() => setTextImageWindow(false)}
        title="Athena Creative Builder"
      >
        <TextImageComponent />
      </Flyout>

      <Flyout
        width="80vw"
        open={isBrandVoiceBuilderOpen}
        onClose={() => setIsBrandVoiceBuilderOpen(false)}
        title="Brand Voice"
        subtitle="Beta"
      >
        <BrandVoice />
      </Flyout>
      <Flyout
        width="80vw"
        open={IsAuthorityBuilderOpen}
        onClose={() => setIsAuthorityBuilderOpen(false)}
        title="Brand Authority"
        subtitle="Beta"
      >
        <AuthorityBuilder
          handleSaveAuthorityBuilder={handleSaveAuthorityBuilder}
        />
      </Flyout>

      <Flyout
        open={openFlyout}
        onClose={() => setOpenFlyout(false)}
        title="Dig deeper into your Audience Voice"
      >
        <SemanticEngine query={SearchQuery} source={filterOptions} />
      </Flyout>
      <Flyout
        open={openSearchIntent}
        onClose={() => setOpenSearchIntent(false)}
        title="Looking for Trending Topics or People Also Ask"
      >
        <SearchIntentDiscovery />
      </Flyout>
      <Flyout
        open={openSpyWizard}
        onClose={() => setOpenSpyWizard(false)}
        title="Find your Audience and Discover their Needs"
      >
        <MarketSpyingWizard />
      </Flyout>
      <div className="topbar-container">
        <div
          className="topbar-container-left"
          style={{
            justifyContent: 'space-evenly',
          }}
        />
        <div className="topbar-items-container">
          {[
            '/Workspace',
            '/ProfilePage',
            '/TeamMember',
            '/WorkspaceGetStarted',
          ].includes(window.location.pathname) ? null : (
            <div className="topbar-project-name-container">
              <CustomTooltipMTI
                placement="bottom"
                title={`Persona ${WORKSPACE}`}
                arrow
              >
                <span className="project-name">{workspace_name}</span>
              </CustomTooltipMTI>
            </div>
          )}

          {[
            '/Workspace',
            '/ProfilePage',
            '/TeamMember',
            '/WorkspaceGetStarted',
          ].includes(window.location.pathname) || !mti ? null : (
            <div className="topbar-project-name-container tool-tip">
              <CustomTooltipMTI
                placement="bottom"
                title="Main Topic Of Interest"
                arrow
              >
                {!!mti && (
                  <span>
                    <span className="project-name">{mti.slice(0, 25)}</span>
                    {mti.length > 25 && '...'}
                  </span>
                )}
              </CustomTooltipMTI>
            </div>
          )}
          <ClickAwayListener onClickAway={() => setOpenDropdown(false)}>
            <div
              className="topbar-username-container"
              onClick={() => setOpenDropdown(true)}
            >
              <span className="topbar-username">
                <PersonIcon />
              </span>
              {OpenDropdown && <DropDownBox />}
            </div>
          </ClickAwayListener>
        </div>
      </div>
    </>
  );
}

export default Topnav;
