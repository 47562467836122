/* eslint-disable import/no-named-as-default-member */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable radix */
/* eslint-disable no-useless-escape */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import { InfoOutlined } from '@mui/icons-material';
import createInstance from 'Config/AxiosConfig';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { userWorkspaceCountUpdate, workspaceSelect } from 'redux/index';
import coin from '../../../../../assets/images/coins.svg';
import './CreateNewWorkspace.css';
import { toast, ToastContainer } from 'react-toastify';

/* Components -----------------*/
import CustomTooltip from '../../../../Shared/CustomTooltip';
import LangTrans, {
  SelectLanguageEngine,
} from '../../../../Shared/LangTrans/LangTrans';
import LoaderSpin from '../../../../Shared/Loader';
import AddTags from './AddTags';

/* Logic Function */
import setGoogleLanguageCookie from '../../../../Shared/Cookie_custom';
import createNewWorkspace, {
  getAllWorkspaceNames,
} from './CreateNewWorkspaceLogic';
import {
  ButtonWrapper,
  CheckBoxWraper,
  FromGroup,
  InputCustom,
} from './styles';
import {
  ButtonPrimary,
  ButtonSecondary,
  Flex,
} from 'Component/Shared/GlobalStyles';
import UsersList from './UserListDropdown';
import TeamMembersList from './TeamMembersList';
import { updateWorkspaceDetails } from '../../../../../redux';
import { initialStateWorkspaceConfig } from 'libs/DefaultStateOfWorkspaceConfig';
import getParsedConfigurations, {
  ErrorToastProps,
} from './parseConfigurations';
import { getListOfCountries } from 'Component/Pages/ContentAutomation/AIcontentWriter/AIcontentLogic';
import { FormControl, Select } from '@mui/material';
import { WORKSPACE } from 'libs/constants';

function CreateNewWorkspace({ workspace_limit }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const workspaceConfigurations = useSelector(
    (state) => state?.updateWorkspaceConfiguration,
  );

  const userName = useSelector((state) => state?.user?.name);

  const validProjectName = /^[0-9a-zA-Z _\b]+$/;

  const expression = /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/;
  const URL_regex = new RegExp(expression);

  const [WorkspaceDetails, setWorkspaceDetails] = useState({
    workspace_name: '',
    workspace_domain: '',
    workspace_language: 'en',
    workspace_engine: 0,
  });

  const [IsWorkspaceFieldEmpty, setIsWorkspaceFieldEmpty] = useState({
    workspace_name: false,
    workspace_domain: false,
  });

  const [IsWorkspaceConfirm, setIsWorkspaceConfirm] = useState(false);
  const [IsConfirmLoading, setIsConfirmLoading] = useState(false);
  const [IsError, setIsError] = useState(false);
  const [IsErrorName, setIsErrorName] = useState(false);
  const [IsErrorDomain, setIsErrorDomain] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState('');
  const [ErrorMessageName, setErrorMessageName] = useState('');
  const [ErrorMessageDomain, setErrorMessageDomain] = useState('');
  const [countries, setCountries] = useState([]);
  const [selectedCountryCode, setselectedCountryCode] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [existingWorkspaceNames, setExistingWorkspaceNames] = useState([]);

  const [level0questions, setLevel0questions] = useState({
    mti: '',
    companyName: '',
    targetAudience: '',
  });
  const { workspace_count, username } = useSelector((state) => state?.user);

  useEffect(() => {
    getAllWorkspaceNames().then((data) => {
      setExistingWorkspaceNames([...data.data]);
    });
    getListOfCountries().then((data) => {
      setCountries(data);
    });
  }, []);

  const handleLevel0AnswerChange = (e, name) => {
    setLevel0questions({ ...level0questions, [name]: e.target.value });
  };

  const handleLevel0AnswerChangeArrow = (e, name) => {
    if (e.keyCode !== 39) return;
    setLevel0questions({ ...level0questions, [name]: e.target.placeholder });
  };

  // SetLanguage Dropdown value
  const handleLanguageChange = (lang) => {
    setWorkspaceDetails({
      ...WorkspaceDetails,
      workspace_language: lang,
    });
  };

  // SetLanguageEngine Dropdown value
  const handleLanguageEngineChange = (engine) => {
    setWorkspaceDetails({
      ...WorkspaceDetails,
      workspace_engine: engine,
    });
  };

  // Set Workspace  name value
  const handleWorkspaceName = (e) => {
    setIsErrorName(false);
    if (e.target.value !== '' && !validProjectName.test(e.target.value)) {
      setIsErrorName(true);
      setErrorMessageName('Avoid special characters [$,@,!,%,- etc]');
    }

    setWorkspaceDetails({
      ...WorkspaceDetails,
      [e.target.name]: e.target.value,
    });
  };
  // Set Workspace  domain value
  const handleRightArrow = (e) => {
    if (e.keyCode !== 39) return;
    setWorkspaceDetails({
      ...WorkspaceDetails,
      [e.target.name]: e.target.placeholder,
    });
  };

  const handleWorkspaceDomain = (e) => {
    setIsErrorDomain(false);

    if (!e.target.value.match(URL_regex)) {
      setIsErrorDomain(true);
      setErrorMessageDomain(
        'Please enter a valid domain name. Ex: https://crawlq.ai',
      );
    }

    setWorkspaceDetails({
      ...WorkspaceDetails,
      [e.target.name]: e.target.value,
    });
  };

  // New Workspace fields filled checks
  const handleConfirm = async () => {
    setIsWorkspaceConfirm(true);

    const workspaceName = WorkspaceDetails?.workspace_name?.trim();
    const workspaceDomain = WorkspaceDetails?.workspace_domain?.trim();
    const workspaceLanguage = WorkspaceDetails?.workspace_language?.trim();
    const { companyName, mti, targetAudience } = level0questions;

    if (!workspaceName) {
      toast.error('Please provide a valid Workspace Name', ErrorToastProps);
      return;
    }

    if (!workspaceDomain) {
      toast.error('Please provide a valid Workspace Domain', ErrorToastProps);
      return;
    }

    if (!workspaceLanguage) {
      toast.error('Please provide a valid Workspace Language', ErrorToastProps);
      return;
    }

    if (existingWorkspaceNames.includes(workspaceName)) {
      toast.error(
        `Workspace Name "${workspaceName}" Already Exists`,
        ErrorToastProps,
      );
      return;
    }

    if (!companyName) {
      toast.error("Please provide a valid Company's Name", ErrorToastProps);
      return;
    }

    if (!mti) {
      toast.error('Please provide a Main Topic of Intrest', ErrorToastProps);
      return;
    }

    if (!targetAudience) {
      toast.error('Please provide a Target Audience', ErrorToastProps);
      return;
    }

    if (!selectedCity) {
      toast.error('Please Enter City Name', ErrorToastProps);
      return;
    }

    if (
      existingWorkspaceNames.includes(WorkspaceDetails.workspace_name.trim())
    ) {
      toast.error(
        `Workspace Name "${WorkspaceDetails.workspace_name}" Already Exits`,
        ErrorToastProps,
      );
      return;
    }

    if (
      WorkspaceDetails.workspace_name?.trim() === '' &&
      WorkspaceDetails.workspace_domain === ''
    ) {
      setIsWorkspaceFieldEmpty({
        ...IsWorkspaceFieldEmpty,
        workspace_name: true,
        workspace_domain: true,
      });
      console.log(IsWorkspaceFieldEmpty);
      setIsConfirmLoading(false);
    } else if (WorkspaceDetails.workspace_name?.trim() === '') {
      setIsWorkspaceFieldEmpty({
        ...IsWorkspaceFieldEmpty,
        workspace_name: true,
        workspace_domain: false,
      });
      console.log(IsWorkspaceFieldEmpty);
      setIsConfirmLoading(false);
    } else if (WorkspaceDetails.workspace_domain === '') {
      setIsWorkspaceFieldEmpty({
        ...IsWorkspaceFieldEmpty,
        workspace_name: false,
        workspace_domain: true,
      });
      console.log(IsWorkspaceFieldEmpty);
      setIsConfirmLoading(false);
    } else {
      setIsConfirmLoading(true);

      setIsWorkspaceFieldEmpty({
        ...IsWorkspaceFieldEmpty,
        workspace_name: false,
        workspace_domain: false,
      });

      // No fields empty , proceed to create new workspace
      await CreateNewWorkspaceConfirmed(
        WorkspaceDetails.workspace_name,
        WorkspaceDetails.workspace_domain,
        WorkspaceDetails.workspace_language,
      );
    }
  };

  // workspace creation endpoint
  const CreateNewWorkspaceConfirmed = async () => {
    setIsConfirmLoading(false);

    const onboarding_input_body = {
      username,
      name: WorkspaceDetails.workspace_name,
      source_lang: WorkspaceDetails.workspace_language,
      input_data: [
        {
          question: 'Define your Niche',
          answer: '',
        },
        {
          question: 'Define your Sub-Niche',
          answer: '',
        },
        {
          question: 'Define your Micro-Niche',
          answer: '',
        },
        {
          question: 'Define your Industry Vertical',
          answer: '',
        },
        {
          question: 'Ideal Prospect Overview',
          answer: '',
        },
        {
          question: 'Your Website Domain Name',
          answer: WorkspaceDetails.workspace_domain,
        },
        {
          question: 'Your Company Legal Name',
          answer: level0questions.companyName,
        },
        {
          question: 'Main topic of interest',
          answer: level0questions.mti,
        },
        {
          question: 'Long Tail Topic 1',
          answer: '',
        },
        {
          question: 'Single Big Shocking Statement',
          answer: '',
        },
        {
          question: 'Single Big Emotional Payoff',
          answer: '',
        },
        {
          question: 'Who you want to target',
          answer: level0questions.targetAudience,
        },
      ],
    };

    const data = {
      name: WorkspaceDetails.workspace_name?.trim(),
      site: WorkspaceDetails.workspace_domain,
      fullName: userName,
      lang: WorkspaceDetails.workspace_language,
      lang_engine: WorkspaceDetails.workspace_engine,
      target_persona: level0questions.targetAudience,
      country_code: selectedCountryCode,
      city: selectedCity,
    };

    let _currentWorkspaceDetails =
      workspaceConfigurations?.workspaces?._currentWorkspace;

    if (!_currentWorkspaceDetails) {
      _currentWorkspaceDetails = {};
    }
    dispatch(
      updateWorkspaceDetails({
        workspaces: {
          _currentWorkspace: {
            ...initialStateWorkspaceConfig,
            ..._currentWorkspaceDetails,
            ...data,
            onboardingData: onboarding_input_body,
          },
        },
      }),
    );
    navigate('/new-workspace/configure', { state: { newWorkspace: true } });
  };

  function LanguageCreditCostDisplay() {
    return (
      <div className="font-accent new-workspace-language-credit-cost">
        <img src={coin} />
        <p>1000 credits</p>
        <CustomTooltip
          title="Applicable only for a non-english workspace"
          placement="top"
          arrow
        >
          <InfoOutlined className="cursor-pointer" />
        </CustomTooltip>
      </div>
    );
  }

  useEffect(() => {
    if (!location?.state) return;

    const oldWorkspace = location?.state?.configurations;
    setWorkspaceDetails({
      workspace_name: '',
      workspace_domain: oldWorkspace?.site,
      workspace_language: oldWorkspace?.Lang,
      workspace_engine: oldWorkspace?.lang_engine,
    });
    setLevel0questions({
      mti: oldWorkspace?.main_topic,
      companyName: '',
      targetAudience: oldWorkspace?.target_persona,
    });

    const level1_configurations = getParsedConfigurations(
      oldWorkspace?.configurations?.level1,
    );
    const level2_configurations = getParsedConfigurations(
      oldWorkspace?.configurations?.level2,
    );
    const level3_configurations = getParsedConfigurations(
      oldWorkspace?.configurations?.level3,
    );

    dispatch(
      updateWorkspaceDetails({
        workspaces: {
          _currentWorkspace: {
            ...initialStateWorkspaceConfig,
            product_name: oldWorkspace?.product_name,
            founder_name: oldWorkspace?.founder_name,
            configurations: {
              level1: {
                ...level1_configurations,
              },
              level2: {
                ...level2_configurations,
              },
              level3: {
                ...level3_configurations,
              },
            },
          },
        },
      }),
    );
  }, []);

  const handleSelectedCountry = (e) => {
    setselectedCountryCode(e.target.value);
  };

  const handleOnChangeCity = (e) => {
    setSelectedCity(e.target.value);
  };

  return (
    <div className="create-new-workspace-main-container">
      <ToastContainer />
      <div className="create-new-workspace-user-container">
        {IsError && ErrorMessage ? (
          <span className="create-new-workspace-error-message">
            {ErrorMessage}
          </span>
        ) : null}

        {IsErrorName && ErrorMessageName ? (
          <span className="create-new-workspace-error-message">
            {ErrorMessageName}
          </span>
        ) : null}
        {IsErrorDomain && ErrorMessageDomain ? (
          <span className="create-new-workspace-error-message">
            {ErrorMessageDomain}
          </span>
        ) : null}
        <div className="create-new-workspace-user-container-box">
          <div className="create-new-workspace-wrapper">
            <div className="new-workspace-input-wrapper">
              <p
                style={
                  IsWorkspaceConfirm &&
                  IsWorkspaceFieldEmpty.workspace_name === true
                    ? { color: 'red' }
                    : null
                }
              >
                {WORKSPACE} Name
              </p>

              <input
                style={
                  IsWorkspaceConfirm &&
                  IsWorkspaceFieldEmpty.workspace_name === true
                    ? { borderBottomColor: 'red' }
                    : null
                }
                name="workspace_name"
                placeholder="3D Printing"
                value={WorkspaceDetails.workspace_name}
                onChange={(e) => handleWorkspaceName(e)}
                // onKeyDown={e => handleRightArrow(e)}
              />
              <div className="new-workspace-input-wrapper">
                <p>Company Legal Name</p>
                <input
                  placeholder="Yeggi"
                  value={level0questions.companyName}
                  onChange={(e) => handleLevel0AnswerChange(e, 'companyName')}
                  // onKeyDown={e =>
                  //   handleLevel0AnswerChangeArrow(e, 'companyName')
                  // }
                />
              </div>
            </div>

            <Flex gap={'1rem'}>
              <Flex
                direction={'column'}
                flex={1}
                className="new-workspace-input-wrapper"
              >
                <p>Country</p>
                <FormControl variant="outlined">
                  <Select
                    native
                    onChange={handleSelectedCountry}
                    value={selectedCountryCode}
                  >
                    {countries.map((country, index) => (
                      <option key={index} value={country.alpha2Code}>
                        {country.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Flex>
              <Flex
                direction={'column'}
                flex={1}
                className="new-workspace-input-wrapper"
              >
                <p>City</p>
                <input
                  placeholder="Chicago"
                  value={selectedCity}
                  onChange={handleOnChangeCity}
                  className="mt-2"
                />
              </Flex>
            </Flex>

            {/* Language Dropdown */}
            <LangTrans
              language={WorkspaceDetails.workspace_language}
              handleLanguageChange={handleLanguageChange}
            />
            {/* LanguageEngine Dropdown */}
            <SelectLanguageEngine
              engine={WorkspaceDetails.workspace_engine}
              currentLanguage={WorkspaceDetails.workspace_language}
              handleEngineChange={handleLanguageEngineChange}
            />
            {WorkspaceDetails.workspace_language !== 'en' && (
              <LanguageCreditCostDisplay />
            )}
          </div>

          <div className="create-new-workspace-wrapper">
            <div className="new-workspace-input-wrapper">
              <p
                style={
                  IsWorkspaceConfirm &&
                  IsWorkspaceFieldEmpty.workspace_domain === true
                    ? { color: 'red' }
                    : null
                }
              >
                Domain Name
              </p>
              <input
                name="workspace_domain"
                placeholder="https://yeggi.com/"
                value={WorkspaceDetails.workspace_domain}
                onChange={(e) => handleWorkspaceDomain(e)}
                // onKeyDown={e => handleRightArrow(e)}
              />
            </div>
            <div className="new-workspace-input-wrapper">
              <p>Main Topic of Interest</p>
              <input
                placeholder="Best 3D printing software"
                value={level0questions.mti}
                onChange={(e) => handleLevel0AnswerChange(e, 'mti')}
                // onKeyDown={e => handleLevel0AnswerChangeArrow(e, 'mti')}
              />
            </div>
            <div
              className="new-workspace-input-wrapper"
              style={{
                flex: 1,
              }}
            >
              <p>Who you want to target</p>
              <textarea
                style={{
                  resize: 'vertical',
                  height: '85%',
                }}
                placeholder="Craft and art students who are looking for new careers in Digital Technology"
                value={level0questions.targetAudience}
                onChange={(e) => handleLevel0AnswerChange(e, 'targetAudience')}
              />
            </div>
          </div>
        </div>
        <div className="new-workspace-button-wrapper">
          {!IsConfirmLoading ? (
            workspace_limit ? (
              <button style={{ backgroundColor: '#ff5050' }}>
                Limit Reached
              </button>
            ) : IsErrorDomain || IsErrorName ? (
              <button style={{ cursor: 'not-allowed' }}>
                Create Workspace
              </button>
            ) : (
              <ButtonWrapper>
                <ButtonPrimary onClick={handleConfirm}>Next</ButtonPrimary>
              </ButtonWrapper>
            )
          ) : (
            <LoaderSpin width="30" height="45" />
          )}
        </div>
      </div>
    </div>
  );
}

export default CreateNewWorkspace;
