import React from 'react';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import { ErrorToastProps } from '../Workspaces/Workspace/CreateNewWorkspace/parseConfigurations';

const cancelAddonHeading = {
  'Quora Addon': 'CrawlQ-Reddit-Quora',
  'Reddit Addon': 'CrawlQ-Reddit-Quora',
  'Chat-Athena Pro AddOn': 'Hypnotic-NLP Conversational AI',
};

const allowedPlansForFreeQuoraRedditAddons = [
  'Startup (Monthly)',
  'Startup (Yearly)',
  'Growth Consulting (Monthly)',
  'Growth Consulting (Yearly)',
  'Agency (Monthly)',
  'Agency (Yearly)',
];

const styles = (theme) => ({
  root: {
    margin: 0,
    width: '600px',
  },
  closeButton: {
    position: 'absolute',
    color: '#eeeeee',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      style={{ display: 'flex' }}
      disableTypography
      className={classes.root}
      {...other}
    >
      <Typography variant="h6">
        <b>{children}</b>
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {},
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
  },
}))(MuiDialogActions);

export default function ConfirmCancelPopup({ name, handleCancel }) {
  const [open, setOpen] = React.useState(false);

  const { plan } = useSelector((state) => state?.user);

  const handleClickOpen = () => {
    if (allowedPlansForFreeQuoraRedditAddons.includes(plan)) {
      toast.error(
        'Can’t cancel as this AddOn is part of the plan!',
        ErrorToastProps,
      );
      return;
    }

    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleNO = async () => {
    await handleCancel();
  };

  return (
    <div>
      <ToastContainer />
      <style>
        {`
            .MuiDialog-paperWidthSm{
                width: 1200px
            }
            .MuiTypography-root {
                width: fit-content;
            }
            p{
                font-size: 1rem
            }
        `}
      </style>
      <Button
        variant="outlined"
        color="error"
        onClick={handleClickOpen}
        className="text-muted"
      >
        Cancel {name}
      </Button>

      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Are you looking to cancel <span className="text-danger">{name} </span>
          ?
        </DialogTitle>
        <DialogContent
          dividers
          style={{
            textAlign: 'justify',
          }}
        >
          <p>
            Before you really make up your mind, I want to show you how much
            work we have to do again to bring your
            <b className="mx-1">{cancelAddonHeading[name]}</b>
            strategy on the track.
          </p>
          <p>
            I would love to show you how we can help you before you really
            decide to press the Cancel Button.
          </p>
          <p>
            <a href="https://calendly.com/crawlq-business-analytics/crawlq-demo-how-to-beat-your-digital-competition">
              Please unlock your free one-on-one strategy call worth $750.
            </a>
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNO} className="text-muted">
            Yes, Cancel My Add-on
          </Button>
          <Button autoFocus onClick={handleClose} className="text-success">
            No, Take Me Back
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
