/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import React, { useState, useEffect } from 'react';
import './UserWorkspaceClone.css';

// Component
import FileCopyRoundedIcon from '@mui/icons-material/FileCopyRounded';
import LoaderSpin from '../../../../../Shared/Loader';

/* Logic Function */
import { fetchUserWorkspace } from '../../WorkspaceLogic';
import CloneWorkspaceRequest from './UserWorkspaceCloneLogic';

/*
 workspace_detail : selected workspace detail => name, domain
 close_clone_modal : function call to close clone modal
 user_workspace_refetch : reload userworkspace table
*/
function UserWorkspaceClone({ workspace_detail, close_clone_modal, user_workspace_refetch }) {
  const validProjectName = /^[0-9a-zA-Z _\b]+$/;

  const [IsCloneConfirming, setIsCloneConfirming] = useState(false);

  const [NewWorkspaceDetail, setNewWorkspaceDetail] = useState({

    new_workspace_name: '',
  });

  const [UserWorkspaceList, setUserWorkspaceList] = useState([]);
  const [WorkspaceExists, setWorkspaceExist] = useState(false);
  const [IsCloning, setIsCloning] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    LoadUserWorkspaceData();
  }, []);

  const LoadUserWorkspaceData = async () => {
    const { result } = await fetchUserWorkspace();

    setUserWorkspaceList(result);
  };

  const handleClone = async (workspace_name, workspace_domain, workspace_language, new_workspace_name) => {
    setIsCloneConfirming(true);
    // console.log(workspace_name, workspace_domain, new_workspace_name)

    const result = await CloneWorkspaceRequest(workspace_name, workspace_domain, workspace_language, new_workspace_name);

    if (result.error === true) {
      setIsCloneConfirming(false);
      setErrorMessage('Workspace already exists !');
      setWorkspaceExist(true);
      setIsCloning(true);
    } else {
      setIsCloneConfirming(false);
      close_clone_modal();
      user_workspace_refetch();
    }
  };

  const handleNewWorkspaceDetail = (e, current_workpace_name) => {
    setIsCloning(false);
    setWorkspaceExist(false);

    if (e.target.name === 'new_workspace_name') {
      // if (!validProjectName.test(e.target.value)) {

      //     setIsCloning(true)
      //     setWorkspaceExist(true)
      //     setErrorMessage("Avoid special characters [$,@,!,%,- etc] and spaces")
      // }
    }

    if (current_workpace_name === e.target.value) {
      setIsCloning(true);
      setWorkspaceExist(true);
      setErrorMessage('Workspace name cannot be same.');
    }

    setNewWorkspaceDetail({
      ...NewWorkspaceDetail,
      [e.target.name]: e.target.value,
    });

    UserWorkspaceList.map(({ name }) => {
      if (name == e.target.value) {
        setWorkspaceExist(true);
        setErrorMessage('Workspace already exists');
      }
    });
  };
  const handleRightArrow = (e) => {
    if (e.keyCode !== 39) return;
    setNewWorkspaceDetail({
      ...NewWorkspaceDetail,
      [e.target.name]: e.target.placeholder,
    });
  };

  return (
    <div className="workspace-clone-main-container">

      <div className="workspace-clone-icon-wrapper">
        <FileCopyRoundedIcon style={{ color: '#2a9df5', width: '5rem', height: '5rem' }} />

      </div>
      <h5>
        Clone
        {' '}
        {workspace_detail.workspace_name}
        {' '}
        ?
      </h5>

      {IsCloning && WorkspaceExists ? <span className="clone-workspace-error-message">{ErrorMessage}</span> : null}

      <div className="workspace-clone-input-wrapper">
        <p style={IsCloning && WorkspaceExists ? { color: 'red' } : null}>
          New Workspace Name
        </p>
        <input
          style={IsCloning && WorkspaceExists ? { borderBottomColor: 'red' } : null}
          name="new_workspace_name"
          placeholder={`${workspace_detail.workspace_name}_clone`}
          value={NewWorkspaceDetail.new_workspace_name}
          onChange={(e) => handleNewWorkspaceDetail(e, workspace_detail.workspace_name)}
          onKeyDown={(e) => handleRightArrow(e)}
        />
      </div>
      <div className="workspace-clone-button-wrapper">

        <button onClick={close_clone_modal}>CANCEL</button>

        {IsCloneConfirming
          ? (
            <div>
              <LoaderSpin width="25" height="25" />
            </div>
          )
          : (
            <button disabled={IsCloning} onClick={() => handleClone(workspace_detail.workspace_name, workspace_detail.workspace_domain, workspace_detail.workspace_language, NewWorkspaceDetail.new_workspace_name)}>
              CONFIRM
            </button>
          )}
      </div>

    </div>
  );
}

export default UserWorkspaceClone;
