/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';
import './AddDFYWorkspace.css';

// Components
import { LoaderTailSpin } from 'Component/Shared/Loader';

// Logic Fns
import AddDFY from './AddDFYWorkspaceLogic';

function AddDFYWorkspace({ choosen, close_modal }) {
  const [AlotUser, setAlotUser] = useState('');
  const [IsAlotUserEmpty, setIsAlotUserEmpty] = useState(false);
  const [Success, setSuccess] = useState();
  const [ResponseMessage, setResponseMessage] = useState('');
  const [Loader, setLoader] = useState(false);

  useEffect(() => {

  }, []);

  const handleAlotUserInput = (e) => {
    setIsAlotUserEmpty(false);
    setAlotUser(e.target.value);
  };

  const handleAlotWorkspace = async (workspace_name) => {
    if (AlotUser === '') {
      setIsAlotUserEmpty(true);
    } else {
      setLoader(true);

      const { success, message } = await AddDFY(workspace_name, AlotUser);

      if (success) {
        setSuccess(true);
        setResponseMessage(message);
        setLoader(false);
        setAlotUser('');
      } else {
        setSuccess(false);
        setResponseMessage(message);
        setLoader(false);
      }
    }
  };

  return (
    <div className="done-dfy-workspace-container">
      <h3>Assign DFY Workspace </h3>
      <div className="done-dfy-workspace-body">

        <div className="update-dfy-workspace-name-container">
          <span className="update-dfy-worksapce-name-header">Workspace Name</span>
          <span className="update-dfy-worksapce-name-data">{choosen.name}</span>
        </div>
        <div className="update-dfy-workspace-name-container">
          <span className="update-dfy-worksapce-name-header">Workspace Domain</span>
          <span className="update-dfy-worksapce-name-data">{choosen.domain}</span>
        </div>
        <p>Workspace will be assigned to:</p>

        <input
          placeholder="Username"
          value={AlotUser}
          onChange={(e) => handleAlotUserInput(e)}
        />

      </div>

      {IsAlotUserEmpty ? <span>Oops ! Please fill Username</span> : null}
      {Success && ResponseMessage ? <span dangerouslySetInnerHTML={{ __html: ResponseMessage }} /> : <span dangerouslySetInnerHTML={{ __html: ResponseMessage }} />}
      {Loader
        ? <LoaderTailSpin width="25" />
        : (
          <div className="done-dfy-worksapce-button-container">

            <button className="done-dfy-button-done" onClick={() => handleAlotWorkspace(choosen.name)}>Assign</button>

          </div>
        )}
    </div>

  );
}

export default AddDFYWorkspace;
