import { MARKET_RESEARCH_LEVELS } from 'constants/market-research';
import React, { createContext, useContext, useMemo, useState } from 'react';

export const MarketReasearchContext = createContext();

export const MarketResearchProvider = ({ children }) => {
  const [Level, setLevel] = useState(MARKET_RESEARCH_LEVELS[0]);

  const marketResearchProviderValue = useMemo(() => ([Level, setLevel]), [Level, setLevel]);

  return (
    <MarketReasearchContext.Provider value={marketResearchProviderValue}>
      {children}
    </MarketReasearchContext.Provider>
  );
};

export const useMarketResearchContext = () => {
  const context = useContext(MarketReasearchContext);

  if (context === undefined) {
    throw new Error('useMarketResearchContext must be used within a MarketRsearchContextProvider');
  }

  return context;
};
