/* eslint-disable indent */
/* eslint-disable no-useless-concat */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import CustomTooltip from 'Component/Shared/CustomTooltip';
import { CreditManage } from 'Component/Shared/CreditManager';
import NoDataAvailable from 'Component/Shared/NoDataResultSection';
import {
  addInEditor,
  selectTextAndCopyWithElement,
} from '../../../Shared/copyToClipBoard';
import CreditUpdater from '../../../Shared/CreditManager';
import LoaderSpin from '../../../Shared/Loader';
import {
  getTranslatedTextArray,
  getTranslatedTextArrayEnglish,
} from '../../MarketReasearch/MarketResearchAndResonance/MarketResearchCategory/fILLAiLangTrans';
import { Editor } from '../contexts/summary_Context';
import '../css/headlines_Section.css';
import createInstance from 'Config/AxiosConfig';
import CustomTextArea from './CustomTextarea';
import { Flex } from 'Component/Shared/GlobalStyles';

export function HeadlinesSection(props, { alert }) {
  const PARAPHRASE_COST = 10;

  const GENERATE_ANSWER = 40;

  const [show, setShow] = useState(false);
  // const [headlinesData, setHeadlinesData] = useState(props.headlines);
  const [logoUrl, setlogoUrl] = useState(
    'https://s2.googleusercontent.com/s2/favicons?domain=',
  );
  const [paraVisiblity, setparaVisiblity] = useState(0);
  const [queVisiblity, setqueVisiblity] = useState();
  const [IsGenerateQuestionLoading, setIsGenerateQuestionLoading] = useState(
    false,
  );
  const [GeneratedQuestion, setGeneratedQuestion] = useState();
  const [GeneratedAnswer, setGeneratedAnswer] = useState();
  const [IsGenerateAnswerLoading, setIsGeneratedAnswerLoading] = useState();

  const [rewriterParaChangeValue, setRewriterParaChangeValue] = useState({});
  const [rewriteScore, setRewriteScore] = useState(1);
  const [isParaphraserLoading, setisParaphraserLoading] = useState(false);

  const { globalEditor, setglobalEditor } = useContext(Editor);

  const [CreditFail, setCreditFail] = useState(false);
  const [AnswerCreditFail, setAnswerCreditFail] = useState(false);

  const context = localStorage.getItem('context');

  function copyClipboard(text) {
    const cleanText = text.replace(/<\/?[^>]+(>|$)/g, '');

    const el = document.createElement('textarea');
    el.value = cleanText;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }

  const headlineData = props.headlines;

  function autosize() {
    const el = this;
    setTimeout(() => {
      // el.style.cssText = 'height:auto; padding:0'
      // for box-sizing other than "content-box" use:
      el.style.cssText = '-moz-box-sizing:content-box';
      el.style.cssText = `height:${el.scrollHeight}px`;
    }, 0);
  }

  const handleChange = (e, index, child_index, parent_index, type) => {
    e.preventDefault();
    const genQue = { ...GeneratedQuestion };
    genQue[`${child_index}` + `${parent_index}` + `${type}`][index] =
      e.target.value;

    setGeneratedQuestion({ ...genQue });
    e.target.addEventListener('keydown', autosize);
  };

  const IncreaseCreditCount = async () => {
    const { success } = await CreditUpdater(20);

    if (success === 'true') {
      return { credit_result: 'Pass' };
    }
    return { credit_result: 'Fail' };
  };

  async function paraphase_text(data, ci, pi, type) {
    const joinedArrayData = data.join(' ');

    setisParaphraserLoading(true);

    const { success } = await CreditManage(PARAPHRASE_COST, true);

    if (success) {
      try {
        const uniqueIndex = `${ci}` + `${pi}` + `${type}`;

        setRewriteScore((rewriteScore) => rewriteScore + 1);

        const body = {
          text: joinedArrayData,
          range_slider: rewriteScore,
        };
        const result = await axios.post(
          'https://apis.crawlq.ai/ai-content-rewriter',
          body,
        );

        if (result.data.statusCode === '200') {
          const { success } = await CreditManage(PARAPHRASE_COST);

          if (success) {
            setRewriterParaChangeValue({
              ...rewriterParaChangeValue,
              [uniqueIndex]: result.data.data.output,
            });
          } else {
            setCreditFail(true);
            setisParaphraserLoading(false);
          }
        }
      } catch (err) {
        console.log(err);
        setisParaphraserLoading(false);
      }
    } else {
      setisParaphraserLoading(false);
      setCreditFail(true);
    }
  }

  const handleHeadlineParaVisiblity = (child_index, parent_index, type) => {
    const id = `${child_index}` + `${parent_index}` + `${type}`;

    // to collapse already opened accordian
    if (
      paraVisiblity[`${child_index}` + `${parent_index}` + `${type}`] === true
    ) {
      setparaVisiblity({ ...paraVisiblity, [id]: false });
    } else {
      setparaVisiblity({ ...paraVisiblity, [id]: true });
      // console.log(child_index,parent_index,type,`${child_index}`+`${parent_index}`+`${type}`)
    }
    console.log(paraVisiblity);
  };

  const handleGenerateQuestion = async (
    paragragh_data,
    child_index,
    parent_index,
    type,
  ) => {
    const id = `${child_index}` + `${parent_index}` + `${type}`;
    setIsGenerateQuestionLoading(true);

    paragragh_data = await getTranslatedTextArrayEnglish(paragragh_data);

    const body = {
      paragraph: paragragh_data[0],
      name: localStorage.getItem('name'),
    };
    try {
      const instance = await createInstance();

      const result = await instance.post(
        'https://apis.crawlq.ai/questions-from-para',
        body,
      );

      if (result.data.statusCode === '200' && result.data.data.length > 0) {
        const generatedQuestionsAnswers = result.data.data.map((item) => ({
          question: item.question,
          answer: item.answer,
        }));
        setGeneratedQuestion({
          ...GeneratedQuestion,
          [id]: generatedQuestionsAnswers,
        });
      } else {
        setGeneratedQuestion(['CrawlQ does not find generative questions']);
      }
      setIsGenerateQuestionLoading(false);
    } catch (err) {
      console.log(err);
      setIsGenerateQuestionLoading(false);
    } finally {
      setqueVisiblity({ ...queVisiblity, [id]: true });
      console.log(GeneratedQuestion);
    }
  };

  const handleGenerateAnswer = async (parent_id, question_index, question) => {
    const uniqueId = parent_id + question_index;

    setIsGeneratedAnswerLoading({
      ...IsGenerateAnswerLoading,
      [uniqueId]: true,
    });

    const { success } = await CreditManage(GENERATE_ANSWER, true);
    if (success) {
      const translatedTexts = await getTranslatedTextArrayEnglish([
        question,
        context,
      ]);
      const body = {
        question: translatedTexts[0],
        context: translatedTexts[1],
        username: localStorage.getItem('username'),
        name: localStorage.getItem('name'),
      };

      try {
        const { data } = await axios.post(
          'https://apis.crawlq.ai/question-answer',
          body,
        );
        const ansTrans = data.answer;

        if (ansTrans) {
          const { success } = await CreditManage(GENERATE_ANSWER);
          if (success) {
            setGeneratedAnswer({ ...GeneratedAnswer, [uniqueId]: ansTrans });
            setIsGeneratedAnswerLoading({
              ...IsGenerateAnswerLoading,
              [uniqueId]: false,
            });
          } else {
            setIsGeneratedAnswerLoading({
              ...IsGenerateAnswerLoading,
              [uniqueId]: false,
            });

            setAnswerCreditFail({
              ...AnswerCreditFail,
              [uniqueId]: true,
            });
          }
        }
      } catch (ex) {
        console.log('Error in Que', ex);
        setIsGeneratedAnswerLoading({
          ...IsGenerateAnswerLoading,
          [uniqueId]: false,
        });
      }

      console.log(GeneratedAnswer);
    } else {
      setIsGeneratedAnswerLoading({
        ...IsGenerateAnswerLoading,
        [uniqueId]: false,
      });

      setAnswerCreditFail({
        ...AnswerCreditFail,
        [uniqueId]: true,
      });
    }
  };

  function ModuleHeadline({
    type,
    heading,
    paragraph,
    child_index,
    parent_index,
  }) {
    const id = `${child_index}` + `${parent_index}` + `${type}`;
    const stringPara = paragraph.join(' ');

    return (
      <div className="headline-container">
        <div className="headline-head-container">
          <div className="headline-right-tag-container">
            <span className="headline-tag notranslate">H{type}</span>
            <span className="headline-head">{heading}</span>
            <img
              className="headlines-copy-icon"
              src="../img/copy.png"
              onClick={(e) => addInEditor(heading)}
            />
          </div>

          <img
            alt="toggle"
            className="headline-dropdown"
            src={
              paraVisiblity[
                `${child_index}` + `${parent_index}` + `${type}`
              ] === true
                ? '../img/upload.png'
                : '../img/down-arrow.png'
            }
            onClick={() =>
              handleHeadlineParaVisiblity(child_index, parent_index, type)
            }
          />
        </div>

        {paraVisiblity[`${child_index}` + `${parent_index}` + `${type}`] ===
          true && (
          <div className="headlines-para-container">
            {rewriterParaChangeValue[
              `${child_index}` + `${parent_index}` + `${type}`
            ] ? (
              <p
                className="headlines-para"
                dangerouslySetInnerHTML={{
                  __html: `<style>
                p > span{
                color: #2a9df5;
                font-weight: bolder
                }
                </style ${
                  rewriterParaChangeValue[
                    `${child_index}` + `${parent_index}` + `${type}`
                  ]
                }`,
                }}
              />
            ) : (
              <p className="headlines-para">{stringPara}</p>
            )}

            <div className="headline-para-controller">
              {IsGenerateQuestionLoading ? (
                <LoaderSpin height="20" width="20" />
              ) : (
                <CustomTooltip title="Generate Questions" placement="top" arrow>
                  <img
                    alt="Generate Question"
                    className="headlines-question-generator"
                    src="../img/question-ans.png"
                    onClick={() =>
                      handleGenerateQuestion(
                        paragraph,
                        child_index,
                        parent_index,
                        type,
                      )
                    }
                  />
                </CustomTooltip>
              )}

              <CustomTooltip title="Copy" placement="top" arrow>
                <img
                  alt="Copy"
                  className="headlines-copy"
                  src="../img/copy.png"
                  onClick={(e) => {
                    addInEditor(stringPara);
                  }}
                />
              </CustomTooltip>
            </div>

            {queVisiblity &&
              queVisiblity[`${child_index}` + `${parent_index}` + `${type}`] ===
                true && (
                <div className="headline-curated-question-container">
                  <span className="curated-question-static-head">
                    Curated Questions :
                  </span>
                  <div className="headline-curated-question">
                    {GeneratedQuestion &&
                      GeneratedQuestion[
                        `${child_index}` + `${parent_index}` + `${type}`
                      ].map((questionAnswers, index) => (
                        <Flex align="flex-start">
                          <Flex align="center" direction="column">
                            <div className="question-answer-container w-100">
                              <span>
                                <span
                                  className="mr-1 pt-1 notranslate"
                                  style={{
                                    fontWeight: '700',
                                    color: ' #2B5C98',
                                  }}
                                >
                                  Q:
                                </span>
                              </span>
                              <div
                                style={{
                                  fontSize: '0.9rem',
                                  width: '100%',
                                  fontWeight: '700',
                                }}
                              >
                                {questionAnswers.question}
                              </div>
                            </div>
                            <div className="qna_answer_container">
                              <p
                                className="qna_answer"
                                dangerouslySetInnerHTML={{
                                  __html: questionAnswers.answer,
                                }}
                              />
                            </div>
                          </Flex>

                          <img
                            className="no-select"
                            src="../img/copy.png"
                            onClick={(e) => {
                              const newContent = `<b>${questionAnswers.question}</b>
                              <br>
                                ${questionAnswers.answer}
                              `;

                              addInEditor(newContent);
                            }}
                            style={{ width: '20px', cursor: 'pointer' }}
                          />
                        </Flex>
                      ))}
                  </div>
                </div>
              )}
          </div>
        )}
      </div>
    );
  }

  function ModuleLinkRank({ headline }) {
    if (
      Object.keys(headline.paragraph_h1).length === 0 &&
      Object.keys(headline.paragraph_h2).length === 0 &&
      Object.keys(headline.paragraph_h3).length === 0 &&
      Object.keys(headline.paragraph_h4).length === 0 &&
      Object.keys(headline.paragraph_h5).length === 0
    ) {
      return null;
    }
    return (
      <div className="link-rank-container">
        <div className="link-logo-container">
          <img className="headlines-link-logo" src={logoUrl + headline.url} />
          <a
            className="headlines-link"
            href={headline.page_url}
            target="_blank"
            rel="noreferrer"
          >
            {headline.url}
          </a>
        </div>

        <div className="rank-container">
          <span className="headlines-rank-static">Rank : </span>
          <span className="headlines-rank-numeral"> {headline.rank}</span>
        </div>
      </div>
    );
  }

  if (typeof props.headlines === 'undefined') {
    return <div>Loading Headlines</div>;
  }

  return (
    <div className="">
      {headlineData &&
        headlineData.map((headline, index) => (
          <div className="moduleHeadline-container">
            <ModuleLinkRank headline={headline} />
            {Object.entries(headline.paragraph_h1).map(
              ([key, value], child_index) => (
                <ModuleHeadline
                  type={1}
                  heading={key}
                  paragraph={value}
                  parent_index={headline.rank}
                  child_index={child_index}
                />
              ),
            )}

            {Object.entries(headline.paragraph_h2).map(
              ([key, value], child_index) => (
                <ModuleHeadline
                  type={2}
                  heading={key}
                  paragraph={value}
                  parent_index={headline.rank}
                  child_index={child_index}
                />
              ),
            )}

            {Object.entries(headline.paragraph_h3).map(
              ([key, value], child_index) => (
                <ModuleHeadline
                  type={3}
                  heading={key}
                  paragraph={value}
                  parent_index={headline.rank}
                  child_index={child_index}
                />
              ),
            )}

            {Object.entries(headline.paragraph_h4).map(
              ([key, value], child_index) => (
                <ModuleHeadline
                  type={4}
                  heading={key}
                  paragraph={value}
                  parent_index={headline.rank}
                  child_index={child_index}
                />
              ),
            )}

            {Object.entries(headline.paragraph_h5).map(
              ([key, value], child_index) => (
                <ModuleHeadline
                  type={5}
                  heading={key}
                  paragraph={value}
                  parent_index={headline.rank}
                  child_index={child_index}
                />
              ),
            )}
          </div>
        ))}
      {headlineData.length === 0 && <NoDataAvailable />}
    </div>
  );
}
export default HeadlinesSection;
