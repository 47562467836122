import { useMutation } from 'react-query';
import axios from 'axios';
import {
  getReduxUserState,
  getReduxWorkspaceState,
} from 'utils/helpers/redux-selectors';
import createInstance from 'Config/AxiosConfig';
import { toast } from 'react-toastify';
import { baseAPIsUrl } from 'Config/Api';

const handleAthenaTraining = async () => {
  const { email } = getReduxUserState();
  const { name } = getReduxWorkspaceState();

  const axiosConfig = {
    multiValueHeaders: {
      'Content-Type': ['application/json'],
      'Access-Control-Allow-Origin': ['*'],
      'Access-Control-Allow-Headers': [
        'Content-Type',
        'X-Amz-Date',
        'Authorization',
        'X-Api-Key',
        'X-Amz-Security-Token',
      ],
    },
  };

  const body = {
    username: email,
    name,
    num: 1,
    cc: 'US',
    is_train: true,
    serp: true,
    overwrite: false,
  };
  await axios.post(`${baseAPIsUrl}/test-semantic`, body, axiosConfig);
};

const saveMarketResearchAnswers = async ({
  toBeSavedAnswers,
  categoryName,
  section,
}) => {
  const { email } = getReduxUserState();
  const { name } = getReduxWorkspaceState();
  const sectionName = section ?? 'Niche';
  const payload = {
    username: email,
    name,
    section: sectionName, // will be 'Niche' but convert category will have dynamic
    subcategory: categoryName,
    data: toBeSavedAnswers,
  };

  const instance = await createInstance();

  const response = await instance.post('save-questions', payload);

  if (response?.data?.statusCode === '200') {
    return response;
  }

  throw new Error('Market Research data failed to save, Try again !');
};

export const useMutateSaveMarketResearchAnswers = () =>
  useMutation(saveMarketResearchAnswers, {
    onSuccess: (data) => {},
    onError: (error) => {
      toast.error(`${error.message}`, {
        position: 'bottom-center',
        theme: 'dark',
      });
    },
    onSettled: () => {
      toast.success('Sucessfully Saved', {
        position: 'bottom-center',
        theme: 'dark',
      });
      handleAthenaTraining();
    },
  });
