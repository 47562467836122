import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useMutateCloneWorkspace } from 'queries/workspace/clone-workspace';
import { LoaderTailSpin } from 'components/common/Loader/Loader';
import { CopyIcon } from '../../styles';
import './clone-action.css';

const CloneAction = ({ workspace, toggleClose }) => {
  const { email } = useSelector((state) => state?.user);

  const {
    mutate,
    isLoading,
    isError,
    error,
    isSuccess,
  } = useMutateCloneWorkspace();
  const [CloneWorkspaceName, setCloneWorkspaceName] = useState(
    `${workspace?.name}_Clone`,
  );

  useEffect(() => {
    if (isSuccess) {
      toggleClose();
    }
  }, [isSuccess]);

  const handleConfirmClick = () => {
    mutate({
      ...workspace,
      new_name: CloneWorkspaceName,
      fullName: email,
    });

    toast.success(`${CloneWorkspaceName} Cloned !`, {
      position: 'bottom-center',
      theme: 'dark',
    });
  };
  return (
    <div className="workspace-clone-main-container">
      <ToastContainer />
      <div className="workspace-clone-header-wrapper">
        <CopyIcon />
        <div>Clone Campaign</div>
      </div>

      <div className="workspace-clone-section-wrapper">
        <div className="workspace-clone-workspace-detail">
          <span className="workspace-clone-workspace-head">
            Campaign Detail
          </span>
          <li>
            name:{' '}
            <span className="clone-workspace-value">{workspace?.name}</span>
          </li>
          <li>
            domain:{' '}
            <span className="clone-workspace-value">{workspace?.site}</span>
          </li>
          <li>
            language:{' '}
            <span className="clone-workspace-value">{workspace?.lang}</span>
          </li>
        </div>
        <span>
          Cloned Campaign name will be :{' '}
          <span className="new-clone-workspace-name">{CloneWorkspaceName}</span>
        </span>

        <div className="workspace-clone-section-input-wrapper">
          <input
            className="clone-workspace-input"
            placeholder={`${workspace?.name}_Clone`}
            value={CloneWorkspaceName}
            onChange={(e) => setCloneWorkspaceName(e.target.value)}
          />
        </div>
      </div>

      {isError && (
        <div className="workspace-clone-error-wrapper">
          Error : {error?.message}
        </div>
      )}

      <div className="workspace-clone-button-wrapper">
        <div className="workspace-clone-button-container clone-cancel">
          <button type="button" onClick={() => toggleClose()}>
            CANCEL
          </button>
        </div>

        <div className="workspace-clone-button-container clone-confirm">
          {!isLoading ? (
            <button type="button" onClick={handleConfirmClick}>
              CONFIRM
            </button>
          ) : (
            <LoaderTailSpin width={20} />
          )}
        </div>
      </div>
    </div>
  );
};

export default CloneAction;
