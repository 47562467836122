import styled from 'styled-components';

export const StepsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
`;

export const DividerWrapper = styled.div`
  display: flex;
  color: #a7a798;
  align-items: center;
`;

export const BannerWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

export const BannerComponent = styled.div`
  display: flex;
  border-radius: 10px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  align-items: center;
  width: 400px;
  height: 130px;
  padding: 0.8rem;
  justify-content: space-evenly;
`;

export const BannerContent = styled.div`
  flex: 5;
  display: flex;
  align-items: center;
  /* flex-direction: column; */
`;

export const BannerImage = styled.img`
  flex: 1;
  overflow: hidden;
`;

export const HeadingStep = styled.p`
  font-size: 0.9rem;
  margin-bottom: 0rem;
  padding-left: 0.4rem;
  font-weight: 600;
`;
export const DescriptionStep = styled.p`
  font-size: 0.5rem;
  padding-left: 0.4rem;
`;

export const HorizontalLine = styled.hr`
  width: 25%;
`;

export const NumberBullet = styled.div`
  background-color: #2a9df5;
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  font-weight: bold;
`;
export const OptionComponent = styled.div`
  height: 1rem;
  background-color: #2a9df5;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: baseline;
  color: #fff;
  font-size: 0.9rem;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-left-radius: 0.1rem;
  border-bottom-left-radius: 0.1rem;
`;
