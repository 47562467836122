/* eslint-disable max-len */
import createInstance from 'Config/AxiosConfig';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { getReduxUserState, getReduxWorkspaceState } from 'utils/helpers/redux-selectors';

export const getTLDR = async () => {
  const iframe = document.querySelector('#texteditor_ifr');
  const editorContent = iframe.contentWindow.document.getElementById('tinymce').innerText;

  const payload = {
    text: editorContent,
  };

  const instance = await createInstance();
  const { data } = await instance.post('get-tldr', payload);

  if (data?.statusCode === '200') {
    return data?.answer;
  }

  throw new Error('Unable to fetch TLDR Content');
};

// Keep Query Value as File Name to maintain consistency and uniqueness in query index
const QUERY_KEY = 'get-tldr';

// Query Config Options
const queryOptions = {
  enabled: false,
  retry: false,
};

// QUERY
export const useGetTLDR = () =>
  useQuery([QUERY_KEY], getTLDR, queryOptions);
