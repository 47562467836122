/* eslint-disable indent */
/* eslint-disable quotes */
/* eslint-disable quote-props */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable no-plusplus */
/* eslint-disable react/button-has-type */
/* eslint-disable no-return-assign */
/* eslint-disable no-use-before-define */

import { Button, Col, Form, Row, Input, Select, Switch, Steps } from 'antd';
import React, { useEffect, useState } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import { getCountries, languageData } from './utils';

const LanguageMobileSelector = (props) => {
  const { data, setData } = props;
  return (
    <Row
      align="middle"
      justify="space-around"
      style={{
        width: '100%',
        // backgroundColor: 'pink'
      }}
    >
      <Col span={11}>
        <ReactFlagsSelect
          placeholder="Select A Country"
          searchable
          selected={data.targetCountry}
          onSelect={(code) => setData({ ...data, targetCountry: code })}
          style={{ width: '50px', color: '!white', backgroundColor: '!white' }}
          selectedSize={12}
        />
      </Col>
      <Col span={11}>
        <ReactFlagsSelect
          className="custom-flag-select"
          placeholder="Select A Country"
          searchable
          selected={data.language}
          countries={getCountries()}
          customLabels={languageData}
          onSelect={(code) => {
            setData({ ...data, languageCode: code, language: code });
          }}
          style={{ width: '50px', color: '!white', backgroundColor: '!white' }}
          selectedSize={12}
        />
      </Col>
    </Row>
  );
};

export default LanguageMobileSelector;
