import React from 'react';
import { ScaffoldCard, PrimaryButton, InfoIcon } from 'components/common';
import { useGetTLDR } from 'queries/content/content-tools/get-tldr';
import { TLDRLoading } from 'components/skeleton/content/content-tools/overview/TLDRLoading';
import CopyText from 'components/common/CopyText/CopyText';

import styles from './tldr.module.css';

function TLDR() {
  const { data, isFetching, refetch: getTLDR } = useGetTLDR();

  return (
    <div className="h-margin-t-large">
      <div className={styles.content_heading}>TL; DR <InfoIcon className="h-margin-l-xsmall" /></div>
      <ScaffoldCard className={styles.content_wrapper}>

        <PrimaryButton
          onClick={() => getTLDR()}
        >
          {!isFetching && data ? 'Re-Generate' : 'Generate'}
        </PrimaryButton>

        {/* show when data exists */}
        {data && <CopyText contentId="tldr-content" />}

        {/* When fetching -> show loader */}
        {isFetching && <TLDRLoading />}

        {/* When Data exists and is not fetching */}
        {!isFetching && data && (
        <div className={styles.content_data}>
          <div id="tldr-content">{data}</div>
        </div>
        )}

      </ScaffoldCard>
    </div>
  );
}

export default TLDR;
