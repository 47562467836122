import 'assets/constants.css';
import styled from 'styled-components';

export const PrimaryButton = styled.button`
  background-color: var(--primary-color);
  color: ${(props) =>
    props.disabled ? 'var(--disbled-color)' : 'var(--white-color)'};
  padding: 0.2rem;
  border-radius: 2px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
