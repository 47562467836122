/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import { motion } from 'framer-motion';
import React from 'react';
import ReactDom from 'react-dom';
import ClearIcon from '@mui/icons-material/Clear';
import './styles.css';

const MODAL_STYLES = {
  width: '350px',
  height: 'min-content',
  position: 'fixed',
  backgroundColor: '#FFF',
  zIndex: 1000,
  top: '30%',
  left: '50%',
  right: 'auto',
  bottom: 'auto',
  marginRight: '-50%',
  transform: 'translate(-50%, -50%)',

};

const OVERLAY_STYLES = {
  position: 'fixed',
  right: 0,
  top: 0,
  bottom: 0,
  left: 0,
  backgroundColor: 'rgba(0,0,0,.7)',
  zIndex: 1000,
};

function FlyoutModal({ isOpen, children, requestClose }) {
  if (!isOpen) return null;

  return ReactDom.createPortal(
    <div>
      <div style={OVERLAY_STYLES} onClick={requestClose} />
      <motion.div
        className="flyoutmodal-container"
        initial={{ opacity: 0, x: '+100vw' }}
        exit={{ x: '+100wv' }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5, ease: 'easeInOut' }}
        style={MODAL_STYLES}
      >

        {children}

      </motion.div>
    </div>,
    document.getElementById('portal'),
  );
}

export default FlyoutModal;
