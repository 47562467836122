/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import { createSvgIcon } from '@mui/material';
import axios from 'axios';
import createInstance from 'Config/AxiosConfig';

const UpdateDFY = async (username, workspace_name, status) => {
  try {
    const req_body = {

      to_user: username,
      workspace: workspace_name,
      status,
    };

    const instance = await createInstance();

    const response = await instance.post('update-dfy', req_body);

    if (response.data.success) {
      return ({
        success: true,
        message: `${workspace_name} updated to ${status} for ${username}`,
      });
    }

    return ({
      success: false,
      message: ` Oops ! ${workspace_name} could not be updated for ${username}, Try Again`,
    });
  } catch (err) {
    console.log(err);
    return ({
      success: false,
      message: ` Oops ! ${workspace_name} could not be updated for ${username}, Try Again`,
    });
  }
};

export default UpdateDFY;
