import React from 'react';
import { ScaffoldCard } from 'components/common/scaffold-card';
import { LoadingPlaceholder } from '../LoadingPlaceholder';
import { MultiLoaderContainer } from './styles';

const MarketQuestionsSkeleton = () => (
  <ScaffoldCard className="h-margin-b-x2">
    <LoadingPlaceholder height="14px" width="30%" marginBottom="8px" />
    <LoadingPlaceholder height="60px" width="100%" marginBottom="8px" />
    <MultiLoaderContainer>
      <LoadingPlaceholder height="10px" width="20%" marginRight="6px" />
      <LoadingPlaceholder height="10px" width="40%" marginRight="6px" />
      <LoadingPlaceholder height="10px" width="30%" marginRight="6px" />
    </MultiLoaderContainer>

    <MultiLoaderContainer>
      <LoadingPlaceholder height="10px" width="40%" marginRight="6px" />
      <LoadingPlaceholder height="10px" width="30%" marginRight="6px" />
      <LoadingPlaceholder height="10px" width="20%" marginRight="6px" />
    </MultiLoaderContainer>

  </ScaffoldCard>

);

export const MarketQuestionsLoading = () => (

  <div>
    {Array(4).fill(null).map((value, index) => (
      <MarketQuestionsSkeleton key={index} />
    ))}
  </div>

);
