import { useQuery } from 'react-query';
import createInstance from 'Config/AxiosConfig';

const getAddOnStatus = async () => {
  const instance = await createInstance();
  const { data } = await instance.post('addon-status');
  console.log();
  return data?.data;
};

export const useAddOnStatus = () => useQuery(['train-athena-add-on'],
  getAddOnStatus,
  { staleTime: Infinity });
