/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable react/jsx-filename-extension */
import { getReferralID } from 'Component/Shared/Cookie_custom';
import { getQueryFromURLParams } from 'Component/Shared/utils';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const PRICING_TABLE_ID = 'prctbl_1MgYhZFRtTTCtwHwtcxftXPc';
const PUBLISHABLE_KEY = 'pk_live_aEO8gt1KB36sENfwsJBeewC40041EnNgdE';
const PRICING_TABLE_WITHOUT_TRIAL = 'prctbl_1N1csXFRtTTCtwHwns2Sr5Nt';

export default function PricingPage() {
  // const {
  //   name, plan, email, modified_date, group,
  // } = useSelector(
  //   (state) => state?.user,
  // );

  // console.log('BeforeEMAILEMAILEMAILEMAILEMAILEMAIL', email, name);

  const location = useLocation();
  const { old_user, email, name } = location?.state;
  const TABLE_ID = old_user === true ? PRICING_TABLE_WITHOUT_TRIAL : PRICING_TABLE_ID;
  const referral_id = getReferralID();

  useEffect(() => {
    handleEmailReferral();
  }, []);

  const handleEmailReferral = () => {
    window.fpr('referral', { email: localStorage.getItem('username') });
  };

  function getFPTid() {
    return (
      (window.FPROM && window.FPROM.data.tid) || `fp_${new Date().getTime()}`
    );
  }

  return (
    <div style={{ background: '#0e1a35', height: '90%' }}>
      <stripe-pricing-table
        pricing-table-id={TABLE_ID}
        publishable-key={PUBLISHABLE_KEY}
        customer-email={email}
        client-reference-id={getFPTid()}
      />
    </div>
  );
}
