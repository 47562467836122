/* eslint-disable object-property-newline */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable quotes */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable semi */
/* eslint-disable consistent-return */
/* eslint-disable no-dupe-else-if */
/* eslint-disable no-else-return */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable max-len */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/button-has-type */
/* eslint-disable no-return-assign */
/* eslint-disable no-use-before-define */

import { Button, Col, Form, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import AthenaLogo from 'assets/images/crawlQ-transparent-favicon.png';
import athena3 from 'assets/images/Athena3.png';
import {
  CaretDownOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  LeftOutlined,
  LoadingOutlined,
  RightOutlined,
} from '@ant-design/icons';
import {
  LoadingIcon,
  ProgressBox,
} from 'Component/Pages/Workspaces/Workspace/BuildWorkspaceStatus/styles';
import Wave from 'react-wavify';
import './style.css';

const elements = [
  'Athena is diving in...',
  'Athena is decoding...',
  'Athena is on the hunt...',
  'Athena is crafting data...',
  'Athena is mapping minds...',
  'Athena is at work...',
  'Athena is sifting data...',
  'Athena is in a deep dive...',
  'Athena is connecting dots...',
  'Athena is synthesizing...',
  'Athena is data-mining...',
  'Athena is on an insight quest...',
  'Athena is on a data journey...',
  'Athena is crunching...',
  'Athena is forging insights...',
  // Add more elements here
];

const OnBoardingScreen = (props) => {
  const { boardingStatus, setStarterFlow } = props;
  const { data, setData } = props;
  const [onBoarding, setOnBoarding] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    console.log(
      `status is ${boardingStatus[0]?.onboarding_status[5]?.current_status}`,
    );
    if (
      boardingStatus[0]?.onboarding_status[5]?.current_status === 'complete'
    ) {
      // redirect to chat Athena
      setStarterFlow('CHATATHENA');
    }
  }, [boardingStatus[0]?.onboarding_status[5]?.current_status]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % elements.length);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const renderStatus = (status) => {
    if (status === 'complete') {
      return (
        <div style={{ color: 'lightGreen', paddingRight: '5px' }}>
          <Row justify="space-between" style={{ width: '100%' }}>
            <Col>
              <span style={{ marginRight: '8px' }}>Complete</span>
            </Col>
            <Col>
              <CheckCircleOutlined style={{ fontSize: 24 }} spin />
            </Col>
          </Row>
        </div>
      );
    } else if (status === 'pending') {
      return (
        <div style={{ color: 'black', paddingRight: '5px' }}>
          <Row justify="space-between" style={{ width: '100%' }}>
            <Col>
              <span style={{ marginRight: '8px' }}>Pending</span>
            </Col>
            <Col>
              <LoadingOutlined style={{ fontSize: 24 }} spin />
            </Col>
          </Row>
        </div>
      );
    } else if (status === 'failed') {
      return (
        <div style={{ color: 'red', paddingRight: '5px' }}>
          <Row justify="space-between" style={{ width: '100%' }}>
            <Col>
              <span style={{ marginRight: '8px' }}>Failed</span>
            </Col>
            <Col>
              <CloseCircleOutlined style={{ fontSize: 24 }} spin />
            </Col>
          </Row>
        </div>
      );
    }
    return <div> </div>;
  };

  return (
    <div
      style={{
        backgroundColor: 'white',
      }}
    >
      <div
        style={{
          height: '10vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontWeight: 'bold',
          fontSize: '25px',
        }}
      ></div>
      <div
        style={{
          height: '70vh',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Form
            name="control-hooks"
            // onFinish={onFinish}
            style={{ width: '100vw', color: 'white' }}
          >
            <Form.Item name="logo">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  // marginBottom: '5px',
                }}
              >
                <img
                  src={AthenaLogo}
                  alt="Athena Logo"
                  style={{
                    display: 'inline-block',
                    width: '45px',
                    // marginLeft: '10%',
                    // marginBottom: '10px',
                  }}
                />
              </div>
            </Form.Item>
            <Form.Item>
              <div
                style={{
                  color: '#4B79FF',
                  width: '100%',
                  fontSize: '4vw',
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}
              >
                {elements[currentIndex]}
              </div>
            </Form.Item>
            <Form.Item rules={[{ required: true }]}>
              <div
                style={{
                  fontWeight: 'bold',
                  color: '#5887FB',
                  // marginLeft: '10%',
                  fontSize: '20px',
                  // marginBottom: '10px',
                  height: '100px',
                }}
              >
                <Wave
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                  mask="url(#mask)"
                  fill="#5887FB"
                  amplitude="30"
                  speed="0.35"
                  points="4"
                >
                  <defs>
                    <linearGradient
                      id="gradient"
                      gradientTransform="rotate(90)"
                    >
                      <stop offset="0" stopColor="white" />
                      <stop offset="0.5" stopColor="black" />
                    </linearGradient>
                    <mask id="mask">
                      <rect
                        x="0"
                        y="0"
                        width="2000"
                        height="200"
                        fill="url(#gradient)"
                      />
                    </mask>
                  </defs>
                </Wave>
              </div>
              <div
                style={{
                  marginLeft: '35%',
                  marginTop: '7%',
                }}
              >
                {true && (
                  <div
                    style={{
                      background: '#4B79FF',
                      borderRadius: '10px',
                      width: '130px',
                      paddingLeft: '25px',
                      marginBottom: '10px',
                      paddingBottom: '5px',
                    }}
                  >
                    <CaretDownOutlined />
                    <span style={{ color: 'white' }}>Attract</span>
                    <div
                      style={{
                        color: 'green ',
                        fontWeight: 'bold',
                        marginBottom: '5px',
                      }}
                    >
                      {renderStatus(
                        boardingStatus[0]?.onboarding_status[0]?.current_status,
                      )}
                    </div>
                  </div>
                )}
                {(boardingStatus[0]?.onboarding_status[1]?.current_status ===
                  'complete' ||
                  boardingStatus[0]?.onboarding_status[0]?.current_status ===
                    'complete') && (
                  <div
                    style={{
                      background: '#4B79FF',
                      borderRadius: '10px',
                      width: '130px',
                      paddingLeft: '25px',
                      marginBottom: '10px',
                      paddingBottom: '5px',
                    }}
                  >
                    <CaretDownOutlined />
                    <span style={{ color: 'white' }}>Listen</span>
                    <div
                      style={{
                        color: 'green ',
                        fontWeight: 'bold',
                        marginBottom: '5px',
                      }}
                    >
                      {renderStatus(
                        boardingStatus[0]?.onboarding_status[1]?.current_status,
                      )}
                    </div>
                  </div>
                )}
                {(boardingStatus[0]?.onboarding_status[2]?.current_status ===
                  'complete' ||
                  boardingStatus[0]?.onboarding_status[1]?.current_status ===
                    'complete') && (
                  <div
                    style={{
                      background: '#4B79FF',
                      borderRadius: '10px',
                      width: '130px',
                      paddingLeft: '25px',
                      marginBottom: '10px',
                      paddingBottom: '5px',
                    }}
                  >
                    <CaretDownOutlined />
                    <span style={{ color: 'white' }}>Desire</span>
                    <div
                      style={{
                        color: 'green ',
                        fontWeight: 'bold',
                        marginBottom: '5px',
                      }}
                    >
                      {renderStatus(
                        boardingStatus[0]?.onboarding_status[2]?.current_status,
                      )}
                    </div>
                  </div>
                )}
                {(boardingStatus[0]?.onboarding_status[3]?.current_status ===
                  'complete' ||
                  boardingStatus[0]?.onboarding_status[2]?.current_status ===
                    'complete') && (
                  <div
                    style={{
                      background: '#4B79FF',
                      borderRadius: '10px',
                      width: '130px',
                      paddingLeft: '25px',
                      marginBottom: '10px',
                      paddingBottom: '5px',
                    }}
                  >
                    <CaretDownOutlined />
                    <span style={{ color: 'white' }}>Learn</span>
                    <div
                      style={{
                        color: 'green ',
                        fontWeight: 'bold',
                        marginBottom: '5px',
                      }}
                    >
                      {renderStatus(
                        boardingStatus[0]?.onboarding_status[3]?.current_status,
                      )}
                    </div>
                  </div>
                )}
                {(boardingStatus[0]?.onboarding_status[4]?.current_status ===
                  'complete' ||
                  boardingStatus[0]?.onboarding_status[3]?.current_status ===
                    'complete') && (
                  <div
                    style={{
                      background: '#4B79FF',
                      borderRadius: '10px',
                      width: '130px',
                      paddingLeft: '25px',
                      marginBottom: '10px',
                      paddingBottom: '5px',
                    }}
                  >
                    <CaretDownOutlined />
                    <span style={{ color: 'white' }}>Convert</span>
                    <div
                      style={{
                        color: 'green ',
                        fontWeight: 'bold',
                        marginBottom: '5px',
                      }}
                    >
                      {renderStatus(
                        boardingStatus[0]?.onboarding_status[4]?.current_status,
                      )}
                    </div>
                  </div>
                )}

                {(boardingStatus[0]?.onboarding_status[5]?.current_status ===
                  'complete' ||
                  boardingStatus[0]?.onboarding_status[4]?.current_status ===
                    'complete') && (
                  <div
                    style={{
                      background: '#4B79FF',
                      borderRadius: '10px',
                      width: '130px',
                      paddingLeft: '25px',
                      marginBottom: '10px',
                      paddingBottom: '5px',
                    }}
                  >
                    <CaretDownOutlined />
                    <span style={{ color: 'white' }}>Triage</span>
                    <div
                      style={{
                        color: 'green ',
                        fontWeight: 'bold',
                        marginBottom: '5px',
                      }}
                    >
                      {renderStatus(
                        boardingStatus[0]?.onboarding_status[5]?.current_status,
                      )}
                    </div>
                  </div>
                )}
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div
        style={{
          height: '10vh',
          // backgroundColor: 'green',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      ></div>
    </div>
  );
};

export default OnBoardingScreen;
