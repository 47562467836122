export const PremiumPlans = Object.freeze([
  'Solo (Monthly)',
  'Solo (Yearly)',
  'Startup (Monthly)',
  'Startup (Yearly)',
  'Growth (Monthly)',
  'Growth (Yearly)',
  'Agency (Monthly)',
  'Agency (Yearly)',
  'Growth Consulting (Yearly)',
  'Growth Consulting (Monthly)',
]);

export const FREEMUIM_PLAN = 'Freemium Plan';
export const MAX_ALLOWED_WORKSPACES_IN_FREEMIUM_PLAN = 3;

export const isPremiumPlan = (plan) => PremiumPlans.includes(plan);
export const isFreemiumPlan = (plan) => plan === FREEMUIM_PLAN;
