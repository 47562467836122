import React, { useState } from 'react';
import cx from 'classnames';
import { LoaderTailSpin } from 'Component/Shared/Loader';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import styles from './document-handler.module.css';

export const DocumentPreview = ({ fileLink, handleBack }) => {
  const [isLoadingDocument, setIsLoadingDocument] = useState(true);

  return (
    <div style={{ height: '100%' }}>
      <div className={styles.preview_header}>
        <div className={styles.back_button_header} onClick={handleBack}>
          <ArrowBackIosRoundedIcon />
          <span>Back</span>
        </div>
        <h2 style={{ textAlign: 'center' }}>Document Preview</h2>
      </div>
      <iframe
        onLoad={() => {
          setIsLoadingDocument(false);
        }}
        width="100%"
        height="100%"
        src={`${fileLink}#zoom=FitH`}
        title="file"
        className={cx({ [styles.iframe_hidden]: isLoadingDocument })}
      />
      {isLoadingDocument && (
        <div className={styles.document_loader}>
          <LoaderTailSpin />
          <p>Fetching Document....</p>
        </div>
      )}
    </div>
  );
};
