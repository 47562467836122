import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import createInstance from 'Config/AxiosConfig';
import { Popconfirm } from 'antd';
import React from 'react';
import Button from '@mui/material/Button';
import ClearAllRoundedIcon from '@mui/icons-material/ClearAllRounded';
import { TrayButton } from './components/TrayButton';

export function ClearChatComponent(props) {
  const { isDarkMode, username, active_workspace, getChatHistory } = props;

  const clearChatHistory = async () => {
    try {
      const body = {
        username,
        name: active_workspace.name,
        delete_all: true,
      };
      const instance = await createInstance();
      const result = await instance.post('delete-chat-history', body);
      // check if its success !
      if (result.status === 200) {
        await getChatHistory();
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <Popconfirm
      title="Delete the whole chat history"
      description="Are you sure to delete this chat history ?"
      okText="Yes"
      // visible={visiblePopConfirm}
      cancelText="No"
      onConfirm={() => clearChatHistory(true)}
      style={{ background: isDarkMode ? '#444653' : 'white' }}
    >
      {/* <Button
        style={{
          borderRadius: '1rem',
          background: isDarkMode ? '#444653' : '#F7F7F8',
          height: '40px',
          color: 'red',
          width: screenType === 'LARGE' ? '175px' : '62px',
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
        }}
      >
        {screenType === 'LARGE' && (
          <strong style={{ color: 'red' }}>Clear Chat !</strong>
        )}
        <ClearOutlined
          style={{
            fontSize: '30px',
            color: 'red',
          }}
        />
      </Button> */}

      <TrayButton label={'Clear Chat !'} Icon={ClearAllRoundedIcon} danger />
    </Popconfirm>
  );
}

export default ClearChatComponent;
