/* eslint-disable max-len */
import createInstance from 'Config/AxiosConfig';
import { useQuery } from 'react-query';
import { getReduxUserState } from 'utils/helpers/redux-selectors';
import { INSIGHTS_360_ENDPOINTS } from 'constants/services';

export const getBigQueryData = async (query) => {
  const { email } = getReduxUserState();
  console.log('queryqueryqueryquery', query);

  const instance = await createInstance();
  const response = await instance.get(
    `${INSIGHTS_360_ENDPOINTS?.GET_BIG_QUERY_DATA}?email=${email}&query=${query}`,
  );

  return response?.data?.data;
};

// Keep Query Value as File Name to maintain consistency and uniqueness in query index
export const GET_BIG_QUERY_DATA = 'get-big-query-data';

// Query Config Options
const queryOptions = {
  staleTime: 1 * 60 * 1000,
  enabled: false,
};

// QUERY
export const useGetBigQueryData = (query) =>
  useQuery(
    [GET_BIG_QUERY_DATA, query],
    () => getBigQueryData(query),
    queryOptions,
  );
