/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable import/order */
/* eslint-disable no-else-return */
/* eslint-disable prefer-template */
/* eslint-disable prefer-const */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable dot-notation */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable max-len */
/* eslint-disable quotes */
/* eslint-disable quote-props */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable no-plusplus */
/* eslint-disable react/button-has-type */
/* eslint-disable no-return-assign */
/* eslint-disable no-use-before-define */

import {
  CaretDownOutlined,
  CaretRightFilled,
  CheckCircleOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Form,
  Row,
  Input,
  Select,
  Switch,
  Radio,
  notification,
} from 'antd';
import React, { useEffect, useState } from 'react';
import createInstance from 'Config/AxiosConfig.js';
import { store } from 'redux/store';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { getCodeLanguage } from '../../sharedMobile/utils';
import { queuedWorkspaceSelect } from 'redux/index.js';
import Wave from 'react-wavify';
import { useNavigate } from 'react-router-dom';

const elements = [
  'Athena is diving in...',
  'Athena is decoding...',
  'Athena is on the hunt...',
  'Athena is crafting data...',
  'Athena is mapping minds...',
  'Athena is at work...',
  'Athena is sifting data...',
  'Athena is in a deep dive...',
  'Athena is connecting dots...',
  'Athena is synthesizing...',
  'Athena is data-mining...',
  'Athena is on an insight quest...',
  'Athena is on a data journey...',
  'Athena is crunching...',
  'Athena is forging insights...',
  // Add more elements here
];

const MainForm = (props) => {
  const {
    data,
    setData,
    isStarterFlow,
    setStarterFlow,
    loadingCreatingButton,
    setLoadingCreatingButton,
  } = props;
  const [api, contextHolder] = notification.useNotification();
  // 0 is by default first page..
  const [preparingOnBoarding, setPreparingOnBoarding] = useState(0);
  const [boardingStatus, setBoardingStatus] = useState({});
  const [intervalId, setIntervalId] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const user = useSelector((state) => state?.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % elements.length);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  useEffect(() => {
    // Set the initial value for the select field
    form.setFieldsValue({
      growthSelect: data?.selectedGrowthOption,
      brandSelect: data?.selectedBrandOption,
      keyInteresetSelect: data?.selectedKeyInteresetOption,
      networkingSelect: data?.selectedNetworkingOption,
      productName: data?.productName,
      founderName: data?.founderName,
      linkedinUrl: data?.linkedinValue,
      targetCountry: data?.targetCountry,
      translationEngine: data?.translationEngine,
      CityOfChoice: data?.city,
      language: data?.language,
    });
  }, []);

  useEffect(() => {
    if (
      boardingStatus[0]?.onboarding_status[5]?.current_status === 'complete'
    ) {
      clearInterval(intervalId);
      dispatch(queuedWorkspaceSelect(data?.selectedGrowthOption));
      setLoadingCreatingButton(false);
      setStarterFlow('CHATATHENA');
      navigate('/chat-athena?new_workspace=true');
      // handleSelectWorkspace({ name: data?.selectedGrowthOption }, true);
      // we need to set the new created workspace for chat athena
    }
  }, [boardingStatus[0]?.onboarding_status[5]?.current_status]);

  const getUserFromRedux = () => {
    const state = store.getState();
    return state.user;
  };
  const openNotificationWithIcon = (description) => {
    api['warning']({
      message: 'Missing field',
      description,
    });
  };
  const validateForm = () => {
    let ok = true;
    if (!data.selectedKeyInteresetOption) {
      openNotificationWithIcon('Topic of interest Name is not filled');
      ok = false;
    }
    if (!data.selectedNetworkingOption) {
      openNotificationWithIcon('Target is not filled');
      ok = false;
    }
    if (!data.selectedGrowthOption) {
      openNotificationWithIcon('Campaign Name is not filled');
      ok = false;
    }
    if (!data.selectedBrandOption) {
      openNotificationWithIcon('Company Name is not filled');
      ok = false;
    }
    if (!data.founderName) {
      openNotificationWithIcon('Founder Name is not filled');
      ok = false;
    }
    if (!data.productName) {
      openNotificationWithIcon('Product Name is not filled');
      ok = false;
    }
    if (!data.linkedinValue) {
      openNotificationWithIcon('Domain name is not filled');
      ok = false;
    }
    if (!ok) {
      return false;
    }
    return true;
  };

  const [form] = Form.useForm();
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 10 },
  };
  const getEngineCode = () => {
    if (data.translationEngine === 'Google') {
      return 0;
    }
    if (data.translationEngine === 'DeepL') {
      return 1;
    }
    return 0;
  };
  const prepareBodyForOnBoarding = () => ({
    username: getUserFromRedux()?.username,
    name: data.selectedGrowthOption,
    site: 'https://' + data.linkedinValue,
    fullName: user.name,
    lang: getCodeLanguage(data.language),
    lang_engine: getEngineCode(),
    tags: [],
    country_code: data.targetCountry,
    city: data.city,
    target_persona: data.selectedNetworkingOption,
    product_name: data?.productName,
    founder_name: data.founderName || user.name,
    configurations: {
      level1: {
        sources: ['mrr'],
        urls: [data.linkedinValue],
      },
      level2: {
        sources: ['mrr'],
        urls: [data.linkedinValue],
      },
      level3: {
        sources: ['mrr'],
        urls: [data.linkedinValue],
      },
    },
  });

  const prepareBodyForCreateProject = () => ({
    username: getUserFromRedux()?.username,
    name: data.selectedGrowthOption,
    source_lang: getCodeLanguage(data.language),
    site: 'https://' + data.linkedinValue,
    input_data: [
      {
        question: 'Define your Niche',
        answer: '',
      },
      {
        question: 'Define your Sub-Niche',
        answer: '',
      },
      {
        question: 'Define your Micro-Niche',
        answer: '',
      },
      {
        question: 'Define your Industry Vertical',
        answer: '',
      },
      {
        question: 'Ideal Prospect Overview',
        answer: '',
      },
      {
        question: 'Your Website Domain Name',
        answer: data.linkedinValue,
      },
      {
        question: 'Your Company Legal Name',
        answer: data.selectedBrandOption,
      },
      {
        question: 'Main topic of interest',
        answer: data.selectedKeyInteresetOption,
      },
      {
        question: 'Long Tail Topic 1',
        answer: '',
      },
      {
        question: 'Single Big Shocking Statement',
        answer: '',
      },
      {
        question: 'Single Big Emotional Payoff',
        answer: '',
      },
      {
        question: 'Who you want to target',
        answer: data.selectedNetworkingOption,
      },
    ],
    fullName: user.name,
    default_templates: [],
  });

  const startOnboarding = async () => {
    try {
      // first step we need to call the create-project..
      const instance = await createInstance();
      // check if the required elements are there..
      const ok = validateForm();
      if (ok) {
        setLoadingCreatingButton(true);
        const body = prepareBodyForOnBoarding();
        // alert(JSON.stringify(body));
        const response1 = await instance.post('create-project', body);
        if (response1.data.statusCode === '500') {
          // Workspace already exists
          if (response1.data.body === 'Project Name already exists') {
            openNotificationWithIcon('Workspace already exists !');
            setLoadingCreatingButton(false);
          }
        }
        if (response1.data.statusCode === '200') {
          // we call the onboard-me-athena here..
          const body2 = prepareBodyForCreateProject();
          axios
            .post(
              'https://p8dapk4yf9.execute-api.us-east-2.amazonaws.com/prod/onboard-me-athena',
              body2,
            )
            .catch((error) => {
              console.error(error);
            });

          // checking the status of on boarding me..
          let ii = setInterval(async () => {
            console.log('start interval');
            const response = await instance.post(
              'https://p8dapk4yf9.execute-api.us-east-2.amazonaws.com/prod/get-onboarding-status',
              { name: data.selectedGrowthOption },
            );
            setBoardingStatus(response.data);
            setPreparingOnBoarding(1);
            console.log(`checking status ${JSON.stringify(response)}`);
          }, 7000);
          setIntervalId(ii);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  const renderStatus = (status) => {
    if (status === 'complete') {
      return (
        <div style={{ color: 'green', paddingRight: '5px' }}>
          <Row justify="space-between" style={{ width: '100%' }}>
            <Col>
              <span style={{ marginRight: '8px' }}>Complete</span>
            </Col>
            <Col>
              <CheckCircleOutlined style={{ fontSize: 24 }} spin />
            </Col>
          </Row>
        </div>
      );
    } else if (status === 'pending') {
      return (
        <div style={{ color: 'black', paddingRight: '5px' }}>
          <Row justify="space-between" style={{ width: '100%' }}>
            <Col>
              <span style={{ marginRight: '8px' }}>Pending</span>
            </Col>
            <Col>
              <LoadingOutlined style={{ fontSize: 24 }} spin />
            </Col>
          </Row>
        </div>
      );
    } else if (status === 'failed') {
      return (
        <div style={{ color: 'red', paddingRight: '5px' }}>
          <Row justify="space-between" style={{ width: '100%' }}>
            <Col>
              <span style={{ marginRight: '8px' }}>Failed</span>
            </Col>
            <Col>
              <CloseCircleOutlined style={{ fontSize: 24 }} spin />
            </Col>
          </Row>
        </div>
      );
    }
    return <div> </div>;
  };
  return (
    <div>
      {contextHolder}
      <Row justify="start">
        <Col span={24}>
          <Form
            disabled={loadingCreatingButton}
            {...layout}
            form={form}
            name="control-hooks"
            // onFinish={onFinish}
            // style={{ width: '85vw', color: 'white' }}
          >
            <Row>
              <Col span={12}>
                <Form.Item
                  name="growthSelect"
                  label={
                    <div style={{ color: '#5887FB', fontWeight: 'bold' }}>
                      Campaign Name
                    </div>
                  }
                  rules={[
                    { required: true, message: 'Campaign Name is required' },
                  ]}
                >
                  <Input
                    placeholder="3D Printing"
                    value={data.selectedGrowthOption}
                    onChange={(selectedOption) => {
                      setData({
                        ...data,
                        selectedGrowthOption: selectedOption.target.value,
                      });
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="brandSelect"
                  label={
                    <div style={{ color: '#5887FB', fontWeight: 'bold' }}>
                      Company Name
                    </div>
                  }
                  rules={[
                    { required: true, message: 'Company Name is required' },
                  ]}
                >
                  <Input
                    placeholder="Yeggi"
                    value={data.selectedBrandOption}
                    onChange={(selectedOption) => {
                      setData({
                        ...data,
                        selectedBrandOption: selectedOption.target.value,
                      });
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="keyInteresetSelect"
                  label={
                    <div style={{ color: '#5887FB', fontWeight: 'bold' }}>
                      Main Topic of Interest
                    </div>
                  }
                  rules={[
                    {
                      required: true,
                      message: 'Main Topic of Interest is required',
                    },
                  ]}
                >
                  <Input
                    placeholder="Best 3D printing software"
                    value={data.selectedKeyInteresetOption}
                    onChange={(selectedOption) => {
                      setData({
                        ...data,
                        selectedKeyInteresetOption: selectedOption.target.value,
                      });
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="networkingSelect"
                  label={
                    <div style={{ color: '#5887FB', fontWeight: 'bold' }}>
                      Who you want to target
                    </div>
                  }
                  rules={[{ required: true, message: 'Target is required' }]}
                >
                  <textarea
                    className="expandable-textarea"
                    placeholder="Craft and art students who are looking for new careers in Digital Technology"
                    value={data.selectedNetworkingOption}
                    onChange={(selectedOption) => {
                      setData({
                        ...data,
                        selectedNetworkingOption: selectedOption.target.value,
                      });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="productName"
                  label={
                    <div style={{ color: '#5887FB', fontWeight: 'bold' }}>
                      Product Name
                    </div>
                  }
                  rules={[
                    { required: true, message: 'Product Name is required' },
                  ]}
                >
                  <Input
                    placeholder="Yeggi Polycarbonate Filaments"
                    value={data.productName}
                    onChange={(selectedOption) => {
                      setData({
                        ...data,
                        productName: selectedOption.target.value,
                      });
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="founderName"
                  label={
                    <div style={{ color: '#5887FB', fontWeight: 'bold' }}>
                      Founder Name
                    </div>
                  }
                  rules={[
                    { required: true, message: 'Founder Name is required' },
                  ]}
                >
                  <Input
                    placeholder="Chuck Hull"
                    value={data.founderName}
                    onChange={(selectedOption) => {
                      setData({
                        ...data,
                        founderName: selectedOption.target.value,
                      });
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="linkedinUrl"
                  label={
                    <div style={{ color: '#5887FB', fontWeight: 'bold' }}>
                      Domain Name
                    </div>
                  }
                  rules={[
                    { required: true, message: 'Domain name is required' },
                  ]}
                >
                  <Row align="middle" justify="space-between">
                    <Col span={24}>
                      <Input
                        addonBefore="https://"
                        value={data.linkedinValue}
                        placeholder="yeggi.com/"
                        onChange={(e) => {
                          setData({ ...data, linkedinValue: e.target.value });
                        }}
                      />
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item
                  name="CityOfChoice"
                  label={
                    <div style={{ color: '#5887FB', fontWeight: 'bold' }}>
                      City
                    </div>
                  }
                >
                  <Input
                    placeholder="Chicago"
                    value={data.city}
                    onChange={(e) => setData({ ...data, city: e.target.value })}
                    // defaultValue={'New York'}
                  />
                </Form.Item>
                {data.language !== 'US' && (
                  <Form.Item
                    name="translationEngine"
                    label={
                      <div style={{ color: '#5887FB', fontWeight: 'bold' }}>
                        Translation Engine
                      </div>
                    }
                  >
                    <Radio.Group
                      value={data.translationEngine}
                      onChange={(e) => {
                        setData({ ...data, translationEngine: e.target.value });
                      }}
                      style={{ color: 'white' }}
                    >
                      <Radio value="Google" style={{ color: 'white' }}>
                        Google
                      </Radio>
                      <Radio value="DeepL" style={{ color: 'white' }}>
                        DeepL
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                )}
              </Col>
            </Row>

            <div
              style={{
                height: '10vh',
                // backgroundColor: 'green',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Button
                // type="primary"
                loading={loadingCreatingButton}
                style={{
                  color: 'black',
                  width: '350px',
                  borderRadius: '10px',
                  fontSize: '20px',
                  background: '#4B79FF',
                  marginRight: '20px',
                  height: '65px',
                  fontWeight: 'bold',
                }}
                onClick={startOnboarding}
              >
                Create a New Campaign <br />
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
      {loadingCreatingButton && (
        <>
          <div
            style={{
              color: '#4B79FF',
              width: '100%',
              fontSize: '1.5vw',
              textAlign: 'center',
              fontWeight: 'bold',
            }}
          >
            {elements[currentIndex]}
          </div>
          <div
            style={{
              marginLeft: '5%',
              // marginTop: '7%',
              display: 'flex',
              marginBottom: '40px',
            }}
          >
            <div
              style={{
                fontWeight: 'bold',
                color: '#5887FB',
                fontSize: '20px',
                // marginBottom: '10px',
                width: '100%',
                height: '100px',
                position: 'absolute',
                bottom: 0,
                left: 0,
                zIndex: 100,
              }}
            >
              <Wave
                style={{
                  width: '100%',
                  height: '100%',
                }}
                mask="url(#mask)"
                fill="#5887FB"
                amplitude="30"
                speed="0.35"
                points="4"
              >
                <defs>
                  <linearGradient id="gradient" gradientTransform="rotate(90)">
                    <stop offset="0" stopColor="white" />
                    <stop offset="0.5" stopColor="black" />
                  </linearGradient>
                  <mask id="mask">
                    <rect
                      x="0"
                      y="0"
                      width="100%"
                      height="300"
                      fill="url(#gradient)"
                    />
                  </mask>
                </defs>
              </Wave>
            </div>
            {true && (
              <>
                <div
                  style={{
                    background: '#bbcffe',
                    borderRadius: '10px',
                    width: '170px',
                    // paddingLeft: '25px',
                    marginBottom: '10px',
                    paddingBottom: '5px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginRight: '5px',
                    zIndex: 101,
                  }}
                >
                  <span
                    style={{
                      color: 'white',
                      fontSize: '16px',
                      fontWeight: 'bold',
                    }}
                  >
                    Attract
                  </span>
                  <div
                    style={{
                      color: 'green ',
                      fontWeight: 'bold',
                      marginBottom: '5px',
                    }}
                  >
                    {renderStatus(
                      boardingStatus[0]?.onboarding_status[0]?.current_status,
                    )}
                  </div>
                </div>
                <CaretRightFilled
                  style={{
                    fontSize: '34px',
                    color: '#bbcffe',
                    marginBottom: '10px',
                  }}
                />
              </>
            )}
            {(boardingStatus[0]?.onboarding_status[1]?.current_status ===
              'complete' ||
              boardingStatus[0]?.onboarding_status[0]?.current_status ===
                'complete') && (
              <>
                <div
                  style={{
                    background: '#bbcffe',
                    borderRadius: '10px',
                    width: '170px',
                    // paddingLeft: '25px',
                    marginBottom: '10px',
                    paddingBottom: '5px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginRight: '5px',
                    zIndex: 101,
                  }}
                >
                  <span
                    style={{
                      color: 'white',
                      fontSize: '16px',
                      fontWeight: 'bold',
                    }}
                  >
                    Listen
                  </span>
                  <div
                    style={{
                      color: 'green ',
                      fontWeight: 'bold',
                      marginBottom: '5px',
                    }}
                  >
                    {renderStatus(
                      boardingStatus[0]?.onboarding_status[1]?.current_status,
                    )}
                  </div>
                </div>
                <CaretRightFilled
                  style={{
                    fontSize: '34px',
                    color: '#bbcffe',
                    marginBottom: '10px',
                  }}
                />
              </>
            )}
            {(boardingStatus[0]?.onboarding_status[2]?.current_status ===
              'complete' ||
              boardingStatus[0]?.onboarding_status[1]?.current_status ===
                'complete') && (
              <>
                <div
                  style={{
                    background: '#bbcffe',
                    borderRadius: '10px',
                    width: '170px',
                    // paddingLeft: '25px',
                    marginBottom: '10px',
                    paddingBottom: '5px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginRight: '5px',
                    zIndex: 101,
                  }}
                >
                  <span
                    style={{
                      color: 'white',
                      fontSize: '16px',
                      fontWeight: 'bold',
                    }}
                  >
                    Desire
                  </span>
                  <div
                    style={{
                      color: 'green ',
                      fontWeight: 'bold',
                      marginBottom: '5px',
                    }}
                  >
                    {renderStatus(
                      boardingStatus[0]?.onboarding_status[2]?.current_status,
                    )}
                  </div>
                </div>
                <CaretRightFilled
                  style={{
                    fontSize: '34px',
                    color: '#bbcffe',
                    marginBottom: '10px',
                  }}
                />
              </>
            )}
            {(boardingStatus[0]?.onboarding_status[3]?.current_status ===
              'complete' ||
              boardingStatus[0]?.onboarding_status[2]?.current_status ===
                'complete') && (
              <>
                <div
                  style={{
                    background: '#bbcffe',
                    borderRadius: '10px',
                    width: '170px',
                    // paddingLeft: '25px',
                    marginBottom: '10px',
                    paddingBottom: '5px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginRight: '5px',
                    zIndex: 101,
                  }}
                >
                  <span
                    style={{
                      color: 'white',
                      fontSize: '16px',
                      fontWeight: 'bold',
                    }}
                  >
                    Learn
                  </span>
                  <div
                    style={{
                      color: 'green ',
                      fontWeight: 'bold',
                      marginBottom: '5px',
                    }}
                  >
                    {renderStatus(
                      boardingStatus[0]?.onboarding_status[3]?.current_status,
                    )}
                  </div>
                </div>
                <CaretRightFilled
                  style={{
                    fontSize: '34px',
                    color: '#bbcffe',
                    marginBottom: '10px',
                  }}
                />
              </>
            )}
            {(boardingStatus[0]?.onboarding_status[4]?.current_status ===
              'complete' ||
              boardingStatus[0]?.onboarding_status[3]?.current_status ===
                'complete') && (
              <>
                <div
                  style={{
                    background: '#bbcffe',
                    borderRadius: '10px',
                    width: '170px',
                    // paddingLeft: '25px',
                    marginBottom: '10px',
                    paddingBottom: '5px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginRight: '5px',
                    zIndex: 101,
                  }}
                >
                  <span
                    style={{
                      color: 'white',
                      fontSize: '16px',
                      fontWeight: 'bold',
                    }}
                  >
                    Convert
                  </span>
                  <div
                    style={{
                      color: 'green ',
                      fontWeight: 'bold',
                      marginBottom: '5px',
                    }}
                  >
                    {renderStatus(
                      boardingStatus[0]?.onboarding_status[4]?.current_status,
                    )}
                  </div>
                </div>
                <CaretRightFilled
                  style={{
                    fontSize: '34px',
                    color: '#bbcffe',
                    marginBottom: '10px',
                  }}
                />
              </>
            )}
            {(boardingStatus[0]?.onboarding_status[5]?.current_status ===
              'complete' ||
              boardingStatus[0]?.onboarding_status[4]?.current_status ===
                'complete') && (
              <div
                style={{
                  background: '#bbcffe',
                  borderRadius: '10px',
                  width: '170px',
                  // paddingLeft: '25px',
                  marginBottom: '10px',
                  paddingBottom: '5px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginRight: '5px',
                  zIndex: 101,
                }}
              >
                <span
                  style={{
                    color: 'white',
                    fontSize: '16px',
                    fontWeight: 'bold',
                  }}
                >
                  Triage
                </span>
                <div
                  style={{
                    color: 'green ',
                    fontWeight: 'bold',
                    marginBottom: '5px',
                  }}
                >
                  {renderStatus(
                    boardingStatus[0]?.onboarding_status[5]?.current_status,
                  )}
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default MainForm;
