/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import NoDataAvailable from 'Component/Shared/NoDataResultSection';
import { QuestionSection } from './question_Section';
import { AiContent } from '../contexts/ai_content.js';
import LoaderSpin, { LoaderTailSpin } from '../../../Shared/Loader';
import QueAns from '../../../Shared/QueAns/QueAns';
import { getTranslatedTextEnglish } from '../../../Shared/TranshelperStatic';
import { getTranslatedTextArray } from '../../MarketReasearch/MarketResearchAndResonance/MarketResearchCategory/fILLAiLangTrans';

export function QuestionCollections(props) {
  const { aiContent, setaiContent } = useContext(AiContent);
  const [questions, setQuestions] = useState([]);
  const [isFetched, setisFetched] = useState(false);

  function copyClipboard(text) {
    const paragraph = document.createElement('textarea');
    document.body.appendChild(paragraph);
    const span_ = document.createElement('span');
    span_.innerHTML = text.text;
    paragraph.value = span_.innerText;

    paragraph.select();
    document.execCommand('copy');
    document.body.removeChild(paragraph);
  }
  useEffect(() => {
    getQuestions();
  }, []);

  const getQuestions = async () => {
    try {
      const { data } = await axios.post(
        'https://apis.crawlq.ai/ai-content-questions',
        {
          q: await getTranslatedTextEnglish(
            localStorage.getItem('questionTopic'),
          ),
        },
      );
      const translatedQue = await getTranslatedTextArray(data.data.questions);
      setQuestions(translatedQue);
    } catch (ex) {
      console.log('Error in Getting Question ', ex);
    } finally {
      setisFetched(true);
    }
  };

  if (typeof aiContent === 'undefined') {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '2.8rem',
        }}
      >
        <LoaderTailSpin />
      </div>
    );
  }
  return (
    <div className="border-rounded-light">
      <div className="">
        <div className="d-flex mb-2">
          <span className="left">
            <img
              src="https://s2.googleusercontent.com/s2/favicons?domain=https://www.quora.com/"
              width="24"
            />
            <span className="ml-1th">Questions</span>
          </span>
        </div>
        {isFetched ? (
          questions.map((inf) => (
            <QueAns question={inf} copyClipboard={copyClipboard} />
          ))
        ) : (
          <LoaderSpin width="30" height="30" />
        )}
        {isFetched && questions.length === 0 && <NoDataAvailable />}
      </div>
    </div>
  );
}
export default QuestionCollections;
