import 'assets/constants.css';
import styled from 'styled-components';

export const SecondaryButton = styled.button`
  background-color: var(--secondary-backdrop-color);
  color: var(--secondary-color);
  padding: 0.2rem;
  border-radius: 2px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;
