import React from 'react';
import { ContentProvider } from 'context/content/ContentContext';
import Content from './Content';

function ContentWrapper() {
  return (
    <ContentProvider>
      <Content />
    </ContentProvider>
  );
}

export default ContentWrapper;
