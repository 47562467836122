export const MARKET_RESEARCH_ENDPOINTS = {
  GET_MARKET_CATEGORIES: 'subcategory?section',
  GET_MARKET_CATEGORY_QUESTION: 'load-questions?subcategory',
  GET_MARKET_HELPER: '',
  POST_FILL_WITH_AI: 'mrr-ai-fill',
  POST_INSIGHTS_SAVE: 'mrr-insights-save',
  POST_FETCH_INSIGHTS: 'mrr-insights',
  POST_SAVE_QUESTIONS_ANSWERS: 'save-questions',
};

export const INSIGHTS_360_ENDPOINTS = {
  GET_BIG_QUERY_DATA: 'get-bigquery-data',
  GET_GENERATED_SQL: 'get-generated-sql',
};
