import React from 'react';

const formattedDate = (timestamp) => {
  const dateValue = new Date(timestamp * 1000).toDateString();
  return dateValue;
};

const CancellationMessage = ({
  addOnName,
  cancellationDate,
  planEndingDate,
}) => (
  <div className="cancellation_message">
    <p>
      {addOnName} was cancelled at{' '}
      <b>{formattedDate(planEndingDate)}</b>, Will Expire on{' '}
      <b>{formattedDate(cancellationDate)}</b>
    </p>
  </div>
);

export default CancellationMessage;
