import React from 'react';
import AppHeaderWrapper from 'components/common/AppHeader/AppHeaderWrapper';
import Profile from 'Component/Pages/Profile/Profile';
import styles from './user-profile.module.css';

function UserProfile() {
  return (
    <AppHeaderWrapper className={styles.user_profile_wrapper}>
      <div className={styles.user_profile_container}>
        {/* <HeaderCard title="Profile" description="" /> */}
        <div className={styles.user_profile_content_container}>
          <Profile />
        </div>
      </div>
    </AppHeaderWrapper>
  );
}

export default UserProfile;
