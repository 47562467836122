/* eslint-disable default-param-last */
import { SAVE_KEYWORD } from './KeywordsTypes';

const keywordInitialState = {

  userKeyword: [],
};

const keywordReducer = (state = keywordInitialState, action) => {
  switch (action.type) {
    case SAVE_KEYWORD:
      return {
        ...state,
        [action.payload[0]]: action.payload[1],
      };

    default:
      return state;
  }
};

export default keywordReducer;
