import React, { useState } from 'react';
import { useGetMarketGlobal } from 'queries/get-market-global';
import { MarketCategoryIcon } from '../../styles';
import FredQA from './FredQA';

import styles from './fred-market.module.css';

function MarketResearch() {
  const { data: globalMarketData, isLoading } = useGetMarketGlobal();

  return (
    <div className={styles.wrapper}>
      {globalMarketData && globalMarketData.map((marketData, index) => (
        <div key={index}>
          <MarketCategoryIcon />
          <span className={styles.market_category}>{marketData?.sub_category}</span>
          <FredQA qnas={marketData?.mrqna} />
        </div>
      ))}
    </div>
  );
}

export default MarketResearch;
