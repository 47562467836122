export const ASSISTANT_HEADER_TITLE = {
  CREATE_ASSISTANT: 'Create a new Assistant',
  EDIT_ASSISTANT: 'Edit Assistant',
  ASSISTANTS_MANAGER: 'Assistants Manager',
};

export const DISPLAY_ASSISTANTS_TABLE_HEADER = [
  'Name',
  'Description',
  'Author',
  'Created On',
  'Actions',
];

export const ASSISTANTS_VALID_FILES_TYPE =
  '.csv, .xls, xlsx, .c, .cs, .cpp, .doc, .docx, .html, .java, .json, .md, .pdf, .php, .pptx, .py, .rb, .tex, .txt, .css, .js, .sh, .ts,';
