/* eslint-disable brace-style */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import { makeStyles } from '@mui/styles';
import Axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BarLoader from 'react-spinners/PulseLoader';
import LoaderSpin from '../Shared/Loader';

const useStyles = makeStyles({
  root: {
    '&:hover $notchedOutline': {
      opacity: '0.5',
      borderRadius: '5px',
      borderColor: '#ffc600',
    },

  },
  notchedOutline: {},
  welcomeBtn: {
    width: '130px',
    objectFit: 'contain',
    border: 'solid 1px #ffffff',
    backgroundColor: '#d8ac1c',
    borderRadius: '50px',
    color: '#2d2d2d',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
  },
});

function Register() {
  const navigate = useNavigate();

  const [users, setUsers] = useState({
    email: '',
    password: '',
    appsumocode: '',
    firstName: '',
    lastName: '',
  });
  const [SignUpSuccess, setSignUpSucess] = useState(true);

  const [nameErr, setNameErr] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onRegisterSubmit = async (event) => {
    event.preventDefault();

    const {
      firstName, email, password, appsumocode, lastName,
    } = users;

    if (
      users.password.trim() === ''
      || users.email.trim() === ''
      || users.firstName.trim() === ''
    ) {
      setIsLoading(false);
      setNameErr('Fields can not be empty');
    } else if (!users.email.match(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/)) {
      setIsLoading(false);
      setNameErr('Invalid Email Address');
    }
    // else if (
    //     !users.password.match(
    //         /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@$#!%*?&]{8,}$/
    //     )) {
    //     setNameErr(
    //         "Password Should Contain Atleast 8 Character,One Uppercase,One LowerCase,One Special Character  "
    //     );
    // }
    else {
      const name = `${firstName} ${lastName}`;

      const body = {
        email,
        Name: name,
        Password: password,
        code: appsumocode,
      };

      pushData('https://apis.crawlq.ai/appsumo-sign-up', body);
    }
  };

  const pushData = (url, body) => {
    const checkboxTerms = document.getElementById('checkboxTerms');

    if (checkboxTerms.checked) {
      setIsLoading(true);
      Axios.post(url, body)
        .then((res) => {
          checkSucessful(res);
        })
        .catch((err) => {
          // console.log(err)
        })
        .finally(() => {
          setIsLoading(true);
        });
    } else {
      setNameErr('Please accept terms and conditions to proceed further.');
    }
  };

  const checkSucessful = (res) => {
    if (res.data.result) {
      setSignUpSucess(false);
      setIsLoading(false);
    } else {
      setNameErr(res.data.message);
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setNameErr('');
    setUsers({
      ...users,
      [name]: value,
    });
  };

  function SignUpContainer() {
    return (
      <form onSubmit={onRegisterSubmit} className="loginfrm">
        <div className="row middleflx">
          <div className="col-sm-6">
            <div className="form-wrap">
              <h2 className="title">Welcome</h2>

              <div className="input-field">
                <input
                  type="text"
                  name="firstName"
                  className="input"
                  value={users.firstName}
                  onChange={handleInput}
                  placeholder="First Name"
                />
              </div>
              <div className="input-field">
                <input
                  type="text"
                  name="lastName"
                  className="input"
                  value={users.lastName}
                  onChange={handleInput}
                  placeholder="Last Name"
                />
              </div>
              <div className="input-field">
                <input
                  type="email"
                  name="email"
                  className="input"
                  value={users.email}
                  onChange={handleInput}
                  placeholder="Email"
                />
              </div>
              <div className="input-field">
                <input
                  type="password"
                  name="password"
                  className="input"
                  value={users.password}
                  onChange={handleInput}
                  placeholder="Password"
                />
              </div>

              <div className="input-field">
                <input
                  type="text"
                  name="appsumocode"
                  className="input"
                  value={users.appsumocode}
                  onChange={handleInput}
                  placeholder="Enter Code"
                />
                <div
                  style={{
                    margin: '0',
                    fontSize: '14px',
                    display: 'flex',
                  }}
                >
                  <input
                    style={{ margin: '0', marginTop: '4px', width: '10%' }}
                    value="1"
                    id="checkboxTerms"
                    type="checkbox"
                  />
                  <label style={{ margin: '0' }}>
                    I agree to the&nbsp;
                    <a
                      target="_blank"
                      href="https://crawlq.ai/terms-and-conditions/"
                      rel="noreferrer"
                    >
                      Terms and Conditions&nbsp;
                    </a>
                    and the
                    <a target="_blank" href="https://crawlq.ai/privacy-policy/" rel="noreferrer">
                      &nbsp;Privacy Policy&nbsp;
                    </a>
                  </label>
                </div>
              </div>
              <div className="register-btn" style={{ marginTop: '3rem' }}>
                {isLoading ? (
                  <LoaderSpin height="30" width="30" />
                ) : (
                  <button type="submit" className="btnn solid">
                    Sign Up
                  </button>
                )}

                <div>
                  <p style={{ color: 'red', marginTop: 20 }}>{nameErr}</p>
                </div>
                <div
                  style={{
                    marginTop: '1px',
                    justifyContent: 'center',
                    display: 'flex',
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="right-logo" style={{ marginTop: '13%' }}>
              <img src="/img/crawlQ.png" />
            </div>
          </div>
        </div>
      </form>
    );
  }

  function SignUpSuccessfulContainer() {
    return (
      <form onSubmit={onRegisterSubmit} className="loginfrm">
        <div className="row middleflx">
          <div className="col-sm-6">
            <div className="form-wrap">
              <h2 className="title">Hey</h2>
              <p style={{ fontSize: '14px' }}>
                <strong>Step 1.</strong>
                {' '}
                You will receive an email with your
                login credentials.
              </p>
              <p style={{ fontSize: '14px' }}>
                <strong>Step 2.</strong>
                {' '}
                <a href="/"> Login</a>
                {' '}
                and search you
                business idea in our Marketplace or create your own workspace.
              </p>
              <p style={{ fontSize: '14px' }}>
                <strong>Step 3.</strong>
                {' '}
                Please join our
                <a
                  target="_blank"
                  href="https://www.facebook.com/groups/crawlq"
                  rel="noreferrer"
                >
                  &nbsp;Facebook Group.
                </a>
              </p>
              <p style={{ fontSize: '14px' }}>
                <strong>Step 4.</strong>
                {' '}
                Check out the latest tutorial videos
                <a
                  target="_blank "
                  href="https://www.youtube.com/watch?v=-RxuWS7DTxs&list=PLQD-WFAGuYO8-dBVclHip9yharaw-oYDq"
                >
                  &nbsp;here
                </a>
                .
              </p>
              <p style={{ fontSize: '14px' }}>
                <strong>Step 5.</strong>
                {' '}
                Check out the latest webinar videos
                <a
                  target="_blank "
                  href="https://www.youtube.com/watch?v=TAN6aBva7Uo&list=PLQD-WFAGuYO_B8Imvt7t9Uk7G-XLw7EmZ"
                >
                  &nbsp;here
                </a>
                .
              </p>

              <p style={{ marginTop: '40px' }}>
                Thank you so much for choosing us.
              </p>
              <p>Happy to have you on board.</p>

              <p style={{ fontWeight: 'bold' }}> ❤️ CrawlQ Team</p>

              <div
                style={{
                  backgroundColor: '#99FF99',
                  height: '50px',
                  borderRadius: '8px',
                  border: '1px dashed green',
                }}
              >
                <p
                  style={{
                    color: 'darkgreen',
                    fontSize: '14px',
                    padding: '12px',
                  }}
                >
                  Check your mail to get login credentials and
                  <a href="/">&nbsp;LOGIN HERE</a>
                </p>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="right-logo">
              <img src="/img/crawlQ.png" />
            </div>
          </div>
        </div>
      </form>
    );
  }

  return (
    <div className="auth-container">
      <div className="container-wrap">
        <div className="panels-container">
          <div className="panel left-panel">
            <img src="img/appsumo.png" className="image" alt="" />
          </div>
          {/* {registermodal()} */}
          <div className="signin-signup">
            {SignUpSuccess ? SignUpContainer() : SignUpSuccessfulContainer()}
          </div>
        </div>
      </div>
      <BarLoader
        size={20}
        margin={2}
        color="#2C3E50"
        loading={isLoading}
        css={`
          position: absolute;
          top: 63%;
          left: 70%;
          border-color: #f5b848;
          z-index: '6';
        `}
      />
    </div>
  );
}

export default Register;
