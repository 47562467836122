import LoaderSpin from 'Component/Shared/Loader';
import React from 'react';

const ValidatingUserLoader = ({ isDarkMode }) => (
  <div
    style={{
      backgroundColor: isDarkMode ? '#343541' : 'white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'center',
      height: '100vh',
      width: '100%',
    }}
  >
    <LoaderSpin />
    <p
      style={{
        color: !isDarkMode ? '#343541' : 'white',
      }}
    >
      Fetching info...
    </p>
  </div>
);

export default ValidatingUserLoader;
