import React from 'react';
import DualActionView from 'components/common/DualActionView';
import Modal from 'react-modal';
import PropTypes from 'prop-types';

const ModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width: '500px',
    height: 'max-content',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'red',
  },
};

function DeleteAssistantModal({
  isOpen,
  handleClose,
  handleDelete,
  isDeleting,
}) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => handleClose()}
      style={ModalStyles}
      ariaHideApp={false}
    >
      <DualActionView
        headingText="Delete Assistant"
        primaryBtnText="Delete"
        secondaryBtnText="Cancel"
        primaryAction={handleDelete}
        processing={isDeleting}
        secondaryAction={handleClose}
      >
        <p className="h-margin-b-tiny">
          {' '}
          Assistant will be deleted permanently.
        </p>
        <p />
      </DualActionView>
    </Modal>
  );
}

DeleteAssistantModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool.isRequired,
};

export default DeleteAssistantModal;
