import { store } from 'redux/store';

const getMTI = () => {
  const state = store.getState();
  const { workspace } = state;
  return workspace?.mti;
};

export const getPhotosFromPexel = async () => {
  const orientation = 'portait';
  try {
    const mti = getMTI();
    const res = await fetch(
      `https://api.pexels.com/v1/search?query=${mti}&orientation=${orientation}&per_page=${80}`,
      {
        headers: {
          Authorization:
            'yuTdazo6oCYLuvLfoaRDyGqPw8iLUW1XyBodCH0NAixTXnWLbIaX3w65',
        },
      },
    );
    const data = await res.json();
    const photos = data.photos.map((photo) => photo.src.tiny);
    return photos;
  } catch (ex) {
    console.log('Cannot Load photo from pexel');
    return [];
  }
};

export const getPhotoFromPexel = async (query) => {
  const orientation = 'landscape';
  try {
    const mti = getMTI();
    const res = await fetch(
      `https://api.pexels.com/v1/search?query=${query}&orientation=${orientation}&per_page=${1}`,
      {
        headers: {
          Authorization:
            'yuTdazo6oCYLuvLfoaRDyGqPw8iLUW1XyBodCH0NAixTXnWLbIaX3w65',
        },
      },
    );
    const data = await res.json();
    const photos = data.photos.map((photo) => photo.src.tiny);
    return photos;
  } catch (ex) {
    console.log('Cannot Load photo from pexel');
    return [];
  }
};
