import React, { useEffect, useState } from 'react';
import InputTextFeild from 'Component/Shared/InputTextFeild/InputTextFeild';
import { ButtonPrimary, ButtonSecondary } from 'Component/Shared/GlobalStyles';
import LoaderSpin from 'Component/Shared/Loader';
import { useQueryClient } from 'react-query';
import { GET_INTEGRATED_APPS_KEY } from 'queries/chat-athena/get-saved-app-integrations';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import { isValidURL } from 'Component/Shared/utils';
import styles from '../styles.module.css';
import {
  getViewAndPayloadFormat,
  saveAppIntegrationCreds,
  updagradeChatAthenaToPro,
} from '../service';
import SelectDropdown from './SelectComponent';
import useIsProActive from '../../../hooks/useIsProActive';

const MAX_URLS_ALLOWED = 5;
const MAX_URLS_ALLOWED_IN_NON_PRO_ACCOUNT = 2;

const APPS_LIST = {
  trello: 'Trello',
  youtube: 'YouTube',
};

const AddAppCreds = ({ setTabId }) => {
  const queryClient = useQueryClient();
  const isProActivated = useIsProActive();

  const [creds, setCreds] = useState({});
  const [displayName, setDisplayName] = useState('');
  const [toolName, setToolName] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [view, setView] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [fetchingUpgradeLink, setFetchingUpgradeLink] = useState(false);
  const [errorsInURL, setErrorsInURls] = useState(false);

  useEffect(() => {
    if (!toolName) return;

    setIsLoading(true);
    setView(null);

    getViewAndPayloadFormat(toolName)
      .then((data) => {
        setView(data);
        let reqFormat = data.request_format;
        if (toolName === 'youtube') {
          reqFormat = { urls: [''] };
        }
        setCreds(reqFormat);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [toolName]);

  const isValidUrls = (urls) => {
    if (!urls) {
      return false;
    }
    if (!Array.isArray(urls)) {
      return false;
    }
    if (!urls.every((url) => isValidURL(url))) {
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (creds?.urls && !isValidUrls(creds?.urls)) {
      setErrorsInURls(true);
      return;
    }

    setIsSaving(true);

    await saveAppIntegrationCreds(creds, {
      displayName: `${APPS_LIST[toolName]} - ${displayName}`,
      toolName,
    });
    queryClient.invalidateQueries(GET_INTEGRATED_APPS_KEY);
    setIsSaving(false);
    setTabId(0);
  };

  const isMaxURLsLimitCrossed = () => {
    if (isProActivated) return false;
    if (creds?.urls?.length < MAX_URLS_ALLOWED_IN_NON_PRO_ACCOUNT) {
      return false;
    }
    return true;
  };

  const handleUpgrade = async () => {
    setFetchingUpgradeLink(true);
    await updagradeChatAthenaToPro();
    setFetchingUpgradeLink(false);
  };

  const addUrlInput = () => {
    if (isMaxURLsLimitCrossed()) {
      handleUpgrade();
      return;
    }
    setCreds((prev) => ({
      ...prev,
      urls: [...prev.urls, ''], // Added an empty string for a new URL input
    }));
  };

  const handleRemoveURLS = (index) => {
    const { urls } = creds;
    if (urls?.length <= 1) return;

    // Create a new array of URLs without the one at the specified index
    const newUrls = urls.filter((url, i) => i !== index);

    // Update the state with the new array of URLs
    setCreds((prev) => ({
      ...{ ...prev, urls: newUrls },
    }));
  };

  const renderYoutubeInputFeilds = () => (
    <div>
      {(creds?.urls ?? []).map((url, index) => (
        <div className={styles.input_group}>
          <div className={styles.input_url}>
            <InputTextFeild
              label={`URL ${index + 1}`}
              value={url}
              onChange={(e) => {
                const { value } = e.target;
                setErrorsInURls(false);
                setCreds((prev) => ({
                  ...prev,
                  urls: prev.urls.map((u, i) => (i === index ? value : u)),
                }));
              }}
            />
          </div>
          <HighlightOffRoundedIcon
            style={{
              color: creds.urls.length > 1 ? 'red' : 'grey',
              cursor: 'pointer',
            }}
            onClick={() => handleRemoveURLS(index)}
          />
        </div>
      ))}
      {errorsInURL && (
        <p className="text-danger">Some Links are invalid please remove them</p>
      )}
      {creds?.urls?.length < MAX_URLS_ALLOWED && (
        <ButtonSecondary style={{ width: '100%' }} onClick={addUrlInput}>
          {!isMaxURLsLimitCrossed() ? 'Add More URL' : 'Upgrade to pro'}
          {fetchingUpgradeLink && <LoaderSpin width={'25'} height={'25'} />}
        </ButtonSecondary>
      )}
    </div>
  );

  const renderTrelloInputFields = () =>
    view?.view_format.map((format) => {
      switch (format.type) {
        case 'input':
          return (
            <InputTextFeild
              key={format.key}
              label={format.label}
              onChange={(e) => {
                const { value } = e.target;
                setCreds((prev) => ({ ...prev, [format.key]: value }));
              }}
            />
          );
        case 'button':
          return (
            <ButtonSecondary color="white" variant="outlined">
              Add URL
            </ButtonSecondary>
          );
        default:
          return null;
      }
    });

  return (
    <div className={styles.container}>
      <SelectDropdown
        options={[
          {
            tool_name: 'trello',
            tool_label: 'Trello',
          },
          {
            tool_name: 'youtube',
            tool_label: 'YouTube',
          },
        ]}
        value={toolName ?? ''}
        setValue={setToolName}
      />

      {isLoading ? (
        <div className={styles.all_documents}>
          <LoaderSpin />
          <p>Loading Apps...</p>
        </div>
      ) : null}

      {toolName && view ? (
        <>
          <h3>Enter Details:</h3>
          <div className={styles.form_wrapper}>
            <InputTextFeild
              label={'Display Name'}
              onChange={(e) => {
                const { value } = e.target;
                setDisplayName(value);
              }}
            />
            {toolName === 'trello' && renderTrelloInputFields()}
            {toolName === 'youtube' && renderYoutubeInputFeilds()}
          </div>
          <div className={styles.form_wrapper}>
            {isSaving ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <LoaderSpin />
              </div>
            ) : (
              <ButtonPrimary onClick={handleSubmit}>
                Complete Integration
              </ButtonPrimary>
            )}
          </div>
          <hr />
          <h4>How to get started</h4>
          <div dangerouslySetInnerHTML={{ __html: view?.steps }} />
        </>
      ) : null}
    </div>
  );
};

export default AddAppCreds;
