import React, { useState, useEffect } from 'react';
import LoaderSpin from 'Component/Shared/Loader';
import { useGetChatHistory } from 'queries/chat-athena/get-chat-history';
import { useIsMobile } from 'Component/hooks/useIsMobile';
import styles from './index.module.css';
import ChatBoxComponent from '../../ChatBox';
import { IntroductionComponent } from '../../IntroductionComponent';
import ScrollToBottomButton from '../ScrollToBottomButton';

const DEFAULT_PAZE_SIZE = 5;

const ChatHistoryView = (props) => {
  const {
    chatContainerRef,
    isDarkMode,
    selectedAnswer,
    selectAnswer,
    sendMessage,
    currentPageSize,
    setCurrentPageSize,
  } = props;

  const isMobile = useIsMobile();

  const {
    data: chatHistory,
    isLoading: isLoadingChatHistory,
    isFetching: isLoadingMore,
    refetch: refetchChatHistory,
  } = useGetChatHistory(currentPageSize);

  const [atBottom, setAtBottom] = useState(false);

  useEffect(() => {
    const container = chatContainerRef.current;

    if (container && currentPageSize === 5) {
      container.scrollTo({
        top: container.scrollHeight - container.clientHeight,
        behavior: 'smooth',
      });
      const handleScroll = () => {
        const isAtBottom =
          container.scrollHeight - container.scrollTop <=
          container.clientHeight;
        setAtBottom(isAtBottom);
      };
      handleScroll();
      container.addEventListener('scroll', handleScroll);

      return () => {
        container.removeEventListener('scroll', handleScroll);
      };
    }
    return null;
  }, [chatHistory?.pairs, isLoadingChatHistory]);

  return (
    <div ref={chatContainerRef} className={styles.chat_pair_wrapper}>
      {isLoadingChatHistory ? (
        <div className={styles.loader_chat_box}>
          <LoaderSpin />
          <p
            style={{
              color: !isDarkMode ? '#343541' : 'white',
            }}
          >
            Loading Chat....
          </p>
        </div>
      ) : (
        <div className={styles.chat_box_grp}>
          {chatHistory?.pairs?.length > 0 && (
            <div>
              {!(chatHistory?.pairs?.length === chatHistory?.max_length) &&
                (!isLoadingMore ? (
                  <button
                    type="submit"
                    className={styles.load_more}
                    onClick={() => {
                      setCurrentPageSize((prev) => prev + DEFAULT_PAZE_SIZE);
                      refetchChatHistory();
                    }}
                  >
                    Load Previous Chat
                  </button>
                ) : (
                  <LoaderSpin />
                ))}
            </div>
          )}
          {(chatHistory?.pairs ?? []).map((element) => (
            <ChatBoxComponent
              key={element.id}
              index={element.index}
              answerId={element.id}
              isDarkMode={isDarkMode}
              isMobile={isMobile}
              element={element}
              selectedAnswer={selectedAnswer}
              selectAnswer={selectAnswer}
            />
          ))}
        </div>
      )}
      {chatHistory?.pairs?.length === 0 && !isLoadingChatHistory && (
        <IntroductionComponent sendMessage={sendMessage} />
      )}
      {!atBottom && chatContainerRef && (
        <ScrollToBottomButton element={chatContainerRef?.current} />
      )}
    </div>
  );
};

export default ChatHistoryView;
