/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import React from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { withStyles, styled } from '@mui/styles';

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className ?? '' }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: 'white',
    backgroundColor: 'rgb(7 7 7 / 95%)',
    fontSize: '12px',
    fontWeight: 'normal',
    letterSpacing: '1px',
    padding: '10px 15px',
    textAlign: 'justify',
    cursor: 'pointer !important',
  },
}));

// const CustomTooltip = withStyles({
//   tooltip: {
//     color: 'white',
//     backgroundColor: 'rgb(7 7 7 / 95%)',
//     fontSize: '12px',
//     fontWeight: 'normal',
//     letterSpacing: '1px',
//     padding: '10px 15px',
//     textAlign: 'justify',
//     cursor: 'pointer !important',
//   },
// })(Tooltip);

export const CustomTooltipMTI = withStyles({
  tooltip: {
    color: 'white',
    backgroundColor: 'rgb(42, 157, 245)',
    fontSize: '14px',
    fontWeight: 'bold',
    letterSpacing: '1px',
    padding: '10px 15px',
    textAlign: 'justify',
  },
})(Tooltip);

export const CustomTooltipLight = withStyles({
  tooltip: {
    color: 'white',
    borderRadius: '0.3rem',
    width: 320,
    backgroundColor: 'black',
    boxShadow: '0 0 5px #dedede',
    fontSize: '0.8rem',
    letterSpacing: '1px',
    padding: '10px 15px',
    textAlign: 'justify',
  },
})(Tooltip);

export default CustomTooltip;
