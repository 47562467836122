/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React from 'react';
import { useIsMobile } from 'Component/hooks/useIsMobile';
import Topnav from '../Sidenav/Topnav';
import Topbar from '../Topbar/Topbar';
import { ChildrenWrapper } from './style';

export default function BaseLayout({ children }) {
  const isMobile = useIsMobile();

  return (
    <>
      {!isMobile && <Topnav />}
      <Topbar />
      <ChildrenWrapper>{children}</ChildrenWrapper>
    </>
  );
}
