import { Auth } from 'aws-amplify';

export const loginAnonymous = async (name, email) => {
  const response = await Auth.signIn({
    username: email,
    password: 'FREEMIUM_CRAWLQ_@23',
  });

  return {
    name,
    email: response?.attributes?.email,
    jwt: response?.signInUserSession?.accessToken?.jwtToken,
  };
};
