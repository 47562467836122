import React, { useState } from 'react';
import { useGetTeamMembers } from 'queries/members/get-members';
import { getMembersWithRole } from 'queries/members/helpers';
import { makeStyles } from '@mui/styles';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { useMutateShareWorkspace } from 'queries/workspace/share-workspace';
import { LoaderTailSpin } from 'components/common/Loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import styles from './share-action.module.css';

const useStyles = makeStyles(() => ({
  formControl: {
    margin: '4px',
    width: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    color: 'var(--default-text-color)',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: '16px',
  },
}));

const ITEM_HEIGHT = 40;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5,
      width: 300,
    },
  },
};

function ShareAction({ workspaceName, toggleClose }) {
  const classes = useStyles();
  const { data: members, isLoading } = useGetTeamMembers();

  const {
    mutateAsync: shareMutate,
    isLoading: isSharingInProcess,
  } = useMutateShareWorkspace();

  const membersWithRole = getMembersWithRole(members ?? []);

  console.log(membersWithRole);

  const [selectedFeilds, setSelectedFeilds] = useState([]);
  const handleChange = (event) => {
    const newValues = event.target.value;
    setSelectedFeilds([...newValues]);
  };

  const handleShareCampaign = async () => {
    await shareMutate({
      name: workspaceName,
      destination_emails: selectedFeilds,
    });

    toast.success(`${workspaceName} shared`, {
      position: 'bottom-center',
      theme: 'dark',
    });

    setTimeout(() => {
      toggleClose();
    }, 2000);
  };

  return (
    <div className={styles.container}>
      <ToastContainer />
      <div className={styles.header_wrapper}>
        <span className={styles.header_text}>Share With Team Member</span>
      </div>
      <div className={styles.content_wrapper}>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-mutiple-checkbox-label">
            Select Team Members{' '}
          </InputLabel>
          <Select
            style={{ color: 'white' }}
            labelId="demo-mutiple-checkbox-label"
            id="demo-mutiple-checkbox"
            multiple
            value={selectedFeilds}
            onChange={handleChange}
            input={<Input />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={MenuProps}
          >
            {membersWithRole &&
              membersWithRole.map(
                (option, _index) =>
                  option?.scope !== 'Admin' && (
                    <MenuItem key={option.name + _index} value={option.email}>
                      <Checkbox
                        checked={selectedFeilds.indexOf(option.email) > -1}
                      />
                      <ListItemText primary={option.email} />
                    </MenuItem>
                  ),
              )}
          </Select>
        </FormControl>

        <div className={styles.actions_container}>
          <div className={styles.action_button} onClick={() => toggleClose()}>
            <button type="button" className={styles.cancel}>
              Cancel
            </button>
          </div>
          <div className={styles.action_button} onClick={handleShareCampaign}>
            {isSharingInProcess ? (
              <LoaderTailSpin width={20} />
            ) : (
              <button type="button" className={styles.confirm}>
                Confirm
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShareAction;
