/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import React, { useState } from 'react';
import './UserWorkspaceDelete.css';

// Component
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import LoaderSpin from '../../../../../Shared/Loader';

/* Logic Functions */
import deleteWorkspaceRequest from './UserWorkspaceDeleteLogic';

function UserWorkspaceDelete({ workspace_detail, close_delete_modal, user_workspace_refetch }) {
  const [IsConfirmLoading, setIsConfirmLoading] = useState(false);

  const handleDelete = async (workspace_name) => {
    setIsConfirmLoading(true);

    const response = await deleteWorkspaceRequest(workspace_name);

    if (response.result === true) {
      // close modal function from parent
      close_delete_modal();

      setIsConfirmLoading(false);

      // refetch user workspace table from parent
      user_workspace_refetch();
    }
  };

  return (
    <div className="workspace-delete-main-container">
      <div className="workspace-delete-icon-wrapper">
        <DeleteRoundedIcon style={{ color: '#ff4e4e', width: '5rem', height: '5rem' }} />

      </div>
      <h5>
        Delete
        {workspace_detail.workspace_name}
        {' '}
        ?
      </h5>

      <div className="workspace-delete-button-wrapper">

        <button onClick={close_delete_modal}>CANCEL</button>

        {IsConfirmLoading
          ? (
            <div>
              <LoaderSpin width="25" height="25" />
            </div>
          )
          : <button onClick={() => handleDelete(workspace_detail.workspace_name)}>CONFIRM</button>}
      </div>

    </div>
  );
}

export default UserWorkspaceDelete;
