/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import './PaidWorkspace.css';
import Modal from 'react-modal';
import ReactTooltip from 'react-tooltip';

// Components
import ImportExportIcon from '@mui/icons-material/ImportExport';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';
import DemoWorkspaceClone from '../DemoWorkspace/DemoWorkspaceClone/DemoWorkspaceClone';
import { WORKSPACE } from 'libs/constants';

const ModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

/* Props :
        workspaces - list of projects in demo account
        workspace_limit - limit to check if user can clone more demo projects
 */
export default function PaidWorkspace({
  workspaces,
  workspace_limit,
  searchInput,
}) {
  const [IsDemoCloneOpen, setIsDemoCloneOpen] = useState(false);
  const [SelectedWorkspaceDetails, setSelectedWorkspaceDetails] = useState({
    workspace_name: '',
    workspace_domain: '',
  });

  const [WorkspaceList, setWorkspaceList] = useState(workspaces);
  const [currentSortedKey, setCurrentSortedKey] = useState({
    key: null,
    asc: true,
  });
  const showSortingIcon = () =>
    (currentSortedKey.asc ? (
      <ArrowDropUpRoundedIcon style={{ color: '#2a9df5', fontSize: '2rem' }} />
    ) : (
      <ArrowDropDownRoundedIcon
        style={{ color: '#2a9df5', fontSize: '2rem' }}
      />
    ));
  function WorkspaceTableHeader() {
    return (
      <div className="demo-workspace-table-column-header">
        <span
          className="demo-column-header-name"
          onClick={() => handleSorting('name', !currentSortedKey.asc)}
        >
          NAME
          {currentSortedKey.key === 'name' && <>{showSortingIcon()}</>}
        </span>
        <span
          className="demo-column-header-domain"
          onClick={() => handleSorting('site', !currentSortedKey.asc)}
        >
          DOMAIN
          {currentSortedKey.key === 'site' && <>{showSortingIcon()}</>}
        </span>
        <span
          className="demo-column-header-language"
          onClick={() => handleSorting('Lang', !currentSortedKey.asc)}
        >
          LANGUAGE
          {currentSortedKey.key === 'Lang' && <>{showSortingIcon()}</>}
        </span>
        <span
          className="demo-column-header-creation-date"
          onClick={() => handleSorting('date_purchased', !currentSortedKey.asc)}
        >
          BOUGHT ON
          {currentSortedKey.key === 'date_purchased' && (
            <>{showSortingIcon()}</>
          )}
        </span>
        <span className="demo-column-header-copy" />
        <span
          className="demo-column-header-price"
          onClick={() => handleSorting('cost', !currentSortedKey.asc)}
        >
          PAID
          {currentSortedKey.key === 'cost' && <>{showSortingIcon()}</>}
        </span>
        <span className="demo-column-header-more" />
      </div>
    );
  }
  useEffect(() => {
    handleSorting('name');
  }, []);

  const handleSorting = (key, asc = true) => {
    setCurrentSortedKey({ asc, key });
    const order = asc ? 1 : -1;
    const paidWorkspaceList = [...WorkspaceList];
    paidWorkspaceList.sort((a, b) =>
      (String(a[key]).toLowerCase() > String(b[key]).toLowerCase()
        ? 1 * order
        : -1 * order),
    );
    setWorkspaceList([...paidWorkspaceList]);
  };

  function PaidWorkspaceRows({
    index,
    name,
    domain,
    price,
    language,
    creation_date,
  }) {
    return (
      <div className="demo-workspace-data-container">
        <div className="demo-workspace-table-row-data">
          <ReactTooltip />

          <span className="demo-row-data-name"> {name} </span>
          <span className="demo-row-data-domain"> {domain} </span>
          <span className="demo-row-data-language">{language} </span>
          <span className="demo-row-data-creation-date">{creation_date}</span>
          {!workspace_limit ? (
            <span
              className="demo-row-data-copy"
              data-tip="Add this to My-Workspaces"
              onClick={() => handleCopyWorkspace(name, domain)}
            >
              <LibraryAddIcon
                style={{ width: '20px', height: '20px', color: '#43e343' }}
              />
            </span>
          ) : (
            <span
              className="demo-row-data-copy"
              data-tip={`${WORKSPACE} Limit Reached`}
            >
              <LibraryAddIcon
                style={{ width: '20px', height: '20px', color: 'grey' }}
              />
            </span>
          )}
          <span className="demo-row-data-price"> ${price}</span>
          <span className="demo-row-data-more" />
        </div>

        {/* {SelectedWorkspaceIndex === index ? (
                    <DemoWorkspacePreview workspace_name={name} />
                ) : null} */}
      </div>
    );
  }

  const handleCopyWorkspace = async (name, domain) => {
    setIsDemoCloneOpen(true);

    setSelectedWorkspaceDetails({
      ...SelectedWorkspaceDetails,
      workspace_name: name,
      workspace_domain: domain,
    });
  };

  const handleCloseCopyWorkspaceModal = () => {
    setIsDemoCloneOpen((IsDemoCloneOpen) => !IsDemoCloneOpen);
  };

  function CloneDemoWorkspaceModal() {
    return (
      <Modal
        isOpen={IsDemoCloneOpen}
        onRequestClose={handleCloseCopyWorkspaceModal}
        style={ModalStyles}
        ariaHideApp={false}
      >
        <DemoWorkspaceClone
          workspace_detail={SelectedWorkspaceDetails}
          close_clone_demo_modal={handleCloseCopyWorkspaceModal}
        />
      </Modal>
    );
  }

  return (
    <div>
      <WorkspaceTableHeader />

      <CloneDemoWorkspaceModal />
      <div className="demo-workspace-table-data-container">
        {workspaces &&
          WorkspaceList.map((workspace, index) => (
            <>
              {workspace.name
                .toLowerCase()
                .includes(searchInput.toLowerCase()) && (
                <>
                  <PaidWorkspaceRows
                    index={index}
                    price={workspace.cost}
                    name={workspace.name}
                    domain={workspace.site}
                    language={workspace.Lang}
                    creation_date={workspace.date_purchased}
                  />
                  <div className="row-data-divider" />
                </>
              )}
            </>
          ))}
      </div>
    </div>
  );
}
