import React, { useState, useEffect } from 'react';
import { anonymousRegister } from 'queries/register-anonymous';
import VerifyEmailRequest, {
  ResendVerificationCode,
} from 'Component/Auth/SignUpFreeTrial/Verification/VerifyEmailLogic';
import { AnimatePresence, motion } from 'framer-motion';
import IconChat from 'assets/IconChat';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReactPlayer from 'react-player';
import { loginAnonymous } from 'queries/login-anonymous';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { saveUserActivity } from 'Component/Pages/Login/LoginLogic';
import { removeAuthToken, setAuthToken } from 'Config/localstorageConfig';
import { validateUserAndRefreshToken } from 'Config/AxiosConfig';
import { userLoginSuccess } from '../../../../redux';

import styles from './anonymous-register.module.css';

const REVIEWS = [
  {
    name: 'Jennifer Anastasi',
    professional: 'Brand and Marketing Strategist',
    description:
      'I made more progress in 30 minutes than i&apos;ve made in the last 5 years.',
    profile:
      'https://i0.wp.com/crawlq.ai/wp-content/uploads/2023/08/1643815209108.jpeg?fit=800%2C800&ssl=1',
  },
  {
    name: 'Sam Broom',
    professional:
      'Experienced Digital Marketing Manager & Strategist,Agriconnect',
    description:
      'This tool has masses of potential...you will pretty much get an entire content marketing strategy and brand identity kit.',
    profile:
      'https://i0.wp.com/crawlq.ai/wp-content/uploads/2023/08/1517412003060-1.jpeg?fit=281%2C281&ssl=1',
  },
  {
    name: 'Elma Pena',
    professional:
      ' Marketing Strategist | Business Start-Up and Scale-Up | Online Reputation Management',
    description:
      'CrawlQ is the only app that I know that helps me do market research...For someone like me who used to spend $$$$$ on market research, this is the best that can replace that.',
    profile:
      'https://i0.wp.com/crawlq.ai/wp-content/uploads/2023/08/749b076603b12abea38fbc0a961de232.jpeg?fit=100%2C100&ssl=1',
  },
];

function AnonymousRegister() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    removeAuthToken();
  }, []);

  const [AnonymousEmail, setAnonymousEmail] = useState('');
  const [AnonymousName, setAnonymousName] = useState('');
  const [AnonymousCode, setAnonymousCode] = useState('');

  const [IsRegistering, setIsRegistering] = useState('');
  const [IsRegisterSuccess, setIsRegisterSuccess] = useState(false);
  const [IsVerifying, setIsVerifying] = useState(false);
  const [EnableResendCode, setEnableResendCode] = useState(false);
  const [HasErrors, setHasErrors] = useState('');

  const handleAnonymousRegister = async () => {
    setIsRegistering(true);
    if (AnonymousName === '') {
      setHasErrors(
        'Athena would love to know your name before proceeding to chat.',
      );
      setIsRegistering(false);

      return;
    }

    if (AnonymousEmail === '') {
      setHasErrors('Athena can only reach to you via an email id.');
      setIsRegistering(false);

      return;
    }
    const response = await anonymousRegister({
      email: AnonymousEmail,
      name: AnonymousName,
      password: 'FREEMIUM_CRAWLQ_@23',
      is_anonymus: 'True',
    });

    if (response?.data?.message === 'Successfully Signed Up!') {
      setIsRegisterSuccess((RegisterSucess) => !RegisterSucess);
      setIsRegistering(false);
    } else {
      setHasErrors('Email already exists');
      setIsRegistering(false);
    }
  };

  const handleVerify = async () => {
    setIsVerifying((IsVerifying) => !IsVerifying);
    const { success } = await VerifyEmailRequest(AnonymousEmail, AnonymousCode);

    if (success) {
      const { name, email, jwt } = await loginAnonymous(
        AnonymousName,
        AnonymousEmail,
      );

      const data = {
        name,
        email,
        username: email,
        is_anonymous: true,
      };
      setAuthToken(jwt);
      localStorage.setItem('username', email);
      await saveUserActivity('FREEMIUM_CRAWLQ_@23');
      await validateUserAndRefreshToken();
      dispatch(userLoginSuccess(data));
      navigate('/chat-athena-flow');
    } else {
      setIsVerifying((IsVerifying) => !IsVerifying);

      setHasErrors('Incorrect code provided');
      setEnableResendCode((EnableResendCode) => !EnableResendCode);
    }
  };

  const handleResendCode = () => {
    const { sent, message } = ResendVerificationCode(AnonymousEmail);

    if (sent) {
      setHasErrors('Check your mail for code');
      return;
    }
    setHasErrors(message);
  };
  return (
    <div className={styles.container}>
      <div className={styles.left_wrapper}>
        <div className={styles.left_container}>
          <div className={styles.title}>
            <h2 className={styles.title_1}>
              Infuse Your Brand With Personality
            </h2>
            <h3 className={styles.title_2}>
              Especially when the depths of your audience&apos;s desires remain
              a mystery
            </h3>
            <div className={styles.sub}>
              Dive into the subconscious realms of your audience.
            </div>
          </div>
          <ReactPlayer
            className={styles.iframes}
            url="https://general-crawlq-videos.s3.us-east-2.amazonaws.com/Athena-Advance+Ai+Assistant.mp4"
            playing
            controls
            loop
            muted
            config={{ file: { attributes: { controlsList: 'nodownload' } } }}
          />

          {/* <div className={styles.reviews_container}>
            {REVIEWS.map((review) => (
              <div className={styles.review_box}>
                <div className={styles.review_box_top}>
                  <img
                    className={styles.review_img}
                    src={review.profile}
                    alt="reviewer"
                  />
                  <div className={styles.review_box_top_info}>
                    <div className={styles.review_box_top_info_name}>
                      {review?.name}
                    </div>
                    <div className={styles.review_box_top_info_pro}>
                      {review?.professional}
                    </div>
                  </div>
                </div>

                <div className={styles.review_box_text}>
                  {review?.description}
                </div>
              </div>
            ))}
          </div> */}
        </div>
      </div>

      <div className={styles.right_wrapper}>
        <div className={styles.right_container}>
          <div className={styles.right_content_container}>
            <div className={styles.content_info}>
              <h4>
                Start <span className={styles.chat_athena}>Now</span>
              </h4>
              <div className={styles.title}>
                Go Deeper into your Customer&apos;s Subconscious Mindstates
              </div>
              <div className={styles.subtitle}>
                Move away from old market research methods
              </div>
              <IconChat size="370" className="h-margin-t-large" />
            </div>

            {!IsRegisterSuccess && (
              <AnimatePresence>
                <motion.div
                  initial={{ x: 300, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  exit={{ x: -100, opacity: 0 }}
                  className={styles.content_input}
                >
                  <div className={styles.input_title}>
                    Get Started In Under 1-Min
                  </div>
                  <fieldset className={styles.inputs_container}>
                    <legend className={styles.legend}>Name</legend>
                    <input
                      className={styles.input}
                      type="name"
                      value={AnonymousName}
                      onChange={(event) => setAnonymousName(event.target.value)}
                    />
                  </fieldset>
                  <fieldset className={styles.inputs_container}>
                    <legend className={styles.legend}>Email Id</legend>
                    <input
                      className={styles.input}
                      type="email"
                      value={AnonymousEmail}
                      onChange={(event) => {
                        setHasErrors('');
                        setAnonymousEmail(event.target.value);
                      }}
                    />
                  </fieldset>
                  {HasErrors && <div className={styles.error}>{HasErrors}</div>}
                  <button
                    className={styles.primary_btn}
                    type="button"
                    onClick={handleAnonymousRegister}
                  >
                    {IsRegistering ? 'Registering ' : 'Start Now'}
                  </button>

                  <span
                    className={styles.already_member}
                    onClick={() => navigate('/')}
                  >
                    Already a member ? <i className={styles.login_}>Login</i>
                  </span>
                </motion.div>
              </AnimatePresence>
            )}

            {IsRegisterSuccess && (
              <AnimatePresence>
                <motion.div
                  initial={{ x: 100, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  exit={{ x: -100, opacity: 0 }}
                  className={styles.content_input}
                >
                  <div className={styles.input_title}>
                    {' '}
                    <ArrowBackIcon
                      className={styles.back_btn}
                      onClick={() => setIsRegisterSuccess(false)}
                    />{' '}
                    Verify Email
                  </div>
                  <fieldset className={styles.inputs_container}>
                    <legend className={styles.legend}>Verification Code</legend>
                    <input
                      className={styles.input}
                      type="name"
                      value={AnonymousCode}
                      onChange={(event) => setAnonymousCode(event.target.value)}
                    />
                  </fieldset>
                  <span>
                    Please check your email for the verification code.
                  </span>
                  {HasErrors && <div className={styles.error}>{HasErrors}</div>}
                  <button
                    className={styles.primary_btn}
                    type="button"
                    onClick={handleVerify}
                  >
                    {IsVerifying ? 'Verifying' : 'Verify'}
                  </button>
                  {EnableResendCode && (
                    <span
                      className={styles.resend_code}
                      onClick={handleResendCode}
                    >
                      Resend Code
                    </span>
                  )}
                </motion.div>
              </AnimatePresence>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AnonymousRegister;
