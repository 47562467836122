import { Flex } from 'Component/Shared/GlobalStyles';
import React from 'react';
import { truncateString } from 'Component/Shared/utils';
import CustomTooltip from 'Component/Shared/CustomTooltip';
import CheckBoxOutlineBlankRoundedIcon from '@mui/icons-material/CheckBoxOutlineBlankRounded';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import styles from './template-selector.module.css';

const TemplateCard = ({ name, descrption, onBtnClick, isSelected }) => (
  <div className={`${styles.body} card`}>
    <Flex
      style={{
        color: 'black',
        backgroundColor: 'white',
      }}
      className="card-body"
      align="flex-start"
      justify="space-between"
      direction="column"
    >
      {isSelected ? (
        <CustomTooltip title="Unselect Template" arrow placement="top">
          <div onClick={onBtnClick} className={styles.use_button}>
            <CheckBoxRoundedIcon />
          </div>
        </CustomTooltip>
      ) : (
        <CustomTooltip title="Select Template" arrow placement="top">
          <div
            onClick={onBtnClick}
            className={`${styles.use_button} ${styles.use_button_unselected}`}
          >
            <CheckBoxOutlineBlankRoundedIcon />
          </div>
        </CustomTooltip>
      )}
      <h6
        style={{
          fontSize: '0.8rem',
          color: 'black',
          fontWeight: 'bold',
          marginBottom: '5px',
        }}
        className={`${styles.heading} card-title`}
      >
        {truncateString(name, 100)}
      </h6>
      <p className={`${styles.description} card-title`}>
        {truncateString(descrption, 250)}
      </p>
    </Flex>
  </div>
);

export default TemplateCard;
