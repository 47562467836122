import React from 'react';
import { div } from 'components/common/cloud-box';
import styles from './overview.module.css';

function Overview({ overviewInfo }) {
  // !TODO : add a function to read time functionality.

  console.log(overviewInfo);
  const averageHeaders = overviewInfo?.avg_headers;
  const averageImages = overviewInfo?.avg_img;
  const averageWords = overviewInfo?.avg_words;
  const averageTopicScore = '';

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.score}>{averageHeaders}</div>
        <div className={styles.header}>Read Time</div>
      </div>
      {averageHeaders && (
      <div className={styles.container}>
        <div className={styles.score}>{averageHeaders}</div>
        <div className={styles.header}>Average Headers</div>
      </div>
      )}
      {averageImages && (
      <div className={styles.container}>
        <div className={styles.score}>{averageImages}</div>
        <div className={styles.header}>Average Images</div>
      </div>
      )}
      {averageWords && (
      <div className={styles.container}>
        <div className={styles.score}>{averageWords}</div>
        <div className={styles.header}>Average Words</div>
      </div>
      )}
      {averageTopicScore && (
      <div className={styles.container}>
        <div className={styles.score}>{averageHeaders}</div>
        <div className={styles.header}>Average Topic Score</div>
      </div>
      )}
    </div>
  );
}

export default Overview;
