/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

function TopOfFunnelIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16.933 16.933"
      style={{
        enableBackground: 'new 0 0 512 512',
      }}
      xmlSpace="preserve"
      {...props}
    >
      <path
        d="M7.719 8.968.92 11.778c-.487.191-.534.965-.052 1.177L7.53 15.7a2.594 2.594 0 0 0 1.874 0l6.662-2.745c.481-.212.434-.986-.053-1.178L9.214 8.968c-.572-.233-.906-.235-1.495 0z"
        fill="#9ad3ff"
        data-original="#ffab61"
      />
      <path
        d="M12.495 10.324c-.995.423-2 .84-2.992 1.267-.648.244-1.074.368-1.862.08l-2.199-.941c-.661-.311-1.098.709-.416.972l2.174.93c.894.39 1.731.35 2.78-.096l3.89-1.645z"
        fill="#9dd5ff"
        data-original="#ff8f64"
      />
      <path
        d="M7.719 5.1.92 7.91c-.487.192-.534.965-.052 1.178l6.662 2.744a2.594 2.594 0 0 0 1.874 0l6.662-2.744c.481-.213.434-.986-.053-1.178L9.214 5.1c-.572-.232-.906-.235-1.495 0z"
        fill="#9ad3ff"
        data-original="#ffc861"
      />
      <path
        d="M12.52 6.47c-.997.423-2.004.841-2.997 1.268-.649.245-1.076.37-1.864.08l-2.203-.942c-.663-.311-1.1.71-.417.974l2.178.931c.895.391 1.733.35 2.783-.096l3.897-1.647z"
        fill="#9ad3ff"
        data-original="#ffab61"
      />
      <path
        d="M7.719 1.234.92 4.043c-.487.192-.534.965-.052 1.178L7.53 7.965a2.594 2.594 0 0 0 1.874 0l6.662-2.744c.481-.213.434-.986-.053-1.178l-6.799-2.81c-.572-.232-.906-.235-1.495 0z"
        fill="#2a9df5"
        data-original="#55a1ff"
      />
      <path
        d="M7.633.99.826 3.803C.117 4.082.057 5.16.763 5.47c2.332.95 4.69 1.942 6.686 2.755.686.255 1.42.258 2.068-.002L12.8 6.87l3.14 1.298c.268.105.303.576.044.69L9.32 11.606c-.52.208-1.115.209-1.687-.004L.976 8.859c-.268-.127-.22-.59.047-.692l1.953-.806c.354-.122.135-.652-.202-.49l-1.948.805C.117 7.955.057 9.033.763 9.344l2.703 1.114-2.64 1.091c-.709.28-.769 1.358-.063 1.669 2.328.963 4.691 1.934 6.686 2.754.686.255 1.42.258 2.068-.002 2.328-.956 4.689-1.93 6.68-2.752.706-.311.646-1.39-.062-1.669l-2.273-.939c-.339-.166-.559.369-.202.49.76.312 1.52.632 2.28.942.268.105.303.576.044.69L9.32 15.48c-.518.208-1.11.208-1.681-.002L.976 12.732c-.259-.114-.224-.585.043-.69 1.047-.43 2.095-.865 3.141-1.298 1.097.451 2.192.906 3.289 1.355.686.255 1.42.258 2.068-.002 2.328-.953 4.687-1.936 6.68-2.753.706-.31.646-1.39-.062-1.668l-2.64-1.09c.9-.372 1.802-.745 2.703-1.115.705-.31.645-1.39-.063-1.668C13.87 2.856 11.591 1.94 9.329.99 8.707.73 8.234.749 7.633.99zm1.494.49c2.373.982 4.783 1.976 6.814 2.815.267.106.302.577.043.691L9.32 7.733c-.52.208-1.115.208-1.687-.005L.976 4.986c-.269-.118-.216-.595.047-.693l6.806-2.81c.558-.229.808-.2 1.298-.003z"
        fill="#fff"
        data-original="#00003a"
      />
    </svg>
  );
}

export default TopOfFunnelIcon;
