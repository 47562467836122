/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import { LoaderTailSpin } from 'Component/Shared/Loader.js';
import React, { useState, useContext } from 'react';
import { AiContent } from '../contexts/ai_content.js';
import HeadlinesSection from './headlines_Section';
import ScoreOverviewsection from './score_OverviewSection.js';

export function HeadlinesCollection(props) {
  const { aiContent, setaiContent } = useContext(AiContent);

  if (typeof aiContent === 'undefined') {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '2.8rem',
        }}
      >
        <LoaderTailSpin />
      </div>
    );
  }
  return (
    <div>
      <ScoreOverviewsection />
      <HeadlinesSection headlines={aiContent.paragraphs} />
    </div>
  );
}
export default HeadlinesCollection;
