function addIndexToTemplates(templates) {
  return templates.map((template, index) => ({ ...template, index }));
}

export function sortTemplates(templates) {
  const sortedTemplates = templates.sort((a, b) => {
    if (a.template_name.trim() < b.template_name.trim()) {
      return -1;
    }
    if (a.template_name > b.template_name) {
      return 1;
    }
    return 0;
  });

  return addIndexToTemplates(sortedTemplates);
}

export function sortBlogs(blogs) {
  const sortedTemplates = blogs.sort((a, b) => {
    if (a.blog_name < b.blog_name) {
      return -1;
    }
    if (a.blog_name > b.blog_name) {
      return 1;
    }
    return 0;
  });
  return addIndexToTemplates(sortedTemplates);
}
