/* eslint-disable import/no-extraneous-dependencies */
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import CustomTooltip from 'Component/Shared/CustomTooltip';
import { Flex } from 'Component/Shared/GlobalStyles';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import styles from '../index.module.css';
import { FormHeading, ProductInputWrapper } from '../styles';
import BottomOfTheFunnel from './BottomOfTheFunnel';
import MiddleOfTheFunnel from './MiddleOfTheFunnel';
import TopOfTheFunnel from './TopOfTheFunnel';

const MarketResearchInput = () => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <ProductInputWrapper>
      <Flex gap={'0.5rem'} align={'flex-end'}>
        {isExpanded ? (
          <KeyboardArrowDownRoundedIcon
            className={styles.icons_size}
            onClick={() => setIsExpanded(false)}
          />
        ) : (
          <KeyboardArrowRightRoundedIcon
            className={styles.icons_size}
            onClick={() => setIsExpanded(true)}
          />
        )}
        <Flex direction={'column'} className="w-100">
          <Flex justify={'center'} align={'center'} className="w-100">
            <p
              className={styles.heading_page}
            >
              Select Data Sources
            </p>
            <CustomTooltip
              arrow
              title="Select single or multiple data sources to train our AI Athena for each stage of the customer decision journey. A/B test variations in data sources to validate your market message"
              placement="top"
            >
              <InfoOutlinedIcon style={{ fontSize: '22px' }} />
            </CustomTooltip>
          </Flex>
          <FormHeading onClick={() => setIsExpanded((prev) => !prev)}>
            Market Research
          </FormHeading>
        </Flex>
      </Flex>
      {isExpanded && (
        <Flex className={styles.mrr_wrapper}>
          <motion.div
            layout
            initial={{ opacity: 0, y: '-5vw', x: '0vw' }}
            animate={{ opacity: 1, y: 0, x: 0 }}
            transition={{ duration: 0.2, ease: 'easeInOut' }}
            className={styles.input_wrapper_parent}
          >
            <Flex direction={'column'} gap={'1rem'}>
              <TopOfTheFunnel />
              <MiddleOfTheFunnel />
              <BottomOfTheFunnel />
            </Flex>
          </motion.div>
        </Flex>
      )}
    </ProductInputWrapper>
  );
};

export default MarketResearchInput;
