/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import React from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import LoaderSpin from 'Component/Shared/Loader';
import coin from '../../../../assets/images/coins.svg';

function DashbaordTopItem({
  type, value, icon, iconsize,
}) {
  return (
    <div className="dashboard-top-item">
      <div className="mr-1">
        <img src={icon} width={iconsize} />
      </div>
      <div>
        <span className="font-accent">{type}</span>

        <div className="font-secondary txt-primary">{value}</div>
      </div>
    </div>
  );
}

export function DashbaordTopItemCredits({
  type,
  value,
  iconsize,
  total,
  topUp,
}) {
  return (
    <div className="dashboard-top-item">
      <div className="mr-1">
        <img src={coin} width={iconsize} />
      </div>
      <div>
        <span className="font-accent mr-1">{type}</span>
        <AddCircleIcon
          style={{ color: '63A5F0', cursor: 'pointer' }}
          onClick={() => document.getElementById('buyCreditsPopUp').click()}
        />
        <br />
        {value !== 'undefined' ? (
          <>
            {total !== '∞' ? (
              <strong className="font-secondary txt-primary">
                {value}
                {' '}
                /
                {total}
                {' '}
                (+
                {topUp}
                )
              </strong>
            ) : (
              <strong className="font-secondary txt-primary">
                {' '}
                Unlimited (+
                {topUp}
                )
                {' '}
              </strong>
            )}
          </>
        ) : (
          <LoaderSpin color="#2a9df5" height="50" width="50" />
        )}
      </div>
    </div>
  );
}

export default DashbaordTopItem;
