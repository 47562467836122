/* eslint-disable import/no-extraneous-dependencies */
import { styled } from '@mui/system';
import React from 'react';
import TablePagination from '@mui/material/TablePagination';

const CustomTablePagination = styled(TablePagination)({
  '& .MuiTablePagination-toolbar': {
    backgroundColor: 'var(--card-background-color)',
    color: 'var(--default-text-color)',
    justifyContent: 'center',
  },

  '& .MuiTablePagination-selectLabel': {
    margin: '0rem',
  },

  '& .MuiTablePagination-displayedRows': {
    margin: '0rem',
  },
  '& .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper': {
    backgroundColor: 'var(--card-background-color)',
    color: 'var(--default-text-color)',
  },
});

export function PaginationView({ page, updatePage, pageSize, updatePageSize, total }) {
  const handleChangePage = (event, newPage) => {
    updatePage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    updatePageSize(parseInt(event.target.value, 10));
    updatePage(0);
  };

  return (
    <CustomTablePagination
      component="div"
      count={total}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={pageSize}
      onRowsPerPageChange={handleChangeRowsPerPage}
      rowsPerPageOptions={[5, 10, 15, 20]}
    />
  );
}
