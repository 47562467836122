/* eslint-disable indent */
/* eslint-disable react/jsx-indent-props */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ScaffoldCard, Divider } from 'components/common';
import styles from './insights.module.css';

const Insights360 = () => {
    const navigate = useNavigate();

    const handleContactClick = () => {
        navigate('/insights-360-ai');
    };

    return (

        <ScaffoldCard className={styles.container}>
            <div className={styles.header}>Insights360ai (beta)</div>
            <Divider />

            <div className={styles.content_container}>
                • Simplify • Connect • Earn
                <br />
                • Data Analytics Redefined
                <br />
                • Integrate 572+ workspace apps. Turn your data into profits.
            </div>

            <button
                // eslint-disable-next-line react/jsx-indent-props
                type="button"
                className={styles.try_now}
                onClick={handleContactClick}
            >
                Try Now
            </button>
        </ScaffoldCard>
    );
};

export default Insights360;
