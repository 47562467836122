/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import Badge from '@mui/material/Badge';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { CreditManage } from 'Component/Shared/CreditManager';
import CustomTooltip from 'Component/Shared/CustomTooltip';
import React, { useEffect, useState } from 'react';

// logic fns
import { PRODUCT_STORY_COST } from 'Component/Shared/featuresCreditsCost';
import { addInEditor } from '../../../../Shared/copyToClipBoard';
// components
import LoaderSpin from '../../../../Shared/Loader';
import './ProductStory.css';
import getProblemAgitationStory from './ProblemAgitationLogic';
import getPainBenefitStory from './PainBenefitLogic';
import getProblemDesireStory from './ProblemDesireLogic';
import getRoadblockResultStory from './RoadblockLogic';

function ProductStory() {
  const [problemAgitationStories, setProblemAgitationStories] = useState([]);
  const [problemDesireStories, setProblemDesireStories] = useState([]);
  const [roadblockResultStories, setRoadblockResultStories] = useState([]);
  const [painBenefitStories, setPainBenefitStories] = useState([]);
  const [isProblemaAgitationLoading, setIsProblemaAgitationLoading] = useState(
    false,
  );
  const [isProblemDesireLoading, setIsProblemDesireLoading] = useState(false);
  const [isRoadblockResultLoading, setIsRoadblockResultLoading] = useState(
    false,
  );
  const [isPainBenefitLoading, setIsPainBenefitLoading] = useState(false);
  const [hasCreditOver, setHasCreditOver] = useState(false);
  const [Retry, setRetry] = useState(false);

  useEffect(() => {}, []);

  const generateProblemAgitationStory = async () => {
    setIsProblemaAgitationLoading(true);
    const { success } = await CreditManage(PRODUCT_STORY_COST, true);
    if (!success) {
      setHasCreditOver(true);
      setIsProblemaAgitationLoading(false);
      return;
    }
    setRetry(false);

    getProblemAgitationStory()
      .then(({ data, error }) => {
        if (!error) {
          if (data !== '') {
            setProblemAgitationStories([data, ...problemAgitationStories]);
            CreditManage(PRODUCT_STORY_COST);
            return;
          }
        }
        setRetry(true);
      })
      .catch(() => {
        setRetry(true);
      })
      .finally(() => {
        setIsProblemaAgitationLoading(false);
      });
  };

  const generateStoryProblemDesireBenefit = async () => {
    setIsProblemDesireLoading(true);
    const { success } = await CreditManage(PRODUCT_STORY_COST, true);
    if (!success) {
      setHasCreditOver(true);
      setIsProblemDesireLoading(false);
      return;
    }
    setRetry(false);

    getProblemDesireStory()
      .then(({ data, error }) => {
        if (!error) {
          if (data !== '') {
            setProblemDesireStories([data, ...problemDesireStories]);
            CreditManage(PRODUCT_STORY_COST);
            return;
          }
        }
        setRetry(true);
      })
      .catch(() => {
        setRetry(true);
      })
      .finally(() => {
        setIsProblemDesireLoading(false);
      });
  };

  const generateStoryRoadblockResult = async () => {
    setIsRoadblockResultLoading(true);
    const { success } = await CreditManage(PRODUCT_STORY_COST, true);
    if (!success) {
      setHasCreditOver(true);
      setIsRoadblockResultLoading(false);
      return;
    }
    setRetry(false);

    getRoadblockResultStory()
      .then(({ data, error }) => {
        if (!error) {
          if (data !== '') {
            setRoadblockResultStories([data, ...roadblockResultStories]);
            CreditManage(PRODUCT_STORY_COST);
            return;
          }
        }
        setRetry(true);
      })
      .catch(() => {
        setRetry(true);
      })
      .finally(() => {
        setIsRoadblockResultLoading(false);
      });
  };

  const generateStoryPainBenefit = async () => {
    setIsPainBenefitLoading(true);
    const { success } = await CreditManage(PRODUCT_STORY_COST, true);
    if (!success) {
      setHasCreditOver(true);
      setIsPainBenefitLoading(false);
      return;
    }
    setRetry(false);

    getPainBenefitStory()
      .then(({ data, error }) => {
        if (!error) {
          if (data !== '') {
            setPainBenefitStories([data, ...painBenefitStories]);
            CreditManage(PRODUCT_STORY_COST);
            return;
          }
        }
        setRetry(true);
      })
      .catch(() => {
        setRetry(true);
      })
      .finally(() => {
        setIsPainBenefitLoading(false);
      });
  };

  return (
    <div style={{ width: '100%' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="CH-btn-heding-group">
          <div>
            <Badge badgeContent="New" color="error" />

            <h4>
              CrawlQ Brand Story Framework <sup>&trade;</sup>
            </h4>

            <p>
              Bring <i className="fas fa-user" /> (FRED) to Life
              <CustomTooltip
                placement="top"
                title={
                  "It generates a story from your inputs in Market Research. The generated story contains a relatable character and describes his problems, some sort of conflict that this person is facing, a learning process, and then a satisfying resolution about how your product/service has helped them. With these elements in the story, you are sure to inspire your target audience's emotional reaction."
                }
                arrow
              >
                <InfoOutlinedIcon className="mb-2 ml-1" />
              </CustomTooltip>
            </p>
            {/* <div style={{ico}}></div> */}
          </div>
          {Retry && <span className="retry-error">Retry !</span>}
          {!isProblemaAgitationLoading ? (
            <>
              {hasCreditOver ? (
                <button
                  className=" p-1 px-2 rounded"
                  style={{
                    background: 'rgb(255 0 0 / 21%)',
                    color: 'rgb(224 2 2 / 81%)',
                  }}
                >
                  Credits Over
                </button>
              ) : (
                <button
                  onClick={generateProblemAgitationStory}
                  className="text-white p-1 px-2 rounded"
                  style={{ backgroundColor: 'rgb(99, 165, 240)' }}
                >
                  <i className="fas fa-user" /> Problem-Agitation-Solution
                </button>
              )}
            </>
          ) : (
            <div className="d-flex justify-content-around">
              <LoaderSpin />
            </div>
          )}
          {!!problemAgitationStories && (
            <div>
              {problemAgitationStories.map((story) => (
                <>
                  <div className="product-story-values">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: story,
                      }}
                    />
                    <FileCopyOutlinedIcon onClick={() => addInEditor(story)} />
                  </div>
                  <hr />
                </>
              ))}
            </div>
          )}

          {!isProblemDesireLoading ? (
            <>
              {hasCreditOver ? (
                <button
                  className=" p-1 px-2 rounded"
                  style={{
                    background: 'rgb(255 0 0 / 21%)',
                    color: 'rgb(224 2 2 / 81%)',
                  }}
                >
                  Credits Over
                </button>
              ) : (
                <button
                  onClick={generateStoryProblemDesireBenefit}
                  className="text-white p-1 px-2 rounded"
                  style={{
                    marginTop: 20,
                    backgroundColor: 'rgb(99, 165, 240)',
                  }}
                >
                  <i className="fas fa-user" /> Problem-Desire
                </button>
              )}
            </>
          ) : (
            <div className="d-flex justify-content-around">
              <LoaderSpin />
            </div>
          )}

          {!!problemDesireStories && (
            <div>
              {problemDesireStories.map((story) => (
                <>
                  <div className="product-story-values">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: story,
                      }}
                    />
                    <FileCopyOutlinedIcon onClick={() => addInEditor(story)} />
                  </div>
                  <hr />
                </>
              ))}
            </div>
          )}

          {!isRoadblockResultLoading ? (
            <>
              {hasCreditOver ? (
                <button
                  className=" p-1 px-2 rounded"
                  style={{
                    background: 'rgb(255 0 0 / 21%)',
                    color: 'rgb(224 2 2 / 81%)',
                  }}
                >
                  Credits Over
                </button>
              ) : (
                <button
                  onClick={generateStoryRoadblockResult}
                  className="text-white p-1 px-2 rounded"
                  style={{
                    marginTop: 20,
                    backgroundColor: 'rgb(99, 165, 240)',
                  }}
                >
                  <i className="fas fa-user" /> Roadblock-Result
                </button>
              )}
            </>
          ) : (
            <div className="d-flex justify-content-around">
              <LoaderSpin />
            </div>
          )}
          {!!roadblockResultStories && (
            <div>
              {roadblockResultStories.map((story) => (
                <>
                  <div className="product-story-values">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: story,
                      }}
                    />
                    <FileCopyOutlinedIcon onClick={() => addInEditor(story)} />
                  </div>
                  <hr />
                </>
              ))}
            </div>
          )}

          {!isPainBenefitLoading ? (
            <>
              {hasCreditOver ? (
                <button
                  className=" p-1 px-2 rounded"
                  style={{
                    background: 'rgb(255 0 0 / 21%)',
                    color: 'rgb(224 2 2 / 81%)',
                  }}
                >
                  Credits Over
                </button>
              ) : (
                <button
                  onClick={generateStoryPainBenefit}
                  className="text-white p-1 px-2 rounded"
                  style={{
                    marginTop: 20,
                    backgroundColor: 'rgb(99, 165, 240)',
                  }}
                >
                  <i className="fas fa-user" /> Pain-Benefit
                </button>
              )}
            </>
          ) : (
            <div className="d-flex justify-content-around">
              <LoaderSpin />
            </div>
          )}
          {!!painBenefitStories && (
            <div>
              {painBenefitStories.map((story) => (
                <>
                  <div className="product-story-values">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: story,
                      }}
                    />
                    <FileCopyOutlinedIcon onClick={() => addInEditor(story)} />
                  </div>
                  <hr />
                </>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProductStory;
