import React, { useState } from 'react';
import cx from 'classnames';
import DeleteIcon from '@mui/icons-material/Delete';
import { Tooltip } from 'chart.js';
import CustomTooltip from 'Component/Shared/CustomTooltip';
import styles from './index.module.css';

export const FileCard = ({
  imgSrc,
  fileName,
  checked,
  onChange,
  fallBackFileImage,
  handleViewFile,
}) => {
  const [lastTapTime, setLastTapTime] = useState(0);

  const handleCardTouchEnd = () => {
    const currentTime = new Date().getTime();
    const delay = 300;

    if (currentTime - lastTapTime < delay) {
      handleViewFile();
    } else {
      setLastTapTime(currentTime);
    }
  };

  return (
    <div
      className={cx(styles.card_container, { [styles.selected]: checked })}
      onTouchEnd={handleCardTouchEnd}
      onDoubleClick={handleViewFile}
    >
      <div className={styles.image_container}>
        <img
          src={imgSrc}
          alt={fileName}
          className={styles.image}
          onError={(e) => {
            e.target.src = fallBackFileImage;
          }}
        />
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          className={styles.checkbox_input}
        />
        {/* <CustomTooltip title="Delete Document" arrow placement="top">
          <DeleteIcon className={styles.delete_icon} />
        </CustomTooltip> */}
      </div>
      <div className={styles.text}>{fileName}</div>
    </div>
  );
};
