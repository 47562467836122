import { useState } from 'react';

export default function useNetworks() {
  const [isOffline, setIsOffline] = useState(!navigator.onLine);
  window.addEventListener('offline', () => {
    setIsOffline(true);
  });

  window.addEventListener('online', () => {
    setIsOffline(false);
  });

  return [isOffline];
}
