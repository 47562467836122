import React, { useState } from 'react';
import { addOnCheckout } from 'utils/get-add-on-checkout';
import { AddModalView } from './AddModalView';

const customStyles = {
  content: {
    width: '600px',
    height: '300px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '95vw',
  },
};

const products = Object.freeze({
  0: 'Reddit AddOn',
  1: 'Quora AddOn',
});

export const AddOn = ({
  level,
  show = false,
  insideFlyout = false,
  setAlternativeShow,
}) => {
  const [showModal, setShowModal] = useState(show);
  const [showLoader, setShowLoader] = useState(false);
  const [isBuyingBoth, setIsBuyingBoth] = useState(false);

  const handleModal = () => {
    if (insideFlyout) {
      setAlternativeShow(false);
    } else {
      setShowModal(!showModal);
    }
  };

  const handleIsbuyingBoth = () => {
    setIsBuyingBoth((prev) => !prev);
  };

  const handleBuyPlan = async () => {
    setShowLoader(true);
    let plans = [];
    if (isBuyingBoth) {
      plans = [products[0], products[1]];
    } else {
      plans = [products[level]];
    }

    await addOnCheckout(plans);

    setShowLoader(false);
  };

  return (
    <div>
      <button
        type="button"
        className="d-none"
        id="addon_purchase"
        onClick={handleModal}
      >
        click
      </button>
      <AddModalView
        isOpen={showModal}
        onRequestClose={handleModal}
        customStyles={customStyles}
        ariaHideApp={false}
        level={level}
        isBuyingBoth={isBuyingBoth}
        handleIsBuyingBoth={handleIsbuyingBoth}
        handleBuyPlan={handleBuyPlan}
        showLoader={showLoader}
      />
    </div>
  );
};
