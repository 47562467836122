/* eslint-disable react/no-unescaped-entities */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
// Internal Imports
import React, { useState } from 'react';
import './NewUserLandingPage.css';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import Modal from 'react-modal';
import ListWorkspace from 'assets/images/choose_workspace.svg';
import Topbar from 'Component/Shared/Topbar/Topbar';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { LoaderTailSpin } from 'Component/Shared/Loader';
import setGoogleLanguageCookie from 'Component/Shared/Cookie_custom';
import { workspaceSelect, userWorkspaceCountUpdate } from 'redux/index';
import Bannervideo from 'Component/Pages/Dashboard/BannerVideo/Bannervideo';
import { userWorkspaceSetAthenaSources } from '../../../../redux';
import StepsFornew from './StepsForNewWorkspace/Stepsfornew';
// Assets

// Components - External
import CreateNewWorkspace from '../Workspace/CreateNewWorkspace/CreateNewWorkspace';
import CurateWorkspace from './CurateWorkspace/CurateWorkspace';

// Logic Fns
import fetchDemoWorkspace from './NewUserLandingPageLogic';
import DemoCloneWorkspaceRequest from '../Workspace/DemoWorkspace/DemoWorkspaceClone/DemoWorkspaceCloneLogic';
import DemoWorkspacePreview from '../Workspace/DemoWorkspace/DemoWorkspacePreview/DemoWorkspacePreview';

const ModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width: '500px',
    height: '420px',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

function NewUserLandingPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const GlobalUserData = useSelector((state) => state.user);
  const [IsOpenNewWorkspaceModal, setIsOpenNewWorkspaceModal] = useState(false);
  const [IsOpenCurateWorkspaceModal, setIsOpenCurateWorkspaceModal] = useState(
    false,
  );

  const [DemoWorkspaces, setDemoWorkspaces] = useState([]);
  const [BusinessInput, setBusinessInput] = useState('');
  const [GetStartedIndexLoading, setGetStartedIndexLoading] = useState(-1);
  const [ShowPreview, setShowPreview] = useState(-1);

  useEffect(() => {
    getDemoWorkspaces();
  }, []);

  const getDemoWorkspaces = async () => {
    const { result } = await fetchDemoWorkspace();
    if (result) {
      setDemoWorkspaces(result);
    } else {
      getDemoWorkspaces();
    }
  };

  function NewWorkspaceModal() {
    return (
      <Modal
        isOpen={IsOpenNewWorkspaceModal}
        onRequestClose={() => setIsOpenNewWorkspaceModal(false)}
        style={ModalStyles}
        ariaHideApp={false}
      >
        <CreateNewWorkspace />
      </Modal>
    );
  }

  function CurateWorkspaceModal() {
    return (
      <Modal
        isOpen={IsOpenCurateWorkspaceModal}
        onRequestClose={() => setIsOpenCurateWorkspaceModal(false)}
        style={ModalStyles}
        ariaHideApp={false}
      >
        <CurateWorkspace />
      </Modal>
    );
  }

  const handleBusinessInput = (e) => {
    setBusinessInput(e.target.value);
  };

  const handleGetStarted = async (
    name,
    domain,
    language,
    language_engine,
    index,
    is_trained,
  ) => {
    setGetStartedIndexLoading(index);

    const result = await DemoCloneWorkspaceRequest([name], [domain]);

    if (result.error === true) {
      // setErrorMessage("Some workspace(s) cannot be copied!")
      setGetStartedIndexLoading(-1);
    } else {
      setGoogleLanguageCookie(`/en/${language}/`);
      localStorage.setItem('name', name);
      localStorage.setItem('url', domain);
      localStorage.removeItem('processPercentage');
      localStorage.setItem('project_name', name);

      // set workspace details in redux-store
      dispatch(
        workspaceSelect({
          name,
          domain,
          language,
          language_engine,
          is_trained,
          mti: null,
        }),
      );
      dispatch(
        userWorkspaceSetAthenaSources({
          success_urls: [],
          failed_urls: [],
          train_option: DemoWorkspaces[index].train_sources,
        }),
      );
      navigate('/chat-athena');
      setGetStartedIndexLoading(-1);
      dispatch(userWorkspaceCountUpdate(1));
    }
  };

  const handlePreview = (index) => {
    if (index === ShowPreview) {
      setShowPreview(-1);
    } else {
      setShowPreview(index);
    }
  };

  return (
    <>
      <NewWorkspaceModal />
      <CurateWorkspaceModal />
      <Topbar />
      <div className="new-user-workspace-container">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {/* <h2 className="new-user-welcome-head">
            Welcome {GlobalUserData.name}
          </h2> */}
          <h3 className="txt-primary font-primary">
            Discover What Your Customers Really Want.
          </h3>
          <span>3 Easy Ways to Get Started</span>
        </div>
        <div className="new-user-workspace-main-container">
          <div className="new-user-workspace-select-wrapper">
            <div className="d-flex mb-3">
              {/*
              <span
                className="new-user-workspace-select-head"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                Choose From Our Niche Audience Templates
              </span>
             <div className="new-user-workspace-create-wrapper">
                <div
                  className="new-user-workspace-create-info"
                  onClick={() => setIsOpenNewWorkspaceModal(true)}
                  style={{ fontSize: '0.9rem' }}
                >
                  Create Your Own Unique Audience Persona
                </div>
              </div>
              <span
                className="new-user-workspace-create-wrapper"
                onClick={() => setIsOpenCurateWorkspaceModal(true)}
                style={{
                  justifyContent: 'flex-end',
                }}
              >
                <span className="new-user-workspace-create-info">
                  Done For You
                </span>
              </span>  */}
            </div>

            <div className="new-user-workspace-select-search-wrapper">
              <h5 className="text-center">
                <b>Enter Your Business Idea and Press Button Get Started</b>
              </h5>
              <p className="text-center">
                <small>
                  Enter a business idea and CrawlQ's AI Athena will give you a
                  jump-start to your marketing strategy with a pre-researched
                  Audience Persona. Publish your first content in under 2
                  minutes and come back later to audience research to A/B test
                  your content and research strategies. You can also clone the
                  initial audience persona and tweak it to your business goals
                  and objectives.
                </small>
              </p>
              <div className="d-flex">
                <input
                  className="new-user-select-search-data-input"
                  placeholder="Enter Business Idea"
                  value={BusinessInput}
                  name="business-name"
                  onChange={(e) => handleBusinessInput(e)}
                />
              </div>
              <div className="new-user-select-search-data">
                {BusinessInput && DemoWorkspaces ? (
                  DemoWorkspaces.map((workspace, index) => (
                    <>
                      {workspace.name
                        .toLowerCase()
                        .includes(BusinessInput.toLowerCase()) && (
                        <div className="new-user-select-workspace">
                          <span className="new-user-select-workspace-name">
                            {workspace.name}
                          </span>
                          <span className="new-user-select-workspace-site">
                            {workspace.site}
                          </span>

                          <div className="new-user-select-workspace-preview">
                            <button
                              className={
                                ShowPreview === index
                                  ? 'close-preview'
                                  : 'show-preview'
                              }
                              onClick={() => handlePreview(index)}
                            >
                              {ShowPreview === index
                                ? 'Close Preview'
                                : 'Show Preview'}
                              {/* <PlayCircleFilledWhiteIcon /> */}
                            </button>
                          </div>

                          {GetStartedIndexLoading === index ? (
                            <LoaderTailSpin width="20" height="20" />
                          ) : (
                            <div className="new-user-select-workspace-button">
                              <button
                                onClick={() =>
                                  handleGetStarted(
                                    workspace.name,
                                    workspace.site,
                                    workspace.Lang,
                                    workspace.lang_engine,
                                    index,
                                    !!workspace.is_trained,
                                  )
                                }
                              >
                                Get Started
                              </button>
                            </div>
                          )}
                        </div>
                      )}

                      {ShowPreview === index ? (
                        <DemoWorkspacePreview workspace_name={workspace.name} />
                      ) : null}
                    </>
                  ))
                ) : (
                  <StepsFornew />
                )}
              </div>
            </div>
          </div>
          <div className="new-user-workspace-create-wrapper-container">
            {' '}
            <Bannervideo newUserLandingPage />
          </div>
        </div>
      </div>
    </>
  );
}

export default NewUserLandingPage;
