/* eslint-disable no-await-in-loop */
import React, { useState } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload, notification } from 'antd';
import { useSelector } from 'react-redux';
import { sleep } from 'Component/Shared/utils';
import { ButtonPrimary, Flex } from 'Component/Shared/GlobalStyles';
import LoaderSpin from 'Component/Shared/Loader';
import { useQueryClient } from 'react-query';
import { GET_UPLOADED_FILES_LISTS_KEY } from 'queries/chat-athena/get-uploaded-files-lists';
import { pointer } from 'd3';
import { useMutateUpdateAssistant } from 'queries/assistants/mutate-edit-assistant';
import styles from './document-handler.module.css';
import {
  getFile,
  processUploadedFile,
  uploadDocument,
} from './services/upload-document';
import { formatFileSize } from './services/file-size-format';
import { shortenFileName } from './services/file-name-trimmer';

const { Dragger } = Upload;

const UploadStatusType = {
  uploading: 'Uploading',
  processing: 'Processing',
  uploaded: 'Uploaded',
};

// Define the allowed file extensions as an object
const AllowedFileExtensions = '.pdf, .csv, .xls, .xlsx, .json';
const ACCEPTED_FILE_SIZE = 5242880; // 5MB

export const UploadDocuments = ({
  darkMode,
  setIsUploadingDocument,
  assistant,
}) => {
  const queryClient = useQueryClient();
  const { email: username } = useSelector((state) => state?.user);
  const { name } = useSelector((state) => state?.workspace);
  const [uploadStatus, setUploadStatus] = useState(null);

  const [uploadedFile, setUploadedFile] = useState(null);
  const [fileSizeExceeded, setFileSizeExceeded] = useState(false);

  const { mutateAsync: mutateEditAssistant } = useMutateUpdateAssistant();

  const showNotification = (type, message, description) => {
    notification.open({
      type,
      message,
      description,
      placement: 'bottomRight',
      duration: 6,
    });
  };

  async function postProcessFile(documentId) {
    let isProcessed = false;
    let counter = 0;
    while (isProcessed === false) {
      const document = await getFile(name, username, documentId);
      isProcessed = document.is_processed;
      if (isProcessed === true) {
        setUploadStatus(null);
        await sleep(1000);
        showNotification(
          'success',
          'Your document has been processed successfully !',
        );
        break;
      }
      if (counter >= 15) {
        showNotification('error', 'Could not process the document !');
        setUploadStatus(null);
        break;
      }
      counter += 1;
      await sleep(10 * 1000);
    }
    queryClient.invalidateQueries(GET_UPLOADED_FILES_LISTS_KEY);
    setUploadStatus(UploadStatusType.uploaded);
  }

  const handleSavingFile = (file) => {
    const fileExtension = file.name
      .substring(file.name.lastIndexOf('.'))
      .toLowerCase();

    const isFileExtensionAllowed = AllowedFileExtensions.includes(
      fileExtension,
    );

    if (!isFileExtensionAllowed) {
      message.error('You can only upload PDF, CSV, JSON or Excel files!');
      return;
    }

    setUploadedFile(file);

    if (file.size >= ACCEPTED_FILE_SIZE) {
      setFileSizeExceeded(true);
      showNotification(
        'error',
        'Failure',
        <div>
          It seems your file exceeds the maximum allowable size of 5MB. No
          worries, you can quickly reduce the file size using the Adobe Online
          PDF Compression tool. To proceed, please{' '}
          <span
            style={{ color: '#868BEB', cursor: 'pointer' }}
            onClick={() =>
              window.open(
                'https://www.adobe.com/acrobat/online/compress-pdf.html',
              )
            }
          >
            click here.
          </span>
        </div>,
      );
      return;
    }
    setFileSizeExceeded(false);
  };

  const handleUpdateAssistant = async (documentId) => {
    if (assistant?.from_support) return;
    const cleanFileIds = assistant?.file_ids?.filter((id) => id);
    mutateEditAssistant({
      assistant_name: assistant?.assistant_name,
      assistant_id: assistant?.assistant_id,
      description: assistant?.description,
      instructions: assistant?.instructions,
      tools: assistant?.tools,
      sources: assistant?.sources,
      avatar_props: assistant?.avatar_props,
      file_ids: [...cleanFileIds, documentId],
    });
  };

  const handleUploadFile = async () => {
    setUploadStatus(UploadStatusType.uploading);
    const documentId = await uploadDocument(
      name,
      username,
      uploadedFile.name,
      uploadedFile,
    );
    if (!documentId) {
      return;
    }
    setUploadStatus(UploadStatusType.processing);
    try {
      try {
        handleUpdateAssistant(documentId);
        await processUploadedFile(name, documentId);
        await postProcessFile(documentId);
      } catch {
        await postProcessFile(documentId);
      }
    } catch (ex) {
      showNotification('error', 'Error in uploading file');
    } finally {
      setUploadedFile(null);
      setIsUploadingDocument(false);
    }
  };

  const darggerProps = {
    name: 'file',
    multiple: false, // Allow only one file upload
    accept: Object.values(AllowedFileExtensions).join(','), // Restrict accepted file types
    action: null,
    beforeUpload: (file) => {
      handleSavingFile(file);
    },
    onDrop(e) {
      handleSavingFile(e.dataTransfer.files[0]);
    },
    onRemove: () => {
      setUploadedFile(null);
    },
  };

  return (
    <div
      style={{
        backgroundColor: darkMode ? '#343641' : 'white',
        color: darkMode ? 'white' : '343641',
      }}
      className={styles.upload_component}
    >
      <div {...darggerProps}>
        {!(uploadStatus && uploadStatus !== UploadStatusType.uploaded) && (
          <Dragger
            {...darggerProps}
            className={styles.dragger_component}
            maxCount={1}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
          </Dragger>
        )}
        {uploadedFile && (
          <div>
            <h4>Selected File Details:</h4>
            <p>Name: {shortenFileName(uploadedFile.name)}</p>
            <p>Size: {formatFileSize(uploadedFile.size)}</p>
            <p>Type: {uploadedFile.type}</p>
            {!uploadStatus && !fileSizeExceeded && (
              <ButtonPrimary onClick={handleUploadFile}>Upload</ButtonPrimary>
            )}
            {fileSizeExceeded && (
              <ButtonPrimary style={{ backgroundColor: 'grey' }} disabled>
                Upload
              </ButtonPrimary>
            )}
          </div>
        )}
        {uploadStatus && uploadStatus !== UploadStatusType.uploaded && (
          <Flex direction={'column'}>
            <LoaderSpin />
            <p>{uploadStatus} Document ...</p>
          </Flex>
        )}
      </div>
    </div>
  );
};
