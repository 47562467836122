/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import React, { useEffect, useState } from 'react';
import './TLDR.css';
import axios from 'axios';

// assets
import { withStyles } from '@mui/styles';
import Badge from '@mui/material/Badge';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

// components
import Tooltip from '@mui/material/Tooltip';
import { CreditManage } from 'Component/Shared/CreditManager';
import { TLDR_COST } from 'Component/Shared/featuresCreditsCost';
import LoaderSpin from '../../../../Shared/Loader';

// logic fns
import copyClipboard, { addInEditor } from '../../../../Shared/copyToClipBoard';
import { getTranslatedText } from '../../../../Shared/TranshelperStatic';
import getTLDR from './TLDRLogic';

const WhiteonBlackTooltip = withStyles({
  tooltip: {
    color: 'white',
    backgroundColor: 'rgb(47 47 47 / 91%)',
    fontSize: '12px',
    fontWeight: 'normal',
    letterSpacing: '1px',
    padding: '10px 15px',
    textAlign: 'justify',
  },
})(Tooltip);

function TLDR() {
  const [TLDRdata, setTLDRdata] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [hasCreditOver, setHasCreditOver] = useState(false);
  const [Retry, setRetry] = useState(false);

  useEffect(() => {}, []);

  const generateHeadlines = async () => {
    setRetry(false);
    setIsLoading(true);

    const { success } = await CreditManage(TLDR_COST, true);
    if (!success) {
      setHasCreditOver(true);
      setIsLoading(false);
    } else {
      const { success, data } = await getTLDR();

      if (success) {
        const { success } = await CreditManage(TLDR_COST);

        const converted_result = await getTranslatedText(data);

        if (TLDRdata) {
          setTLDRdata([converted_result, ...TLDRdata]);
        } else {
          setTLDRdata([converted_result]);
        }

        setIsLoading(false);
      } else {
        setIsLoading(false);
        setRetry(true);
      }
    }
  };
  return (
    <div style={{ width: '100%' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="CH-btn-heding-group">
          <div>
            <Badge badgeContent="New" color="error" />

            <h4>
              TL;DR
              <WhiteonBlackTooltip
                placement="top"
                title="Too Long; Don’t Read. It helps you summarize any piece of text into concise, easy to digest content so you can free yourself from information overload."
              >
                <InfoOutlinedIcon className="mb-2 ml-1" />
              </WhiteonBlackTooltip>
            </h4>
          </div>

          {!isLoading ? (
            <>
              {hasCreditOver ? (
                <button
                  className=" p-1 px-2 rounded"
                  style={{
                    background: 'rgb(255 0 0 / 21%)',
                    color: 'rgb(224 2 2 / 81%)',
                  }}
                >
                  Credits Over
                </button>
              ) : (
                <button
                  onClick={generateHeadlines}
                  className="text-white p-1 px-2 rounded"
                  style={{ backgroundColor: 'rgb(99, 165, 240)' }}
                >
                  Generate
                </button>
              )}
            </>
          ) : (
            <div className="d-flex justify-content-around">
              <LoaderSpin />
            </div>
          )}
        </div>
        {Retry && <span className="retry-error">Oops ! Please Try Again.</span>}
        {TLDRdata &&
          TLDRdata.map((data) => (
            <div className="mt-3">
              <ul className="p-0">
                <li className="tldr-data-container">
                  <span>{data}</span>
                  <FileCopyOutlinedIcon
                    className="cursor-pointer"
                    onClick={() => addInEditor(data)}
                  />
                </li>
              </ul>
            </div>
          ))}
      </div>
    </div>
  );
}

export default TLDR;
