import React from 'react';
import { LoadingPlaceholder } from '../LoadingPlaceholder';
import { StatusIndicatorContainer, StatusIndicatorWrapper, ViewDetailsContainer } from './styles';

export const WorkspacePersonaStatusLoading = () => (

  <div>
    <LoadingPlaceholder height="15px" width="20%" marginBottom="12px" marginTop="4px" />
    <StatusIndicatorWrapper className="h-margin-t-jumbo h-margin-b-xlarge">
      {Array(7).fill(null).map((index) => (
        <StatusIndicatorContainer key={index}>
          <LoadingPlaceholder height="25px" width="25px" marginBottom="6px" />
          <LoadingPlaceholder height="15px" width="60px" />
        </StatusIndicatorContainer>
      ))}
    </StatusIndicatorWrapper>
    <ViewDetailsContainer className="h-margin-t-jumbo ">
      <LoadingPlaceholder height="10px" width="10%" />
    </ViewDetailsContainer>
  </div>
);
