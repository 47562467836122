import {
  ButtonPrimary,
  Flex,
  Input,
  InputWrapper,
  Label,
} from 'Component/Shared/GlobalStyles';
import { SourceFilter } from 'libs/enums/sourceFilter.enum';
import React, { useEffect, useState } from 'react';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { isValidURL } from 'Component/Shared/utils';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { useDispatch, useSelector } from 'react-redux';
import { initialStateWorkspaceConfig } from 'libs/DefaultStateOfWorkspaceConfig';
import CustomTooltip from 'Component/Shared/CustomTooltip';
import { useIsMobile } from 'Component/hooks/useIsMobile';
import { CheckBoxWraper, CustomGrid, URLItems, URLsWrapper } from '../styles';
import { updateWorkspaceDetails } from '../../../../../../redux';

function isObject(value) {
  return (
    !(value instanceof Date) &&
    !Array.isArray(value) &&
    !Object.is(value, null) &&
    !Object.is(value, undefined) &&
    !(value instanceof Function)
  );
}

const MarketResearchCommonInput = ({ level }) => {
  const dispatch = useDispatch();

  const isMobile = useIsMobile();

  const workspaces = useSelector(
    (state) => state?.updateWorkspaceConfiguration?.workspaces,
  );

  const [urls, setUrls] = useState([]);

  const [urlInput, setUrlInput] = useState('');
  const [sources, setSources] = useState({
    [SourceFilter.mrr]: true,
    [SourceFilter.serp]: true,
    [SourceFilter.quora]: false,
    [SourceFilter.reddit]: false,
    [SourceFilter.website]: false,
  });

  const [errorInUrl, setErrorInUrl] = useState(false);

  const handleAddUrls = () => {
    if (!isValidURL(urlInput)) {
      setErrorInUrl(true);
      return;
    }

    setUrls([urlInput]);

    const _currentWorkspaceDetails = workspaces?._currentWorkspace;
    let prevConfigurations = _currentWorkspaceDetails?.configurations;

    let prevSources = prevConfigurations[level]?.sources;

    if (!prevConfigurations) {
      prevConfigurations = {};
    }

    if (!prevSources) {
      prevSources = {};
    }

    const newDataForRedux = {
      ...initialStateWorkspaceConfig,
      ..._currentWorkspaceDetails,
      configurations: {
        ...prevConfigurations,
        [level]: {
          sources: { ...prevSources },
          urls: [...urls, urlInput],
        },
      },
    };

    dispatch(
      updateWorkspaceDetails({
        workspaces: {
          _currentWorkspace: {
            ...newDataForRedux,
          },
        },
      }),
    );

    setUrlInput('');
  };

  const addSourcesToRedux = (newSources) => {
    const _currentWorkspaceDetails = workspaces?._currentWorkspace;
    let prevConfigurations = _currentWorkspaceDetails?.configurations;

    let prevUrls = prevConfigurations[level]?.urls;

    if (!prevConfigurations) {
      prevConfigurations = {};
    }

    if (!prevUrls) {
      prevUrls = [];
    }

    const newDataForRedux = {
      ...initialStateWorkspaceConfig,
      ..._currentWorkspaceDetails,
      configurations: {
        ...prevConfigurations,
        [level]: {
          urls: { ...prevUrls },
          sources: { ...newSources },
        },
      },
    };

    dispatch(
      updateWorkspaceDetails({
        workspaces: {
          _currentWorkspace: {
            ...newDataForRedux,
          },
        },
      }),
    );
  };

  const handleDelete = (index) => {
    const urlArray = [...urls];
    urlArray.splice(index, 1);
    setUrls([...urlArray]);
  };

  useEffect(() => {
    const urlsFromRedux =
      workspaces?._currentWorkspace?.configurations[level]?.urls;
    const sourcesFromRedux =
      workspaces?._currentWorkspace?.configurations[level]?.sources;

    if (urlsFromRedux?.length > 0) {
      setUrls([...urlsFromRedux]);
    }

    if (isObject(sourcesFromRedux)) {
      setSources({ ...sourcesFromRedux });
    }
  }, [level]);

  return (
    <Flex direction="column" gap={'2rem'}>
      <CustomGrid isMobile={isMobile}>
        {Object.entries(SourceFilter).map((source) => (
          <CheckBoxWraper key={source[0]}>
            <input
              type={'checkbox'}
              onChange={(e) => {
                const newSources = {
                  ...sources,
                  [SourceFilter[source[0]]]: e.target.checked,
                };
                setSources({ ...newSources });
                addSourcesToRedux(newSources);
              }}
              checked={sources[SourceFilter[source[0]]]}
            />
            <p>{source[1]}</p>
          </CheckBoxWraper>
        ))}
      </CustomGrid>
      {sources[SourceFilter.website] && (
        <Flex direction="column">
          <Flex gap={'1rem'} direction={'column'}>
            <Flex gap={'0.5rem'}>
              <InputWrapper>
                <Label>Enter URL</Label>
                <Input
                  value={urlInput}
                  onChange={(e) => {
                    setErrorInUrl(false);
                    setUrlInput(e.target.value);
                  }}
                  onBlur={handleAddUrls}
                />
                {errorInUrl && <p className="text-danger">*Invalid URL</p>}
              </InputWrapper>
            </Flex>
            <Flex>
              <URLsWrapper>
                {urls.map((url, index) => (
                  <Flex gap={'0.01rem'} key={url + index}>
                    <CustomTooltip title={url} arrow placement="top">
                      <URLItems>{url.slice(0, 36)}</URLItems>
                    </CustomTooltip>
                    <CustomTooltip title={'Remove URL'} arrow placement="top">
                      <CancelRoundedIcon
                        className="tool-tip"
                        onClick={() => handleDelete(index)}
                      />
                    </CustomTooltip>
                  </Flex>
                ))}
              </URLsWrapper>
            </Flex>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default MarketResearchCommonInput;
