import fallBackFileImage from 'assets/chat-athena/fileIcon.svg';
import React, { useContext, useState } from 'react';
import {
  GET_UPLOADED_FILES_LISTS_KEY,
  useGetUploadedFilesLists,
} from 'queries/chat-athena/get-uploaded-files-lists';
import LoaderSpin from 'Component/Shared/Loader';
import { showNotification } from 'libs/notification';
import { useIsMobile } from 'Component/hooks/useIsMobile';
import TabSwitcher from 'Component/Shared/TabSwitcher/TabSwitcher';
import fallBackPdfImage from 'assets/chat-athena/pdf.svg';
import CustomTooltip from 'Component/Shared/CustomTooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import { useQueryClient } from 'react-query';
import styles from './document-handler.module.css';
import { SelectedFileContext } from '../contexts/SelectedFileContext';
import { shortenFileName } from './services/file-name-trimmer';
import { FileCard } from './Card/FileCard';
import { TrayButton } from '../TrayButton';
import { deleteFiles } from './services/delete-files';

const TABS = Object.freeze({
  PDF: 'PDFs',
  OTHER: 'Others',
});

export const ListDocuments = ({ setPreviewingUrl }) => {
  const queryClient = useQueryClient();

  const {
    data: documents,
    isLoading,
    isError,
    error,
    isFetching,
  } = useGetUploadedFilesLists();

  const {
    selectedFileId,
    setSelectedFileId,
    selectedToolId,
    setSelectedToolId,
  } = useContext(SelectedFileContext);

  const isMobile = useIsMobile();

  const [isPdfSelected, setIsPdfSelected] = useState(false);
  const [currentActiveTab, setCurrentActiveTab] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleFileSelect = (fileId, status, isPdf) => {
    if (status) {
      if (isPdf && !isPdfSelected) {
        setSelectedFileId([fileId]);
      } else {
        setSelectedFileId((prevSelected) =>
          isPdf ? [...(prevSelected ?? []), fileId] : [fileId],
        );
      }
      setIsPdfSelected(isPdf);
      if (selectedToolId) {
        showNotification('success', 'Document Mode Activated');
        setSelectedToolId(null);
      }
    } else {
      setSelectedFileId((prevSelected) =>
        prevSelected ? prevSelected.filter((id) => id !== fileId) : null,
      );
    }
  };

  if (isLoading || isFetching) {
    return (
      <div className={styles.all_documents}>
        <LoaderSpin />
        <p>Loading Documents...</p>
      </div>
    );
  }

  if (documents?.length === 0) {
    return null;
  }

  // Separate PDF files from other files
  const pdfFiles = documents.filter(
    (doc) => doc.is_processed && doc.file_name.toLowerCase().endsWith('.pdf'),
  );
  const otherFiles = documents.filter(
    (doc) => doc.is_processed && !doc.file_name.toLowerCase().endsWith('.pdf'),
  );

  const handleDelete = async () => {
    setIsDeleting(true);
    const isDeleted = await deleteFiles(selectedFileId);
    if (isDeleted) {
      queryClient.invalidateQueries(GET_UPLOADED_FILES_LISTS_KEY);
      setSelectedFileId([]);
      showNotification('success', 'Files deleted successfully');
    } else {
      showNotification('error', 'Error deleting files');
    }
    setIsDeleting(false);
  };

  return (
    <div className={styles.all_documents}>
      <TabSwitcher
        tab1={TABS.PDF}
        tab2={TABS.OTHER}
        currentActiveTab={currentActiveTab}
        setSwitcher={setCurrentActiveTab}
      />
      <div className={styles.document_list_wrapper}>
        {currentActiveTab === 0 && (
          <>
            <div className={styles.document_header}>
              <div>
                <h6>PDF Files</h6>
                <small className="text-warning">
                  Pro Tip: Double {isMobile ? 'Tap' : 'Click'} to Preview
                  Document
                </small>
              </div>
              {isDeleting ? (
                <LoaderSpin />
              ) : (
                <CustomTooltip
                  title={
                    selectedFileId?.length
                      ? 'Selected files will be deleted'
                      : 'Please select files to delete'
                  }
                  placement="top"
                  arrow
                >
                  <div>
                    <TrayButton
                      Icon={DeleteIcon}
                      danger
                      label={'Delete Files'}
                      onClick={handleDelete}
                      disabled={!selectedFileId?.length}
                    />
                  </div>
                </CustomTooltip>
              )}
            </div>
            {pdfFiles.length > 0 && (
              <div className={styles.document_list}>
                {pdfFiles.map((doc) => (
                  <div className={styles.document} key={doc.file_id}>
                    <FileCard
                      fileName={shortenFileName(doc.file_name)}
                      imgSrc={doc.thumbnail_url}
                      checked={(selectedFileId ?? []).includes(doc.file_id)}
                      fallBackFileImage={fallBackPdfImage}
                      setPreviewingUrl={setPreviewingUrl}
                      handleViewFile={() => setPreviewingUrl(doc.url)}
                      onChange={(e) =>
                        handleFileSelect(doc.file_id, e.target.checked, true)
                      }
                    />
                  </div>
                ))}
              </div>
            )}
          </>
        )}
        {currentActiveTab === 1 && (
          <>
            <h6>Other Files</h6>
            <small className="text-warning">
              Pro Tip: Double Click to Preview Document
            </small>
            {otherFiles.length > 0 && (
              <div className={styles.document_list}>
                {otherFiles.map((doc) => (
                  <div className={styles.document} key={doc.file_id}>
                    <FileCard
                      fileName={shortenFileName(doc.file_name)}
                      imgSrc={doc.thumbnail_url}
                      checked={(selectedFileId ?? []).includes(doc.file_id)}
                      fallBackFileImage={fallBackFileImage}
                      handleViewFile={() => setPreviewingUrl(doc.url)}
                      onChange={(e) =>
                        handleFileSelect(doc.file_id, e.target.checked, false)
                      }
                    />
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </div>
      {isError && (
        <p className="text-danger">Error in Loading Documents: {error}</p>
      )}
    </div>
  );
};
