import React from 'react';
import { useEditWorkspaceMutation } from 'queries/workspace/update-workspace';
import { LoaderTailSpin } from 'components/common';
import { LikeIcon, UnLikeIcon } from '../styles';

export function FavouriteWorkspace({ workspaceName, isFavourite }) {
  const { mutate: mutateWorkspace, isLoading } = useEditWorkspaceMutation();

  const handleToggleFavourite = async () => {
    await mutateWorkspace({
      is_favourite: !isFavourite,
      name: workspaceName,
    });
  };

  if (isLoading) {
    return <LoaderTailSpin className="cursor-pointer" height={20} width={20} />;
  }

  return isFavourite ? (
    <LikeIcon className="h-margin-r-x2" onClick={handleToggleFavourite} />
  ) : (
    <UnLikeIcon className="h-margin-r-x2" onClick={handleToggleFavourite} />
  );
}
