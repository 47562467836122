import React from 'react';
import { useGetBrandVoiceData } from 'queries/brand-voice/brand-voice-data';
import LoaderSpin from 'Component/Shared/Loader';
import { Flex } from 'Component/Shared/GlobalStyles';
import { useDeleteBrandVoice } from 'queries/brand-voice/delete-brand-voice-data';
import BrandVoiceCard from './BrandVoiceCard';
import styles from '../style.module.css';
import NoData from './NoData';

const SavedBrandVoices = () => {
  const {
    data: brandVoiceList,
    isLoading,
    isError,
    error,
  } = useGetBrandVoiceData();
  const { mutate: deleteBrandVoice } = useDeleteBrandVoice();

  const handleDeleteBrandVoice = (brandVoiceName) => {
    deleteBrandVoice(brandVoiceName);
  };

  if (isLoading) {
    return (
      <Flex justify={'center'}>
        <LoaderSpin />
      </Flex>
    );
  }

  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className={styles.card_container}>
      {brandVoiceList?.map((brandVoice) => (
        <div
          key={brandVoice.brand_voice_name}
        >
          <BrandVoiceCard
            brand_voice_name={brandVoice.brand_voice_name}
            created_at={brandVoice.created_at}
            description={brandVoice.description}
            onDelete={() => handleDeleteBrandVoice(brandVoice.brand_voice_name)}
          />
        </div>
      ))}

      {brandVoiceList.length === 0 && <NoData />}
    </div>
  );
};

export default SavedBrandVoices;
