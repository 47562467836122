import axios from 'axios';
import { baseAPIsUrl } from 'Config/Api';

async function getBrandVoiceDescriptionByType(type) {
  try {
    const { data } = await axios.get(
      `${baseAPIsUrl}/get-brand-voice-settings?type=${type}`,
    );
    return data.data;
  } catch (err) {
    console.log('Error in loading Description');
    return null;
  }
}

export { getBrandVoiceDescriptionByType };
