/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import './Lockcontentautomation.css';
import { Link } from 'react-router-dom';

const customStyles = {
  content: {
    width: '600px',
    height: '300px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

function LockContentAutomation() {
  const { is_trained } = useSelector((state) => state.workspace);
  const [blockContentAutomation, setBlockContentAutomation] = useState(true);
  useEffect(() => {
    if (!showOptionsToSwitch() || isForLevelTwoBlock()) {
      setBlockContentAutomation(false);
      return;
    }
    if (is_trained) {
      setBlockContentAutomation(false);
    }
  }, []);

  const isForLevelTwoBlock = () => {
    const currentPathname = window.location.pathname;

    if (
      currentPathname === '/dashboard'
      || currentPathname === '/Market-Resonance'
    ) {
      return true;
    }
    return false;
  };

  const showOptionsToSwitch = () => {
    const currentPathname = window.location.pathname;

    if (
      currentPathname === '/AIContentWriter'
      || currentPathname === '/SalesCopyWritingWizard'
      || currentPathname === '/Market-Resonance'
    ) {
      return true;
    }
    return false;
  };

  const openModalWithButton = () => {
    setBlockContentAutomation(true);
  };

  const handleClose = () => {
    if (!showOptionsToSwitch() || isForLevelTwoBlock()) {
      setBlockContentAutomation(false);
    }
  };
  if (blockContentAutomation) {
    return (
      <Modal
        isOpen={blockContentAutomation}
        onRequestClose={handleClose}
        style={customStyles}
        ariaHideApp={false}
      >
        <div className="lock-content-automation-container">
          <h4 className="font-primary text-primary">Oops! 😬 </h4>
          <p className="mb-0">Looks like you have not trained Athena.</p>
          <p>
            Go to Dashboard and Power-Up Athena or switch to a different
            workspace to get the best of CrawlQ’s content research and
            automated content writing.
          </p>
          <div className="lock-content-automation-btn-group">
            {showOptionsToSwitch() ? (
              <>
                <Link to="/Workspace">
                  <button className="train-athena">Switch Workspace</button>
                </Link>
                <Link to="/dashboard">
                  <button className="train-athena">
                    Take Me to Dashboard
                  </button>
                </Link>
              </>
            ) : (
              <a>
                <button
                  className="train-athena"
                  onClick={() => setBlockContentAutomation(false)}
                >
                  OK
                </button>
              </a>
            )}
          </div>
        </div>
      </Modal>
    );
  }
  if (!showOptionsToSwitch() || isForLevelTwoBlock()) {
    return (
      <button
        style={{ display: 'none' }}
        id="open-lockmodal-content-Automation"
        onClick={openModalWithButton}
      />
    );
  }
  return <></>;
}

export default LockContentAutomation;
