/* eslint-disable no-unreachable */
/* eslint-disable radix */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import axios from 'axios';
import createInstance from 'Config/AxiosConfig';

const UpdateCreditLimit = async (IncrementCredit) => {
  try {
    const body = {
      amount: parseInt(IncrementCredit),
      check: false,
    };

    const instance = await createInstance();

    const result = await instance.post('charge-credits', body);

    if (result.data.success === 'true') {
      if (result.data.total_credits === '∞') {
        result.data.total_credits = Number.MAX_SAFE_INTEGER;
        result.data.credits = 0;
      }
      return {
        user_credit: result.data.credits,
        total_credit: result.data.total_credits,
        success: 'true',
      };
    }
    return {
      success: 'false',
    };
  } catch (err) {
    console.log(err);
    return {
      success: false,
    };
  }
};

export const CreditManage = async (cost, check_credit = false) => {
  try {
    const instance = await createInstance();

    const req_body = {
      amount: parseInt(cost),
      check: check_credit,
    };

    const response = await instance.post('charge-credits', req_body);

    // response value
    const { success } = response.data;

    // converting response value into boolean
    const is_possible = success === 'true';

    if (check_credit) {
      // true - update possible , false - credit update not possible
      return {
        success: is_possible,
      };
    }

    // update success or not , true - updated, false - credit not available
    return {
      success: is_possible,
    };
  } catch (error) {
    console.log(error);
    return {
      success: false,
    };
  }
};

export default UpdateCreditLimit;
