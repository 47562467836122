/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import React, { useState } from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import './unlockLevel.css';
import { CloseRounded } from '@mui/icons-material';

const customStyles = {
  content: {
    width: '600px',
    height: '350px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
function UnlockLevel({ heading, description }) {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <button className="train-athena" onClick={() => setOpen(true)}>
        Open Modal
      </button>
      <Modal
        isOpen={open}
        onRequestClose={() => setOpen(false)}
        style={customStyles}
      >
        <div className="unlock-container">
          <div className="unlock-desc">
            <span
              style={{ flex: 10, marginLeft: '5rem' }}
              className="font-accent txt-primary text-center"
            >
              {heading}
              {' '}
              &#127942;
            </span>
            <span className="unock-close-btn" onClick={() => setOpen(false)}>
              <CloseRounded />
            </span>
          </div>
          <hr />
          <div className="unlock-heading">
            <span>Awesome! You are now ready to train Athena 🎉</span>
            <span className="unlock-smallDesc">{description}</span>
            <br />
            <p>
              ⚠️ Have you reviewed all the answers? If yes, Go to Dashboard and
              Train Athena. If not, we suggest you to review them first.
            </p>
          </div>
          <div className="unlock-btn-group">
            <Link to="/dashboard">
              <button className="unlock-btn">Take Me To Dashboard</button>
            </Link>
            <div>
              <button className="unlock-btn" onClick={() => setOpen(false)}>
                Review Now
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default UnlockLevel;
