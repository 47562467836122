/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
export default function copyClipboard(text, withoutFormatting = false) {
  text = text.replaceAll('<br>', '\n');
  text = text.replaceAll('<hr>', '\n');
  const paragraph = document.createElement('textarea');
  document.body.appendChild(paragraph);
  const span_ = document.createElement('span');
  span_.innerHTML = text;
  if (withoutFormatting) {
    paragraph.value = span_.innerText;
  } else {
    paragraph.value = span_.innerHTML;
  }
  paragraph.select();
  document.execCommand('copy');
  document.body.removeChild(paragraph);

  // alert.success('Copied')
}

function clearSelection() {
  if (window.getSelection) {
    window.getSelection().removeAllRanges();
  } else if (document.selection) {
    document.selection.empty();
  }
}

export function selectTextAndCopy(node) {
  node = document.getElementById(node);

  if (document.body.createTextRange) {
    const range = document.body.createTextRange();
    range.moveToElementText(node);
    range.select();
  } else if (window.getSelection) {
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(node);
    selection.removeAllRanges();
    selection.addRange(range);
  } else {
    console.warn('Could not select text in node: Unsupported browser.');
  }
  document.execCommand('copy');
  setTimeout(() => {
    clearSelection();
  }, 100);
}

export function selectTextAndCopyWithElement(element) {
  try {
    const node = element.previousElementSibling;

    if (document.body.createTextRange) {
      const range = document.body.createTextRange();
      range.moveToElementText(node);
      range.select();
    } else if (window.getSelection) {
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(node);
      selection.removeAllRanges();
      selection.addRange(range);
    } else {
      console.warn('Could not select text in node: Unsupported browser.');
    }
    document.execCommand('copy');
    setTimeout(() => {
      clearSelection();
    }, 100);
  } catch (ex) {
    console.log('Cannot copy', ex);
  }
}

export function addInEditor(newContent) {
  const editor = window.tinymce.activeEditor;
  const editorContent = editor.getContent();
  editor.setContent(`${editorContent}
                              ${newContent}`);
  const height = editor.getBody().scrollHeight;
  editor.getWin().scrollTo(0, height);
}
