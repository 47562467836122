/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button, { TabSwitcheWrapper, TabContainer } from './styles';

function MRR_TabSwitcher() {
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(0);
  const handleTabSwitch = (tab) => {
    setCurrentTab(tab);
  };

  const isActive = (url) => window.location.href.includes(url);

  return (
    <TabContainer>
      <TabSwitcheWrapper>
        <Button
          onClick={() => navigate('/MRR-Get-Started')}
          active={isActive('/MRR-Get-Started')}
        >
          Attract
        </Button>
        <Button
          onClick={() => {
            localStorage.setItem('category', 'Niche');
            window.location.assign('/MarketResearchCategory?level=2');
          }}
          active={isActive('/MarketResearchCategory?level=2')}
        >
          Desire
        </Button>
        <Button
          onClick={() => navigate('/Market-Resonance-Level-3')}
          active={
            isActive('/MarketResearchCategory?level=3') ||
            isActive('Market-Resonance-Level-3')
          }
        >
          Convert
        </Button>
      </TabSwitcheWrapper>
    </TabContainer>
  );
}

export default MRR_TabSwitcher;
