import { motion_variants } from 'constants/content/content-tools';
import React from 'react';
import AppHeaderWrapper from 'components/common/AppHeader/AppHeaderWrapper';
import { motion } from 'framer-motion';
import { ScaffoldCard } from 'components/common/scaffold-card';
import { ContentProvider, useContentContext } from 'context/content/ContentContext';
import ContentTools from './content-tools/ContentTools';
import ContentEditor from './content-editor/ContentEditor';
import styles from './content.module.css';

function Content() {
  const { contentToolsVisiblity } = useContentContext();

  const [ShowContentTools] = contentToolsVisiblity;

  return (
    <AppHeaderWrapper className={styles.wrapper}>
      <div className={styles.container}>
        <ScaffoldCard className={styles.main_section}>
          <ContentEditor />
        </ScaffoldCard>
        <motion.div
          className={styles.aside_section}
          animate={ShowContentTools ? 'show' : 'hide'}
          variants={motion_variants}
        >
          <ScaffoldCard className={styles.aside_child}>
            <ContentTools />
          </ScaffoldCard>

        </motion.div>
      </div>
    </AppHeaderWrapper>
  );
}

export default Content;
