import React from 'react';
import moment from 'moment';
import { useSyncConnectorMutation } from 'queries/chat-athena/fivetran/mutate-fivetran-sync-app';
import { LoaderTailSpin, SyncingIcon } from 'components/common';
import cx from 'classnames';
import { ClockLoader } from 'react-spinners';
import { Colors } from 'libs/colors';
import { FiVETRAN_APP_INTEGRATION_STATUS } from 'libs/fivetran-constant';
import styles from '../index.module.css';
import localStyles from './index.module.css';

export const ConnetedApps = ({ app }) => {
  const {
    mutate: mutateSyncConnector,
    isLoading: isSyncingApp,
  } = useSyncConnectorMutation();

  const handleSyncApp = (app) => {
    if (isSyncingApp) return;
    mutateSyncConnector({ connector_id: app.connector_id });
  };

  return (
    <div className={styles.app_item_wapper}>
      <div className={styles.app_item_left}>
        <div className={localStyles.left_wrapper}>
          <div className={styles.connected_app_details}>
            <p>{app.display_name}</p>
            <small>
              Connected Date: {moment(app.created_at).format('MMM Do YYYY')}
            </small>
          </div>
          {app.status === FiVETRAN_APP_INTEGRATION_STATUS.SYNCING && (
            <ClockLoader color={Colors.yellow} size={'20px'} />
          )}
        </div>
      </div>

      <div
        className={cx(styles.connect_button, {
          [styles.button_disabled]: isSyncingApp,
        })}
        onClick={() => handleSyncApp(app)}
      >
        <SyncingIcon />
        {isSyncingApp ? <LoaderTailSpin width={'20px'} /> : <p>Sync </p>}
      </div>
    </div>
  );
};
