import React from 'react';
import AudienceEmpathyScore from './AudienceEmpathyScore/AudienceEmpathyScore';
import TextSummarizer from './TextSummarizer/TextSummarizer';
import TLDR from './TLDR/tldr';
import HeadlineIntros from './HeadlineIntros/HeadlineIntros';
import styles from './overview.module.css';

function Overview() {
  return (
    <div className={styles.overview_container}>
      <AudienceEmpathyScore />
      <HeadlineIntros />
      <TextSummarizer />
      <TLDR />

    </div>
  );
}

export default Overview;
