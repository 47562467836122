import axios from 'axios';

export const getAllSavedDocsBothSalesAndContent = async (
  isFromSales = false,
) => {
  try {
    let body = {
      username: localStorage.getItem('username'),
      name: localStorage.getItem('name'),
      source: '',
    };

    if (isFromSales) {
      body = { ...body, source: 'Sales Copywriter' };
    }

    const { data } = await axios.post(

      'https://apis.crawlq.ai/ai-writer-select',

      body,
    );
    return { error: false, docs: data.blog };
  } catch (ex) {
    console.log('Error in getting SavedDoc', ex);
    return { error: true, docs: [] };
  }
};

export default getAllSavedDocsBothSalesAndContent;
