module.exports = [
  {
    category: 'Welcome to CrawlQ ',
    child: [
      {
        videoTitle: 'CrawlQ Resources',
        videoLink: 'https://www.youtube.com/embed/8fB0LZ7sEaQ',
      },
      {
        videoTitle: 'Ultimate Guide to Discover Your FRED',
        videoLink: 'https://share.vidyard.com/watch/sA7Em3vj9nprRQEUJfpjKH',
      },
      {
        videoTitle: 'Market Research: How to Channel Mass Desires and identify Fears, Failures an Suspicions',
        videoLink: 'https://share.vidyard.com/watch/Y5RXChgAcbFENi2zLGDt8Y',
      },
      {
        videoTitle: 'Market Research: How to Map Features, Benefits, Value and Jobs to be Done to Sales',
        videoLink: 'https://share.vidyard.com/watch/DDRDvTrwJagjQPniRpHCPT',
      },

    ],
  },
  {
    category: 'CrawlQ Master Thesis',
    child: [
      {
        videoTitle: 'Master Thesis',
        videoLink: 'https://share.vidyard.com/watch/LP5mj7xUoWkcdnjVeHR8wR',
      },

    ],
  },
  {
    category: 'User Guiding Videos',
    child: [
      {
        videoTitle: 'User Onboarding Video',
        videoLink: 'https://share.vidyard.com/watch/M4UiDMqkrjpRx5S8bzBGbf',
      },

    ],
  },
  {
    category: 'CrawlQ Copywriting Framework',
    child: [
      {
        videoTitle: 'CrawlQ Foundational Copywriting Framework',
        videoLink: 'https://share.vidyard.com/watch/bxpRHdK1PX7Qcd86M4NC5N',
      },
      {
        videoTitle: 'How To Channel Mass Desire',
        videoLink: 'https://share.vidyard.com/watch/4pboqezKAgB1NFa8V9hXW4',
      },

      {
        videoTitle: "5 Stages of FRED's Awareness",
        videoLink: 'https://share.vidyard.com/watch/DRbB7mX7B8wr87YnvsmWp7',
      },

      {
        videoTitle: 'Your Claim and Mechanism to your Market',
        videoLink: 'https://share.vidyard.com/watch/tpdBUhjoohbAAij6JEDAbM',
      },
      {
        videoTitle: 'Express Personality of your Product',
        videoLink: 'https://share.vidyard.com/watch/gyK5HhMYNf17aak9mLuXZx',
      },

    ],
  },
  {
    category: 'Use Cases',
    child: [
      {
        videoTitle: 'Use Case Smups Games',
        videoLink: 'https://share.vidyard.com/watch/Uqqw5u6gSrriCRkSFA5qeU',
      },
      {
        videoTitle: 'Turn your restaurant to Uber of Kitchen',
        videoLink: 'https://share.vidyard.com/watch/xjLYbCCW2nQMJNpHd4NMrC',
      },

    ],
  },
  {
    category: 'Tips and Tricks',
    child: [
      {
        videoTitle: 'Language Hack',
        videoLink: 'https://share.vidyard.com/watch/RJQ6tr8KVXmhSKRTziDdcd',
      },

    ],
  },
];
