/* eslint-disable default-param-last */
import {
  SET_ACTIVE_WORKSPACE,
  SET_LANGUAGE_ENGINE,
  SET_QUEUED_WORKSPACE_FROM_START_CAMPAIGN,
} from './ActiveWorkspaceTypes';

const initialState = {
  name: '',
  domain: '',
  language: '',
  language_engine: 0, // 0 -> google 1-> DeepL
  is_trained: false, // athena_trained false default
  mti: null,
  queuedWorkspace: '',
};

const activeWorkspaceReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_WORKSPACE:
      return {
        ...state,
        ...action.payload,
      };
    case SET_LANGUAGE_ENGINE:
      return {
        ...state,
        language_engine: action.payload,
      };
    case SET_QUEUED_WORKSPACE_FROM_START_CAMPAIGN:
      return {
        ...state,
        queuedWorkspace: action.payload,
      };
    default:
      return state;
  }
};

export default activeWorkspaceReducer;
