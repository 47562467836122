/* eslint-disable max-len */
import { useQuery } from 'react-query';
import createInstance from 'Config/AxiosConfig';

const getIsCreditAvailable = async (cost) => {
  const instance = await createInstance();

  const payload = {
    amount: parseInt(cost, 10),
    check: true,
  };

  const response = await instance.post('charge-credits', payload);

  if (response?.data?.statusCode === '200' && response?.data?.success === 'true') return response?.data;

  if (response?.data?.statusCode === '200' && response?.data?.success === 'false') return false;

  throw new Error('Zero Credits Remaining, please add-on extra credits to avoid stoppage to productivity');
};

// Keep Query Value as File Name to maintain consistency and uniqueness in query index
export const GET_CREDITS_QUERY_KEY = 'get-credits-query-key';

// Query Config Options
const queryOptions = {
  staleTime: 30000,
};

// QUERY
export const useGetIsCreditAvailable = (cost) =>
  useQuery(GET_CREDITS_QUERY_KEY, () => getIsCreditAvailable(cost), queryOptions);
