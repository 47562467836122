import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './styles.css';
import { Divider } from 'components/common/divider';
import RefreshIcon from '@mui/icons-material/Refresh';
import { ScaffoldCard } from 'components/common/scaffold-card';
import { Link, useNavigate } from 'react-router-dom';
import { addNewTab, removeTab, workspaceSelect } from 'redux/index';
import { useDispatch, useSelector } from 'react-redux';
import StatusIndicator from './StatusIndicator';
import { CompleteIcon } from './styles';

const TRIAGE = 'triage';
const COMPLETE = 'complete';

const PersonaProgressStatus = ({
  personaName,
  personaTopic,
  personaStatus,
  isNewWorkspace }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const tabs = useSelector((state) => state?.tabManager?.tabs);
  const currentTab = useSelector((state) => state?.tabManager?.currentTab);

  const checkPersonaComplete = () => {
    if (personaStatus?.[5]?.stage === TRIAGE &&
      personaStatus?.[5]?.current_status === COMPLETE) {
      setTimeout(() => {
        // localStorage.setItem('name', personaName);
        // localStorage.setItem('project_name', personaName);

        dispatch(workspaceSelect({
          name: personaName,
          is_trained: true,
          mti: personaTopic,
        }));

        dispatch(removeTab(currentTab?.id));

        dispatch(addNewTab({
          id: (tabs.length),
          name: 'Market Research',
          workspaceName: personaName,
          path: '/MRR-Get-Started',
        }));

        navigate('/MRR-Get-Started');
      }, 5000);
    }
  };

  useEffect(() => {
    if (isNewWorkspace) {
      checkPersonaComplete();
    }
  }, [personaStatus]);

  return (
    <ScaffoldCard className="persona-progress-status-wrapper h-margin-b-small">
      <div className="persona-progress-status-header-wrapper">
        <span className="persona-progress-status-workspace-name">{personaName}</span>
        <RefreshIcon style={{ width: '1rem', cursor: 'pointer' }} />
      </div>
      <Divider />
      <div className="persona-progress-status-indicator-wrapper">

        {/* Default State To Begin With */}
        <div className="persona-progress-status-indicator">
          <CompleteIcon />
          <div>Seed</div>
        </div>

        {/* Stages mapped throught API response */}
        {personaStatus.map((personaStatus) => (
          <StatusIndicator
            key={personaStatus?.stage}
            stage={personaStatus?.stage}
            status={personaStatus?.current_status}
          />
        ))}
      </div>
      {isNewWorkspace === false && (
      <div className="persona-progress-status-view-details">
        <Link to={`/workspace-persona-status?workspace=${personaName}`}>
          View Details
        </Link>
      </div>
      )}

    </ScaffoldCard>
  );
};

PersonaProgressStatus.defaultProps = {
  personaName: PropTypes.string.isRequired,
  personaTopic: false,
  isFromAllPersonaStatus: false,
  personaStatus: PropTypes.arrayOf(PropTypes.shape({
    completed_at: PropTypes.string,
    current_status: PropTypes.string,
    details: PropTypes.string,
    stage: PropTypes.string,
  },
  )).isRequired,
};

export default PersonaProgressStatus;
