/* eslint-disable global-require */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import React, { useState, useEffect } from 'react';
import './DoneForYouWorkspace.css';
import { useSelector } from 'react-redux';
import Modal from 'react-modal';
import ReactTooltip from 'react-tooltip';

// components
import CurateWorkspace from 'Component/Pages/Workspaces/NewUserLandingPage/CurateWorkspace/CurateWorkspace';
import QueryBuilderRoundedIcon from '@mui/icons-material/QueryBuilderRounded';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';

// assets

// logic fns
import { LoaderTailSpin } from 'Component/Shared/Loader';
import CustomTooltip from 'Component/Shared/CustomTooltip';
import fetchDFYWorkspaces, {
  fetchDFYWorkspaceForUsers,
  copyBuyDFY,
  addToMyWorkspaceRequest,
} from './DoneForYouWorkspaceLogic';
import { DoneForYouPreviewLogicRequest } from './DoneforyouPreviewLogic';
import DemoWorkspacePreview from '../DemoWorkspace/DemoWorkspacePreview/DemoWorkspacePreview';
import AddDFYWorkspace from './AddDFYWorkspace/AddDFYWorkspace';
import UpdateDFYWorkspace from './UpdateDFYWorkspace/UpdateDFYWorkspace';
import { WORKSPACE } from 'libs/constants';

const ModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

function DoneForYouWorkspace() {
  // constants
  // const DoneForYouAdmin = 'doneforyou@quantamixsolutions.com'
  const DoneForYouAdmin = 'cus_LBy2V9HUNZTjnY';

  // Redux Values
  const GlobalUsername = useSelector((state) => state.user.username);
  const GlobalUserGroup = useSelector((state) => state.user.group);

  // States
  const [
    DoneForYouWorkspaceListLoader,
    setDoneForYouWorkspaceListLoader,
  ] = useState(false);
  const [DoneForYouWorkspaceList, setDoneForYouWorkspaceList] = useState(false);
  const [IsOpenCurateWorkspaceModal, setIsOpenCurateWorkspaceModal] = useState(
    false,
  );
  const [
    IsOpenUpdateDFYWorkspaceModal,
    setIsOpenUpdateDFYWorkspaceModal,
  ] = useState(false);
  const [IsOpenAddDFYWorkspaceModal, setIsOpenAddDFYWorkspaceModal] = useState(
    false,
  );
  const [ChoosenDFY, setChoosenDFY] = useState({
    name: '',
    domain: '',
  });

  const [Admin, setAdmin] = useState(false);
  const [FetchingDFYForAdmin, setFetchingDFYForAdmin] = useState(true);
  const [BuyLoader, setBuyLoader] = useState(false);
  const [ShowPreview, setShowPreview] = useState(false);
  const [
    showErrorInAddingToWorkspace,
    setShowErrorInAddingToWorkspace,
  ] = useState(false);

  const [hasAddedToMyWorkspace, setHasAddedToMyWorkspace] = useState(false);
  const [isAddingToMyWorkspace, setIsAddingToMyWorkspace] = useState(false);

  useEffect(() => {
    // Check logged In User Admin / Non-Admin ( email id )
    if (GlobalUserGroup === DoneForYouAdmin) {
      setAdmin(true);
      getDFYWorkspacesAdmin();
    } else {
      getDFYWorkspacesForUsers();
    }
  }, []);

  // To fetch DFY Workspaces if logged in as Non-Admin
  const getDFYWorkspacesForUsers = async () => {
    setDoneForYouWorkspaceListLoader(true);

    const { data, error } = await fetchDFYWorkspaceForUsers(GlobalUsername);

    if (!error) {
      if (data.length > 0) {
        console.log('1');
        setDoneForYouWorkspaceList(data);

        setDoneForYouWorkspaceListLoader(false);
      } else {
        console.log('2');

        setDoneForYouWorkspaceList(false);
        setDoneForYouWorkspaceListLoader(false);
      }
    } else {
      console.log('3');

      setDoneForYouWorkspaceListLoader(false);
    }
  };

  // To fetch DFY Workspaces if logged in as Admin
  const getDFYWorkspacesAdmin = async () => {
    const { data, error } = await fetchDFYWorkspaces();

    if (!error) {
      setDoneForYouWorkspaceList(data);
      setFetchingDFYForAdmin(false);
    } else {
      setFetchingDFYForAdmin(true);
    }
  };

  // To Add to my Workspace
  const handleAddToMyWorkspace = async (name, domain, creator_email) => {
    setShowErrorInAddingToWorkspace(false);
    setHasAddedToMyWorkspace(false);
    setIsAddingToMyWorkspace(true);
    const { data, error } = await addToMyWorkspaceRequest(
      name,
      domain,
      creator_email,
    );
    if (error) {
      setShowErrorInAddingToWorkspace(true);
      setTimeout(() => {
        setShowErrorInAddingToWorkspace(false);
      }, 3000);
      return;
    }
    setHasAddedToMyWorkspace(true);
    setIsAddingToMyWorkspace(false);
  };

  // Modal Pop Up // Component
  function CurateWorkspaceModal() {
    return (
      <Modal
        isOpen={IsOpenCurateWorkspaceModal}
        onRequestClose={() => setIsOpenCurateWorkspaceModal(false)}
        style={ModalStyles}
        ariaHideApp={false}
      >
        <CurateWorkspace />
      </Modal>
    );
  }

  // Modal Pop Up Update // Component
  function UpdateDFYWorkspaceModal() {
    return (
      <Modal
        isOpen={IsOpenUpdateDFYWorkspaceModal}
        onRequestClose={() => setIsOpenUpdateDFYWorkspaceModal(false)}
        style={ModalStyles}
        ariaHideApp={false}
      >
        <UpdateDFYWorkspace choosen={ChoosenDFY} />
      </Modal>
    );
  }

  // Modal Pop Up Add // Component
  function DoneDFYWorkspaceModal() {
    return (
      <Modal
        isOpen={IsOpenAddDFYWorkspaceModal}
        onRequestClose={() => setIsOpenAddDFYWorkspaceModal(false)}
        style={ModalStyles}
        ariaHideApp={false}
      >
        <AddDFYWorkspace
          choosen={ChoosenDFY}
          close_modal={handleCloseDoneForYouModal}
        />
      </Modal>
    );
  }

  // Component
  function WorkspaceTableHeader() {
    return (
      <div className="demo-workspace-table-column-header">
        <span
          className="demo-column-header-name"
          // onClick={() => handleSorting('name', !currentSortedKey.asc)}
        >
          NAME
          {/* {currentSortedKey.key === 'name' && <>{showSortingIcon()}</>} */}
        </span>
        <span
          className="demo-column-header-domain"
          // onClick={() => handleSorting('site', !currentSortedKey.asc)}
        >
          DOMAIN
          {/* {currentSortedKey.key === 'site' && <>{showSortingIcon()}</>} */}
        </span>
        <span
          className="demo-column-header-language"
          // onClick={() => handleSorting('Lang', !currentSortedKey.asc)}
        >
          LANGUAGE
          {/* {currentSortedKey.key === 'Lang' && <>{showSortingIcon()}</>} */}
        </span>
        <span
          className="demo-column-header-creation-date"
          // onClick={() => handleSorting('date_purchased', !currentSortedKey.asc)}
          style={{ justifyContent: 'center' }}
        >
          REQUESTED ON
          {/* {currentSortedKey.key === 'date_purchased' && (
                        <>{showSortingIcon()}</>
                    )} */}
        </span>
        <span
          style={{ justifyContent: 'center' }}
          className="demo-column-header-copy"
        >
          STATUS
        </span>
        <span className="demo-column-header-creation-date" />
        <span className="demo-column-header-more">PREVIEW</span>
      </div>
    );
  }

  // Component
  function AdminWorkspaceTableHeader() {
    return (
      <div className="demo-workspace-table-column-admin-header">
        <span className="admin-header-name">NAME</span>
        <span className="admin-header-domain">DOMAIN</span>
        <span className="admin-header-status">CREATION</span>
        <span className="admin-header-done">ASSIGN</span>
        <span className="admin-header-update">UPDATE</span>
      </div>
    );
  }

  // Fn to open Update DFY Modal
  const handleUpdateWorkspace = (name, domain) => {
    setChoosenDFY({
      name,
      domain,
    });
    setIsOpenUpdateDFYWorkspaceModal(true);
  };

  // Fn to open Add DFY Modal
  const handleAddWorkspace = (name, domain) => {
    setChoosenDFY({
      name,
      domain,
    });
    setIsOpenAddDFYWorkspaceModal(true);
  };

  // To Buy Requested DFY -> Non Admin
  const handleBuyCopyDFY = async (workspace_name, index, creator_email) => {
    setBuyLoader(index); // setting loader as per clicked row index

    const { success, url } = await copyBuyDFY(workspace_name, creator_email);
    if (success) {
      window.open(url, '_self'); // to open stipe payment gateway in current tab
      setBuyLoader(false);
    } else {
      setBuyLoader(false);
    }
  };

  // Function to close Modal - passed down as props
  const handleCloseDoneForYouModal = () => {
    setIsOpenAddDFYWorkspaceModal(false);
  };

  // Component
  function AdminDoneForYouRows({ key, name, domain, date_created }) {
    return (
      <div className="demo-workspace-data-container">
        <div className="demo-workspace-table-row-data">
          <span className="admin-dfy-name">{name}</span>
          <span className="admin-dfy-domain">{domain}</span>
          <span className="admin-dfy-status">{date_created}</span>
          <span className="admin-dfy-done">
            <button onClick={() => handleAddWorkspace(name, domain)}>
              Assign Workspace
            </button>
          </span>
          <span className="admin-dfy-update">
            <button onClick={() => handleUpdateWorkspace(name, domain)}>
              Update
            </button>
          </span>
        </div>
      </div>
    );
  }

  const handleDemoPreview = (index) => {
    if (ShowPreview === index) {
      setShowPreview(false);
    } else {
      setShowPreview(index);
    }
  };

  // Component
  function UserDoneForYouRows({
    index,
    name,
    domain,
    language,
    req_date,
    status,
    available,
    creator_email,
  }) {
    return (
      <div className="demo-workspace-data-container">
        <ReactTooltip />
        <div className="demo-workspace-table-row-data">
          <span className="user-dfy-name">{name}</span>
          <span className="user-dfy-domain">{domain}</span>
          <span className="user-dfy-language">{language}</span>
          <span className="user-dfy-req-date"> {req_date}</span>
          <span className="user-dfy-copy-buy">
            {status === 'Pending' ? (
              <CustomTooltip title="Pending" arrow placement="top">
                <QueryBuilderRoundedIcon
                  style={{ width: '1.3rem', color: '#ed5555' }}
                />
              </CustomTooltip>
            ) : (
              <CustomTooltip title="Done" arrow placement="top">
                <CheckCircleOutlineRoundedIcon
                  style={{
                    width: '1.2rem',
                    color: '#6aed6e',
                    cursor: 'pointer',
                  }}
                />
              </CustomTooltip>
            )}
          </span>
          <span
            style={{
              width: '1.2rem',
              color: '#6aed6e',
              cursor: 'pointer',
              flex: 0.1,
            }}
          >
            {BuyLoader === index ? (
              <LoaderTailSpin width="20" height="20" />
            ) : (
              <>
                {status !== 'Pending' ? (
                  <>
                    {available ? (
                      <CustomTooltip
                        title="Add to My-Workspace"
                        arrow
                        placement="top"
                      >
                        <button
                          className="demo-workspace-btn demo-workspace-btn-buy"
                          style={{
                            fontSize: '0.9rem',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'rgb(43, 209, 43)',
                          }}
                          onClick={() =>
                            handleAddToMyWorkspace(name, domain, creator_email)
                          }
                        >
                          Add
                        </button>
                      </CustomTooltip>
                    ) : (
                      <button
                        className="demo-workspace-btn demo-workspace-btn-buy"
                        onClick={() =>
                          handleBuyCopyDFY(name, index, creator_email)
                        }
                        style={{
                          fontSize: '0.9rem',
                          alignItems: 'center',
                          justifyContent: 'space-evenly',
                        }}
                      >
                        <ShoppingCartRoundedIcon /> Buy Now
                      </button>
                    )}
                  </>
                ) : (
                  <button
                    className="demo-workspace-btn demo-workspace-btn-buy"
                    style={{
                      fontSize: '0.9rem',
                      alignItems: 'center',
                      background: 'gray',
                      justifyContent: 'space-evenly',
                    }}
                  >
                    <ShoppingCartRoundedIcon /> Buy Now
                  </button>
                )}
              </>
            )}
          </span>
          <span className="user-dfy-status tool-tip">
            {status !== 'Pending' ? (
              <CustomTooltip title="Show Preview" arrow placement="top">
                <div
                  className="demo-row-data-more"
                  onClick={() => handleDemoPreview(index)}
                >
                  <UnfoldMoreIcon style={{ width: '20px', height: '20px' }} />
                </div>
              </CustomTooltip>
            ) : (
              <CustomTooltip
                title="Preview Not Available"
                arrow
                placement="top"
              >
                <div className="demo-row-data-more text-muted">
                  <UnfoldMoreIcon style={{ width: '20px', height: '20px' }} />
                </div>
              </CustomTooltip>
            )}
          </span>
        </div>
      </div>
    );
  }

  // Logged In User -> Admin for DFY
  if (Admin && !FetchingDFYForAdmin) {
    return (
      <div>
        <AdminWorkspaceTableHeader />

        <UpdateDFYWorkspaceModal choosen={ChoosenDFY} />
        <DoneDFYWorkspaceModal />
        <div className="demo-workspace-table-data-body">
          <div className="demo-workspace-table-data-container">
            {DoneForYouWorkspaceList &&
              DoneForYouWorkspaceList.map((workspace, index) => (
                <AdminDoneForYouRows
                  key={index}
                  name={workspace.name}
                  domain={workspace.site}
                  date_created={workspace.date_created.slice(0, 10)}
                />
              ))}
          </div>
        </div>
      </div>
    );
  }

  // Logged In User -> Non-Admin for DFY

  // DFY Workspace List Empty
  if (!DoneForYouWorkspaceList) {
    return (
      <div className="done-for-you-empty-container">
        <CurateWorkspaceModal />
        <div className="done-for-you-empty-container-left">
          <h4>Trouble Finding Your Business Idea In Marketplace ?</h4>
          <p>
            CrawlQ will help you build a unique niche and audience specific{' '}
            {WORKSPACE}.
          </p>
          <div className="done-for-you-empty-container-button-wrapper">
            <button
              className="dfy-start"
              onClick={() => setIsOpenCurateWorkspaceModal(true)}
            >
              Start Now
            </button>
            <button className="dfy-book">
              <a
                href="https://calendly.com/crawlq-business-analytics/15min"
                target="_blank"
                rel="noreferrer"
              >
                Book Demo Call
              </a>
            </button>
          </div>
        </div>

        <img
          src={require('assets/images/done-for-you.svg').default}
          alt="Build Together"
        />
      </div>
    );
  }

  // If DFY Workspace List has data
  return (
    <div>
      <div
        style={{
          height: '37.19px', // HardCoded to keep exact height
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        {showErrorInAddingToWorkspace && (
          <p className="text-danger" style={{ marginRight: '2rem' }}>
            Workspace Already Exists!
          </p>
        )}
        {hasAddedToMyWorkspace && (
          <p className="text-success" style={{ marginRight: '2rem' }}>
            Workspace Added Successfully
          </p>
        )}
      </div>
      <WorkspaceTableHeader />
      <CurateWorkspaceModal />
      <div
        className="fab-x-wrapper"
        onClick={() => setIsOpenCurateWorkspaceModal(true)}
      >
        <div className="fab-y">Request DFY</div>
        <div className="fab-x">DFY</div>
        <div className="fab-z">
          <AddCircleIcon />
        </div>
      </div>

      <div className="demo-workspace-table-data-body">
        {DoneForYouWorkspaceListLoader ? (
          <div className="dfy-table-loader">
            <h4>Loading Done For You Workspace</h4>
            <LoaderTailSpin />
          </div>
        ) : (
          <div className="demo-workspace-table-data-container">
            {DoneForYouWorkspaceList &&
              DoneForYouWorkspaceList.map((workspace, index) => (
                <>
                  <UserDoneForYouRows
                    index={index}
                    name={workspace.name}
                    domain={workspace.site}
                    language={workspace.Lang}
                    req_date={workspace.date_requested}
                    status={workspace.status}
                    available={workspace.available}
                    creator_email={workspace.creator_email}
                  />

                  {ShowPreview === index ? (
                    <DemoWorkspacePreview
                      workspace_name={workspace.name}
                      creator_email={workspace.creator_email}
                    />
                  ) : null}
                </>
              ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default DoneForYouWorkspace;
