/* eslint-disable no-undef */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable import/order */
/* eslint-disable import/newline-after-import */
/* eslint-disable dot-notation */
/* eslint-disable quote-props */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable quotes */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable prefer-template */
/* eslint-disable max-len */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/button-has-type */
/* eslint-disable no-return-assign */
/* eslint-disable no-use-before-define */

import {
  Button,
  Col,
  Form,
  Row,
  Select,
  Input,
  Checkbox,
  Radio,
  notification,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  CheckCircleOutlined,
  CheckOutlined,
  LeftOutlined,
  LinkedinOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { store } from 'redux/store';
import axios from 'axios';
import ReactFlagsSelect from 'react-flags-select';
import createInstance from 'Config/AxiosConfig.js';
import OnBoardingScreen from './OnBoardingScreen.js';
import FlowSteps from './FlowSteps.js';
import AthenaLogo from 'assets/images/crawlQ-transparent-favicon.png';
import LanguageMobileSelector from '../sharedMobile/LanguageMobileSelectors.js';
import NavigationSelectors from '../sharedMobile/NavigationSelectors.js';
import { queuedWorkspaceSelect } from 'redux/index.js';
import { getCodeLanguage } from '../sharedMobile/utils.js';

const { Option } = Select;

const ThirdScreen = (props) => {
  const { index, changeIndex, setStarterFlow, isStarterFlow, setIndex } = props;
  const { data, setData } = props;
  const user = useSelector((state) => state?.user);
  const [api, contextHolder] = notification.useNotification();

  // 0 is by default first page..
  const [preparingOnBoarding, setPreparingOnBoarding] = useState(0);
  const [boardingStatus, setBoardingStatus] = useState({});
  const [intervalId, setIntervalId] = useState('');

  const [loadingCreatingButton, setLoadingCreatingButton] = useState(false);

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  useEffect(() => {
    console.log(
      'starterFlow is ' +
        isStarterFlow +
        ' status' +
        boardingStatus[0]?.onboarding_status[5]?.current_status,
    );
    if (
      boardingStatus[0]?.onboarding_status[5]?.current_status === 'complete'
    ) {
      clearInterval(intervalId);
      dispatch(queuedWorkspaceSelect(data?.selectedGrowthOption));
      // handleSelectWorkspace({ name: data?.selectedGrowthOption }, true);
      // we need to set the new created workspace for chat athena
    }
  }, [boardingStatus[0]?.onboarding_status[5]?.current_status]);

  useEffect(() => {
    // Set the initial value for the select field
    form.setFieldsValue({
      linkedinUrl: data?.linkedinValue,
      targetCountry: data?.targetCountry,
      translationEngine: data?.translationEngine,
      CityOfChoice: data?.city,
      language: data?.language,
    });
  }, []);

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const openNotificationWithIcon = (description) => {
    api['warning']({
      message: 'Missing field',
      description,
    });
  };

  const pasteButtonHandler = async () => {
    if (navigator.clipboard && navigator.clipboard.readText) {
      try {
        const text = await navigator.clipboard.readText();
        setData({ ...data, linkedinValue: text });
        // Do something with the pasted text
        console.log(text);
      } catch (error) {
        console.error('Failed to read clipboard contents: ', error);
      }
    } else {
      // Fallback for browsers that don't support the Clipboard API
      console.warn('Clipboard API not supported.');
      // Implement fallback logic here (e.g., prompt the user to manually paste the content)
    }
  };
  const validateForm = () => {
    if (!data.selectedKeyInteresetOption) {
      openNotificationWithIcon('Topic of interest Name is not filled');
      return false;
    }
    if (!data.selectedNetworkingOption) {
      openNotificationWithIcon('Target is not filled');
      return false;
    }
    if (!data.selectedGrowthOption) {
      openNotificationWithIcon('Campaign Name is not filled');
      return false;
    }
    if (!data.selectedBrandOption) {
      openNotificationWithIcon('Company Name is not filled');
      return false;
    }
    if (!data.founderName) {
      openNotificationWithIcon('Founder Name is not filled');
      return false;
    }
    if (!data.productName) {
      openNotificationWithIcon('Product Name is not filled');
      return false;
    }
    return true;
  };

  const startOnboarding = async () => {
    try {
      // first step we need to call the create-project..
      const instance = await createInstance();
      // check if the required elements are there..
      const ok = validateForm();
      if (ok) {
        setLoadingCreatingButton(true);
        const body = prepareBodyForOnBoarding();
        // alert(JSON.stringify(body));
        const response1 = await instance.post('create-project', body);
        if (response1.data.statusCode === '500') {
          // Workspace already exists
          if (response1.data.body === 'Project Name already exists') {
            openNotificationWithIcon('Workspace already exists !');
            setLoadingCreatingButton(false);
          }
        }
        if (response1.data.statusCode === '200') {
          // we call the onboard-me-athena here..
          const body2 = prepareBodyForCreateProject();
          axios
            .post(
              'https://p8dapk4yf9.execute-api.us-east-2.amazonaws.com/prod/onboard-me-athena',
              body2,
            )
            .catch((error) => {
              console.error(error);
            });

          // checking the status of on boarding me..
          let ii = setInterval(async () => {
            console.log('start interval');
            const response = await instance.post(
              'https://p8dapk4yf9.execute-api.us-east-2.amazonaws.com/prod/get-onboarding-status',
              { name: data.selectedGrowthOption },
            );
            setBoardingStatus(response.data);
            setPreparingOnBoarding(1);
            console.log(`checking status ${JSON.stringify(response)}`);
          }, 7000);
          setIntervalId(ii);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getUserFromRedux = () => {
    const state = store.getState();
    return state.user;
  };

  const getEngineCode = () => {
    if (data.translationEngine === 'Google') {
      return 0;
    }
    if (data.translationEngine === 'DeepL') {
      return 1;
    }
    return 0;
  };

  const prepareBodyForOnBoarding = () => ({
    username: getUserFromRedux()?.username,
    name: data.selectedGrowthOption,
    site: 'https://' + data.linkedinValue,
    fullName: user.name,
    lang: getCodeLanguage(data.language),
    lang_engine: getEngineCode(),
    tags: [],
    country_code: data.targetCountry,
    city: data.city,
    target_persona: data.selectedNetworkingOption,
    product_name: `${data.selectedBrandOption}`,
    founder_name: user.name,
    configurations: {
      level1: {
        sources: ['mrr'],
        urls: [data.linkedinValue],
      },
      level2: {
        sources: ['mrr'],
        urls: [data.linkedinValue],
      },
      level3: {
        sources: ['mrr'],
        urls: [data.linkedinValue],
      },
    },
  });

  const prepareBodyForCreateProject = () => ({
    username: getUserFromRedux()?.username,
    name: data.selectedGrowthOption,
    source_lang: getCodeLanguage(data.language),
    site: 'https://' + data.linkedinValue,
    input_data: [
      {
        question: 'Define your Niche',
        answer: '',
      },
      {
        question: 'Define your Sub-Niche',
        answer: '',
      },
      {
        question: 'Define your Micro-Niche',
        answer: '',
      },
      {
        question: 'Define your Industry Vertical',
        answer: '',
      },
      {
        question: 'Ideal Prospect Overview',
        answer: '',
      },
      {
        question: 'Your Website Domain Name',
        answer: data.linkedinValue,
      },
      {
        question: 'Your Company Legal Name',
        answer: data.selectedBrandOption,
      },
      {
        question: 'Main topic of interest',
        answer: data.selectedKeyInteresetOption,
      },
      {
        question: 'Long Tail Topic 1',
        answer: '',
      },
      {
        question: 'Single Big Shocking Statement',
        answer: '',
      },
      {
        question: 'Single Big Emotional Payoff',
        answer: '',
      },
      {
        question: 'Who you want to target',
        answer: selectedNetworkingOption,
      },
    ],
    fullName: user.name,
    default_templates: [],
  });

  return (
    <>
      {contextHolder}{' '}
      {preparingOnBoarding === 1 && (
        <OnBoardingScreen
          boardingStatus={boardingStatus}
          setStarterFlow={setStarterFlow}
        />
      )}
      {preparingOnBoarding === 0 && (
        <div
          style={{
            background: 'white',
            height: '100vh',
          }}
        >
          <div
            style={{
              height: '10vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontWeight: 'bold',
              fontSize: '25px',
              // color: 'white',
            }}
          >
            <LanguageMobileSelector data={data} setData={setData} />
          </div>

          <div
            style={{
              height: '70vh',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                // height: '70vh',
                width: '90vw',
                display: 'flex',
                justifyContent: 'center',
                // alignItems: 'center',
              }}
            >
              <Form
                {...layout}
                form={form}
                name="control-hooks"
                // onFinish={onFinish}
                style={{ width: '85vw' }}
              >
                <Form.Item name="logo">
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={AthenaLogo}
                      alt="Athena Logo"
                      style={{
                        display: 'inline-block',
                        width: '45px',
                        // marginLeft: '10%',
                        marginBottom: '10px',
                      }}
                    />
                  </div>
                </Form.Item>
                <Form.Item
                  name="linkedinUrl"
                  label={
                    <div style={{ color: '#5887FB', fontWeight: 'bold' }}>
                      Domain Name
                    </div>
                  }
                >
                  <Row align="middle" justify="space-between">
                    <Col span={24}>
                      <Input
                        addonBefore="https://"
                        value={data.linkedinValue}
                        placeholder="yeggi.com/"
                        onChange={(e) => {
                          setData({ ...data, linkedinValue: e.target.value });
                        }}
                      />
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item
                  name="CityOfChoice"
                  label={
                    <div style={{ color: '#5887FB', fontWeight: 'bold' }}>
                      City
                    </div>
                  }
                >
                  <Input
                    placeholder="Chicago"
                    value={data.city}
                    onChange={(e) => setData({ ...data, city: e.target.value })}
                    // defaultValue={'New York'}
                  />
                </Form.Item>
                {data.language !== 'US' && (
                  <Form.Item
                    name="translationEngine"
                    label={
                      <div style={{ color: '#5887FB', fontWeight: 'bold' }}>
                        Translation Engine
                      </div>
                    }
                  >
                    <Radio.Group
                      value={data.translationEngine}
                      onChange={(e) => {
                        setData({ ...data, translationEngine: e.target.value });
                      }}
                    >
                      <Radio value="Google">Google</Radio>
                      <Radio value="DeepL">DeepL</Radio>
                    </Radio.Group>
                  </Form.Item>
                )}
              </Form>
            </div>
          </div>
          <div
            style={{
              height: '10vh',
              // backgroundColor: 'green',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              // type="primary"
              loading={loadingCreatingButton}
              style={{
                color: 'white',
                width: '200px',
                borderRadius: '10px',
                fontSize: '15px',
                background: '#4B79FF',
              }}
              onClick={startOnboarding}
            >
              Create campaign
            </Button>
          </div>
          <NavigationSelectors changeIndex={changeIndex} />
          {/* <FlowSteps currentStep={2} setIndex={setIndex} /> */}
        </div>
      )}
    </>
  );
};

export default ThirdScreen;
