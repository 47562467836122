import { useMutation, useQueryClient } from 'react-query';
import createInstance from 'Config/AxiosConfig';
import { toast } from 'react-toastify';
import { GET_UPLOADED_FILES_LISTS_KEY } from './chat-athena/get-uploaded-files-lists';

async function processUploadedFile({ name, fileId }) {
  const instance = await createInstance();
  await instance.post('process-file', { name, file_id: fileId });
}

export const useMutateProcessUploadedDocument = () => {
  const queryClient = useQueryClient();

  return useMutation(processUploadedFile, {
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [GET_UPLOADED_FILES_LISTS_KEY],
      });
      toast.success('File processed successfully.', {
        position: 'bottom-center',
        theme: 'dark',
      });
    },
  });
};
