/* eslint-disable max-len */
import React from 'react';
import './styles.css';
import { useGetWorkspacePersonaStatus } from 'queries/workspace/get-workspace-persona-status';
import { getWorkspacePersonaStatusResponse } from 'queries/workspace/selectors';
import { getQueryFromURL, getFormattedTime } from 'utils/helpers';
import { ScaffoldCard } from 'components/common/scaffold-card';
import { WorkspacePersonaDetailLoading } from 'components/skeleton/persona-library/WorkspacePersonaDetailLoading';
import AppHeaderWrapper from 'components/common/AppHeader/AppHeaderWrapper';
import PersonaProgressStatus from '../PersonaProgressStatus';
import PersonaDetail from './PersonaDetail';
import { CalendarIcon } from '../styles';

const WorkspacePersonStatus = () => {
  const workspaceName = getQueryFromURL('workspace');
  const isNewWorkspace = getQueryFromURL('new') ?? false;

  const { data, isLoading } = useGetWorkspacePersonaStatus(workspaceName, isNewWorkspace);
  const personaStatusResponse = getWorkspacePersonaStatusResponse(data);
  const creationDate = getFormattedTime(personaStatusResponse?.configurations?.date_created).toString();

  if (isLoading) {
    return (
      <AppHeaderWrapper className="workspace-persona-status-wrapper">
        <div className="workspace-persona-status-container">
          <WorkspacePersonaDetailLoading />
        </div>
      </AppHeaderWrapper>
    );
  }
  return (
    <AppHeaderWrapper className="workspace-persona-status-wrapper">
      <div className="workspace-persona-status-container">
        <ScaffoldCard className="h-margin-b-large">
          <h3>Persona : <span className="workspace-persona-name">{workspaceName}</span></h3>
          <span className="workspace-persona-creation"> <CalendarIcon /> {creationDate.slice(0, 25)}</span>
        </ScaffoldCard>

        {!isLoading && personaStatusResponse && (
        <div className="h-margin-b-small">
          <PersonaProgressStatus
            personaName={personaStatusResponse?.name}
            personaTopic={personaStatusResponse?.main_topic}
            personaStatus={personaStatusResponse?.onboarding_status}
            isNewWorkspace={isNewWorkspace}
          />

          <PersonaDetail
            personaName={personaStatusResponse?.name}
            personaTarget={personaStatusResponse?.target_persona}
            personaInfo={personaStatusResponse?.configurations}
          />

        </div>
        )}

      </div>
    </AppHeaderWrapper>
  );
};

export default WorkspacePersonStatus;
