import { Avatar, Col, Row } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { PreviewComponent } from './components/MessageBox/PreviewComponent';

export function QuestionComponent(props) {
  const { isDarkMode, isMobile, element } = props;

  const user = useSelector((state) => state?.user);

  function getIntitials(name) {
    return name
      .trim()
      .split(' ')
      .map((nm) => nm.charAt(0).toUpperCase())
      .join('.');
  }

  return (
    <Row
      justify="end"
      align="top"
      style={{
        backgroundColor: isDarkMode ? '#343541' : 'white',
        paddingTop: '10px',
        paddingBottom: '10px',
        // borderBottom: `2px solid ${isDarkMode}` ? 'black' : 'lightGray',
        fontSize: '16px',
        color: isDarkMode ? 'white !important' : '#374151 !important',
        fontWeight: '400',
        fontStyle: 'normal',
        fontVariant: 'normal',
        textTransform: 'none',
        lineHeight: '28px',
        verticalAlign: 'baseline',
        letterSpacing: 'normal',
        wordSpacing: '0px',
      }}
    >
      <Col span={isMobile ? 18 : 14}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            flexDirection: 'column',
          }}
        >
          <span
            style={{
              float: 'right',
              backgroundColor: isDarkMode ? '#343541' : 'white',
              padding: '8px',
              paddingLeft: '10px',
              borderRadius: '16px 0px 15px 0px',
              marginRight: '20px',
              color: isDarkMode ? 'white' : '#374151',
            }}
            dangerouslySetInnerHTML={{
              __html: element.question,
            }}
          />
          {(element.input_media ?? []).map((media) => (
            <PreviewComponent fileType={media.type} fileSrc={media.url} />
          ))}
        </div>
      </Col>
      <Col
        span={isMobile ? 4 : 2}
        style={{ display: 'flex', justifyContent: 'start' }}
      >
        <span style={{}}>
          <strong>
            {' '}
            <Avatar
              style={{
                marginLeft: '10px',
                marginRight: '5px',
                backgroundColor: '#2a95fd',
              }}
              size="large"
            >
              {getIntitials(user.name)}
            </Avatar>
          </strong>
        </span>
      </Col>
    </Row>
  );
}
