export function truncateString(str, number) {
  if (!str || str.length <= number) {
    return str;
  }
  return `${str.slice(0, number - 3)}...`;
}

export function getItem(label, key, icon, data) {
  return {
    key,
    icon,
    label,
    data,
  };
}

export function getLatestWorkspace(workspaces) {
  let latestWorkspace = null;
  for (let i = 0; i < workspaces.length; i += 1) {
    const workspace = workspaces[i];
    const workspaceDate = new Date(workspace.data.date_created);
    if (
      latestWorkspace === null ||
      workspaceDate > new Date(latestWorkspace.data.date_created)
    ) {
      latestWorkspace = workspace;
    }
  }
  return latestWorkspace;
}

export function renderAnswerWithImages(element, isMobile) {
  const answer = element.answer || '';
  const imageUrl = element.images?.[0]?.url || '';

  if (imageUrl.length > 0) {
    const imageStyle = isMobile ? 'style="width:200px"' : 'style="width:400px"';
    return {
      dangerouslySetInnerHTML: {
        __html: `<p>${answer}</p> <img src=${imageUrl} ${imageStyle} />`,
      },
    };
  }
  return {
    dangerouslySetInnerHTML: {
      __html: `<p>${answer}</p>`,
    },
  };
}
