export const ChatAthenaModelNameEnum = Object.freeze({
  gpt3: 'turbo_gpt',
  gpt4: 'gpt_4',
  claude: 'claude',
  llama: 'llama',
  dalle: 'dall-e-3',
  gemini: 'gemini',
});

export const CHATATHENA_QUERY_CREDIT_COST = 300;

export const FALLBACK_ACTIVE_WORKSPACE = {
  name: 'Business Made Simple',
  mti: '',
};

export const FivetranTab = Object.freeze({
  'All Apps': 0,
  'Connected Apps': 1,
});
