import { useQuery } from 'react-query';
import { store } from 'redux/store';
import createInstance from 'Config/AxiosConfig';

const getBrandVoiceData = async (brandVoiceName) => {
  const state = store.getState();
  const active_workspace = state.workspace;
  const { user } = state;

  let url = `/get-brand-voice?name=${active_workspace.name}&username=${user.username}`;
  if (brandVoiceName) {
    url += `&brand_voice_name=${brandVoiceName}`;
  }

  const instance = await createInstance();

  const response = await instance.get(url);
  if (response.data.statusCode === '200') {
    return response.data.data;
  }
  throw new Error('Failed to fetch brand voice data');
};

export const BRAND_VOICE_QUERY_KEY = 'get-saved-brand-voice-data';

export const useGetBrandVoiceData = (brandVoiceName) => {
  const queryKey = [BRAND_VOICE_QUERY_KEY, brandVoiceName];
  return useQuery(queryKey, () => getBrandVoiceData(brandVoiceName));
};
