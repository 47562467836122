import React, { useContext, useState } from 'react';
import Modal from 'react-modal';
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import CustomTooltip from 'Component/Shared/CustomTooltip';
import { Badge } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { UploadDocuments } from './UploadDocument';
import styles from './document-handler.module.css';
import { ListDocuments } from './ListDocuments';
import { SelectedFileContext } from '../contexts/SelectedFileContext';
import { DocumentPreview } from './DocumentPreview';

const customStyles = {
  height: '700px',
  top: '50%',
  left: '50%',
  right: 'auto',
  bottom: 'auto',
  marginRight: '-50%',
  transform: 'translate(-50%, -50%)',
  width: '95vw',
  maxWidth: '1200px',
  overflow: 'hidden',
  maxHeight: '80vh',
};

const DocumentHandler = ({ darkMode, selectedAssistant }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [previewingFileUrl, setPreviewingUrl] = useState(null);
  const [isUploadingDocument, setIsUploadingDocument] = useState(false);

  const { selectedFileId } = useContext(SelectedFileContext);

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div>
      <CustomTooltip
        title="Select Document"
        arrow
        placement="top"
        onClick={() => setModalOpen((prev) => !prev)}
      >
        <Badge
          badgeContent={
            selectedFileId?.length > 0 ? selectedFileId?.length : null
          }
          color="success"
        >
          <FileUploadRoundedIcon />
        </Badge>
      </CustomTooltip>
      <Modal
        isOpen={modalOpen}
        onRequestClose={closeModal}
        contentLabel="Search Prompts Modal"
        style={{
          content: {
            ...customStyles,
            backgroundColor: darkMode ? '#343641' : 'white',
            color: darkMode ? 'white' : '343641',
          },
        }}
      >
        {previewingFileUrl ? (
          <DocumentPreview
            fileLink={previewingFileUrl}
            handleBack={() => setPreviewingUrl(null)}
          />
        ) : (
          <>
            <h2 style={{ textAlign: 'center' }}>Assets Library</h2>
            <div
              className={styles.new_document}
              onClick={() => setIsUploadingDocument((prev) => !prev)}
            >
              {!isUploadingDocument ? (
                <>
                  <AddRoundedIcon /> <span>Upload Document</span>
                </>
              ) : (
                <>
                  <InsertDriveFileOutlinedIcon /> <span>All Document</span>
                </>
              )}
            </div>

            <div className={styles.document_wrapper}>
              {!isUploadingDocument ? (
                <ListDocuments setPreviewingUrl={setPreviewingUrl} />
              ) : (
                <UploadDocuments
                  darkMode={darkMode}
                  setIsUploadingDocument={setIsUploadingDocument}
                  assistant={selectedAssistant}
                />
              )}
            </div>
          </>
        )}
      </Modal>
    </div>
  );
};

export default DocumentHandler;
