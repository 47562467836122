/* eslint-disable no-prototype-builtins */
/* eslint-disable max-len */
import { MARKET_RESEARCH_FILL_AI } from 'constants/credits-cost';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ScaffoldCard } from 'components/common/scaffold-card';
import {
  GET_MARKET_CATEGORY_QUESTION_QUERY_KEY,
  useGetMarketCategoryQuestion,
} from 'queries/market-research/get-market-category-questions';
import { useMutateSaveMarketResearchAnswers } from 'queries/market-research/mutate-save-market-research-answers';
import { useMutateFillWithAI } from 'queries/market-research/mutate-market-fill-ai';
import { useGetIsCreditAvailable } from 'queries/get-credits';
import { useQueryClient } from 'react-query';
import { MarketQuestionsLoading } from 'components/skeleton/market-research';
import { CreditOverToast } from 'components/common/CreditOverToast/CreditOverToast';
import ModalContainer from 'components/common/ModalContainer/ModalContainer';
import { AIPenIcon } from 'components/common';
import {
  clearAnswer,
  formatAnswersToSave,
  formatQuestionAnswers,
  isMinTwoQuestionsAnswered,
  formatFilledQuestionAnswer,
  formatCategoryQuestionAnswer,
} from 'queries/market-research/selectors';
import { GET_MARKET_CATEGORIES_QUERY_KEY } from 'queries/market-research/get-market-categories';
import { ToastContainer, toast } from 'react-toastify';
import DownloadResearch from './download-research/DownloadResearch';
import { SavedIcon, DownloadIcon } from './styles';
import 'react-toastify/dist/ReactToastify.css';
import './market-research.css';

const MarketQuestions = ({ categoryName, section, IsMinAnsweredCheck }) => {
  const queryClient = useQueryClient();
  const activeWorkspace = useSelector((state) => state?.workspace);

  const {
    data: attractCategoryData,
    isLoading: isMarketQuestionsLoading,
    refetch: refetchMarketCategoryQuestion,
  } = useGetMarketCategoryQuestion(categoryName);

  const { data: isCreditAvailable } = useGetIsCreditAvailable(
    MARKET_RESEARCH_FILL_AI,
  );

  // Only Occurs When Campaign/Workspace is changed.
  useEffect(() => {
    refetchMarketCategoryQuestion();
  }, [activeWorkspace]);

  const { mutateAsync: mutateSave } = useMutateSaveMarketResearchAnswers();

  const {
    mutateAsync: mutateFillWithAI,
    data: fillWithAIResponse,
  } = useMutateFillWithAI();

  const attractCategoryQuestions = attractCategoryData?.data;

  const [QuestionSets, setQuestionSets] = useState();
  const [QuestionAnswers, setQuestionAnswers] = useState();
  const [IsModalContainerOpen, setIsModalContainerOpen] = useState(false);

  useEffect(() => {
    // This formatting should be used to format response from load-question endpoint / useGetMarketCategoryQuestion hook
    const formattedQuestionAnswer = formatCategoryQuestionAnswer(
      attractCategoryQuestions,
    );

    setQuestionSets(attractCategoryQuestions);
    setQuestionAnswers({ ...formattedQuestionAnswer });
  }, [attractCategoryData, categoryName]);

  const handleAnswerInput = (event, question) => {
    const answer = event.target.value;

    setQuestionAnswers({
      ...QuestionAnswers,
      [question]: answer,
    });
  };

  const handleClearClick = async () => {
    const clearedFormattedResponse = clearAnswer(QuestionAnswers);
    setQuestionAnswers({ ...clearedFormattedResponse });

    toast.info('Clearing your answers, Please wait.', {
      position: 'bottom-center',
      theme: 'dark',
    });

    const toBeSavedAnswers = formatAnswersToSave(clearedFormattedResponse);
    await mutateSave({ toBeSavedAnswers, categoryName, section });

    // To Update The Category Filled Or Not
    await queryClient.invalidateQueries({
      queryKey: [GET_MARKET_CATEGORIES_QUERY_KEY, section],
    });

    // Invalidate this query, to get updated response
    await queryClient.invalidateQueries({
      queryKey: [GET_MARKET_CATEGORY_QUESTION_QUERY_KEY, categoryName],
    });
  };

  const handleSaveClick = async () => {
    const toBeSavedAnswers = formatAnswersToSave(QuestionAnswers);
    await mutateSave({ toBeSavedAnswers, categoryName, section });

    // To fetch the Updated The Category Filled Or Not
    await queryClient.invalidateQueries({
      queryKey: [GET_MARKET_CATEGORIES_QUERY_KEY, section],
    });

    // Invalidate this query, to get updated response
    await queryClient.invalidateQueries({
      queryKey: [GET_MARKET_CATEGORY_QUESTION_QUERY_KEY, categoryName],
    });
  };

  const handleFillWithAI = async () => {
    const formattedQuestionAnswer = formatQuestionAnswers(
      QuestionAnswers,
      QuestionSets,
    );

    if (isCreditAvailable) {
      // Fill With AI
      await mutateFillWithAI({
        formattedQuestionAnswer,
        categoryName,
      }).then(async (fillWithAIResponse) => {
        // Once AI Fill Successfull, fetch the response and convert into required format to save into QuestionAnswers State
        const formattedResponse = formatFilledQuestionAnswer(
          fillWithAIResponse,
        );

        // If formattedResponse is not empty
        if (Object.keys(formattedResponse).length !== 0) {
          // set the formatted response into QuestionAnswers State
          setQuestionAnswers(formattedResponse);

          // Reformat the same into save endpoint format
          const toBeSavedAnswers = formatAnswersToSave(formattedResponse);
          // Hit the save endpoint
          await mutateSave({ toBeSavedAnswers, categoryName, section });

          // To fetch the Updated The Category Filled Or Not
          queryClient.invalidateQueries({
            queryKey: [GET_MARKET_CATEGORIES_QUERY_KEY, section],
          });

          // Invalidate this query, to get updated response
          await queryClient.invalidateQueries({
            queryKey: [GET_MARKET_CATEGORY_QUESTION_QUERY_KEY, categoryName],
          });
        }
      });
    } else {
      toast.error(CreditOverToast, {
        position: 'bottom-center',
        theme: 'dark',
      });
    }
  };

  const handleFillWithAIClick = async () => {
    if (IsMinAnsweredCheck) {
      const isAnswered = isMinTwoQuestionsAnswered(QuestionAnswers);

      if (isAnswered) {
        await handleFillWithAI();
        return;
      }
      toast.error('Please answer first two questions', {
        position: 'bottom-center',
        theme: 'dark',
      });
      return;
    }

    // Will happen only for desire and convert stage, attrack stage requires min answer check
    await handleFillWithAI();
  };

  return (
    <div className="market-research-center">
      <ToastContainer />
      <ModalContainer
        isOpen={IsModalContainerOpen}
        toggleClose={() => setIsModalContainerOpen(false)}
      >
        <DownloadResearch
          selectedCategory={categoryName}
          questionAnswers={QuestionAnswers}
          toggleClose={() => setIsModalContainerOpen(false)}
        />
      </ModalContainer>

      <ScaffoldCard className="market-research-question-action-wrapper">
        <div className="market-research-question-action-left">
          <AIPenIcon
            data-title="Fill With AI"
            className="market-research-question-action-fillai"
            onClick={handleFillWithAIClick}
          />
        </div>
        <div className="market-research-question-action-right">
          <SavedIcon
            data-title="Save"
            className="h-margin-r-xsmall"
            onClick={handleSaveClick}
          />
          <DownloadIcon
            data-title="Download"
            onClick={() => setIsModalContainerOpen(true)}
          />
          <span
            data-title="Clear"
            className="market-research-question-action-clear"
            onClick={handleClearClick}
          >
            Clear
          </span>
        </div>
      </ScaffoldCard>

      <div className="market-research-questions-wrapper">
        {isMarketQuestionsLoading && <MarketQuestionsLoading />}

        {!isMarketQuestionsLoading &&
          QuestionSets?.map((questionSet) => (
            <ScaffoldCard
              className="market-research-questions-container h-margin-b-x2"
              key={questionSet?.question}
            >
              <div className="market-research-question">
                {questionSet?.question}
              </div>
              <textarea
                onChange={(event) =>
                  handleAnswerInput(event, questionSet?.question)
                }
                className="market-research-textarea"
                value={QuestionAnswers[questionSet?.question]}
              />
              {questionSet?.helper && (
                <div className="market-research-hint">
                  HINT : {questionSet?.helper}
                </div>
              )}
            </ScaffoldCard>
          ))}
      </div>
    </div>
  );
};

export default MarketQuestions;
