/* eslint-disable max-len */
import { ROUTES } from 'constants/routes';
import { DISPLAY_TEMPLATES_TABLE_HEADER } from 'constants/manage-templates/display-tempates';
import SearchInput from 'components/common/SearchInput';
import { TableHeader } from 'components/common/Table/Table';
import { ScaffoldCard } from 'components/common/scaffold-card';
import { WorkspaceContentLoading } from 'components/skeleton/all-workspace/WorkspaceContentLoading';
import {
  GET_USER_TEMPLATES_QUERY_KEY,
  useGetUserTemplates,
} from 'queries/manage-templates/get-users-templates';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import ModalContainer from 'components/common/ModalContainer/ModalContainer';
import CustomTooltip from 'Component/Shared/CustomTooltip';
import {
  CopyFileIcon,
  DeleteIcon,
  EditIcon,
  PublicIcon,
  ShareIcon,
} from 'components/common';
import DualActionView from 'components/common/DualActionView/index';
import { useQueryClient } from 'react-query';
import { showNotification } from 'libs/notification';
import manage_templates_common_styles from '../manage-templates.module.css';
import styles from './display-templates.module.css';
import CloneAction from '../Actions/Clone/CloneTemplate';
import ShareAction from '../Actions/Share/Share';
import { delelteTemplate, getTrimmedString } from '../template.service';
import MakeTemplatePublic from '../components/MakeTemplatePublic';
import GenerateURL from '../components/GenerateURL';
import { PaginationView } from './PaginationView';
import { FilterOptions } from './FiltersOptions';

const ACTION_NAME = {
  EMPTY: 'EMPTY',
  CLONE: 'CLONE',
  SHARE: 'SHARE',
  DELETE: 'DELETE',
  PUBLIC: 'PUBLIC',
  URL: 'URL',
};

const DisplayTemplates = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [ModalIs, setModalIs] = useState('');
  const [SelectedTemplateId, setSelectedTemplateId] = useState('');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(1000000); // Once filter is added fix it.
  const [tab, setTab] = useState('All');

  const { data: userBlogTemplatesData, isLoading } = useGetUserTemplates(
    page,
    pageSize,
  );

  const userBlogTemplates = userBlogTemplatesData?.templates || [];
  const totalTemplates = userBlogTemplatesData?.totalTemplates || 0;

  const [searchKey, setSearchKey] = useState('');

  const applySearchFilter = (template) => {
    const key = searchKey.trim()?.toLowerCase();
    if (key === '' && !tab) return true;

    const { template_name, template_description, author_name } = template;

    if (!tab || tab === 'All') {
      return (
        template_name?.toLowerCase().includes(key) ||
        template_description?.toLowerCase().includes(key) ||
        author_name?.toLowerCase().includes(key)
      );
    }

    const tabInLowerCase = tab.toLowerCase();

    return (
      template.template_name?.toLowerCase().includes(tabInLowerCase) ||
      template.template_description?.toLowerCase().includes(tabInLowerCase)
    );
  };

  const handleActionClick = (actionName, template) => {
    const templateId = template?.template_id;

    setModalIs(actionName);
    setSelectedTemplateId(templateId);
  };

  const handleEdit = (template) => {
    navigate(ROUTES.EDIT_TEMPLATES, { state: template });
  };

  const handleDeleteTemplate = async () => {
    const isDeleted = await delelteTemplate(SelectedTemplateId);
    if (isDeleted) {
      setModalIs('');
      queryClient.invalidateQueries(GET_USER_TEMPLATES_QUERY_KEY);
      showNotification('success', 'Template Deleted Successfully.');
    }
  };

  return (
    <div className={manage_templates_common_styles.child_wrapper}>
      <ModalContainer isOpen={!!ModalIs} toggleClose={() => setModalIs('')}>
        {/* CLONE ACTION */}
        {ModalIs === ACTION_NAME.CLONE && (
          <CloneAction
            templateId={SelectedTemplateId}
            closeModal={() => setModalIs('')}
          />
        )}
        {/* SHARE ACTION */}
        {ModalIs === ACTION_NAME.SHARE && (
          <ShareAction
            templateId={SelectedTemplateId}
            closeModal={() => setModalIs('')}
          />
        )}

        {ModalIs === ACTION_NAME.DELETE && (
          <DualActionView
            headingText="Delete Template"
            primaryBtnText="Delete"
            secondaryBtnText="Cancel"
            primaryAction={handleDeleteTemplate}
            secondaryAction={() => setModalIs('')}
          >
            <p className="h-margin-b-tiny">
              {' '}
              Template will be deleted permanently.
            </p>
            <p />
          </DualActionView>
        )}

        {ModalIs === ACTION_NAME.PUBLIC && (
          <MakeTemplatePublic
            templateId={SelectedTemplateId}
            setShowModal={() => setModalIs('')}
          />
        )}

        {ModalIs === ACTION_NAME.URL && (
          <GenerateURL
            templateId={SelectedTemplateId}
            setShowModal={() => setModalIs('')}
          />
        )}
      </ModalContainer>
      <div className={manage_templates_common_styles.left_child}>
        {/* {userBlogTemplates && userBlogTemplates.length > 0 && ( */}
        <div className={styles.buttons_wrapper}>
          <div className={styles.new_templates}>
            <FilterOptions tab={tab} setTab={setTab} />
            <button
              className={styles.create_button}
              type="submit"
              onClick={() => navigate(ROUTES.CREATE_TEMPLATES)}
            >
              Create Template
            </button>
          </div>
          <div className={styles.search_wrapper}>
            <SearchInput
              placeholder="Search Templates"
              onChange={(e) => setSearchKey(e.target.value)}
              value={searchKey}
            />
          </div>
        </div>
        {/* )} */}
        <TableHeader headers={DISPLAY_TEMPLATES_TABLE_HEADER} />
        {isLoading ? (
          <WorkspaceContentLoading />
        ) : (
          <div className={styles.table_column_wrapper}>
            {userBlogTemplates &&
              userBlogTemplates.length > 0 &&
              userBlogTemplates.filter(applySearchFilter).map((template) => (
                <ScaffoldCard
                  key={`${template?.name} ${template.template_id}`}
                  className={`${styles.blogs_table_content} h-margin-b-small`}
                >
                  <div
                    className={`${styles.table_name} ${styles.template_name}`}
                  >
                    <CustomTooltip
                      title={template?.template_name}
                      placement="top"
                      arrow
                    >
                      {getTrimmedString(template?.template_name, 70)}
                    </CustomTooltip>
                  </div>

                  <div className={styles.table_description}>
                    <CustomTooltip
                      title={template?.template_description}
                      placement="top"
                      arrow
                    >
                      {getTrimmedString(template?.template_description, 120)}
                    </CustomTooltip>
                  </div>

                  <div className={styles.table_name}>
                    {template?.author_name}
                  </div>

                  <div className={styles.table_name}>
                    {template?.created_at}
                  </div>
                  <div className={styles.table_name}>
                    <CustomTooltip
                      placement="top"
                      title="Make Template Public"
                      arrow
                    >
                      <PublicIcon
                        onClick={() =>
                          handleActionClick(ACTION_NAME.PUBLIC, template)
                        }
                      />
                    </CustomTooltip>
                    {!template?.from_support && (
                      <CustomTooltip
                        placement="top"
                        title="Edit Template"
                        arrow
                      >
                        <EditIcon onClick={() => handleEdit(template)} />
                      </CustomTooltip>
                    )}

                    <CustomTooltip
                      placement="top"
                      title="Share Template With Other CrawlQ Account"
                      arrow
                    >
                      <ShareIcon
                        onClick={() =>
                          handleActionClick(ACTION_NAME.SHARE, template)
                        }
                      />
                    </CustomTooltip>

                    <CustomTooltip placement="top" title="Clone Template" arrow>
                      <CopyFileIcon
                        onClick={() =>
                          handleActionClick(ACTION_NAME.CLONE, template)
                        }
                      />
                    </CustomTooltip>

                    {!template?.from_support && (
                      <CustomTooltip
                        placement="top"
                        title="Delete Template"
                        arrow
                      >
                        <DeleteIcon
                          onClick={() =>
                            handleActionClick(ACTION_NAME.DELETE, template)
                          }
                        />
                      </CustomTooltip>
                    )}
                  </div>
                </ScaffoldCard>
              ))}
          </div>
        )}
        {/*
        // Uncomment it once filter is implemented in backend.

        <div className={styles.pagination_view}>
          <PaginationView
        {!isLoading && (
          <div className={styles.pagination_view}>
            <PaginationView
              page={page}
              updatePage={setPage}
              pageSize={pageSize}
              updatePageSize={setPageSize}
              total={totalTemplates}
            />
          </div>
        )} */}
      </div>
    </div>
  );
};

export default DisplayTemplates;
