import 'assets/constants.css';
import styled from 'styled-components';

export const DangerButton = styled.button`
  background-color: var(--danger-color);
  color: var(--white-color);
  padding: 0.2rem;
  border-radius: 2px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;
