import { baseAPIsUrl } from 'Config/Api';
import createInstance from 'Config/AxiosConfig';
import axios from 'axios';

export async function getFile(name, username, fileId) {
  const instance = await createInstance();
  const { data } = await instance.get(
    `${baseAPIsUrl}/get-files?username=${username}&name=${name}&file_id=${fileId}`,
  );
  if (data.statusCode === '200') {
    return data.data[0];
  }
  return null;
}

export async function processUploadedFile(name, fileId) {
  const instance = await createInstance();
  await instance.post('process-file', { name, file_id: fileId });
}

const processFileName = (filename) => {
  const proccessedName = filename.replace(/[^a-zA-Z .]/g, '');
  return proccessedName;
};

export async function uploadDocument(name, username, file_name, file) {
  const _processed_filename = processFileName(file_name);
  const queryParams = new URLSearchParams({
    name,
    username,
    file_name: _processed_filename,
  });

  const headers = {
    'Content-Type': 'multipart/form-data',
  };

  const config = {
    headers,
    params: queryParams,
  };
  const fileBinary = await file.arrayBuffer();
  const response = await axios.post(
    `${baseAPIsUrl}/upload-files`,
    fileBinary,
    config,
  );

  if (response.data.statusCode === '200') {
    return response.data.data.file_id;
  }
  return null;
}
