/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
export const WORKSPACE_CREATION_STAGES = ['Attract', 'Litsen', 'Desire', 'Learn', 'Convert', 'Traige'];

export const STATUS = {
  COMPLETE: 'complete',
  INPROGRESS: 'in_progress',
  FAILED: 'failed',
};

export const getStatusOfStage = (workspaceStatusResponse) => {
//   console.log(workspaceStatusResponse.current_status);

  for (const response in workspaceStatusResponse) {
    // console.log('check', workspaceStatusResponse[response].current_status);
    if (workspaceStatusResponse[response].current_status === STATUS.COMPLETE) {
      return STATUS.COMPLETE;
    }
    if (workspaceStatusResponse[response].current_status === STATUS.FAILED) {
      return STATUS.FAILED;
    }
  }
  return STATUS.INPROGRESS;
};
