import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import LockRoundedIcon from '@mui/icons-material/LockRounded';
import GoogleIcon from '@mui/icons-material/Google';
import CustomTooltip from 'Component/Shared/CustomTooltip';
import LoaderSpin from 'Component/Shared/Loader';
import { ChatAthenaModelNameEnum } from 'libs/chat-athena-constants';
import claudeImg from 'assets/chat-athena/claude.png';
import metaIcon from 'assets/chat-athena/meta-icon.svg';
import dalleIcon from 'assets/chat-athena/dale.png';
import cx from 'classnames';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import styles from './model-selector.module.css';
import { SelectedModelContext } from '../contexts/SelectedModel';
import { ImageModelSelector } from './ImageModelSelector';
import { useModelSelectorController } from './hooks/useModelSelectionController';

const MappingEnum = Object.freeze({
  [ChatAthenaModelNameEnum.gpt3]: {
    label: 'GPT - 4o',
    icon: <ElectricBoltIcon />,
  },
  [ChatAthenaModelNameEnum.gpt4]: {
    label: 'GPT - 4',
    icon: <AutoFixHighIcon className="mb-2" />,
  },
  [ChatAthenaModelNameEnum.gemini]: {
    label: 'Gemini',
    icon: <GoogleIcon />,
  },
  [ChatAthenaModelNameEnum.claude]: {
    label: 'Sonnet 3.5',
    icon: <img style={{ height: '25px' }} src={claudeImg} alt="img" />,
  },
  [ChatAthenaModelNameEnum.llama]: {
    label: 'Llama AI 3.1',
    icon: <img style={{ height: '25px' }} src={metaIcon} alt="img" />,
  },
});

const ModelSelector = () => {
  const {
    isProActivated,
    handleModelSelect,
    handleImageModelSelect,
    handleUpgrade,
    loadingId,
  } = useModelSelectorController();
  const { modelName } = useContext(SelectedModelContext);
  const user = useSelector((state) => state.user);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const isUserAnonymus = user?.is_anonymous || user.plan === 'Freemium Plan';

  const getToolTip = (addOnName) => {
    if (user?.is_anonymous === true) {
      return `Upgrade to CrawlQ paid plan first to subscribe to ${addOnName} AddOn`;
    }
    return `Upgrade to pro to use ${addOnName}`;
  };

  const renderModelOption = (modelProps) => {
    const { modelName: currentModelName, icon, lockIcon } = modelProps;

    const handleModelClick = () => {
      if (!isProActivated && !isUserAnonymus) return;
      handleModelSelect(currentModelName);
    };

    const handleUpgradeClick = () => {
      if (!isUserAnonymus) {
        handleUpgrade(currentModelName);
      }
    };

    return (
      <div
        className={cx(styles.model_option, {
          [styles.selected]: currentModelName === modelName,
        })}
        onClick={handleModelClick}
      >
        {lockIcon && !isProActivated && !isUserAnonymus ? (
          <CustomTooltip
            title={getToolTip(MappingEnum[currentModelName].label)}
            arrow
            placement="top"
          >
            {loadingId === currentModelName ? (
              <div className={styles.model_option}>
                <LoaderSpin />
              </div>
            ) : (
              <div className={styles.model_option} onClick={handleUpgradeClick}>
                {MappingEnum[currentModelName]?.icon}
                {MappingEnum[currentModelName]?.label}
                <LockRoundedIcon />
              </div>
            )}
          </CustomTooltip>
        ) : (
          <div className={styles.model_option}>
            {MappingEnum[currentModelName]?.icon}
            {MappingEnum[currentModelName]?.label}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={styles.model_selector}>
      <div onClick={handleClick} className={styles.current_model_highlight}>
        <div>
          {renderModelOption({
            modelName: modelName ?? ChatAthenaModelNameEnum.gpt3,
            lockIcon: false,
          })}
        </div>
        <KeyboardArrowDownRoundedIcon />
      </div>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <div className={styles.options_wrapper}>
          <MenuItem onClick={handleClose}>
            {renderModelOption({
              modelName: ChatAthenaModelNameEnum.gpt3,
              lockIcon: false,
            })}
          </MenuItem>

          <MenuItem onClick={handleClose}>
            {renderModelOption({
              modelName: ChatAthenaModelNameEnum.gpt4,
              lockIcon: true,
            })}
          </MenuItem>
          <MenuItem onClick={handleClose}>
            {renderModelOption({
              modelName: ChatAthenaModelNameEnum.gemini,
              lockIcon: true,
            })}
          </MenuItem>
          <MenuItem onClick={handleClose}>
            {renderModelOption({
              modelName: ChatAthenaModelNameEnum.llama,
              lockIcon: true,
            })}
          </MenuItem>
          <MenuItem onClick={handleClose}>
            {renderModelOption({
              modelName: ChatAthenaModelNameEnum.claude,
              lockIcon: true,
            })}
          </MenuItem>
        </div>
      </Menu>
      <ImageModelSelector
        isProActivated={isProActivated || isUserAnonymus}
        handleImageModelSelect={handleImageModelSelect}
        icon={dalleIcon}
        handleUpgrade={() => handleUpgrade(ChatAthenaModelNameEnum.dalle)}
        loadingId={loadingId}
      />
    </div>
  );
};

export { ModelSelector };
