/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
/* eslint-disable import/prefer-default-export */
import React from 'react';

export function InputFeilds({
  name,
  value,
  onChange,
  error = false,
  success = false,
}) {
  return (
    <div
      className="profile-input-form__group"
      style={{
        width: '250px',
      }}
    >
      <input
        id={`profile_input_${name}`}
        type="text"
        className="profile-input-form__field"
        placeholder={name}
        value={value}
        onChange={onChange}
      />
      <label
        htmlFor={`profile_input_${name}`}
        className="profile-input-label form__label"
      >
        {name}
      </label>
      {!!error && <small className="text-danger">{error}</small>}
      {!!success && <small className="text-success">{success}</small>}
    </div>
  );
}
