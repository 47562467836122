/* eslint-disable max-len */
import ControlPointRoundedIcon from '@mui/icons-material/ControlPointRounded';
import BaseLayout from 'Component/Shared/BaseLayout/BaseLayout';
import MinifiedLayout from 'Component/Shared/BaseLayout/MinifiedLayout';
import CustomTooltip from 'Component/Shared/CustomTooltip';
import {
  ButtonPrimary,
  ButtonSecondary,
  Divider,
  Flex,
  HeadingDesc,
  HeadingName,
} from 'Component/Shared/GlobalStyles';
import { addNewTab, removeTab } from 'redux/index';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useIsMobile } from 'Component/hooks/useIsMobile';
import LoaderSpin from 'Component/Shared/Loader';
import { updateWorkspaceDetails } from '../../../../../redux';
import createNewWorkspace from '../CreateNewWorkspace/CreateNewWorkspaceLogic';
import MarketResearchInput from './components/MarketResearchInput';
import ProductInput from './components/ProductInput';
import {
  changeFormatToJSONToArray,
  startOnboarding,
} from './confuguration.service';
import styles from './index.module.css';

const ToastProps = {
  autoClose: 3000,
  position: 'bottom-center',
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  theme: 'colored',
};

const RESOURCE_FEEDBACK_FORM =
  'https://docs.google.com/forms/d/e/1FAIpQLSeaF7VrbZBfIatEioopO02tyDiB7trYcLSba6-xadEH8p5okw/viewform';

const NewWorkspceConfiguration = () => {
  const dispatch = useDispatch();
  const tabs = useSelector((state) => state?.tabManager?.tabs);
  const currentTab = useSelector((state) => state?.tabManager?.currentTab);

  const isMobile = useIsMobile();

  const location = useLocation();
  const navigate = useNavigate();

  const workspaceConfigurations = useSelector(
    (state) => state?.updateWorkspaceConfiguration,
  );
  const user = useSelector((state) => state?.user);

  const [currentWorkspaceName, setCurrentWorkspaceName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const getValuesFromRedux = () => {
    if (location?.state?.newWorkspace) {
      const _currentWorkspace =
        workspaceConfigurations.workspaces?._currentWorkspace;
      setCurrentWorkspaceName(_currentWorkspace?.name);
      return;
    }
    if (!location?.state?.workspaceDetails) {
      navigate('/new-workspace');
    }
  };

  const handleCreatePersona = async () => {
    const currentWorkspace =
      workspaceConfigurations.workspaces._currentWorkspace;
    const { onboardingData, ...newWorkspaceData } = currentWorkspace;

    if (
      newWorkspaceData.product_name?.trim() === '' ||
      newWorkspaceData.founder_name?.trim() === ''
    ) {
      return;
    }

    newWorkspaceData.configurations.level1.sources = changeFormatToJSONToArray(
      newWorkspaceData.configurations.level1.sources,
    );

    newWorkspaceData.configurations.level2.sources = changeFormatToJSONToArray(
      newWorkspaceData.configurations.level2.sources,
    );

    newWorkspaceData.configurations.level3.sources = changeFormatToJSONToArray(
      newWorkspaceData.configurations.level3.sources,
    );

    onboardingData.fullName = user.name;
    onboardingData.username = user.username;

    if (isMobile) {
      setIsLoading(true);
      await createNewWorkspace(newWorkspaceData);
      navigate('/chat-athena?new_workspace=true');
      try {
        startOnboarding(onboardingData);
      } catch (err) {
        //
      }
      navigate('/chat-athena?new_workspace=true');

      setIsLoading(false);

      return;
    }

    if (location?.state?.newWorkspace) {
      const data = await createNewWorkspace(newWorkspaceData);
      if (data.error) return;
    }
    startOnboarding(onboardingData);

    const workpaceDetails =
      workspaceConfigurations.workspaces?._currentWorkspace;

    dispatch(
      updateWorkspaceDetails({
        workspaces: {
          _currentWorkspace: {},
        },
        [currentWorkspaceName]: workpaceDetails,
      }),
    );

    dispatch(removeTab(currentTab?.id));
    dispatch(
      addNewTab({
        id: tabs.length,
        name: `${currentWorkspaceName} Persona`,
        workspaceName: currentWorkspaceName,
        path: `/workspace-persona-status?new=true&workspace=${currentWorkspaceName}`,
      }),
    );

    // navigate(
    //   `/workspace-persona-status?new=true&workspace=${currentWorkspaceName}`,
    // );
    // navigate(`${planPanelInfo?.plan_path}`);
    navigate('/new-workspace-template-selector', {
      state: {
        ...location?.state,
        newWorkspaceData,
        onboardingData,
      },
    });
  };

  useEffect(() => {
    getValuesFromRedux();
  }, [location]);

  return (
    <MinifiedLayout>
      <ToastContainer />
      <HeadingName isMobile={isMobile}>
        {currentWorkspaceName}- Customer Journey Stages
      </HeadingName>
      <HeadingDesc>Custom Configuration</HeadingDesc>
      <Divider />
      <Flex direction={'column'} gap={'1.5rem'} className="shadow mt-3 p-3">
        <Flex direction={'column'}>
          <Flex align={'center'} justify={'space-between'}>
            <Flex align={'center'}>
              {/* <ControlPointRoundedIcon /> */}
              <span className={styles.sub_head_desc}>Intake Brief</span>
            </Flex>
            <Flex className="d-none d-md-flex">
              <ButtonSecondary
                className={styles.more_resources_request}
                onClick={() => {
                  window.open(
                    RESOURCE_FEEDBACK_FORM,
                    'popup',
                    'width=600,height=600',
                  );
                }}
              >
                Request more data sources
              </ButtonSecondary>
            </Flex>
          </Flex>
          <Divider />
        </Flex>
        <MarketResearchInput />
        <ProductInput />
        <Flex
          gap={'1rem'}
          justify={'space-between'}
          direction={isMobile ? 'column' : 'row'}
          align={'center'}
        >
          <Flex>
            <ButtonSecondary onClick={() => navigate(-1)}>Back</ButtonSecondary>
          </Flex>
          <Flex
            gap={'1rem'}
            justify={'flex-end'}
            direction={isMobile ? 'column' : 'row'}
            align={'center'}
          >
            <ButtonSecondary>Save</ButtonSecondary>
            <CustomTooltip
              title={
                workspaceConfigurations.workspaces._currentWorkspace.product_name?.trim() ===
                  '' ||
                workspaceConfigurations.workspaces._currentWorkspace.founder_name?.trim() ===
                  ''
                  ? 'Please fill in all mandatory fields (*).'
                  : 'Go Ahead Create Persona'
              }
              placement="top"
              arrow
            >
              {!isLoading ? (
                <ButtonPrimary
                  className={
                    workspaceConfigurations.workspaces._currentWorkspace.product_name?.trim() ===
                      '' ||
                    workspaceConfigurations.workspaces._currentWorkspace.founder_name?.trim() ===
                      ''
                      ? 'bg-dark'
                      : 'text-light'
                  }
                  onClick={handleCreatePersona}
                >
                  {isMobile ? 'Create Workspace' : 'Next'}
                </ButtonPrimary>
              ) : (
                <LoaderSpin />
              )}
            </CustomTooltip>
          </Flex>
        </Flex>
      </Flex>
    </MinifiedLayout>
  );
};

export default NewWorkspceConfiguration;
