import { ROUTES } from 'constants/routes';
import React from 'react';
import { useGetUsersAddOns } from 'queries/user-account/services/get-user-addons';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LockIcon from '@mui/icons-material/Lock';
import { PlanType } from 'libs/enums/planType.enum';
import { toast } from 'react-toastify';
import {
  FBIcon,
  OnboardIcon,
  ChatIcon,
  PlusIcon,
  YoutubeIcon,
  CommunityIcon,
  TestimonialIcon,
  Divider,
} from '..';
import styles from './quick-navigation.module.css';
import { ScaffoldCard } from '../scaffold-card';

const MANAGE_INTEGRATION = 'Manage Insights360ai Integrations';

const QUICK_NAV_LIST = [
  { name: 'Dashboard', route: ROUTES.DASHBOARD },
  { name: 'Market Research', route: ROUTES.MARKET_RESEARCH },
  { name: 'Market Message', route: ROUTES.CHAT_ATHENA },
  { name: 'Digital Assets', route: ROUTES.DIGITAL_ASSETS_BLOGS },
  { name: 'Manage Templates', route: ROUTES.MANAGE_TEMPLATES },
  { name: 'Manage Assistants', route: ROUTES.ASSISTANTS },
  { name: 'Team Members', route: ROUTES.TEAM_MEMBER },
];

const QUICK_CONNECT_LIST = [
  {
    name: 'Schedule an Onboarding Session',
    link: 'https://at.crawlq.app/onboarding',
    icon: <OnboardIcon />,
  },
  {
    name: 'Facebook Community',
    link: ' https://at.crawlq.app/fbg',
    icon: <FBIcon />,
  },
  {
    name: 'Slack Community',
    link: 'https://at.crawlq.app/slack',
    icon: <ChatIcon />,
  },
  {
    name: 'YouTube',
    link: ' https://www.youtube.com/@crawlqai',
    icon: <YoutubeIcon />,
  },
  {
    name: 'Become an Affliate',
    link: ' https://crawlq.ai/crawlq-affiliate/',
    icon: <CommunityIcon />,
  },
  {
    name: 'Write us a Testimonial',
    link: 'https://at.crawlq.app/testimonial',
    icon: <TestimonialIcon />,
  },
];

export function QuickNavigation() {
  const navigate = useNavigate();
  const { data: addons, isFetched: hasLoadedAddons } = useGetUsersAddOns();
  const user = useSelector((state) => state.user);

  const hasPurchasedInsights360 = () => {
    if (!hasLoadedAddons) return false;

    const monthlyStatus = addons[PlanType.insights360Monthly]?.status === true;
    const yearlyStatus = addons[PlanType.insights360Yearly]?.status === true;

    return monthlyStatus || yearlyStatus;
  };

  const handleRedirectToManageIntegrations = () => {
    if (!hasLoadedAddons) {
      toast.info('Fetching your plan status');
    }

    if (hasPurchasedInsights360()) {
      navigate(`${ROUTES.CHAT_ATHENA}?connectorUserEmail=${user.username}`);
    } else {
      navigate(ROUTES.USER_PROFILE);
    }
  };

  return (
    <div className={styles.quick_nav_container}>
      <div className={styles.top_nav_container}>
        <div className={styles.create_workspace_container}>
          <a className={styles.create_workspace} href="/chat-athena-flow">
            <PlusIcon
              width="20px"
              height="20px"
              color="var(--default-text-color)"
            />
            <span className={styles.create_workspace_text}>
              Create New Campaign
            </span>
          </a>
        </div>
        {QUICK_NAV_LIST.map((navigation) => (
          <a
            className={styles.quick_nav_card_container}
            href={navigation?.route}
          >
            <ScaffoldCard className={styles.quick_nav_text_container}>
              <span>{navigation?.name}</span>
            </ScaffoldCard>
          </a>
        ))}

        <div
          className={styles.quick_nav_card_container}
          onClick={handleRedirectToManageIntegrations}
        >
          <ScaffoldCard className={styles.quick_nav_text_container}>
            <div className={styles.manage_integration}>
             {!hasPurchasedInsights360() && <LockIcon />} <span>{MANAGE_INTEGRATION}</span>
            </div>
          </ScaffoldCard>

        </div>
      </div>
      <div className={styles.bottom_nav_container}>
        <div className={styles.botton_nav_container_header}>Quick Links</div>
        <Divider className="h-margin-t-small h-margin-b-small" />
        {QUICK_CONNECT_LIST?.map((quickLink) => (
          <a
            target="_blank"
            className={styles.quick_link_container}
            href={quickLink?.link}
            rel="noreferrer"
          >
            <div className={styles.quicklink_wrapper}>
              <span className={styles.quickLink_icon_wrapper}>
                {quickLink?.icon ?? ''}
              </span>
              <span className={styles.quickLink_text}>{quickLink?.name}</span>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
}
