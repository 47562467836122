import { MARKET_CONFIG_TERMS } from 'constants/workspace/persona-library';

export const getWorkspacePersonaStatusResponse = (response) => response?.data[0];

export const sortWorkspaceOnCreatedDate = (WorkspacePersonas) => {
  // sorting based on creation date
  const sortedWorkspacePersona = WorkspacePersonas.sort(
    (a, b) => a?.item?.configurations?.date_created > b?.item?.configurations?.date_created);

  return sortedWorkspacePersona;
};

export const getMarketSourceByLevel = (sources) => {
  const marketSource = [];

  sources.forEach((source) => {
    const value = MARKET_CONFIG_TERMS[source] ? MARKET_CONFIG_TERMS[source] : source;
    marketSource.push(value);
  });

  return marketSource;
};
