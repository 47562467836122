import CustomTooltip from 'Component/Shared/CustomTooltip';
import { LoaderTailSpin, PrimaryButton } from 'components/common';
import { PlanType } from 'libs/enums/planType.enum';
import { useMutateAddons } from 'queries/add-ons/mutate-add-ons';
import React from 'react';

const Insights360aiButtonText = 'Insights360ai Integrations';

export const InsightsPurchaaseButton = () => {
  const {
    mutate: mutateAddons,
    isLoading: isFetchingPurchaseUrl,
  } = useMutateAddons();

  const handlePurchaseMonthlyAddons = () => {
    mutateAddons([PlanType.insights360Monthly]);
  };

  const handlePurchaseYearlyAddons = () => {
    mutateAddons([PlanType.insights360Yearly]);
  };

  return (
    <div
      style={{
        display: 'flex',
        gap: '1rem',
      }}
    >
      {isFetchingPurchaseUrl ? (
        <LoaderTailSpin />
      ) : (
        <>
          <CustomTooltip
            title="Subscribe, Simplify, and Connect: Data Integration Redefined"
            arrow
            placement="top"
          >
            <PrimaryButton onClick={handlePurchaseMonthlyAddons}>
              Insights360ai : Monthly Plan
            </PrimaryButton>
          </CustomTooltip>
          <CustomTooltip
            title="Subscribe, Simplify, and Connect: Data Integration Redefined"
            arrow
            placement="top"
          >
            <PrimaryButton onClick={handlePurchaseYearlyAddons}>
              Insights360ai : Yearly Plan
            </PrimaryButton>
          </CustomTooltip>
        </>
      )}
    </div>
  );
};
