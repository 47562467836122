import createInstance from 'Config/AxiosConfig';
import { useQuery } from 'react-query';

export const getUserBlogTemplate = async () => {
  const instance = await createInstance();
  const {
    data,
  } = await instance.post(
    'https://p8dapk4yf9.execute-api.us-east-2.amazonaws.com/devel/get-user-blog-template',
    { include_all: true },
  );
  if (data.statusCode === '200') return data.templates;

  throw new Error('Could not fetch User Blog Template');
};

// Keep Query Value as File Name to maintain consistency and uniqueness
const QUERY_KEY = 'get-user-blog-template';

// Query Config Options
const queryOptions = {
  staleTime: 30000,
};

// QUERY
export const useGetUserBlogTemplate = () =>
  useQuery(QUERY_KEY, getUserBlogTemplate, queryOptions);
