import React from 'react';
import AppHeader from './AppHeader';

const AppHeaderWrapper = ({ children, className }) => (
  <div className="app-container">
    <AppHeader />
    <div className="app-tab-component-container">
      <div className={className}>{children}</div>
    </div>
  </div>

);

export default AppHeaderWrapper;
