import { Colors } from 'libs/colors';
import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1;
  align-items: baseline;
  width: 100%;
`;

export const HeadingName = styled.b`
  font-size: 2rem;
  ${({ isMobile }) =>
    isMobile &&
    `
    font-size: 1rem;
  `}
`;
export const HeadingDesc = styled.span`
  color: ${Colors.blue};
`;

export const ButtonBasic = styled.button`
  border-radius: 4px;
  background-color: ${Colors.white};
  padding: 4px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  font-size: 1rem;
`;

export const ButtonPrimary = styled(ButtonBasic)`
  background-color: ${Colors.blue};
  color: white;
  &:disabled {
    background-color: gray;
  }
`;

export const ButtonSecondary = styled(ButtonBasic)`
  color: ${Colors.blue};
  border: 1px solid ${Colors.blue};
  &:hover {
    background-color: ${Colors.blue};
    color: ${Colors.white} !important;
  }
`;

export const Divider = styled.hr`
  width: 100%;
  height: 1px;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  background-color: ${Colors.grey};
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.1rem;
`;

export const Label = styled.label`
  font-size: 0.8rem;
  color: ${Colors.blue};
  margin: 0;
`;

export const Input = styled.input`
  width: 100%;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  font-size: 0.9rem;
  &::placeholder {
    color: ${Colors.grey};
  }
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction};
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align};
  gap: ${(props) => props.gap};
  cursor: ${(props) => props.cursor};
  flex: ${(props) => props.flex};
  cursor: ${(props) => props.cursor};
  flex-wrap: ${(props) => props.wrap};
`;
export const ButtonSecondaryColored = styled(ButtonSecondary)`
  color: ${(props) => props.color};
  &:hover {
    background-color: ${(props) => props.color};
    color: #fff !important;
  }
`;
