import React, { useState } from 'react';
import { ScaffoldCard, InfoIcon, PrimaryButton, Divider } from 'components/common';
import { useGetHeadlineIntros } from 'queries/content/content-tools/get-headline-intros';
import { useContentContext } from 'context/content/ContentContext';
import { HeadlineIcon } from '../../styles';
import styles from './headline.module.css';

function HeadlineIntros() {
  const { topic } = useContentContext();
  const [Topic] = topic;
  const { data: headlineIntros, refetch, isLoading, isFetched } = useGetHeadlineIntros(Topic);

  const [HeadlineIntros, setHeadlineIntros] = useState([]);

  console.log(isFetched);
  const refetchHeadline = async () => {
    console.log('enter');
    await refetch();
    console.log(headlineIntros);
    // const headlineArray = [...HeadlineIntros];
    // headlineArray.push(headlineIntros);
    setHeadlineIntros([...HeadlineIntros, headlineIntros]);
    console.log('exit');
  };

  return (
    <div className="h-margin-t-large">
      <div className={styles.content_heading}>Headline Intros <InfoIcon className="h-margin-l-xsmall" /> NEED WORK</div>
      <ScaffoldCard className={styles.content_wrapper}>

        <PrimaryButton
          onClick={() => refetchHeadline()}
        >
          Generate
        </PrimaryButton>
        {isLoading && 'dance'}

        <div className={styles.content_wrapper}>
          {HeadlineIntros && HeadlineIntros.map((headline, index) => (
            <div className={styles.headline_intro_wrapper}>
              {index > 1 && <Divider />}
              <div className={styles.headline}><HeadlineIcon /> {headline?.headlines}</div>
              <div className={`h-margin-t-x2 ${styles.description}`}>{headline?.intro}</div>
            </div>
          ))}

        </div>

        {/* { headlineIntros && headlineIntros?.headlines} */}

      </ScaffoldCard>

    </div>
  );
}

export default HeadlineIntros;
