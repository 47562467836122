/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CustomTooltip from '../../../Shared/CustomTooltip';

const options = [
  { name: 'Video Vineyard', imgSrc: '/img/salesoption/video-camera.png' },
  { name: 'Headline Helios', imgSrc: '/img/salesoption/newspaper.png' },
  { name: 'Sales Samurai', imgSrc: '/img/salesoption/sales.png' },
  { name: 'Email Espresso', imgSrc: '/img/salesoption/email.png' },
  { name: 'Webinar Warrior', imgSrc: '/img/salesoption/webinar.png' },
  {
    name: 'LandingPage Linguini',
    imgSrc: '/img/salesoption/landing-page.png',
  },
  { name: 'Teaching Thor', imgSrc: '/img/salesoption/presentation.png' },
  { name: 'Insights Incubator', imgSrc: '/img/salesoption/insight.png' },
  { name: 'Upsell Unicorn', imgSrc: '/img/salesoption/upselling.png' },
  { name: 'Testimonialzilla', imgSrc: '/img/salesoption/testimonial.png' },
];

function SelectCategory({ onChange, selectedCategory }) {
  return (
    <>
      <div className="sales-select-category-list">
        {options.map((element, index) => (
          <div
            className={
            `sales-each-sub ${
              selectedCategory === element.name ? 'selected' : ''}`
          }
            key={index}
          >
            <input
              className="sales-radiobtn"
              type="radio"
              name="dropdownoption"
              id={element.name}
              value={element.name}
              onChange={onChange}
            />
            <label htmlFor={element.name} className="d-flex sales-radiobtn-label">
              <img
                className="mx-2 align-self-center"
                src={element.imgSrc}
                width="25"
              />
              <div>{element.name}</div>
            </label>
          </div>
        ))}
      </div>
      {/* <div className="sales-disclamer-body">
      <div className="sales-disclamer font-accent">
        <h2 className="text-center">Disclamer</h2>
        <p>
          During the process of development and beta testing we have collected
          scripts from different sources. We are working on adding more scripts.
          Users will also be able to add their scripts directly in future.
        </p>
      </div>
    </div> */}
    </>
  );
}

export function SelectSubCategory({ onChange, selectedCategory, options }) {
  return (
    <div className="sales-select-category-list">
      {options.map((element, index) => (
        <div
          className={
            `sales-each-sub ${
              selectedCategory === element.script_name ? 'selected' : ''}`
          }
          key={index}
        >
          <input
            className="sales-radiobtn"
            type="radio"
            name="dropdownoption"
            id={element.script_id}
            value={index}
            onChange={onChange}
          />
          <label htmlFor={element.script_id} className=" sales-radiobtn-label">
            <CustomTooltip title={element.description} placement="top" arrow>
              <InfoOutlinedIcon className="float-right text-muted" />
            </CustomTooltip>
            {element.script_name}
          </label>
        </div>
      ))}
    </div>
  );
}

export default SelectCategory;
