import React, { useState } from 'react';
import { ScaffoldCard } from 'components/common/scaffold-card';
import { PrimaryButton, InfoIcon } from 'components/common/';
import { useMutateSummarize } from 'queries/content/content-tools/mutate-text-summarize';
import styles from './text-summarizer.module.css';

function TextSummarizer() {
  const [RangeValue, setRangeValue] = useState(20);
  const [TextAreaValue, setTextAreaValue] = useState('');
  const [SummarizedData, setSummarizedData] = useState('');

  const { data, mutateAsync, isSuccess } = useMutateSummarize();

  const handleSummarizeClick = async () => {
    console.log('debug0', RangeValue);
    await mutateAsync({ TextAreaValue, RangeValue }).then((data) => setSummarizedData(data));
  };

  return (
    <div className="h-margin-t-large">
      <div className={styles.content_heading}>Summarizer <InfoIcon className="h-margin-l-xsmall" /></div>
      <ScaffoldCard className={styles.content_wrapper}>

        <div className={styles.textarea_container}>
          <textarea
            className={styles.textarea}
            type="textarea"
            value={TextAreaValue}
            onChange={(e) => setTextAreaValue(e.target.value)}
          />
        </div>

        <div className={`h-margin-t-large ${styles.range_container}`}>
          <input
            className={styles.range}
            type="range"
            min="20"
            max="50"
            value={RangeValue}
            onChange={(e) => setRangeValue(e.target.value)}
          />
          <span>Content Length : { RangeValue}</span>
        </div>

        <PrimaryButton
          className="h-margin-t-large"
          onClick={handleSummarizeClick}
        >
          Generate
        </PrimaryButton>

        {SummarizedData && <p>{SummarizedData}</p>}
      </ScaffoldCard>

    </div>
  );
}

export default TextSummarizer;
