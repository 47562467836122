import React from 'react';
import './CreateWorkspace.css';
import { Link, useLocation, Outlet } from 'react-router-dom';
import InnerTab from 'components/common/InnerTab/InnerTab';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import AppHeaderWrapper from 'components/common/AppHeader/AppHeaderWrapper';
import { CreateNewWorkspaceInnerTabs } from './CreateNewWorkspaceInnerTabDetails';

function CreateWorkspace() {
  const { pathname } = useLocation();

  return (
    <AppHeaderWrapper className="create-workspace-container">
      <InnerTab>
        {CreateNewWorkspaceInnerTabs.map((tabDetail, index) => (
          <>
            <Link
              className={pathname === tabDetail?.tab_route ? 'switch-button switch-button-active' : 'switch-button switch-button-inactive'}
              to={tabDetail?.tab_route}
              key={tabDetail?.tab_id}
            >
              {tabDetail?.tab_name}
            </Link>
            {index !== CreateNewWorkspaceInnerTabs.length - 1 && <NavigateNextIcon className="h-margin-r-tiny" />}
          </>

        ))}
      </InnerTab>
      <Outlet />
    </AppHeaderWrapper>

  );
}

export default CreateWorkspace;
