import styled from 'styled-components';

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  .create-tempalte {
    display: flex;
    justify-content: flex-end;
    width: 95%;
    gap: 1rem;
  }
  .clone-template-from-url {
    display: flex;
    justify-content: flex-end;
    width: 95%;
    margin-right: 50px;
  }
`;

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 65vh;
  flex-direction: column;
  gap: 1rem;
`;

export const TemplatesWrapper = styled.div`
  flex: 1;
  width: 95%;
  border-top-right-radius: 0.5rem;
  box-shadow: 0 5px 5px rgb(153, 153, 153);
  padding: 0.5rem;
  box-shadow: 0 0 5px #d4d4d4;
  border-radius: 5px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
`;
export const Heading = styled.h3`
  flex: 1;
  font-weight: 700;
`;
export const TemplateContainer = styled.div`
  flex: 10;
  display: flex;
  flex-direction: column;
`;
export const TemplateCardWrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: row;
  height: 580px;
  overflow: scroll;
  flex-wrap: wrap;
  justify-content: space-around;
`;
export const TableCommon = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.5rem;
  .name {
    flex: 1.2;
    font-weight: 600;
    cursor: pointer;
  }
  .name:hover {
    text-decoration: underline;
  }

  .description_heading {
    font-size: 1rem;
    flex: 2;
    padding: 0.2rem;
    text-align: justify;
  }

  .description {
    flex: 2;
    font-size: 0.9rem;
    padding: 0;
  }
  .author {
    flex: 1;
    font-size: 0.9rem;
  }
  .created_at {
    flex: 0.8;
    font-size: 0.9rem;
  }
  .actions {
    flex: 1;
    display: flex;
    justify-content: space-evenly;
  }
`;

export const TableHead = styled(TableCommon)`
  color: rgb(190, 190, 190);
  font-weight: 600;
  text-transform: uppercase;
  font-size: 1rem;
`;
export const TableColumn = styled(TableCommon)`
  color: #000;
  .show-on-hover > * {
    display: none;
  }
  &:hover {
    .show-on-hover > * {
      display: flex;
    }
    cursor: pointer;
  }
  height: max-content
`;
export const Divider = styled.hr`
  width: 100%;
  height: 1px;
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
  background-color: rgb(233, 233, 233);
`;

export const ModalBodyWrapper = styled.div`
  width: 30rem;
  height: max-content;
  padding: 1rem;
  border-top: 3px solid #2a9df5;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  .icon_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    .tool-tip {
      scale: 2;
      padding: 1rem;
    }
  }
`;

export const TextInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  gap: 0.2rem;
  .label_box {
    font-size: 0.8rem;
    color: #2a9df5;
  }
  .input_box {
    width: 100%;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    font-size: 0.9rem;
  }
`;
export const ModalButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
