/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable global-require */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import React, { useState } from 'react';
import './CurateWorkspace.css';
import { WORKSPACE } from 'libs/constants';

function CurateWorkspace() {
  const [BeginNow, setBeginNow] = useState(false);
  const [FormLoaded, setFormLoaded] = useState(false);
  const [LoadValue, setLoadValue] = useState(0);
  const BookDemoCallUrl =
    'https://calendly.com/crawlq-business-analytics/15min';

  const handleLoad = () => {
    // Google Form loaded first time, so set to 1
    if (LoadValue === 0) {
      setLoadValue(1);
    } else {
      // if load value is 2 means submitted
      setLoadValue(LoadValue + 1);
    }
  };

  // Load Value 2 => Run Payment Fn
  if (LoadValue === 2) {
    // setting zero for next response
    setLoadValue(0);
  }

  return (
    <div style={{ height: 'auto', display: 'flex' }}>
      <div style={{ width: '40%', padding: '15px' }}>
        <h5>DFY Workspace For Your Business Idea </h5>
        <img
          style={{ marginBottom: '20px', marginTop: '10px' }}
          src={require('assets/images/curate-workspace.svg').default}
          alt="Build Together"
        />
        <div
          style={{
            fontSize: '0.8rem',
            lineHeight: '1.3rem',
            fontWeight: '500',
          }}
        >
          <div>
            <span>Why DFY Workspace? ( Done-For-You )</span>
            <div>
              <span>
                Save time and effort by 10x. CrawlQ will do the research and
                content creation for you.
              </span>
              <span>
                We curate a highly niche-specific {WORKSPACE} on your business
                idea.
              </span>
            </div>
          </div>

          <div>
            <span>Perks :</span>
            <ul>
              <li>100% Level-1 Market Research </li>
              <li>1 Blog </li>
              <li>1 Script</li>
              <li>Pre-trained on Market Research and Search Intent</li>
            </ul>
          </div>
        </div>

        <div style={{ marginTop: '10px' }}>
          Have Questions?
          <a href={BookDemoCallUrl} target="_blank" rel="noreferrer">
            <button className="book-call-button">
              <b>
                {' '}
                <i
                  className="fas fa-video"
                  style={{ marginRight: '1px' }}
                />{' '}
                Book A Call
              </b>
            </button>
          </a>
        </div>
      </div>

      <div className="container">
        <iframe
          id="google-form-iframe"
          className="responsive-iframe"
          src="https://docs.google.com/forms/d/e/1FAIpQLScl72w2Y98mXO6qA9_My8h3ZwhUqBVatnqWN5xCYRlPJDKAXA/viewform?embedded=true"
          onLoad={() => handleLoad()}
        >
          Loading…
        </iframe>
      </div>
    </div>
  );

  // return (
  //     <div className='curate-workspace-main-container'>

  //         <div className='curate-workspace-main-container-left'>

  //             <h3>DFY Workspace For Your Business Idea </h3>

  //             <img src={require('assets/images/curate-workspace.svg').default} alt="Build Together" />

  //             <div className='curate-workspace-dfy-description-wrapper'>

  //                 <div className='curate-workspace-dfy-explain-wrapper'>
  //                     <span className='curate-workspace-dfy-question'>Why DFY Workspace? ( Done-For-You )</span>

  //                     <div className='curate-workspace-dfy-answer-wrapper'>
  //                         <span>Save time and effort by 10x. CrawlQ will do the research and content creation for you.</span>
  //                         <span>We curate a highly niche-specific workspace on your business idea.</span>
  //                     </div>

  //                 </div>

  //                 <div className='curate-workspace-dft-perks-wrapper'>
  //                     <span>Perks :</span>
  //                     <ul>
  //                         <li>100% Level-1 Market Research </li>
  //                         <li>1 Blog </li>
  //                         <li>1 Script</li>
  //                         <li>Pre-trained on Market Research and Search Intent</li>

  //                     </ul>
  //                 </div>
  //             </div>

  //             <div style={{marginTop: "10px"}}>Have Questions?
  //                 <a href={BookDemoCallUrl} target="_blank">
  //                 <b> Book A Call</b>
  //                 </a>
  //             </div>

  //         </div>

  //         <div className='gform-container'>

  //             <iframe id="google-form-iframe"
  //                     src="https://docs.google.com/forms/d/e/1FAIpQLScl72w2Y98mXO6qA9_My8h3ZwhUqBVatnqWN5xCYRlPJDKAXA/viewform?embedded=true" onLoad={() => handleLoad()}
  //                     width="600px" height="600px"
  //                     // frameborder="0" marginheight="0"
  //                     // marginwidth="0"
  //                     >
  //                     Loading…
  //             </iframe>

  //         </div>

  //     </div>
  // )
}

export default CurateWorkspace;
