import { SupportRounded } from '@mui/icons-material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AssistantRoundedIcon from '@mui/icons-material/AssistantRounded';
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded';
import MyLocationRoundedIcon from '@mui/icons-material/MyLocationRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import AssistantPicker from 'Component/Pages/ChatAthena/components/AssistantPicker';
import BrandVoice from 'Component/Pages/ChatAthena/components/BrandVoice';
import { MenuButton } from 'Component/Pages/ChatAthena/components/MenuButton';
import Prompts from 'Component/Pages/ChatAthena/components/Prompts';
import SearchPrompts from 'Component/Pages/ChatAthena/components/SearchPrompts';
import WorkspaceListDrawer from 'Component/Pages/ChatAthena/components/WorkspaceDrawer';
import { AddOn } from 'Component/Shared/AddOnModal/AddonModal';
import CustomTooltip from 'Component/Shared/CustomTooltip';
import { useIsMobile } from 'Component/hooks/useIsMobile';
import { validateUserAndRefreshToken } from 'Config/AxiosConfig.js';
import { ChatAthenaModelNameEnum } from 'libs/chat-athena-constants';
import { useGetSavedConfigurations } from 'queries/chat-athena/get-saved-configurations';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IntegrationsIcon } from 'components/common';
import { ROUTES } from 'constants/routes';
import { useGetChatHistory } from 'queries/chat-athena/get-chat-history';
import { ClearChatComponent } from './ChatAthenaComponents/ClearChatComponent';
import HelperVideo from './ChatAthenaComponents/HelperVideo';
import AppIntegration from './ChatAthenaComponents/components/AppIntegration';
import { ChatAthenaConfigurations } from './ChatAthenaComponents/components/ChatConfigurations/ChatAthenaConfigurations';
import ChatHistoryView from './ChatAthenaComponents/components/ChatHistory/ChatHistory';
import PromptsModal from './ChatAthenaComponents/components/DefaultPrompts/PromptsModal';
import DocumentHandler from './ChatAthenaComponents/components/Document/DocumentHandler';
import MessageBox from './ChatAthenaComponents/components/MessageBox/MessageBox';
import { ModelSelector } from './ChatAthenaComponents/components/ModelSelector/ModelSelector';
import { TrayButton } from './ChatAthenaComponents/components/TrayButton';
import { SelectedImageUploadContext } from './ChatAthenaComponents/components/contexts/SelectedImageUpload';
import { SelectedModelContext } from './ChatAthenaComponents/components/contexts/SelectedModel';
import { useSendMessage } from './ChatAthenaComponents/hooks/useSendMessage';
import FiveTranModal from './ChatAthenaComponents/components/FiveTran/FiveTranModal';
import styles from './chat-athena.module.css';
import './chat_athena.css';

const DEFAULT_PAZE_SIZE = 5;

export function ChatAthenaComponent(props) {
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const user = useSelector((state) => state?.user);
  const active_workspace = useSelector((state) => state?.workspace);
  const fallback_active_workspace = { name: 'Business Made Simple', mti: '' };
  const { uploadedImages, setUploadedImages } = useContext(
    SelectedImageUploadContext,
  );
  const { modelName, setModelName, imageModelName } = useContext(
    SelectedModelContext,
  );

  const {
    data: savedConfigurations,
    isLoading: isLoadingSavedConfigurations,
  } = useGetSavedConfigurations();

  const {
    sendMessageAndMutateHistory,
    isTyping,
    retryCount,
  } = useSendMessage();

  const { loading } = props;
  const [loadingLiking, setLoadingLiking] = useState(false);
  const [message, setMessage] = useState('');
  const [showAppIntegration, setShowAppIntegration] = useState(false);
  const textAreaRef = useRef(null);
  const chatContainerRef = useRef();

  const [selectedAnswer, setSelectedAnswer] = useState({
    index: -1,
    answer: '',
  });

  const [addOnLevel, setAddOnLevel] = useState(-1);
  const [showPrompt, setShowPrompt] = useState(false);
  const [showSearchPrompt, setShowSearchPrompt] = useState(false);
  const [showBrandVoice, setShowBrandVoice] = useState(false);
  const [showAssistants, setShowAssitants] = useState(false);
  const [assistant, setAssistant] = useState(null);
  const [selectedCreativeTool, setSelectedCreativeTool] = useState('');
  const [selectedTemperature, setSelectedTemperature] = useState(0.35);

  const [chatMode, setChatMode] = useState(false);
  const [selectedSources, setSelectedSources] = useState([]);
  const [selectedLikedIndex, setSelectedLikedIndex] = useState(-1);

  const [mode, setMode] = useState('basic');
  const [selectedPrompt, setSelectedPrompt] = useState(false);

  const { isDarkMode, setDarkMode, setStarterFlow } = props;

  const [selectedBrandVoice, setSelectedBrandVoice] = useState('');

  const [fivetranModalOpen, setFivetranModalOpen] = useState(false);
  const [defaultFivetranTab, setDefaultFivetranTab] = useState('All Apps');
  const [searchParams] = useSearchParams();
  let urlParams = Object.fromEntries([...searchParams]);
  const [currentPageSize, setCurrentPageSize] = useState(DEFAULT_PAZE_SIZE);

  const { data: chatHistory } = useGetChatHistory(currentPageSize);

  useEffect(() => {
    if (message === '') {
      setSelectedPrompt(null);
    }
  }, [message]);

  useEffect(() => {
    try {
      validateUserAndRefreshToken().then((loggedIn) => {
        if (!loggedIn) {
          navigate('/');
        }
      });
    } catch (e) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    const workspaceName = active_workspace?.name;
    localStorage.setItem('name', workspaceName);
  }, []);

  useEffect(() => {
    if (props?.selectedToolId !== null || props?.selectedToolId !== '') {
      setSelectedTemperature(0);
    }
  }, [props?.selectedToolId]);

  useEffect(() => {
    if (urlParams && urlParams.connectorUserEmail === user?.username) {
      setDefaultFivetranTab('Connected Apps');
      setFivetranModalOpen(true);
      urlParams = null;
      window.history.pushState(null, '', ROUTES.CHAT_ATHENA);
    }
  }, []);

  function selectAnswer(index, answer) {
    setSelectedAnswer({
      index,
      answer,
    });
  }

  function handleSendMessage(_question) {
    if (!(_question && _question.trim() !== '')) {
      textAreaRef.current.selectionStart = 0;
      return;
    }

    setMessage('');
    setUploadedImages([]);
    let _modelname = modelName;
    if (
      ((props?.selectedFileId && props?.selectedFileId?.length) ||
        props?.selectedToolId) &&
      modelName &&
      modelName === ChatAthenaModelNameEnum.claude
    ) {
      _modelname = ChatAthenaModelNameEnum.gpt3;
    }

    let mediaList = [];
    if (
      (_modelname === ChatAthenaModelNameEnum.gemini ||
        modelName === ChatAthenaModelNameEnum.gpt4) &&
      uploadedImages?.length > 0
    ) {
      mediaList = uploadedImages.map((img) => img.split(',')[1]);
    }
    const question = _question;
    const body = {
      username: user?.username,
      name: active_workspace.name ?? fallback_active_workspace?.name,
      main_topic: active_workspace.mti || 'Best Resources for 3D Printing',
      question,
      brand_voice_text: selectedBrandVoice,
      model_name: _modelname,
      image_model: imageModelName,
      media_list: mediaList,
      mode,
      sources: selectedSources,
      blog_type: selectedCreativeTool,
      chat_mode: chatMode,
      stat_me: selectedCreativeTool === 'stat_me',
      quote_me: selectedCreativeTool === 'quote_me',
      file_ids: props?.selectedFileId,
      tool_id: props?.selectedToolId,
      temperature: selectedTemperature,
      assistant_id: assistant?.assistant_id,
    };

    if (
      selectedCreativeTool === 'seo_brief' ||
      selectedCreativeTool === 'creative_brief'
    ) {
      setSelectedCreativeTool('');
    }

    sendMessageAndMutateHistory(body);
  }

  useEffect(() => {
    if (savedConfigurations && !isLoadingSavedConfigurations) {
      setSelectedSources(savedConfigurations?.selectedSources ?? []);
      setSelectedTemperature(savedConfigurations?.selectedTemperature ?? 0.35);
      setModelName(
        savedConfigurations?.modelName || ChatAthenaModelNameEnum.gpt3,
      );
    }
  }, [savedConfigurations]);

  return (
    <>
      {(addOnLevel === 0 || addOnLevel === 1) && (
        <AddOn
          level={addOnLevel}
          show
          insideFlyout
          setAlternativeShow={() => setAddOnLevel(-1)}
        />
      )}

      {showPrompt && (
        <Prompts
          darkMode={isDarkMode}
          show={showPrompt}
          setMessage={setMessage}
          closeModal={() => setShowPrompt(false)}
          setSelectedPrompt={setSelectedPrompt}
        />
      )}
      {showSearchPrompt && (
        <SearchPrompts
          darkMode={isDarkMode}
          show={showSearchPrompt}
          closeModal={() => setShowSearchPrompt(false)}
        />
      )}

      {showBrandVoice && (
        <BrandVoice
          darkMode={isDarkMode}
          show={showBrandVoice}
          setBrandVoice={setSelectedBrandVoice}
          setMessage={setMessage}
          closeModal={() => setShowBrandVoice(false)}
        />
      )}

      {showAssistants && (
        <AssistantPicker
          show={showAssistants}
          setAssistant={setAssistant}
          closeModal={() => setShowAssitants(false)}
        />
      )}

      {fivetranModalOpen && (
        <FiveTranModal
          visible={fivetranModalOpen}
          onClose={() => setFivetranModalOpen(false)}
          selectedTab={defaultFivetranTab}
        />
      )}

      <div className={styles.chatbox_wrapper}>
        <div className={styles.chatbox_header}>
          <div className={styles.workspace_list_drawer}>
            <WorkspaceListDrawer setStarterFlow={setStarterFlow} />
          </div>
          <MenuButton />
          <div className={styles.model_selector}>
            {!isMobile && <ModelSelector />}
          </div>

          <div className={styles.configs_wrapper}>
            {chatHistory?.pairs?.length > 0 && (
              <PromptsModal sendMessage={handleSendMessage} />
            )}

            <CustomTooltip title={'Explore CrawlQ'} arrow placement="bottom">
              <SupportRounded
                style={{ color: 'white' }}
                onClick={() =>
                  window.open('https://at.crawlq.app/explore', '__blank')
                }
              />
            </CustomTooltip>
            <ChatAthenaConfigurations
              selectedSources={selectedSources}
              setSelectedSources={setSelectedSources}
              setAddOnLevel={setAddOnLevel}
              selectedCreativeTool={selectedCreativeTool}
              setSelectedCreativeTool={setSelectedCreativeTool}
              selectedTemperature={selectedTemperature}
              setSelectedTemperature={setSelectedTemperature}
              documentMode={props?.selectedFileId}
            />
            <HelperVideo />
            <DocumentHandler
              darkMode={isDarkMode}
              selectedAssistant={assistant}
            />
            {/* <FiveTranHandler darkMode={isDarkMode} /> */}

            <CustomTooltip
              title="Manage Integrations"
              arrow
              placement="top"
              onClick={() => setFivetranModalOpen((prev) => !prev)}
            >
              <IntegrationsIcon />
            </CustomTooltip>
            <AppIntegration
              showAppIntegration={showAppIntegration}
              setShowAppIntegration={setShowAppIntegration}
            />
            <AccountCircleIcon onClick={() => navigate('/user-profile')} />
          </div>
        </div>
        <ChatHistoryView
          chatContainerRef={chatContainerRef}
          loading={loading}
          isDarkMode={isDarkMode}
          isMobile={isMobile}
          selectedAnswer={selectedAnswer}
          setSelectedAnswer={setSelectedAnswer}
          selectAnswer={selectAnswer}
          loadingLiking={loadingLiking}
          selectedLikedIndex={selectedLikedIndex}
          setSelectedLikedIndex={setSelectedLikedIndex}
          sendMessage={handleSendMessage}
          active_workspace={active_workspace}
          fallback_active_workspace={fallback_active_workspace}
          currentPageSize={currentPageSize}
          setCurrentPageSize={setCurrentPageSize}
        />

        <div className={styles.input_wrapper}>
          <div className={styles.message_box_wrapper}>
            <MessageBox
              delayedResponse={retryCount > 0}
              isTyping={isTyping}
              setMessage={setMessage}
              message={message}
              assistant={assistant}
              onSendMessage={handleSendMessage}
              removeAssistant={() => setAssistant(null)}
            />

            <div className={styles.helper_buttons_group}>
              <TrayButton
                label={'CrawlQ Search'}
                Icon={SearchRoundedIcon}
                onClick={() => {
                  setShowSearchPrompt(true);
                }}
              />

              <TrayButton
                label={'Use Assistant'}
                Icon={AssistantRoundedIcon}
                isAttachedInMessage={assistant}
                onClick={() => {
                  setShowAssitants(true);
                }}
              />
              <TrayButton
                label={'Use Templates'}
                Icon={MyLocationRoundedIcon}
                onClick={() => {
                  setShowPrompt(true);
                }}
              />
              <TrayButton
                label={'Brand Voice'}
                Icon={CampaignRoundedIcon}
                isAttachedInMessage={selectedBrandVoice.length > 0}
                onClick={() => {
                  setShowBrandVoice(true);
                }}
              />

              <ClearChatComponent
                isDarkMode={isDarkMode}
                isMobile={isMobile}
                username={user?.username}
                active_workspace={active_workspace ?? fallback_active_workspace}
                getChatHistory={() => {}}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ChatAthenaComponent;
