import { Button } from 'antd';
import { Flex } from 'Component/Shared/GlobalStyles';
import React from 'react';
import styles from '../chat-athena.module.css';
import { truncateString } from '../utils';

export const PromptCard = ({ name, prompt, onUse, darkMode }) => (
  <div className={`${styles.body} card`}>
    <Flex
      style={{
        color: darkMode ? 'white' : 'black',
        backgroundColor: darkMode ? '#444653' : 'white',
      }}
      className="card-body"
      align="flex-start"
      justify="space-between"
      direction="column"
    >
      <Button
        onClick={onUse}
        style={{
          backgroundColor: darkMode ? '#444653' : 'white',
          color: darkMode ? 'white' : 'black',
          borderRadius: '10px',
          marginBottom: '8px',
        }}
      >
        Use
      </Button>
      <h6
        style={{
          fontSize: '0.8rem',
          color: darkMode ? 'white' : 'black',
          fontWeight: 'bold',
          marginBottom: '5px',
        }}
        className={`${styles.heading} card-title`}
      >
        {truncateString(name, 100)}
      </h6>
      <p className={`${styles.description} card-title`}>
        {truncateString(prompt, 250)}
      </p>
    </Flex>
  </div>
);
