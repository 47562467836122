import { useRetryAddChatHistory } from 'queries/chat-athena/get-retry-add-chat-history';
import { useEffect } from 'react';

const { useMutateChatHistory } = require('queries/chat-athena/mutate-add-chat');

export const useSendMessage = () => {
  const {
    mutateAsync: sendMessageAndMutateHistory,
    isLoading: isTypingFirstAttempt,
    isError: hadFailed,
    failureCount: firstFailure,
  } = useMutateChatHistory();

  const {
    failureCount,
    isLoading: isRetryingToGetLatestResponse,
    mutate: retryToGetResponseOfPreviousMessage,
    isSuccess: hasRecivedResponseToPreviousMessage,
  } = useRetryAddChatHistory();

  useEffect(() => {
    if (!hadFailed) return;
    retryToGetResponseOfPreviousMessage();
  }, [hadFailed, retryToGetResponseOfPreviousMessage]);

  const getFailureCount = () => {
    if (firstFailure === 0) return 0;
    if (hasRecivedResponseToPreviousMessage) return 0;
    return failureCount + 1;
  };

  return {
    sendMessageAndMutateHistory,
    isTyping: isTypingFirstAttempt || isRetryingToGetLatestResponse,
    retryCount: getFailureCount(),
  };
};
