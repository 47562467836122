/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useState, useEffect } from 'react';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import Slider from '@mui/material/Slider';
import Tooltip from '@mui/material/Tooltip';
import useHover from 'hooks/useMousehover';
import GetAppRoundedIcon from '@mui/icons-material/GetAppRounded';
import styles from './index.module.css';

const PLAYBACK_RATES = [0.5, 0.75, 1, 1.25, 1.5, 2];

function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

export const AudioPlayer = ({ url }) => {
  if (!url) return <></>;

  const { isHovered, bind: volumeContainerBind } = useHover();

  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);
  const [playbackRate, setPlaybackRate] = useState(1);

  const playAudio = () => {
    if (audioRef.current) {
      audioRef.current.play();
      setIsPlaying(true);
    }
  };

  const pauseAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      setIsPlaying(false);
    }
  };

  const togglePlay = () => {
    if (isPlaying) {
      pauseAudio();
    } else {
      playAudio();
    }
  };

  const handleVolumeChange = (val) => {
    setVolume(val / 100);
  };

  const handlePlaybackRateChange = (e) => {
    setPlaybackRate(parseFloat(e.target.value));
  };

  const handleSeekBarChange = (event) => {
    const newProgress = parseFloat(event.target.value);
    setProgress(newProgress);
    if (audioRef.current) {
      audioRef.current.currentTime = (newProgress / 100) * duration;
    }
  };

  const formatDuration = (value) => {
    const minutes = Math.floor(value / 60);
    const seconds = Math.floor(value % 60);
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  const handleDownloadAudio = () => {
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.target = '_blank';

    document.body.appendChild(downloadLink);

    downloadLink.click();

    document.body.removeChild(downloadLink);
  };

  useEffect(() => {
    const audio = audioRef.current;

    const updateProgress = () => {
      if (audio) {
        if (audio.currentTime >= audio.duration) {
          pauseAudio();
        }
        setProgress(
          (parseInt(audio.currentTime, 10) / parseInt(audio.duration, 10)) *
            100,
        );
        setCurrentTime(parseInt(audio.currentTime, 10));
      }
    };

    const updateDuration = () => {
      if (audio) {
        setDuration(audio.duration);
      }
    };

    audio.addEventListener('timeupdate', updateProgress);
    audio.addEventListener('loadedmetadata', updateDuration);

    return () => {
      audio.removeEventListener('timeupdate', updateProgress);
      audio.removeEventListener('loadedmetadata', updateDuration);
    };
  }, []);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume;
    }
  }, [volume]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.playbackRate = playbackRate;
    }
  }, [playbackRate]);

  return (
    <div className={styles.audioPlayer}>
      <audio ref={audioRef} className={styles.audio} controls>
        <source src={url} />
      </audio>
      <div className={styles.audio_box}>
        <div className={styles.controls}>
          {isPlaying ? (
            <PauseIcon onClick={togglePlay} className={styles.icon} />
          ) : (
            <PlayArrowIcon onClick={togglePlay} className={styles.icon} />
          )}
        </div>
        <div className={styles.seekBarContainer}>
          <input
            type="range"
            value={Math.floor(progress)}
            min={0}
            max={100}
            onChange={handleSeekBarChange}
            className={styles.seekBar}
          />
          <div className={styles.duration}>
            {formatDuration(currentTime)}/{formatDuration(duration)}
          </div>
        </div>
      </div>

      <div className={styles.volumeContainer}>
        <div className={styles.volumeContainer} {...volumeContainerBind}>
          {volume === 0 ? (
            <VolumeOffIcon />
          ) : (
            <VolumeDownIcon className={styles.icon} />
          )}
          <div className={styles.volumeSlider}>
            <Slider
              valueLabelDisplay="auto"
              slots={{
                valueLabel: ValueLabelComponent,
              }}
              aria-label="custom thumb label"
              min={0}
              max={100}
              value={parseInt(volume * 100, 10)}
              onChange={(e, val) => handleVolumeChange(val)}
            />
          </div>
        </div>

        <select
          className={styles.select}
          onChange={handlePlaybackRateChange}
          value={playbackRate}
        >
          {PLAYBACK_RATES.map((rate) => (
            <option key={rate} value={rate}>
              {rate}x
            </option>
          ))}
        </select>
        <GetAppRoundedIcon
          onClick={handleDownloadAudio}
          className={styles.icon}
        />
      </div>
    </div>
  );
};
