/* eslint-disable react/require-default-props */
import React from 'react';
import './styles.css';
import Loader from 'react-loader-spinner';
import PropTypes from 'prop-types';

function FallbackLoader({ color, height, width }) {
  return (
    <div className="fallback-container">
      <Loader
        type="TailSpin"
        color={color || '#63a5f0'}
        secondaryColor="white"
        height={height}
        width={width}
      />
    </div>

  );
}

FallbackLoader.propTypes = {
  color: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,

};

export default FallbackLoader;
