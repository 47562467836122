/* eslint-disable consistent-return */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import axios from 'axios';
import { store } from 'redux/store';
import createInstance from 'Config/AxiosConfig';
import {
  baseAPIsUrl,
  marketResearchQuestion,
} from '../../../../../../Config/Api';

const getUsernameFromReduxStore = () => {
  const state = store.getState();
  const { username } = state.user;
  return username;
};

const DemoCloneWorkspaceRequest = async (name, domain) => {
  try {
    const body = {
      name,
      site: domain,
      demo_name: name,
      fullName: getUsernameFromReduxStore(),
      fromaccount: 'support@quantamixsolutions.com',
    };

    const instance = await createInstance();

    const result = await instance.post('demo-load', body);

    if (result.data.statusCode === '500') {
      return {
        error: true,
        data: null,
      };
    }
    // window.location.reload() Check Manikant why it had this reload like this.
    return {
      error: false,
      data: true,
    };
  } catch (error) {
    console.log(error);
  }
};

// fetch users selected workspace and check MRR - Define your niche is filled or not ? /dashboard : /mrr-get-started
export const IsMinMarketResearchDone = async (demo_workspace_name) => {
  try {
    const body = {
      username: getUsernameFromReduxStore(),
      name: demo_workspace_name,
    };
    const { data } = await axios.post(
      'https://apis.crawlq.ai/check-onboarding',
      body,
    );

    if (data.statusCode === 500) {
      return { destination: '/', mrr_filled: false, error: true };
    }
    if (data.goto_onboarding) {
      return {
        destination: '/onboarding-qna',
        mrr_filled: false,
        error: false,
      };
    }
    return { destination: '/dashboard', mrr_filled: false, error: false };
  } catch (error) {
    console.log(error);
  }
};

export const getMainTopicOfIntrest = async (name) => {
  const reduxState = store.getState();
  try {
    let { data } = await axios.get(
      `${baseAPIsUrl}/load-questions?subcategory=Define%20your%20Niche%20Semantics&username=${reduxState.user.username}&name=${name}`,
    );

    data = data.data;

    return data[0].answer;
  } catch (ex) {
    console.log('Error in gettting MTI', ex);
    return null;
  }
};

export default DemoCloneWorkspaceRequest;
