/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import React, { useContext, useState } from 'react';
import '../css/topic_Section.css';

import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import copyClipboard, {
  addInEditor,
  selectTextAndCopyWithElement,
} from '../../../Shared/copyToClipBoard';
import { Editor } from '../contexts/summary_Context';

export function TopicSection(props) {
  const [topic_name, setTopicName] = useState(props.topic_name);
  const [keywords, setKeywords] = useState(props.keywords);

  const { globalEditor, setglobalEditor } = useContext(Editor);

  function add_Topic_Name_Editor() {
    setglobalEditor(`${globalEditor}<br>${topic_name}`);
  }
  function add_Topic_Keywords_Editor() {
    setglobalEditor(`${globalEditor}<br>${keywords}`);
  }
  function add_Topic_Questions_Editor() {
    setglobalEditor(`${globalEditor}<br>${topic_name}`);
  }

  if (typeof props.topic_name === 'undefined') {
    return <div>Show loader</div>;
  }
  return (
    <div className="tag-body">
      <div className="tag-col">
        <span className="topic__name__copyIcon">
          <span style={{ display: 'flex' }}>
            <span>{props.topic_name}</span>
            <FileCopyOutlinedIcon
              style={{ cursor: 'pointer', fontSize: '1.5rem' }}
              onClick={(e) => {
                const newContent = `<span>${props.topic_name}</span>`;
                addInEditor(newContent);
              }}
            />
          </span>
        </span>
        <span>{props.score}</span>
      </div>
    </div>
  );
}
export default TopicSection;
