/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import React, { useState, useEffect } from 'react';
import './UserWorkspaceCopy.css';

// Component
import IosShareIcon from '@mui/icons-material/IosShare';
import LoaderSpin from '../../../../../Shared/Loader';

/* Logic Function */
import { fetchUserWorkspace } from '../../WorkspaceLogic';
import CopyWorkspaceRequest from './UserWorkspaceCopyLogic';
import { getMemberslist } from 'Component/Pages/TeamMember/TeamMemberLogic';
import MultiSelectEmails from './MultiSelectEmails';
import { toast, ToastContainer } from 'react-toastify';
import { notification } from 'antd';

function UserWorkspaceCopy({ workspace_name, close_copy_modal }) {
  const validProjectName = /^[0-9a-zA-Z _\b]+$/;

  const [IsCopyConfirming, setIsCopyConfirming] = useState(false);
  const [destinationEmails, setDestinationEmails] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [IsCopying, setIsCopying] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState('');
  const [isMembersLoaded, setIsMembersLoaded] = useState(false);

  const showNotification = (type, message, description) => {
    notification.open({
      type,
      message,
      description,
      placement: 'bottomRight',
      duration: 6,
    });
  };

  useEffect(() => {
    getTeamMembers();
  }, []);

  const getTeamMembers = async () => {
    const adminEmail = localStorage.getItem('username');
    const teamMembersList = await getMemberslist();
    const filteredList = teamMembersList.filter(
      (_pair) => _pair?.email !== adminEmail,
    );
    // if (!filteredList.length > 0) {
    //   showNotification('info', 'No Team member found!', 'Please click on the profile page to add team member!');
    //   return;
    // }
    setIsMembersLoaded(true);
    setTeamMembers(filteredList);
  };

  const handleCopy = async (workspace_name) => {
    setIsCopyConfirming(true);
    const result = await CopyWorkspaceRequest(
      workspace_name,
      destinationEmails,
    );

    if (result.error === true) {
      setIsCopyConfirming(false);
      setErrorMessage('Could not copy workspace');
      setIsCopying(true);
    } else {
      const membersString = destinationEmails.join(', ');
      console.log('membersStringmembersString', membersString);
      setIsCopyConfirming(false);
      close_copy_modal();
      showNotification(
        'success',
        'SUCCESS',
        `Workspace successfully shared with ${membersString}`,
      );
    }
  };

  const handleChangeEmail = (destination_emails) => {
    setDestinationEmails(destination_emails);
  };

  return (
    isMembersLoaded && (
      <div className="workspace-copy-main-container">
        <div className="workspace-copy-icon-wrapper">
          <IosShareIcon
            style={{ color: '#2a9df5', width: '5rem', height: '5rem' }}
          />
        </div>
        <h5>Share {workspace_name} ?</h5>

        {IsCopying ? (
          <span className="copy-workspace-error-message">{ErrorMessage}</span>
        ) : null}

        <MultiSelectEmails
          team_members={teamMembers}
          setData={handleChangeEmail}
        />

        <div className="workspace-copy-button-wrapper">
          <button onClick={close_copy_modal}>CANCEL</button>
          {IsCopyConfirming ? (
            <div>
              <LoaderSpin width="25" height="25" />
            </div>
          ) : (
            <button
              disabled={IsCopying}
              onClick={() => handleCopy(workspace_name)}
            >
              CONFIRM
            </button>
          )}
        </div>
      </div>
    )
  );
}

export default UserWorkspaceCopy;
