/* eslint-disable react/jsx-filename-extension */
import React from 'react';

function NoDataAvailable() {
  return (
    <div
      style={{
        height: '25rem',
        display: 'flex',
        justifyContent: 'center',
        // alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <p>
        Your search -
        {' '}
        <b>{localStorage.getItem('questionTopic')}</b>
        - did not
        match any documents.
      </p>
      <p style={{ marginTop: '1rem' }}>Suggestions:</p>
      <ul>
        <li>Make sure that all words are spelled correctly.</li>
        <li>Try different keywords.</li>
        <li>Try more general keywords.</li>
      </ul>
    </div>
  );
}

export default NoDataAvailable;
