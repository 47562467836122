/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable consistent-return */
/* eslint-disable no-unreachable */
/* eslint-disable no-param-reassign */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/destructuring-assignment */
import BaseLayout from 'Component/Shared/BaseLayout/BaseLayout';
import {
  Divider,
  Flex,
  HeadingDesc,
  HeadingName,
} from 'Component/Shared/GlobalStyles';
import { LoaderTailSpin } from 'Component/Shared/Loader';
import { sortArrayOfObjects } from 'Component/Shared/utils';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { getAllWorkspaceBuildStatus } from './builstatus.service';
import ProgressComponent from './helper-components/ProgressComponent';

const BuildWorkspaceStatus = () => {
  const [workspaceStatus, setWorkspaceStatus] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchWorkspaceData = () => {
    setIsLoading(true);
    getAllWorkspaceBuildStatus()
      .then(({ data }) => {
        const data_sorted = sortArrayOfObjects(data, [
          (item) => moment(item.configurations?.date_created),
        ]);
        setWorkspaceStatus([...data_sorted]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchWorkspaceData();
  }, []);

  return (
    <BaseLayout>
      <HeadingName>Persona Engine Configuration</HeadingName>
      <HeadingDesc>
        Turn AI-powered listening into actionable insights
      </HeadingDesc>
      <Divider />
      {isLoading && (
        <Flex justify="center">
          <LoaderTailSpin />
        </Flex>
      )}
      {workspaceStatus.length > 0 && (
        <Flex direction={'column'} gap={'2rem'} className="container">
          {workspaceStatus.map((workspace) => (
            <ProgressComponent
              workspaceName={workspace.name}
              data={workspace.onboarding_status}
              fetchWorkspaceData={fetchWorkspaceData}
              date_created={moment
                .utc(workspace?.configurations?.date_created)
                .local()
                .format('MMM Do YYYY, h:mm:ss a')}
              viewMore
            />
          ))}
        </Flex>
      )}
      {workspaceStatus.length === 0 && !isLoading && (
        <p>You Have Not Build any workspace Yet.</p>
      )}
    </BaseLayout>
  );
};

export default BuildWorkspaceStatus;
