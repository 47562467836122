import BaseLayout from 'Component/Shared/BaseLayout/BaseLayout';
import { HeadingDesc, HeadingName } from 'Component/Shared/GlobalStyles';
import AppHeaderWrapper from 'components/common/AppHeader/AppHeaderWrapper';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { BLOG_PATH } from '../PublishedArticles/components/constant';
import PublishedArticles from '../PublishedArticles/PublishedArticles';
import styles from './digital-assets.module.css';

function DigitalAssetsPageBlogPost() {
  const fullName = useSelector((state) => state?.user?.name);

  return (
    <AppHeaderWrapper>
      <div
        style={{
          display: 'flex',
          gap: '2rem',
          alignItems: 'center',
        }}
      >
        <HeadingName>Digital Assets Management</HeadingName>
        <div className={styles.link_group}>
          <Link
            to="/digital-asset-management-blogposts"
            className={styles.link_active}
          >
            Blog Posts
          </Link>
          <Link to="/digital-asset-management-salesposts">Sales Posts</Link>
        </div>
      </div>
      <HeadingDesc style={{ fontWeight: '500', marginLeft: '10px' }}>
        Hey {fullName}, Now You Can Plan, Organize, and Track Your Content
        Initiatives Using Editorial Calendar.
      </HeadingDesc>
      <PublishedArticles from={BLOG_PATH.NON_SALES} />
    </AppHeaderWrapper>
  );
}

export default DigitalAssetsPageBlogPost;
