import CustomTooltip from 'Component/Shared/CustomTooltip';
import {
  LinkIcon,
  CopyFileIcon,
} from 'components/common';
import React, { useState } from 'react';
import Modal from 'react-modal';
import { showNotification } from 'libs/notification';
import copyClipboard from 'Component/Shared/copyToClipBoard';
import { useSelector } from 'react-redux';
import styles from './model-styles.module.css';

const customStyles = {
  content: {
    width: '30rem',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const CreateAssistantLink = ({ assistantId }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const user = useSelector((state) => state.user);
  const assistantLink = `https://app.crawlq.ai/assistant?from=${user.username}&assistant_id=${assistantId}`;

  const handleModalOpen = () => {
    setModalIsOpen(true);
  };

  const handleCopyLink = () => {
    copyClipboard(assistantLink, true);
    showNotification('success', "Assistant's Link Copied Successfully.");
  };

  return (
    <div>
      <CustomTooltip
        placement="top"
        title="Generate Link and Share Assistant With Other CrawlQ Account"
        arrow
      >
        <LinkIcon onClick={handleModalOpen} />
      </CustomTooltip>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={customStyles}
      >
        <div className={styles.modal_container}>
          <h5>Generate Assistant Link</h5>
          <div className={styles.assistant_link}>
            <code>{assistantLink}</code>
            <CopyFileIcon onClick={handleCopyLink} />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CreateAssistantLink;
