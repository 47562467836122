/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// Components
import UpgradeButton from 'Component/Shared/UpdagradeEntryPlan/UpgradeButton';
import Flyout from 'Component/Shared/Flyout/Flyout';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TimelineIcon from '@mui/icons-material/Timeline';
import ButtonNext, { ButtonPrevious } from '../../../Shared/ButtonNext';
import CustomTooltip from '../../../Shared/CustomTooltip';
import LoaderSpin from '../../../Shared/Loader';
import Topnav from '../../../Shared/Sidenav/Topnav';
import Topbar from '../../../Shared/Topbar/Topbar';
import InnerTopnav from '../Shared/InnerTopNav';
import QuestionsHead from './QuestionsThirdStage';
import SelectCategory, { SelectSubCategory } from './SelectCategory';
import PublishedArticles from '../PublishedArticles/PublishedArticles';

// Helpers && Utilities && Logic Fn
import './salesCopyWriter.css';
import minQueAnswered from './salesCopyWriterHelper';
import handleGetAllSubscripts, {
  getAllQuestionsSales,
  getSavedDocs,
} from './salesCopyWriterLogic';
import LockContentAutomation from '../Shared/LockContentAutomation';
import { BLOG_PATH } from '../PublishedArticles/components/constant';

// Assets
import RightArrow from '../../../../assets/img/right-arrow.breadcrumb.svg';
import upgradePlanImg from '../../../../assets/img/upgradePlan.svg';
import { HeadingDesc } from 'Component/Shared/GlobalStyles';
import { useNavigate } from 'react-router-dom';

function SalesCopyWritter() {
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubCategory, setSelectedSubCategory] = useState('');
  const [stage, setStage] = useState(1);
  const [subScripts, setSubScripts] = useState({ data: {}, hadLoaded: false });
  const [questions, setQuestions] = useState([]);
  const [hasQuestionsLoaded, setHasQuestionsLoaded] = useState(false);
  const [fillWithAI, setFillWithAI] = useState({
    isLoading: false,
    compulsoryQuestion: 0,
    questionsAnswredIndexes: [],
    activateEachAI: false,
  });
  const [isEntryPlan, setIsEntryPlan] = useState(false);

  const [SavedDrafts, setSavedDrafts] = useState({
    data: [],
    isLoading: false,
  });
  const userPlan = useSelector((state) => state.user.plan);
  const fullName = useSelector((state) => state?.user?.name);

  const [IsPublishedArticlesOpen, setIsPublishedArticlesOpen] = useState(false);
  useEffect(() => {
    if (
      userPlan === 'CrawlQ Entry Plan (Monthly)' ||
      userPlan === 'CrawlQ Entry Plan (Yearly)' ||
      userPlan === 'CrawlQ Free Trial'
    ) {
      setIsEntryPlan(true);
    }

    handleGetAllSubscripts().then(({ data, error }) => {
      if (!error) {
        setSubScripts({ data, hasLoaded: true });
      }
    });
  }, []);

  const handleCategoryChange = (e) => {
    if (stage === 1) setSelectedCategory(e.target.value);
    else {
      setSelectedSubCategory(subScripts.data[selectedCategory][e.target.value]);
    }
  };

  const goToNextStage = () => {
    setStage((prev) => prev + 1);
    if (stage === 2) {
      if (selectedSubCategory === '') {
        setStage((prev) => prev - 1);
        return;
      }
      getAllQuestionsSales(selectedSubCategory.script_id).then(({ data }) => {
        setQuestions(data);
        setFillWithAI({
          ...fillWithAI,
          compulsoryQuestion: minQueAnswered(data.length),
        });
        setHasQuestionsLoaded(true);
      });
    }
  };

  function InnerStepNavBar() {
    return (
      <>
        {stage === 1 ? (
          <div />
        ) : (
          <div className="sales-button-group">
            <ButtonPrevious
              onClick={() => {
                setStage((prev) => prev - 1);
                setSelectedSubCategory('');
              }}
            />
          </div>
        )}
        {stage === 1 && (
          <div className="font-accent sales">
            Select A Category
            <CustomTooltip
              title="During the process of development and beta testing we have collected scripts from different sources. We are working on adding more scripts. Users will also be able to add their scripts directly in future."
              arrow
              placement="top"
            >
              <InfoOutlinedIcon className="mx-2" />
            </CustomTooltip>
          </div>
        )}
        {stage === 2 && (
          <div className="font-accent sales">Select A Sub Category</div>
        )}

        {subScripts.hasLoaded && selectedCategory !== '' ? (
          <div className="sales-button-group">
            {stage < 3 ? <ButtonNext onClick={goToNextStage} /> : null}
          </div>
        ) : (
          <button className="btn btn-secondary" disabled>
            Next
          </button>
        )}
      </>
    );
  }

  function UpgradeEntryplan() {
    return (
      <div className="sales-upgradeEntryPlanSales">
        <div>
          <h4>Upgrade Plan 🚀</h4>

          <img src={upgradePlanImg} />
          <pre className="font-accent">
            {`
          To create amazing sales letters, landing pages and email sequences
          powered by market insights and target audience questions and answers`}
          </pre>
          <span>Please upgrade your plan</span>
          <div>
            <UpgradeButton />
          </div>
        </div>
      </div>
    );
  }

  const openDigitalAssetsBlogSalesPage = () => {
    navigate('/digital-asset-management-salesposts');
  };

  return (
    <>
      <Topbar />
      <Topnav />
      <LockContentAutomation />
      <Flyout
        open={IsPublishedArticlesOpen}
        onClose={() => setIsPublishedArticlesOpen(false)}
        title="Digital Asset Management (DAM)"
      >
        <HeadingDesc style={{ fontWeight: '500', marginLeft: '10px' }}>
          Hey {fullName}, Now You Can Plan, Organize, and Track Your Content
          Initiatives Using Editorial Calendar.
        </HeadingDesc>
        <PublishedArticles from={BLOG_PATH.SALES} />
      </Flyout>
      <div className="sales-container">
        <div className="sales-nav-container">
          <InnerTopnav />
        </div>
        <div className="aw-breadcrumb">
          <span
            className="font-accent txt-primary cursor-pointer"
            onClick={() => setStage(1)}
          >
            Sales Copywriter
          </span>
          <img className="mx-2" width="15" src={RightArrow} />
          <span
            className="font-accent txt-primary cursor-pointer"
            onClick={() => setStage(2)}
          >
            {selectedCategory === '' ? 'Initial Step' : selectedCategory}
          </span>
          {selectedSubCategory !== '' ? (
            <>
              <img className="mx-2" width="15" src={RightArrow} />
              <span className="font-accent txt-primary cursor-pointer">
                {selectedSubCategory.script_name}
              </span>
            </>
          ) : (
            ''
          )}
          <hr />
        </div>
        <div className="sales-main-container">
          <div className="sales-main">
            {isEntryPlan ? (
              <UpgradeEntryplan />
            ) : (
              <>
                <div className="sales-main-phase2-nav-controller">
                  <InnerStepNavBar />
                </div>
                <div className="sales-options-container">
                  {stage === 1 && (
                    <SelectCategory
                      onChange={handleCategoryChange}
                      selectedCategory={selectedCategory}
                    />
                  )}
                  {stage === 2 && (
                    <SelectSubCategory
                      onChange={handleCategoryChange}
                      selectedCategory={selectedSubCategory.script_name}
                      options={subScripts.data[selectedCategory]}
                    />
                  )}
                  {stage === 3 && (
                    <>
                      {hasQuestionsLoaded ? (
                        <QuestionsHead
                          selectedSubCategory={selectedSubCategory}
                          questions={questions}
                          Docs={SavedDrafts}
                        />
                      ) : (
                        <div className="sales-question-Loader">
                          <LoaderSpin />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </>
            )}
          </div>
          <div className="sales-recent-activity">
            <div className="aw-show-activity">
              <button onClick={openDigitalAssetsBlogSalesPage}>
                <TimelineIcon />
                Articles
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SalesCopyWritter;
