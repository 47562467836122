import {
  Divider,
  FilePinIcon,
  CrossIcon,
  UploadDoneIcon,
  LoaderTailSpin,
  PrimaryButton,
  ScaffoldCard,
} from 'components/common';
import AvatarGeneratorPlaceholder from 'components/common/AvatarGenerator/AvatarGenerator';
import React, { useEffect, useState } from 'react';
import {
  getProcessedAndUploadedFileIds,
  getSelectedActiveSources,
  getSelectedActiveTools,
  setUploadingForSelectedFile,
  validateEditAssistantInputs,
} from 'queries/assistants/helpers';
import { useMutateProcessUploadedDocument } from 'queries/process-uploaded-file';
import { useMutateUploadDocument } from 'queries/upload-document/upload-document';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';
import { useMutateUpdateAssistant } from 'queries/assistants/mutate-edit-assistant';
import { ASSISTANTS_VALID_FILES_TYPE } from 'constants/assistants';
import ExistingFiles from '../assistants-actions/existing-files/ExistingFiles';
import styles from './edit-assistant.module.css';

const EditAssistant = () => {
  const location = useLocation();

  // Update Assistant Hook
  const {
    mutateAsync: mutateEditAssistant,
    isLoading: isUpdatingAssistant,
  } = useMutateUpdateAssistant();

  // Upload Docs Hook
  const { mutateAsync: mutateAsyncUploadDocument } = useMutateUploadDocument();

  // Process Docs Hook
  const {
    mutateAsync: mutateAsyncProcessUploadedDocument,
  } = useMutateProcessUploadedDocument();

  const [assistantInputInfo, setAssistantInputInfo] = useState({
    name: '',
    description: '',
    instruction: '',
    id: '',
  });
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [processedAndUploadedFiles, setProcessedAndUploadedFiles] = useState(
    [],
  );
  const [assistantAvatarProps, setAssistantAvatarProps] = useState();
  const [assistantTools, setAssistantTools] = useState([
    {
      name: 'Retrieval',
      value: 'retrieval',
      checked: false,
    },
    {
      name: 'Code Interpreter',
      value: 'code_interpreter',
      checked: false,
    },
  ]);

  const [assistantSources, setAssistantSources] = useState([
    {
      name: 'Market Research',
      value: 'mrr',
      checked: false,
    },
    {
      name: 'Market Insights',
      value: 'mrr_insights',
      checked: false,
    },
    {
      name: 'Google',
      value: 'serp',
      checked: false,
    },
  ]);

  // Responsible for assistant name, instruction and description inputs
  const handleAssistantInputInfo = (event) => {
    setAssistantInputInfo({
      ...assistantInputInfo,
      [event.target.name]: event.target.value,
    });
  };

  // Responsbile for file selection from device
  const handleFileSelection = (event) => {
    const file = event.target.files[0];

    // Adding Selected Files Into Files Array
    if (file) {
      setSelectedFiles([
        ...selectedFiles,
        { name: event.target.files[0]?.name, file, uploading: false },
      ]);
    }
  };

  // Responsible for uploading the selected document/file
  const handleUploadFile = async (selectedDocument) => {
    // setting the selected file 'uploading' status to true
    const updatedUploadingStateOfSelectedDocument = setUploadingForSelectedFile(
      selectedFiles,
      selectedDocument,
    );

    setSelectedFiles(updatedUploadingStateOfSelectedDocument);

    // uploading the file/document
    await mutateAsyncUploadDocument({
      fileName: selectedDocument?.name,
      file: selectedDocument?.file,
    }).then(async (uploadedFileId) => {
      // if fileId received, its means file uploaded successfully.
      if (uploadedFileId) {
        // process the uploaded document
        mutateAsyncProcessUploadedDocument({
          name: selectedDocument?.name,
          file_id: uploadedFileId,
        }).then(() => {
          // SelectedFiles state needs to filter the uploaded file
          const nonProcessedAndUploadedFileList = selectedFiles.filter(
            (file) => file?.name !== selectedDocument?.name,
          );
          setSelectedFiles(nonProcessedAndUploadedFileList);

          // Updated the ProcessedAndUploaded state with the new uploaded file
          setProcessedAndUploadedFiles([
            ...processedAndUploadedFiles,
            {
              name: selectedDocument?.name,
              fileId: uploadedFileId,
              state: true,
            },
          ]);
        });
      }
    });
  };

  // Responsible for removing the selected file
  const handleFilterFile = (toRemoveFile) => {
    const filteredFiles = selectedFiles.filter(
      (file) => file?.name !== toRemoveFile,
    );

    setSelectedFiles(filteredFiles);
  };

  const handleFilterProcessedFile = (toRemoveFile) => {
    const filteredFiles = processedAndUploadedFiles.filter(
      (file) => file?.name !== toRemoveFile,
    );

    setProcessedAndUploadedFiles(filteredFiles);
  };

  // Responsible for toggling checked state of the selected tool
  const handleToolSelection = (selectedTool) => {
    // Find The Index of selected Tool
    const selectedToolIdx = assistantTools.findIndex(
      (tool) =>
        tool?.name === selectedTool?.name || tool?.value === selectedTool?.name,
    );

    if (selectedToolIdx === -1) {
      return;
    }

    // Create Copy of Assistant Tool
    const AssistantToolsCopy = [...assistantTools];

    // Toogle the Tool boolean value in Copy of Assistant Tool
    AssistantToolsCopy[selectedToolIdx].checked = !assistantTools[
      selectedToolIdx
    ].checked;

    // Set the copy of Assistant Tool
    setAssistantTools(AssistantToolsCopy);
  };

  // Responsible for toggling checked state of the selected source
  const handleSourceSelection = (selectedSource) => {
    // Find The Index of selected Source
    const selectedSourceIdx = assistantSources.findIndex(
      (source) =>
        source?.name === selectedSource?.name ||
        source?.value === selectedSource?.name,
    );

    if (selectedSourceIdx === -1) {
      return;
    }

    const AssistantSourcesCopy = [...assistantSources];

    // Toogle the Source boolean value in Copy of Assistant Sources
    AssistantSourcesCopy[selectedSourceIdx].checked = !assistantSources[
      selectedSourceIdx
    ]?.checked;

    setAssistantSources(AssistantSourcesCopy);
  };

  // Responsible for validating input and hitting the create assistant endpoint
  const handleUpdateAssistant = async () => {
    const { fail, errorMessage } = validateEditAssistantInputs(
      assistantInputInfo,
    );

    if (fail) {
      toast.error(errorMessage, {
        position: 'bottom-center',
        theme: 'dark',
      });
      return;
    }

    await mutateEditAssistant({
      assistant_name: assistantInputInfo?.name,
      assistant_id: assistantInputInfo?.id,
      description: assistantInputInfo?.description,
      instructions: assistantInputInfo?.instruction,
      tools: await getSelectedActiveTools(assistantTools),
      sources: await getSelectedActiveSources(assistantSources),
      avatar_props: assistantAvatarProps,
      file_ids: await getProcessedAndUploadedFileIds(processedAndUploadedFiles),
    });
  };

  useEffect(() => {
    const state = location?.state;
    if (state) {
      setAssistantInputInfo({
        description: state?.description,
        instruction: state?.instructions,
        name: state?.assistant_name,
        id: state?.assistant_id,
      });
      setAssistantAvatarProps(state?.avatar_props);
      setAssistantTools(state?.tools);
      setAssistantSources(state?.sources);
      state?.sources.map((source) =>
        handleSourceSelection({ name: source, checked: true }),
      );
      state?.tools.map((tool) => handleToolSelection({ name: tool }));
      const processedAndUploadedFiles = state?.files.map((file) => ({
        name: file?.file_name,
        fileId: file?.file_id,
        state: true,
      }));

      setProcessedAndUploadedFiles(processedAndUploadedFiles);
    }
  }, []);

  return (
    <div className={styles.update_assistant_parent_container}>
      <ScaffoldCard className={styles.update_assistant_wrapper}>
        <div className={styles.update_assistant_content}>
          <div className={styles.section_left}>
            <AvatarGeneratorPlaceholder avatarProp={assistantAvatarProps} />
            <div className={styles.input_container}>
              <label className={styles.input_label}>Name</label>
              <input
                name="name"
                className={styles.input_box}
                value={assistantInputInfo?.name}
                placeholder="Give your AI Assistant a friendly name"
                onChange={(event) => handleAssistantInputInfo(event)}
              />
            </div>
            <div className={styles.input_container}>
              <label className={styles.input_label}>Descrption</label>
              <textarea
                name="description"
                className={styles.input_box}
                value={assistantInputInfo?.description}
                placeholder="Provide a brief description of your AI Assistant"
                onChange={(event) => handleAssistantInputInfo(event)}
              />
            </div>
            <div className={styles.input_container}>
              <label className={styles.input_label}>Instruction</label>
              <textarea
                name="instruction"
                className={`${styles.input_box} ${styles.instruction_textarea}`}
                value={assistantInputInfo?.instruction}
                placeholder="Give detailed instructions to your AI Assistant"
                onChange={(event) => handleAssistantInputInfo(event)}
              />
            </div>
          </div>
          <div className={styles.section_right}>
            <div className={styles.input_container}>
              <label className={styles.input_label}>Tools</label>
              <Divider />
              {assistantTools?.map((tool) => (
                <label className={styles.checkbox_container}>
                  <input
                    className={styles.checkbox_input}
                    type="checkbox"
                    value={tool?.value}
                    checked={tool?.checked}
                    onChange={() => handleToolSelection(tool)}
                  />
                  {tool?.name}
                </label>
              ))}
            </div>

            <div className={styles.input_container}>
              <label className={styles.input_label}>Data Sources</label>
              <Divider />
              {assistantSources?.map((source) => (
                <label className={styles.checkbox_container}>
                  <input
                    className={styles.checkbox_input}
                    type="checkbox"
                    value={source?.value}
                    checked={source?.checked}
                    onChange={() => handleSourceSelection(source)}
                  />
                  {source?.name}
                </label>
              ))}
            </div>

            <div className={styles.input_container}>
              <label className={styles.input_label}>
                <span>Files</span>
                <div
                  className={styles.file_select_container}
                  htmlFor="inputFile"
                >
                  <input
                    id="inputFile"
                    className={styles.input_file}
                    type="file"
                    accept={ASSISTANTS_VALID_FILES_TYPE}
                    onChange={handleFileSelection}
                  />
                  <FilePinIcon primary width="16px" />{' '}
                  <span className={styles.add_text}>Add</span>
                </div>
              </label>
              <Divider />

              {selectedFiles?.map((file) => (
                <div className={styles.selectedfile_wrapper}>
                  <div className={styles.selectedfile_container}>
                    <FilePinIcon active width="16px" />
                    <span className={styles.selectedfile_text}>
                      {file?.name}
                    </span>
                  </div>
                  <CrossIcon
                    danger
                    marginLeft="0.4rem"
                    marginRight="0.4rem"
                    width="16px"
                    onClick={() => handleFilterFile(file?.name)}
                  />
                  {!file?.uploading && (
                    <button
                      className={styles.upload_file_button}
                      type="button"
                      onClick={() => handleUploadFile(file)}
                    >
                      Upload
                    </button>
                  )}
                  {file?.uploading && (
                    <LoaderTailSpin className={'h-margin-l-tiny'} width={18} />
                  )}
                </div>
              ))}

              {processedAndUploadedFiles?.map((file) => (
                <div className={styles.selectedfile_wrapper}>
                  <div className={styles.selectedfile_container}>
                    <FilePinIcon active width="16px" />
                    <span className={styles.selectedfile_text}>
                      {file?.name}
                    </span>
                  </div>
                  <UploadDoneIcon active marginLeft="8px" />
                  <CrossIcon
                    danger
                    marginLeft="0.4rem"
                    marginRight="0.4rem"
                    width="16px"
                    onClick={() => handleFilterProcessedFile(file?.name)}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={styles.update_action_container}>
          <PrimaryButton onClick={handleUpdateAssistant}>
            Update Assistant
            {isUpdatingAssistant && <LoaderTailSpin width={18} />}
          </PrimaryButton>
        </div>
      </ScaffoldCard>
      <ExistingFiles
        processedFiles={processedAndUploadedFiles}
        setProcessedFiles={setProcessedAndUploadedFiles}
      />
    </div>
  );
};

export default EditAssistant;
