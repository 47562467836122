import React, { useState } from 'react';
import Modal from 'react-modal';
import { InfoCircleOutlined } from '@ant-design/icons';
import {
  ButtonSecondary,
  Flex,
  ButtonPrimary,
} from 'Component/Shared/GlobalStyles';
import LoaderSpin from 'Component/Shared/Loader';
import { Tooltip, Input } from 'antd';
import HelperVideoModalPopup from 'Component/Shared/HelperVideoPopUp/HelperVideoPop';
import { useIsMobile } from 'Component/hooks/useIsMobile';
import { MANAGE_TEMPLATES_FILTER_OPTIONS } from 'components/constants';
import styles from '../chat-athena.module.css';
import { PromptCard } from './PromptCard';

const { Search } = Input;

const ADVANCED_PROMPTS_GOOGLE_SHEET_URL =
  'https://docs.google.com/spreadsheets/d/1edWP_pgeNgqsXNeKL9l3baKc4U1IfnqF/edit?usp=sharing&ouid=117329392553469258002&rtpof=true&sd=true';

const customStyles = {
  height: '700px',
  top: '50%',
  left: '50%',
  right: 'auto',
  bottom: 'auto',
  marginRight: '-50%',
  transform: 'translate(-50%, -50%)',
  width: '85vw',
  maxWidth: '1200px',
  maxHeight: '85vh',
};

function Tab({ tab, setTab, tabIndex, tabName, darkMode }) {
  return (
    <small
      style={{
        fontSize: '14px',
        color: darkMode || tab === tabIndex ? '#fff' : '#000',
      }}
      className={`btn btn-sm btn-outline-secondary ${
        tab === tabIndex ? 'active' : ''
      }`}
      onClick={() => setTab(tabIndex)}
    >
      {tabName}
    </small>
  );
}

export function PopUpModal({
  showModal,
  onRequestClose,
  setMessage,
  prompts,
  isLoading,
  tab,
  setTab,
  darkMode,
  setSelectedPrompt,
}) {
  const isMobile = useIsMobile();
  const [searchKey, setSearchKey] = useState('');

  const applyfilter = (prompt) => {
    if (prompt?.message?.toLowerCase().includes(searchKey.toLowerCase())) {
      return true;
    }
    if (prompt?.name?.toLowerCase().includes(searchKey.toLowerCase())) {
      return true;
    }
    return false;
  };
  return (
    <Modal
      isOpen={showModal}
      onRequestClose={onRequestClose}
      style={{
        content: {
          ...customStyles,
          backgroundColor: darkMode ? '#343641' : 'white',
        },
      }}
      ariaHideApp={false}
    >
      <div className={styles.modal_body}>
        <Flex direction={'column'} className={styles.modal_head}>
          <div>
            <Flex justify={'center'} align={'center'} gap={'1rem'}>
              <div
                className="text-center"
                style={{
                  color: darkMode ? 'white' : 'black',
                  fontSize: '30px',
                }}
              >
                CrawlQ Templates
              </div>
              {!isMobile && (
                <Flex
                  style={{
                    color: darkMode ? '#fff' : 'rgb(42 157 245)',
                    cursor: 'pointer',
                  }}
                >
                  <HelperVideoModalPopup
                    tooltipTitle={'ChatAthena Prompt Customization'}
                    videoLink="https://athena-helper-videos.s3.us-east-2.amazonaws.com/V-3+ChatAthena+ChatAthena+Customization.mp4"
                  />
                </Flex>
              )}
            </Flex>
            {!isMobile && (
              <div
                className="text-center"
                style={{
                  marginBottom: '15px',
                  marginLeft: '25px',
                  marginRight: '35px',
                  color: darkMode ? 'white' : 'black',
                  fontSize: '15px',
                }}
              >
                {
                  "ChatAthena HypnoLib is a ready-to-use collection of NLP-powered prompts, optimized to seamlessly work with Athena's Semantic Intelligence."
                }
                <Tooltip
                  placement="top"
                  title={`This powerful feature allows you to create deeply engaging and hypnotic conversations,
            effortlessly unlocking the emotional connection between your brand and customers. Say
            goodbye to generic interactions and elevate your marketing campaigns with the power of
            Hypnotic Conversational AI`}
                >
                  <InfoCircleOutlined
                    style={{ position: 'absolute', top: 10, right: 10 }}
                  />
                </Tooltip>
              </div>
            )}
          </div>

          <Flex justify={isMobile ? 'center' : 'flex-end'}>
            <Search
              style={{
                width: '250px',
                marginLeft: '10px',
              }}
              value={searchKey}
              placeholder="Search Templates"
              onChange={(e) => setSearchKey(e.target?.value)}
            />

            <div className="mr-2" />

            <ButtonPrimary className="mx-2 d-none d-md-block">
              <a
                className="text-white"
                target="_blank"
                href={ADVANCED_PROMPTS_GOOGLE_SHEET_URL}
                rel="noreferrer"
              >
                <small>Advanced Template Builder</small>
              </a>
            </ButtonPrimary>
            <ButtonSecondary
              style={{
                marginRight: '43px',
                height: '32px',
                fontSize: '15px',
                backgroundColor: darkMode ? 'grey' : 'white',
                color: darkMode ? '#fff' : '#2a95df',
                borderColor: darkMode ? 'grey' : '#2a95df',
              }}
              className="d-none d-md-block"
            >
              <a
                className="text-white"
                target="_blank"
                href="/manage-templates"
              >
                Create Templates
              </a>
            </ButtonSecondary>
          </Flex>
          <Flex justify={'center'} style={{ marginTop: '10px' }}>
            {!isMobile ? (
              <div
                className={`btn-group btn-group-toggle ${styles.filter_tab}`}
              >
                {MANAGE_TEMPLATES_FILTER_OPTIONS.map((TAB, tabIndex) => (
                  <Tab
                    tab={tab}
                    setTab={setTab}
                    tabIndex={tabIndex}
                    tabName={TAB}
                    darkMode={darkMode}
                  />
                ))}
              </div>
            ) : (
              <select
                className={styles.select_box}
                value={tab}
                onChange={(e) => setTab(e.target?.value)}
              >
                {MANAGE_TEMPLATES_FILTER_OPTIONS.map((TAB, tabIndex) => (
                  <option key={tabIndex} value={tabIndex}>
                    {TAB}
                  </option>
                ))}
              </select>
            )}
          </Flex>
        </Flex>
        <div className={styles.card_component}>
          {isLoading ? (
            <LoaderSpin />
          ) : (
            <div
              className={styles.card_container}
              style={{ marginTop: '35px' }}
            >
              {prompts?.filter(applyfilter)?.map((prompt) => (
                <PromptCard
                  darkMode={darkMode}
                  name={prompt.name}
                  prompt={prompt.message}
                  onUse={() => {
                    setMessage((prev) => prev + prompt.message);
                    setSelectedPrompt(prompt.message);
                    onRequestClose();
                  }}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}
