/* eslint-disable max-len */
import createInstance from 'Config/AxiosConfig';
import { useQuery } from 'react-query';

export const getTopicsAndParagraphs = async (user_term, user_country) => {
  const payload = {
    q: user_term,
    cc: user_country,
  };

  const instance = await createInstance();
  const { data, status } = await instance.post('get-ai-content-sites', payload);

  if (data.statusCode === '200' || data.data.length !== 0) {
    return data.data;
  }

  throw new Error('Unable to fetch Topics and Paragraphs related to Main Topic Of Interest');
};

// Keep Query Value as File Name to maintain consistency and uniqueness in query index
const QUERY_KEY = 'get-topics-and-paragraphs';

// Query Config Options
const queryOptions = {
  staleTime: 60000,

};

// QUERY
export const useGetTopicsAndParapgraphs = (user_term, user_country) =>
  useQuery([QUERY_KEY, user_term, user_country], () => getTopicsAndParagraphs(user_term, user_country), queryOptions);
