// https://apis.crawlq.ai/generate-and-publish-blogs
//

/* eslint-disable max-len */
import { baseAPIsUrl } from 'Config/Api';
import createInstance from 'Config/AxiosConfig';
import { useQuery } from 'react-query';
import { getReduxWorkspaceState } from 'utils/helpers/redux-selectors';

export const generateAndPublishBlogs = async (templates) => {
  const workspaceName = getReduxWorkspaceState()?.name;
  const reqBody = {
    name: workspaceName,
    templates,
  };

  try {
    const instance = await createInstance();
    const { data } = await instance.post(
      `${baseAPIsUrl}/generate-and-publish-blogs`,
      reqBody,
    );
    if (data.statusCode === '200') return true;
    return false;
  } catch (ex) {
    return true;
  }
};

// Keep Query Value as File Name to maintain consistency and uniqueness
const QUERY_KEY = 'generate-and-publish-blogs';

// Query Config Options
const queryOptions = {
  staleTime: 30000,
};

// QUERY
export const useGenerateAndPublishBlogs = () =>
  useQuery(QUERY_KEY, generateAndPublishBlogs, queryOptions);
