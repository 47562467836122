/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-const-assign */
/* eslint-disable prefer-const */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';

import './AiContentWriter.css';

// assets
import { FormControl, Select } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import BlogLogo from 'assets/images/blog-logo.svg';
import RightArrow from '../../../../assets/img/right-arrow.breadcrumb.svg';
import TimelineIcon from '@mui/icons-material/Timeline';
import notfoundimg from '../../../../assets/img/not_found.svg';

// components
import CustomTooltip, {
  CustomTooltipLight,
} from '../../../Shared/CustomTooltip';
import LoaderSpin, { LoaderTailSpin } from '../../../Shared/Loader';
import Topnav from '../../../Shared/Sidenav/Topnav';
import Topbar from '../../../Shared/Topbar/Topbar';
import ButtonNext, { Button, ButtonPrevious } from '../../../Shared/ButtonNext';
import InnerTopnav from '../Shared/InnerTopNav';
import RecentActivity from './RecentActivity';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import Flyout from 'Component/Shared/Flyout/Flyout';
import PublishedArticles from '../PublishedArticles/PublishedArticles';

// Logic Fn
import {
  getTranslatedText,
  getTranslatedTextEnglish,
} from '../../../Shared/TranshelperStatic';
import { getTranslatedTextArrayEnglish } from '../../MarketReasearch/MarketResearchAndResonance/MarketResearchCategory/fILLAiLangTrans';
import getAllSavedDocsBothSalesAndContent from '../Shared/sharedAPis';
import getSavedDocs, {
  CreateBlogTemplate,
  GetBlogTemplate,
  createNewDocument,
  fillWithExample,
  getLimitOfCreatingNewDocs,
  getListOfCountries,
} from './AIcontentLogic';
import { CreditManage } from 'Component/Shared/CreditManager';
import LockContentAutomation from '../Shared/LockContentAutomation';
import { BLOG_PATH } from '../PublishedArticles/components/constant';
import TabSwitcher from 'Component/Shared/TabSwitcher/TabSwitcher';
import getPrivateBlogs from './PrivateBlogTemplatesLogic';
import NotFound from 'Component/Pages/NotFound';
import { ButtonSecondary, HeadingDesc } from 'Component/Shared/GlobalStyles';

function AicontentWriter() {
  const athena_sources = useSelector(
    (state) => state.user.athena_sources?.train_option,
  );
  const SelectedWorkspaceDetail = useSelector((state) => state.workspace);
  const fullName = useSelector((state) => state?.user?.name);
  const GlobalUserDetail = useSelector((state) => state.user);
  const [savedDocs, setSavedDocs] = useState({ data: [], isLoading: false });

  const [countries, setCountries] = useState([]);
  const [inputValuesStep1, setInputValuesStep1] = useState({
    interest: '',
    idea: '',
    lang: 'US',
  });
  const [hasLimitReached, setHasLimitReached] = useState(false);
  const [hasLimitReachedLoaded, setHasLimitReachedLoaded] = useState(false);
  const [incompleteFieldsMessage, setIncompleteFieldsMessage] = useState('');
  const [creatingDocs, setCreatingDocs] = useState(false);
  const [inputValuesStep2, setInputValuesStep2] = useState([
    {
      input_question: 'What problem does this content solve for your readers?',
      question_example: 'E.g: helps in reducing fat, gives knowledge about seo',
      MRR_mapping: 'Fear',
      example_mapping: 'Mastering Traditional Sales Copywriting Skills',
      answer: '',
    },
  ]);
  const [step, setStep] = useState(0);
  const [IsCreditFail, setIsCreditFail] = useState(false);
  const [BlogTemplateLoading, setBlogTemplateLoading] = useState(true);
  const [BlogTemplate, setBlogTemplate] = useState();
  const [SelectedBlogTemplate, setSelectedBlogTemplate] = useState({
    id: '',
    name: '',
    credits: '',
  });
  const [IsPublishedArticlesOpen, setIsPublishedArticlesOpen] = useState(false);

  const [filterOptions, setFilterOptions] = useState([
    { key: 'mrr', value: 'Market Research', checked: true },
    { key: 'mrr_insight', value: 'Market Insights', checked: true },
    { key: 'script', value: 'Sales Copywriting', checked: true },
    { key: 'serp', value: 'Google', checked: true },
    { key: 'quora', value: 'Quora', checked: true },
    { key: 'reddit', value: 'Reddit', checked: true },
    { key: 'website', value: 'URL', checked: true },
  ]);
  const [activeTab, setActiveTab] = useState(0);
  const [privateTemplates, setPrivateTemplates] = useState([]);

  const navigate = useNavigate();
  const [
    allSavedDocsBothSalesAndContent,
    setAllSavedDocsBothSalesAndContent,
  ] = useState({
    data: [],
    hadLoaded: false,
  });

  const [isQuerySame, setIsQuerySame] = useState(false);

  const location = useLocation();

  function SourceFilterSelecter() {
    return (
      <div style={{ width: '100%' }}>
        <div className="question text-black d-flex">
          <span className="mx-1">Athena Sources</span>
          <CustomTooltip
            title="Athena will use the following sources on which it is trained to search and fill the answers."
            placement="top"
            arrow
          >
            <InfoOutlinedIcon color="grey" fontSize="small" />
          </CustomTooltip>
        </div>
        {athena_sources ? (
          <div
            className="onboarding-qna-source-filter"
            style={{ justifyContent: 'space-between' }}
          >
            {filterOptions.map(
              (option, index) =>
                athena_sources.includes(option.key) && (
                  <span
                    onClick={() => handleSourceFilterSelect(index)}
                    key={index}
                  >
                    {option.checked ? (
                      <CheckCircleOutlineRoundedIcon />
                    ) : (
                      <RadioButtonUncheckedRoundedIcon />
                    )}
                    {option.value}
                  </span>
                ),
            )}
          </div>
        ) : (
          <div className="text-center">
            <b className="text-warning">
              No sources selected as Athena is not trained
            </b>
          </div>
        )}
      </div>
    );
  }

  const handleSourceFilterSelect = (index) => {
    const filters = [...filterOptions];
    filters[index].checked = !filters[index].checked;
    setFilterOptions([...filterOptions]);
  };

  useEffect(() => {
    getSavedArticles();
    fetchBlogTemplate();
    getPrivateBlogs().then((data) => setPrivateTemplates([...data]));
    getListOfCountries().then((data) => {
      setCountries(data);
    });
    getAllSavedDocsBothSalesAndContent().then(({ error, docs }) => {
      if (!error) {
        docs = docs.map((doc) => {
          if (doc.blog) return doc.blog.trim();
          return doc.trim();
        });
        setAllSavedDocsBothSalesAndContent({ data: docs, hasLoaded: true });
      }
    });
    if (location.state) {
      setSelectedBlogTemplate({
        credits: 0,
        id: location.state.template_id,
        name: location.state.template_name,
      });
      setStep(1);
      setActiveTab(1);
    } else {
      navigate('/chat-athena');
    }
  }, [location]);

  const getSavedArticles = async () => {
    setSavedDocs({ ...savedDocs, isLoading: true });
    const { error, docs } = await getSavedDocs();

    if (!error) {
      setSavedDocs({ ...savedDocs, data: docs, isLoading: false });
      if (docs.length < getLimitOfCreatingNewDocs()) {
        setHasLimitReachedLoaded(true);
      }
      return;
    }
    setSavedDocs({ ...savedDocs, data: [], isLoading: false });
  };

  const fetchBlogTemplate = async () => {
    let { success, data } = await GetBlogTemplate();

    if (success) {
      // Moving General Blog to First Index
      const general_blog = data.filter(
        (blog_template) => blog_template.id === 'general_blog',
      );
      data = data.filter(
        (blog_template) => blog_template.id !== 'general_blog',
      );
      data = [...general_blog, ...data];

      setBlogTemplate(data);
      setBlogTemplateLoading(false);
    }
  };

  const handleChangeStep1 = (name) => (e) => {
    setIncompleteFieldsMessage('');
    setIsQuerySame(false);
    setInputValuesStep1({ ...inputValuesStep1, [name]: e.target.value });
  };

  const handleChangeStep2 = (index) => (e) => {
    setIncompleteFieldsMessage('');
    const oldInputValues = [...inputValuesStep2];
    oldInputValues[index].answer = e.target.value;
    setInputValuesStep2(oldInputValues);
  };

  const handleFillWithexample = () => {
    const newQuesAns = fillWithExample(inputValuesStep2);
    setInputValuesStep2([...newQuesAns]);
  };

  const handleCreateNewDoc = async () => {
    if (
      inputValuesStep1.interest.trim() === '' ||
      inputValuesStep1.idea.trim() === '' ||
      inputValuesStep2[0].answer.trim() === ''
    ) {
      setIncompleteFieldsMessage('*All fields are compulsory');
      return;
    }
    if (!allSavedDocsBothSalesAndContent.hasLoaded) {
      setIsQuerySame(true);
      return;
    }
    if (allSavedDocsBothSalesAndContent.data.includes(inputValuesStep1.idea)) {
      setIsQuerySame(true);
      return;
    }
    setCreatingDocs(true);
    localStorage.setItem('questionTopic', inputValuesStep1.interest);
    localStorage.setItem('context', inputValuesStep1.interest);
    localStorage.setItem('initialHeading', inputValuesStep1.idea);
    localStorage.setItem('save-doc-name-sales', inputValuesStep1.idea);

    console.log(SelectedBlogTemplate.credits);

    let creditToCheck = SelectedBlogTemplate.credits;
    if (!creditToCheck) creditToCheck = 0;
    const { success } = await CreditManage(creditToCheck, true);

    if (success) {
      try {
        if (SelectedBlogTemplate.name === 'General Blog Outline') {
          const translatedData = await getTranslatedTextArrayEnglish([
            inputValuesStep2[0].answer,
            inputValuesStep1.idea,
          ]);
          console.log(athena_sources);
          const data = {
            query: translatedData[1],
            data: inputValuesStep2.map(({ input_question, answer }) => ({
              question: input_question,
              answer: translatedData[0],
            })),
            sources: filterOptions
              .filter(
                (source) =>
                  source.checked && athena_sources.includes(source.key),
              )
              .map((source) => source.key),
          };

          createNewDocument(data).then(async ({ error, data }) => {
            if (error) return;

            setCreatingDocs(false);

            const { success } = await CreditManage(
              SelectedBlogTemplate.credits,
            );

            if (success) {
              const outlineResult = await getTranslatedText(data);
              navigate('/ai-content-writer/editor', {
                state: {
                  GoogleQuery: inputValuesStep1.interest,
                  query: inputValuesStep1.idea,
                  generatedOutputs: outlineResult,
                  flag: 'ai-writer',
                  lang: inputValuesStep1.lang,
                  save: false,
                },
              });
            } else {
              setIsCreditFail(true);
            }
          });
        } else {
          console.log('private');
          let reqBody = {
            name: SelectedWorkspaceDetail.name,
            context: await getTranslatedTextEnglish(inputValuesStep1.idea),
            blog_template_id: SelectedBlogTemplate.id,
            sources: filterOptions
              .filter(
                (source) =>
                  source.checked && athena_sources.includes(source.key),
              )
              .map((source) => source.key),
          };

          if (activeTab === 1) {
            reqBody = { ...reqBody, template_type: 'private' };
          }

          let { success, data } = await CreateBlogTemplate(reqBody);

          let creditToCheck = SelectedBlogTemplate.credits;
          if (!creditToCheck) creditToCheck = 0;

          if (success) {
            console.log('success private');
            const { success } = await CreditManage(creditToCheck);

            const convertedBlog = await getTranslatedText(data);

            navigate('/ai-content-writer/editor', {
              state: {
                GoogleQuery: inputValuesStep1.interest,
                query: inputValuesStep1.idea,
                generatedOutputs: convertedBlog,
                flag: 'ai-writer',
                lang: inputValuesStep1.lang,
                save: false,
              },
            });
          } else {
            console.log('fail private');

            setCreatingDocs(false);
          }
        }
      } catch (err) {
        setCreatingDocs(false);
        console.log('Could not generate blog', err);
      }
    } else {
      setCreatingDocs(false);

      setIsCreditFail(true);
    }
  };

  function AuthorityBlogTools() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <InnerStepNav style={{ flex: 2 }} />
        <div className="aw-main-authority-blog">
          <div className="aw-auth-input">
            <div className="aw-input-label">
              <div> Main Topic Of Interest</div>
              <div className="tool-tip">
                <CustomTooltip
                  arrow
                  placement="top"
                  title="Write the main topic of your content here. It is used to fetch relevant SERP data. Eg: “3D printing”"
                >
                  <InfoOutlinedIcon />
                </CustomTooltip>
              </div>
            </div>
            <input
              onChange={handleChangeStep1('interest')}
              value={inputValuesStep1.interest}
            />
          </div>
          <div className="aw-auth-input">
            <div className="aw-input-label">
              <div>Initial Content Idea </div>
              <div className="tool-tip">
                <CustomTooltip
                  arrow
                  placement="top"
                  title="Write the title of your content/article. It is used to create outline. Eg: “The best software to use for your next 3D printing project”"
                >
                  <InfoOutlinedIcon />
                </CustomTooltip>
              </div>
            </div>

            <input
              onChange={handleChangeStep1('idea')}
              value={inputValuesStep1.idea}
            />
          </div>
          {isQuerySame && (
            <p
              className="text-right font-accent text-danger"
              style={{ fontSize: '0.8rem' }}
            >
              *Initial Content Idea Already Exists
            </p>
          )}
          <div className="aw-auth-input">
            <div className="aw-input-label">
              <div>{inputValuesStep2[0].input_question}</div>
              <div className="tool-tip">
                <CustomTooltip
                  placement="top"
                  title="Write the problem-solution that your content focusses on. Eg: “Helps them to choose the best and trending 3D printing software”"
                  arrow
                >
                  <InfoOutlinedIcon />
                </CustomTooltip>
              </div>
            </div>
            <textarea
              onChange={handleChangeStep2(0)}
              value={inputValuesStep2[0].answer}
              style={{
                height: '88px',
              }}
            />
          </div>
          <div className="aw-auth-input">
            <div className="aw-input-label">
              <div>Select Country</div>
            </div>
            <FormControl variant="outlined" style={{ width: '60%' }}>
              <Select
                native
                onChange={handleChangeStep1('lang')}
                value={inputValuesStep1.lang}
              >
                {countries.map((country, index) => (
                  <option key={index} value={country.alpha2Code}>
                    {country.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </div>
          <div
            className="aw-auth-input"
            style={{ justifyContent: 'space-around' }}
          >
            {activeTab === 0 && <SourceFilterSelecter />}
          </div>
          {incompleteFieldsMessage !== '' && (
            <p className="text-center text-danger">{incompleteFieldsMessage}</p>
          )}
          {!creatingDocs ? (
            <>
              {IsCreditFail ? (
                <button
                  className="float-right "
                  style={{
                    backgroundColor: 'rgb(255, 200, 200)',
                    color: 'red',
                    padding: '0.5rem 1rem',
                    borderRadius: '0.3rem',
                  }}
                >
                  Credits Over
                </button>
              ) : hasLimitReached ? (
                <span className="float-right btn btn-secondary">
                  Create Outline
                </span>
              ) : (
                <ButtonNext
                  className="float-right"
                  onClick={handleCreateNewDoc}
                  buttonName="Create Draft"
                />
              )}
            </>
          ) : (
            <div className="float-right">
              <LoaderSpin />
            </div>
          )}
        </div>
      </div>
    );
  }

  function InnerStepNav() {
    return (
      <div
        className="aw-main-phase2-nav-controller"
        style={
          step == 0
            ? { justifyContent: 'space-between' }
            : { justifyContent: 'space-between' }
        }
      >
        {step == 0 && (
          <div
            className="inner-step-nav-header"
            style={{ justifyContent: 'end' }}
          >
            Select Template
          </div>
        )}

        {step === 0 ? null : <ButtonPrevious onClick={() => navigate(-1)} />}

        {step == 1 && <div className="inner-step-nav-header">Create Draft</div>}

        {step === 1 ? null : SelectedBlogTemplate.name ? (
          <ButtonNext onClick={() => setStep(1)} buttonName="Next" />
        ) : (
          <button className="not-selected-button">
            <span>Next</span>
            <img
              src="/img/circular-arrow-right.svg"
              className="img-fluid ml-2"
              width="18"
            />
          </button>
        )}
      </div>
    );
  }

  const handleSelectBlogTemplate = (id, name, cost) => {
    if (SelectedBlogTemplate.name === name) {
      setSelectedBlogTemplate({
        ...SelectedBlogTemplate,
        id: '',
        name: '',
        credits: '',
      });
    } else {
      console.log(cost);
      setSelectedBlogTemplate({
        ...SelectedBlogTemplate,
        id,
        name,
        credits: cost,
      });
    }
  };

  function BlogTemplateCard(template) {
    return (
      <div
        initial={{ opacity: 0, y: '-2vh' }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
          duration: 0.2,
          ease: 'easeIn',
          type: 'spring',
          stiffness: 100,
        }}
        className={
          SelectedBlogTemplate.name === template.blog_name
            ? 'aw-blog-cards aw-blog-card-selected'
            : 'aw-blog-cards'
        }
        onClick={() =>
          handleSelectBlogTemplate(
            template.id,
            template.blog_name,
            template.credits,
          )
        }
      >
        <img src={BlogLogo} alt="logo" />
        <span>{template.blog_name}</span>
        <div className="tool-tip">
          <CustomTooltipLight
            placement="top"
            title={template.info_description}
            arrow
          >
            <InfoOutlinedIcon className="aw-info-icon" />
          </CustomTooltipLight>
        </div>
      </div>
    );
  }

  function PrivateBlogTemplateCard(template) {
    return (
      <div
        initial={{ opacity: 0, y: '-2vh' }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
          duration: 0.2,
          ease: 'easeIn',
          type: 'spring',
          stiffness: 100,
        }}
        className={
          SelectedBlogTemplate.name === template.template_name
            ? 'aw-blog-cards aw-blog-card-selected'
            : 'aw-blog-cards'
        }
        onClick={() =>
          handleSelectBlogTemplate(
            template.template_id,
            template.template_name,
            template.credits,
          )
        }
      >
        <img src={BlogLogo} alt="logo" />
        <span>{template.template_name}</span>
        <div className="tool-tip">
          <CustomTooltipLight
            placement="top"
            title={template.template_description}
            arrow
          >
            <InfoOutlinedIcon className="aw-info-icon" />
          </CustomTooltipLight>
        </div>
      </div>
    );
  }

  function PublicBlogTemplates() {
    return (
      <div>
        {/* Rendering General Blog First  */}
        <div className="aw-blog-cards-container">
          {BlogTemplate && BlogTemplateCard(BlogTemplate[0])}
          <div style={{ width: '18rem' }}>
            <ButtonSecondary onClick={() => navigate('/chat-athena/')}>
              Create Draft
            </ButtonSecondary>
          </div>
        </div>
        <hr />
        <div className="aw-blog-cards-container">
          <>
            {/* Rendering Rest of the blogs */}
            {BlogTemplate &&
              BlogTemplate.map((template, index) => (
                <>{!!index && BlogTemplateCard(template)}</>
              ))}
          </>
        </div>
      </div>
    );
  }

  function PrivateBlogTeamplates() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div className="d-flex justify-content-between mt-3">
          <ButtonSecondary
            onClick={() => {
              navigate('/Manage-Templates');
            }}
          >
            Manage Templates
          </ButtonSecondary>
          <Button
            name="Create New Template"
            onClick={() => {
              navigate('/ai-template-builder');
            }}
          />
        </div>
        {privateTemplates.length === 0 ? (
          <div className="not-found">
            <img src={notfoundimg} width={200} />
            <b style={{ fontSize: '2rem', marginTop: '1.5rem' }}>
              You haven't Created any Template Yet.
            </b>
          </div>
        ) : (
          <div>
            <div className="aw-blog-cards-container">
              {privateTemplates.map((template) =>
                PrivateBlogTemplateCard(template),
              )}
            </div>
          </div>
        )}
      </div>
    );
  }

  function BlogToolTemplateStep() {
    if (!BlogTemplateLoading) {
      return (
        <div className="aw-phase2-container">
          <InnerStepNav />
          <div className="aw-question-answer-container">
            <TabSwitcher
              tab1="General"
              tab2="Private"
              setSwitcher={setActiveTab}
              style={{ width: '15rem' }}
            />
            {activeTab === 0 && <PublicBlogTemplates />}
            {activeTab === 1 && <PrivateBlogTeamplates />}
          </div>
        </div>
      );
    }
    return <LoaderTailSpin />;
  }

  const openDigitalAssetsBlogPostsPage = () => {
    navigate('/digital-asset-management-blogposts');
  };

  return (
    <>
      <Flyout
        open={IsPublishedArticlesOpen}
        onClose={() => setIsPublishedArticlesOpen(false)}
        title="Digital Asset Management (DAM)"
      >
        <HeadingDesc style={{ fontWeight: '500', marginLeft: '10px' }}>
          Hey {fullName}, Now You Can Plan, Organize, and Track Your Content
          Initiatives Using Editorial Calendar.
        </HeadingDesc>
        <PublishedArticles from={BLOG_PATH.NON_SALES} />
      </Flyout>
      <Topbar />
      <Topnav />
      <LockContentAutomation />
      <div className="aw-container">
        <div className="aw-nav-container">
          <InnerTopnav />
        </div>
        <div className="aw-breadcrumb">
          <span className="font-accent txt-primary" onClick={() => setStep(0)}>
            AI Content Writer
          </span>
          <img className="mx-2" width="15" src={RightArrow} />
          {SelectedBlogTemplate.name ? (
            <span
              className="font-accent txt-primary"
              onClick={() => setStep(0)}
            >
              {SelectedBlogTemplate.name}
            </span>
          ) : (
            <span
              className="font-accent txt-primary"
              onClick={() => setStep(0)}
            >
              Select Template
            </span>
          )}
          {step === 1 && <img className="mx-2" width="15" src={RightArrow} />}
          {step === 1 ? (
            <span className="font-accent txt-primary">Generate Draft</span>
          ) : null}

          <hr />
        </div>
        <div className="aw-main-container">
          <div className="aw-new-docs">
            {step === 0 && BlogToolTemplateStep()}
            {step === 1 && AuthorityBlogTools()}
          </div>
          <div className="aw-activity-container">
            <div className="aw-show-activity">
              <button onClick={openDigitalAssetsBlogPostsPage}>
                <TimelineIcon /> {'  '}
                Articles
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AicontentWriter;
