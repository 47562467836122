/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable consistent-return */
/* eslint-disable dot-notation */
/* eslint-disable semi */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable import/order */
/* eslint-disable quotes */
/* eslint-disable quote-props */
/* eslint-disable max-len */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable no-plusplus */
/* eslint-disable react/button-has-type */
/* eslint-disable no-return-assign */
/* eslint-disable no-use-before-define */

import {
  Button,
  Col,
  Form,
  Row,
  Input,
  Select,
  Switch,
  Steps,
  AutoComplete,
  notification,
} from 'antd';
import React, { useEffect, useState } from 'react';
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  GoldOutlined,
  LeftOutlined,
  LinkedinOutlined,
  RightOutlined,
} from '@ant-design/icons';
import ReactFlagsSelect from 'react-flags-select';
import FlowSteps from './FlowSteps';
import AthenaLogo from 'assets/images/crawlQ-transparent-favicon.png';
import './style.css';
import LanguageMobileSelector from '../sharedMobile/LanguageMobileSelectors';
import NavigationSelectors from '../sharedMobile/NavigationSelectors';

const { Option } = Select;

const SecondScreen = (props) => {
  const {
    index,
    changeIndex,
    growZoneOptions,
    brandsNameOptions,
    keyInterestOptions,
    networkingGoalOptions,
    setIndex,
  } = props;
  const { data, setData } = props;
  const [form] = Form.useForm();
  const [selected, setSelected] = useState('');
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    // Set the initial value for the select field
    form.setFieldsValue({
      growthSelect: data?.selectedGrowthOption,
      brandSelect: data?.selectedBrandOption,
      keyInteresetSelect: data?.selectedKeyInteresetOption,
      networkingSelect: data?.selectedNetworkingOption,
      productName: data?.productName,
    });
  }, []);

  const openNotificationWithIcon = (description) => {
    api['warning']({
      message: 'Missing field',
      description,
    });
  };

  const goRight = () => {
    if (!data.selectedGrowthOption) {
      openNotificationWithIcon('Campaign Name is not filled');
      return false;
    }
    if (!data.selectedBrandOption) {
      openNotificationWithIcon('Company Name is not filled');
      return false;
    }
    changeIndex('RIGHT');
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const handleToggleCustom = (flag, option) => {
    if (flag in data) {
      setData({
        ...data,
        [flag]: !data[flag],
        [option]: '',
      });
    } else {
      setData({
        ...data,
        [flag]: true,
        [option]: '',
      });
    }
  };

  return (
    <>
      {contextHolder}{' '}
      <div style={{ background: 'white' }}>
        <div
          style={{
            height: '10vh',
            // backgroundColor: 'green',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 'bold',
            fontSize: '25px',
          }}
        >
          <LanguageMobileSelector data={data} setData={setData} />
        </div>
        <div
          style={{
            height: '70vh',
            display: 'flex',
            justifyContent: 'center',
            // alignItems: 'center',
          }}
        >
          <div
            style={{
              height: '70vh',
              display: 'flex',
              justifyContent: 'center',
              // alignItems: 'center',
            }}
          >
            <Form
              {...layout}
              form={form}
              name="control-hooks"
              // onFinish={onFinish}
              style={{ width: '85vw', color: 'white' }}
            >
              <Form.Item name="logo">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img
                    src={AthenaLogo}
                    alt="Athena Logo"
                    style={{
                      display: 'inline-block',
                      width: '45px',
                      // marginLeft: '10%',
                      marginBottom: '10px',
                    }}
                  />
                </div>
              </Form.Item>
              <Form.Item
                name="growthSelect"
                label={
                  <div style={{ color: '#5887FB', fontWeight: 'bold' }}>
                    Campaign Name
                  </div>
                }
                rules={[
                  { required: true, message: 'Campaign Name is required' },
                ]}
              >
                <Input
                  placeholder="3D Printing"
                  value={data.selectedGrowthOption}
                  onChange={(selectedOption) => {
                    setData({
                      ...data,
                      selectedGrowthOption: selectedOption.target.value,
                    });
                  }}
                />
              </Form.Item>

              <Form.Item
                name="brandSelect"
                label={
                  <div style={{ color: '#5887FB', fontWeight: 'bold' }}>
                    Company Name
                  </div>
                }
                rules={[
                  { required: true, message: 'Company Name is required' },
                ]}
              >
                <Input
                  placeholder="Yeggi"
                  value={data.selectedBrandOption}
                  onChange={(selectedOption) => {
                    setData({
                      ...data,
                      selectedBrandOption: selectedOption.target.value,
                    });
                  }}
                />
              </Form.Item>
            </Form>
          </div>
        </div>
        <div
          style={{
            height: '10vh',
            // backgroundColor: 'green',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            // type="primary"
            style={{
              color: 'white',
              width: '200px',
              borderRadius: '10px',
              fontSize: '15px',
              background: '#4B79FF',
              // boxShadow: '10px 5px 5px black',
            }}
            onClick={() => goRight()}
          >
            Go Next
          </Button>
        </div>
        <NavigationSelectors changeIndex={changeIndex} goRight={goRight} />
        {/* <FlowSteps currentStep={0} setIndex={setIndex} /> */}
      </div>
    </>
  );
};

export default SecondScreen;
