import { Divider, Flex } from 'Component/Shared/GlobalStyles';
import { Colors } from 'libs/colors';
import styled from 'styled-components';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';

export const ProgressWrapper = styled(Flex)`
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  flex-direction: column;
  padding: 1rem;
  gap: 0.8rem;
`;

export const Heading = styled.h2`
  color: ${Colors.blue};
  font-weight: 600;
  font-size: 1.2rem;
  border-bottom: 0.05rem solid ${Colors.grey};
  width: 100%;
  padding-bottom: 0.2rem;
  text-transform: capitalize;
`;

export const GreenTick = styled(TaskAltRoundedIcon)`
  color: #1d8102;
  width: 3rem;
  height: 3rem;
`;

export const RedCross = styled(CancelTwoToneIcon)`
  color: red;
  width: 3rem;
  height: 3rem;
`;

export const LoadingIcon = styled(PendingOutlinedIcon)`
  color: ${Colors.blue};
  width: 3rem;
  height: 3rem;
`;

export const UnreachedIcon = styled(PendingOutlinedIcon)`
  color: ${Colors.darkGrey};
  width: 3rem;
  height: 3rem;
`;

export const DividerBold = styled(Divider)`
  height: 0.15rem;
  background-color: ${(props) =>
    (props.isCompleted ? Colors.green : Colors.darkGrey)};
  margin-top: 29px;
  flex: 0.5;
`;

export const ProgressBox = styled(Flex)`
  height: 100px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1.2;
  &b {
    font-weight: 600 !important;
  }
`;

export const DetailedDisplayComponent = styled(Flex)`
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  flex-direction: column;
  padding: 1rem;
  gap: 0.8rem;
  max-height: 60vh;
  overflow: scroll;
`;

export const DateContainer = styled(Flex)`
  font-size: 14px;
  gap: 0.5rem;
  font-weight: bold;
  color: #6a6a6a;
  .label {
    color: #000;
  }
`;

export const SectionHeading = styled.h3`
  font-size: 1.2rem;
  font-weight: bolder;
  margin-bottom: 0;
`;

export const SectionDataHeading = styled.h3`
  font-size: 0.9rem;
  font-weight: bold;
  margin-bottom: 0;
`;

export const SectionData = styled.span`
  font-size: 0.9rem;
  font-weight: 600;
  color: #6a6a6a;
  border: 2.5px solid #6a6a6a;
  border-radius: 5px;
`;

export const SectionsWrapper = styled(Flex)`
  justify-content: space-between;
  gap: 1.5rem;
  flex-direction: row;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;
