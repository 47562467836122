import {
  Form,
  Input,
  Select,
  Button,
  Col,
  Row,
  Slider,
  InputNumber,
  Image,
  Spin,
  Tooltip,
  Space,
  Transfer,
  Cascader,
  Tag,
} from 'antd';
import TabSwitcher from 'Component/Shared/TabSwitcher/TabSwitcher';
import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import createInstance from 'Config/AxiosConfig.js';
import { store } from 'redux/store';
import { v4 as uuidv4 } from 'uuid';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './textImage.css';
import {
  InfoCircleOutlined,
  LeftCircleOutlined,
  LoadingOutlined,
  RightCircleOutlined,
} from '@ant-design/icons';
import TabSwitcherAlpha from 'Component/Shared/TabSwitcher/TabSwitcherAlpha';
import ReactInstaStories from 'react-insta-stories';

export function TextImageComponent(props) {
  const [activeTab, setActiveTab] = useState(0);
  const [selectedFeatures, setSelectedFeatures] = useState({});
  const [features, setFeatures] = useState({});
  const [mrrprompt, setMrrprompt] = useState('');
  const [extraMrrprompt, setExtraMrrprompt] = useState('');

  const [numOutputs, setNumOutputs] = useState(1);
  const [numInferenceSteps, setNumInferenceSteps] = useState(50);
  const [guidanceScale, setGuidanceScale] = useState(7.5);
  const [loading, setLoading] = useState(false);
  const [loadingImages, setLoadingImages] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const { TextArea } = Input;

  const [widths, setWidths] = useState([
    { label: 128, value: 128 },
    { label: 256, value: 256 },
    { label: 384, value: 384 },
    { label: 448, value: 448 },
    { label: 512, value: 512 },
    { label: 576, value: 576 },
    { label: 640, value: 640 },
    { label: 704, value: 704 },
    { label: 768, value: 768 },
    { label: 832, value: 832 },
    { label: 896, value: 896 },
    { label: 960, value: 960 },
    { label: 1024, value: 1024 },
  ]);

  const [heights, setHeights] = useState([
    { label: 128, value: 128 },
    { label: 256, value: 256 },
    { label: 384, value: 384 },
    { label: 448, value: 448 },
    { label: 512, value: 512 },
    { label: 576, value: 576 },
    { label: 640, value: 640 },
    { label: 704, value: 704 },
    { label: 768, value: 768 },
    { label: 832, value: 832 },
    { label: 896, value: 896 },
    { label: 960, value: 960 },
    { label: 1024, value: 1024 },
  ]);

  const [selectedHeight, setSelectedHeight] = useState(512);
  const [selectedWidth, setSelectedWidth] = useState(512);
  const [uuid, setUuid] = useState('');

  const getStartingEntities = () =>
    (features.mrr_entities || []).map((entity, i) => ({
      key: i.toString(),
      title: entity,
    }));

  const initialTargetKeys = [];
  const [targetKeys, setTargetKeys] = useState(initialTargetKeys);
  const [selectedKeys, setSelectedKeys] = useState([]);

  const [images, setImages] = useState([
    'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
  ]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const state = store.getState();
      const active_workspace = state.workspace;
      const username = localStorage.getItem('username');

      const instance = await createInstance();
      const { data } = await instance.get(
        `get-creative-brief-data?name=${active_workspace.name}&username=${username}`,
      );
      setMrrprompt('');

      const _features = data.data;
      setFeatures(_features);
      // set the options..

      const _options = Object.keys(_features)
        .filter((key) => key !== 'mrr_entities')
        .map((key) => ({
          label: key,
          value: key,
          children: _features[key].map((feature) => ({
            label: feature,
            value: feature,
          })),
        }));
      setOptions(_options);

      setLoading(false);
    })();
  }, []);

  const onChange = (nextTargetKeys, direction, moveKeys) => {
    const _array = (nextTargetKeys || [])
      .map((target) => (features.mrr_entities || [])[parseInt(target, 10)])
      .join(', ');
    setMrrprompt(_array);
    setTargetKeys(nextTargetKeys);
  };

  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const getChunk = (arr, size) =>
    arr.reduce((acc, _, i) => (i % size ? acc : [...acc, arr.slice(i, i + size)]), []);

  const valueMapper = (toMap, key) =>
    (toMap[key] || []).map((element) => ({
      value: element,
    }));

  const getPromptString1 = () =>
    `${mrrprompt}, ${Object.keys(selectedFeatures)
      .filter((key) => selectedFeatures[key].length > 0)
      .map((key) => selectedFeatures[key].join(', '))
      .join(', ')}`;

  const getPromptString = () => {
    const str = getPromptString1();
    if (str.charAt(0) === ',') {
      return str.slice(1);
    }
    return str;
  };

  const sendImageInfo = async () => {
    try {
      const state = store.getState();
      const active_workspace = state.workspace;
      const _id = uuidv4();
      setUuid(_id);
      const info = {
        name: active_workspace.name,
        request_id: _id,
        prompt: `${getPromptString()}, ${extraMrrprompt}`,
        guidance_scale: guidanceScale,
        num_inference_steps: numInferenceSteps,
        results: numOutputs,
        width: selectedWidth,
        height: selectedHeight,
      };
      setLoadingImages(true);
      const instance = await createInstance();
      const { data } = await instance.post('generate-image', info);

      const username = localStorage.getItem('username');
      const response = await instance.get(
        `/get-request-data?include_data=${true}&request_id=${_id}&username=${username}`,
      );

      const { files } = response.data.data;
      setImages(files.map((file) => file.data));
      setLoadingImages(false);
    } catch (e) {
      console.log(e);
    }
  };

  const isColor = (strColor) => {
    const s = new Option().style;
    s.color = strColor;
    const test1 = s.color === strColor;
    const test2 = /^#[0-9A-F]{6}$/i.test(strColor);
    if (test1 === true || test2 === true) {
      return true;
    }
    return false;
  };

  const onChangeCascader = (value) => {
    // we need to do the mapping between the value and the selected Features
    let _features = {};
    value.forEach((feature) => {
      // if the size is one, then we need to selected all the entities of this feature.
      if (feature.length === 1) {
        _features = { ..._features, [feature[0]]: features[feature[0]] };
      } else if (feature.length > 1) {
        if (feature[0] in _features) {
          _features = { ..._features, [feature[0]]: [..._features[feature[0]], feature[1]] };
        } else {
          _features = { ..._features, [feature[0]]: [feature[1]] };
        }
      }
    });
    setSelectedFeatures(_features);
    // we need to flat the list with one element to be a set of lists of all the features..

    setSelectedOptions(value);
  };

  const deleteTag = (feature, tag) => {
    setLoading(true);
    // delete it from the selectedFeatures..
    const _selectedFeature = selectedFeatures[feature].filter((_f) => _f !== tag);
    const _selectedFeatures = { ...selectedFeatures, [feature]: _selectedFeature };
    setSelectedFeatures(_selectedFeatures);

    const _selectedOptions = selectedOptions
      .reduce((acc, curr) => {
        if (curr.length === 1) {
          const _temp = _selectedFeatures[curr[0]].map((feature) => [curr[0], feature]);
          return [...acc, ..._temp];
        }
        return [...acc, curr];
      }, [])
      .filter((option) => `${option[0]}-${option[1]}` !== `${feature}-${tag}`);

    setSelectedOptions(_selectedOptions);
    // and from the selected options

    setLoading(false);
  };

  const resetForm = () => {
    setSelectedFeatures({});
    setMrrprompt('');
    setSelectedOptions([]);
    setExtraMrrprompt('');
    setSelectedKeys([]);
    setTargetKeys([]);
    setActiveTab(0);
  };

  const forceUpdate = React.useCallback(() => resetForm(), []);

  const renderPrompt = () => (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      style={{
        marginTop: '30px',
        float: 'left',
        width: '100%',
        height: '100vh',
        paddingLeft: '30px',
        backgroundColor: '#ebf1f7',
        borderRadius: '15px',
        paddingTop: '15px',
      }}
      labelAlign="left"
    >
      <Form.Item
        label={<strong>Market Research Concepts</strong>}
        name="Base prompt"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        labelAlign="left"
      >
        <Transfer
          listStyle={{
            width: '44%',
            height: 200,
          }}
          dataSource={getStartingEntities()}
          titles={['Source', 'Target']}
          targetKeys={targetKeys}
          selectedKeys={selectedKeys}
          onChange={onChange}
          onSelectChange={onSelectChange}
          render={(item) => item.title}
        />
      </Form.Item>

      <Form.Item
        label={<strong>Entities</strong>}
        name="Base prompt"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        labelAlign="left"
      >
        <Cascader
          value={selectedOptions}
          defaultValue={selectedOptions}
          style={{ width: '92%' }}
          options={options}
          multiple
          maxTagCount="responsive"
          onChange={onChangeCascader}
          dropdownMenuColumnStyle={{ width: '400px', marginBottom: '5px' }}
          size="large"
        />
        {Object.keys(selectedFeatures)
          .filter((feature) => selectedFeatures[feature].length > 0)
          .map((feature) => (
            <>
              <strong>
                <div style={{ marginTop: '10px', marginBottom: '5px' }}>{feature} : </div>
              </strong>
              <div>
                {selectedFeatures[feature].map((tag) => (
                  <Tag
                    key={`${tag}-${feature}`}
                    closable
                    onClose={() => {
                      deleteTag(feature, tag);
                    }}
                    style={{ marginBottom: '5px', marginTop: '5px' }}
                  >
                    {tag}
                  </Tag>
                ))}
              </div>
            </>
          ))}
      </Form.Item>

      {/* <Row justify="start" vertical-fill>
        {getChunk(
          Object.keys(features).filter((key) => key !== 'mrr_entities'),
          2,
        ).map((keys) => (
          <>
            {keys.map((key) => (
              <Col span={12}>
                <Form.Item label={key} name={key}>
                  <Select
                    size="small"
                    mode="multiple"
                    showArrow
                    style={{ width: '80%', resize: 'both', maxWidth: 'none' }}
                    defaultValue={valueMapper(selectedFeatures, key)}
                    onChange={(_ft) => {
                      setSelectedFeatures({ ...selectedFeatures, [key]: _ft });
                    }}
                    // options={valueMapper(features, key)}
                  >
                    {features[key].map((feature) => {
                      if (isColor(feature)) {
                        return (
                          <Select.Option value={feature}>
                            <Tag color={feature}>{feature}</Tag>
                          </Select.Option>
                        );
                      }
                      return <Select.Option value={feature}>{feature}</Select.Option>;
                    })}
                  </Select>
                </Form.Item>
              </Col>
            ))}
          </>
        ))}
      </Row> */}
    </Form>
  );

  const switchImage = (direction) => {
    const index = selectedImageIndex + direction;
    if (index === -1) {
      setSelectedImageIndex(images.length - 1);
    } else if (index === images.length) {
      setSelectedImageIndex(0);
    } else {
      setSelectedImageIndex(index);
    }
  };

  const renderPreview = () => (
    <Row style={{ marginTop: '30px' }} justify="space-around">
      <Col
        span={12}
        style={{
          backgroundColor: '#ebf1f7',
          width: '100vw',
          marginRight: '10px',
          borderRadius: '15px',
        }}
      >
        <Form
          name="basic"
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 13 }}
          style={{ marginTop: '30px', paddingLeft: '30px' }}
          labelAlign="left"
        >
          <Form.Item
            label={<strong>Prompt</strong>}
            name="Base prompt"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            style={{ marginBottom: '40px' }}
          >
            <TextArea
              disabled
              style={{ resize: 'vertical', maxWidth: 'none', width: '78%', height: '100px' }}
              defaultValue={getPromptString()}
              value={getPromptString()}
              onChange={(value) => setMrrprompt(value.target.value)}
            />
          </Form.Item>
          <Form.Item
            label={<strong>Extra Entities</strong>}
            name="Extra Entities"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            style={{ marginBottom: '40px' }}
          >
            <TextArea
              style={{ resize: 'vertical', maxWidth: 'none', width: '78%', height: '60px' }}
              defaultValue={extraMrrprompt}
              value={extraMrrprompt}
              onChange={(value) => setExtraMrrprompt(value.target.value)}
            />
          </Form.Item>
          <Form.Item
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 17 }}
            label={<strong>Sizes</strong>}
            name="sizes"
            style={{ marginBottom: '30px', marginRight: '10px' }}
          >
            <Row justify="start">
              <Col span={12}>
                <Form.Item name="Width" label={<strong>W</strong>}>
                  <Select
                    style={{ width: '70%' }}
                    placeholder="width"
                    defaultValue={512}
                    onChange={(value) => setSelectedWidth(value)}
                  >
                    {widths.map((width) => (
                      <Select.Option value={width.value}>{width.label}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="Height" label={<strong>H</strong>}>
                  <Select
                    style={{ width: '70%' }}
                    placeholder="height"
                    defaultValue={512}
                    onChange={(value) => setSelectedHeight(value)}
                  >
                    {heights.map((height) => (
                      <Select.Option value={height.value} defaultValue={128}>
                        {height.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item
            label={(
              <>
                {<strong>Num outputs</strong>}{' '}
                <Tooltip
                  title="Number of images to output. (minimum: 1; maximum: 4)"
                  color="#2a95df"
                >
                  <InfoCircleOutlined style={{ marginLeft: '10px', color: '#2a95df' }} />
                </Tooltip>{' '}
              </>
            )}
            name="num_outputs"
            style={{ marginBottom: '40px' }}
          >
            <Row>
              <Col span={12}>
                <Slider
                  min={1}
                  max={4}
                  onChange={(newValue) => setNumOutputs(newValue)}
                  value={typeof numOutputs === 'number' ? numOutputs : 0}
                />
              </Col>
              <Col span={4}>
                <InputNumber
                  min={1}
                  max={4}
                  style={{ margin: '0 16px' }}
                  value={numOutputs}
                  onChange={(newValue) => setNumOutputs(newValue)}
                />
              </Col>
            </Row>
          </Form.Item>
          <Form.Item
            label={(
              <>
                {<strong>Num inference steps</strong>}{' '}
                <Tooltip
                  title="Number of denoising steps (minimum: 1; maximum: 500)"
                  color="#2a95df"
                >
                  <InfoCircleOutlined style={{ marginLeft: '10px', color: '#2a95df' }} />
                </Tooltip>{' '}
              </>
            )}
            name="num_inference_steps"
            style={{ marginBottom: '40px' }}
          >
            <Row>
              <Col span={12}>
                <Slider
                  min={1}
                  max={500}
                  onChange={(newValue) => setNumInferenceSteps(newValue)}
                  value={typeof numInferenceSteps === 'number' ? numInferenceSteps : 0}
                />
              </Col>
              <Col span={4}>
                <InputNumber
                  min={1}
                  max={500}
                  style={{ margin: '0 16px' }}
                  value={numInferenceSteps}
                  onChange={(newValue) => setNumInferenceSteps(newValue)}
                />
              </Col>
            </Row>
          </Form.Item>
          <Form.Item
            label={(
              <>
                {<strong>Guidance scale</strong>}{' '}
                <Tooltip
                  title="Scale for classifier-free guidance (minimum: 1; maximum: 20)"
                  color="#2a95df"
                >
                  <InfoCircleOutlined style={{ marginLeft: '10px', color: '#2a95df' }} />
                </Tooltip>{' '}
              </>
            )}
            name="guidance_scale"
            style={{ marginBottom: '40px' }}
          >
            <Row>
              <Col span={12}>
                <Slider
                  min={1}
                  max={20}
                  onChange={(newValue) => setGuidanceScale(newValue)}
                  value={typeof guidanceScale === 'number' ? guidanceScale : 0}
                  step={0.01}
                />
              </Col>
              <Col span={4}>
                <InputNumber
                  min={1}
                  max={20}
                  style={{ margin: '0 16px' }}
                  value={guidanceScale}
                  onChange={(newValue) => setGuidanceScale(newValue)}
                  step={0.01}
                />
              </Col>
            </Row>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 6 }}>
            <Button
              type="primary"
              htmlType="submit"
              onClick={sendImageInfo}
              style={{
                borderRadius: '15px',
                width: '140px',
              }}
            >
              Generate Images
            </Button>
            <Button
              htmlType="button"
              onClick={forceUpdate}
              style={{
                borderRadius: '15px',
                width: '100px',
                margin: '0 12px',
              }}
            >
              Reset
            </Button>
          </Form.Item>
        </Form>
      </Col>
      <Col
        span={11}
        style={{
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: '#ebf1f7',
          borderRadius: '15px',
          width: '100%',
        }}
      >
        {!loadingImages && (
          <div>
            {/* <Space
              direction="vertical"
              size="middle"
              style={{
                display: 'flex',
              }}
            >
              <div style={{ width: '450px', position: 'relative' }}>
                <Image
                  src={images[selectedImageIndex]}
                  style={{ width: '100%', borderRadius: '10px' }}
                />
              </div>
              <Row justify="space-evenly" align="middle">
                <Col>
                  <LeftCircleOutlined
                    onClick={() => switchImage(-1)}
                    style={{ color: '#2a95df', fontSize: '45px' }}
                  />
                </Col>
                <Col style={{ fontSize: '25px' }}>
                  <strong>
                    {selectedImageIndex + 1}/{images.length}
                  </strong>
                </Col>
                <Col>
                  <RightCircleOutlined
                    onClick={() => switchImage(1)}
                    style={{ color: '#2a95df', fontSize: '45px' }}
                  />
                </Col>
              </Row>
            </Space> */}
            <ReactInstaStories
              stories={images}
              keyboardNavigation
              preventDefault={false}
              storyContainerStyles={{
                borderRadius: '15px',
                marginTop: '15px',
                width: '100%',
                backgroundColor: 'rgb(230, 230, 230)',
              }}
              loop
            />
          </div>
        )}

        {loadingImages && (
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <LoadingOutlined style={{ color: '#2a95df', fontSize: '170px' }} />
          </div>
        )}
      </Col>
    </Row>
  );

  return (
    <>
      {loading && (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <LoadingOutlined style={{ color: '#2a95df', fontSize: '170px' }} />
        </div>
      )}
      {!loading && (
        <div>
          <TabSwitcherAlpha
            tab1="Build Prompt"
            tab2="Generate Creatives"
            setSwitcher={setActiveTab}
            style={{ width: '20rem' }}
            tab={activeTab}
          />

          {activeTab === 0 && (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {renderPrompt()}
            </div>
          )}
          {activeTab === 1 && (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {renderPreview()}
            </div>
          )}
        </div>
      )}
    </>
  );
}
export default TextImageComponent;
