import { baseAPIsUrl } from 'Config/Api';
import createInstance from 'Config/AxiosConfig';

export async function deleteFiles(fileIds) {
  try {
    const instance = await createInstance();
    const { data } = await instance.post(`${baseAPIsUrl}/delete_files`, {
      file_ids: fileIds,
    });
    if (data.statusCode === '200') {
      return true;
    }
    return false;
  } catch (ex) {
    console.log(ex);
    return false;
  }
}
