/* eslint-disable import/order */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/button-has-type */
/* eslint-disable no-return-assign */
/* eslint-disable no-use-before-define */

import { Button, Col, Form, Row, Select, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  CheckCircleOutlined,
  CheckOutlined,
  LeftOutlined,
  RightOutlined,
  RocketOutlined,
} from '@ant-design/icons';
import { store } from 'redux/store';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import OnBoardingScreen from './OnBoardingScreen.js';
import FlowSteps from './FlowSteps.js';
import createInstance from 'Config/AxiosConfig.js';

const { Option } = Select;

const ThirdScreenTis = (props) => {
  const { index, changeIndex, setStarterFlow, setIndex } = props;
  const { data, setData } = props;
  const user = useSelector((state) => state?.user);

  // 0 is by default first page..
  const [preparingOnBoarding, setPreparingOnBoarding] = useState(0);
  const [boardingStatus, setBoardingStatus] = useState({});

  const [form] = Form.useForm();

  useEffect(() => {
    // Set the initial value for the select field
    form.setFieldsValue({
      linkedinUrl: data?.linkedinValue,
      targetCountry: data?.targetCountry,
      translationEngine: data?.translationEngine,
      CityOfChoice: data?.city,
      language: data?.language,
    });
  }, []);

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const pasteButtonHandler = async () => {
    if (navigator.clipboard && navigator.clipboard.readText) {
      try {
        const text = await navigator.clipboard.readText();
        setData({ ...data, linkedinValue: text });
        // Do something with the pasted text
        console.log(text);
      } catch (error) {
        console.error('Failed to read clipboard contents: ', error);
      }
    } else {
      // Fallback for browsers that don't support the Clipboard API
      console.warn('Clipboard API not supported.');
      // Implement fallback logic here (e.g., prompt the user to manually paste the content)
    }
  };
  const validateForm = () => {
    if (!data.selectedKeyInteresetOption) {
      alert('KeyInteresetOption is not filled');
      return false;
    }
    if (!data.selectedNetworkingOption) {
      alert('NetworkingOption is not filled');
      return false;
    }
    if (!data.selectedGrowthOption) {
      alert('GrowthOption is not filled');
      return false;
    }
    if (!data.selectedBrandOption) {
      alert('BrandOption is not filled');
      return false;
    }
    return true;
  };

  const startOnboarding = async () => {
    try {
      // first step we need to call the create-project..
      const instance = await createInstance();
      // check if the required elements are there..
      const ok = validateForm();
      if (ok) {
        const body = prepareBodyForOnBoarding();
        alert(JSON.stringify(body));
        const response1 = await instance.post('create-project', body);
        if (response1.data.statusCode === '500') {
          // Workspace already exists
          if (response1.data.body === 'Project Name already exists') {
            alert('Workspace already exists !');
          }
        }
        if (response1.data.statusCode === '200') {
          // we call the onboard-me-athena here..
          const body2 = prepareBodyForCreateProject();
          axios
            .post(
              'https://p8dapk4yf9.execute-api.us-east-2.amazonaws.com/prod/onboard-me-athena',
              body2,
            )
            .catch((error) => {
              console.error(error);
            });

          // checking the status of on boarding me..
          setInterval(async () => {
            console.log('start interval');
            const response = await instance.post(
              'https://p8dapk4yf9.execute-api.us-east-2.amazonaws.com/prod/get-onboarding-status',
              { name: data.selectedGrowthOption },
            );
            setBoardingStatus(response.data);
            setPreparingOnBoarding(1);
            console.log(`checking status ${JSON.stringify(response)}`);
          }, 7000);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getUserFromRedux = () => {
    const state = store.getState();
    return state.user;
  };

  const getEngineCode = () => {
    if (data.translationEngine === 'Google') {
      return 0;
    }
    if (data.translationEngine === 'DeepL') {
      return 1;
    }
    return 0;
  };

  const prepareBodyForOnBoarding = () => ({
    username: getUserFromRedux()?.username,
    name: data.selectedGrowthOption,
    site: data.linkedinValue,
    fullName: user.name,
    lang: data.language,
    lang_engine: getEngineCode(),
    tags: [],
    country_code: data.targetCountry,
    city: data.city,
    target_persona: data.selectedNetworkingOption,
    product_name: data.selectedBrandOption,
    founder_name: user.name,
    configurations: {
      level1: {
        sources: ['mrr'],
        urls: [],
      },
      level2: {
        sources: ['mrr'],
        urls: [],
      },
      level3: {
        sources: ['mrr'],
        urls: [],
      },
    },
  });

  const prepareBodyForCreateProject = () => ({
    username: getUserFromRedux()?.username,
    name: data.selectedGrowthOption,
    source_lang: data.language,
    site: data.linkedinValue,
    input_data: [
      {
        question: 'Define your Niche',
        answer: '',
      },
      {
        question: 'Define your Sub-Niche',
        answer: '',
      },
      {
        question: 'Define your Micro-Niche',
        answer: '',
      },
      {
        question: 'Define your Industry Vertical',
        answer: '',
      },
      {
        question: 'Ideal Prospect Overview',
        answer: '',
      },
      {
        question: 'Your Website Domain Name',
        answer: data.linkedinValue,
      },
      {
        question: 'Your Company Legal Name',
        answer: data.selectedBrandOption,
      },
      {
        question: 'Main topic of interest',
        answer: data.selectedKeyInteresetOption,
      },
      {
        question: 'Long Tail Topic 1',
        answer: '',
      },
      {
        question: 'Single Big Shocking Statement',
        answer: '',
      },
      {
        question: 'Single Big Emotional Payoff',
        answer: '',
      },
      {
        question: 'Who you want to target',
        answer: data.selectedNetworkingOption,
      },
    ],
    fullName: user.name,
    default_templates: [],
  });

  return (
    <>
      {' '}
      {preparingOnBoarding === 1 && (
        <OnBoardingScreen
          boardingStatus={boardingStatus}
          setStarterFlow={setStarterFlow}
        />
      )}
      {preparingOnBoarding === 0 && (
        <div
          style={{
            backgroundColor: '#444653',
            height: '100vh',
          }}
        >
          <div
            style={{
              height: '10vh',
              // backgroundColor: 'green',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontWeight: 'bold',
              fontSize: '25px',
              color: 'white',
            }}
          >
            <Row
              align="middle"
              justify="space-between"
              style={{
                width: '100%',
                // backgroundColor: 'pink'
              }}
            >
              <Col span={12}>
                <CaretLeftOutlined
                  onClick={() => changeIndex('LEFT')}
                  style={{
                    textAlign: 'left',
                    marginLeft: '10px',
                    cursor: 'pointer',
                    color: '#5887FB',
                    fontSize: '50px',
                  }}
                />
              </Col>
              <Col span={12}>
                <CaretRightOutlined
                  onClick={() => changeIndex('RIGHT')}
                  style={{
                    textAlign: 'right',
                    marginRight: '10px',
                    cursor: 'pointer',
                    color: '#5887FB',
                    fontSize: '50px',
                    float: 'right',
                  }}
                />
              </Col>
            </Row>
          </div>
          <div
            style={{
              height: '70vh',
              // backgroundColor: 'yellow',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Form
              {...layout}
              form={form}
              name="control-hooks"
              // onFinish={onFinish}
              style={{ width: '85vw' }}
            >
              <Form.Item name="logo">
                <div>
                  <RocketOutlined
                    style={{ fontSize: '100px', color: '#5887FB' }}
                  />
                </div>
              </Form.Item>
            </Form>
          </div>
          <div
            style={{
              height: '10vh',
              // backgroundColor: 'green',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              type="primary"
              style={{
                width: '200px',
                borderRadius: '10px',
                fontSize: '15px',
                backgroundColor: '#5887FB',
                boxShadow: '10px 5px 5px black',
              }}
              onClick={startOnboarding}
            >
              Lets Start
            </Button>
          </div>
          <FlowSteps currentStep={4} setIndex={setIndex} />
        </div>
      )}
    </>
  );
};

export default ThirdScreenTis;
