import { useMutation } from 'react-query';
import {
  getReduxUserState,
  getReduxWorkspaceState,
} from 'utils/helpers/redux-selectors';
import createInstance from 'Config/AxiosConfig';
import { toast } from 'react-toastify';
import { trainAthena } from 'queries/train-athena';

const saveMarketInsights = async ({ categoryName, insight }) => {
  const { email } = getReduxUserState();
  const { name } = getReduxWorkspaceState();

  const payload = {
    username: email,
    name,
    subcategory: categoryName,
    insight,
  };

  const instance = await createInstance();

  const response = await instance.post('mrr-insights-save', payload);

  if (response?.data?.statusCode === '200') {
    return response?.data?.data;
  }

  throw new Error('Market Insights could not be saved, Try again !');
};

export const useMutateSaveMarketInsights = () =>
  useMutation(saveMarketInsights, {
    onSuccess: (data) => {
      trainAthena();
      toast.success('Sucessfully Saved', {
        position: 'bottom-center',
        theme: 'dark',
      });
    },
    onError: (error) => {
      toast.error(`${error.message}`, {
        position: 'bottom-center',
        theme: 'dark',
      });
    },
  });
