/* eslint-disable global-require */
import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './rootReducer';

let middlewares = [thunk];

// To Avoid Logger In Production App ,
// require method here to avoid bundle size increase only in development host.
if (process.env.NODE_ENV === 'development') {
  middlewares = [require('redux-logger').default, ...middlewares];
} else {
  middlewares = [...middlewares];
}

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middlewares)),
);

export const persistor = persistStore(store);

export default { store, persistor };
