import { notification } from 'antd';

export const showNotification = (type, message, description) => {
  notification.open({
    type,
    message,
    description,
    placement: 'bottomRight',
    duration: 6,
  });
};
