/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import createInstance from 'Config/AxiosConfig';
import { store } from 'redux/store';

function sleep(ms) {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const onBoardingFillWithAILevel2 = async (
  founderName,
  product,
  sources,
) => {
  const state = store.getState(); // Redux state

  try {
    const body = {
      username: state.user.username,
      name: state.workspace.name,
      product,
      founder: founderName,
      source_lang: state.workspace.language,
      source: sources,
    };
    const instance = await createInstance();
    const { data } = await instance.post('mrr-onboarding-level2', body);

    if (data.statusCode === '200') return { error: false };
    return { error: true };
  } catch (ex) {
    for (let i = 0; i < 4; i++) {
      console.log('sleeping count ', i + 1);
      await sleep(15 * 1000);
      const status = await getProgressPercentageOnBoarding();
      if (status) {
        return { error: false };
      }
    }
    return { error: true };
  }
};

const getProgressPercentageOnBoarding = async () => {
  const state = store.getState(); // Redux state

  const body = {
    username: state.user.username,
    name: state.workspace.name,
  };
  const instance = await createInstance();
  const { data } = await instance.post('dashboard-mrr-progress', body);
  return data.data.percentage2 > 1.5; // 1.5% api fullfeiled
};

export const saveAnswersLevel2 = async (founderName, product) => {
  const state = store.getState(); // Redux state

  const body = {
    username: state.user.username,
    name: state.workspace.name,
    section: 'Niche',
    subcategory: 'Define your Identity to your Niche',
    data: {
      'Name of Your Company Founder': [founderName],
      'Name or title of product or service': [product],
    },
  };

  const instance = await createInstance();
  const { data } = await instance.post('save-questions', body);
  if (data.statusCode === '200') return { error: false };
  return { error: true };
};
