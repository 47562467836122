/* eslint-disable indent */
import { BLOG_TYPE } from 'constants/market-message/generate-blogs';
import NoData from 'components/common/NoData';
import SearchInput from 'components/common/SearchInput';
import { MarketMessageArticleLoading } from 'components/skeleton/market-message/ArticlesLoading';
import { useGetSavedBlogs } from 'queries/digital-assets/get-save-blogs';
import { getSavedBlogsSelector } from 'queries/digital-assets/selectors';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ArticleCard from '../ArticleCard/ArticleCard';
import digital_assets_common_styles from '../digital-assets.module.css';

const BlogsPost = () => {
  const { email } = useSelector((state) => state?.user);
  const { data, isLoading } = useGetSavedBlogs(email, BLOG_TYPE.general);

  const blogs = getSavedBlogsSelector(data);
  const [searchKey, setSearchKey] = useState('');

  const applySearchFilter = (blog) => {
    const key = searchKey?.toLowerCase();
    const blogName = blog?.blog_name?.toLowerCase();
    const blogAuthor = blog?.blog_author?.toLowerCase();
    const blogScript = blog?.blog_script?.toLowerCase();

    return (
      blogName.includes(key) ||
      blogAuthor.includes(key) ||
      blogScript.includes(key)
    );
  };

  return (
    <div className={digital_assets_common_styles.child_wrapper}>
      <div className={digital_assets_common_styles.left_child}>
        <div className={digital_assets_common_styles.top_row}>
          <div className={digital_assets_common_styles.placeholder_div} />
          <div className={digital_assets_common_styles.search_wrapper}>
            <SearchInput
              placeholder="Search Blogs"
              onChange={(e) => setSearchKey(e.target.value)}
              value={searchKey}
            />
          </div>
        </div>
        <hr />
        <div className={digital_assets_common_styles.article_card_wrapper}>
          {blogs?.filter(applySearchFilter)?.map((blog) => (
            <ArticleCard
              blogName={blog.blog_name}
              blogImage={blog.blog_image}
              blogAuthor={blog.blog_author}
              blogScript={blog.blog_script}
              blogTopic={blog?.blog_topic}
              blogReadingTime={blog?.blog_readtime}
              id={blog.blog_id}
              key={blog.blog_id}
              datePublished={blog.date_published}
              from=""
            />
          ))}
        </div>
        {!isLoading &&
          blogs?.length > 0 &&
          blogs?.filter(applySearchFilter).length === 0 && (
            <NoData
              message={`No results found for ${searchKey}. Please try a different search term.`}
            />
          )}

        {!isLoading && blogs?.length === 0 && (
          <NoData message={'You have not created any blogs yet.'} />
        )}
        {isLoading && <MarketMessageArticleLoading />}
      </div>
      {/* <div className={digital_assets_common_styles.aside_wrapper}>aside</div> */}
    </div>
  );
};

export default BlogsPost;
