import { LogoUrl } from 'constants/index';
import React from 'react';
import { ScaffoldCard } from 'components/common/scaffold-card';
import { Divider } from 'components/common/divider';
import styles from './serp-headers.module.css';
import SerpHeadContent from './SerpHeadContent';

function SerpHeaders({ serp }) {
  const pageRank = serp?.rank;

  const serpH1 = serp?.paragraph_h1;
  const serpH2 = serp?.paragraph_h2;
  const serpH3 = serp?.paragraph_h3;
  const serpH4 = serp?.paragraph_h4;
  const serpH5 = serp?.paragraph_h5;

  return (
    <ScaffoldCard className={styles.wrapper}>
      <div className={styles.url_container}>
        <div>
          <img alt={serp.url} src={LogoUrl + serp.url} />
          {serp.url}
        </div>
        <div className={styles.rank_container}>
          <span>Rank:</span>
          <span>{pageRank}</span>
        </div>
      </div>

      <SerpHeadContent serpHeadData={serpH1} type="H1" />
      { Object.keys(serpH1).length !== 0 && <Divider className="h-margin-b-x2 h-margin-t-x2" />}
      <SerpHeadContent serpHeadData={serpH2} type="H2" />
      { Object.keys(serpH2).length !== 0 && <Divider className="h-margin-b-x2 h-margin-t-x2" />}
      <SerpHeadContent serpHeadData={serpH3} type="H3" />
      { Object.keys(serpH3).length !== 0 && <Divider className="h-margin-b-x2 h-margin-t-x2" />}
      <SerpHeadContent serpHeadData={serpH4} type="H4" />
      { Object.keys(serpH4).length !== 0 && <Divider className="h-margin-b-x2 h-margin-t-x2" />}
      <SerpHeadContent serpHeadData={serpH5} type="H5" />

    </ScaffoldCard>
  );
}

export default SerpHeaders;
