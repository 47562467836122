/* eslint-disable radix */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { RefreshRounded } from '@mui/icons-material';
import ReactSpeedometer from 'react-d3-speedometer';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Loader from '../../../../Shared/Loader';
import CustomTooltip from '../../../../Shared/CustomTooltip';
import { getTranslatedTextEnglish } from '../../../../Shared/TranshelperStatic';

function DocSimilarityScore() {
  const [percentage, setPercetage] = useState(30);
  const [percentageLoader, setPercetageLoader] = useState(0);
  const [hasLoadedPercentage, setHasLoadedPercentage] = useState(false);
  let INCREMENTER = 1;
  useEffect(() => {
    handleSimilarityScore();
    const interval = setInterval(() => {
      controlCounterLoader();
    }, 100);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const messagesToScore = [
    'Nope! You are not prepared for Coffee with FRED.',
    'Cool! You need a little more prep for Coffee with FRED.',
    'Keep Going! You are almost ready to book a call with FRED.',
    'Wow! Expect you are going to get a YES from FRED.',
    'Bravo! FRED is going to love this content. Go crush it!',
  ];

  const handleSimilarityScore = async () => {
    setHasLoadedPercentage(false);
    try {
      const iframe = document.querySelector('#texteditor_ifr');
      const result_document = iframe.contentWindow.document.getElementById(
        'tinymce',
      ).innerText;
      const body = {
        username: localStorage.getItem('username'),
        name: localStorage.getItem('name'),
        document: await getTranslatedTextEnglish(result_document),
      };

      const result = await axios.post(
        'https://apis.crawlq.ai/document-similarity-score',
        body,
      );

      if (result.data.statusCode === '500') return;
      // eslint-disable-next-line radix
      setPercetage(parseInt(result.data.body));
      setHasLoadedPercentage(true);
    } catch (err) {
      console.log(err);
    }
  };

  const controlCounterLoader = () => {
    setPercetageLoader((prev) => {
      if (prev === 70) INCREMENTER = -1;
      if (prev === 30) INCREMENTER = 1;
      return prev + INCREMENTER;
    });
  };

  return hasLoadedPercentage ? (
    <div
      style={{
        display: 'flex',
        height: '150px',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <ReactSpeedometer
          minValue={0}
          maxValue={100}
          value={percentage || 0}
          onClick={handleSimilarityScore}
          currentValueText={`Score = ${percentage}`}
          height={130}
          width={200}
          segmentColors={[
            '#03BFFF',
            '#0592D0',
            '#2A52BE',
            '#0047AB',
            '#0073CF',
          ]}
          ringWidth={45}
        />
        <div className="d-flex" style={{ alignItems: 'center' }}>
          <span
            className="font-accent"
            style={{ fontSize: '0.8rem', textAlign: 'justify' }}
          >
            {messagesToScore[parseInt((percentage / 100) * 5)]}
          </span>
          <button
            style={{ backgroundColor: '#fff', alignSelf: 'start' }}
            onClick={handleSimilarityScore}
          >
            <RefreshRounded />
          </button>
        </div>
      </div>
      <CustomTooltip
        title="The percentage similarity of how much the content on the left aligns with your Market Research data."
        arrow
        placement="top"
        style={{ color: '#0073CF' }}
      >
        <InfoOutlined />
      </CustomTooltip>
    </div>
  ) : (
    <div
      style={{
        overflow: 'hidden',
        display: 'flex',
        height: '150px',
        justifyContent: 'center',
      }}
    >
      <ReactSpeedometer
        minValue={0}
        maxValue={100}
        value={percentageLoader}
        currentValueText="Calculating..."
        height={200}
        width={200}
        segmentColors={['#03BFFF', '#0592D0', '#2A52BE', '#0047AB', '#0073CF']}
        ringWidth={45}
      />
    </div>
  );
}
export default DocSimilarityScore;
