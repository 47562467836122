import { SourceFilter } from 'libs/enums/sourceFilter.enum';

const getParsedConfigurations = (configurations) => {
  let urls = [];
  if (Object.values(configurations.urls).length > 0) {
    urls = [...Object.values(configurations.urls)];
  }
  const default_sources = {};
  Object.keys(SourceFilter).map((source) => {
    default_sources[source] = false;
    return {};
  });

  const sources = {};
  configurations?.sources.map((source) => {
    sources[source] = true;
    return {};
  });
  const result = { sources: { ...default_sources, ...sources }, urls };
  return result;
};

export const ErrorToastProps = {
  autoClose: 3000,
  position: 'bottom-center',
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  theme: 'colored',
};

export default getParsedConfigurations;
