import {
  SECTION_DEFAULT_DATA,
  TEXT_DEFAULT_DATA,
} from 'constants/manage-templates/create-templates';
import { ROUTES } from 'constants/routes';
import { ScaffoldCard } from 'components/common/scaffold-card';
import React, { useEffect, useRef, useState } from 'react';

import { useLocation } from 'react-router-dom';
import manage_templates_common_styles from '../manage-templates.module.css';
import styles from './create-templates.module.css';

import {
  Div,
  FormWrapper,
  Input,
  TeamplateContainer,
  TemplateBuilderWrapper,
  Textarea,
} from './styles';
import SectionForm from '../components/SectionForm';
import FormButtonWrapper from '../components/FormButtonWrapper';

const EditTemplates = () => {
  const location = useLocation();

  const [sectionData, setSectionData] = useState([{ ...SECTION_DEFAULT_DATA }]);
  const [formData, setFormData] = useState({ ...TEXT_DEFAULT_DATA });
  const [isError, setIsError] = useState(false);
  const dragItem = useRef();
  const dragOverItem = useRef();
  const [isUpdating, setIsUpdating] = useState(false);

  const dragStart = (e, position) => {
    dragItem.current = position;
    e.target.className = 'drag-child';
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
    e.target.className = '';
  };

  const drop = () => {
    const copyListItems = [...sectionData];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setSectionData([...copyListItems]);
  };

  const handleChange = (e, name) => {
    setFormData({ ...formData, [name]: e.target.value });
    setIsError(false);
  };

  useEffect(() => {
    if (location.state) {
      const { template_name, template_description, sections } = location.state;
      if (ROUTES.EDIT_TEMPLATES === location.pathname) {
        setIsUpdating(true);
        setFormData({ template_name, template_description });
        setSectionData(sections);
      }
    }
  }, [location.state]);

  return (
    <div className={manage_templates_common_styles.child_wrapper}>
      <div className={manage_templates_common_styles.left_child}>
        <ScaffoldCard className={styles.main_wrapper}>
          <TemplateBuilderWrapper>
            <TeamplateContainer className="h-margin-a-x2">
              {isError && (
                <p className="text-danger text-center">
                  500 Something Went Wrong
                </p>
              )}
              <FormWrapper>
                <Div>
                  <label className="global-fc-accent mb-0">Template Name</label>
                  <Input
                    value={formData.template_name}
                    onChange={(e) => handleChange(e, 'template_name')}
                  />
                </Div>
                <Div>
                  <label className="global-fc-accent mb-0">Description</label>
                  <Textarea
                    value={formData.template_description}
                    onChange={(e) => handleChange(e, 'template_description')}
                  />
                </Div>
              </FormWrapper>
              <div className={styles.section_wrapper}>
                <hr />
                {sectionData.map((data, index) => (
                  <div
                    onDragStart={(e) => dragStart(e, index)}
                    onDragEnter={(e) => dragEnter(e, index)}
                    onDragEnd={drop}
                    key={`${index + 1} ${data.template_text_prompt}`}
                    draggable
                  >
                    <SectionForm
                      data={data}
                      index={index}
                      sectionData={sectionData}
                      setSectionData={setSectionData}
                      setIsError={setIsError}
                    />
                    <hr />
                  </div>
                ))}
              </div>
              <FormButtonWrapper
                formData={formData}
                sectionData={sectionData}
                setFormData={setFormData}
                setIsError={setIsError}
                setSectionData={setSectionData}
                isUpdating={isUpdating}
              />
            </TeamplateContainer>
          </TemplateBuilderWrapper>
        </ScaffoldCard>
      </div>
    </div>
  );
};

export default EditTemplates;
