import { useQuery } from 'react-query';
import { store } from 'redux/store';
import createInstance from 'Config/AxiosConfig';

const getIntegratedAppsList = async () => {
  const state = store.getState();
  const active_workspace = state.workspace;
  const { user } = state;

  const instance = await createInstance();
  const { data } = await instance.get(
    `/get-user-tool-credentials?username=${user?.username}&name=${active_workspace?.name}`,
  );
  if (data.statusCode === '200') {
    return data.data;
  }
  throw new Error('Failed to Fetch Apps List');
};

export const GET_INTEGRATED_APPS_KEY = 'get-integrated-apps-keys';

// Query Config Options
const queryOptions = {
  staleTime: 90 * 1000, // 90 secs
};

export const useGetIntegratedAppsLists = () => {
  const queryKey = [GET_INTEGRATED_APPS_KEY];
  return useQuery(queryKey, getIntegratedAppsList, queryOptions);
};
