import {
  getReduxUserState,
  getReduxWorkspaceState,
} from 'utils/helpers/redux-selectors';
import axios from 'axios';
import { baseAPIsUrl } from 'Config/Api';

export const trainAthena = async () => {
  const { email } = getReduxUserState();
  const { name } = getReduxWorkspaceState();

  const axiosConfig = {
    multiValueHeaders: {
      'Content-Type': ['application/json'],
      'Access-Control-Allow-Origin': ['*'],
      'Access-Control-Allow-Headers': [
        'Content-Type',
        'X-Amz-Date',
        'Authorization',
        'X-Api-Key',
        'X-Amz-Security-Token',
      ],
    },
  };

  const body = {
    username: email,
    name,
    num: 1,
    cc: 'US',
    is_train: true,
    serp: true,
    overwrite: false,
  };
  await axios.post(`${baseAPIsUrl}/test-semantic`, body, axiosConfig);
};
