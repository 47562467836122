import createInstance from 'Config/AxiosConfig';
import { store } from 'redux/store';

const getWorkspaceFromRedux = () => {
  const state = store.getState();
  return state.workspace;
};

const getPrivateBlogs = async () => {
  try {
    const instance = await createInstance();
    const { data } = await instance.post(
      'https://p8dapk4yf9.execute-api.us-east-2.amazonaws.com/prod/get-user-blog-template',
    );

    if (data.statusCode === '200') {
      return data.templates;
    }
    return [];
  } catch (ex) {
    return [];
  }
};

export const createBlogsDrafts = async (templates) => {
  try {
    const workspace = getWorkspaceFromRedux();
    const body = {
      name: workspace.name,
      templates: [...templates],
    };
    const instance = await createInstance();
    const { data } = await instance.post('/generate-and-publish-blogs', body);
    if (data.statusCode === '200') {
      return {
        error: false,
        message: 'Blogs Created Successfully',
      };
    }
    return {
      error: true,
      message: 'Server Error',
    };
  } catch (ex) {
    return {
      error: true,
      message: 'Something went wrong',
    };
  }
};

export default getPrivateBlogs;
