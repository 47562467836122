/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
import createInstance from 'Config/AxiosConfig';
import { store } from 'redux/store';

const getUserFromRedux = () => {
  const state = store.getState();
  return state.user;
};

const getWorkspaceFromRedux = () => {
  const state = store.getState();
  return state.workspace;
};

export const getCurrentFormatedDate = () => {
  const optionsToFomatDate = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };

  const formattedDate = new Date(new Date()).toLocaleDateString(
    'en-US',
    optionsToFomatDate,
  );
  return formattedDate;
};

const saveInsights = async (subCategoryName, content, autosave = false) => {
  try {
    let body = {
      username: getUserFromRedux().username,
      name: getWorkspaceFromRedux().name,
      subcategory: subCategoryName,
    };
    if (!content) {
      if (!window.tinyMCE.activeEditor) return;
      content = window.tinyMCE.activeEditor.getContent();
    }
    if (autosave && content.trim() === '') return;
    body = { ...body, insight: content };
    const instance = await createInstance();
    // eslint-disable-next-line no-unused-vars
    const result = await instance.post('mrr-insights-save', body);
    if (autosave) return;
    window.tinyMCE.activeEditor.notificationManager.open({
      text: 'Insights Saved',
      type: 'success',
      timeout: 1500,
    });
  } catch (err) {
    console.log('Error', err);
  }
};

export const fetchInsights = async (subCategoryName) => {
  const body = {
    username: getUserFromRedux().username,
    name: getWorkspaceFromRedux().name,
    subcategory: subCategoryName,
  };

  const instance = await createInstance();
  const result = await instance.post('mrr-insights', body);

  return result;
};

export const fetchSubCategoryHelper = async (subCategoryName) => {
  const instance = await createInstance();
  const { username } = getUserFromRedux();
  const { name } = getWorkspaceFromRedux();
  const category = localStorage.getItem('category');
  const result = await instance.get(
    `helper?username=${username}&name=${name}&section=${category}&subcategory=${subCategoryName}`,
  );
  return result;
};

export const deleteMRRLevelAnswers = async (mrlevel) => {
  try {
    const body = {
      workspace: getWorkspaceFromRedux().name,
      mrlevel,
      section: localStorage.getItem('category'),
    };
    const instance = await createInstance();
    const { data } = await instance.post('clear-mr-level', body);
    return { error: data.statusCode !== '200' };
  } catch (ex) {
    console.log('Error in Delete level answers', ex);
    return { error: true };
  }
};

export default saveInsights;
