export function shortenFileName(inputString) {
  const maxLength = 20;
  if (inputString.length <= maxLength) {
    return inputString;
  }
  const firstPart = inputString.slice(0, 10);
  const lastPart = inputString.slice(-10);

  return `${firstPart}...${lastPart}`;
}
