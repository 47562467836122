/* eslint-disable max-len */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ScaffoldCard } from 'components/common/scaffold-card';
import { Divider } from 'components/common/divider';
import { getFormattedTimeInDayMonthYear } from 'utils/helpers';
import { getMainTopicInterest } from 'queries/get-main-topic-interest';
import { workspaceSelect } from 'redux/index';
import { toast } from 'react-toastify';
import styles from './recent-workspace.module.css';

function WorkspaceCard({ workspace, onClose }) {
  const dispatch = useDispatch();
  const activeWorkspaceName = useSelector((state) => state?.workspace?.name);

  const isActiveWorkspace = activeWorkspaceName === workspace?.name;
  const [ViewDetails, setViewDetails] = useState(false);

  const handleSwitchWorkspace = async () => {
    const mainTopicInterest = await getMainTopicInterest(workspace?.name);

    dispatch(
      workspaceSelect({
        name: workspace?.name,
        domain: workspace.site,
        language: workspace?.Lang,
        language_engine: workspace?.lang_engine,
        is_trained: workspace?.is_trained,
        last_trained: workspace?.last_trained,
        mti: mainTopicInterest,
      }),
    );

    toast.success(`Campaign Switched To ${workspace?.name}`, {
      position: 'bottom-center',
      theme: 'dark',
    });

    onClose();
  };

  return (
    <ScaffoldCard
      className={
        isActiveWorkspace
          ? styles.active_workspace
          : styles.recent_workspace_list
      }
    >
      <div className={styles.recent_workspace_name}>
        <div
          className={
            isActiveWorkspace
              ? styles.active_name_container
              : styles.name_container
          }
        >
          <span>{workspace?.name}</span>
          <div
            className={styles.view_details}
            onClick={() => setViewDetails((ViewDetails) => !ViewDetails)}
          >
            {ViewDetails ? 'Close Details' : 'View Details'}
          </div>
        </div>
        <span
          className={isActiveWorkspace ? styles.active : styles.switch}
          onClick={handleSwitchWorkspace}
        >
          {isActiveWorkspace ? 'Active Campaign' : 'Switch Campaign'}
        </span>
      </div>
      {ViewDetails && (
        <div className="h-margin-t-x2">
          <Divider />
          <div className={styles.workspace_detail_info}>
            <div>
              Domain :{' '}
              <span className={styles.details_info_value}>
                {workspace?.domain}
              </span>
            </div>
            <div>
              Main Topic Of Interest :{' '}
              <span className={styles.details_info_value}>
                {workspace?.mti}
              </span>
            </div>
            <div>
              Athena Trained :{' '}
              <span className={styles.details_info_value}>
                {workspace?.is_trained ? 'Yes' : 'No'}
              </span>{' '}
            </div>
            {workspace?.last_trained && (
              <div>
                Athena Last Trained On :{' '}
                <span className={styles.details_info_value}>
                  {getFormattedTimeInDayMonthYear(
                    workspace?.last_trained,
                  )?.toString()}
                </span>{' '}
              </div>
            )}
          </div>
        </div>
      )}
    </ScaffoldCard>
  );
}

export default WorkspaceCard;
