/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import React, { useState, useEffect } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { useDispatch, useSelector } from 'react-redux';
import { saveKeywordAction } from 'redux/index';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';
import { saveKeyword } from '../utils/index';
import {
  FinalListWrapper, CloudWordWrapper, SaveButton, WordWrapper, LimitReachedContainer,
} from './styles';

const CustomTooltip = withStyles({
  tooltip: {
    color: 'white',
    backgroundColor: 'rgb(7 7 7 / 95%)',
    fontSize: '12px',
    fontWeight: 'normal',
    letterSpacing: '1px',
    padding: '10px 15px',
    textAlign: 'justify',
    cursor: 'pointer',
  },
})(Tooltip);

function FinalList({
  choosenList, removeWordFromList, limitReached, handleSaveAuthorityBuilder,
}) {
  const [Saving, setSaving] = useState(false);

  const dispatch = useDispatch();
  const workspaceName = localStorage.getItem('name');
  const existingKeywords = useSelector((state) => state.authorityKeyword[workspaceName]) ?? [];

  const handleSaveKeyword = async () => {
    setSaving(true);
    const workspaceName = localStorage.getItem('name');
    const payload = {
      name: workspaceName,
      keywords_to_use: choosenList,
    };

    const response = await saveKeyword(payload);
    if (response) {
      const newChoosenList = [...choosenList];
      newChoosenList.push(...existingKeywords);
      const finalChoosenList = [...new Set(newChoosenList)];

      dispatch(saveKeywordAction([workspaceName, finalChoosenList]));
      localStorage.setItem(`seo_keywords_${workspaceName}`, JSON.stringify(finalChoosenList));
      handleSaveAuthorityBuilder();
      setSaving(false);
    }
  };

  return (
    <div style={{ height: '100%' }} className="global-d-flex-col">
      {limitReached && <LimitReachedContainer>Limit Reached</LimitReachedContainer>}
      <FinalListWrapper>
        {choosenList && choosenList.map((keyword, i) => (
          <CloudWordWrapper className="global-fs-secondary" key={i}>
            <WordWrapper>
              <div>{keyword}</div>
              <ClearIcon
                onClick={() => removeWordFromList(keyword)}
                style={{ width: '18px', marginLeft: '6px' }}
              />
            </WordWrapper>
          </CloudWordWrapper>
        ))}
      </FinalListWrapper>
      <CustomTooltip
        placement="top"
        title="Save and close to continue "
        arrow
      >
        <SaveButton onClick={handleSaveKeyword}>{Saving ? 'Saving' : 'Save'}</SaveButton>
      </CustomTooltip>
    </div>
  );
}

export default FinalList;
