import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ThumbDownOffAltOutlinedIcon from '@mui/icons-material/ThumbDownOffAltOutlined';
import ThumbDownRoundedIcon from '@mui/icons-material/ThumbDownRounded';
import ThumbUpRoundedIcon from '@mui/icons-material/ThumbUpRounded';
import React from 'react';
import { useMutateUpdateChatHistory } from 'queries/chat-athena/mutate-update-chat';
import CustomTooltip from 'Component/Shared/CustomTooltip';
import styles from './chat-athena-components.module.css';

export function LikeDislikeComponent({ rating, answerId }) {
  const { mutate: updateAnswerMutation } = useMutateUpdateChatHistory();

  const handleUpdateAnswer = (rating_scale) => {
    updateAnswerMutation({
      id: answerId,
      rating: rating_scale,
    });
  };

  function renderLikingFeature() {
    if (rating === -1) {
      return (
        <>
          <CustomTooltip
            arrow
            title="Give positive feedback by clicking Like Button to improve the output"
            placement="top"
          >
            <ThumbUpOutlinedIcon
              className={styles.icons}
              onClick={() => handleUpdateAnswer(1)}
            />
          </CustomTooltip>
          <CustomTooltip arrow title="Remove negative feedback" placement="top">
            <ThumbDownRoundedIcon
              className={styles.icons}
              onClick={() => handleUpdateAnswer(0)}
            />
          </CustomTooltip>
        </>
      );
    }
    if (rating === 1) {
      return (
        <>
          <CustomTooltip arrow placement="top" title="Unlike Response">
            {' '}
            <ThumbUpRoundedIcon
              className={styles.icons}
              onClick={() => handleUpdateAnswer(0)}
            />
          </CustomTooltip>
          <CustomTooltip
            arrow
            title="Give negative feedback by clicking Dislike Button to improve the output"
            placement="top"
          >
            <ThumbDownOffAltOutlinedIcon
              className={styles.icons}
              onClick={() => handleUpdateAnswer(-1)}
            />
          </CustomTooltip>
        </>
      );
    }

    return (
      <>
        <CustomTooltip
          arrow
          placement="top"
          title="Give postive feedback by clicking Like Button to improve the output"
        >
          <ThumbUpOutlinedIcon
            className={styles.icons}
            onClick={() => handleUpdateAnswer(1)}
          />
        </CustomTooltip>
        <CustomTooltip
          arrow
          title="Give negative feedback by clicking Dislike Button to improve the output"
          placement="top"
        >
          <ThumbDownOffAltOutlinedIcon
            className={styles.icons}
            onClick={() => handleUpdateAnswer(-1)}
          />
        </CustomTooltip>
      </>
    );
  }
  return renderLikingFeature();
}

export default LikeDislikeComponent;
