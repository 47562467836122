import axios from 'axios';

export const anonymousRegister = async (payload) => {
  console.log(payload);

  const response = await axios.post('https://apis.crawlq.ai/register', payload);

  console.log('debug', response);

  return response;
};
