import axios from 'axios';
import { baseAPIsUrl } from 'Config/Api';
import { useMutation } from 'react-query';
import {
  getReduxUserState,
  getReduxWorkspaceState,
} from 'utils/helpers/redux-selectors';
import { toast } from 'react-toastify';

const getCleanFileName = (filename) => filename.replace(/[^a-zA-Z .]/g, '');

export async function uploadDocument({ fileName, file }) {
  const cleanFileName = getCleanFileName(fileName);

  const { email } = getReduxUserState();
  const { name } = getReduxWorkspaceState();

  const queryParams = new URLSearchParams({
    name,
    username: email,
    file_name: cleanFileName,
  });

  const headers = {
    'Content-Type': 'multipart/form-data',
  };

  const config = {
    headers,
    params: queryParams,
  };
  const fileBinary = await file.arrayBuffer();

  const response = await axios.post(
    `${baseAPIsUrl}/upload-files`,
    fileBinary,
    config,
  );

  if (response.data.statusCode === '200') {
    return response.data.data.file_id;
  }
  return null;
}

export const useMutateUploadDocument = () =>
  useMutation(uploadDocument, {
    onSuccess: (data) => {
      toast.success('Uploaded file successfully', {
        position: 'bottom-center',
        theme: 'dark',
      });
    },
    onError: (error) => {
      toast.error('Please try again, our uploader seems to be a bit lazy.', {
        position: 'bottom-center',
        theme: 'dark',
      });
    },
  });
