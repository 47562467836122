/* eslint-disable import/no-extraneous-dependencies */
import BaseLayout from 'Component/Shared/BaseLayout/BaseLayout';
import Flyout from 'Component/Shared/Flyout/Flyout';
import {
  ButtonBasic,
  ButtonPrimary,
  Divider,
  Flex,
  HeadingDesc,
  HeadingName,
} from 'Component/Shared/GlobalStyles';
import LoaderSpin, { LoaderTailSpin } from 'Component/Shared/Loader';
import { getQueryFromURLParams } from 'Component/Shared/utils';
import { onBoardingSteps } from 'libs/enums/onboarding-steps.enum';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { workspaceSelect, addNewTab, removeTab } from 'redux/index';
import { useGetWorkspaceBuildStatus } from 'queries/workspace/get-workspace-persona-status';
import { getWorkspacePersonaStatusResponse } from 'queries/workspace/selectors';
import { getWorkspaceBuildStatusByName } from './builstatus.service';
import PersonaVariant from './helper-components/PersonaVariant';
import ProgressComponent from './helper-components/ProgressComponent';
import SourceDisplay from './helper-components/sourcesDisplay';
import {
  DateContainer,
  DetailedDisplayComponent,
  Heading,
  SectionHeading,
  SectionsWrapper,
} from './styles';

const WorkspaceBuildSatusDetails = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const workspaceName = getQueryFromURLParams('workspace');
  const fromStatusPage = getQueryFromURLParams('from_status_page');
  const [isLoading, setIsLoading] = useState(false);
  const [workspaceStatus, setWorkspaceStatus] = useState(null);
  const [isOpenModel, setIsOpenModel] = useState(false);

  const fetchWorkspaceData = async () => {
    setIsLoading(true);
    try {
      const resp = await getWorkspaceBuildStatusByName(workspaceName);
      if (resp?.data) {
        setWorkspaceStatus(resp.data[0]);
        console.log(resp.data[0]);
        setIsLoading(false);
      } else {
        setIsLoading(true);
      }
    } catch {
      setIsLoading(true);
    }
  };

  // useEffect(() => {
  //   fetchWorkspaceData();
  //   if (!fromStatusPage) {
  //     const intervalId = setInterval(() => {
  //       fetchWorkspaceData();
  //     }, 10 * 1000);
  //     return () => {
  //       clearInterval(intervalId);
  //     };
  //   }
  //   return true;
  // }, []);

  const openMRR = () => {
    localStorage.setItem('name', workspaceName);
    localStorage.setItem('project_name', workspaceName);

    // set workspace details in redux-store
    dispatch(
      workspaceSelect({
        name: workspaceName,
        is_trained: true,
        mti: workspaceStatus?.main_topic,
      }),
    );
  };

  const handleCreateNewVariant = () => {
    console.log('w', workspaceStatus.configurations);
    // navigate('/New-Workspace', {
    //   state: {
    //     configurations: {
    //       ...workspaceStatus.configurations,
    //       mti: workspaceStatus?.target_persona,
    //       main_topic: workspaceStatus?.main_topic,
    //     },
    //   },
    // });
    setIsOpenModel(true);
  };

  return (
    <BaseLayout>
      <Flyout
        open={isOpenModel}
        onClose={() => setIsOpenModel(false)}
        title={`Build Persona Variant of ${workspaceName}`}
      >
        <PersonaVariant
          initialData={{
            variantName: workspaceName,
            site: workspaceStatus?.configurations?.site,
            mti: workspaceStatus?.main_topic,
            target: workspaceStatus?.target_persona,
            product_name: workspaceStatus?.configurations?.product_name,
            founder_name: workspaceStatus?.configurations?.founder_name,
            company_name: workspaceStatus?.company_name,
          }}
          configurations={workspaceStatus?.configurations}
        />
      </Flyout>
      <HeadingName>{workspaceName}</HeadingName>
      <HeadingDesc>
        Turn AI-powered listening into actionable insights
      </HeadingDesc>
      <Divider />
      {!workspaceStatus && isLoading && (
        <Flex
          align={'center'}
          justify={'center'}
          style={{
            height: '50vh',
          }}
          gap={'0.1rem'}
          direction={'column'}
        >
          <LoaderSpin width={'100'} />
          <p className="text-center">Athena is researching ...</p>
        </Flex>
      )}

      {workspaceStatus && (
        <Flex
          style={{
            height: '100%',
          }}
          direction={'column'}
          gap={'2rem'}
          className="container"
        >
          <ProgressComponent
            workspaceName={workspaceStatus?.name}
            data={workspaceStatus?.onboarding_status}
            fetchWorkspaceData={fetchWorkspaceData}
            viewMore={false}
            openMRR={openMRR}
            fromStatusPage={fromStatusPage}
          />
          <DetailedDisplayComponent>
            <Flex justify={'space-between'} align={'center'}>
              <Heading>Configurations Details </Heading>
            </Flex>
            <Flex justify="space-between">
              <DateContainer>
                <span className="label">Created On:</span>
                <span>
                  {moment
                    .utc(workspaceStatus?.configurations?.date_created)
                    .local()
                    .format('MMM Do YYYY, h:mm:ss a')}
                </span>
              </DateContainer>
              <ButtonPrimary onClick={handleCreateNewVariant}>
                Create a new variant
              </ButtonPrimary>
            </Flex>
            <SectionsWrapper>
              <Flex direction={'column'} gap={'0.8rem'} flex={1}>
                <SectionHeading>Seed Inputs</SectionHeading>
                <SourceDisplay
                  heading={onBoardingSteps.Attract}
                  sourceData={
                    workspaceStatus?.configurations?.configurations?.level1
                      .sources
                  }
                />
                <SourceDisplay
                  heading={onBoardingSteps.Convert}
                  sourceData={
                    workspaceStatus?.configurations?.configurations?.level2
                      .sources
                  }
                />
                <SourceDisplay
                  heading={onBoardingSteps.Desire}
                  sourceData={
                    workspaceStatus?.configurations?.configurations?.level3
                      .sources
                  }
                />
              </Flex>
              <Flex flex={1} direction={'column'} gap={'0.8rem'}>
                <SectionHeading />
                <SourceDisplay
                  heading={'Main Topic Of Interest'}
                  sourceData={[workspaceStatus?.main_topic]}
                />
                <SourceDisplay
                  heading={'Who do you want to target ?'}
                  sourceData={[workspaceStatus?.target_persona]}
                />
              </Flex>
            </SectionsWrapper>
            <Divider />
            <SectionsWrapper>
              <Flex flex={1} direction={'column'} gap={'0.8rem'}>
                <SectionHeading>Others</SectionHeading>
                <SourceDisplay
                  heading={'Tags'}
                  sourceData={workspaceStatus?.configurations?.tags}
                />
                <SourceDisplay
                  heading={'Language'}
                  sourceData={[workspaceStatus?.configurations?.Lang]}
                />
                <SourceDisplay
                  heading={'Domain'}
                  sourceData={[workspaceStatus?.configurations?.site]}
                />
              </Flex>
              <Flex flex={1} direction={'column'} gap={'0.8rem'}>
                <SectionHeading>Product/Service Details</SectionHeading>
                <SourceDisplay
                  heading={'Founder Name'}
                  sourceData={[workspaceStatus?.configurations?.founder_name]}
                />
                <SourceDisplay
                  heading={'Product Name'}
                  sourceData={[workspaceStatus?.configurations?.product_name]}
                />
              </Flex>
            </SectionsWrapper>
          </DetailedDisplayComponent>
        </Flex>
      )}
    </BaseLayout>
  );
};

export default WorkspaceBuildSatusDetails;
