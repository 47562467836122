import { useGetNews } from 'queries/content/content-tools/get-news';
import React from 'react';
import { useContentContext } from 'context/content/ContentContext';
import { Divider } from 'components/common/divider';
import { NewsLoading } from 'components/skeleton/content/content-tools/news/NewsLoading';
import styles from './news.module.css';

const NewsLogoUrl = 'https://s2.googleusercontent.com/s2/favicons?domain=';

function NewsTab() {
  const x = '';

  const { country, topic } = useContentContext();

  const [Topic] = topic;
  const [Country] = country;
  const { data: newsList, isLoading } = useGetNews(Topic, Country);

  if (isLoading) return (<NewsLoading />);
  return (
    <div className={styles.wrapper}>
      {newsList && newsList.map((news, index) => (
        <>
          {index > 0 && <Divider />}
          <div key={news.link} className={styles.container}>
            <div>
              <img
                className={styles.news_logo}
                src={NewsLogoUrl + news.link}
                alt={news.description}
              />
              <span className={styles.news_date}>{news.date.slice(0, -13)}</span>
            </div>

            <p className={styles.news_title}>
              <a
                href={news.link}
                target="_blank"
                rel="noreferrer"
              >{news.title}
              </a>
            </p>
          </div>
        </>

      ))}
    </div>
  );
}

export default NewsTab;
