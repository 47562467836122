/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import { Select } from '@mui/material';
import React, { useState, useEffect } from 'react';
import setGoogleLanguageCookie from '../Cookie_custom';
import languages from './AllLanguages.json';

function LangTrans({ language, handleLanguageChange }) {
  const [lang, setLang] = useState(language);
  useEffect(() => {
    const scriptElement = document.createElement('script');
    scriptElement.type = 'text/javascript';
    scriptElement.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit  ';
    document.head.appendChild(scriptElement);
    return () => { };
  }, []);
  const handleSelectLanguage = (e) => {
    handleLanguageChange(e.target.value);
  };
  return (
    <>
      <div id="google_translate_element" style={{ display: 'none' }} />
      <small className="new-workspace-input-wrapper">
        <p
          style={{
            marginTop: '1rem',
          }}
        >
          Language
        </p>
      </small>
      <div className="d-flex flex-column notranslate">
        {/* <b>Select Language</b> */}
        <Select
          native
          onChange={handleSelectLanguage}
          value={language}
          style={{ padding: '0.1rem 0.4rem' }}
          variant="outlined"
        // disabled
        >
          {languages.map((language, index) => (
            <option key={index} value={language.code}>
              {language.language}
            </option>
          ))}
        </Select>
      </div>
    </>
  );
}

export function SelectLanguageEngine({
  engine,
  handleEngineChange,
  currentLanguage,
}) {
  const handleSelectLanguageEngine = (e) => {
    handleEngineChange(e.target.value);
  };

  const deepL_support_lang = [
    'bg',
    'cs',
    'da',
    'de',
    'el',
    'en',
    'es',
    'et',
    'fi',
    'fr',
    'hu',
    'it',
    'ja',
    'lt',
    'lv',
    'nl',
    'pl',
    'pt',
    'ro',
    'ru',
    'sk',
    'sl',
    'sv',
    'zh',
  ];

  return (
    <>
      <small className="new-workspace-input-wrapper">
        <p
          style={{
            marginTop: '1rem',
          }}
        >
          Language Engine
        </p>
      </small>
      <div className="d-flex flex-column notranslate">
        <Select
          native
          onChange={handleSelectLanguageEngine}
          value={engine}
          style={{
            padding: '0.1rem 0.4rem',
            marginTop: '0.1rem',
          }}
          variant="outlined"
        >
          <option value={0}>Google</option>
          {deepL_support_lang.includes(currentLanguage) && (
            <option value={1}>DeepL</option>
          )}
        </Select>
      </div>
    </>
  );
}

export default LangTrans;
