import { baseAPIsUrl } from 'Config/Api';
import createInstance from 'Config/AxiosConfig';
import axios from 'axios';
import { store } from 'redux/store';
import { addOnCheckout } from 'utils/get-add-on-checkout';

async function updagradeChatAthenaToPro() {
  const plans = ['GPT4 AddOn'];

  await addOnCheckout(plans);
}

async function saveAppIntegrationCreds(creds, data) {
  const state = store.getState();
  const active_workspace_name = state.workspace?.name;

  const instance = await createInstance();
  const body = {
    name: active_workspace_name,
    tool_name: data.toolName,
    display_name: data.displayName,
    credentials: creds,
  };
  await instance.post('save-user-tool-credentials', body);
}

async function deleteAppIntegrationCreds(toolId) {
  const instance = await createInstance();
  const body = {
    tool_id: toolId,
  };
  await instance.post('delete-user-tool-credentials', body);
}

async function getViewAndPayloadFormat(toolName) {
  const { data } = await axios.get(
    `${baseAPIsUrl}/get-payload-formats?tool_name=${toolName}`,
  );
  if (data.statusCode === '200') {
    return data.data[0];
  }
  return null;
}

export {
  saveAppIntegrationCreds,
  getViewAndPayloadFormat,
  deleteAppIntegrationCreds,
  updagradeChatAthenaToPro,
};
