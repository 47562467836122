import { useQuery } from 'react-query';
import { getReduxUserState } from 'utils/helpers/redux-selectors';
import createInstance from 'Config/AxiosConfig';

const getMembers = async () => {
  const instance = await createInstance();

  const { email } = getReduxUserState();

  const { data } = await instance.get(
    `/dashboard-teammembers?username=${email}`,
  );

  if (data?.statusCode === '200') return data?.data;

  throw new Error('Unable to fetch Team Members');
};

// Keep Query Value as File Name to maintain consistency and uniqueness in query index
const QUERY_KEY = 'get-team-members';

// QUERY
export const useGetTeamMembers = () => useQuery(QUERY_KEY, getMembers);
