/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import React from 'react';
import './levelButtons.css';
import lockIcon from 'Component/../assets/dashboard-imgs/locked.png';
import unlock from '../../../../assets/MarketResearchImages/unlocked-mrr.svg';

import ButtonNext from '../../../Shared/ButtonNext';

const LEVEL_NAME_MAP_TO_NUMBER = {
  1: 'Top of the Funnel',
  2: 'Middle of the Funnel',
  3: 'Bottom of the Funnel',
};

function LevelButtons({
  level, onClick, desc, isUnlocked, showLoader,
}) {
  return (
    <div className="level-container">
      <div className="level-no">
        <img src={isUnlocked ? unlock : lockIcon} />
        <h3 style={{ marginLeft: '20px' }}>
          {LEVEL_NAME_MAP_TO_NUMBER[level]}
        </h3>
      </div>
      <div
        className="level-desc"
        dangerouslySetInnerHTML={{ __html: desc }}
      />
      <div className="level-btn">
        {isUnlocked ? (
          <ButtonNext
            onClick={onClick}
            className="w-100"
            buttonName="Explore"
            showLoader={showLoader}
          />
        ) : (
          <button className="level-disable-button">
            <span>Explore</span>
            <img
              src="/img/circular-arrow-right.svg"
              className="img-fluid ml-2"
              width={18}
            />
          </button>
        )}
      </div>
    </div>
  );
}

export default LevelButtons;
