import { useMutation, useQueryClient } from 'react-query';
import createInstance from 'Config/AxiosConfig';
import { useIsUserAnonymus } from 'hooks/useIsUserAnonymus';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ROUTES } from 'constants/routes';
import { toast } from 'react-toastify';
import { GET_CREDITS_QUERY_KEY } from './get-credits';
import { GET_REMAINING_CREDITS_KEY } from './chat-athena/get-remaining-credits';

const WARNING_CREDITS_LIMIT = 500;
const CREDIT_LIMIT_EXCEED_MESSAGE = 'Limit Exceeded';

// MUTATION
export const useMutateCredits = () => {
  const queryClient = useQueryClient();
  const { isUserAnonymus } = useIsUserAnonymus();
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user);
  const chargeCredits = async (cost) => {
    const payload = {
      amount: parseInt(cost, 10),
      check: false, // will deduct credits
    };

    const instance = await createInstance();

    const response = await instance.post('charge-credits', payload);
    const totalCredits = response?.data?.total_credits || 0;
    const creditsConsumed = response?.data?.credits || 0;
    const creditsLeft = totalCredits - creditsConsumed;

    if (response.data.statusCode !== '200') {
      throw new Error('Error in charging credits');
    }

    if (creditsLeft <= WARNING_CREDITS_LIMIT) {
      throw new Error(
        'Your credits will expire soon, you will be redirected to pricing page to upgrade the plan',
      );
    }

    if (response?.data?.message === CREDIT_LIMIT_EXCEED_MESSAGE) {
      if (isUserAnonymus) {
        navigate(ROUTES.PRICING, {
          state: {
            email: user.email,
            name: user.name,
            old_user: true,
            origin: 'chat-athena',
          },
        });
      }

      throw new Error(
        'You have exceed you credit limit, please subscribe to paid plan',
      );
    }
  };

  // Mutation Config Options
  const mutationOptions = {
    retry: false,
    onError: (error) => {
      toast.error(error.message, {
        position: 'bottom-center',
        theme: 'dark',
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries([GET_CREDITS_QUERY_KEY, GET_REMAINING_CREDITS_KEY]);
    },
  };

  return useMutation(chargeCredits, mutationOptions);
};
