import React from 'react';
import 'assets/constants.css';
import { ToastContainer, toast } from 'react-toastify';

const style = {
  display: 'flex',
  justifyContent: 'flex-end',
  fontSize: '12px',
  color: 'rgb(131, 131, 131)',
  marginBottom: '4px',
  marginTop: '6px',
  cursor: 'pointer',
};

export const handleCopy = (contentId) => {
  const content = document.getElementById(contentId).innerText;

  navigator.clipboard.writeText(content);
};

function CopyText({ contentId }) {
  const handleCopy = () => {
    const content = document.getElementById(contentId).innerText;
    navigator.clipboard.writeText(content);
    toast.success('Content Copied', { position: 'bottom-center', theme: 'dark' });
    // const iframe = document.querySelector('#texteditor_ifr');
    // const editorContent = iframe.contentWindow.document.getElementById('tinymce');
    // editorContent.focus();

    // editorContent.innerHTML += `\n${content}`;
  };
  return (
    <>
      <ToastContainer />
      <span style={style} onClick={handleCopy}>Click here to copy</span>
    </>
  );
}

export default CopyText;
