import CloseRounded from '@mui/icons-material/CloseRounded';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import React from 'react';
import DropdownCustom from './DropdownCustom';
import MultipleSelectDropdown from './MultiSelectDropdown';
import TextPromptInput from './TextPropmtTitleInput';

import {
  ActionWrapper,
  CheckboxWrapper,
  FormGroupWrapper,
  Input,
  SectionWrapper,
} from '../CreateTemplate/styles';

import styles from '../CreateTemplate/create-templates.module.css';

export default function SectionForm({
  data,
  index,
  sectionData,
  setSectionData,
  setIsError,
}) {
  const handleTextPromptChange = (e, index) => {
    const tempData = [...sectionData];
    tempData[index].template_text_prompt = e.target.value;
    setSectionData([...tempData]);
    setIsError(false);
  };

  const handlePromtChange = (index) => {
    const tempData = [...sectionData];
    tempData[index].show_prompt = !tempData[index].show_prompt;
    setSectionData([...tempData]);
    setIsError(false);
  };

  const handleMultipleDropDownChange = (dropDownData, index) => {
    const tempData = [...sectionData];
    tempData[index].sources = [...dropDownData];
    setSectionData([...tempData]);
    setIsError(false);
  };

  const handleMethodChange = (val, index) => {
    const tempData = [...sectionData];
    tempData[index].method = val;
    setSectionData([...tempData]);
  };

  const handleDuplicateSection = (index) => {
    const tempData = [...sectionData];
    setSectionData([...tempData, { ...tempData[index] }]);
    setIsError(false);
  };

  const handleRemoveSection = (index) => {
    const tempData = [...sectionData];
    if (tempData.length === 1) return;
    tempData.splice(index, 1);
    setSectionData([...tempData]);
    setIsError(false);
  };

  return (
    <SectionWrapper>
      <FormGroupWrapper>
        <TextPromptInput
          defaultValue={data.template_text_prompt}
          index={index}
          updatePrompt={handleTextPromptChange}
        />
        {/* <CheckboxWrapper>
          <Input
            type="checkbox"
            style={{
              height: '13px',
            }}
            checked={data.show_prompt}
            onClick={() => handlePromtChange(index)}
          />
          <label className="global-f-bold mb-0 mx-2">Show Prompt</label>
        </CheckboxWrapper>
        <div className={styles.multiple_dropdown_wrapper}>
          <MultipleSelectDropdown
            value={data.sources}
            setData={handleMultipleDropDownChange}
            index={index}
          />
        </div>
        <DropdownCustom
          value={data.method}
          updateValue={(val) => handleMethodChange(val, index)}
        /> */}
        <ActionWrapper>
          <FileCopyOutlinedIcon onClick={() => handleDuplicateSection(index)} />
          <CloseRounded onClick={() => handleRemoveSection(index)} />
        </ActionWrapper>
      </FormGroupWrapper>
    </SectionWrapper>
  );
}
