const minQueAnswered = (currentQueCount) => {
  if (currentQueCount > 10 && currentQueCount < 15) {
    return Math.round((currentQueCount * 20) / 100);
  }
  if (currentQueCount > 15 && currentQueCount < 20) {
    return Math.round((currentQueCount * 15) / 100);
  }
  if (currentQueCount > 20) {
    return 4;
  }
  return Math.round((currentQueCount * 40) / 100);
};
export default minQueAnswered;
