/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import LoaderSpin from '../../../../Shared/Loader';
import './News.css';
import { getTranslatedTextEnglish } from '../../../../Shared/TranshelperStatic';
import createInstance from 'Config/AxiosConfig';
import NoDataAvailable from 'Component/Shared/NoDataResultSection';

function NewsTab(props) {
  useEffect(() => {
    fetchNewsApi();
  }, []);

  const contextTopic = localStorage.getItem('context');
  const contextCountry = props.country;
  const [NewsResult, setNewsResult] = useState();
  const [logoUrl, setlogoUrl] = useState(
    'https://s2.googleusercontent.com/s2/favicons?domain=',
  );
  const [notFound, setNotFound] = useState(false);

  const fetchNewsApi = async () => {
    const body = {
      term: await getTranslatedTextEnglish(contextTopic),
      country: contextCountry,
    };
    const instance = await createInstance();
    const result = await instance.post('get-news', body);

    if (result.data.statusCode !== '200' || result.data.news.length === 0) {
      setNotFound(true);
    }
    setNewsResult(result.data.news);
  };

  function NewsCard() {
    return !NewsResult ? (
      <LoaderSpin width="20" height="20" />
    ) : (
      NewsResult.map((news) => (
        <div className="news-container">
          <img src={logoUrl + news.link} />
          <p dangerouslySetInnerHTML={{ __html: news.description }} />
        </div>
      ))
    );
  }

  return notFound ? <NoDataAvailable /> : <NewsCard />;
}

export default NewsTab;
