/* eslint-disable max-len */
import createInstance from 'Config/AxiosConfig';
import { useQuery } from 'react-query';

export const getNews = async (user_term, user_country) => {
  const payload = {
    term: user_term,
    country: user_country,
  };

  const instance = await createInstance();
  const { data, status } = await instance.post('get-news', payload);

  if (data.statusCode === '200' || data.news.length !== 0) {
    return data.news;
  }

  throw new Error('Unable to fetch News related to Main Topic Of Interest');
};

// Keep Query Value as File Name to maintain consistency and uniqueness in query index
const QUERY_KEY = 'get-news';

// Query Config Options
const queryOptions = {
  staleTime: 10000,
};

// QUERY
export const useGetNews = (user_term, user_country) =>
  useQuery([QUERY_KEY, user_term, user_country], () => getNews(user_term, user_country), queryOptions);
