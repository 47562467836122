import React from 'react';
import chatGPTIcon from 'Component/../assets/images/chatgpt.png';
import { ButtonSecondary } from '../GlobalStyles';
import { ChatGPTButtonWrapper } from './style';

const ChatGPTLink = 'https://chat.openai.com/chat';

const ChatGPTButton = () => (
  <ChatGPTButtonWrapper align="flex-end">
    {/* <ButtonSecondary
      onClick={() => {
        window.open(ChatGPTLink, 'popup', 'width=600,height=600');
      }}
    >
      <img src={chatGPTIcon} alt="chat-gpt" />
    </ButtonSecondary> */}
  </ChatGPTButtonWrapper>
);

export default ChatGPTButton;
