import { useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetUsersAddOns } from 'queries/user-account/services/get-user-addons';
import { isPremiumPlan } from 'libs/enums/premiumPlans.enum';
import { PlanType } from 'libs/enums/planType.enum';
import { saveConfigurations } from '../../services/chat-athena-configurations';
import { SelectedModelContext } from '../../contexts/SelectedModel';
import { SelectedImageUploadContext } from '../../contexts/SelectedImageUpload';
import { updagradeChatAthenaToPro } from '../model-selector.service';

export const useModelSelectorController = () => {
  const { setModelName, setImageModelName } = useContext(SelectedModelContext);
  const { setUploadedImages } = useContext(SelectedImageUploadContext);
  const user = useSelector((state) => state?.user);
  const { data: addons, isLoading: isLoadingPlans } = useGetUsersAddOns();
  const [loadingId, setLoadingId] = useState(-1);

  const isProActivated = useMemo(() => {
    if (isPremiumPlan(user?.plan)) return true;
    if (isLoadingPlans) return false;
    if (addons[PlanType.chatAthena]?.status) return true;
    if (addons[PlanType.chatAthenaYearly]?.status) return true;
    return false;
  }, [addons]);

  const handleModelSelect = (modelName) => {
    setModelName(modelName);
    setUploadedImages([]);
    saveConfigurations({ modelName });
  };

  const handleImageModelSelect = (imgModelName) => {
    setImageModelName(imgModelName);
    saveConfigurations({ modelName: imgModelName });
  };

  const handleUpgrade = async (loaderId, modelName) => {
    setLoadingId(loaderId);
    await updagradeChatAthenaToPro();
    setLoadingId(false);
    handleModelSelect(modelName);
  };

  return {
    isProActivated,
    handleModelSelect,
    handleImageModelSelect,
    handleUpgrade,
    loadingId,
  };
};
