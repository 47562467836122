import { useQuery } from 'react-query';
import axios from 'axios';
import { baseAPIsUrl } from 'Config/Api';

const QUERY_KEY = 'get-options-for-brand-voice';

const queryOptions = {
  staleTime: 30 * 60 * 1000,
};

const getOptionsForBrandVoice = async () => {
  const response = await axios.get(`${baseAPIsUrl}/get-brand-voice-options`);
  if (response.data.statusCode !== '200') {
    throw new Error('Failed to fetch brand voice options');
  }
  return response.data.result;
};

export const useGetOptionsForBrandVoice = () =>
  useQuery(QUERY_KEY, getOptionsForBrandVoice, queryOptions);
