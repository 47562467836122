import React, { createContext, useContext, useMemo, useState } from 'react';

export const AssistantsContext = createContext();

export const AssistantsProvider = ({ children }) => (
  <AssistantsContext.Provider value={''}>{children}</AssistantsContext.Provider>
);

export const useAssistantContext = () => {
  const context = useContext(AssistantsContext);

  if (context === undefined) {
    throw new Error(
      'useAssistantContext must be used within a Assistant Provider',
    );
  }

  return context;
};
