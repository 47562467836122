/* eslint-disable indent */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-multiple-empty-lines */
/* eslint-disable no-trailing-spaces */
/* eslint-disable import/order */
/* eslint-disable max-len */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable no-plusplus */
/* eslint-disable react/button-has-type */
/* eslint-disable no-return-assign */
/* eslint-disable no-use-before-define */

import { Button, Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { baseAPIsUrl } from 'Config/Api';
import AthenaLogo from 'assets/images/Athena3.png';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { store } from 'redux/store';
import FirstScreen from './FirstScreen';
import SecondScreen from './SecondScreen';
import ThirdScreen from './ThirdScreen';
import createInstance from 'Config/AxiosConfig.js';
import PreparingWorkspacesScreen from './PreparingWorkspacesScreen';
import SecondScreenBis from './SecondScreenBis';
import ThirdScreenBis from './ThirdScreenBis';
import ThirdScreenTis from './ThirdScreenTis';
import './style.css';

const getUserFromRedux = () => {
  const state = store.getState();
  return state.user;
};

const StarterFlowManager = (props) => {
  const [index, setIndex] = useState(0);
  const [data, setData] = useState({
    targetCountry: 'US',
    city: 'New York',
    language: 'US',
    languageCode: 'en',
    translationEngine: 'Google',
    founderName: getUserFromRedux()?.name,
  });
  const [loading, setLoading] = useState(false);
  const { setStarterFlow, isStarterFlow } = props;

  const [growZoneOptions, setGrowZoneOptions] = useState([
    'Public Speaking',
    'Social Media Marketing',
    'Leadership Development',
    'Content Creation',
    'Networking Skills',
    'Time Management',
    'Negotiation Skills',
    'Project Management',
    'Productivity Hacks',
    'Emotional Intelligence',
  ]);
  const [brandsNameOptions, setBrandsNameOptions] = useState([
    "Your Brand's Catchy Name",
    'The Creative Connector',
    'The Strategic Visionary',
    'The Digital Dynamo',
    'The Thought Leader',
    'The Networking Ninja',
    'The Productivity Powerhouse',
    'The Empathetic Leader',
    'The Innovation Instigator',
    'The Mindful Marketer',
    'The Entrepreneurial Enthusiast',
  ]);
  const [keyInterestOptions, setKeyInterestOptions] = useState([
    'Personal Development',
    'Technology and Innovation',
    'Marketing and Advertising',
    'Leadership and Management',
    'Health and Wellness',
    'Finance and Investment',
    'Entrepreneurship',
    'Travel and Lifestyle',
    'Education and Learning',
    'Sustainability and Environment',
  ]);
  const [networkingGoalOptions, setNetworkingGoalOptions] = useState([
    'Build a strong professional network',
    'Enhance industry knowledge and stay updated',
    'Find mentors and advisors',
    'Discover new business opportunities',
    'Collaborate on projects and initiatives',
    'Gain exposure to potential clients or customers',
    'Develop strategic partnerships and alliances',
    'Improve personal branding and online presence',
    'Learn and share best practices',
    'Foster long-term relationships for mutual growth',
  ]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const data = await getAllWorkspaceNames();
      const existedWorkspaces = data.data;
      const newGrowZoneOptions = subtractArrays(
        growZoneOptions,
        existedWorkspaces,
      );
      setGrowZoneOptions(newGrowZoneOptions);
      setLoading(false);
    })();
  }, []);

  const subtractArrays = (arr1, arr2) => {
    const result = [];

    const set2 = new Set(arr2);

    for (let i = 0; i < arr1.length; i++) {
      const element = arr1[i];

      if (!set2.has(element)) {
        result.push(element);
      }
    }

    return result;
  };

  const getAllWorkspaceNames = async () => {
    const username = getUserFromRedux()?.username;
    const instance = await createInstance();
    const { data } = await instance.get(
      `${baseAPIsUrl}/get-projects-names?username=${username}`,
    );
    return data;
  };

  const changeIndex = (direction) => {
    if (direction === 'LEFT') {
      if (index !== 0) {
        setIndex(index - 1);
      }
    } else if (direction === 'RIGHT') {
      if (index !== 3) {
        setIndex(index + 1);
      }
    }
  };

  return (
    <>
      {' '}
      {loading && <PreparingWorkspacesScreen />}
      {!loading && (
        <div>
          {index === 0 && (
            <FirstScreen
              data={data}
              setData={setData}
              index={index}
              changeIndex={changeIndex}
              setStarterFlow={setStarterFlow}
              isStarterFlow={isStarterFlow}
            />
          )}
          {index === 1 && (
            <SecondScreen
              data={data}
              setData={setData}
              index={index}
              changeIndex={changeIndex}
              growZoneOptions={growZoneOptions}
              brandsNameOptions={brandsNameOptions}
              keyInterestOptions={keyInterestOptions}
              networkingGoalOptions={networkingGoalOptions}
              setIndex={setIndex}
            />
          )}
          {index === 2 && (
            <SecondScreenBis
              data={data}
              setData={setData}
              index={index}
              changeIndex={changeIndex}
              growZoneOptions={growZoneOptions}
              brandsNameOptions={brandsNameOptions}
              keyInterestOptions={keyInterestOptions}
              networkingGoalOptions={networkingGoalOptions}
              setIndex={setIndex}
            />
          )}
          {index === 3 && (
            <ThirdScreen
              data={data}
              setData={setData}
              index={index}
              changeIndex={changeIndex}
              setStarterFlow={setStarterFlow}
              isStarterFlow={isStarterFlow}
              setIndex={setIndex}
            />
          )}
          {index === 4 && (
            <ThirdScreenBis
              data={data}
              setData={setData}
              index={index}
              changeIndex={changeIndex}
              setStarterFlow={setStarterFlow}
              isStarterFlow={isStarterFlow}
              setIndex={setIndex}
            />
          )}
          {index === 5 && (
            <ThirdScreenTis
              data={data}
              setData={setData}
              index={index}
              changeIndex={changeIndex}
              setStarterFlow={setStarterFlow}
              setIndex={setIndex}
            />
          )}
        </div>
      )}
    </>
  );
};

export default StarterFlowManager;
