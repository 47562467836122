/* eslint-disable no-case-declarations */
/* eslint-disable default-param-last */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
import { SET_ACTIVE_TAB, ADD_NEW_TAB, REMOVE_SELECTED_TAB, SET_TABS_EMPTY } from './TabManagerTypes';

const initialState = {
  tabs: [{ id: 1, name: 'Home', path: '/home' }],
  currentTab: { id: 1, name: 'Home', path: '/home', blog_name: '', blog_id: '', from: '' },
};

const TabManager = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_NEW_TAB:
      return {
        ...state,
        tabs: [...state.tabs, payload],
        currentTab: payload,
      };

    case SET_ACTIVE_TAB:
      return {
        ...state,
        currentTab: payload,
      };

    case REMOVE_SELECTED_TAB:
      const filteredTabs = state.tabs.filter((tab) => tab.id !== payload);

      return {
        ...state,
        tabs: filteredTabs,
        currentTab: state.tabs[0],
      };

    case SET_TABS_EMPTY:
      return initialState;

    default:
      return state;
  }
};

export default TabManager;
