import { useGetConnectedFivetranApps } from 'queries/chat-athena/fivetran/get-connected-apps';
import React, { useState } from 'react';
import SearchInput from 'components/common/SearchInput';
import { useInsightsContext } from 'context/assistants/InsightsContext';
import { InsightsAppConnectorLoader } from 'components/skeleton/assistant/insights-app-connector-loader';
import styles from './app-connectors.module.css';

function AppConnectors() {
  const {
    data: connectedFiveTranApps,
    isLoading,
  } = useGetConnectedFivetranApps();

  const { useSelectedConnectorInfo } = useInsightsContext();
  const { setSelectedConnectorInfo } = useSelectedConnectorInfo;

  const [SearchKey, setSearchKey] = useState('');

  const handleSelectedApp = (connectedFiveTranApp) => {
    setSelectedConnectorInfo({
      dataSetId: connectedFiveTranApp?.dataset_id,
      connectorName: connectedFiveTranApp?.display_name,
    });
  };

  if (isLoading) {
    return (
      <div className={styles.container}>
        <SearchInput
          value={SearchKey}
          onChange={(event) => setSearchKey(event.target.value)}
        />
        <InsightsAppConnectorLoader />
      </div>
    );
  }
  return (
    <div className={styles.container}>
      <SearchInput
        value={SearchKey}
        onChange={(event) => setSearchKey(event.target.value)}
      />
      <div className={styles.apps_container}>
        {connectedFiveTranApps
          ?.filter((connectedFiveTranApp) =>
            connectedFiveTranApp?.display_name
              ?.toLowerCase()
              .includes(SearchKey?.toLowerCase()),
          )
          .map((connectedFiveTranApp) => (
            <div className={styles.app_container}>
              <div className={styles.app_info}>
                <div className={styles.app_name}>
                  {connectedFiveTranApp?.display_name}
                </div>
                <div className={styles.app_service}>
                  {connectedFiveTranApp?.service}
                </div>
              </div>
              <div className={styles.button_container}>
                {connectedFiveTranApp?.status === 'sync_end' ? (
                  <div className={styles.green_circle} />
                ) : (
                  <div className={styles.red_circle} />
                )}
                {connectedFiveTranApp?.status === 'sync_end' ? (
                  <button
                    className={styles.select_button}
                    type="button"
                    onClick={() => handleSelectedApp(connectedFiveTranApp)}
                  >
                    Select
                  </button>
                ) : (
                  <button className={styles.disabled_button} type="button">
                    Unsynced
                  </button>
                )}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default AppConnectors;
