/* eslint-disable no-await-in-loop */
import { sleep } from 'Component/Shared/utils';
import { devAPIUrl } from 'Config/Api';
import createInstance from 'Config/AxiosConfig.js';
import { store } from 'redux/store';
import {
  getReduxUserState,
  getReduxWorkspaceState,
} from 'utils/helpers/redux-selectors';

async function callApi(index) {
  const state = store.getState();
  const active_workspace = state?.workspace?.name;
  const user = state?.user;
  try {
    const instance = await createInstance();
    const result = await instance.get(
      `/get-chat-history?name=${active_workspace}&username=${user?.username}&page_num=${index}&page_size=1`,
    );
    const pair = result.data?.answer?.pairs[0];
    if (pair?.speech_file_url) {
      return pair?.speech_file_url;
    }
    return null;
  } catch (err) {
    return null;
  }
}

export async function getUpdatedAudioURL(index) {
  let retry = 0;

  let url = await callApi(index);

  while (!url || retry < 15) {
    await sleep(2 * 1000);
    url = await callApi(index);
    retry += 1;
  }
}

export async function getSpeechUrlFromText(index) {
  const state = store.getState();
  const active_workspace = state?.workspace?.name;
  try {
    const instance = await createInstance();
    const { data } = await instance.post('/text-to-speech', {
      name: active_workspace,
      index,
      voice: 'alloy',
    });

    if (data.statusCode === '200') {
      return data.result.speech_file_url;
    }
    const url = getUpdatedAudioURL(index);

    return url;
  } catch (error) {
    console.log(error);
    const url = getUpdatedAudioURL(index);

    return url;
  }
}

export async function generateShareUrl(
  question,
  answer,
  image_id = '',
  speech_file_id = '',
) {
  const user = getReduxUserState();
  const workspace = getReduxWorkspaceState();

  try {
    const processedAnswer = `<br></br><br></br>${answer}`;

    const body = {
      username: user?.username,
      name: workspace.name,
      text: processedAnswer,
      blog_name: question,
      main_topic: workspace.mti,
      image_id,
      speech_file_id,
    };
    const instance = await createInstance();
    const result = await instance.post(
      `${devAPIUrl}/save-and-publish-blog`,
      body,
    );

    if (result.status === 200) {
      const blogId = result?.data?.body?.blog_id;
      const currentUrl = window.location.origin;
      window.open(`${currentUrl}/blog?id=${blogId}`, '_blank');
    }
  } catch (e) {
    console.log(e);
  }
}
