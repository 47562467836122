/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import { useSelector } from 'react-redux';
import { values } from 'Component/Shared/VideoTutorials/VideoDetails';

const useStyles = makeStyles(() => ({
  formControl: {
    margin: '4px',
    width: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: '16px',
  },
}));

const ITEM_HEIGHT = 40;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5,
      width: 300,
    },
  },
};

export default function MultiSelectEmails({ team_members, setData }) {
  const classes = useStyles();
  const [selectedFeilds, setSelectedFeilds] = useState([]);
  const handleChange = (event) => {
    const newValues = event.target.value;
    setData([...newValues]);
    setSelectedFeilds([...newValues]);
  };

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-mutiple-checkbox-label">Select Team Members </InputLabel>
        <Select
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          multiple
          value={selectedFeilds}
          onChange={handleChange}
          input={<Input />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {(team_members || {}).map((option, _index) => (
            <MenuItem key={option.name + _index} value={option.email}>
              <Checkbox checked={selectedFeilds.indexOf(option.email) > -1} />
              <ListItemText primary={option.email} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
