export const STATUS = {
  COMPLETE: 'complete',
  INPROGRESS: 'in_progress',
  FAILED: 'failed',
  PENDING: 'pending',
};

export const MARKET_CONFIG_TERMS = {
  mrr: 'Market Research',
  mrr_insight: 'Market Insights',
  script: 'Sales Copywriting',
  serp: 'Google',
  quora: 'Quora',
  reddit: 'Reddit',
  website: 'URL',
};
