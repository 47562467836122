/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

function NavFeatureItems({ feature, url, isActive }) {
  const navigate = useNavigate();

  return (
    <span
      onClick={() => navigate(`${url}`, { replace: true })}
      className={`font-accent ${
        isActive ? 'mrr-navfeature-active' : 'mrr-navfeature'
      }`}
    >
      {feature}
    </span>
  );
}

function InnerTopnav() {
  const [currentLocation, setCurrentLocation] = useState('');

  const getActiveClassName = (currentTab) =>
    (currentTab === currentLocation ? 'aw-nav-item-active' : '');

  useEffect(() => {
    setCurrentLocation(window.location.pathname);
  }, []);

  return (
    <div className="aw-nav-container">
      <div className="aw-heading">
        <div className="font-secondary">Market Message</div>
      </div>
      <div className="aw-nav-items">
        <div className={`aw-sub-head ${getActiveClassName('/create-drafts')}`}>
          <NavFeatureItems
            feature="Generate Blogs"
            url="/create-drafts"
            isActive={currentLocation === '/create-drafts'}
          />
        </div>
        <div
          className={`aw-sub-head ${getActiveClassName(
            '/SalesCopyWritingWizard',
          )}`}
        >
          <NavFeatureItems
            feature="Sales Copywriter"
            url="/SalesCopyWritingWizard"
            isActive={currentLocation === '/SalesCopyWritingWizard'}
          />
        </div>
      </div>
      <div className="heading-options">
        <Link to="/MRR-Get-Started">Market Research</Link>
      </div>
    </div>
  );
}

export default InnerTopnav;
