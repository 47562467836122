/* eslint-disable max-len */
import React, { useState } from 'react';
import { useContentContext } from 'context/content/ContentContext';
import { useGetQuestions } from 'queries/content/content-tools/get-questions';
import { ScaffoldCard } from 'components/common/scaffold-card';
import { QuestionLoading } from 'components/skeleton/content/content-tools/question/QuestionsLoading';
import QuestionAnswer from './QuestionAnswers';
import styles from './questions.module.css';

function Questions() {
  const { topic } = useContentContext();

  const [Topic] = topic;
  const { data: questionsData, isLoading } = useGetQuestions(Topic);

  if (isLoading) return <QuestionLoading />;

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        { questionsData && questionsData.map((question) => (
          <ScaffoldCard className={styles.content_container}>
            <QuestionAnswer question={question} />
          </ScaffoldCard>
        ))}
      </div>
    </div>
  );
}

export default Questions;
