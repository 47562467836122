import React from 'react';
import AppHeaderWrapper from 'components/common/AppHeader/AppHeaderWrapper';
import TeamMemberOld from 'Component/Pages/TeamMember/TeamMember';
import styles from './index.module.css';

function TeamMember() {
  return (
    <AppHeaderWrapper className={styles.team_member_wrapper}>
      <div className={styles.team_member_container}>
        <div className={styles.team_member_content_container}>
          {/* TODO: // Refactor following component and replace */}
          <TeamMemberOld />
        </div>
      </div>
    </AppHeaderWrapper>
  );
}

export default TeamMember;
