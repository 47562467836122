import { ScaffoldCard } from 'components/common/scaffold-card';
import React from 'react';
import { LoadingPlaceholder } from '../LoadingPlaceholder';

export const InsightsAppConnectorLoader = () => (
  <div>
    {Array(5)
      .fill(null)
      .map((val, index) => (
        <ScaffoldCard className="h-margin-b-small h-margin-t-large" key={index}>
          <LoadingPlaceholder height="35px" />
        </ScaffoldCard>
      ))}
  </div>
);
