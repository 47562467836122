/* eslint-disable max-len */
import { PublishPanelInfos } from 'constants/main-menu';
import React from 'react';
import './styles.css';

// assets
import LaunchIcon from '@mui/icons-material/Launch';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addNewTab } from 'redux/index';

function PublishPanel() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tabs = useSelector((state) => state?.tabManager?.tabs);

  const handleOpen = (publishInfo) => {
    dispatch(addNewTab({ id: (tabs.length) + 1,
      name: publishInfo?.feature_name,
      path: publishInfo?.feature_path },
    ));

    navigate(`${publishInfo?.feature_path}`);
  };
  return (
    <div className="feature-detail-list">
      { PublishPanelInfos.map((publishPanelInfo) => (
        <div className="feature-detail" key={publishPanelInfo.feature_name}>
          <div className="produce-panel-features">
            <div className="produce-panel-feaure-name" onClick={() => handleOpen(publishPanelInfo)}>
              <span>{publishPanelInfo.feature_name}</span>
              <LaunchIcon style={{ width: '14px', color: '#2a9df5', marginRight: '0.25rem', cursor: 'pointer' }} />
            </div>

          </div>
        </div>
      ))}
    </div>
  );
}
export default PublishPanel;
