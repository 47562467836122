/* eslint-disable consistent-return */
import { useMutation, useQueryClient } from 'react-query';
import createInstance from 'Config/AxiosConfig';
import {
  CHATATHENA_QUERY_CREDIT_COST,
  ChatAthenaModelNameEnum,
} from 'libs/chat-athena-constants';
import { useMutateCredits } from 'queries/mutate-credits';
import { getReduxWorkspaceState } from 'utils/helpers/redux-selectors';
import { useIsUserAnonymus } from 'hooks/useIsUserAnonymus';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { CHAT_HISTORY_QUERY_KEY } from './get-chat-history';
import { useMutateBlog } from './mutate-publish-blog';
import { useMutateAthenaTraining } from './mutate-athena-training';

const TIME_OUT_ERROR = 'Time Out';

export function useMutateChatHistory() {
  const { isUserAnonymus } = useIsUserAnonymus();
  const queryClient = useQueryClient();
  const { mutateAsync: deductCredits } = useMutateCredits();
  const { mutateAsync: publishAsBlog } = useMutateBlog();
  const { mutate: trainAthena } = useMutateAthenaTraining();

  const workspace = useSelector((state) => state?.workspace);
  const workspaceName = workspace?.name;

  async function sendMessage(requestData) {
    try {
      const instance = await createInstance();

      const result = await instance.post('chat-athena-bot', requestData);

      if (result.data.statusCode !== '200') {
        throw new Error('Something went wrong! Cannot Generate Response');
      }

      const resultData = result?.data?.data;
      const answer = {
        question: requestData.question,
        answer: resultData?.answer || '',
        images: resultData?.images,
        created_at: resultData?.created_at || '',
        rating_scale: resultData?.rating_scale || '',
        input_media: resultData?.input_media,
        speech_file_url: resultData?.speech_file_url,
        avatar_props: resultData?.avatar_props,
        id: resultData?.id,
        index: resultData?.index,
        source: resultData?.source,
        model_name: resultData?.model_name,
      };

      return answer;
    } catch (err) {
      throw new Error(TIME_OUT_ERROR);
    }
  }

  function handleSuccess(answer, variables) {
    const currentMessage = queryClient.getQueryData([
      CHAT_HISTORY_QUERY_KEY,
      workspaceName,
    ]);
    const newMessage = {
      ...currentMessage,
      pairs: [...currentMessage.pairs, answer],
    };
    queryClient.setQueryData(
      [CHAT_HISTORY_QUERY_KEY, workspaceName],
      newMessage,
    );
    if (
      !isUserAnonymus &&
      (variables.model_name === ChatAthenaModelNameEnum.gpt4 ||
        variables.model_name === ChatAthenaModelNameEnum.claude)
    ) {
      deductCredits(CHATATHENA_QUERY_CREDIT_COST);
    }
    publishAsBlog();

    if (newMessage.pairs.length && newMessage.pairs.length % 5 === 0) {
      trainAthena();
    }
  }

  return useMutation(sendMessage, {
    onSuccess: handleSuccess,
    onError: (error) => {
      if (error.message !== TIME_OUT_ERROR) {
        toast.error(error.message, {
          position: 'bottom-center',
          theme: 'dark',
        });
      }
      // incase of time out error, retry will be done
    },
  });
}
