import styled from 'styled-components';

export const TagStatus = styled.span.attrs((props) => ({
  active: props.active || null,
}))`
  display: flex;
  flex-direction: row;
  cursor: default;
  margin: 0 0.7rem;
  font-size: var(--text-size-default);
  padding-right: 0.3rem;
  font-weight: 500;
  color: ${(props) => (props.active ? 'var(--primary-color)' : null)};
  border-bottom: ${(props) =>
    props.active ? '2px solid var(--primary-color)' : null};
  cursor: pointer;
`;

export const TabContentWrapper = styled.div`
  overflow-y: scroll;
  padding: 4px;
  box-shadow: var(--card-box-shadow);
  border-radius: var(--card-border-radius);
  margin-right: 12px;
`;

export const CardContainer = styled.div`
  background-color: var(--card-background-color);
  border-radius: var(--card-border-radius);
  box-shadow: var(--card-box-shadow);
  padding: 16px;
  min-width: 12rem;
  max-width: 100%;
  height: 12rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CardTitle = styled.h3`
  font-size: var(--text-size-xlarge);
  margin-bottom: 8px;
  color: var(--font-primary-color);
`;

export const CardDescription = styled.p`
  color: var(--font-secondary-color);
  font-size: var(--text-size-default);
  margin-bottom: 16px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

export const TextArea = styled.textarea`
  height: 6rem;
  background-color: var(--background-color);
  border: none; 
`;
