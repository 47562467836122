/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import { useSelector } from 'react-redux';
import { values } from 'Component/Shared/VideoTutorials/VideoDetails';

const useStyles = makeStyles(() => ({
  formControl: {
    margin: '4px',
    width: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: '16px',
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const dropDownDataReverse = {
  mrr: 'Market Research',
  mrr_insight: 'Market Insights',
  script: 'Sales Copywriting',
  serp: 'Google',
  quora: 'Quora',
  reddit: 'Reddit',
  website: 'URL',
};

export default function MultipleSelectDropdown({ value, setData, index }) {
  const allowSources = useSelector(
    (state) => state.user.athena_sources?.train_option,
  );
  const [filterOptions, setFilterOptions] = useState([
    { key: 'mrr', value: 'Market Research', checked: false },
    { key: 'mrr_insight', value: 'Market Insights', checked: false },
    { key: 'script', value: 'Sales Copywriting', checked: false },
    { key: 'serp', value: 'Google', checked: false },
    { key: 'quora', value: 'Quora', checked: false },
    { key: 'reddit', value: 'Reddit', checked: false },
    { key: 'website', value: 'URL', checked: false },
  ]);

  const classes = useStyles();
  const [selectedFeilds, setSelectedFeilds] = useState([]);
  const handleChange = (event) => {
    console.log('values', event.target.value);
    setSelectedFeilds(event.target.value);
    console.log(event.target.value);
    setData(event.target.value, index);
  };

  useEffect(() => {
    console.log('val props', value);
    const convertedValues = value.map((val) => {
      const data = dropDownDataReverse[val];
      if (data) return data;
      return val;
    });
    if (typeof value === 'object') setSelectedFeilds([...convertedValues]);
  }, [value]);

  if (typeof value !== 'object') return <></>;

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-mutiple-checkbox-label">Sources</InputLabel>
        <Select
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          multiple
          value={selectedFeilds}
          onChange={handleChange}
          input={<Input />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {filterOptions.map((option, _index) => (
            <MenuItem key={option.key} value={option.value}>
              <Checkbox checked={selectedFeilds.indexOf(option.value) > -1} />
              <ListItemText primary={option.value} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
