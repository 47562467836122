export function getSavedBlogsSelector(rawBlogs) {
  const { savedBlogs = [], imageResult = [] } = rawBlogs ?? {};

  if (savedBlogs?.length === 0) return [];
  const blogs = [];

  for (let i = 0; i < savedBlogs.length; i += 1) {
    const obj = {
      blog_name: savedBlogs[i]?.blog,
      blog_author: savedBlogs[i]?.fullname,
      blog_readtime: savedBlogs[i]?.reading_time,
      blog_script: savedBlogs[i]?.script_name,
      blog_image: imageResult[i] !== undefined ? imageResult[i] : '',
      blog_date: savedBlogs[i]?.scheduled_date,
      blog_id: savedBlogs[i]?.id,
      blog_topic: savedBlogs[i]?.topic,
      date_published: savedBlogs[i]?.date_published,
    };

    blogs.push(obj);
  }
  return blogs;
}
