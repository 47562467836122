/* eslint-disable jsx-a11y/media-has-caption */
import { Button, Dialog, DialogActions } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styles from './message-box.module.css';

const VIDEO_TYPE = 'video/mp4';

export const PreviewComponent = ({ fileType, fileSrc }) => {
  const [open, setOpen] = useState(false);
  const [isVideo, setIsVideo] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (fileSrc?.includes(VIDEO_TYPE) || fileType === VIDEO_TYPE) {
      setIsVideo(true);
    }
  }, [fileSrc]);

  return (
    <div className={styles.preview_parent}>
      <div onClick={handleOpen}>
        {isVideo ? (
          <video
            className={styles.video_thumb}
            controls={false}
            autoPlay={false}
          >
            <source src={fileSrc} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <img className={styles.image_thumb} src={fileSrc} alt="img" />
        )}
      </div>
      <Dialog open={open} onClose={handleClose}>
        <div className={styles.preview_wrapper}>
          <span className={styles.preview_heading}>Preview</span>
          <div>
            {isVideo ? (
              <video className={styles.video_preview} controls>
                <source src={fileSrc} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img src={fileSrc} alt="Preview" />
            )}
          </div>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};
