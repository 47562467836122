import { useMutation } from 'react-query';
import { getReduxUserState, getReduxWorkspaceState } from 'utils/helpers/redux-selectors';
import createInstance from 'Config/AxiosConfig';
import { toast } from 'react-toastify';

const fetchAnswerForQuestion = async ({ Topic, question }) => {
  const { email } = getReduxUserState();
  const { name } = getReduxWorkspaceState();

  const payload = {
    context: Topic,
    name,
    question,
    username: email,
  };

  const instance = await createInstance();

  const response = await instance.post('question-answer', payload);

  if (response?.data?.statusCode === '200') return response?.data?.answer;

  throw new Error('Answers could not be fetched, Please try again !');
};

export const useMutateFetchAnswerForQuestion = () => useMutation(fetchAnswerForQuestion, {

  onSuccess: async (data) => {

  },
  onError: async (error) => {

  },
});
