import { useState, useEffect } from 'react';

function isMobileDevice() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );
}

export function useIsMobile() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const _isMobile = window.innerWidth < 910;
      setIsMobile(_isMobile);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    if (isMobileDevice()) {
      setIsMobile(true);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile;
}
