import createInstance from 'Config/AxiosConfig';

export const sourceMap = {
  'Market Research': 'mrr',
  'Market Insights': 'mrr_insight',
  'Sales Copywriting': 'script',
  Google: 'serp',
  Quora: 'quora',
  Reddit: 'reddit',
  URL: 'website',
};

export default async function buildTemplate(body) {
  const instance = await createInstance();
  console.log('bulding');
  const { data } = await instance.post('save-user-blog-template', body);

  return data.statusCode === '200';
}

export async function updateTemplate(body) {
  const instance = await createInstance();
  const { data } = await instance.post('update-user-blog-template', body);

  return data.statusCode === '200';
}
