/* eslint-disable consistent-return */
/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable import/no-unresolved */

import createInstance from 'Config/AxiosConfig';

import { store } from 'redux/store';

const getUsersEmail = () => {
  const state = store.getState();
  const { username } = state.user;
  return username;
};

const fetchDFYWorkspace = async () => {
  try {
    const instance = await createInstance();
    const username = getUsersEmail();
    const result = await instance.get(`projects-all?username=${username}`);

    console.log(result);
    if (result.data.statusCode === '200') {
      return {
        data: result.data.data.data,
        error: false,
      };
    }

    return {
      data: null,
      error: true,
    };
  } catch (ex) {
    console.log('Error in Display Table DFY', ex);
    return {
      data: null,
      error: true,
    };
  }
};

export const fetchDFYWorkspaceForUsers = async (email) => {
  try {
    const instance = await createInstance();
    const result = await instance.get(`projects-all?username=${email}`);

    console.log(result);
    if (result.data.statusCode === '200') {
      if (result.data.data.paidworkspaces.dfys) {
        console.log('1');

        return {
          data: result.data.data.paidworkspaces.dfys,
          error: false,
        };
      }
      console.log('2');
      return {
        data: [],
        error: false,
      };
    }

    console.log('3');
    return {
      data: [],
      error: true,
    };
  } catch (ex) {
    console.log('4');
    console.log('Error in Display Table DFY', ex);
    return {
      data: [],
      error: true,
    };
  }
};

export const copyBuyDFY = async (workspace_name, creator_email) => {
  // buy api integration

  try {
    const req_body = {
      dfy_name: workspace_name,
      creator_email,
    };

    const instance = await createInstance();

    const response = await instance.post('dfy-checkout', req_body);

    if (response.data.statusCode === '200') {
      return {
        success: true,
        url: response.data.url,
      };
    }
    return {
      success: false,
      url: '',
    };
  } catch (err) {
    console.log(err);
    return {
      success: false,
      url: '',
    };
  }
};

export const addToMyWorkspaceRequest = async (name, domain, creator_email) => {
  try {
    const body = {
      fromaccount: creator_email,
      name: [name],
      site: [domain],
      demo_name: [name],
      fullName: getUsersEmail(),
    };

    const instance = await createInstance();

    const result = await instance.post('demo-load', body);

    if (result.data.statusCode === '500') {
      return {
        error: true,
        data: null,
      };
    }
    return {
      error: false,
      data: true,
    };
  } catch (error) {
    console.log(error);
  }
};

export default fetchDFYWorkspace;
