import { baseAPIsUrl } from 'Config/Api';
import axios from 'axios';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';

export const useMutateAthenaTraining = () => {
  const user = useSelector((state) => state?.user);
  const workspace = useSelector((state) => state?.workspace);

  async function trainAthena() {
    const axiosConfig = {
      multiValueHeaders: {
        'Content-Type': ['application/json'],
        'Access-Control-Allow-Origin': ['*'],
        'Access-Control-Allow-Headers': [
          'Content-Type',
          'X-Amz-Date',
          'Authorization',
          'X-Api-Key',
          'X-Amz-Security-Token',
        ],
      },
    };

    const body = {
      username: user?.username,
      name: workspace?.name,
      num: 1,
      cc: 'US',
      is_train: true,
      serp: true,
      overwrite: false,
    };
    const response = await axios.post(
      `${baseAPIsUrl}/test-semantic`,
      body,
      axiosConfig,
    );

    if (response.data?.statusCode !== '200') {
      throw new Error('Error in training Athena');
    }
  }

  return useMutation(trainAthena);
};
