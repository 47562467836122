import { baseAPIsUrl } from 'Config/Api';
import createInstance from 'Config/AxiosConfig';
import { useQuery } from 'react-query';

export const getUsersTemplates = async (page = 1, limit = 10) => {
  try {
    const instance = await createInstance();
    const { data } = await instance.post(
      `${baseAPIsUrl}/get-user-blog-template`,
      {
        include_all: true,
        page_num: page,
        page_size: limit,
      },
    );
    if (data.statusCode === '200') {
      return { templates: data.templates, totalTemplates: data.max_length };
    }
    return { templates: [], totalTemplates: 0 };
  } catch (ex) {
    return { templates: [], totalTemplates: 0 };
  }
};

// Keep Query Value as File Name to maintain consistency and uniqueness
export const GET_USER_TEMPLATES_QUERY_KEY = 'get-users-templates-query-key';

// Query Config Options
const queryOptions = {
  staleTime: 30000,
};

// QUERY
export const useGetUserTemplates = (page, limit) =>
  useQuery(
    [GET_USER_TEMPLATES_QUERY_KEY, page, limit],
    () => getUsersTemplates(page, limit),
    queryOptions,
  );
