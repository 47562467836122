import createInstance from 'Config/AxiosConfig';
import { FALLBACK_ACTIVE_WORKSPACE } from 'libs/chat-athena-constants';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

// Keep Query Value as File Name to maintain consistency and uniqueness in query index
export const CHAT_HISTORY_QUERY_KEY = 'get-chat-history-query';

// Query Config Options
const queryOptions = {
  staleTime: 10 * 60 * 1000,
  // 10 mins
};

// QUERY
export const useGetChatHistory = (currentPageSize) => {
  const user = useSelector((state) => state?.user);
  const workspace = useSelector((state) => state?.workspace);
  const workspaceName = workspace?.name ?? FALLBACK_ACTIVE_WORKSPACE?.name;

  async function fetchChatHistory(currentPageSize) {
    try {
      const instance = await createInstance();
      const result = await instance.get(
        `/get-chat-history?name=${workspaceName}&username=${
          user?.username
        }&page_num=${0}&page_size=${currentPageSize}`,
      );

      if (result.data?.statusCode === '200') {
        const chatHistory = result.data?.answer;
        return chatHistory;
      }
      throw new Error('Error fetching chat history');
    } catch (err) {
      console.log(err);
      throw new Error('Error fetching chat history');
    }
  }

  return useQuery(
    [CHAT_HISTORY_QUERY_KEY, workspaceName],
    () => fetchChatHistory(currentPageSize),
    queryOptions,
  );
};
