/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useContext, useEffect, useState } from 'react';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded';
import { Flex } from 'Component/Shared/GlobalStyles';
import { WORKSPACE, WORKSPACES } from 'libs/constants';
import toggleWorkspaceAsFavourite from 'Component/Pages/Workspaces/Workspace/UserWorkspace/UserWorkspaceLogic';
import { toast } from 'react-toastify';
import { ErrorToastProps } from 'Component/Pages/Workspaces/Workspace/CreateNewWorkspace/parseConfigurations';
import { PieChartOutlined } from '@mui/icons-material';
import CustomTooltip from 'Component/Shared/CustomTooltip';
import ViewSidebarRoundedIcon from '@mui/icons-material/ViewSidebarRounded';
import { ModelSelector } from 'Component/Pages/Content Automation/AIContentWriter/ChatAthenaComponents/components/ModelSelector/ModelSelector';
import { useIsMobile } from 'Component/hooks/useIsMobile';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import { SearchInput } from 'components/global/SearchInput';
import styles from '../chat-athena.module.css';
import { getItem, truncateString } from '../utils';
import WorkspacesContext from './WorkspsaceContext';
import CreditManager from './CreditManager';

const WorkspaceList = ({ fromDrawer, setStarterFlow }) => {
  const isMobile = useIsMobile();

  const plan = useSelector((state) => state?.user?.plan);

  const {
    isDarkMode,
    workspaceName,
    workspaces,
    selectedWorkspace,
    handleSelectWorkspace,
    setWorkspaces,
    userWorkspacesList,
  } = useContext(WorkspacesContext);

  const [isSidebarOpen, setIsSidebarOpen] = useState(!isMobile);
  const onSearch = (event) => {
    const { value } = event.target;
    if (value === '') {
      setWorkspaces(
        userWorkspacesList.map((workspace) =>
          getItem(
            workspace.name,
            workspace.name,
            <PieChartOutlined />,
            workspace,
          ),
        ),
      );
    } else {
      setWorkspaces(
        userWorkspacesList
          .map((workspace) =>
            getItem(
              workspace.name,
              workspace.name,
              <PieChartOutlined />,
              workspace,
            ),
          )
          .filter((workspace) =>
            workspace.label.toLowerCase().includes(value.toLowerCase()),
          ),
      );
    }
  };

  const handleSetWorkspaceAsFavorite = async (
    workspaceName,
    is_favourite,
    id,
  ) => {
    const previousUserWorkspaceList = [...workspaces];

    const updatedUserWorkspaceList = workspaces.map((workspace, index) => {
      if (index === id) {
        return {
          ...workspace,
          data: { ...workspace.data, is_favourite: !is_favourite },
        };
      }
      return workspace;
    });

    setWorkspaces([...updatedUserWorkspaceList]);

    try {
      const { error } = await toggleWorkspaceAsFavourite(
        workspaceName,
        is_favourite,
      );

      if (error) {
        setWorkspaces([...previousUserWorkspaceList]);
        const favoriteStatus = is_favourite ? 'as favorite' : 'as not favorite';
        toast.error(
          `Error in setting ${WORKSPACE}: ${workspaceName} ${favoriteStatus}`,
          ErrorToastProps,
        );
      }
    } catch (error) {
      setWorkspaces([...previousUserWorkspaceList]);
      const favoriteStatus = is_favourite ? 'as favorite' : 'as not favorite';
      toast.error(
        `Error in setting ${WORKSPACE}: ${workspaceName} ${favoriteStatus}`,
        ErrorToastProps,
      );
    }
  };

  useEffect(() => {
    setIsSidebarOpen(!isMobile);
  }, [isMobile]);

  if (!isSidebarOpen && !fromDrawer) {
    return (
      <div className={styles.workspace_list_container_show_hide}>
        <div className={styles.show_hide}>
          <CustomTooltip title={`Show ${WORKSPACE} list`} placement="top" arrow>
            <ViewSidebarRoundedIcon onClick={() => setIsSidebarOpen(true)} />
          </CustomTooltip>
        </div>
      </div>
    );
  }

  return (
    <div
      className={styles.workspace_list_container}
      style={{
        background: isDarkMode ? '#000' : 'white',
      }}
    >
      <div
        style={{
          background: isDarkMode ? '#444653' : '#F7F7F8',
        }}
        className={styles.workspace_heading}
      >
        <strong style={{ color: isDarkMode ? 'white' : 'black' }}>
          {WORKSPACE} : {workspaceName}
        </strong>
        {!fromDrawer && (
          <div className={styles.show_hide}>
            <CustomTooltip
              title={`Hide ${WORKSPACE} list`}
              placement="top"
              arrow
            >
              <ViewSidebarRoundedIcon onClick={() => setIsSidebarOpen(false)} />
            </CustomTooltip>
          </div>
        )}
      </div>
      <div>
        <SearchInput placeholder={`Search ${WORKSPACES}....`} onChange={onSearch} />
      </div>
      <button
        type="button"
        style={{
          color: isDarkMode ? 'white' : 'black',
          background: isDarkMode ? '#444653' : '#F7F7F8',
        }}
        className={cx([styles.new_workspace_button])}
        onClick={() => setStarterFlow('FLOW')}
      >
        <span
          style={{
            color: isDarkMode ? 'white' : 'black',
          }}
        >
          New {WORKSPACE}
        </span>
        <AddRoundedIcon />
      </button>
      <div className={styles.workspace_lists}>
        {workspaces.map((workspace, id) => (
          <div
            style={{
              color: isDarkMode ? 'white' : 'black',
              backgroundColor:
                workspace.label === selectedWorkspace ? 'lightGray' : '',
            }}
            key={workspace.key}
            className={styles.workspace_list}
          >
            <Flex flex={'1'} width={'100%'} justify={'space-between'}>
              <div
                onClick={() => {
                  window.history.replaceState(
                    null,
                    '',
                    window.location.pathname,
                  );
                  handleSelectWorkspace(workspace.data, true);
                }}
              >
                <CustomTooltip title={workspace.label} arrow placement="right">
                  <span>{truncateString(workspace.label, 100)}</span>
                </CustomTooltip>
              </div>
              <div
                onClick={() =>
                  handleSetWorkspaceAsFavorite(
                    workspace.label,
                    workspace.data.is_favourite,
                    id,
                  )
                }
              >
                {workspace.data.is_favourite ? (
                  <div
                    style={{
                      color: 'red',
                    }}
                  >
                    <FavoriteRoundedIcon />
                  </div>
                ) : (
                  <div>
                    <FavoriteBorderRoundedIcon />
                  </div>
                )}
              </div>
            </Flex>
          </div>
        ))}
      </div>
      <div className={styles.data_display_wrapper}>
        <CreditManager
          style={{
            background: '#2f2f2f',
            color: '#fff',
            width: '100%',
            marginLeft: '0',
          }}
        />
        {isMobile && <ModelSelector />}
      </div>
    </div>
  );
};

export default WorkspaceList;
