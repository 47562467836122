import React from 'react';
import { useSelector } from 'react-redux';
import { useGetWorkspaceCreationActivity } from 'queries/graph-info/get-workspace-creation-activity';
import { ResponsiveCalendar } from '@nivo/calendar';
import { getFormattedTimeInDayMonthYear } from 'utils/helpers';
import { ScaffoldCard } from 'components/common';
import { THEME } from '.';
import styles from './workspace-creation-heatmap.module.css';

const HeatMapToolTip = (props) => {
  console.log(props);

  const { day } = props ?? '';
  const campaignList = props?.data?.campaigns ?? [];

  if (props) {
    return (
      <div className={styles.tooltip_container}>
        <b>{getFormattedTimeInDayMonthYear(day)}</b>
        <ul className={styles.info_container}>
          <li>Campaigns created : {campaignList?.length ?? ''}</li>
          <li>
            Campaigns name :
            <ul className={styles.info_container}>
              {campaignList.map((campaign) => (
                <li>{campaign}</li>
              ))}
            </ul>
          </li>
        </ul>
      </div>
    );
  }
  return null;
};

function WorkspaceCreationActivityHeatMap() {
  const name = useSelector((state) => state?.user?.name);
  const { data, isLoading } = useGetWorkspaceCreationActivity();

  const totalActivitiesThisYear = data?.length ?? 0;
  return (
    <ScaffoldCard className={styles.workspace_creation_heatmap_container}>
      <div className={styles.workspace_creation_heatmap_header_container}>
        <div className={styles.header}>Activity Overview : {name}</div>
        <span className={styles.subheader}>
          A graphical representation of campaigns generated
        </span>
      </div>
      <div className={styles.graph_container}>
        <ResponsiveCalendar
          data={data ?? []}
          from="2024-01-01"
          to="2024-12-31"
          theme={THEME}
          emptyColor="var(--background-secondary-color)"
          colors={['#61cdbb', '#97e3d5', '#e8c1a0', '#f47560']}
          margin={{ top: 10, right: 10, bottom: 10, left: 30 }}
          yearLegend={() => '2023'}
          yearSpacing={40}
          monthBorderColor="#343541"
          dayBorderWidth={2}
          dayBorderColor="#343541"
          tooltip={HeatMapToolTip}
          legends={[
            {
              anchor: 'bottom-right',
              direction: 'row',
              translateY: 36,
              itemCount: 4,
              itemWidth: 42,
              itemHeight: 36,
              itemsSpacing: 14,
              itemDirection: 'right-to-left',
            },
          ]}
        />
      </div>
    </ScaffoldCard>
  );
}

export default WorkspaceCreationActivityHeatMap;
