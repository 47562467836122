/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext, useEffect, useState } from 'react';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import { BeatLoader } from 'react-spinners';
import AvatarGeneratorPlaceholder from 'components/common/AvatarGenerator/AvatarGenerator';
import cx from 'classnames';
import { useIsMobile } from 'Component/hooks/useIsMobile';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CustomTooltip from 'Component/Shared/CustomTooltip';
import styles from './message-box.module.css';
import { ImageUpload } from '../ImagesUpload/ImageUpload';
import { SelectedImageUploadContext } from '../contexts/SelectedImageUpload';
import { PreviewComponent } from './PreviewComponent';

const MessageBox = ({
  message,
  setMessage,
  onSendMessage,
  isTyping,
  delayedResponse,
  assistant,
  removeAssistant,
}) => {
  const isMobile = useIsMobile();

  const { uploadedImages, setUploadedImages } = useContext(
    SelectedImageUploadContext,
  );

  const [draftMessage, setDraftMessage] = useState(message);

  const handleInputChange = (e) => {
    setDraftMessage(e.target.value);
  };

  const handleSendClick = () => {
    if (draftMessage.trim() !== '') {
      onSendMessage(draftMessage);
      setDraftMessage('');
    }
  };

  const handleBlur = () => {
    setMessage(draftMessage);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey && !isMobile) {
      e.preventDefault();
      if (draftMessage.trim() !== '') {
        onSendMessage(draftMessage);
        setDraftMessage('');
      }
    }
  };

  const handleRemoveImage = (idx) => {
    const prevImages = [...uploadedImages];
    prevImages.splice(idx, 1);

    setUploadedImages([...prevImages]);
  };

  useEffect(() => {
    setDraftMessage(message);
  }, [message]);

  return (
    <div className={styles.chat_input_wrapper}>
      {isTyping && !delayedResponse && (
        <p className={styles.para_text}>Athena is Typing... </p>
      )}
      {isTyping && delayedResponse && (
        <p className={styles.para_text}>
          <strong>Relax, take a coffee sip, Athena is still busy...</strong>
        </p>
      )}

      <div className={styles.chat_input}>
        <div className={styles.send_button_wrapper}>
          <ImageUpload />
        </div>
        <div className={styles.chat_input_group}>
          <textarea
            placeholder="Type your message..."
            value={draftMessage}
            onChange={handleInputChange}
            onBlur={handleBlur}
            className={styles.message_textarea}
            onKeyPress={handleKeyPress}
          />
          <div className={styles.img_group}>
            {uploadedImages?.map((img, idx) => (
              <div
                className={styles.image_container}
                key={img.slice(0, 20) + idx}
              >
                <PreviewComponent fileSrc={img} />
                <CancelRoundedIcon
                  onClick={() => handleRemoveImage(idx)}
                  className={styles.cancel_icon}
                />
              </div>
            ))}
          </div>
          {assistant && (
            <CustomTooltip
              title={assistant.assistant_name}
              placement="top"
              arrow
            >
              <div className={styles.image_container}>
                <div className={styles.assistant_icon}>
                  <AvatarGeneratorPlaceholder
                    avatarProp={assistant.avatar_props}
                  />
                </div>
                <CancelRoundedIcon
                  onClick={removeAssistant}
                  className={styles.cancel_icon_assistant}
                />
              </div>
            </CustomTooltip>
          )}
        </div>

        {isTyping ? (
          <div className={styles.loader_bubbles}>
            <BeatLoader color="#fff" loading size={10} />
          </div>
        ) : (
          <div className={styles.send_button_wrapper}>
            <button
              onClick={handleSendClick}
              disabled={!draftMessage}
              className={cx(styles.send_button, {
                [styles.send_button_disabled]: !draftMessage,
              })}
              type="button"
            >
              <SendRoundedIcon />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default MessageBox;
