import React from 'react';
import { validateEmail } from 'Component/Shared/utils';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';
import { userEmailUpdate } from '../../../../redux';
import { ButtonDanger, ButtonPrimary, ExpandedMenuWrapper } from '../styles';
import { removeTeamMember } from '../TeamMemberLogic';

function ExpandedMenu({ email }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleAccessAcount = () => {
    if (!validateEmail(email)) return;
    dispatch(userEmailUpdate(email));
    localStorage.setItem('username', email);
    navigate('/Workspace', { replace: true });
  };

  const showNotification = (type, message, description) => {
    notification.open({
      type,
      message,
      description,
      placement: 'bottomRight',
      duration: 6,
    });
  };

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  const handleRemoveUser = async () => {
    const result = await removeTeamMember(email);
    if (result?.statusCode === '200') {
      showNotification(
        'success',
        'SUCCESS',
        `User : ${email} removed successfully!`,
      );
      await sleep(5 * 100);
      window.location.reload();
      return true;
    }
    showNotification(
      'error',
      'Failure',
      `Could not remove user: ${result?.body}`,
    );
    return false;
  };

  return (
    <ExpandedMenuWrapper>
      <ButtonPrimary onClick={handleAccessAcount}>Access Account</ButtonPrimary>
      <ButtonDanger onClick={handleRemoveUser}>Delete Account</ButtonDanger>
    </ExpandedMenuWrapper>
  );
}

export default ExpandedMenu;
