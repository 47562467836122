/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-regex-literals */
const getPlanNameForDisplay = (plan) => {
  const plansNotToDisplay = [
    'AppSumo Plus Basic Plan',
    'AppSumo Plus Advance Plan',
    'AppSumo Plus Pro Plan',
    'AppSumo Plus Enterprise Plan',
  ];
  if (plansNotToDisplay.includes(plan)) {
    return 'AppSumo Plus Advance Plan';
  }
  return plan;
};

export const getWordCount = () =>
  window.tinymce.activeEditor.plugins.wordcount.getCount();

export const getQueryFromURLParams = (query) => {
  const urlString = window.location.href;
  const url = new URL(urlString);
  return url.searchParams.get(query);
};

export const getNameInitials = (name) => {
  const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

  let initials = [...name.matchAll(rgx)] || [];

  initials = (
    (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
  ).toUpperCase();

  return initials;
};

export const validateEmail = (email) =>
  String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

export function removeItemArray(arr, value) {
  const index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
}

export function isValidURL(str) {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  return !!pattern.test(str);
}
export const getURLPathBeforeLastSegment = () => {
  const splitedPathnames = window.location.pathname.split('/');
  return splitedPathnames[1];
};

export function sortArrayOfObjects(items, getter) {
  const copy = JSON.parse(JSON.stringify(items));

  const sortFn = (fn) => {
    copy.sort((a, b) => {
      a = fn(a);
      b = fn(b);
      return a === b ? 0 : a < b ? 1 : -1;
    });
  };

  getter.forEach((x) => {
    const fn = typeof x === 'function' ? x : (item) => item[x];
    sortFn(fn);
  });

  return copy;
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function truncateString(str, number) {
  if (str.length <= number) {
    return str;
  }
  return `${str.slice(0, number - 3)}...`;
}

export const customDebounce = (func, delay) => {
  let timeout;
  return function () {
    const context = this;
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
};

export default getPlanNameForDisplay;
