import Loader from 'react-loader-spinner';
import React from 'react';

export function LoaderTailSpin({ color, height, width, ...props }) {
  return (
    <Loader
      type="TailSpin"
      color={color || '#63a5f0'}
      secondaryColor="white"
      height={height || '100%'}
      width={width}
      {...props}
    />
  );
}
