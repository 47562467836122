/* eslint-disable indent */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable no-undef */
/* eslint-disable max-len */
/* eslint-disable import/order */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable comma-dangle */
/* eslint-disable quotes */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  CaretLeftOutlined,
  CommentOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, Card, Col, Layout, Row } from 'antd';
import React, { useMemo, useState } from 'react';
import AthenaLogo1 from 'assets/images/crawlQ-transparent-favicon.png';
import './desktopPage.css';
import ChatAthenaWeb from '../../ChatAthenaController/ChatAthenaWeb';
import LanguageDesktopSelector from '../../sharedMobile/LanguageDesktopSelectors';
import Wave from 'react-wavify';
import MainForm from './MainForm';
import { useSelector } from 'react-redux';
import { useGetAllUsersWorkspaces } from 'queries/chat-athena/get-all-users-workspaces';
import LoaderSpin from 'Component/Shared/Loader';
import {
  MAX_ALLOWED_WORKSPACES_IN_FREEMIUM_PLAN,
  isFreemiumPlan,
} from 'libs/enums/premiumPlans.enum';
import { updagradeChatAthenaToPro } from 'Component/Pages/Content Automation/AIContentWriter/ChatAthenaComponents/components/AppIntegration/service';

const { Header } = Layout;

export const DesktopDashboard = (props) => {
  const user = useSelector((state) => state?.user);

  const { data: worksapces } = useGetAllUsersWorkspaces();

  // Zero workspace ensures that the user is new.
  const isNewUser = worksapces?.length === 0;
  const { isDarkMode, setDarkMode, isStarterFlow, setStarterFlow } = props;
  const [formStart, setFormStart] = useState(false);
  const [loadingCreatingButton, setLoadingCreatingButton] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [messageToShow, setMessageToShow] = useState('Preparing ..');
  const [isLoadingUpgrade, setIsLoadingUpgrade] = useState(false);

  const isWorkspaceLimitReached = useMemo(
    () =>
      isFreemiumPlan(user?.plan) &&
      worksapces?.length >= MAX_ALLOWED_WORKSPACES_IN_FREEMIUM_PLAN,
    [worksapces],
  );

  const [data, setData] = useState({
    targetCountry: 'US',
    city: 'New York',
    language: 'US',
    languageCode: 'en',
    translationEngine: 'Google',
    founderName: user?.name,
  });
  const switchToForm = async () => {
    if (isWorkspaceLimitReached) {
      setIsLoadingUpgrade(true);
      await updagradeChatAthenaToPro();
      setIsLoadingUpgrade(false);
      return;
    }
    setMessageToShow('Preparing the New Campaign Form for you ..');
    setLoadingForm(true);
    setTimeout(() => {
      setLoadingForm(false);
      setFormStart(true);
    }, 1300);
  };
  const switchToAthena = () => {
    setMessageToShow('Preparing Chat Athena for you ..');
    setLoadingForm(true);
    setTimeout(() => {
      // setLoadingForm(false);
      setStarterFlow('CHATATHENA');
      setLoadingCreatingButton(false);
      setFormStart(false);
      setLoadingForm(false);
    }, 1300);
  };

  return (
    <div>
      {isStarterFlow === 'FLOW' && (
        <div style={{ backgroundColor: '#2e2f39' }}>
          <Header
            style={{
              backgroundColor: '#2e2f39',
              height: '100px',
              paddingTop: '10px',
            }}
          >
            <div
              style={{
                color: 'white',
                fontWeight: 'bold',
                fontSize: '20px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {' '}
              <img
                src={AthenaLogo1}
                alt="Athena Logo"
                style={{
                  display: 'inline-block',
                  width: '50px',
                  height: '50px',
                  marginRight: '10px',
                  // marginBottom: '15px',
                }}
              />
              <span
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: '5px',
                  color: 'white',
                  fontSize: '26px',
                  fontWeight: 'bold',
                }}
              >
                Welcome to CrawlQ: Where Insights Emerge <br />
              </span>
            </div>
          </Header>
          {!isNewUser && !formStart && (
            <div className="split-container">
              <div className="left">
                <Card
                  hoverable
                  style={{
                    width: '75vw',
                    borderRadius: '10px',
                    padding: '25px',
                    backgroundColor: '#3c3d49',
                    minHeight: '55vh',
                    display: 'flex',
                    justifyContent: 'center',
                    // alignItems: 'center',
                  }}
                  // cover={}
                >
                  <div>
                    <span
                      style={{
                        marginLeft: '27%',
                        color: '#4B79FF',
                        fontSize: '22px',
                        fontWeight: 'bold',
                      }}
                    >
                      Embark on a Journey of Discovery <br />
                    </span>
                    <span
                      style={{
                        marginLeft: '34%',
                        color: '#4B79FF',
                        fontSize: '22px',
                        fontWeight: 'bold',
                      }}
                    >
                      Choose Your Path
                    </span>
                  </div>
                  {true && (
                    <div
                      style={{
                        height: '10vh',
                        // backgroundColor: 'green',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {' '}
                      {loadingForm && (
                        <div>
                          <div
                            style={{
                              position: 'absolute',
                              bottom: '18%',
                              left: '15%',
                              color: '#4B79FF',
                              fontSize: '40px',
                              fontWeight: 'bold',
                            }}
                          >
                            {messageToShow}
                          </div>
                          <div
                            style={{
                              fontWeight: 'bold',
                              color: '#5887FB',
                              fontSize: '20px',
                              // marginBottom: '10px',
                              width: '100%',
                              height: '100px',
                              position: 'absolute',
                              bottom: 0,
                              left: 0,
                              zIndex: 100,
                            }}
                          >
                            <Wave
                              style={{
                                width: '100%',
                                height: '100%',
                              }}
                              mask="url(#mask)"
                              fill="#5887FB"
                              amplitude="30"
                              speed="0.35"
                              points="4"
                            >
                              <defs>
                                <linearGradient
                                  id="gradient"
                                  gradientTransform="rotate(90)"
                                >
                                  <stop offset="0" stopColor="white" />
                                  <stop offset="0.5" stopColor="black" />
                                </linearGradient>
                                <mask id="mask">
                                  <rect
                                    x="0"
                                    y="0"
                                    width="100%"
                                    height="300"
                                    fill="url(#gradient)"
                                  />
                                </mask>
                              </defs>
                            </Wave>
                          </div>
                        </div>
                      )}
                      {isWorkspaceLimitReached ? (
                        <Button
                          style={{
                            color: '#fff',
                            width: '450px',
                            borderRadius: '10px',
                            fontSize: '25px',
                            background: '#01ac39',
                            marginRight: '20px',
                            height: '100px',
                            fontWeight: 'bold',
                            marginTop: '15%',
                          }}
                          onClick={() => switchToForm()}
                        >
                          <Row align="top">
                            {isLoadingUpgrade ? (
                              <LoaderSpin />
                            ) : (
                              <>
                                <Col span={4}>
                                  <PlusOutlined
                                    style={{
                                      marginRight: '15px',
                                      fontSize: '55px',
                                      color: 'white',
                                      marginTop: '13px',
                                    }}
                                  />
                                </Col>
                                <Col offset={1} span={17}>
                                  Upgrade Plan <br />
                                  <picture
                                    style={{
                                      fontSize: '0.7rem',
                                      color: 'white',
                                      fontWeight: 'normal',
                                    }}
                                  >
                                    Create more Workspaces, Boost Productivity,
                                    Thrive!
                                  </picture>
                                </Col>
                              </>
                            )}
                          </Row>
                        </Button>
                      ) : (
                        <Button
                          // type="primary"
                          // loading={loadingCreatingButton}
                          style={{
                            color: 'black',
                            width: '450px',
                            borderRadius: '10px',
                            fontSize: '25px',
                            background: '#4B79FF',
                            marginRight: '20px',
                            height: '100px',
                            fontWeight: 'bold',
                            marginTop: '15%',
                          }}
                          onClick={() => switchToForm()}
                        >
                          <Row align="top">
                            <Col span={4}>
                              {' '}
                              <PlusOutlined
                                style={{
                                  marginRight: '15px',
                                  fontSize: '55px',
                                  color: 'white',
                                  marginTop: '13px',
                                }}
                              />
                            </Col>
                            <Col offset={1} span={17}>
                              Create a New Campaign <br />
                              <span
                                style={{
                                  fontSize: '16px',
                                  color: 'white',
                                  fontWeight: 'normal',
                                }}
                              >
                                Start fresh and uncover new insights
                              </span>
                            </Col>
                          </Row>
                        </Button>
                      )}
                      <Button
                        // type="primary"
                        // disabled={loadingCreatingButton}
                        style={{
                          color: 'black',
                          width: '500px',
                          height: '100px',
                          borderRadius: '10px',
                          fontSize: '25px',
                          background: '#4B79FF',
                          fontWeight: 'bold',
                          marginTop: '15%',
                        }}
                        onClick={() => switchToAthena()}
                      >
                        <Row align="top">
                          <Col span={4}>
                            {' '}
                            <CommentOutlined
                              style={{
                                marginRight: '15px',
                                fontSize: '55px',
                                color: 'white',
                                marginTop: '13px',
                              }}
                            />
                          </Col>
                          <Col offset={1} span={17}>
                            Dive into Existing Campaigns <br />
                            <span
                              style={{
                                fontSize: '16px',
                                color: 'white',
                                fontWeight: 'normal',
                              }}
                            >
                              Continue where you left off{' '}
                            </span>
                          </Col>
                        </Row>
                      </Button>
                    </div>
                  )}
                </Card>
              </div>
            </div>
          )}
          {(formStart || isNewUser) && (
            <div className="split-container">
              <div className="left">
                <Card
                  hoverable
                  style={{
                    width: '75vw',
                    borderRadius: '10px',
                    padding: '25px',
                    backgroundColor: '#3c3d49',
                    minHeight: '45vh',
                  }}
                  cover={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <CaretLeftOutlined
                        disabled={loadingCreatingButton}
                        onClick={() => {
                          if (!loadingCreatingButton) {
                            setLoadingForm(false);
                            setFormStart(false);
                          }
                        }}
                        style={{
                          fontSize: '60px',
                          color: loadingCreatingButton ? 'gray' : '#4B79FF',
                          marginRight: '20px',
                        }}
                      />
                      <LanguageDesktopSelector data={data} setData={setData} />
                    </div>
                  }
                >
                  {true && (
                    <MainForm
                      data={data}
                      setData={setData}
                      loadingCreatingButton={loadingCreatingButton}
                      setLoadingCreatingButton={setLoadingCreatingButton}
                      isStarterFlow={isStarterFlow}
                      setStarterFlow={setStarterFlow}
                    />
                  )}
                </Card>
              </div>
            </div>
          )}
        </div>
      )}
      {isStarterFlow === 'CHATATHENA' && (
        <ChatAthenaWeb
          isDarkMode={isDarkMode}
          isNewUser={isNewUser}
          setDarkMode={setDarkMode}
          isMobile={false}
          setStarterFlow={setStarterFlow}
        />
      )}
    </div>
  );
};
