/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-unresolved */
import createInstance from 'Config/AxiosConfig';
import { store } from 'redux/store';

const getReduxStore = () => {
  const state = store.getState();
  return state;
};

export const getDestinationForLevel = async (level) => {
  const reduxStore = getReduxStore();
  try {
    const body = {
      username: reduxStore.user.username,
      name: reduxStore.workspace.name,
      mrlevel: level,
    };

    const instance = await createInstance();
    const { data } = await instance.post('check-onboarding', body);
    if (data.statusCode === 500) {
      return {
        destination: '/',
        mrr_filled: false,
        error: true,
        showPopup: false,
      };
    }
    if (data.goto_onboarding) {
      return {
        destination: '/onboarding-qna-level-2',
        mrr_filled: data.mrqna,
        error: false,
        showPopup: true,
      };
    }
    return {
      destination: '/market-research/desire',
      mrr_filled: false,
      error: false,
      showPopup: false,
    };
  } catch (error) {
    console.log(error);
    return {
      destination: '/',
      mrr_filled: false,
      error: true,
      showPopup: false,
    };
  }
};
