import React from 'react';
import KeyboardDoubleArrowDownRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowDownRounded';
import styles from './index.module.css';

const ScrollToBottomButton = ({ element }) => (
  <div
    className={styles.scroll_button}
    onClick={() => {
      element.scrollTo({
        top: element.scrollHeight - element.clientHeight,
        behavior: 'smooth',
      });
    }}
  >
    <KeyboardDoubleArrowDownRoundedIcon />
  </div>
);

export default ScrollToBottomButton;
