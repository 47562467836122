/* eslint-disable max-len */
import React, { createContext, useContext, useMemo, useState } from 'react';

export const ContentContext = createContext();

export const ContentProvider = ({ children }) => {
  const [Country, setCountry] = useState('US');
  const [Topic, setTopic] = useState('');
  const [ShowContentTools, setShowContentTools] = useState(false);

  const contentContextProviderValue = useMemo(
    () => ({ country: [Country, setCountry], topic: [Topic, setTopic], contentToolsVisiblity: [ShowContentTools, setShowContentTools] }),
    [Country, setCountry, Topic, setTopic, ShowContentTools, setShowContentTools]);

  return (
    <ContentContext.Provider value={contentContextProviderValue}>
      {children}
    </ContentContext.Provider>
  );
};

export const useContentContext = () => {
  const context = useContext(ContentContext);

  if (context === undefined) {
    throw new Error('useContentContext must be used within a ContentContextProvider');
  }

  return context;
};
