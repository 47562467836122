import styled from 'styled-components';

export const TagStatus = styled.span.attrs((props) => ({

  active: props.active || null,
}))`    
    display:flex;
    flex-direction:row;
      cursor: default;
      margin: 0 0.7rem;
      font-size: 14px;
      padding-right:0.3rem;
      font-weight: 500;
      color: ${(props) => (props.active ? '#2a9df5' : null)};
      border-bottom:  ${(props) => (props.active ? '2px solid #2a9df5' : null)};
  
  `;

export const CheckboxWrapper = styled.div`

display:flex;
align-items: center;
`;

export const FinalListWrapper = styled.div`
    padding:4px;
    box-shadow: 0 0 3px #d7d7d7;
    border-radius:3px;
    flex:0.95;
    margin-bottom:6px;
    overflow-y: scroll;

`;

export const KeywordListWrapper = styled.div`
    padding:4px;
    border-radius:3px;
    flex:0.95;
    margin-bottom:6px;
    height:300px;
    overflow-y: scroll;

`;

export const SaveButton = styled.button`

    background-color: #2a9df5;
    color: white;
    font-weight: bold;
    flex:0.05;
    border-radius: 4px;
`;

export const TabContentWrapper = styled.div`
    overflow-y:scroll;
    padding:4px;
    box-shadow: 0 0 3px #d7d7d7;
    border-radius:3px;
    margin-right:12px;
`;

export const CloudWordWrapper = styled.div`


    display:inline-block;
    width:fit-content;
    margin-top:4px;
    margin-bottom:4px;
    justify-content:center;
    margin-right:8px;
    padding:6px;
    border-radius:4px;
    align-items:center;
    font-weight:italic;
    background-color: #ebf5ff;

`;

export const WordWrapper = styled.div`
        display:flex;
        flex-direction: row;
        align-items: center;
`;

export const InputWrapper = styled.div`
    cursor: pointer;
    display :flex;
    align-items: center;
    padding:0px 2px;
    margin-bottom:16px;
    width:80%;
`;

export const ResultWrapper = styled.div`

    display:flex;
    flex-direction: column;
    font-size: 14px;
`;

export const Input = styled.input`
    border: none;
    border-bottom:1px solid #d4d4d4;
    padding: 0px 4px;
    font-size: 14px;
    flex:1;
`;

export const Filters = styled.div`
font-size: 14px;
color:#2a9df5;
margin-left:6px;
position: relative;

`;

export const DropdownWrapper = styled.div`
    background-color: white;
    font-size:12px;
    margin-top:12px;
    z-index:5;
    box-shadow: 2px;
    border-radius: 4px;
    padding:6px 8px;
    box-shadow: 2px 4px 2px #7f7f7f;
    color:black;
    position: absolute;
    width:max-content;
`;

export const SearchButton = styled.div`

        border-radius: 3px;
        margin-left:12px;
        /* box-shadow: 2px 4px 6px #cccccc ; */
        background-color: #2a9df5;
        color:white;
        font-size: 14px;
        padding:4px 8px;
        letter-spacing: 1px;
        cursor: pointer;
`;

export const ResultBox = styled.div`

    display:flex;
    flex-direction: column;
    margin-bottom:16px;
`;

export const QAWrapper = styled.div`

    display:flex;
    align-items: flex-start;
`;

export const Text = styled.div`


`;

export const KeywordResearchWrapper = styled.div`

    height:100%;
    display:flex;
    justify-content:center;
    align-items: center;
`;

export const KeywordInput = styled.input`

    border-radius: 4px;
    border:1px solid #afafaf;
    flex:0.7;
    padding:2px 4px;

`;
export const AddKeywordButtonDisabled = styled.div`

    border-radius: 4px;
    background-color: #e0e0e0;
    color: white;
    padding:3px 8px;
    margin-left: 6px;
    cursor: pointer;
`;

export const AddKeywordButton = styled.div`

    border-radius: 4px;
    background-color: #2a9df5;
    color: white;
    padding:3px 8px;
    margin-left: 6px;
    cursor: pointer;
`;
export const UpdateKeywordButton = styled.button`

    width:100%;
    background-color: #2a9df5;
    border-radius: 4px;
    margin-bottom:8px;
    color :white;
    justify-content: center;
    padding: 4px 8px;
`;

export const LimitReachedContainer = styled.div`
    background: #ffd2d2;
    border-radius: 4px;
    padding: 2px 4px;
    margin-bottom: 6px;
    color: #cf1010;
    font-size: 14px;
`;
