/* eslint-disable no-empty */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import DropdownArrow from 'Component/../../src/assets/images/dropdown-arrow.svg';
import DropUpArrow from 'Component/../../src/assets/images/dropup-arrow.svg';
import LoaderSpin from 'Component/Shared/Loader';
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import axios from 'axios';
import { baseAPIsUrl } from 'Config/Api';
import ToggleButton from 'react-toggle-button';
import { fetchSubCategoryHelper } from './insightsLogic';

function SubCategoryDescription({ subCategoryName }) {
  const [
    IsSubCtegoryDescriptionFetched,
    setIsSubCategoryDescriptionFetched,
  ] = useState();
  const [hasDescription, sethasDescription] = useState(false);
  const [athenaVideoUrl, setAthenaVideoUrl] = useState('');
  const [athenaHelperText, setAthenaHelperText] = useState('');
  const [toggleValue, setToggleValue] = useState({ value: false });

  useEffect(() => {
    handleCollapsibleHelper(true);
    getAthenaHelperData();
    setToggleValue({ value: true });
  }, [subCategoryName]);

  const handleCollapsibleHelper = (isCollasped) => {
    sethasDescription(isCollasped);
    if (isCollasped) getAthenaHelperData();
  };

  const getAthenaHelperData = async () => {
    setIsSubCategoryDescriptionFetched(false);
    try {
      const response = await axios.post(`${baseAPIsUrl}/athena-helper-text`, {
        category_name: subCategoryName,
      });
      if (response?.data?.statusCode === '200' && response?.data?.data) {
        const output = response?.data?.data;
        setAthenaVideoUrl(output?.athena_video_link);
        setAthenaHelperText(
          output?.helper_text_athena.replaceAll('\n', '<br />'),
        );
        hasDescription(true);
      }
    } catch (err) {
    } finally {
      setIsSubCategoryDescriptionFetched(true);
    }
  };

  const handleToggle = (value) => {
    setToggleValue({ value: !value });
  };

  return (
    <>
      <div className="description-head">
        <div className="description-left">
          <span>Help:</span>
          <span>{subCategoryName}</span>
        </div>
        <ToggleButton
          inactiveLabel="Text"
          activeLabel="Video"
          value={toggleValue.value || false}
          onToggle={(value) => {
            handleToggle(value);
          }}
          colors={{
            activeThumb: {
              base: 'rgb(255, 255, 255)',
            },
            inactiveThumb: {
              base: 'rgb(255, 255, 255)',
            },
            active: {
              base: 'rgba(245, 0, 187, 0.00)',
              hover: 'rgb(14, 26, 53)',
            },
            inactive: {
              base: 'rgb(14, 26, 53)',
              hover: 'rgba(245, 0, 187, 0.00)',
            },
          }}
        />

        {hasDescription ? (
          <img
            onClick={() => handleCollapsibleHelper(false)}
            src={DropUpArrow}
          />
        ) : (
          <img
            onClick={() => handleCollapsibleHelper(true)}
            src={DropdownArrow}
          />
        )}
      </div>
      {hasDescription && !toggleValue.value && (
        <div className="mrr-main-right-top">
          {IsSubCtegoryDescriptionFetched ? (
            <div
              className="description-details"
              dangerouslySetInnerHTML={{
                __html: athenaHelperText,
              }}
            />
          ) : (
            <div className="data-load-wait-display">
              <LoaderSpin height="50px" width="50px" />
              <p>Curating Helper</p>
            </div>
          )}
        </div>
      )}
      {hasDescription && toggleValue.value && (
        <div className="mrr-main-right-top-video">
          <ReactPlayer
            url={athenaVideoUrl}
            // playing={true}
            width="100%"
            height="100%"
            controls
            loop
            muted
            config={{ file: { attributes: { controlsList: 'nodownload' } } }}
          />
        </div>
      )}
    </>
  );
}

export default SubCategoryDescription;
