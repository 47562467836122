import React from 'react';
import { Divider } from 'components/common/divider';
import { LoadingPlaceholder } from '../LoadingPlaceholder';
import { CategoryContainer } from './styles';

const MarketCategoriesSkeleton = ({ index }) => (
  <>
    {index > 0 && <Divider />}
    <CategoryContainer>
      <LoadingPlaceholder height="12px" width="12px" borderRadius="50%" marginRight="12px" />
      <LoadingPlaceholder height="14px" width="70%" marginRight="12px" />
      <LoadingPlaceholder height="16px" width="16px" borderRadius="50%" />
    </CategoryContainer>
  </>

);

export const MarketCategoriesLoading = () => (

  <div>
    {Array(4).fill(null).map((value, index) => (
      <MarketCategoriesSkeleton key={index} index={index} />
    ))}
  </div>

);
