export const NEW_BRAND_VOICE = 'NEW BRAND VOICE';
export const SAVED_BRAND_VOICES = 'SAVED BRAND VOICES';
export const CATEGORY_DESCRIPTION = 'CATEGORY DESCRIPTION';

export const TABS = [NEW_BRAND_VOICE, SAVED_BRAND_VOICES, CATEGORY_DESCRIPTION];

export const CATEGORY_NAMES = [
  { name: 'creative_theme', label: 'Creative Theme' },
  { name: 'literary_device', label: 'Literary Device' },
  { name: 'mimic_writing_style', label: 'Mimic Writing Style' },
  { name: 'personality_types', label: 'Personality Types' },
];

export const MANAGE_TEMPLATES_FILTER_OPTIONS = [
  'All',
  'Success MindsetMap',
  'Instagram',
  'Facebook',
  'Twitter',
  'Top-of-the-Funnel',
  'Bottom-of-the-Funnel',
  'Middle-of-the-Funnel',
  'Psychological Framework',
  'Influencer Marketing',
  'Copywriting',
  'Email Marketing',
  'LinkedIn',
  'YouTube',
  'Coding',
  'Cold DM',
  'Cold Email',
  'FUN',
  'Growth Hacking',
  'Health',
  'Newsletter Article',
  'Recipes',
  'Role Play',
  'SEO',
  'Other',
  'Hypnotic-NLP',
  'Donald Miller: StoryBrand Script',
  'Content Editing Prompt',
  'Market Research',
  'Business Idea Validation',
];
