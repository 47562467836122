import { ScaffoldCard } from 'components/common/scaffold-card';
import React from 'react';
import { Divider } from 'components/common/divider';
import styles from './fred-market.module.css';

function FredQA({ qnas }) {
  return (
    <ScaffoldCard className={styles.qna_wrapper}>
      {qnas && qnas.map((qna, index) => (
        <div className={styles.qna_container}>
          {index > 0 && <Divider />}
          <div className={styles.qna_question}>{qna?.question}</div>
          <div className={styles.qna_answer}>{qna?.answer}</div>
        </div>
      ))}
    </ScaffoldCard>
  );
}

export default FredQA;
