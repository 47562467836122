import createInstance from 'Config/AxiosConfig';

const saveBrandVoice = async (body) => {
  const instance = await createInstance();

  const { data } = await instance.post('/save-brand-voice', body);
  if (data.statusCode !== '200') {
    throw new Error('Error saving brand voice');
  }
  return true;
};

export default saveBrandVoice;
