/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';
import { css } from '@emotion/core';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import BarLoader from 'react-spinners/PulseLoader';

function ChangePassword(props) {
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loader, setLoader] = useState(true);
  const [isShowPass, setIsShowPass] = useState(true);
  const [inputType, setInputType] = useState(true);
  const [err, setErr] = useState('');

  function handleChangePassword(event) {
    setErr('');
    setPassword(event.target.value);
  }

  function handleChangeOldPassword(event) {
    setErr('');
    setOldPassword(event.target.value);
  }

  function handleChangeConfirmPassword(event) {
    setErr('');
    setConfirmPassword(event.target.value);
  }

  async function handleChangeClick(event) {
    event.preventDefault();
    console.log('Change');
    setLoader(true);

    if (oldPassword === '' || password === '' || confirmPassword === '') {
      setLoader(false);
      setErr('All Fields Are Mandatory');
    } else if (!password.match(/^(?=.*[@$!%*?&])/i)) {
      setLoader(false);
      setErr('Password Should have One Special Charcter');
    } else if (!password.match(/[A-Z]/)) {
      setLoader(false);
      setErr('Password Should have One Capital Letter');
    } else if (!password.match(/[a-z]/)) {
      setLoader(false);
      setErr('Password Should have One Lower Case Charcter');
    } else if (!password.match(/^(?=.*?[0-9])/i)) {
      setLoader(false);
      setErr('Password Should have One Number');
    } else if (password !== confirmPassword) {
      setLoader(false);
      setErr('New Password And Confirm Password Should Match');
    } else {
      try {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(user, oldPassword, password);
        console.log('Password change successful');
        alert('Password has been changed successfully');
        navigate(-1); // Navigate back to the previous page
      } catch (err) {
        setLoader(false);
        if (
          err.message === 'Attempt limit exceeded, please try after some time.'
        ) {
          setErr('Attempt limit exceeded, please try after some time');
        } else {
          setErr('Incorrect Old Password');
        }
      }
    }
  }

  function handlePasswordEye() {
    setInputType(!inputType);
  }

  function handleShowPassword() {
    setIsShowPass(!isShowPass);
  }

  return (
    <>
      <div className="auth-container">
        <div className="container-wrap">
          <div className="panels-container">
            <div className="panel left-panel">
              <img src="img/login.png" className="image" alt="" />
            </div>
            <div className="signin-signup">
              <form onSubmit={handleChangeClick} className="loginfrm">
                <div className="row middleflx">
                  <div
                    className="col-sm-6"
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <div style={{ width: '100%', marginBottom: '30px' }}>
                      <span
                        style={{ paddingLeft: '20px', fontSize: '22px' }}
                        onClick={() => navigate(-1)}
                      >
                        <i
                          className="fas fa-arrow-left"
                          style={{ cursor: 'pointer', color: '#007bff' }}
                        >
                          Back
                        </i>
                      </span>
                    </div>
                    <div className="form-wrap">
                      <h2 className="title">Change Password</h2>

                      <div className="input-field">
                        <input
                          type="password"
                          name="oldPassword"
                          onChange={handleChangeOldPassword}
                          value={oldPassword}
                          placeholder="Old Password"
                        />
                      </div>
                      <div className="input-field">
                        <input
                          type={inputType ? 'password' : 'text'}
                          name="password"
                          value={password}
                          onChange={handleChangePassword}
                          placeholder="New Password"
                        />
                        <span
                          className="reveal-password"
                          onClick={handlePasswordEye}
                        >
                          <i
                            className={
                              inputType ? 'fa fa-eye-slash' : 'fa fa-eye'
                            }
                          />
                        </span>
                      </div>
                      <div className="input-field">
                        <input
                          type={isShowPass ? 'password' : 'text'}
                          name="confirmPassword"
                          onChange={handleChangeConfirmPassword}
                          value={confirmPassword}
                          placeholder="Confirm New Password"
                        />
                        <span
                          className="reveal-password"
                          onClick={handleShowPassword}
                        >
                          <i
                            className={
                              isShowPass ? 'fa fa-eye-slash' : 'fa fa-eye'
                            }
                          />
                        </span>
                      </div>
                      <div className="passcriteria">
                        <p>
                          Must Include, At Least: 8 Characters, 1 Upper case, 1
                          Lower case, 1 Number and 1 Special Character
                        </p>
                      </div>
                      <div className="text-center">
                        <button type="submit" className="btnn solid">
                          Submit
                        </button>
                      </div>
                      <div>
                        <p style={{ color: 'red', marginBottom: 0 }}>{err}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="right-logo" style={{ marginTop: '13%' }}>
                      <img src="img/crawlQ-logo.png" alt="CrawlQ logo" />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <BarLoader
        size={20}
        margin={2}
        color="#2C3E50"
        loading={loader}
        css={css`
          position: absolute;
          top: 70%;
          left: 74%;
          border-color: #f5b848;
        `}
      />
    </>
  );
}

export default ChangePassword;
