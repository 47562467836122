import React, { useState } from 'react';
import { DeleteIcon, PersonIcon } from 'components/common/index.js';

import { showNotification } from 'libs/notification';
import styles from './article-card.module.css';
import { deleteBlog } from './services/delete-blog';
import { DeleteBlogModal } from './DeleteBlogModal';

function ArticleCard({
  blogName,
  blogImage,
  blogAuthor,
  blogReadingTime,
  blogScript,
  id,
  datePublished,
}) {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleOpenBlog = () => {
    window.open(`https://app.crawlq.ai/Blog?id=${id}`, '_blank');
  };

  const handleDeleteBlog = async () => {
    setIsDeleting(true);
    const isDeleted = await deleteBlog(blogName);
    setIsDeleting(false);
    setIsDeleteModalOpen(false);
    if (isDeleted) {
      showNotification('success', `${blogName} is Deleted Successfully`);
    } else {
      showNotification('error', 'Failed to Delete Blog');
    }
  };

  return (
    <div className={styles.card_container}>
      <DeleteBlogModal
        blogName={blogName}
        handleDelete={handleDeleteBlog}
        handleCloseDeleteModal={() => {
          setIsDeleteModalOpen(false);
        }}
        isDeleteModalOpen={isDeleteModalOpen}
        processing={isDeleting}
      />
      <img
        className={styles.content_image}
        alt={blogName}
        src={blogImage}
        id={id}
        onClick={handleOpenBlog}
      />

      <div
        className={styles.content_title}
        data-title={blogName}
        onClick={handleOpenBlog}
      >
        {blogName.substring(0, 30)}...
      </div>
      {blogScript && (
        <div className={styles.content_subtitle}>{blogScript}</div>
      )}
      <div className={styles.content_info}>
        {blogAuthor && (
          <div className={styles.content_author_container}>
            <PersonIcon />
            <span>{blogAuthor}</span>
          </div>
        )}
        {blogReadingTime && (
          <div className={styles.content_readtime}>
            <span>{blogReadingTime}</span>
          </div>
        )}
      </div>
      <div className={styles.content_action_container}>
        <p>{datePublished}</p>
        <DeleteIcon
          className={styles.content_delete}
          onClick={() => setIsDeleteModalOpen(true)}
        />
      </div>
    </div>
  );
}

export default ArticleCard;
