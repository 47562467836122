import React, { useState } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import './Topbar.css';
import { ClickAwayListener } from '@mui/material';
import DropDownBox from './Dropdownbox';
import { Flex } from '../GlobalStyles';

function MinifiedTopbar() {
  const [OpenDropdown, setOpenDropdown] = useState(false);

  return (
    <Flex justify={'flex-end'} className="pt-2">
      <div className="topbar-items-container">
        <ClickAwayListener onClickAway={() => setOpenDropdown(false)}>
          <div
            className="topbar-username-container"
            onClick={() => setOpenDropdown(true)}
          >
            <span className="topbar-username">
              <PersonIcon />
            </span>
            {OpenDropdown && <DropDownBox />}
          </div>
        </ClickAwayListener>
      </div>
    </Flex>
  );
}

export default MinifiedTopbar;
