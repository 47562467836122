import createInstance from 'Config/AxiosConfig';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { isValidURL } from 'Component/Shared/utils';
import { GET_ASSISTANTS_QUERY_KEY } from './get-assistants';

export const useMutateImportAssistant = () => {
  const queryClient = useQueryClient();

  const importAssistantByURL = async (url) => {
    try {
      if (!isValidURL(url)) {
        throw new Error('Invalid Assistant URL');
      }

      const instance = await createInstance();
      const { data } = await instance.post('copy-assistant-from-url', {
        url,
      });
      if (data?.statusCode === '200') {
        return data?.url;
      }
      throw new Error('Error in importing assistant');
    } catch (err) {
      console.error(err);
      throw new Error('Error in importing assistant');
    }
  };

  return useMutation(importAssistantByURL, {
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [GET_ASSISTANTS_QUERY_KEY],
      });
      toast.success('Assistant imported successfully', {
        position: 'bottom-center',
        theme: 'dark',
      });
    },
    onError: (error) => {
      toast.error(`${error.message}`, {
        position: 'bottom-center',
        theme: 'dark',
      });
    },
  });
};
