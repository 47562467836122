/* eslint-disable max-len */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-tag-spacing */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/button-has-type */
/* eslint-disable no-return-assign */
/* eslint-disable no-use-before-define */

import { Button, Col, Row, Steps } from 'antd';
import React, { useEffect, useState } from 'react';
import AthenaLogo from 'assets/images/crawlQ-transparent-favicon.png';
import AthenaLogo1 from 'assets/images/Athena3.png';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import ChatAthenaWeb from '../ChatAthenaController/ChatAthenaWeb';
import StarterFlowManager from './StarterFlowManager';

const MiniDashboard = (props) => {
  const { isDarkMode, setDarkMode, isStarterFlow, setStarterFlow } = props;

  const renderMiniDashboard = () => (
    <div
      style={{
        height: '100vh',
        backgroundColor: 'white',
        paddingTop: '50%',
      }}
    >
      <div
        style={{
          marginLeft: '10%',
          fontWeight: 'bold',
          color: '#5887FB',
          marginBottom: '15px',
          fontSize: '25px',
        }}
      >
        Welcome to CrawlQ Mobile !
      </div>
      <div style={{ backgroundColor: 'white' }}>
        <img
          src={AthenaLogo}
          alt="Athena Logo"
          style={{
            display: 'inline-block',
            width: '30%',
            marginLeft: '30%',
            marginBottom: '15px',
          }}
        />
      </div>

      <br />
      <Row>
        <Col
          span={11}
          style={{
            backgroundColor: '#5887FB',
            padding: '10px',
            fontWeight: 'bold',
            borderRadius: '10px',
            marginLeft: '5px',
            color: 'white',
          }}
          onClick={() => setStarterFlow('CHATATHENA')}
        >
          <img
            src={AthenaLogo1}
            alt="Athena Logo"
            style={{
              display: 'inline-block',
              // width: '30%',
              // marginLeft: '30%',
              // marginBottom: '15px',
            }}
          />
          <div>Start ChatAthena</div>
        </Col>
        <Col
          span={11}
          offset={1}
          style={{
            backgroundColor: '#5887FB',
            padding: '10px',
            fontWeight: 'bold',
            borderRadius: '10px',
            color: 'white',
          }}
          onClick={() => setStarterFlow('FLOW')}
        >
          <img
            src={AthenaLogo1}
            alt="Athena Logo"
            style={{
              display: 'inline-block',
              // width: '30%',
              // marginLeft: '30%',
              // marginBottom: '15px',
            }}
          />
          <div>Start OnBoarding</div>
        </Col>
      </Row>
    </div>
  );

  return (
    <div>
      {false && <>{renderMiniDashboard()}</>}
      {isStarterFlow === 'FLOW' && (
        <StarterFlowManager
          setStarterFlow={setStarterFlow}
          isStarterFlow={isStarterFlow}
        />
      )}
      {isStarterFlow === 'CHATATHENA' && (
        <ChatAthenaWeb
          isDarkMode={isDarkMode}
          setDarkMode={setDarkMode}
          isMobile={true}
          setStarterFlow={setStarterFlow}
          isStarterFlow={isStarterFlow}
        />
      )}
    </div>
  );
};

export default MiniDashboard;
