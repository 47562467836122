/* eslint-disable indent */
import { Tooltip } from 'antd';
import { customerPortal } from 'Component/Pages/Pricing/PricingLogic';
import LoaderSpin from 'Component/Shared/Loader';
import createInstance from 'Config/AxiosConfig';
import { Button } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import React, { useEffect, useState } from 'react';
import { addOnCheckout } from 'utils/get-add-on-checkout';
import { PlanType } from 'libs/enums/planType.enum';
import { userLogOut } from 'redux/index';
import { Auth } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { useGetUsersAddOns } from 'queries/user-account/services/get-user-addons';
import { Flex } from 'Component/Shared/GlobalStyles';
import { userPlanUpdate } from '../../../redux/User/UserActions';
import ConfirmCancelPopup from './ConfirmCancelPopup';
import './Profile.css';
import {
  cancelAddon,
  getNameInitials,
  getUpdateduserPlan,
  redeemAppSumoCode,
} from './ProfileLogic';
import CancellationMessage from './CacellationMessage';
import { InsightsPurchaaseButton } from './InsightsButton';

const allowedPlansForFreeQuoraRedditAddons = [
  'Startup (Monthly)',
  'Startup (Yearly)',
  'Growth Consulting (Monthly)',
  'Growth Consulting (Yearly)',
  'Agency (Monthly)',
  'Agency (Yearly)',
];

function ButtonCustom({ name, onClick, isLoading }) {
  return (
    <div>
      {!isLoading ? (
        <button type="button" className="profile-btn" onClick={onClick}>
          {name}
        </button>
      ) : (
        <LoaderSpin height={25} width={25} />
      )}
    </div>
  );
}

function InputFeilds({
  name,
  value,
  onChange,
  error = false,
  success = false,
}) {
  return (
    <div className="profile-input-form__group">
      <input
        id={`profile_input_${name}`}
        type="text"
        className="profile-input-form__field"
        placeholder={name}
        value={value}
        onChange={onChange}
      />
      <label
        htmlFor={`profile_input_${name}`}
        className="profile-input-label form__label"
      >
        {name}
      </label>
      {!!error && <small className="text-danger">{error}</small>}
      {!!success && <small className="text-success">{success}</small>}
    </div>
  );
}

function Profile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data: addOnsData, isLoading: isLoadingAddOns } = useGetUsersAddOns();
  const { name, plan, email, modified_date, group } = useSelector(
    (state) => state?.user,
  );
  const workspace = useSelector((state) => state?.workspace);
  const language_engine = !!workspace.language_engine;
  const { language } = workspace;

  const [upgradeSubcriptionData, setUpgradeSubcriptionData] = useState({
    error: false,
    isLoading: false,
  });
  const [appsumoCode, setAppsumoCode] = useState({
    code: '',
    error: false,
    isLoading: false,
    message: '',
    success: false,
  });

  const [addons, setAddons] = useState({
    quora: false,
    reddit: false,
    chatAthenaAddOn: false,
    isLoadingBuyer: false,
  });
  const [addonsCancelled, setAddonsCancelled] = useState({
    quora: null,
    reddit: null,
    chatAthenaAddOn: null,
  });

  const [loadingButtonNo, setLoadingButtonNo] = useState(0);

  const handlelogout = async () => {
    dispatch(userLogOut());
    localStorage.clear();
    await Auth.signOut();
    navigate('/');
  };

  const handleUpgradePlan = async (e) => {
    setUpgradeSubcriptionData({
      ...upgradeSubcriptionData,
      isLoading: true,
    });
    let url = '';
    if (group.includes('cus_')) {
      url = await customerPortal();
    } else {
      // url = 'https://crawlq.ai/limited-period-lifetime-deal-offer/';
      navigate('/pricing', {
        state: {
          email,
          name,
          old_user: true,
          origin: 'chat-athena',
        },
      });
    }
    setUpgradeSubcriptionData((prev) => ({
      ...prev,
      isLoading: false,
    }));
    if (url === '') {
      setUpgradeSubcriptionData((prev) => ({
        ...prev,
        error: true,
      }));
      setTimeout(() => {
        setUpgradeSubcriptionData((prev) => ({
          ...prev,
          error: false,
        }));
      }, 10000);
      return;
    }
    window.open(url);
  };

  const handleManageBilling = async (e) => {
    window.open('https://billing.stripe.com/p/login/14keWId5l8M3c6c7ss');
  };

  const handleRedeemAppSumoCode = async () => {
    if (appsumoCode.code.trim() === '') return;
    setAppsumoCode({
      ...appsumoCode,
      message: '',
      error: false,
      isLoading: true,
    });
    const { error, message } = await redeemAppSumoCode(
      email,
      group,
      appsumoCode.code,
    );
    if (error) {
      setAppsumoCode({
        ...appsumoCode,
        error,
        message,
        isLoading: false,
      });
      return;
    }

    // Moving ahead if code was valid and fetching updated plan details
    const updatedPlan = await getUpdateduserPlan(email);
    if (updatedPlan.error) {
      setAppsumoCode({
        ...appsumoCode,
        error,
        message: 'Please Try Again!',
        isLoading: false,
      });
      return;
    }

    // Updating Redux
    dispatch(
      userPlanUpdate({
        plan: updatedPlan.plan_name,
        modified_date: updatedPlan.modified_date,
        member_limit: updatedPlan.users,
        workspace_limit: updatedPlan.workspaces,
      }),
    );
    setAppsumoCode({
      ...appsumoCode,
      error: false,
      message: 'Code Redeemed Sucessfully!',
      isLoading: false,
      success: true,
    });
  };

  const handleBuyAddon = async (addonId) => {
    setAddons({
      ...addons,
      isLoadingBuyer: true,
    });

    let plans = [PlanType.reddit, PlanType.quora];
    if (addonId === 1) {
      plans = [PlanType.quora];
      setLoadingButtonNo(1);
    }
    if (addonId === 2) {
      plans = [PlanType.reddit];
      setLoadingButtonNo(2);
    }

    await addOnCheckout(plans);
    setAddons({
      ...addons,
      isLoadingBuyer: false,
    });
    setLoadingButtonNo(0);
  };

  const handleChatAthenaAddon = async (interval = 'monthly') => {
    setAddons({
      ...addons,
      isLoadingBuyer: true,
    });
    let planName = PlanType.chatAthena;
    if (interval === 'yearly') {
      planName = PlanType.chatAthenaYearly;
    }

    const plans = [planName];

    await addOnCheckout(plans);

    setAddons({
      ...addons,
      isLoadingBuyer: false,
    });
    setLoadingButtonNo(0);
  };

  const handleCancelAddon = async (addon) => {
    if (addon === PlanType.quora) {
      setLoadingButtonNo(1);
    } else if (addon === PlanType.reddit) {
      setLoadingButtonNo(2);
    } else if (addon === PlanType.chatAthena) {
      setLoadingButtonNo(3);
      if (addons.chatAthenaAddOnYearly) {
        addon = PlanType.chatAthenaYearly;
      }
    }

    const isAddonCanceled = await cancelAddon(addon);
    if (isAddonCanceled) {
      if (addon === PlanType.quora) {
        setAddons({
          ...addons,
          quora: false,
        });
      } else if (addon === PlanType.reddit) {
        setAddons({
          ...addons,
          reddit: false,
        });
      } else if (
        addon === PlanType.chatAthena ||
        addon === PlanType.chatAthenaYearly
      ) {
        setAddons({
          ...addons,
          chatAthenaAddOn: false,
        });
      }
    }
    setLoadingButtonNo(0);
  };

  const handleFetchPlans = () => {
    if (isLoadingAddOns) return;
    if (allowedPlansForFreeQuoraRedditAddons.includes(plan)) {
      setAddons({
        ...addons,
        quora: true,
        reddit: true,
        chatAthenaAddOn: true,
      });
    } else {
      setAddons({
        ...addons,
        quora: addOnsData[PlanType.quora].status,
        reddit: addOnsData[PlanType.reddit].status,
        chatAthenaAddOn: addOnsData[PlanType.chatAthena].status,
        chatAthenaAddOnYearly: addOnsData[PlanType.chatAthenaYearly].status,
      });

      const cancelledPlanDetails = {
        quora: {
          willBeCancelled: !!addOnsData[PlanType.quora].cancel_at,
          cancellationDate: addOnsData[PlanType.quora].cancel_at,
          planEndingDate: addOnsData[PlanType.quora].canceled_at,
        },
        reddit: {
          willBeCancelled: !!addOnsData[PlanType.reddit].cancel_at,
          cancellationDate: addOnsData[PlanType.reddit].cancel_at,
          planEndingDate: addOnsData[PlanType.reddit].canceled_at,
        },
        chatAthenaAddOn: addOnsData[PlanType.chatAthena].cancel_at
          ? {
              willBeCancelled: !!addOnsData[PlanType.chatAthena].cancel_at,
              cancellationDate: addOnsData[PlanType.chatAthena].cancel_at,
              planEndingDate: addOnsData[PlanType.chatAthena].canceled_at,
            }
          : {
              willBeCancelled: !!addOnsData[PlanType.chatAthenaYearly]
                .cancel_at,
              cancellationDate: addOnsData[PlanType.chatAthenaYearly].cancel_at,
              planEndingDate: addOnsData[PlanType.chatAthenaYearly].canceled_at,
            },
      };
      setAddonsCancelled(cancelledPlanDetails);
    }
  };

  useEffect(() => {
    handleFetchPlans();
  }, [isLoadingAddOns, addOnsData]);

  const hasNoActiveAddons = () =>
    !addons.quora &&
    !addons.reddit &&
    !addons.chatAthenaAddOn &&
    !addons.chatAthenaAddOnYearly;

  return (
    <div className="parent-profile">
      <div className="profile-body">
        {/* <div className="profile-header">
          <b>
            {' '}
            <ArrowBackIcon onClick={() => navigate(-1)} /> Profile
          </b>
          <span className="font-accent txt-primary">User&apos;s Detail</span>
        </div> */}

        <div className="profile-container-badge ">
          <div className="profile-container">
            <div className="profile-name-img">
              <div className="profile-image">
                <div className="notranslate">{getNameInitials(name)}</div>
              </div>
              <div className="profile-email-group-container">
                <div className="profile-name">
                  <b>{name}</b>
                  <span className="notranslate">{email}</span>
                </div>
                <div
                  className="profile-name"
                  style={{ alignItems: 'flex-end' }}
                >
                  <b>Group Name</b>
                  <span className="notranslate">{group}</span>
                </div>
                <div
                  className="profile-name"
                  style={{ alignItems: 'flex-end' }}
                >
                  <Button
                    variant="contained"
                    color="error"
                    startIcon={<LogoutIcon />}
                    onClick={handlelogout}
                    size="small"
                  >
                    Log Out
                  </Button>
                </div>
              </div>
            </div>
            <div className="profile-details-editable">
              <div className="profile-password">
                <div>
                  <span>Password</span>
                  <span>••••••••••••••</span>
                </div>
                <div>
                  <Link to="/ChangePassword">
                    <ButtonCustom name="Change" />
                  </Link>
                </div>
              </div>

              <div className="profile-details">
                <InputFeilds
                  name="Enter Code"
                  onChange={(e) =>
                    setAppsumoCode({
                      ...appsumoCode,
                      code: e.target.value,
                      error: false,
                    })
                  }
                  value={appsumoCode.code}
                  error={appsumoCode.error ? appsumoCode.message : false}
                  success={appsumoCode.success ? appsumoCode.message : false}
                />
                <div>
                  <ButtonCustom
                    name="Redeem"
                    isLoading={appsumoCode.isLoading}
                    onClick={handleRedeemAppSumoCode}
                  />
                </div>
              </div>
            </div>
            <div className="profile-subscription">
              <div>
                <div>
                  <span>Active Plan</span>
                  <b className="my-2">{plan}</b>
                  <span className="text-muted">{modified_date}</span>
                </div>
                <div>
                  <ButtonCustom
                    onClick={handleUpgradePlan}
                    isLoading={upgradeSubcriptionData.isLoading}
                    name="Manage Plan"
                  />
                  {upgradeSubcriptionData.error && (
                    <small className="text-danger">No Active Plan</small>
                  )}
                  <div style={{ marginTop: '10px ' }}>
                    <ButtonCustom
                      onClick={handleManageBilling}
                      name="Manage Billing"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="profile-subscription profile-addon">
              <div>
                <div>
                  <span>Active Addons</span>
                </div>
                {!isLoadingAddOns ? (
                  <div className="profile-addon-btns">
                    {addons.quora ? (
                      <div>
                        {!addonsCancelled?.quora?.willBeCancelled ? (
                          <ConfirmCancelPopup
                            name="Quora Addon"
                            handleCancel={() =>
                              handleCancelAddon(PlanType.quora)
                            }
                          />
                        ) : (
                          <CancellationMessage
                            addOnName={'"Quora Addon'}
                            cancellationDate={
                              addonsCancelled?.quora?.cancellationDate
                            }
                            planEndingDate={
                              addonsCancelled?.quora?.planEndingDate
                            }
                          />
                        )}
                      </div>
                    ) : (
                      !!addons.reddit && (
                        <ButtonCustom
                          onClick={() => handleBuyAddon(1)}
                          name="Subscribe to Quora Addon"
                          isLoading={loadingButtonNo === 1}
                        />
                      )
                    )}

                    {addons.reddit ? (
                      <div>
                        {!addonsCancelled?.quora?.willBeCancelled ? (
                          <ConfirmCancelPopup
                            name="Reddit Addon"
                            handleCancel={() =>
                              handleCancelAddon(PlanType?.reddit)
                            }
                          />
                        ) : (
                          <CancellationMessage
                            addOnName={'"Reddit Addon'}
                            cancellationDate={
                              addonsCancelled?.reddit?.cancellationDate
                            }
                            planEndingDate={
                              addonsCancelled?.reddit?.planEndingDate
                            }
                          />
                        )}
                      </div>
                    ) : (
                      !!addons.quora && (
                        <ButtonCustom
                          onClick={() => handleBuyAddon(2)}
                          name="Subscribe Reddit Addon"
                          isLoading={loadingButtonNo === 2}
                        />
                      )
                    )}

                    {!addons.isLoadingBuyer ? (
                      <>
                        {hasNoActiveAddons() && (
                          <div className="profile-addon-btns">
                            <small className="text-danger">
                              No Active Addon
                            </small>
                            <ButtonCustom
                              onClick={handleBuyAddon}
                              name="Subscribe Addons"
                              isLoading={addons.isLoadingBuyer}
                            />
                          </div>
                        )}
                        {!addons.quora && !addons.reddit && (
                          <div className="profile-addon-btns">
                            <ButtonCustom
                              onClick={handleBuyAddon}
                              name="Subscribe to Reddit and Quora Addons"
                              isLoading={addons.isLoadingBuyer}
                            />
                          </div>
                        )}

                        {!addons.chatAthenaAddOn &&
                        !addons.chatAthenaAddOnYearly ? (
                          <Tooltip
                            title={
                              'Upgrade to Pro to Unlock the power of GPT4, Creative Copywriter and Long-form Blog Generator'
                            }
                            color="blue"
                            key={`options-${'option'}`}
                          >
                            <ButtonCustom
                              onClick={handleChatAthenaAddon}
                              name="Upgrade to CrawlQ Pro AddOn (Monthly)"
                              isLoading={addons.isLoadingBuyer}
                            />

                            <ButtonCustom
                              onClick={() => handleChatAthenaAddon('yearly')}
                              name="Upgrade to CrawlQ Pro AddOn (Yearly)"
                              isLoading={addons.isLoadingBuyer}
                            />
                          </Tooltip>
                        ) : (
                          <div>
                            {!addonsCancelled?.chatAthenaAddOn
                              ?.willBeCancelled ? (
                              <ConfirmCancelPopup
                                name="CrawlQ Pro AddOn"
                                handleCancel={() =>
                                  handleCancelAddon(PlanType.chatAthena)
                                }
                              />
                            ) : (
                              <CancellationMessage
                                addOnName={'CrawlQ Pro'}
                                cancellationDate={
                                  addonsCancelled?.chatAthenaAddOn
                                    ?.cancellationDate
                                }
                                planEndingDate={
                                  addonsCancelled?.chatAthenaAddOn
                                    .planEndingDate
                                }
                              />
                            )}
                          </div>
                        )}

                        {addons?.chatAthenaAddOn &&
                          !addons?.chatAthenaAddOnYearly && (
                            <Tooltip
                              title={
                                'Upgrade to Yearly Plan to Unlock the power of GPT4, Creative Copywriter and Long-form Blog Generator'
                              }
                              color="blue"
                              key={`options-${'option'}`}
                            >
                              <ButtonCustom
                                onClick={() => handleChatAthenaAddon('yearly')}
                                name="Upgrade to CrawlQ Pro AddOn (Yearly)"
                                isLoading={addons.isLoadingBuyer}
                              />
                            </Tooltip>
                          )}
                      </>
                    ) : (
                      <Flex
                        className="w-100"
                        direction={'center'}
                        justify={'center'}
                      >
                        <LoaderSpin />
                      </Flex>
                    )}
                    <InsightsPurchaaseButton />
                  </div>
                ) : (
                  <div className="profile-page-loader">
                    <LoaderSpin />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="profile-badge" />
        </div>
      </div>
    </div>
  );
}

export default Profile;
