/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable import/no-unresolved */

import axios from 'axios';
import createInstance from 'Config/AxiosConfig';

import { store } from 'redux/store';

const getUser = () => {
  const state = store.getState();
  const { user } = state;
  return user;
};

const fetchDemoWorkspace = async () => {
  try {
    const { data } = await axios.get('https://apis.crawlq.ai/projects-all?username=support@quantamixsolutions.com');

    if (data.statusCode === '200') {
      return ({
        result: data.data.data,

      });
    }
    return ({ result: false });
  } catch (ex) {
    console.log('Error in Display Table', ex);
    return ({ result: false });
  }
};

export const fetchUserWorkspace = async () => {
  try {
    const { username } = getUser();
    const instance = await createInstance();
    const { data } = await instance.get(`projects-all?username=${username}`);

    if (data.statusCode === '200') {
      if (Object.keys(data.data.paidworkspaces).length === 0) {
        return {
          result: data.data.data,
          paid_workspaces: [],
        };
      }
      return {
        result: data.data.data,
        paid_workspaces: data.data?.paidworkspaces?.demos,
        dfys_workspaces: data.data?.paidworkspaces?.dfys,
      };
    }
    return { result: false };
  } catch (ex) {
    console.log('Error in Display Table', ex);
    return { result: false };
  }
};

export const fetchUserPlan = async () => {
  try {
    const { username } = getUser();
    const instance = await createInstance();
    const response = await instance.get(`dashboard-userplan?username=${username}`);
    const data = response.data.data[0];

    const user_plan = data.Description;

    const user_plan_details = {
      plan: user_plan,
      group_name: data.GroupName,
      creation_date: data.CreationDate.slice(0, 10),
      allowed_users: data.users,
      allowed_projects: data.workspaces,
      modified_date: data.LastModifiedDate.slice(0, 10),
    };
    console.log(user_plan_details);
    return { data: user_plan_details, error: false };
  } catch (ex) {
    console.log('Error in Getting Plan', ex);
    return { data: null, error: true };
  }
};

export default fetchDemoWorkspace;
