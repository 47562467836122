import styled, { keyframes, css } from 'styled-components';

const px = (num) => (typeof num === 'number' ? `${num.toString(10)}px` : num);

const pulseAnimation = keyframes`
    0% { opacity:1;}
    50% {opacity:0.3;}
    100% {opacity:1}
`;

export const LoadingSkeleton = styled.div`
    height: ${(props) => px(props.height ?? '100%')};
    width: ${(props) => px(props.width ?? '100%')};
    margin-left : ${(props) => px(props.marginLeft ?? '0px')};
    margin-top : ${(props) => px(props.marginTop ?? '0px')};
    margin-right : ${(props) => px(props.marginRight ?? '0px')};
    margin-bottom : ${(props) => px(props.marginBottom ?? '0px')};
    border-radius: ${(props) => px(props.borderRadius ?? '4px')};
    background-color: var(--loading-highlight);
    ${(props) =>
    props.isAnimated &&
    css`
      animation-name: ${pulseAnimation};
      animation-duration: ${props?.animationDuration ?? '1s'};
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
    `}
`;
