import { STATUS } from 'constants/workspace/persona-library';
import React from 'react';
import PropTypes from 'prop-types';
import { getFirstLetterCapital } from 'utils/helpers';
import { LoadingIcon, FailIcon, CompleteIcon, PendingIcon } from './styles';

const StatusIndicator = ({ stage, status }) => (
  <div>
    <div className="persona-progress-status-indicator">
      {status === STATUS.PENDING && <PendingIcon />}
      {status === STATUS.INPROGRESS && <LoadingIcon />}
      {status === STATUS.FAILED && <FailIcon />}
      {status === STATUS.COMPLETE && <CompleteIcon />}
      <div>{getFirstLetterCapital(stage)}</div>
    </div>
  </div>
);

StatusIndicator.propTypes = {
  stage: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

export default StatusIndicator;
