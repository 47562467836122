/* eslint-disable indent */
import { ASSISTANT_HEADER_TITLE } from 'constants/assistants';
import React, { useState } from 'react';
import { useNavigate, Link, Outlet, useLocation } from 'react-router-dom';

import AppHeaderWrapper from 'components/common/AppHeader/AppHeaderWrapper';
import { HeaderCard } from 'components/common/HeaderCard/HeaderCard';
import { PlusIcon, Insights360Icon } from 'components/common';
import { ROUTES } from 'constants/routes';
import Flyout from 'components/common/FlyOut/FlyOut';
import { InsightsProvider } from 'context/assistants/InsightsContext';
import { ToastContainer } from 'react-toastify';
import Insights from './assistants-actions/insights-360/Insights';
import 'react-toastify/dist/ReactToastify.css';
import styles from './assistants.module.css';

const getTitle = (currentPath) => {
  if (ROUTES.CREATE_ASSISTANTS === currentPath) {
    return ASSISTANT_HEADER_TITLE.CREATE_ASSISTANT;
  }
  if (ROUTES.EDIT_ASSISTANTS === currentPath) {
    return ASSISTANT_HEADER_TITLE.EDIT_ASSISTANT;
  }
  return ASSISTANT_HEADER_TITLE.ASSISTANTS_MANAGER;
};

const Assistants = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const headerTitle = getTitle(location?.pathname);

  const [IsInsightsFlyoutOpen, setIsInsightsFlyoutOpen] = useState(false);

  const handleInsights360FlyoutClose = () => {
    setIsInsightsFlyoutOpen(false);
  };
  return (
    <>
      {/* <Flyout
        open={IsInsightsFlyoutOpen}
        title="Insights360ai"
        isRight
        onClose={() => handleInsights360FlyoutClose()}
      >
        <InsightsProvider>
          <Insights />
        </InsightsProvider>
      </Flyout> */}
      <ToastContainer />
      <AppHeaderWrapper className={styles.assistants}>
        <HeaderCard
          className={styles.assistant_header_container}
          title={headerTitle}
          description="Boost your productivity with our personalised Assistants"
        >
          <div className={styles.action_buttons_container}>
            {location?.pathname !== ROUTES.CREATE_ASSISTANTS && (
              <div className={styles.assistant_new_container}>
                <Link
                  to={ROUTES.CREATE_ASSISTANTS}
                  className={styles.create_new_assistant_container}
                >
                  <PlusIcon />
                  <span>New Assistant</span>
                </Link>
              </div>
            )}

            {(location?.pathname === ROUTES.CREATE_ASSISTANTS ||
              location?.pathname === ROUTES.EDIT_ASSISTANTS) && (
                <div
                  className={styles.assistant_new_container}
                  onClick={() => navigate('/insights-360-ai')}
                >
                  <button
                    type="button"
                    className={styles.insights_button}
                  >
                    <Insights360Icon active />
                    <span> Insights360ai</span>
                  </button>
                </div>
              )}
          </div>
        </HeaderCard>
        <div className={styles.outlet_container}>
          <Outlet />
        </div>
      </AppHeaderWrapper>
    </>
  );
};

export default Assistants;
