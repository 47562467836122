/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { TABLE_HEADERS } from 'constants/workspace/all-workspace/my-workspace';
import { useNavigate } from 'react-router-dom';
import {
  workspaceSelect,
  userWorkspaceCountUpdate,
  setRecentWorkspace,
} from 'redux/index';
import { useDispatch, useSelector } from 'react-redux';
import { useGetUsersWorkspaces } from 'queries/workspace/get-users-all-workspaces';
import { getMainTopicInterest } from 'queries/get-main-topic-interest';
import { TableRow } from 'components/common/Table/Table';
import { WorkspaceContentLoading } from 'components/skeleton/all-workspace/WorkspaceContentLoading';
import { ScaffoldCard } from 'components/common/scaffold-card';
import { DownArrowIcon, UpArrowIcon, ShareIcon } from 'components/common';
import { LoaderTailSpin } from 'components/common/Loader/Loader';
import ModalContainer from 'components/common/ModalContainer/ModalContainer';
import { getFormattedTimeInDayMonthYear } from 'utils/helpers';
import CloneAction from '../workspace-actions/CloneAction/CloneAction';
import DeleteAction from '../workspace-actions/DeleteAction/DeleteAction';
import { WebsiteIcon, RemoveIcon, CopyIcon, OpenIcon } from '../styles';
import { FavouriteWorkspace } from './FavouriteWorkspace';
import './styles.css';
import ShareAction from '../workspace-actions/ShareAction/ShareAction';

const MyWorkspace = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { email } = useSelector((state) => state?.user);

  const [WorkspaceList, setWorkspaceList] = useState('');
  const [OpeningWorkspace, setOpeningWorkspace] = useState('');
  const [IsModalContainerOpen, setIsModalContainerOpen] = useState(false);
  const [WorkspaceActions, setWorkspaceActions] = useState({
    isDelete: false,
    isClone: false,
    isShare: true,
  });
  const [SelectedWorkspace, setSelectedWorkspace] = useState({
    name: '',
    lang: '',
    site: '',
  });

  const { data: userWorkspaceList, isLoading } = useGetUsersWorkspaces(email);
  const userWorkspaceListLength = userWorkspaceList?.length;

  useEffect(() => {
    const sortedWorkspaceList = userWorkspaceList?.sort((a, b) =>
      a?.is_favourite < b?.is_favourite ? 1 : -1,
    );

    setWorkspaceList(sortedWorkspaceList);
  }, [userWorkspaceList]);

  useEffect(() => {
    if (userWorkspaceList) {
      dispatch(userWorkspaceCountUpdate(userWorkspaceListLength));
    }
  }, [userWorkspaceListLength, userWorkspaceList]);

  const handleDeleteActionClick = (workspaceName) => {
    setIsModalContainerOpen((IsModalContainerOpen) => !IsModalContainerOpen);

    // To set the selected workspace data
    setSelectedWorkspace({
      ...SelectedWorkspace,
      name: workspaceName,
    });

    // To enable Delete Component
    setWorkspaceActions({
      ...WorkspaceActions,
      isDelete: true,
      isClone: false,
      isShare: false,
    });
  };

  const handleCloneActionClick = (
    workspaceName,
    workspaceSite,
    workspaceLang,
  ) => {
    setIsModalContainerOpen((IsModalContainerOpen) => !IsModalContainerOpen);

    // To set the selected workspace data
    setSelectedWorkspace({
      ...SelectedWorkspace,
      name: workspaceName,
      site: workspaceSite,
      lang: workspaceLang,
    });

    // To enable Clone Component
    setWorkspaceActions({
      ...WorkspaceActions,
      isClone: true,
      isDelete: false,
      isShare: false,
    });
  };

  const handleOpenActionClick = async (workspaceDetails) => {
    setOpeningWorkspace(workspaceDetails?.name);

    const mainTopicInterest = await getMainTopicInterest(
      workspaceDetails?.name,
    );

    dispatch(
      setRecentWorkspace({
        name: workspaceDetails?.name,
        domain: workspaceDetails?.site,
        language: workspaceDetails?.Lang,
        language_engine: workspaceDetails?.lang_engine,
        is_trained: workspaceDetails?.is_trained,
        last_trained: workspaceDetails?.last_trained,
        mti: mainTopicInterest,
      }),
    );

    dispatch(
      workspaceSelect({
        name: workspaceDetails?.name,
        domain: workspaceDetails?.site,
        language: workspaceDetails?.Lang,
        language_engine: workspaceDetails?.lang_engine,
        is_trained: workspaceDetails?.is_trained,
        last_trained: workspaceDetails?.last_trained,
        mti: mainTopicInterest,
      }),
    );

    // const workspace = {
    //   workspace_name: workspaceDetails?.name,
    //   workspace_domain: workspaceDetails?.site,
    //   workspace_language_engine: workspaceDetails?.lang_engine,
    //   is_trained: workspaceDetails?.is_trained,
    //   last_trained: workspaceDetails?.last_trained,
    //   mti: mainTopicInterest,
    // };

    // dispatch(
    //   addNewTab({
    //     id: tabs.length + 1,
    //     name: 'Dashboard',
    //     path: '/dashboardd',
    //     workspace,
    //   }),
    // );

    navigate('/chat-athena');
  };

  const handleHeaderClickSorting = (header) => {
    console.log(header);
  };

  const handleShareActionClick = (workspaceName) => {
    setIsModalContainerOpen((IsModalContainerOpen) => !IsModalContainerOpen);

    setSelectedWorkspace({
      ...SelectedWorkspace,
      name: workspaceName,
      site: '',
      lang: '',
    });

    // To enable Share Component
    setWorkspaceActions({
      ...WorkspaceActions,
      isClone: false,
      isDelete: false,
      isShare: true,
    });
  };

  return (
    <div className="my-workspace-wrapper">
      <div
        className="floating-create-campaign-container"
        onClick={() => navigate('/chat-athena-flow')}
      >
        <span className="floating-create-campaign-text">
          Create New Campaign
        </span>
        +
      </div>
      <ModalContainer
        isOpen={IsModalContainerOpen}
        toggleClose={() => setIsModalContainerOpen(false)}
      >
        {WorkspaceActions.isDelete && (
          <DeleteAction
            workspace={SelectedWorkspace}
            toggleClose={handleDeleteActionClick}
          />
        )}
        {WorkspaceActions.isClone && (
          <CloneAction
            workspace={SelectedWorkspace}
            toggleClose={handleCloneActionClick}
          />
        )}

        {WorkspaceActions.isShare && (
          <ShareAction
            workspaceName={SelectedWorkspace?.name}
            toggleClose={() => setIsModalContainerOpen(false)}
          />
        )}
      </ModalContainer>

      <div className="my-workspace-table-container">
        <div className="table-header-container h-margin-b-small">
          {TABLE_HEADERS.map((header) => (
            <span
              key={header}
              className={`table-header ${header}`}
              onClick={() => handleHeaderClickSorting(header)}
            >
              {header}
              {(header === 'NAME' || header === 'CREATION') && (
                <>
                  <DownArrowIcon width="16px" height="16px" secondary />
                  <UpArrowIcon width="16px" height="16px" secondary />
                </>
              )}
            </span>
          ))}
        </div>

        {isLoading && <WorkspaceContentLoading />}

        {WorkspaceList &&
          userWorkspaceList.length > 0 &&
          WorkspaceList.map((workspace, index) => (
            <ScaffoldCard
              key={workspace?.name + index}
              className="my-workspace-table-content h-margin-b-small"
            >
              <TableRow
                value={workspace?.name}
                outerClass="m-w-t-c-name-container"
                innerClass="m-w-t-c-name"
                onClick={() => handleOpenActionClick(workspace)}
              >
                <TableRow
                  icon={<WebsiteIcon />}
                  value={workspace?.site}
                  outerClass="m-w-t-c-site-container"
                  innerClass="m-w-t-c-site"
                />
              </TableRow>

              <TableRow
                value={getFormattedTimeInDayMonthYear(workspace?.date_created)}
                outerClass="m-w-t-c-creation-container"
              />

              <TableRow outerClass="m-w-t-c-action-container">
                <FavouriteWorkspace
                  workspaceName={workspace.name}
                  isFavourite={!!workspace?.is_favourite}
                />
                <RemoveIcon
                  className="h-margin-r-x2"
                  onClick={() => handleDeleteActionClick(workspace?.name)}
                />
                <CopyIcon
                  className="h-margin-r-x2"
                  onClick={() =>
                    handleCloneActionClick(
                      workspace?.name,
                      workspace?.site,
                      workspace?.Lang,
                    )
                  }
                />
                <ShareIcon
                  primary
                  className="h-margin-r-x2"
                  onClick={() => handleShareActionClick(workspace?.name)}
                />
                {OpeningWorkspace === workspace?.name ? (
                  <LoaderTailSpin width={20} />
                ) : (
                  <OpenIcon
                    className="h-margin-r-x2"
                    onClick={() => handleOpenActionClick(workspace)}
                  />
                )}
              </TableRow>
            </ScaffoldCard>
          ))}
      </div>
    </div>
  );
};

export default MyWorkspace;
