import { useQuery } from 'react-query';
import createInstance from 'Config/AxiosConfig';
import { baseAPIsUrl } from 'Config/Api';

const getRemainingCredits = async () => {
  const instance = await createInstance();

  const response = await instance.post(`${baseAPIsUrl}/charge-credits`, {
    amount: 0,
    check: true,
  });

  const responseData = response.data;

  if (responseData.statusCode === '200') {
    const { credits } = responseData;
    const totalCredits = responseData.total_credits;

    if (totalCredits === '∞') {
      return 'Unlimited';
    }
    return totalCredits - credits;
  }

  throw new Error('Failed to get remaining credits');
};

export const GET_REMAINING_CREDITS_KEY = 'get-remaining-credits';

// Query Config Options
const queryOptions = {
  staleTime: 90 * 1000, // 90 secs
};

export const useGetRemainingCredits = () => {
  const queryKey = [GET_REMAINING_CREDITS_KEY];
  return useQuery(queryKey, getRemainingCredits, queryOptions);
};
