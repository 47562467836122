/* eslint-disable no-restricted-syntax */
import axios from 'axios';
import createInstance from 'Config/AxiosConfig';
import { SourceFilter } from 'libs/enums/sourceFilter.enum';

export const startOnboarding = async (onboardingData) => {
  const instance = await createInstance();
  const { data } = await axios.post(
    'https://p8dapk4yf9.execute-api.us-east-2.amazonaws.com/prod/onboard-me-athena',
    onboardingData,
  );
};

const swapKeyValue = (object) =>
  Object.entries(object).reduce(
    (swapped, [key, value]) => ({ ...swapped, [value]: key }),
    {},
  );

export const changeFormatToJSONToArray = (data) => {
  let updatedData = [];

  const SourceFilterSwapped = swapKeyValue(SourceFilter);

  for (const key of Object.keys(data)) {
    if (data[key]) {
      updatedData = [...updatedData, SourceFilterSwapped[key]];
    }
  }
  return updatedData;
};
