/* eslint-disable react/react-in-jsx-scope */
import Modal from 'react-modal';
import styles from './index.module.css';
import { CrossIcon } from '../common-icons';
import { Divider } from '../divider';

const customStyles = {
  content: {
    width: '600px',
    height: '300px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    padding: '6px !important',
    backgroundColor: 'var(--background-color)',
    transform: 'translate(-50%, -50%)',
    maxWidth: '95vw',
  },
};
const NoActionInfoModal = ({ isVisible, close, children, title }) => (
  <Modal isOpen={isVisible} onRequestClose={close} style={customStyles}>
    <div className={styles.container}>
      <div className={styles.title_container}>
        <div className={styles.title}>{title}</div>
        <CrossIcon onClick={close} />
      </div>
      <Divider />
      <div className={styles.content_container}> {children}</div>
    </div>
  </Modal>
);

export default NoActionInfoModal;
