import React, { useEffect, useState } from 'react';
import { useGetAllTemplates } from 'queries/chat-athena/get-all-templates';
import { MANAGE_TEMPLATES_FILTER_OPTIONS } from 'components/constants';
import { sortTemplates } from '../../Workspaces/Workspace/WorkspaceConfigutation/template-selector-helper';
import { PopUpModal } from './PopupModal';

function propmtMapper(data) {
  return {
    name: data.template_name,
    message: data.sections
      .map((section) => section.template_text_prompt)
      .join('\n\n'),
    author_email: data.author_email,
    type: 'SEO',
  };
}

function Prompts({ setMessage, closeModal, darkMode, setSelectedPrompt }) {
  const { data, isLoading } = useGetAllTemplates();
  const [prompts, setPrompts] = useState([]);
  const [tab, setTab] = useState(0);

  const handleModal = () => {
    closeModal();
  };

  const applyTabFilter = (data) => {
    if (tab === 0) return true;

    const searchKey = data.template_name.toLowerCase();
    if (MANAGE_TEMPLATES_FILTER_OPTIONS[tab] === 'Other') {
      const found = MANAGE_TEMPLATES_FILTER_OPTIONS.some((TAB) =>
        searchKey.includes(TAB.toLowerCase()),
      );
      if (found) return false;
      return true;
    }

    if (
      searchKey.includes(MANAGE_TEMPLATES_FILTER_OPTIONS[tab].toLowerCase())
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (data?.length > 0) {
      const sortedPrompts = sortTemplates(data);
      const promptsData = sortedPrompts
        ?.filter(applyTabFilter)
        ?.map(propmtMapper);
      setPrompts([...promptsData]);
    }
  }, [data, tab]);

  return (
    <div>
      <button
        type="button"
        className="d-none"
        id="addon_purchase"
        onClick={handleModal}
      >
        click
      </button>
      <PopUpModal
        darkMode={darkMode}
        showModal
        onRequestClose={handleModal}
        setMessage={setMessage}
        prompts={prompts}
        isLoading={isLoading}
        tab={tab}
        setTab={setTab}
        setSelectedPrompt={setSelectedPrompt}
      />
    </div>
  );
}

export default Prompts;
