import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './index.module.css';
import { GoBackIcon } from '../common-icons';

const GoBack = ({ currentLocation }) => {
  const navigate = useNavigate();

  const handleGoBackClick = () => {
    navigate(-1);
  };
  return (
    <div className={styles.goback_wrapper}>
      <GoBackIcon onClick={handleGoBackClick} />
      <div className={styles.goback_current_location}>{currentLocation}</div>
    </div>
  );
};

export default GoBack;
