/* eslint-disable indent */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-return-assign */
/* eslint-disable no-redeclare */
/* eslint-disable no-plusplus */
/* eslint-disable no-empty */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import { useSelector } from 'react-redux';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import DescriptionIcon from '@mui/icons-material/Description';
import {
  BrowserRouter as Router,
  Link,
  Route,
  Switch,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import SpeedIcon from '@mui/icons-material/Speed';
// import FroalaEditorComponent from 'react-froala-wysiwyg';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Badge from '@mui/material/Badge';

import React, { useState, useContext, useEffect, useRef } from 'react';
import '../css/text_Editor_Components/text_Editor_Components.css';
import 'froala-editor/js/plugins/align.min.js';
import { AiContent } from '../contexts/ai_content';
import LoaderSpin from '../../../Shared/Loader';
import '../css/text_editor.css';
import lodash from 'lodash';
import ReactTooltip from 'react-tooltip';

import { Editor as TinyEditor } from '@tinymce/tinymce-react';
import '../css/text_Editor_Components/text_Editor_Components.css';
import axios from 'axios';
import {
  seoMeAthenaHelper,
  socialMeAthenaHelper,
  writeMeAthenaHelper,
  athenaAuthorityBuilder,
  outlineMeAthenaHelper,
  quoteMeAthenaHelper,
  statMeAthenaHelper,
} from '../../ContentAutomation/AIcontentWriter/AIcontentLogic';
import handleSummerizerForEditor from '../../ContentAutomation/ResultSection/Text-summerizer/TextSummerizerForEditor';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import './ModesDropdown.css';
import PublishButton from 'Component/Pages/ContentAutomation/BlogShare/PublishButton';
import Flyout from 'Component/Shared/Flyout/Flyout';
import AuthorityBuilder from 'Component/Pages/ContentAutomation/ResultSection/AuthorityBuilder/AuthorityBuilder';
import { mrr_data_fill_with_ai } from 'Config/Api';
import { Editor } from '../contexts/summary_Context';
import {
  ButtonPrimary,
  ButtonSecondary,
  ButtonSecondaryColored,
} from 'Component/Shared/GlobalStyles';
import CustomTooltip from 'Component/Shared/CustomTooltip';
import TextImageComponent from './text_image_component';
import { getURLPathBeforeLastSegment } from 'Component/Shared/utils';

export function TextEditor(props) {
  let choosenText = 'car';
  const athena_valid_sources = useSelector(
    (state) => state.user.athena_sources?.train_option,
  );
  const navigate = useNavigate();
  const location = useLocation();
  const { aiContent, setaiContent } = useContext(AiContent);
  const { globalEditor, setglobalEditor } = useContext(Editor);
  const [html, setHtml] = useState(globalEditor);
  const [saving, setSaving] = useState(false);
  const editorRef = useRef(null);
  const [currentWorkspace, setCurrentWorkspace] = useState(
    localStorage.getItem('name'),
  );

  const [isAdvancedMode, setIsAdvancedMode] = useState(
    !location.pathname.includes('/editor'),
  );

  const [textImageWindow, setTextImageWindow] = useState(false);

  const [currentUserName, setCurrentUserName] = useState(
    localStorage.getItem('username'),
  );
  const [SelectedCopy, setSelectedCopy] = useState('');
  const [WriteMeAthenaCreditsFail, setWriteMeAthenaCreditsFail] = useState(
    false,
  );
  const [isAthenaWriting, setIsAthenaWriting] = useState(false);
  const [Sources, setSources] = useState(athena_valid_sources);
  const [menuPop, setmenuPop] = useState(false);
  const [SelectedSources, setSelectedSources] = useState(['General']);
  const [IsAuthorityBuilderOpen, setIsAuthorityBuilderOpen] = useState(false);
  const [SourceArray, setSourceArray] = useState([
    { key: 'General', value: 'General', checked: true },
    { key: 'mrr', value: 'Market Research', checked: false },
    { key: 'mrr_insight', value: 'Market Insights', checked: false },
    { key: 'script', value: 'Sales Copywriting', checked: false },
    { key: 'quora', value: 'Quora', checked: false },
    { key: 'reddit', value: 'Reddit', checked: false },
    { key: 'serp', value: 'Google', checked: false },
    { key: 'website', value: 'URL', checked: false },
  ]);

  const arr = [];
  let preSaveContent = '';
  useEffect(() => {
    // Interval inorder to prevent from slowness of contexts re renders.
    const interval = setInterval(() => {
      setglobalEditor(html);
      handleSave(true); // true is for autosave
    }, 3000);
    if (!window.location.pathname.includes('sales-ai-content-writer')) {
    }
    return () => {
      clearInterval(interval);
    };
  }, []);

  if (typeof aiContent !== 'undefined' && aiContent !== 'error') {
    for (let i = 0; i < aiContent.topics?.length; i++) {
      arr.push({
        keyword: aiContent.topics[i].keyword,
        avg_mention: aiContent.topics[i]['Avg. Mentions'],
      });
    }
  }

  const writeMeAthena = (engine_type) => {
    handleWriteMeAthena(engine_type);
  };

  function update_ngrams_topics(editor_html, topics) {
    const span = document.createElement('span');
    span.innerHTML = editor_html;
    const get_innerText = span.innerText;
    const _ = lodash;
    const sum_topics_number = _.sumBy(topics, (o) => o.avg_mention);

    if (typeof aiContent !== 'undefined' && aiContent !== 'error') {
      for (let i = 0; i < aiContent.topics.length; i++) {
        arr.push({
          keyword: aiContent.topics[i].keyword,
          avg_mention: aiContent.topics[i]['Avg. Mentions'],
        });
      }
    }
  }
  function update_ngrams_topics(editor_html, topics) {
    const span = document.createElement('span');
    span.innerHTML = editor_html;
    const get_innerText = span.innerText;
    const _ = lodash;
    const sum_topics_number = _.sumBy(topics, (o) => o.avg_mention);
    let count_keywords = 0;
    for (let i = 0; i < topics.length; i++) {
      const count_topics = get_innerText.match(
        new RegExp(topics[i].keyword.toLowerCase(), 'g'),
      );
      let val = 0;

      if (count_topics !== null) {
        if (count_topics.length <= topics[i].avg_mention) {
          val = count_topics.length;
        } else {
          val = topics[i].avg_mention;
        }
        count_keywords += val;
      }
    }
    const score = (count_keywords / sum_topics_number) * 100;
    if (document.getElementById('topic_score')) {
      document.getElementById('topic_score').innerHTML = Math.round(score);
    }
  }

  function counter_scores(html) {
    const span = document.createElement('span');
    span.innerHTML = html;
    const text = span.textContent || span.innerText;

    // update values from editor to the result section
    if (document.getElementById('word-count') !== null) {
      document.getElementById('word-count').innerHTML = text.split(' ').length;
      document.getElementById('header-count').innerHTML =
        span.getElementsByTagName('h1').length +
        span.getElementsByTagName('h2').length;
      document.getElementById(
        'img-count',
      ).innerHTML = span.getElementsByTagName('img').length;
    }
  }

  function currentData(model) {
    // console.log(model)
    if (typeof aiContent !== 'undefined') {
      update_ngrams_topics(model, arr);
      counter_scores(model);
      // var myContent = Editor.get('myTextarea').getContent();
      // console.log(myContent)
    }
    setHtml(model);
  }

  const getSelectedText = () => {
    const input = window.tinyMCE.activeEditor.selection.getContent();
    console.log(input);
    choosenText = input;
  };

  const setSelectedData = () => {
    window.tinymce.activeEditor.execCommand(
      'mceInsertContent',
      false,
      `${choosenText} `,
    );
  };

  const handleSocialMeAthena = (engine_type, sources) => {
    console.log('hellosocial');
    const input = window.tinyMCE.activeEditor.selection.getContent();

    if (input.length <= 1) {
      window.tinyMCE.activeEditor.notificationManager.open({
        text: 'Please highlight a sentence or word for Athena',
        type: 'error',
        timeout: 3000,
      });
      setIsAthenaWriting(false);
    } else {
      socialMeAthenaHelper(input, SelectedSources).then(
        ({ credits, data, error }) => {
          if (!credits) {
            setWriteMeAthenaCreditsFail(true);
          } else if (!error) {
            window.tinymce.activeEditor.execCommand(
              'mceInsertContent',
              false,
              `${input} ${data}`,
            );
            // typeWriter(data)
            window.tinymce.activeEditor.execCommand(
              'mceInsertContent',
              false,
              data,
            );
            setIsAthenaWriting(false);

            window.tinyMCE.activeEditor.notificationManager.open({
              text: 'CrawlQ Athena curated Niche related content.',
              type: 'success',
              timeout: 1000,
            });
          }
        },
      );
    }
  };

  const handleOutlineMeAthena = () => {
    const input = window.tinyMCE.activeEditor.selection.getContent();
    const mainTopic = localStorage.getItem('questionTopic');

    if (input.length <= 1) {
      window.tinyMCE.activeEditor.notificationManager.open({
        text: 'Please highlight a sentence or word for Athena',
        type: 'error',
        timeout: 3000,
      });
      setIsAthenaWriting(false);
    } else {
      outlineMeAthenaHelper(input, mainTopic).then(
        ({ credits, data, error }) => {
          if (!credits) {
            setWriteMeAthenaCreditsFail(true);
          } else if (!error) {
            window.tinymce.activeEditor.execCommand(
              'mceInsertContent',
              false,
              `${input} ${data}`,
            );
            // typeWriter(data)
            window.tinymce.activeEditor.execCommand(
              'mceInsertContent',
              false,
              data,
            );
            setIsAthenaWriting(false);

            window.tinyMCE.activeEditor.notificationManager.open({
              text: 'CrawlQ Athena curated Niche related content.',
              type: 'success',
              timeout: 1000,
            });
          }
        },
      );
    }
  };

  const handleQuoteMeAthena = () => {
    const input = window.tinyMCE.activeEditor.selection.getContent();
    const mainTopic = localStorage.getItem('questionTopic');

    if (input.length <= 1) {
      window.tinyMCE.activeEditor.notificationManager.open({
        text: 'Please highlight a sentence or word for Athena',
        type: 'error',
        timeout: 3000,
      });
      setIsAthenaWriting(false);
    } else {
      quoteMeAthenaHelper(input, mainTopic).then(({ credits, data, error }) => {
        if (!credits) {
          setWriteMeAthenaCreditsFail(true);
        } else if (!error) {
          window.tinymce.activeEditor.execCommand(
            'mceInsertContent',
            false,
            `${input} ${data}`,
          );
          // typeWriter(data)
          window.tinymce.activeEditor.execCommand(
            'mceInsertContent',
            false,
            data,
          );
          setIsAthenaWriting(false);

          window.tinyMCE.activeEditor.notificationManager.open({
            text: 'CrawlQ Athena curated Niche related content.',
            type: 'success',
            timeout: 1000,
          });
        }
      });
    }
  };

  const handleStatMeAthena = () => {
    const input = window.tinyMCE.activeEditor.selection.getContent();
    const mainTopic = localStorage.getItem('questionTopic');

    if (input.length <= 1) {
      window.tinyMCE.activeEditor.notificationManager.open({
        text: 'Please highlight a sentence or word for Athena',
        type: 'error',
        timeout: 3000,
      });
      setIsAthenaWriting(false);
    } else {
      statMeAthenaHelper(input, mainTopic).then(({ credits, data, error }) => {
        if (!credits) {
          setWriteMeAthenaCreditsFail(true);
        } else if (!error) {
          window.tinymce.activeEditor.execCommand(
            'mceInsertContent',
            false,
            `${input} ${data}`,
          );
          // typeWriter(data)
          window.tinymce.activeEditor.execCommand(
            'mceInsertContent',
            false,
            data,
          );
          setIsAthenaWriting(false);

          window.tinyMCE.activeEditor.notificationManager.open({
            text: 'CrawlQ Athena curated Niche related content.',
            type: 'success',
            timeout: 1000,
          });
        }
      });
    }
  };

  const handleAthenaBuilder = async () => {
    const input = window.tinyMCE.activeEditor.selection.getContent();
    if (input.length <= 1) {
      window.tinyMCE.activeEditor.notificationManager.open({
        text: 'Please highlight a sentence or word for Athena',
        type: 'error',
        timeout: 3000,
      });
    } else {
      athenaAuthorityBuilder(input).then(({ credits, data, error }) => {
        if (!credits) {
          setWriteMeAthenaCreditsFail(true);
        } else if (!error) {
          window.tinymce.activeEditor.execCommand(
            'mceInsertContent',
            false,
            `${input}
              
              ${data}`,
          );

          window.tinyMCE.activeEditor.notificationManager.open({
            text: 'CrawlQ Athena curated Niche related content.',
            type: 'success',
            timeout: 1000,
          });
        }
      });
    }
  };

  const handleSeoMeAthena = (engine_type, sources) => {
    const input = window.tinyMCE.activeEditor.selection.getContent();
    console.log('jjhj');
    if (input.length <= 1) {
      window.tinyMCE.activeEditor.notificationManager.open({
        text: 'Please highlight a sentence or word for Athena',
        type: 'error',
        timeout: 3000,
      });
    } else {
      seoMeAthenaHelper(input, SelectedSources).then(
        ({ credits, data, error }) => {
          if (!credits) {
            setWriteMeAthenaCreditsFail(true);
          } else if (!error) {
            window.tinymce.activeEditor.execCommand(
              'mceInsertContent',
              false,
              `${input} `,
            );
            // typeWriter(data)
            window.tinymce.activeEditor.execCommand(
              'mceInsertContent',
              false,
              data,
            );

            window.tinyMCE.activeEditor.notificationManager.open({
              text: 'CrawlQ Athena curated Niche related content.',
              type: 'success',
              timeout: 1000,
            });
          }
        },
      );
    }
  };

  const handleWriteMeAthena = (engine_type, sources) => {
    setIsAthenaWriting(true);
    const input = window.tinyMCE.activeEditor.selection.getContent();

    if (input.length <= 1) {
      window.tinyMCE.activeEditor.notificationManager.open({
        text: 'Please highlight a sentence or word for Athena',
        type: 'error',
        timeout: 3000,
      });
      setIsAthenaWriting(false);
    } else {
      writeMeAthenaHelper(input, engine_type, SelectedSources).then(
        ({ credits, data, error }) => {
          setIsAthenaWriting(false);
          if (!credits) {
            setWriteMeAthenaCreditsFail(true);
          } else if (!error) {
            console.log('hey yaaa write me 2', data);
            window.tinymce.activeEditor.execCommand(
              'mceInsertContent',
              false,
              `${input} ${data}`,
            );
            setIsAthenaWriting(false);
            console.log('hey yaaa write me 4');
            window.tinyMCE.activeEditor.notificationManager.open({
              text: 'CrawlQ Athena curated Niche related content.',
              type: 'success',
              timeout: 1000,
            });
          }
        },
      );
    }
  };

  const handleSave = async (autoSave = false) => {
    const html = editorRef.current?.getContent();

    if (preSaveContent === html) return;
    preSaveContent = html;
    setSaving(!autoSave);
    const data = {
      username: localStorage.getItem('username'),
      name: localStorage.getItem('name'),
      blog_name: localStorage.getItem('save-doc-name-sales'),
      text: html,
      topic: props.topic,
    };

    if (window.location.pathname.includes('sales-ai-content-writer')) {
      data.source = 'Sales Copywriter';
      data.script_name = localStorage.getItem('scriptName');
      const iframe = document.getElementById('texteditor_ifr');
      const iframeDocument =
        iframe.contentDocument || iframe.contentWindow.document;

      let styleIndex = 0;

      const isFirefox = typeof InstallTrigger !== 'undefined';
      if (isFirefox) {
        styleIndex = 1;
      }
      if (iframeDocument) {
        data.text += `<head><style>${
          iframe.contentWindow.document.getElementsByTagName('style')[
            styleIndex
          ].innerHTML
        }</style></head>`;
      }
    }

    if (
      window.location.pathname.includes('ai-content-writer') ||
      window.location.pathname.includes('sales-ai-content-writer')
    ) {
      if (html) {
        axios
          .post('https://apis.crawlq.ai/ai-writer-save', data)

          .then((res) => {
            // console.log(res)
          })
          .catch((ex) => {})
          .finally((f) => {
            setTimeout(() => {
              setSaving(false);
            }, 2000);
            if (!autoSave) {
              window.tinyMCE.activeEditor.notificationManager.open({
                text: 'Saved successfully.',
                type: 'success',
                timeout: 1500,
              });
            }
          });
      }
    }
  };

  const handleDownload = () => {
    window.tinymce.activeEditor.plugins.export.download('clientpdf', {});
  };

  const handleMenuPop = (event) => {
    setmenuPop((menuPop) => !menuPop);
  };

  const handleSelectedOption = (e) => {
    if (e.target.value === 'General') {
      if (SelectedSources.includes(e.target.value)) {
        setSelectedSources([]);
        localStorage.setItem('sources', JSON.stringify([]));
      } else {
        setSelectedSources(['General']);
        localStorage.setItem('sources', JSON.stringify([]));
      }
    } else if (SelectedSources.includes(e.target.value)) {
      const filtered1 = SelectedSources.filter(
        (Source) => Source !== 'General',
      );
      const filtered = filtered1.filter((Source) => Source !== e.target.value);
      setSelectedSources(filtered);
      localStorage.setItem('sources', JSON.stringify(filtered));
    } else {
      const filtered1 = SelectedSources.filter(
        (Source) => Source !== 'General',
      );
      const filtered2 = filtered1.concat(e.target.value);
      setSelectedSources(filtered2);
      localStorage.setItem('sources', JSON.stringify(filtered2));
    }
  };

  // Calculates Read Time
  const handleEstimateReadTime = () => {
    try {
      const iframe = document.querySelector('#texteditor_ifr');
      const result_document = iframe.contentWindow.document.getElementById(
        'tinymce',
      ).innerText;
      const wpm = 225;
      let time = 0;
      if (result_document !== '\n') {
        const words = result_document.trim().split(/\s+/).length;
        time = Math.ceil(words / wpm);
      }
      document.getElementById('read_time').innerText = time;
    } catch (ex) {
      console.log('Error in Estimating Time', ex);
    }
  };

  const handleTrainNowMode = async () => {
    await handleSave(true);
    navigate('/dashboard', { replace: true });
  };

  const handleModeToggle = () => {
    const firstSegment = getURLPathBeforeLastSegment();
    location.state.generatedOutputs = window.tinymce.activeEditor.getContent();

    if (isAdvancedMode) {
      navigate(`/${firstSegment}/editor`, {
        state: { ...location?.state },
      });
    } else {
      navigate(`/${firstSegment}/app`, {
        state: { ...location?.state },
      });
    }
    setIsAdvancedMode(!isAdvancedMode);
  };

  return (
    <div className="editor__main">
      <Flyout
        open={textImageWindow}
        onClose={() => setTextImageWindow(false)}
        title="Athena Creative Builder"
      >
        <TextImageComponent />
      </Flyout>
      <Flyout
        width="80vw"
        open={IsAuthorityBuilderOpen}
        onClose={() => setIsAuthorityBuilderOpen(false)}
        title="Brand Authority"
        subtitle="Beta"
      >
        <AuthorityBuilder
          topic={props.topic}
          query={props.query}
          text={editorRef.current?.getContent()}
        />
      </Flyout>
      <div className="editor__nav_div editor_nav_group">
        <div
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
          }}
        >
          <div className="button_group_navigation">
            <Link to="/dashboard">
              <img
                style={{
                  flex: 0.1,
                  filter: 'drop-shadow( 0px 5px 5px #e2e2e2',
                  cursor: 'pointer',
                  width: '25px',
                  height: '25px',
                  objectFit: 'contain',
                }}
                src="../img/home-icon.png"
              />
            </Link>

            <img
              style={{
                flex: 0.1,
                filter: 'drop-shadow( 0px 5px 5px #e2e2e2',
                cursor: 'pointer',
                width: '25px',
                height: '25px',
                objectFit: 'contain',
              }}
              src="../img/previous.png"
              onClick={() => navigate(-1)}
            />

            <img
              style={{
                flex: 0.1,
                filter: 'drop-shadow( 0px 5px 5px #e2e2e2',
                cursor: 'pointer',
                width: '25px',
                height: '25px',
                objectFit: 'contain',
              }}
              src="../img/download.png"
              onClick={handleDownload}
            />
          </div>
          <div className="button_group_Editor">
            {saving ? (
              <div className="editor-nav-icons">
                <LoaderSpin height="25" width="25" />
              </div>
            ) : (
              <div className="editor-nav-icons" onClick={() => handleSave()}>
                <CustomTooltip placement="top" arrow title="Save">
                  <SaveRoundedIcon />
                </CustomTooltip>
              </div>
            )}

            <PublishButton blog_id={props.blogId} blog_name={props.query} />
            <div
              className="dropdown mt-0 p-0"
              style={{
                flex: 0.15,
              }}
            >
              <CustomTooltip placement="top" arrow title="Templates">
                <a
                  href="https://crawlq.notion.site/43ffaa6b4b004c83ad29cb89d974d92b?v=c0b5f9c732a442bfab7d6b7d63ed02a8"
                  target="blank"
                >
                  <div className="editor-nav-icons">
                    <DescriptionIcon />
                  </div>
                </a>
              </CustomTooltip>
            </div>
          </div>
        </div>

        <div
          style={{
            fontSize: '14px',
            flex: 3,
            display: 'flex',
            alignItems: 'flex-end',
            gap: '1.5rem',
            justifyContent: 'flex-end',
          }}
        >
          <CustomTooltip
            placement="top"
            arrow
            title={
              isAdvancedMode ? 'Basic Editor Mode' : 'Advanced Editor Mode'
            }
            style={{
              cursor: 'pointer',
            }}
          >
            <div className="txt-primary" onClick={handleModeToggle}>
              <SpeedIcon />
            </div>
          </CustomTooltip>

          <div className="dropdown_container">
            <ButtonPrimary
              className="editor_nav_button"
              id="demo-simple-select-label"
              onClick={() => handleMenuPop()}
            >
              Modes {menuPop ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </ButtonPrimary>

            <div
              className="dropdown_options_container"
              style={menuPop ? { display: 'block' } : { display: 'none' }}
            >
              {SourceArray &&
                SourceArray.map((option) =>
                  (Sources && Sources.includes(option.key)) ||
                  option.key === 'General' ? (
                    <div className="dropdown_option_active">
                      {/* // Source equal General or Trained Sources */}
                      {option.key === 'General' ? (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flex: '1',
                              alignItems: 'center',
                            }}
                          >
                            <input
                              type="checkbox"
                              value={option.key}
                              checked={SelectedSources.includes(option.key)}
                              onChange={(e) => handleSelectedOption(e)}
                            />
                            <span
                              className="train_options"
                              htmlFor={option.key}
                            >
                              {option.value}
                            </span>
                          </div>
                          <hr
                            style={{
                              backgroundColor: 'rgb(245 245 245)',
                              height: 0.1,
                              width: '390px',
                              marginTop: '4px',

                              margin: '0px',
                            }}
                          />
                        </div>
                      ) : (
                        // Trained Sources but not General
                        <div
                          style={{
                            display: 'flex',
                            flex: '1',
                            alignItems: 'center',
                          }}
                        >
                          <input
                            type="checkbox"
                            value={option.key}
                            checked={SelectedSources.includes(option.key)}
                            onChange={(e) => handleSelectedOption(e)}
                          />
                          <span className="train_options" htmlFor={option.key}>
                            {option.value}
                          </span>
                          <span className="train_now"> </span>
                        </div>
                      )}
                    </div>
                  ) : (
                    // Non Trained Sources
                    <div className="dropdown_option_disabled">
                      <ReactTooltip multiline />
                      <input type="checkbox" disabled value={option} />
                      <span className="train_options">{option.value} </span>
                      <span
                        className="train_now"
                        data-tip={
                          option.value === 'Market Research'
                            ? 'Enable this mode by training on Niche Data'
                            : option.value === 'Market Insights'
                            ? 'Enable this mode by training on Niche Data'
                            : option.value === 'Sales Copywriting'
                            ? 'Enable this mode by training on Niche Data'
                            : `Enable this mode by training on ${option.value}`
                        }
                      >
                        {' '}
                        <InfoOutlinedIcon
                          style={{ width: '17px', height: '17px' }}
                        />
                      </span>
                    </div>
                  ),
                )}
            </div>
          </div>
          {WriteMeAthenaCreditsFail ? (
            <ButtonSecondaryColored color="red">
              Credits Over
            </ButtonSecondaryColored>
          ) : isAthenaWriting ? (
            <ButtonSecondary>
              <LoaderSpin height="25" width="25" />
            </ButtonSecondary>
          ) : (
            <ButtonSecondary
              className="editor_nav_button"
              onClick={() => handleWriteMeAthena(0)}
            >
              Write Me
            </ButtonSecondary>
          )}
          {/* {window.location.pathname.includes('app') && (
            <div onClick={() => setIsAuthorityBuilderOpen(true)}>
              <Badge badgeContent="New" color="error" />
              <ButtonSecondary className="editor_nav_button">
                Brand Authority
              </ButtonSecondary>
            </div>
          )} */}
        </div>
      </div>

      <TinyEditor
        onInit={(evt, editor) => (editorRef.current = editor)}
        id="texteditor"
        tinymceScriptSrc="%PUBLIC_URL%/tinymce/js/tinymce/tinymce.min.js"
        initialValue={globalEditor}
        init={{
          skin: 'borderless',
          menubar: false,
          height: 500,
          menubar_location: 'bottom',
          toolbar: false,
          selector: 'textarea#premiumskinsandicons-borderless',
          contextmenu_never_use_native: true,
          plugins: [
            'quickbars',
            'contextmenu',
            ' autosave noneditable permanentpen pageembed tinycomments quickbars linkchecker emoticons advtable',
            'mediaembed  advlist checklist tinymcespellchecker powerpaste',
            'casechange importcss tinydrive searchreplace autosave advcode',
            ' autolink lists link image charmap print preview hr anchor pagebreak',
            'searchreplace wordcount visualblocks visualchars  fullscreen',
            'insertdatetime media nonbreaking save table contextmenu directionality',
            'emoticons template textcolor colorpicker textpattern imagetools codesample toc help',
            'ExportToDoc  export',
          ], // note the comma at the end of the line!
          contextmenu: 'CustomPaste  | cell row column deletetable',

          statusbar: false,

          forced_root_block: 'p',

          menu: {
            tc: {
              title: 'TinyComments',
              items: 'addcomment showcomments deleteallconversations',
            },
            media: {
              title: 'Media',
              items: 'insertfile image media pageembed template faicons',
            },
            more: {
              title: 'Features',
              items: 'casechange code',
            },
          },
          // menubar:
          //   'file edit view insert  format tools table tc media more   help customInsertButton',
          browser_spellcheck: true,
          quickbars_selection_toolbar:
            'bold italic underline | Car | formatselect image | bullist numlist quicklink | forecolor backcolor | summerizer | writeMe | CompleteAthena | SocialAthena | AuthorityBuilder | OutlineAthena | QuoteAthena | StatisticsAthena |',
          powerpaste_googledocs_import: 'prompt',
          powerpaste_word_import: 'clean',
          powerpaste_html_import: 'clean',
          // toolbar: 'customInsertButton',
          // toolbar4: 'alignleft aligncenter alignright alignjustify ',
          // toolbar2: '  link anchor | codesample export  language | spellcheckdialog restoredraft | fullscreen  preview save print |  casechange permanentpen | pagebreak charmap emoticons ',
          // toolbar3: ' numlist bullist checklist | forecolor backcolor | outdent indent  ',
          autosave_ask_before_unload: true,
          tinycomments_mode: 'embedded',
          etd_file_name: 'tinymce-content',

          valid_children: '+body[style]',

          spellchecker_language: 'en',
          // // PowerPaste
          powerpaste_allow_local_images: true,
          // //PowerPaste-end
          save_enablewhendirty: true,
          autosave_ask_before_unload: false,
          fixed_toolbar_container: '#css3-selector',

          setup(ed) {
            ed.on('change', (e) => {
              handleEstimateReadTime();
            });

            ed.ui.registry.addMenuItem('CustomPaste', {
              text: 'Paste',
              icon: 'Paste',
              onAction() {
                setSelectedData();
              },
            });

            ed.ui.registry.addIcon('triangleUp', '?');

            ed.ui.registry.addButton('basicDateButton', {
              text: 'Save',
              tooltip: 'Save Your Content',
              onAction() {},
            });

            ed.ui.registry.addButton('summerizer', {
              // text: 'Summarize',
              tooltip: 'Text Summerizer',
              icon: 'toc',

              async onAction() {
                const input = window.tinymce.activeEditor.selection.getContent();
                console.log(input.trim().split(' ').length);
                if (
                  input.trim().split(' ').length > 250 ||
                  input.trim().split(' ').length < 30
                ) {
                  window.tinyMCE.activeEditor.notificationManager.open({
                    text: 'Please Select Words between 30 to 250.',
                    type: 'error',
                    timeout: 2000,
                  });
                  return;
                }
                const output = await handleSummerizerForEditor(input);
                console.log(input + output);
                window.tinymce.activeEditor.execCommand(
                  'mceInsertContent',
                  false,
                  `${input} <p> ${output} </p>`,
                );
                window.tinyMCE.activeEditor.notificationManager.open({
                  text: 'Text Summerized Sucessfully.',
                  type: 'success',
                  timeout: 1000,
                });
              },
            });

            ed.ui.registry.addButton('writeMe', {
              text: 'Write Me Athena',
              tooltip: 'Write me Athena',
              icon: 'edit-block',
              onAction() {
                writeMeAthena(0);
              },
            });

            ed.ui.registry.addButton('CompleteAthena', {
              text: 'Ask Me Athena',
              icon: 'ordered-list',
              tooltip: 'Ask me Athena',

              onAction() {
                writeMeAthena(1);
              },
            });

            ed.ui.registry.addButton('AuthorityBuilder', {
              text: 'Brand Authority',
              icon: 'template',
              tooltip: 'Brand Authority',

              onAction() {
                handleAthenaBuilder();
              },
            });

            ed.ui.registry.addButton('SocialAthena', {
              text: 'Social Me Athena',
              icon: 'user',
              tooltip: 'Write Social Post',

              onAction() {
                handleSocialMeAthena();
              },
            });

            ed.ui.registry.addButton('OutlineAthena', {
              text: 'Outline',
              icon: 'list-num-upper-roman',
              tooltip: 'Generate Outline',

              onAction() {
                handleOutlineMeAthena();
              },
            });

            ed.ui.registry.addButton('QuoteAthena', {
              text: 'Quotes',
              icon: 'quote',
              tooltip: 'Generate Quotes',

              onAction() {
                handleQuoteMeAthena();
              },
            });

            ed.ui.registry.addButton('StatisticsAthena', {
              text: 'Statistics',
              icon: 'insert-character',
              tooltip: 'Generate Statistics',

              onAction() {
                handleStatMeAthena();
              },
            });

            ed.ui.registry.addButton('Car', {
              text: 'Copy',
              icon: 'Copy',
              tooltip: 'Copy',

              onAction() {
                getSelectedText();
              },
            });
          },
        }}
        onEditorChange={currentData}
      />
    </div>
  );
}

export default TextEditor;
