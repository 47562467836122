export const COST_OF_SECTION = 100;

export const SOURCE_MAP = {
  'Market Research': 'mrr',
  'Market Insights': 'mrr_insight',
  'Sales Copywriting': 'script',
  Google: 'serp',
  Quora: 'quora',
  Reddit: 'reddit',
  URL: 'website',
};

export const SECTION_DEFAULT_DATA = {
  show_prompt: false,
  sources: [],
  method: '',
  template_text_prompt: '',
};

export const TEXT_DEFAULT_DATA = {
  template_name: '',
  template_description: '',
};
