import { baseAPIsUrl } from 'Config/Api';
import createInstance from 'Config/AxiosConfig';
import { useQuery } from 'react-query';
import { getReduxUserState, getReduxWorkspaceState } from 'utils/helpers/redux-selectors';

export const getBlogContent = async (blogName, from) => {
  const instance = await createInstance();
  const { email } = getReduxUserState();
  const { name } = getReduxWorkspaceState();

  const payload = {
    blog_name: blogName,
    name,
    source: from,
    username: email,
  };

  const { data } = await instance.post('ai-writer-load', payload);

  if (data?.statusCode === '200') return data?.blog;

  throw new Error('Could not fetch blog content');
};

const QUERY_KEY = 'get-all-questions-sales';

const queryOptions = {
  staleTime: Infinity,
  cacheTime: Infinity,
};

export const useGetBlogContent = (blogName, from) =>
  useQuery([QUERY_KEY, blogName + from], () => getBlogContent(blogName, from), queryOptions);
