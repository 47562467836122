import React from 'react';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';

function TableRow({
  name,
  email,
  scope,
  handleExpansions,
  isExpanded,
  isAdmin,
  index,
}) {
  return (
    <tr className="row">
      {!isAdmin ? (
        <td className="col-1 text-center tool-tip" onClick={handleExpansions}>
          {isExpanded ? (
            <KeyboardArrowDownRoundedIcon />
          ) : (
            <ChevronRightRoundedIcon />
          )}
        </td>
      ) : (
        <td className="col-1" />
      )}
      <td className="col-4">{name}</td>
      <td className="col-4">{email}</td>
      <td className="col-3 text-center">{scope}</td>
    </tr>
  );
}

export default TableRow;
