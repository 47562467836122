import { getNameInitials } from 'Component/Shared/utils';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Header,
  HeadingDesc,
  HeadingName,
} from 'Component/Shared/GlobalStyles';
import { useNavigate } from 'react-router-dom';
import AddTeamMember from './Componets/AddTeamMember';
import {
  ProfileComponents,
  ProfileDetailsWrapper,
  ProfileImage,
  ProfleDetails,
  TeamMemberContainer,
  TeamMemberWrapper,
} from './styles';
import MembersTable from './Componets/TableDisplay';

function TeamMemberOld() {
  const { name, plan, email, group } = useSelector((state) => state?.user);

  const navigate = useNavigate();

  useEffect(() => {
    if (plan.includes('Clients ')) {
      navigate('/ProfilePage');
    }
  }, []);

  return (
    <div className="main-content" id="panel">
      <Header>
        <HeadingName>Collaborate and Scale</HeadingName>
        <HeadingDesc>With Your Team & Clients</HeadingDesc>
      </Header>
      <TeamMemberWrapper>
        <TeamMemberContainer>
          <ProfileComponents>
            <ProfileImage>
              <div className="no-translate">{getNameInitials(name)}</div>
            </ProfileImage>
            <ProfileDetailsWrapper>
              <ProfleDetails>
                <b>{name}</b>
                <span className="notranslate">{email}</span>
              </ProfleDetails>
              <ProfleDetails end>
                <b>Group Name</b>
                <span className="notranslate">{group}</span>
              </ProfleDetails>
            </ProfileDetailsWrapper>
          </ProfileComponents>
          <AddTeamMember />
          <MembersTable />
        </TeamMemberContainer>
      </TeamMemberWrapper>
    </div>
  );
}

export default TeamMemberOld;
