/* eslint-disable react/no-danger */
import { renderAnswerWithImages } from 'Component/Pages/ChatAthena/utils';
import { useIsMobile } from 'Component/hooks/useIsMobile';
import React from 'react';

function SnippetCodeComponent(props) {
  const {
    selectedAnswer,
    element,
    setSelectedAnswer,
    index,
    answerId,
    isDarkMode,
  } = props;

  const isMobile = useIsMobile();

  return (
    <div
      key={`answer-${answerId}`}
      contentEditable={index === selectedAnswer.index}
      onInput={(e) => {
        setSelectedAnswer({
          index,
          answer: e.currentTarget.innerHTML,
        });
      }}
      style={{
        // float: 'left',
        backgroundColor: isDarkMode ? '#444653' : '#F7F7F8',
        padding: '15px',
        paddingRight: '15px',
        paddingLeft: '10px',
        borderRadius: '0px 15px 0px 15px',
        marginRight: '20px',
        color: isDarkMode ? 'white' : '#444653',
        // marginBottom: '20px',

        border: index === selectedAnswer.index ? 'solid 0.3em red' : '',
      }}
      id={`answer-${answerId}`}
      {...renderAnswerWithImages(element, isMobile)}
    />
  );
}
export default SnippetCodeComponent;
