/* eslint-disable no-lone-blocks */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import React, { useEffect, useState } from 'react';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import { motion } from 'framer-motion';
import { FormControl, Select } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useNavigate } from 'react-router-dom';
import { CreditManage } from 'Component/Shared/CreditManager';
import axios from 'axios';
import { SALES_COPY_WRITING_COST } from 'Component/Shared/featuresCreditsCost';
import LoaderSpin from '../../../Shared/Loader';
import CustomTooltip from '../../../Shared/CustomTooltip';
import { getListOfCountries } from '../AIcontentWriter/AIcontentLogic';
import { baseAPIsUrl, salesCopywritingWizard } from 'Config/Api';
import CreditUpdater from '../../../Shared/CreditManager';
import {
  getTranslatedText,
  getTranslatedTextEnglish,
  getTranslatedTextForHTML,
} from '../../../Shared/TranshelperStatic';

import { getSavedDocs } from './salesCopyWriterLogic';

function QuesTabsPhase2({
  que,
  queDesc,
  answer,
  index,
  handleClear,
  handleChange,
  activateEachAI,
  handleFillWithAIEach,
  loadingIndex,
}) {
  return (
    <div className="aw-main-question-container font-accent sales-height-unset">
      <div className="aw-question-container-index sales-ques-serial">
        Q
        {index + 1}
      </div>

      <div className="aw-question-container-right">
        <div className="sales-question">
          <p>{que}</p>
          <div className="d-flex">
            {activateEachAI && (
            <>
              {index !== loadingIndex.current ? (
                <button
                  className="btn btn-success sales-AI-each-btn mr-1"
                  onClick={(e) => {
                    handleFillWithAIEach(index);
                  }}
                  disabled={loadingIndex.calledIndexes.includes(index)}
                >
                  A.I
                </button>
              ) : (
                <LoaderSpin height={40} />
              )}
            </>
            )}
            <button
              className="btn btn-outline-danger sales-clear-each-btn"
              onClick={() => handleClear(index)}
            >
              Clear
            </button>
          </div>
        </div>
        <textarea
          className="aw-answer-input"
          value={answer}
          onChange={(e) => handleChange(index, e.target.value)}
        />
        <div className="aw-helper">{queDesc}</div>
      </div>
    </div>
  );
}

function QuestionsHead({
  // Do not remove

  selectedSubCategory,
  questions,
  Docs,
  // handleClear,
  // handleChange,
  // query,
  // activateEachAI,
  // handleFillWithAIEach,
  // loadingIndex,
  // isQuerySame,

  // Do not remove
}) {
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false); // do not remove
  const [countries, setCountries] = useState([]); // do not remove
  const [MTI, setMTI] = useState('');
  const [ICI, setICI] = useState('');
  const [SelectedCountry, setSelectedCountry] = useState('US');
  const [CreateDraftLoader, setCreateDraftLoader] = useState(false);
  const [EmptyFields, setEmptyFields] = useState(false);
  const [DOCexists, setDOCexists] = useState(false);
  const [DocumentName, setDocumentName] = useState('');
  const [CreditsOver, setCreditsOver] = useState(false);

  useEffect(() => {
    getListOfCountries().then((data) => {
      setCountries(data);
    });
  }, []);

  const CheckDOCexists = async (draft_name) => {
    Docs.data.forEach((savedDraft) => {
      if (savedDraft.blog_name === draft_name) {
        setDOCexists(true);
      }
    });
  };

  const CreateBodyRequest = async () => {
    const question_block_arr = [];

    questions.forEach((value, index) => {
      const question = value.input_question;

      const each_block = {
        question,
        answer: '',
        example: '',
        id: index,
      };

      question_block_arr.push(each_block);
    });

    const script_description = [
      {
        answer: await getTranslatedTextEnglish(MTI),
        question: 'What is your Topic Of Interest?',
      },
      {
        main_topic: {
          answer: selectedSubCategory.description,
          question:
            'What is your users Main Goal/ Objective/ Pain-Point/ Benefit/ Desire ?',
        },
      },
    ];

    const body = {
      username: localStorage.getItem('username'),
      name: localStorage.getItem('name'),
      input_data: {
        script_description,
        script_data: question_block_arr,
      },
    };

    return body;
  };

  const FillWithAI = async () => {
    const body_request = await CreateBodyRequest();

    const axiosConfig = {
      multiValueHeaders: {
        'Content-Type': ['application/json'],
        'Access-Control-Allow-Origin': ['*'],
        'Access-Control-Allow-Headers': [
          'Content-Type',
          'X-Amz-Date',
          'Authorization',
          'X-Api-Key',
          'X-Amz-Security-Token',
        ],
      },
    };

    const result = await axios.post(
      'https://semantic.crawlq.ai/',
      JSON.stringify(body_request),
      axiosConfig,
    );

    return result.data;
  };

  const BuildScript = async (AI_respoonse) => {
    const answers = [];

    AI_respoonse.forEach((value) => answers.push(value.ai_suggestion));

    const body = {
      script_id: selectedSubCategory.script_id,
      data: answers,
    };

    // console.log(body)

    const { data } = await axios.post(`${baseAPIsUrl}/build-script`, body);

    return [data.output, answers];
  };

  const SaveBuild = async (answers) => {
    const { script_id } = selectedSubCategory;
    const { script_name } = selectedSubCategory;

    const body = {
      script_id,
      input_questions: answers,
      username: localStorage.getItem('username'),
      script_name,
      name: localStorage.getItem('name'),
    };

    // console.log(body)
    const res = await axios.post(`${baseAPIsUrl}/save-script`, body);

    return res.data.status;
  };

  const CreateDraft = async () => {
    const { success } = await CreditManage(SALES_COPY_WRITING_COST, true);

    if (success) {
      setCreateDraftLoader(true);

      const FillAI_result = await FillWithAI();

      const Build_result = await BuildScript(FillAI_result);

      const Save_result = await SaveBuild(Build_result[1]);

      const TranslatedResult = await getTranslatedTextForHTML(Build_result[0]);

      // console.log(TranslatedResult)

      if (Save_result === 'success') {
        const { success } = await CreditManage(SALES_COPY_WRITING_COST);

        if (success) {
          const query = DocumentName;
          const GoogleQuery = MTI;

          setCreateDraftLoader(false);

          localStorage.setItem('questionTopic', MTI);
          localStorage.setItem('initialHeading', MTI);
          localStorage.setItem('context', MTI);

          localStorage.setItem('scriptName', selectedSubCategory.script_name);

          navigate(
            '/sales-ai-content-writer/app', // link

            {
              state: {
                query, // sent to editor as params ICI
                GoogleQuery, // sent to editor as params MTI
                generatedOutputs: TranslatedResult, // generated output for editor
                flag: 'sales', // flag for sales or ai_content
                lang: SelectedCountry, // choosen country
              },
            },
          );
        } else {
          setCreditsOver(true);
        }
      }
    } else {
      setCreditsOver(true);
    }
  };

  const handleMTIinput = (e) => {
    setMTI(e.target.value);
    setEmptyFields(false);
  };

  const handleICIinput = (e) => {
    setICI(e.target.value);
    setEmptyFields(false);
  };

  const handleCountrySelection = (e) => {
    setSelectedCountry(e.target.value);
  };

  const handleDocumentinput = (e) => {
    setDOCexists(false);

    setDocumentName(e.target.value);
    CheckDOCexists(e.target.value);

    localStorage.setItem('save-doc-name-sales', e.target.value);
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      <motion.div className="aw-main-authority-blog2" style={{ width: '100%' }}>
        <div className="aw-auth-head">
          <h4 className="aw-auth-head-text text-primary">Generate Draft</h4>
        </div>

        <div className="aw-auth-input">
          <div className="aw-input-label">
            <div> Document Name</div>
            <div className="tool-tip">
              <CustomTooltip
                arrow
                placement="top"
                title="Choose a name for your document which will be helpful for you to refer in Recent Scripts"
              >
                <InfoOutlinedIcon />
              </CustomTooltip>
            </div>
          </div>
          <input onChange={(e) => handleDocumentinput(e)} value={DocumentName} />
        </div>

        {DOCexists ? (
          <div
            style={{
              fontSize: '0.8rem',
              color: 'red',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            Article Already exist with this Name.
          </div>
        ) : null}

        <div className="aw-auth-input">
          <div className="aw-input-label">
            <div> Main Topic Of Interest</div>
            <div className="tool-tip">
              <CustomTooltip
                arrow
                placement="top"
                title="Write the main topic of your content here. It is used to fetch relevant SERP data. Eg: “3D printing”"
              >
                <InfoOutlinedIcon />
              </CustomTooltip>
            </div>
          </div>
          <input onChange={(e) => handleMTIinput(e)} value={MTI} />
        </div>

        <div className="aw-auth-input">
          <div className="aw-input-label">
            <div>Initial Content Idea </div>
            <div className="tool-tip">
              <CustomTooltip
                arrow
                placement="top"
                title="Write the title of your content/article. It is used to create outline. Eg: “The best software to use for your next 3D printing project”"
              >
                <InfoOutlinedIcon />
              </CustomTooltip>
            </div>
          </div>

          <textarea
            style={{
              height: '70px',
            }}
            onChange={(e) => handleICIinput(e)}
            value={ICI}
          />
        </div>
        {/* {(
          <p
            className="text-right font-accent text-danger"
            style={{ fontSize: '0.8rem' }}
          >
            *Initial Content Idea Already Exists
          </p>
        )} */}
        {/* <div className="aw-auth-input">
          <div className="aw-input-label">
            <div>{"f"}</div>
            <div className="tool-tip">
              <CustomTooltip
                placement="top"
                title="Write the problem-solution that your content focusses on. Eg: “Helps them to choose the best and trending 3D printing software”"
                arrow
              >
                <InfoOutlinedIcon />
              </CustomTooltip>
            </div>
          </div>
          <input
            onChange={() => console.log("g")}
            value="dd"
          />
        </div> */}
        <div className="aw-auth-input">
          <div className="aw-input-label">
            <div>Select Country</div>
          </div>
          <FormControl variant="outlined" style={{ width: '60%' }}>
            <Select
              native
              onChange={(e) => handleCountrySelection(e)}
              value={SelectedCountry}
            >
              {countries.map((country, index) => (
                <option key={index} value={country.alpha2Code}>
                  {country.name}
                </option>
              ))}
            </Select>
          </FormControl>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '30%',
            float: 'right',
          }}
        >
          {EmptyFields ? (
            <div
              style={{
                fontSize: '0.8rem',
                color: 'red',
                float: 'right',
                display: 'flex',
                marginBottom: '0.5rem',
                flexDirection: 'column',
              }}
            >
              <span> Please fill data to generate draft </span>
            </div>
          ) : null}

          {CreditsOver ? (
            <span
              className="float-right btn-secondary"
              onClick={() => CreateDraft()}
              style={{
                backgroundColor: 'rgb(255 200 200)',
                color: 'red',
                textAlign: 'center',
                padding: '5px 0px',
                borderRadius: '4px',
              }}
            >
              Credits Over
            </span>
          ) : CreateDraftLoader ? (
            <div style={{ float: 'right' }}>
              {' '}
              <LoaderSpin width="35" height="35" />
              {' '}
            </div>
          ) : MTI && ICI && DocumentName && !DOCexists ? (
            <span
              className="float-right btn btn-secondary"
              onClick={() => CreateDraft()}
              style={{ backgroundColor: '#2a9df5' }}
            >
              Create Draft
            </span>
          ) : (
            <span
              className="float-right btn btn-secondary"
              onClick={() => setEmptyFields(true)}
            >
              Create Draft
            </span>
          )}
        </div>
      </motion.div>
    </div>
  );
}

export default QuestionsHead;

// Do not remove

{
  /* <>
<div className="sales-qna-in font-accent">
  <div>
    <p className="sales-question-nw"> Main Topic Of Interest </p>
    <div className="sales-answer-section">
      <p className="sales-que-helper">
        Write your main topic in 2-3 words.
      </p>
      <p className="sales-compulsory">*Compulsory</p>
    </div>
  </div>
  <textarea
    onChange={e => handleChange(-1, e.target.value)}
    name=" What is your Topic Of Interest?"
    value={query}
    // name={question.input_question}
    placeholder="Eg : Content Automation"
  />
  {isQuerySame && (
    <p className="font-accent text-danger" style={{ fontSize: '0.8rem' }}>
      *Main Topic Of Interest Already Exists
    </p>
  )}
  <div onClick={() => setIsExpanded(prev => !prev)}>
    <div className="d-flex">
      <p className="sales-question-nw"> Instructions </p>
      {isExpanded ? (
        <ExpandLessRoundedIcon className="mt-2" />
      ) : (
        <ExpandMoreRoundedIcon className="mt-2" />
      )}
    </div>
    <div className="sales-answer-section">
      <p className="sales-que-helper">
        Please provide your initial answers aligned to the following
        instructions so that CrawlQ AI generates perfect copy optimised
        for your target audience.
      </p>
    </div>
  </div>
  {isExpanded && (
    <p
      className="sales-que-desc"
      // onChange={e => handleObjective(e)}
      name="What is your users Main Goal/ Objective/ Pain-Point/ Benefit/ Desire ?"
      // value={Objective}
      // disabled
      // name={question.input_question}
    >
      {selectedSubCategory.description}
    </p>
  )}
</div>
<div className="sales-questions">
  {questions.map((question, index) => (
    <QuesTabsPhase2
      key={index}
      que={question.input_question}
      queDesc={question.question_example}
      answer={question.answer}
      example={question.example_mapping}
      index={index}
      handleClear={handleClear}
      handleChange={handleChange}
      handleFillWithAIEach={handleFillWithAIEach}
      activateEachAI={activateEachAI}
      loadingIndex={loadingIndex}
    />
  ))}
</div>
</> */
}
