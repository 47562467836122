/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import { Button } from 'Component/Shared/ButtonNext';
import { ButtonSecondary } from 'Component/Shared/GlobalStyles';
import React, { useEffect, useState } from 'react';
import LinkIcon from '@mui/icons-material/Link';
import { generateTemplateURL } from '../template.service';
import { ModalBodyWrapper, ModalButtonContainer, TextInput } from './styles';
import styles from '../Actions/action.module.css';

function GenerateURL({ templateId, setShowModal }) {
  const [isLoading, setIsLoading] = useState(false);
  const [URL, setURL] = useState('');
  const [isCopied, setIsCopied] = useState(false);

  const handleGenerateURL = async () => {
    setIsLoading(true);
    const url = await generateTemplateURL(templateId);
    if (url) {
      setURL(url);
      setTimeout(() => {
        setShowModal(false);
      }, 10 * 1000);
    }
    setIsLoading(false);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(URL);
    setIsCopied(true);
  };

  useEffect(() => {
    handleGenerateURL();
  }, []);

  const buttonName = isCopied ? 'Copied !' : 'Copy';

  return (
    <div className={styles.container}>
      <div className="icon_body">
        <h5>Generate Template URL</h5>
        <div className="tool-tip">
          <LinkIcon style={{ color: '#2a9df5' }} />
        </div>
      </div>
      <TextInput>
        <input className="input_box" value={URL} />
      </TextInput>
      <ModalButtonContainer>
        <ButtonSecondary
          className="h-margin-l-tiny"
          onClick={() => setShowModal()}
        >
          Cancel
        </ButtonSecondary>
        <Button
          name={buttonName}
          onClick={() => handleCopy()}
          showLoader={isLoading}
        />
      </ModalButtonContainer>
    </div>
  );
}

export default GenerateURL;
