/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import { Button } from 'Component/Shared/ButtonNext';
import { ButtonSecondary } from 'Component/Shared/GlobalStyles';
import React, { useState } from 'react';
import PublicIcon from '@mui/icons-material/Public';
import { shareTemplateWithEmail } from '../template.service';
import { ModalBodyWrapper, ModalButtonContainer } from './styles';
import styles from '../Actions/action.module.css';

function MakeTemplatePublic({ templateId, setShowModal }) {
  const [isShared, setIsShared] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const SUPPORT_ACCOUNT_EMAIL = 'support@quantamixsolutions.com';

  const handleShare = async () => {
    setIsLoading(true);
    const isShared = await shareTemplateWithEmail(
      templateId,
      SUPPORT_ACCOUNT_EMAIL,
    );
    if (isShared) {
      setIsShared(true);
      setTimeout(() => {
        setIsShared(false);
        setShowModal(false);
      }, 10 * 1000);
    }
    setIsLoading(false);
  };

  return (
    <div className={styles.container}>
      <div className="icon_body">
        <div className="tool-tip">
          <span style={{ color: '#2a9df5' }}>
            <PublicIcon />
          </span>
        </div>
        {!isShared && (
          <>
            <h5>Make Template Public</h5>
            <p>
              Are you sure you want to make this template public? This will
              allow others to view and potentially use this template
            </p>
          </>
        )}
      </div>
      {isShared ? (
        <p>
          Template successfully made public. It is now visible to others and can
          be used by them.
        </p>
      ) : (
        <ModalButtonContainer>
          <ButtonSecondary
            className="h-margin-l-tiny"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </ButtonSecondary>
          <Button
            name="Make Public"
            onClick={handleShare}
            showLoader={isLoading}
          />
        </ModalButtonContainer>
      )}
    </div>
  );
}

export default MakeTemplatePublic;
