/* eslint-disable max-len */
import createInstance from 'Config/AxiosConfig';
import { useQuery } from 'react-query';

// Keep Query Value as File Name to maintain consistency and uniqueness
const QUERY_KEY = 'get-workspace-build-status';

// API Call
export const getWorkspaceBuildStatus = async (workspaceName) => {
  const instance = await createInstance();

  const response = await instance.post(
    'https://p8dapk4yf9.execute-api.us-east-2.amazonaws.com/prod/get-onboarding-status',
    { name: workspaceName },
  );
  return response;
};

// Query Config Options
const queryOptionsRefetch = {
  staleTime: 10000,
  refetchInterval: 10000,
};

const queryOptionsNoRefetch = {
  staleTime: 50000,

};

// QUERY
export const useGetWorkspacePersonaStatus = (workspaceName, isNewWorkspace) =>
  (isNewWorkspace ?
    useQuery(QUERY_KEY, () => getWorkspaceBuildStatus(workspaceName), queryOptionsRefetch)
    : useQuery(QUERY_KEY, () => getWorkspaceBuildStatus(workspaceName), queryOptionsNoRefetch));
