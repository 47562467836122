/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Button, { TabSwitcheWrapper, TabContainer } from './styles';

function TabSwitcherAlpha({ tab1, tab2, setSwitcher, tab }) {
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    setCurrentTab(tab);
  }, [tab]);

  const handleTabSwitch = (tab) => {
    setCurrentTab(tab);
    setSwitcher(tab);
  };
  return (
    <TabContainer>
      <TabSwitcheWrapper>
        <Button onClick={() => handleTabSwitch(0)} active={currentTab === 0}>
          {tab1}
        </Button>
        <Button onClick={() => handleTabSwitch(1)} active={currentTab === 1}>
          {tab2}
        </Button>
      </TabSwitcheWrapper>
    </TabContainer>
  );
}

export default TabSwitcherAlpha;
