import React from 'react';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import styles from './index.module.css';

export const SearchInput = ({ placeholder, value, onChange }) => (
  <div className={styles.parent}>
    <input
      className={styles.input}
      type="text"
      value={value}
      onChange={onChange}
      placeholder={placeholder}
    />
    <button type="button">
      <SearchRoundedIcon className={styles.icon} />
    </button>
  </div>
);
