import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoutes = () => {
  const isLoggedIn = localStorage.getItem('id_token');

  return (isLoggedIn ? <Outlet /> : <Navigate to="/" />);
};

export default PrivateRoutes;
