import { getReduxUserState } from 'utils/helpers/redux-selectors';
import createInstance from 'Config/AxiosConfig';

export const getMainTopicInterest = async (workspaceName) => {
  const { email } = getReduxUserState();

  const instance = await createInstance();
  const { data } = await instance.get(`load-questions?subcategory=Define%20your%20Niche%20Semantics&username=${email}&name=${workspaceName}`);

  return data?.data[0]?.answer ?? '';
};
