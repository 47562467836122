/* eslint-disable consistent-return */
/* eslint-disable no-console */
/* eslint-disable import/no-unresolved */
import axios from 'axios';
import createInstance from 'Config/AxiosConfig';

import { store } from 'redux/store';

const getGroupName = () => {
  const state = store.getState();
  const { group } = state.user;
  return group;
};

export const StripeCheckOut = async (plan, group, referId) => {
  try {
    console.log(group);
    let body = {};

    if (referId) {
      body = {
        group_name: group,
        plan,
        referId,
      };
    } else {
      body = {
        group_name: group,
        plan,
      };
    }

    const instance = await createInstance();

    const result = await instance.post('subscription-checkout', body);

    if (result.data.statusCode === '500') {
      return {
        error: true,
        message: result.data.message,
        success: false,
        url: null,
      };
    }
    return {
      error: false,
      message: null,
      success: true,
      url: result.data.url,
    };
  } catch (err) {
    console.log(err);
  }
};

export const customerPortal = async () => {
  try {
    const body = {
      group_name: getGroupName(),
    };
    const res = await axios.post('https://apis.crawlq.ai/customer-portal', body);

    return res.data.url;
  } catch (err) {
    console.log(err);
    return '';
  }
};
