// Begin tab_id with 0 always to have default state set as first tab
export const CreateNewWorkspaceInnerTabs = [
  {
    tab_name: 'Creation',
    tab_route: '/new-workspace',
    tab_id: 0,
  },
  {
    tab_name: 'Breif Intake',
    tab_route: '/new-workspace/configure',
    tab_id: 1,
  },

];

export const CREATION_TAB = CreateNewWorkspaceInnerTabs[0].tab_id;
export const BREIF_INTAKE_TAB = CreateNewWorkspaceInnerTabs[1].tab_id;
