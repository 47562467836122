import styled, { css } from 'styled-components';

export const FlexCard = styled.div`

    ${(props) =>
    props?.flex &&
    css`
        display:flex;
    `}

    ${(props) =>
    props?.wrap &&
    css`
        flex-wrap:wrap;
    `}

    ${(props) =>
    props?.column &&
    css`
        flex-direction: column;
    `}

    


`;
