import axios from 'axios';

const VerifyEmailRequest = async (email, code) => {
  const body = {
    email,
    code,
  };

  try {
    const result = await axios.post('https://apis.crawlq.ai/confirm-signup', body);

    if (result.data.statusCode === '200') {
      return ({ success: true });
    }
    return ({ success: false });
  } catch (err) {
    console.log(err);
    return ({
      error: true,
      message: 'Check Internet Connectivity',
      success: false,
    });
  }
};

export const ResendVerificationCode = async (email) => {
  try {
    const body = {
      username: email,
    };

    const response = await axios.post('https://apis.crawlq.ai/resend-code', body);

    if (response.data.statusCode === '200' && response.data.status === 'success') {
      return ({
        sent: true,
        message: 'Code Sent Successfully',
      });
    }

    return ({
      sent: false,
      message: 'Limit Exceeded, Try Again after few minutes.',
    });
  } catch (error) {
    console.log(error);
    return ({
      sent: false,
      message: error,
    });
  }
};

export default VerifyEmailRequest;
