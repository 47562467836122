/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// assets
import AmazonLogo from 'assets/images/amazonLogo.png';
import CrawlQLogo from 'assets/images/crawlQ.png';
import DynataLogo from 'assets/images/dynataLogo.png';
import GartnerLogo from 'assets/images/gartner.svg';
import Googlelogo from 'assets/images/googleLogo.svg';
import IqviaLogo from 'assets/images/iqviaLogo.png';
import KabbaxLogo from 'assets/images/KabbaxLogo.png';
import LeanCaseLogo from 'assets/images/leancaseLogo.png';

// Logic Fn
import { LoginRequest } from 'Component/Pages/Login/LoginLogic';
import LoaderSpin from 'Component/Shared/Loader';
import { notification } from 'antd';
import { setAuthToken } from 'Config/localstorageConfig';
import VerifyEmailRequest, { ResendVerificationCode } from './VerifyEmailLogic';
import { userLoginSuccess } from '../../../../redux';

function VerifyEmail() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const email = location.state?.email;
  const password = location.state?.password;
  const cameFrom = location.state?.location;

  const [UsersCode, setUsersCode] = useState('');
  const [IsError, setIsError] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState('');
  const [CodeSent, setCodeSent] = useState(false);
  const [CodeSentMessage, setCodeSentMessage] = useState(null);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    console.log(handleAutoResendCode());
  }, []);

  const handleAutoResendCode = async () => {
    if (cameFrom === 'login') {
      await ResendVerificationCode(email);
    }
  };

  const handleInput = (e) => {
    setCodeSentMessage(null);
    setUsersCode(e.target.value);
  };

  const handleVerifyCheck = async () => {
    if (UsersCode.length === 0) {
      setIsError(true);
      setErrorMessage('Verification box cannot be empty');
    } else {
      setShowLoader(true);
      await handleVerify(email, UsersCode);
    }
  };

  const handleVerify = async (email, code) => {
    const { success } = await VerifyEmailRequest(email, code);

    if (success) {
      notification.open({
        type: 'success',
        message: 'Verified',
        description: 'Code Verified successfully. Now you can login!',
        placement: 'bottomRight',
        duration: 6,
      });
      const { success, data, jwt_token, destination } = await LoginRequest(
        email,
        password,
      );

      setShowLoader(false);
      // set users details in store
      dispatch(userLoginSuccess(data));
      setAuthToken(jwt_token);
      localStorage.setItem('username', data.username);
      localStorage.setItem('is_admin', data.admin);
      localStorage.setItem('signedUserDetails', JSON.stringify(data));
      navigate(destination, {
        state: {
          email: data.email,
          name: data.name,
          old_user: data.old_user,
          origin: 'login',
        },
      });
    } else {
      setIsError(true);
      setShowLoader(false);
      setErrorMessage('Incorrect Code, Try Again');
    }
  };

  const handleResendVerification = async (email) => {
    const response = await ResendVerificationCode(email);

    setCodeSent(response.sent);
    setCodeSentMessage(response.message);
    return response;
  };

  return (
    <div className="signup-free-container">
      <div className="signup_left_container">
        <div className="left_container_box">
          <div className="logo-container">
            <img className="logo" src={CrawlQLogo} alt="CrawlQ Logo" />
            <h5>Register Now to the Future</h5>
            <span>Research and Write—100% Unique Content in 2 minutes</span>
          </div>

          <div className="input_container">
            <h3>Verify Email</h3>
            <p className="verify-message">
              Verification code is sent to <b>{email}</b>
            </p>
            <div className="single-box">
              <div className="input_field_container">
                <input
                  type="text"
                  name="code"
                  className="input"
                  value={UsersCode}
                  onChange={handleInput}
                  placeholder="Verification Code"
                />
              </div>
            </div>

            {CodeSentMessage ? (
              <span style={CodeSent ? { color: 'green' } : { color: 'red' }}>
                {CodeSentMessage}
              </span>
            ) : null}

            {showLoader ? (
              <button>
                <LoaderSpin width="25" height="25" color="white" />
              </button>
            ) : (
              <button onClick={handleVerifyCheck}>V E R I F Y</button>
            )}
            {IsError && ErrorMessage ? (
              <span className="error-message" style={{ color: 'red ' }}>
                {ErrorMessage}
              </span>
            ) : null}
            <div
              className="resend-code"
              onClick={() => handleResendVerification(email)}
            >
              <i className="fas fa-redo-alt" />
              Resend Code
            </div>
          </div>
        </div>
      </div>

      <div className="signup_right_container">
        <div className="logo-containers">
          <img src={Googlelogo} alt="" />
          <img
            style={{ height: '5rem', width: '7rem' }}
            src={DynataLogo}
            alt=""
          />
          <img
            style={{ height: '5rem', width: '9rem' }}
            src={IqviaLogo}
            alt=""
          />
          <img
            style={{ height: '6rem', width: '9rem' }}
            src={KabbaxLogo}
            alt=""
          />
          <img
            style={{ height: '5rem', width: '10rem' }}
            src={LeanCaseLogo}
            alt=""
          />
          <img
            style={{ height: '5rem', width: '10rem' }}
            src={GartnerLogo}
            alt=""
          />
          <img
            src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
            alt=""
          />
          <img
            style={{ height: '3rem', width: '10rem' }}
            src={AmazonLogo}
            alt=""
          />
        </div>
      </div>
    </div>
  );
}

export default VerifyEmail;
