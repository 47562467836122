import React, { useState } from 'react';
import { Divider } from 'components/common/divider';
import { ScaffoldCard } from 'components/common/scaffold-card';
import NoActionInfoModal from 'components/common/NoActionInfoModal';
import ContactSupport from '../ContactSupport/ContactSupport';
import styles from './help.module.css';

const HelpCard = () => {
  const [IsContactInfo, setIsContactInfo] = useState(false);

  const handleContactClick = () => {
    setIsContactInfo(true);
  };

  return (
    <>
      <NoActionInfoModal
        isVisible={IsContactInfo}
        close={() => setIsContactInfo(!IsContactInfo)}
        title="Contact Us"
      >
        <ContactSupport />
      </NoActionInfoModal>

      <ScaffoldCard className={styles.help_container}>
        <div className={styles.header}>Get In Touch With Us !</div>
        <Divider />

        <div className={styles.content_container}>
          CrawlQ is here to help you and assist in your journey.
          <br />
          Need learning resources ?
          <br />
          Configuration Assistance ?
          <br />
          Say HI to CrawlQ team ?
        </div>

        <button
          type="button"
          className={styles.contact_btn}
          onClick={handleContactClick}
        >
          Contact Support
        </button>
      </ScaffoldCard>
    </>
  );
};

export default HelpCard;

// support@quantamixsolutions.com
