import React, { useState } from 'react';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import { useSelector } from 'react-redux';
import saveBrandVoice from 'queries/brand-voice/save-brand-voice';
import { showNotification } from 'libs/notification';
import {
  CardContainer,
  CardDescription,
  ButtonContainer,
  CardTitle,
  TextArea,
} from './styles';

const BrandVoiceCard = ({ brand_voice_name, description, onDelete }) => {
  const { name } = useSelector((state) => state.workspace);

  const [editMode, setEditMode] = useState(false);
  const [updatedDescription, setUpdatedDescription] = useState(description);

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleCancelClick = () => {
    setEditMode(false);
    setUpdatedDescription(description);
  };

  const handleSaveClick = () => {
    if (updatedDescription.trim() === '') {
      showNotification('error', 'Brandvoice Cannot be Empty');
      return;
    }
    setEditMode(false);
    const body = {
      brand_voice_name,
      description: updatedDescription,
      name,
    };

    saveBrandVoice(body).catch(() => {
      setEditMode(false);
      showNotification('error', 'Could not save the brand voice');
    });
  };

  const handleDescriptionChange = (event) => {
    setUpdatedDescription(event.target.value);
  };

  return (
    <CardContainer>
      <CardTitle>{brand_voice_name}</CardTitle>
      {editMode ? (
        <TextArea
          value={updatedDescription}
          onChange={handleDescriptionChange}
        />
      ) : (
        <CardDescription>
          {updatedDescription.trim() === '' ? description : updatedDescription}
        </CardDescription>
      )}
      <ButtonContainer>
        <DeleteRoundedIcon color={'error'} onClick={onDelete} />
        {editMode ? (
          <>
            <ClearRoundedIcon onClick={handleCancelClick} />
            <SaveRoundedIcon onClick={handleSaveClick} />
          </>
        ) : (
          <EditRoundedIcon onClick={handleEditClick} />
        )}
      </ButtonContainer>
    </CardContainer>
  );
};

export default BrandVoiceCard;
