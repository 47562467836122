/* eslint-disable no-plusplus */
/* eslint-disable no-console */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import axios from 'axios';
import { getPhotosFromPexel } from 'Component/Shared/pexels-image.provider';
import createInstance from 'Config/AxiosConfig.js';

const MAXIMUM_IMAGE = 80;

export const TruncateString = (str, length = 48) => {
  const withoutSpaceString = str.replace(/ /g, '');
  return withoutSpaceString.length > length ? `${str.slice(0, length)}..` : str;
};

export const getSavedBlogs = async (from) => {
  try {
    const body = {
      username: localStorage.getItem('username'),
      name: localStorage.getItem('name'),
      source: from,
    };

    const instance = await createInstance();
    const { data } = await instance.post('ai-writer-select', body);

    const result = data.blog;
    const imageResult = await getPhotosFromPexel();
    const docs = [];
    console.log('blogdata__blogdata__blogdata__blogdata__blogdata__', result);

    for (let i = 0; i < result.length; i++) {
      const obj = {
        blog_name: result[i]?.blog,
        blog_author: result[i]?.fullname,
        blog_readtime: result[i]?.reading_time,
        blog_script: result[i]?.script_name,
        blog_image: imageResult[i % MAXIMUM_IMAGE],
        blog_date: result[i]?.scheduled_date,
        blog_published: result[i]?.date_published,
        image_url: result[i]?.image_url,
        blog_id: result[i]?.id,
      };

      docs.push(obj);
    }
    return { error: false, data: docs };
  } catch (ex) {
    console.log('Error in getting SavedDoc', ex);
    return { error: true, data: [] };
  }
};

export const openBlog = async (docName, from) => {
  try {
    console.log('_from_from', from);

    const body = {
      username: localStorage.getItem('username'),
      name: localStorage.getItem('name'),
      blog_name: docName,
      source: from,
    };

    // if (window.location.pathname === '/sales-ai-content-writer/app') {
    //   body = { ...body, source: 'Sales Copywriter' }
    // }
    const { data } = await axios.post(
      'https://apis.crawlq.ai/ai-writer-load',
      body,
    );

    return {
      data: {
        blogId: data.blog.id,
        goolgeQuery: data.blog.topic,
        query: docName,
        output: data.blog.text,
        lang: 'US',
        save: true,
      },
      error: false,
    };
  } catch (ex) {
    console.log('Error in Opening Document', ex);
    return {
      error: true,
      data: null,
    };
  }
};

export const deleteBlog = async (blogName, isFromSales = false) => {
  try {
    let payload = {
      username: localStorage.getItem('username'),
      name: localStorage.getItem('name'),
      blog_name: blogName,
      source: '',
    };
    if (isFromSales) {
      payload = { ...payload, source: 'Sales Copywriter' };
    }
    const res = await axios.post(
      'https://apis.crawlq.ai/ai-writer-delete',
      payload,
    );

    if (res.data.statusCode === '200') return true;
    return false;
  } catch (ex) {
    console.log('Error in deleting Docs', ex);
    return false;
  }
};

export function sortBlogsByDate(arr) {
  return arr.sort((a, b) => {
    const dateA = new Date(a.blog_date);
    const dateB = new Date(b.blog_date);
    return dateB - dateA;
  });
}
