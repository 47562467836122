import PieChartIcon from '@mui/icons-material/PieChart';
import DFYIcon from '@mui/icons-material/LibraryBooks';
import NewWorkspaceIcon from '@mui/icons-material/FiberNew';
import ClockIcon from '@mui/icons-material/AccessTime';
import LaunchIcon from '@mui/icons-material/Launch';
import TopOfFunnelIcon from 'assets/icons/TopOfFunnelIcon';
import PlanIcon from '@mui/icons-material/Psychology';
import MarketResearchIcon from '@mui/icons-material/TravelExplore';
import ProduceIcon from '@mui/icons-material/DriveFileRenameOutline';
import PublishIcon from '@mui/icons-material/Public';

import PlanPanel from 'components/common/MainMenu/PlanPanel/PlanPanel';
import MarketFunnelPanel from 'components/common/MainMenu/MarketFunnelPanel/MarketFunnelPanel';
import ProducePanel from 'components/common/MainMenu/ProducePanel/ProducePanel';
import PublishPanel from 'components/common/MainMenu/PublishPanel/PublishPanel';

export const PlanPanelInfos = [
  {
    id: 1,
    plan_header: 'Done For You Research',
    plan_header_icon: PieChartIcon,
    plan_description: 'Our experts will build your workspace with your little answers and enjoy DFY workspace in no time.',
    plan_time: '5 mins',
    plan_time_icon: ClockIcon,
    plan_start_text: 'Start',
    plan_start_text_icon: LaunchIcon,
    plan_path: '/done-for-you',
  },
  {
    id: 2,
    plan_header: 'Choose From Template',
    plan_header_icon: DFYIcon,
    plan_description: 'Jump start your business idea with pre-researched audience persona.',
    plan_time: '2 mins',
    plan_time_icon: ClockIcon,
    plan_start_text: 'Start',
    plan_start_text_icon: LaunchIcon,
    plan_path: '/begin-from-template',

  },
  {
    id: 3,
    plan_header: 'Begin From Scratch',
    plan_header_icon: NewWorkspaceIcon,
    plan_description: 'AI Athena helps you build your persona research and pin pointsyour customers need and thoughts',
    plan_time: '7 mins',
    plan_time_icon: ClockIcon,
    plan_start_text: 'Start',
    plan_start_text_icon: LaunchIcon,
    plan_path: '/new-workspace',

  },
];

export const MarketFunnelPanelInfos = [
  {
    header_text: 'Attract',
    header_icon: TopOfFunnelIcon,
    description_text: 'Define your Niche and Sub Niche as well as Value Propositions.',
    time_text: '7 mins',
    time_icon: ClockIcon,
    start_text: 'Explore',
    start_icon: LaunchIcon,
    path: '/market-research',
  },
  {
    header_text: 'Desire',
    header_icon: TopOfFunnelIcon,
    description_text: 'Define your Niche and Sub Niche as well as Value Propositions.',
    time_text: '9 mins',
    time_icon: ClockIcon,
    start_text: 'Explore',
    start_icon: LaunchIcon,
    path: '/market-research/desire',

  },
  {
    header_text: 'Convert',
    header_icon: TopOfFunnelIcon,
    description_text: 'Define your Niche and Sub Niche as well as Value Propositions.',
    time_text: '7 mins',
    time_icon: ClockIcon,
    start_text: 'Explore',
    start_icon: LaunchIcon,
    path: '/market-research/convert',

  },
];

export const ProducePanelInfos = [
  {
    header_text: 'Build Market Message',
    header_icon: TopOfFunnelIcon,
    features_list: [
      {
        feature_name: 'Generate Blog Outline',
        feature_path: '/market-message',
      },
      {
        feature_name: 'Generate Templates',
        feature_path: '/market-message',

      },
      {
        feature_name: 'Private Custom Templates',
        feature_path: '/market-message',

      },
    ],
  },
  {
    header_text: 'Build Sales Message',
    header_icon: TopOfFunnelIcon,
    features_list: [
      {
        feature_name: 'Video Vineyards',
        feature_path: '/market-message/sales',

      },
      {
        feature_name: 'Headline Helios',
        feature_path: '/market-message/sales',

      },
      {
        feature_name: 'Sales Samurai',
        feature_path: '/market-message/sales',

      },
      {
        feature_name: 'Email Espresso',
        feature_path: '/market-message/sales',

      },
    ],
  },
];

export const PublishPanelInfos = [
  {
    feature_name: 'Articles Assets Library',
    feature_path: '/digital-assets-library',
  },
  {
    feature_name: 'Sales Assets Library',
    feature_path: '/digital-assets-library',
  },
  {
    feature_name: 'Template Library',
    feature_path: '/home',
  },
  {
    feature_name: 'Persona Library',
    feature_path: '/all-workspace-persona-status',
  },

];

export const PanelInfos = [

  {
    panel_id: 1,
    panel_header: 'Plan',
    panel_icon: PlanIcon,
    panel_subheader: 'Begin your content journey by creating new workspace',
    panel_component: PlanPanel,
  },
  {
    panel_id: 2,
    panel_header: 'Market Research',
    panel_icon: MarketResearchIcon,
    panel_subheader: 'Unique Research Funnels to target your audience',
    panel_component: MarketFunnelPanel,
  },
  {
    panel_id: 3,
    panel_header: 'Produce',
    panel_icon: ProduceIcon,
    panel_subheader: 'Create Templatized Content compliant to audience strategy',
    panel_component: ProducePanel,
  },
  {
    panel_id: 4,
    panel_header: 'Publish',
    panel_icon: PublishIcon,
    panel_subheader: 'Increae ROI of Content across channel, persona and business.',
    panel_component: PublishPanel,
  },
];
