import CustomTooltip from 'Component/Shared/CustomTooltip';
import { DeleteIcon, EditIcon } from 'components/common';
import AvatarGeneratorPlaceholder from 'components/common/AvatarGenerator/AvatarGenerator';
import SearchInput from 'components/common/SearchInput';
import { TableHeader } from 'components/common/Table/Table';
import { ScaffoldCard } from 'components/common/scaffold-card';
import { DisplayAssistantsLoader } from 'components/skeleton/assistant/display-table-loader';
import { DISPLAY_ASSISTANTS_TABLE_HEADER } from 'constants/assistants';
import { ROUTES } from 'constants/routes';
import { useGetAllAssistants } from 'queries/assistants/get-assistants';
import { useMutateDeleteAssistant } from 'queries/assistants/mutate-delete-assistant';
import { useNavigate } from 'react-router-dom';
import { getTrimmedString } from 'utils/helpers/string-service';
import React, { useState } from 'react';
import CreateAssistantLink from '../assistants-actions/CreateAssistantLink';
import ImportAssistantLink from '../assistants-actions/ImportAssistantLink';
import MakeAssistantPublic from '../assistants-actions/MakeAssistantPublic';
import ShareAssistant from '../assistants-actions/ShareAssistant';
import DeleteAssistantModal from '../assistants-actions/delete-assistant/DeleteAssistant';
import styles from './show-assistants.module.css';

const ACTION_NAME = {
  EMPTY: 'EMPTY',
  CLONE: 'CLONE',
  SHARE: 'SHARE',
  DELETE: 'DELETE',
  PUBLIC: 'PUBLIC',
  URL: 'URL',
};

const ShowAssistants = () => {
  const navigate = useNavigate();

  const [ModalIs, setModalIs] = useState('');
  const [selectedAssistantId, setSelectedAssistantId] = useState('');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(1000000); // Once filter is added fix it.
  const [tab, setTab] = useState('All');

  const { data: assistants, isFetching } = useGetAllAssistants(page, pageSize);

  const {
    mutateAsync: deleteAssistant,
    isLoading: isDeletingAssistant,
  } = useMutateDeleteAssistant();

  // const totalAssistants = userBlogAssistantsData?.totalAssistants || 0;

  const [searchKey, setSearchKey] = useState('');

  const applySearchFilter = (assistant) => {
    const key = searchKey.trim()?.toLowerCase();
    if (key === '' && !tab) return true;

    const { assistant_name, description, creator_name } = assistant;

    if (!tab || tab === 'All') {
      return (
        assistant_name?.toLowerCase().includes(key) ||
        description?.toLowerCase().includes(key) ||
        creator_name?.toLowerCase().includes(key)
      );
    }

    const tabInLowerCase = tab.toLowerCase();

    return (
      assistant.assistant_name?.toLowerCase().includes(tabInLowerCase) ||
      assistant.description?.toLowerCase().includes(tabInLowerCase)
    );
  };

  const handleActionClick = (actionName, assistant) => {
    const assistantId = assistant?.assistant_id;

    setModalIs(actionName);
    setSelectedAssistantId(assistantId);
  };

  const handleEdit = (assistant) => {
    navigate(ROUTES.EDIT_ASSISTANTS, { state: assistant });
  };

  const handleDeleteAssistant = async () => {
    await deleteAssistant(selectedAssistantId);
    setModalIs('');
  };

  return (
    <div className={styles.child_wrapper}>
      {ModalIs === ACTION_NAME.DELETE && (
        <DeleteAssistantModal
          isOpen={!!ModalIs}
          handleClose={() => setModalIs('')}
          handleDelete={handleDeleteAssistant}
          isDeleting={isDeletingAssistant}
        />
      )}

      <div className={styles.left_child}>
        <div className={styles.buttons_wrapper}>
          <div className={styles.search_wrapper}>
            <ImportAssistantLink />
            <SearchInput
              placeholder="Search Assistants"
              onChange={(e) => setSearchKey(e.target.value)}
              value={searchKey}
            />
          </div>
        </div>
        {/* )} */}
        <TableHeader headers={DISPLAY_ASSISTANTS_TABLE_HEADER} />
        {isFetching ? (
          <DisplayAssistantsLoader />
        ) : (
          <div className={styles.table_column_wrapper}>
            {assistants &&
              assistants.length > 0 &&
              assistants.filter(applySearchFilter).map((assistant) => (
                <ScaffoldCard
                  key={`${assistant?.assistant_name} ${assistant.assistant_id}`}
                  className={`${styles.blogs_table_content} h-margin-b-small`}
                >
                  <div
                    className={`${styles.table_name} ${styles.assistant_name}`}
                  >
                    <div>
                      <AvatarGeneratorPlaceholder
                        avatarProp={assistant?.avatar_props}
                        width={'30%'}
                      />
                    </div>

                    {getTrimmedString(assistant?.assistant_name, 70)}
                  </div>

                  <div className={styles.table_description}>
                    <CustomTooltip
                      title={assistant?.assistant_description}
                      placement="top"
                      arrow
                    >
                      {getTrimmedString(assistant?.description, 120)}
                    </CustomTooltip>
                  </div>

                  <div
                    className={`${styles.table_name} ${styles.creator_name}`}
                  >
                    {assistant?.creator_name}
                  </div>

                  <div
                    className={`${styles.table_name} ${styles.creator_name}`}
                  >
                    {assistant?.created_at}
                  </div>
                  <div className={styles.table_name}>
                    <MakeAssistantPublic
                      // published assistants have from_support flag true
                      isPublic={Boolean(assistant?.from_support)}
                      assistantId={assistant.assistant_id}
                    />
                    <CustomTooltip
                      placement="top"
                      title={
                        !assistant?.from_support
                          ? 'Edit Assistant'
                          : 'Cannot Edit Public Assistant'
                      }
                      arrow
                    >
                      <EditIcon
                        disabled={assistant?.from_support}
                        onClick={() => handleEdit(assistant)}
                      />
                    </CustomTooltip>

                    <ShareAssistant
                      // published assistants have from_support flag true, and cannot be shared
                      isPublic={Boolean(assistant?.from_support)}
                      assistantId={assistant.assistant_id}
                    />
                    <CreateAssistantLink assistantId={assistant.assistant_id} />
                    <CustomTooltip
                      placement="top"
                      title={
                        !assistant?.from_support
                          ? 'Delete Assistant'
                          : 'Cannot Delete public assistant'
                      }
                      arrow
                    >
                      <DeleteIcon
                        onClick={() =>
                          !assistant?.from_support &&
                          handleActionClick(ACTION_NAME.DELETE, assistant)
                        }
                        disabled={assistant?.from_support}
                      />
                    </CustomTooltip>
                  </div>
                </ScaffoldCard>
              ))}
          </div>
        )}
        {/*
        // Uncomment it once filter is implemented in backend.

        <div className={styles.pagination_view}>
          <PaginationView
        {!isLoading && (
          <div className={styles.pagination_view}>
            <PaginationView
              page={page}
              updatePage={setPage}
              pageSize={pageSize}
              updatePageSize={setPageSize}
              total={totalAssistants}
            />
          </div>
        )} */}
      </div>
    </div>
  );
};

export default ShowAssistants;
