import { ROUTES } from 'constants/routes';
import { useMutation, useQueryClient } from 'react-query';
import createInstance from 'Config/AxiosConfig';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { GET_ASSISTANTS_QUERY_KEY } from './get-assistants';

export const useMutateCreateAssistant = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const createAssistant = async ({
    assistant_name,
    description,
    instructions,
    tools,
    sources,
    avatar_props,
    file_ids,
  }) => {
    const payload = {
      assistant_name,
      description,
      instructions,
      tools,
      sources,
      avatar_props,
      file_ids,
      model_name: 'gpt-4-1106-preview',
    };

    const instance = await createInstance();

    const response = await instance.post('create-assistant', payload);

    if (response?.data?.statusCode === '200') {
      return response?.data?.data;
    }

    throw new Error('Assistant could not be created, Try again !');
  };

  return useMutation(createAssistant, {
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [GET_ASSISTANTS_QUERY_KEY],
      });
      toast.success('Assistant created successfully', {
        position: 'bottom-center',
        theme: 'dark',
      });
      navigate(ROUTES.ASSISTANTS);
    },
    onError: (error) => {
      toast.error(`${error.message}`, {
        position: 'bottom-center',
        theme: 'dark',
      });
    },
  });
};
