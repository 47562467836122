import React, { useEffect, useState } from 'react';
import { CATEGORY_NAMES } from 'components/constants';
import CustomizedAccordions from './CustomAccordian';
import { getBrandVoiceDescriptionByType } from '../services';

const CategoryDescription = () => {
  const [expanded, setExpanded] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [description, setDescription] = useState(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    if (!expanded) return;
    setIsLoading(true);
    getBrandVoiceDescriptionByType(expanded?.name)
      .then((data) => {
        setDescription(data);
      })
      .catch()
      .finally(() => {
        setIsLoading(false);
      });
  }, [expanded]);

  return (
    <CustomizedAccordions
      panels={CATEGORY_NAMES}
      expanded={expanded}
      descriptionData={description}
      handleChange={handleChange}
      isLoading={isLoading}
    />
  );
};

export default CategoryDescription;
