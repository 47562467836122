/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import { CreditManage } from 'Component/Shared/CreditManager';
import Flyout from 'Component/Shared/Flyout/Flyout';
import { MARKET_INSIGHTS_COST } from 'Component/Shared/featuresCreditsCost';
import LoaderSpin from 'Component/Shared/Loader';
import { DistractionFreeTextBox } from 'Component/Shared/Tinytextbox';
import { getTranslatedText } from 'Component/Shared/TranshelperStatic';
import AdvancedTextEditors from 'Component/Pages/Content Automation/AIContentWriter/AdvancedTextEditorInsights';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ClearAllButtons from './ClearAllButton';
import saveInsights, {
  fetchInsights,
  getCurrentFormatedDate,
} from './insightsLogic';
import SubCategoryDescription from './SubCategoryDescription';

function Insights(props) {
  const isAthenaTrained = useSelector((state) => state.workspace.is_trained);

  const [subCategoryName, setSubCategoryName] = useState('');
  const [categoryInsightsData, setCategoryInsightsData] = useState('');
  const [
    IsCategoryInsightDataFetched,
    setIsCategoryInsightDataFetched,
  ] = useState(false);

  const [IsInsightsLoading, setIsInsightsLoading] = useState(false);
  const [IsInsightsSaving, setIsInsightsSaving] = useState(false);
  const [IsCreditFailInsights, setIsCreditFailInsights] = useState(false);
  const [isOpenEditorAdvancedMode, setIsOpenEditorAdvancedMode] = useState(
    false,
  );

  useEffect(() => {
    setSubCategoryName((prev) => props.subCategoryName);
    setIsCategoryInsightDataFetched(true);
    setCategoryInsightsData(props.mrr_insight);
  }, [props]);

  useEffect(() => {
    if (!subCategoryName) return;
    if (!window.tinyMCE.activeEditor) {
      console.log('no editor');
      return;
    }
    window.tinyMCE.activeEditor.on('change', () => {
      saveInsights(subCategoryName, false, true);
    });
  });

  const handleGenerateInsights = async () => {
    const prevContent = window.tinyMCE.activeEditor.getContent();

    setIsInsightsLoading(true);
    setIsCategoryInsightDataFetched(false);

    try {
      const { success } = await CreditManage(MARKET_INSIGHTS_COST, true);

      if (success) {
        const result = await fetchInsights(subCategoryName);
        const currentFormatedDate = getCurrentFormatedDate();

        const sepString = `<p><blockquote><em> ${currentFormatedDate} </em></blockquote></p>`;
        const translatedTexts = await getTranslatedText(
          result.data.data.replaceAll('\n', '<br>'),
        );

        const content = `${sepString} ${translatedTexts} ${prevContent} `;

        setCategoryInsightsData(content);
        props.setInsightsData(content);

        const { success } = await CreditManage(MARKET_INSIGHTS_COST);

        if (success) {
          handleSaveInsights(null, content); // event as sent as null because save funtion is also called from button click
        } else {
          setIsCreditFailInsights(true);
        }
      } else {
        setIsCreditFailInsights(true);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsInsightsLoading(false);
      setIsCategoryInsightDataFetched(true);
      setIsInsightsLoading(false);
    }
  };

  const handleSaveInsights = async (e, content = false) => {
    // event(e) may be recived as null ( but no issue not using event), content is false in case function is called from btn click
    setIsInsightsSaving(true);
    await saveInsights(subCategoryName, content);
    setIsInsightsSaving(false);
  };

  return (
    <>
      <Flyout
        open={isOpenEditorAdvancedMode}
        onClose={() => {
          const updatedEditor = window.tinymce
            .get('texteditor-insights')
            .getContent();
          setCategoryInsightsData(updatedEditor);
          setIsOpenEditorAdvancedMode(false);
        }}
        title="Advance Social Listening Insights"
      >
        {isOpenEditorAdvancedMode && (
          <AdvancedTextEditors
            text={categoryInsightsData}
            handleSave={handleSaveInsights}
          />
        )}
      </Flyout>

      <ClearAllButtons />
      <SubCategoryDescription subCategoryName={subCategoryName} />
      <div className="mrr-main-right-bottom">
        <div className="insights-container">
          <div className="insights-header">
            <span>Social Listening </span>
            <div className="insights-header-right">
              {IsCreditFailInsights ? (
                <span className="insights-credit-over">Credits Over</span>
              ) : IsInsightsLoading ? (
                <LoaderSpin width="20" height="20" />
              ) : (
                <span
                  className="insights-buttons"
                  onClick={handleGenerateInsights}
                >
                  Generate
                </span>
              )}
              {IsInsightsSaving ? (
                <LoaderSpin width="20" height="20" />
              ) : (
                <span
                  className="insights-buttons mx-1"
                  onClick={handleSaveInsights}
                >
                  Save
                </span>
              )}
              {isAthenaTrained ? (
                <span
                  style={{ fontSize: '0.8rem' }}
                  onClick={() => {
                    const updatedEditor = window.tinymce
                      .get('texteditor')
                      .getContent();
                    setCategoryInsightsData(updatedEditor);
                    setIsOpenEditorAdvancedMode(true);
                  }}
                  className="insights-buttons "
                >
                  Advanced Mode
                </span>
              ) : (
                <small
                  style={{ fontSize: '0.8rem' }}
                  className="btn btn-secondary px-0 ml-3"
                  disabled
                >
                  Athena not Trained
                </small>
              )}
            </div>
          </div>

          <div>
            {IsCategoryInsightDataFetched && props.showLoader ? (
              <DistractionFreeTextBox
                text={categoryInsightsData}
                onChange={(e) => {}}
              />
            ) : (
              <div className="data-load-wait-display">
                <LoaderSpin height="50px" width="50px" />
                <p style={{ color: 'black' }}>Curating Insights</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Insights;
