/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { motion } from 'framer-motion';
import React from 'react';
import ReactDom from 'react-dom';
import ClearIcon from '@mui/icons-material/Clear';
import './FlyOut.css';
import { Divider } from '../divider';

const OVERLAY_STYLES = {
  position: 'fixed',
  right: 0,
  top: 40,
  bottom: 0,
  left: 0,
  backgroundColor: 'rgba(0,0,0,.7)',
  zIndex: 1000,
};

function Flyout({ open, children, onClose, title, subtitle, width, isRight }) {
  const MODAL_STYLES = {
    width: width || '40vw',
    height: '95vh',
    position: 'fixed',
    ...(!isRight && { left: 0 }),
    ...(isRight && { right: 0 }),
    top: 40,
    backgroundColor: 'var(--background-color)',
    zIndex: 1000,
    borderRadius: '2px',
  };

  if (!open) return null;

  return ReactDom.createPortal(
    <div>
      <div style={OVERLAY_STYLES} onClick={onClose} />
      <motion.div
        className="flyout-container h-padding-a-small"
        initial={
          isRight ? { opacity: 0, x: '+100vw' } : { opacity: 0, x: '-100vw' }
        }
        exit={isRight ? { x: '+100vw' } : { x: '-100vw' }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5, ease: 'easeInOut' }}
        style={MODAL_STYLES}
      >
        <div className="flyout-close-container  h-padding-a-small">
          <h2>{title}</h2>
          {subtitle ? (
            <span
              style={{ fontSize: '14px', color: '#13d613', fontWeight: '700' }}
            >
              Beta
            </span>
          ) : null}

          <ClearIcon className="flyout-close-button" onClick={onClose} />
        </div>
        <Divider />
        <div className="flyout-content-container">{children}</div>
      </motion.div>
    </div>,
    document.getElementById('portal'),
  );
}

export default Flyout;
