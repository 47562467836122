/* eslint-disable consistent-return */
/* eslint-disable no-empty */
/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable import/no-unresolved */
import axios from 'axios';
import createInstance from 'Config/AxiosConfig';
import { store } from 'redux/store';
import { baseAPIsUrl } from 'Config/Api';
import Plans from '../../Shared/Plans';

const getUserFromRedux = () => {
  const state = store.getState();
  return state.user;
};
const getWorkspaceFromRedux = () => {
  const state = store.getState();
  return state.workspace;
};

const getUserPlan = async () => {
  try {
    const response = await axios.get(
      `https://apis.crawlq.ai/dashboard-userplan?username=${localStorage.getItem(
        'username',
      )}`,
    );

    const user_plan = response.data.data.data.Groups[0].Description;

    const creation_date = response.data.data.data.Groups[0].CreationDate;

    const user_plan_details = {
      plan: user_plan,
      allowedUsers: Plans[user_plan].users,
      allowedProjects: Plans[user_plan].projects,
      allowedCredits: Plans[user_plan].credits,
      group_name: response.data.data.data.Groups[0].GroupName,
      creation_date,
    };

    return user_plan_details;
  } catch (ex) {
    console.log('Not able to fetch user plan', ex);
    return '-';
  }
};

export const getProgressPercentage = async () => {
  const user = getUserFromRedux();
  const workspace = getWorkspaceFromRedux();
  try {
    const body = {
      name: workspace.name,
      username: user.username,
    };
    const instance = await createInstance();
    const result = await instance.post('dashboard-mrr-progress', body);
    return result.data.data;
  } catch (ex) {
    console.log(`Cannot load ProgressPercentage ${ex}`);
    return [];
  }
};

export const getProjectCount = async () => {
  // getTeamMembersCount()
  try {
    const { data } = await axios.get(
      `${baseAPIsUrl}/projects-all?username=${localStorage.getItem(
        'username',
      )}`,
    );
    const projectCount = data.data.data.length;
    return projectCount;
  } catch (ex) {
    console.log('Project count not fetched', ex);
    return 0;
  }
};

export const getTeamMembers = async () => {
  try {
    const response = await axios.get(
      `https://apis.crawlq.ai/dashboard-teammembers?username=${localStorage.getItem(
        'username',
      )}`,
    );

    if (response.status !== 200 || response.data === undefined) {
    } else {
      const memberCount = response.data.data.length;
      if (memberCount === 1) {
        return 0;
      }

      return memberCount - 1;
    }
  } catch (ex) {
    console.log('Cannot get team members');
    return '-';
  }
};

export const getCreditCount = async () => {
  try {
    const body = {
      amount: 0,
      check: true,
    };

    const instance = await createInstance();
    const result = await instance.post('charge-credits', body);

    if (result.status === 200) {
      return {
        user_credit: result.data.credits,
        total_credit: result.data.total_credits,
        topup_credits: result.data.extra,
      };
    }
  } catch (err) {
    console.log(err);
  }
};

export const getAthenaSources = async () => {
  const body = {
    username: getUserFromRedux().username,
    name: getWorkspaceFromRedux().name,
  };
  const response = await axios.post(
    'https://apis.crawlq.ai/editor-athena-modes',
    body,
  );
  // const response = await axios.post('https://p8dapk4yf9.execute-api.us-east-2.amazonaws.com/devel/editor-athena-modes', body)

  if (response.data.statusCode === 200) {
    return response.data.body;
  }
  return [];
};

export default getUserPlan;
