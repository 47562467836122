import createInstance from 'Config/AxiosConfig';

export const getAllWorkspaceBuildStatus = async () => {
  const instance = await createInstance();
  const response = await instance.post(
    'https://p8dapk4yf9.execute-api.us-east-2.amazonaws.com/prod/get-onboarding-status',
  );
  return response;
};

export const getWorkspaceBuildStatusByName = async (workspaceName) => {
  const instance = await createInstance();

  const response = await instance.post(
    'https://p8dapk4yf9.execute-api.us-east-2.amazonaws.com/prod/get-onboarding-status',
    { name: workspaceName },
  );
  return response;
};
