/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import React, { useState, useEffect, useContext } from 'react';
import TimelineRoundedIcon from '@mui/icons-material/TimelineRounded';
import '../css/mrr.css';
import { Button } from '@mui/material';
import axios from 'axios';
import InsightsGlobalDisplay from '../../InsightsGlobal/InsightsGlobalDisplay.js';
import LoaderSpin from '../../../Shared/Loader';

const commonStyle = {
  borderRadius: '0px',
  boxShadow: 'none',
  flex: '1',
};

const activeStyle = {
  backgroundColor: '#2a9df5',
  border: 'none',
  color: '#fff',
  ...commonStyle,
};

const inactiveStyle = {
  color: '#2a9df5',
  borderColor: '#2a9df5',
  ...commonStyle,
};

export function AI_MRR(props) {
  const [currentTab, setCurrentTab] = useState(0);
  return (
    <div className="d-flex flex-column">
      <div className="d-flex justify-content-center" style={{}}>
        <Button
          onClick={() => setCurrentTab(0)}
          variant={currentTab === 0 ? 'contained' : 'outlined'}
          style={currentTab === 0 ? activeStyle : inactiveStyle}
        >
          FRED Avatar
        </Button>
        <Button
          onClick={() => setCurrentTab(1)}
          variant={currentTab === 1 ? 'contained' : 'outlined'}
          style={currentTab === 1 ? activeStyle : inactiveStyle}
        >
          Insights
        </Button>
      </div>
      {currentTab === 0 && <QuestionTab props={props} />}
      {currentTab === 1 && <InsightsGlobalDisplay />}
    </div>
  );
}

function QuestionTab(props) {
  const [mrrData, setMrrData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const getMRRData = async () => {
    try {
      setIsLoading(true);
      const { data } = await axios.post('https://apis.crawlq.ai/mrr-global', {
        username: localStorage.getItem('username'),
        name: localStorage.getItem('name'),
      });
      setMrrData(data.data);
      setIsLoading(false);
      return data;
    } catch (ex) {
      console.log('Cannot Load MRR Data', ex);
      return [];
    }
  };

  useEffect(() => {
    getMRRData();
  }, []);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center">
        <LoaderSpin />
      </div>
    );
  }
  if (mrrData.length === 0) {
    return (
      <div>
        <h4>Have you not done yet your Market Research?? </h4>
        <h6>
          Get to know your target audience better by navigating through CrawlQ{' '}
          <a href="/MRR-Get-Started" target="_blank">
            Market Research and Resonance.
          </a>
        </h6>
      </div>
    );
  }
  return (
    <div>
      {mrrData.map((inf) => (
        <div className="headline_subHeadlines border-rounded-light my-3">
          <div className="mrr_questions">
            <div className="div-link">
              <TimelineRoundedIcon className="analytics-icon" />
              <h4 className="mb-0">{inf.sub_category}</h4>
            </div>
            <div className="tag-table">
              <div className="d-flex mb-2" style={{ marginTop: '3.5rem' }}>
                <span className="left">
                  <span className="th"> Questions</span>
                </span>
                <span className="right-answer">
                  <span className="th">Answer</span>
                </span>
              </div>

              <div className="w-100">
                {inf.mrqna.map((que) => (
                  <div className="tag-col d-flex">
                    <span className="left">{que.question}</span>
                    <span className="right-answer">{que.answer}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default AI_MRR;
