/* eslint-disable max-len */
import createInstance from 'Config/AxiosConfig';
import { useQuery } from 'react-query';

export const getUserBlogTemplate = async () => {
  try {
    const instance = await createInstance();
    const { data } = await instance.post(
      'https://p8dapk4yf9.execute-api.us-east-2.amazonaws.com/prod/get-user-blog-template',
    );
    if (data.statusCode === '200') return data.templates;
    return [];
  } catch (ex) {
    console.log('hey');
    return [];
  }
};

// Keep Query Value as File Name to maintain consistency and uniqueness
const QUERY_KEY = 'get-user-blog-template';

// Query Config Options
const queryOptions = {
  staleTime: 30000,
};

// QUERY
export const useGetAllWorkspacePersonaStatus = () =>
  useQuery(QUERY_KEY, getUserBlogTemplate, queryOptions);
