import createInstance from 'Config/AxiosConfig';
import { store } from 'redux/store';

const toggleWorkspaceAsFavourite = async (workspaceName, is_favourite) => {
  try {
    const state = store.getState();
    const { username } = state.user;

    const body = {
      is_favourite: !is_favourite,
      name: workspaceName,
      username,
    };

    const instance = await createInstance();

    const result = await instance.post('edit-project', body);

    if (result.data.statusCode === '200') {
      return {
        error: false,
      };
    }
    return {
      error: true,
    };
  } catch (error) {
    return {
      error: true,
    };
  }
};

export default toggleWorkspaceAsFavourite;
