import React, { useEffect, useState } from 'react';
import ChatAthenaWeb from './ChatAthenaController/ChatAthenaWeb';
import { DesktopDashboard } from './startFlowPages/desktopPages/DesktopDashboard';
import MiniDashboard from './startFlowPages/MiniDashboard';

export const ChatAthena = (props) => {
  const { type } = props;
  const [isMobile, setIsMobile] = useState(false);
  const [isDarkMode, setDarkMode] = useState(true);
  const [isStarterFlow, setStarterFlow] = useState(type);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 910);
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (isMobile) {
    return (
      <MiniDashboard
        isDarkMode={isDarkMode}
        setDarkMode={setDarkMode}
        isStarterFlow={isStarterFlow}
        setStarterFlow={setStarterFlow}
      />
    );
  }

  return (
    <DesktopDashboard
      isDarkMode={isDarkMode}
      setDarkMode={setDarkMode}
      isStarterFlow={isStarterFlow}
      setStarterFlow={setStarterFlow}
    />
  );
};
