/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable max-len */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Workspace.css';
import Modal from 'react-modal';

/* Components */
import AddIcon from '@mui/icons-material/Add';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { userWorkspaceCountUpdate } from 'redux/index';
import { useNavigate } from 'react-router-dom';
import HelperVideoModalPopup from 'Component/Shared/HelperVideoPopUp/HelperVideoPop';
import { WORKSPACE, WORKSPACES } from 'libs/constants';
import WorkspaceHeader from './WorkspaceHeader/WorkspaceHeader';
import DoneForYouWorkspace from './DoneForYouWorkspace/DoneForYouWorkspace';
import UserWorkspace from './UserWorkspace/UserWorkspace';
import PaidWorkspace from './PaidWorkspace/PaidWorkspace';
import DemoWorkspace from './DemoWorkspace/DemoWorkspace';
import CreateNewWorkspace from './CreateNewWorkspace/CreateNewWorkspace';
import LoaderSpin from '../../../Shared/Loader';
import Topbar from '../../../Shared/Topbar/Topbar';
import NewUserImage from '../../../../assets/images/new-user.svg';
import NewUserLandingPage from '../NewUserLandingPage/NewUserLandingPage';

/* Logic Function */
import fetchDemoWorkspace, {
  fetchUserWorkspace,
  fetchUserPlan,
} from './WorkspaceLogic';
import setGoogleLanguageCookie, {
  eraseCookie,
} from '../../../Shared/Cookie_custom';

/* Utils */

const ModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width: '640px',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

function Workspace() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [Workspace, setWorkspace] = useState('user');

  const workspaceCount = useSelector((state) => state.user.workspace_count);
  const [DemoWorkspacesList, setDemoWorkspacesList] = useState([]);
  const [PaidWorkspacesList, setPaidWorksapcesList] = useState([]);
  const [UserWorkspacesList, setUserWorkspacesList] = useState();
  const [dfysWorkspacesList, setDfysWorkspacesList] = useState([]);
  const [IsOpenNewWorkspaceModal, setIsOpenNewWorkspaceModal] = useState(false);
  const [IsOpenCurateWorkspaceModal, setIsOpenCurateWorkspaceModal] = useState(
    false,
  );

  const [IsWorkspaceLimitReached, setIsWorkspaceLimitReached] = useState(false);
  const [SearchOpen, setSearchOpen] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  useEffect(async () => {
    setGoogleLanguageCookie(null);
    eraseCookie('googtrans', '.crawlq.ai'); // erases the domain domain cookie
    eraseCookie('googtrans', 'devel.crawlq.ai'); // erases the subdomain domain cookie
    eraseCookie('googtrans', 'beta.crawlq.ai'); // erases the subdomain domain cookie
    eraseCookie('googtrans', 'app.crawlq.ai'); // erases the subdomain domain cookie

    await LoadUserWorkspace();
  }, []);

  const handleChangeSearchInput = (e) => {
    setSearchInput(e.target.value);
  };

  // Fetch Demo Workspaces and setResults
  const LoadDemoWorkspace = async (paid_workspaces) => {
    const { result } = await fetchDemoWorkspace();

    if (paid_workspaces) {
      const paidWorkspaceArrayList = paid_workspaces.map(
        (paidWorkspace) => paidWorkspace.name,
      );

      const filteredDemoWorkspace = (result || []).filter(
        (item) => !paidWorkspaceArrayList.includes(item.name),
      );

      setDemoWorkspacesList(filteredDemoWorkspace);
    } else {
      setDemoWorkspacesList(result);
    }
  };

  // Fetch User Workspaces and Paid Workspace and setResults
  const LoadUserWorkspace = async () => {
    const {
      result,
      paid_workspaces,
      dfys_workspaces,
    } = await fetchUserWorkspace();

    setPaidWorksapcesList(paid_workspaces);
    setDfysWorkspacesList(dfys_workspaces);
    // set user worksapce count to store.
    dispatch(userWorkspaceCountUpdate(result.length));

    setUserWorkspacesList(result);

    await LoadDemoWorkspace(paid_workspaces);

    await WorkspaceLimit(result);
  };

  const handleTabChange = (tabName) => {
    setWorkspace(tabName);
    if (searchInput) {
      setSearchInput('');
      setSearchOpen(false);
    }
  };

  const WorkspaceLimit = async (list_of_user_workspace) => {
    const { data, error } = await fetchUserPlan();
    if (error) return;
    const max_project_allowed = data.allowed_projects;

    if (list_of_user_workspace.length >= max_project_allowed) {
      setIsWorkspaceLimitReached(true);
    } else {
      setIsWorkspaceLimitReached(false);
    }
  };

  function NewWorkspaceModal() {
    return (
      <Modal
        isOpen={IsOpenNewWorkspaceModal}
        onRequestClose={() => setIsOpenNewWorkspaceModal(false)}
        style={ModalStyles}
        ariaHideApp={false}
      >
        <CreateNewWorkspace workspace_limit={IsWorkspaceLimitReached} />
      </Modal>
    );
  }

  function NoWorkspaceAvailable() {
    return (
      <div className="NO-WORKSPACE-CONTAINER">
        <div>
          <h5>
            Welcome,
            {localStorage.getItem('Current_user')}
          </h5>

          <img src={NewUserImage} />
          <span>Research and Write 100% Unique Content</span>
          <div>
            <button onClick={() => setIsOpenNewWorkspaceModal(true)}>
              Create New {WORKSPACE}
            </button>
            <button onClick={() => setWorkspace('demo')}>
              Clone From Marketplace
            </button>
          </div>
        </div>
      </div>
    );
  }

  function NoPaidWorkspace() {
    return (
      <div className="NO-WORKSPACE-CONTAINER">
        <div>
          <h5>
            Welcome,
            {localStorage.getItem('Current_user')}
          </h5>
          <span />
          <img src={NewUserImage} />
          <span>
            Oops! Looks like you have not purchased any {WORKSPACE}. Head to our
            marketplace and make your first purchase.
          </span>
          <div>
            {/* <button onClick={() => setIsOpenNewWorkspaceModal(true)}>Create New Workspace</button> */}
            <button onClick={() => setWorkspace('demo')}>
              Take me to Marketplace
            </button>
          </div>
        </div>
      </div>
    );
  }

  if (workspaceCount === 0 && !dfysWorkspacesList) {
    navigate('/chat-athena-flow', { replace: true });
    return <></>;
  }
  return (
    <>
      <Topbar />

      <div className="workspace_container">
        <NewWorkspaceModal />

        <WorkspaceHeader message={WORKSPACES} />

        <div className="main-workspace-container">
          <div className="workspace-table-container">
            <div className="workspace-table-tab">
              <div className="tabs-container">
                <div
                  className="tabs"
                  onClick={() => {
                    handleTabChange('user');
                    setWorkspace('user');
                  }}
                  style={
                    Workspace === 'user'
                      ? {
                          backgroundColor: '#2a9df5',
                          color: 'white',
                          boxShadow: '5px -3px 5px rgb(202, 202, 202)',
                          fontWeight: '500',
                        }
                      : {
                          backgroundColor: 'rgb(229 229 229)',
                          color: '#606060',
                        }
                  }
                >
                  {Workspace === 'user' && SearchOpen ? (
                    <div className="workspace-searchinputbox-container">
                      <input
                        value={searchInput}
                        onChange={handleChangeSearchInput}
                        placeholder={`Search ${WORKSPACE}`}
                        className="workspace-searchinputbox"
                      />
                      <CloseRoundedIcon
                        onClick={() => {
                          setSearchInput('');
                          setSearchOpen(false);
                        }}
                      />
                    </div>
                  ) : (
                    <div>
                      <span> My {WORKSPACES}</span>
                      <SearchRoundedIcon
                        onClick={() => setSearchOpen(true)}
                        className="mx-2"
                      />
                    </div>
                  )}
                  <div>
                    <HelperVideoModalPopup
                      tooltipTitle={`What is the Audience Persona ${WORKSPACE}? Learn more here!`}
                      videoLink="https://athena-helper-videos.s3.us-east-2.amazonaws.com/Video+1+-+Workspace.mp4"
                    />
                  </div>
                </div>
                <div
                  className="tabs"
                  onClick={() => {
                    handleTabChange('paid');
                    setWorkspace('paid');
                  }}
                  style={
                    Workspace === 'paid'
                      ? {
                          backgroundColor: '#2a9df5',
                          color: 'white',
                          boxShadow: '5px -3px 5px rgb(202, 202, 202)',
                          fontWeight: '500',
                        }
                      : {
                          backgroundColor: 'rgb(229 229 229)',
                          color: '#606060',
                        }
                  }
                >
                  {Workspace === 'paid' && SearchOpen ? (
                    <div className="workspace-searchinputbox-container">
                      <input
                        value={searchInput}
                        onChange={handleChangeSearchInput}
                        placeholder="Search Purchase"
                        className="workspace-searchinputbox"
                      />
                      <CloseRoundedIcon
                        onClick={() => {
                          setSearchInput('');
                          setSearchOpen(false);
                        }}
                      />
                    </div>
                  ) : (
                    <div>
                      <span>My Purchases</span>
                      <SearchRoundedIcon
                        onClick={() => setSearchOpen(true)}
                        className="mx-2"
                      />
                    </div>
                  )}
                </div>

                <div
                  className="tabs"
                  onClick={() => {
                    handleTabChange('demo');
                    setWorkspace('demo');
                  }}
                  style={
                    Workspace === 'demo'
                      ? {
                          backgroundColor: '#2a9df5',
                          color: 'white',
                          boxShadow: '5px -3px 5px rgb(202, 202, 202)',
                          fontWeight: '500',
                        }
                      : {
                          backgroundColor: 'rgb(229 229 229)',
                          color: '#606060',
                        }
                  }
                >
                  {Workspace === 'demo' && SearchOpen ? (
                    <div className="workspace-searchinputbox-container">
                      <input
                        value={searchInput}
                        onChange={handleChangeSearchInput}
                        placeholder="Search Marketplace"
                        className="workspace-searchinputbox"
                      />
                      <CloseRoundedIcon
                        onClick={() => {
                          setSearchInput('');
                          setSearchOpen(false);
                        }}
                      />
                    </div>
                  ) : (
                    <div>
                      <span>Marketplace</span>
                      <SearchRoundedIcon
                        onClick={() => setSearchOpen(true)}
                        className="mx-2"
                      />
                    </div>
                  )}
                  <div>
                    <HelperVideoModalPopup
                      tooltipTitle="Explore the Marketplace for templatized playground Audience Persona Workspaces? Save time and 50% of your AI credits!"
                      videoLink="https://athena-helper-videos.s3.us-east-2.amazonaws.com/Video+4+-+Marketplace.mp4"
                    />
                  </div>
                </div>
                <div
                  className="tabs"
                  onClick={() => {
                    handleTabChange('DFY');
                    setWorkspace('DFY');
                  }}
                  style={
                    Workspace === 'DFY'
                      ? {
                          backgroundColor: '#2a9df5',
                          color: 'white',
                          boxShadow: '5px -3px 5px rgb(202, 202, 202)',
                          fontWeight: '500',
                        }
                      : {
                          backgroundColor: 'rgb(229 229 229)',
                          color: '#606060',
                        }
                  }
                >
                  {Workspace === 'DFY' && SearchOpen ? (
                    <div className="workspace-searchinputbox-container">
                      <input
                        value={searchInput}
                        onChange={handleChangeSearchInput}
                        placeholder={`Search ${WORKSPACE}`}
                        className="workspace-searchinputbox"
                      />
                      <CloseRoundedIcon
                        onClick={() => {
                          setSearchInput('');
                          setSearchOpen(false);
                        }}
                      />
                    </div>
                  ) : (
                    <div>
                      <span>Done For You</span>
                      <SearchRoundedIcon
                        onClick={() => setSearchOpen(true)}
                        className="mx-2"
                      />
                    </div>
                  )}
                  <div>
                    <HelperVideoModalPopup
                      tooltipTitle="Order Done For Your Research? Our team of experts will deliver high-quality research within 2-days!"
                      videoLink="https://athena-helper-videos.s3.us-east-2.amazonaws.com/Video+3+-+New+Audience+Persona.mp4"
                    />
                  </div>
                </div>
              </div>

              <div
                className="tabs-create"
                onClick={() => navigate('/chat-athena-flow')}
              >
                <span>
                  <AddIcon /> Create new {WORKSPACE}
                </span>
                <div>
                  <HelperVideoModalPopup
                    tooltipTitle="How to setup a new custom Audience Persona Workspace? Explore 3 simple options!"
                    videoLink="https://athena-helper-videos.s3.us-east-2.amazonaws.com/Video+5+-+DFY.mp4"
                  />
                </div>
              </div>
            </div>

            {Workspace === 'user' && (
              <div
                className="workspace-table"
                style={
                  !UserWorkspacesList
                    ? {
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                      }
                    : null
                }
              >
                {UserWorkspacesList ? (
                  UserWorkspacesList.length === 0 ? (
                    <NoWorkspaceAvailable />
                  ) : (
                    <UserWorkspace
                      workspaces={UserWorkspacesList}
                      refetch_user_workspace={LoadUserWorkspace}
                      workspace_limit={IsWorkspaceLimitReached}
                      searchInput={searchInput}
                    />
                  )
                ) : (
                  <>
                    <LoaderSpin width="100" height="100" />
                    <p>{localStorage.getItem('Current_user')}, Loading</p>
                  </>
                )}
              </div>
            )}

            {Workspace === 'demo' && (
              <div
                className="workspace-table"
                style={
                  !DemoWorkspacesList
                    ? {
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                      }
                    : null
                }
              >
                {DemoWorkspacesList.length !== 0 ? (
                  <DemoWorkspace
                    workspaces={DemoWorkspacesList}
                    workspace_limit={IsWorkspaceLimitReached}
                    searchInput={searchInput}
                  />
                ) : (
                  <>
                    <LoaderSpin width="100" height="100" />
                    <p>
                      Loading demo workspaces for you,{' '}
                      {localStorage.getItem('Current_user')}
                    </p>
                  </>
                )}
              </div>
            )}

            {Workspace === 'paid' && (
              <div
                className="workspace-table"
                style={
                  !DemoWorkspacesList
                    ? {
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                      }
                    : null
                }
              >
                {PaidWorkspacesList ? (
                  <>
                    {PaidWorkspacesList ? (
                      PaidWorkspacesList.length === 0 ? (
                        <NoPaidWorkspace />
                      ) : (
                        <PaidWorkspace
                          workspaces={PaidWorkspacesList}
                          workspace_limit={IsWorkspaceLimitReached}
                          searchInput={searchInput}
                        />
                      )
                    ) : (
                      <>
                        <LoaderSpin width="100" height="100" />
                        <p>
                          Loading Paid Workspace for you,{' '}
                          {localStorage.getItem('Current_user')}
                        </p>
                      </>
                    )}
                  </>
                ) : (
                  <NoPaidWorkspace />
                )}
              </div>
            )}

            {Workspace === 'DFY' && (
              <div
                className="workspace-table"
                style={
                  !DemoWorkspacesList
                    ? {
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                      }
                    : null
                }
              >
                <DoneForYouWorkspace />
              </div>
            )}
          </div>

          {/* <div className="workspace-right-column">

                    </div> */}
        </div>
      </div>
    </>
  );
}

export default Workspace;
