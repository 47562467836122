import React from 'react';
import { AssistantsProvider } from 'context/assistants/AssistantsContext';
import Assistants from './Assistants';

function AssistantWrapper() {
  return (
    <AssistantsProvider>
      <Assistants />
    </AssistantsProvider>
  );
}

export default AssistantWrapper;
