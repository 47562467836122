/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import { CreditManage } from 'Component/Shared/CreditManager';
import { MARKET_SPYING_WIZARD_COST } from 'Component/Shared/featuresCreditsCost';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CustomTooltip from 'Component/Shared/CustomTooltip';
import LoaderSpin, { LoaderTailSpin } from '../../../Shared/Loader';
import './MarketSpyingWizard.css';
import getSearch from './MarketSpyingWizardLogic';
import TabResults from './SearchResults';

function MarketSpyingWizard() {
  const navigate = useNavigate();
  const workspace_name = useSelector((state) => state.workspace.name);
  const [searchQuery, setSearchQuery] = useState('');
  const [error, setError] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [results, setResults] = useState();
  const [activeTab, setActiveTab] = useState();
  const [IsCreditFail, setIsCreditFail] = useState(false);

  const handleChange = (e) => {
    setError(false);
    setSearchQuery(e.target.value);
  };

  // handle search on press Enter Button
  function handleEnterSearch(e) {
    if (e.key === 'Enter') {
      handleSearch();
    }
  }

  const handleSearch = async () => {
    setIsSearching(true);
    setActiveTab(null);
    setResults(null);

    const { success } = await CreditManage(MARKET_SPYING_WIZARD_COST, true);

    if (success) {
      const res = await getSearch(searchQuery);
      if (res.error) {
        setError(true);
        setIsSearching(false);

        return;
      }

      const { success } = await CreditManage(MARKET_SPYING_WIZARD_COST);

      if (success) {
        setResults(res);
        setActiveTab(Object.keys(res)[0]);
        setIsSearching(false);
      }
    } else {
      setIsSearching(false);
      setIsCreditFail(true);
    }
  };

  const TabsPanel = () => Object.keys(results).map((result, index) => (
    <div
      className={
              `font-accent font-normal${
                result === activeTab ? ' spy-display-tab-active ' : ''}`
            }
      key={index}
      onClick={() => setActiveTab(result)}
    >
      {result}
    </div>
  ));

  return (
    <div className="spy-container">
      <div className="spy-nav-container">
        <span className="font-primary txt-primary spy-sub-head-active-name">
          Start here
        </span>
        {!!workspace_name && (
        <a
          href="/AI-Insights"
          target="_blank"
          className="overlay-ai-insights-link"
        >
          <img width={25} src="/img/notes-icon.svg" />
          <CustomTooltip
            title="Organise Your Notes Here!"
            arrow
            placement="top"
          >
            <div className="mx-2"> AI Insights</div>
          </CustomTooltip>
        </a>
        )}
      </div>

      <div className="spy-results-container">
        <div className="spy-results">
          <div className="spy-search-box">
            <span className="font-accent">
              Search Audience Role With A Desired Outcome Or A Topic of
              Interest
            </span>
            <div className="spy-input-box-wrapper">
              <input
                className={
                    `spy-input-search font-accent${
                      error ? ' spy-error-input' : ''}`
                  }
                onChange={(e) => handleChange(e)}
                onKeyPress={(e) => handleEnterSearch(e)}
                value={searchQuery}
                placeholder="Brand Marketer looking for Brand Authority"
              />

              {IsCreditFail ? (
                <span className="credit-over">Credits Over</span>
              ) : (
                <button
                  className="spy-button"
                  onClick={handleSearch}
                  disabled={isSearching}
                >
                  {isSearching ? (
                    <LoaderSpin height="20px" width="20px" />
                  ) : (
                    <div>
                      <span className="font-normal">Search</span>
                    </div>
                  )}
                </button>
              )}
            </div>
          </div>
          {isSearching && (
          <div className="text-center">
            <LoaderTailSpin />
          </div>
          )}
          <div className="spy-panel-wrapper">
            {!!results && (
            <div className="spy-result-panel-left">
              <div className="spy-display-tab-panel">{TabsPanel()}</div>
            </div>
            )}
            <div className="spy-result-panel-right">
              {results ? (
                <TabResults
                  results={results[activeTab]}
                  showUnlock={
                      activeTab === 'Discover Semantics and Psychographics'
                    }
                  keyWord={searchQuery}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MarketSpyingWizard;
