import React from 'react';
import { ScaffoldCard } from 'components/common/scaffold-card';
import { Divider } from 'components/common/divider';
import { LoadingPlaceholder } from '../LoadingPlaceholder';
import { StatusIndicatorContainer, StatusIndicatorWrapper, ViewDetailsContainer, DateContainer } from './styles';

export const WorkspacePersonaDetailLoading = () => (

  <div>
    <ScaffoldCard className="h-margin-b-large">
      <LoadingPlaceholder height="30px" width="30%" marginBottom="12px" marginTop="4px" />
      <DateContainer>
        <LoadingPlaceholder height="25px" width="25px" marginBottom="8px" marginRight="4px" />
        <LoadingPlaceholder height="25px" width="20%" marginBottom="8px" marginRight="4px" />
      </DateContainer>
    </ScaffoldCard>

    <ScaffoldCard className="h-margin-b-small">
      <LoadingPlaceholder height="15px" width="20%" marginBottom="12px" marginTop="4px" />
      <StatusIndicatorWrapper className="h-margin-t-jumbo h-margin-b-xlarge">
        {Array(7).fill(null).map((index) => (
          <StatusIndicatorContainer key={index}>
            <LoadingPlaceholder height="25px" width="25px" marginBottom="6px" />
            <LoadingPlaceholder height="15px" width="60px" />
          </StatusIndicatorContainer>
        ))}
      </StatusIndicatorWrapper>
      <ViewDetailsContainer className="h-margin-t-jumbo ">
        <LoadingPlaceholder height="10px" width="10%" />
      </ViewDetailsContainer>
    </ScaffoldCard>

    <ScaffoldCard>
      <div className="h-margin-b-small">
        <LoadingPlaceholder height="15px" width="20%" marginBottom="12px" marginTop="4px" />
        <Divider />
      </div>
      <div className="h-margin-b-small">
        <LoadingPlaceholder height="10px" width="10%" marginBottom="1%" marginTop="3%" />
        <LoadingPlaceholder height="15px" width="15%" marginBottom="4px" />
      </div>

      <div className="h-margin-b-small">
        <LoadingPlaceholder height="10px" width="10%" marginBottom="1%" marginTop="3%" />
        <LoadingPlaceholder height="15px" width="25%" marginBottom="4px" />
      </div>

      <div className="h-margin-b-small">
        <LoadingPlaceholder height="10px" width="10%" marginBottom="1%" marginTop="3%" />
        <LoadingPlaceholder height="15px" width="15%" marginBottom="4px" />
      </div>

      <div className="h-margin-b-small">
        <LoadingPlaceholder height="10px" width="10%" marginBottom="1%" marginTop="3%" />
        <LoadingPlaceholder height="15px" width="15%" marginBottom="4px" />
      </div>

    </ScaffoldCard>

  </div>
);
