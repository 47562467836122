/* eslint-disable indent */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable no-plusplus */
/* eslint-disable react/button-has-type */
/* eslint-disable no-return-assign */
/* eslint-disable no-use-before-define */

import { Button, Col, Form, Row, Input, Select, Switch, Steps } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  CaretLeftOutlined,
  CaretRightOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import AthenaLogo from 'assets/images/Athena3.png';
import ReactFlagsSelect from 'react-flags-select';

const NavigationSelectors = (props) => {
  const { changeIndex, goRight } = props;
  return (
    <Row>
      <Col span={12}>
        <CaretLeftOutlined
          onClick={() => changeIndex('LEFT')}
          style={{
            textAlign: 'left',
            marginLeft: '10px',
            cursor: 'pointer',
            color: '#5887FB',
            fontSize: '50px',
          }}
        />
      </Col>
      <Col span={12}>
        <CaretRightOutlined
          onClick={() => goRight()}
          style={{
            textAlign: 'right',
            marginRight: '10px',
            cursor: 'pointer',
            color: '#5887FB',
            fontSize: '50px',
            float: 'right',
          }}
        />
      </Col>
    </Row>
  );
};

export default NavigationSelectors;
