/* eslint-disable arrow-parens */
/* eslint-disable no-use-before-define */
/* eslint-disable padded-blocks */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-constructed-context-values */
import ChatAthenaComponent from 'Component/Pages/Content Automation/AIContentWriter/chat_athena_component';
import React, { useEffect, useMemo, useState } from 'react';

import { PieChartOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import LoaderSpin from 'Component/Shared/Loader';
import { ChatAthenaModelNameEnum } from 'libs/chat-athena-constants';
import { GET_UPLOADED_FILES_LISTS_KEY } from 'queries/chat-athena/get-uploaded-files-lists';
import { useQueryClient } from 'react-query';
import {
  GET_SAVED_CONFIGURATIONS_KEY,
  useGetSavedConfigurations,
} from 'queries/chat-athena/get-saved-configurations';
import { useGetUsersAddOns } from 'queries/user-account/services/get-user-addons';
import { isPremiumPlan } from 'libs/enums/premiumPlans.enum';
import { PlanType } from 'libs/enums/planType.enum';
import { SelectedImageUploadContext } from 'Component/Pages/Content Automation/AIContentWriter/ChatAthenaComponents/components/contexts/SelectedImageUpload';
import { getQueryFromURLParams } from 'Component/Shared/utils';
import { queuedWorkspaceSelect, workspaceSelect } from '../../../../redux';
import { getMainTopicOfIntrest } from '../../Workspaces/Workspace/DemoWorkspace/DemoWorkspaceClone/DemoWorkspaceCloneLogic';
import { fetchUserWorkspace } from '../../Workspaces/Workspace/WorkspaceLogic';
import styles from '../chat-athena.module.css';
import ParentLoader from '../components/ParentLoader';
import { getItem, getLatestWorkspace } from '../utils';
import WorkspaceList from '../components/WorkspaceList';
import { SelectedFileContext } from '../../Content Automation/AIContentWriter/ChatAthenaComponents/components/contexts/SelectedFileContext';
import WorkspacesContext from '../components/WorkspsaceContext';
import { SelectedModelContext } from '../../Content Automation/AIContentWriter/ChatAthenaComponents/components/contexts/SelectedModel';
import { saveConfigurations } from '../../Content Automation/AIContentWriter/ChatAthenaComponents/components/services/chat-athena-configurations';
import { SelectedAppContext } from '../../Content Automation/AIContentWriter/ChatAthenaComponents/components/contexts/SelectedAppContext';

const ChatAthenaWeb = (props) => {
  const workspaceName = useSelector((state) => state?.workspace?.name);

  const queryClient = useQueryClient();

  const {
    isDarkMode,
    setDarkMode,
    isMobile,
    setStarterFlow,
    isStarterFlow,
  } = props;
  const {
    data: savedConfigurations,
    isLoading: isLoadingSavedConfigurations,
  } = useGetSavedConfigurations();
  const [loading, setLoading] = useState(false);
  const [loadingWorkspaceChat, setLoadingWorkspaceChat] = useState(false);
  const [loadingWorkspaces, setLoadingWorkspaces] = useState(false);
  const [selectedWorkspace, setSelectedWorkspace] = useState('');

  const [showWorkspacesSider, setWorkspacesSider] = useState(false);

  const [userWorkspacesList, setUserWorkspacesList] = useState();
  const [workspaces, setWorkspaces] = useState([]);
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [selectedToolId, setSelectedToolId] = useState(null);
  const [imageModelName, setImageModelName] = useState(null);
  const [uploadedImages, setUploadedImages] = useState([]);

  const workspace = useSelector((state) => state.workspace);
  const user = useSelector((state) => state?.user);

  const dispatch = useDispatch();

  const { data: addons, isLoading: isLoadingPlans } = useGetUsersAddOns();

  const isProActivated = useMemo(() => {
    if (isPremiumPlan(user?.plan)) return true;
    if (isLoadingPlans) return false;
    if (addons[PlanType.chatAthena]?.status) return true;
    if (addons[PlanType.chatAthenaYearly]?.status) return true;
    return false;
  }, [addons]);

  const [modelName, setModelName] = useState(ChatAthenaModelNameEnum.gpt3);

  useEffect(() => {
    if (isProActivated) {
      setModelName(ChatAthenaModelNameEnum.gpt4);
    }
  }, [isProActivated]);

  // To open and set workspace detail in redux
  const handleOpenWorkspace = async (
    name,
    domain,
    language,
    language_engine,
    is_trained,
    last_trained,
    mti,
  ) => {
    // set workspace details in redux-store
    dispatch(
      workspaceSelect({
        name,
        domain,
        language,
        language_engine,
        is_trained,
        last_trained,
        mti,
      }),
    );
  };

  // To select workspace
  const handleSelectWorkspace = async (data, saveConfig) => {
    setLoadingWorkspaceChat(true);
    const {
      name,
      is_trained,
      last_trained,
      Lang: language,
      site: domain,
      lang_engine: language_engine,
    } = data;

    setSelectedWorkspace(name);
    const mti = await getMainTopicOfIntrest(name);

    await handleOpenWorkspace(
      name,
      domain,
      language,
      language_engine,
      is_trained,
      last_trained,
      mti,
    );
    queryClient.invalidateQueries(GET_UPLOADED_FILES_LISTS_KEY);
    setWorkspacesSider(false);
    setSelectedFileId(null);
    setSelectedToolId(null);
    if (saveConfig) {
      await saveConfigurations({
        selectedSources: ['mrr'],
        selectedCreativeTool: '',
        selectedTemperature: '0.35',
        workspaceData: {
          name,
          domain,
          language,
          language_engine,
          is_trained,
          last_trained,
          mti,
        },
      });
      queryClient.invalidateQueries(GET_SAVED_CONFIGURATIONS_KEY);
    }
    setLoadingWorkspaceChat(false);
  };

  // Fetch workspace and sort according to favourite first.
  const handleFetchWorkspaces = async () => {
    setLoadingWorkspaces(true);
    const { result } = await fetchUserWorkspace();

    result.sort((a, b) => {
      if (a?.is_favourite && !b?.is_favourite) {
        return -1;
      }
      if (!a?.is_favourite && b.is_favourite) {
        return 1;
      }
      return 0;
    });

    setUserWorkspacesList([...result]);

    setWorkspaces(
      result.map((workspace) =>
        getItem(
          workspace.name,
          workspace.name,
          <PieChartOutlined />,
          workspace,
        ),
      ),
    );
    setLoadingWorkspaces(false);
    return result;
  };

  const handleSelectRecentWorkspace = async () => {
    if (workspace?.name) return;
    const worksapaceData = savedConfigurations?.workspaceData;

    if (!worksapaceData?.name) {
      const latestWorkspace = getLatestWorkspace(workspaces);
      await handleSelectWorkspace(latestWorkspace.data, true);
      return;
    }
    if (worksapaceData.name === selectedWorkspace) return;
    const {
      name,
      is_trained,
      last_trained,
      Lang: language,
      site: domain,
      lang_engine: language_engine,
    } = worksapaceData;
    const recentWorksapce = {
      name,
      site: domain,
      Lang: language,
      lang_engine: language_engine,
      is_trained,
      last_trained,
    };
    handleSelectWorkspace(recentWorksapce);
    if (workspace.queuedWorkspace !== '') {
      await handleFetchWorkspaces();
      await getNewCreatedWorkspaceFromMobile();
      dispatch(queuedWorkspaceSelect(''));
    }
  };

  const getNewCreatedWorkspaceFromMobile = async () => {
    const _workspace = workspaces.filter(
      (wk) => wk.data.name === workspace.queuedWorkspace,
    )[0];
    await handleSelectWorkspace(_workspace.data, true);
  };

  const handleSelectNewWorkspace = async () => {
    const latestWorkspace = getLatestWorkspace(workspaces);
    if (latestWorkspace.data.name === selectedWorkspace) return;
    await handleSelectWorkspace(latestWorkspace.data, true);
  };

  useEffect(() => {
    handleFetchWorkspaces();
  }, []);

  useEffect(() => {
    if (isLoadingSavedConfigurations || workspaces.length === 0) {
      return;
    }

    const selectNewWorkspace = getQueryFromURLParams('new_workspace');
    if (selectNewWorkspace === 'true') {
      handleSelectNewWorkspace();
      return;
    }

    if (isStarterFlow !== 'FLOW') {
      handleSelectRecentWorkspace();
      return;
    }
    handleSelectNewWorkspace();
  }, [savedConfigurations, workspaces, workspace?.queuedWorkspace]);

  return (
    <SelectedFileContext.Provider
      value={{
        selectedToolId,
        setSelectedToolId,
        selectedFileId,
        setSelectedFileId,
      }}
    >
      <SelectedAppContext.Provider
        value={{
          selectedToolId,
          setSelectedToolId,
          selectedFileId,
          setSelectedFileId,
        }}
      >
        <SelectedModelContext.Provider
          value={{ modelName, setModelName, imageModelName, setImageModelName }}
        >
          <SelectedImageUploadContext.Provider
            value={{
              uploadedImages,
              setUploadedImages,
            }}
          >
            <WorkspacesContext.Provider
              value={{
                userWorkspacesList,
                handleSelectWorkspace,
                isDarkMode,
                selectedWorkspace,
                setWorkspaces,
                workspaceName,
                workspaces,
              }}
            >
              <div className={styles.chat_athena_wrapper}>
                {loadingWorkspaces ? (
                  <ParentLoader isDarkMode={isDarkMode} />
                ) : (
                  <div
                    style={{
                      backgroundColor: isDarkMode ? '#343541' : 'white',
                    }}
                    className={styles.chat_athena_parent}
                  >
                    <div className={styles.chat_athena_container}>
                      <WorkspaceList setStarterFlow={setStarterFlow} />
                      <div className={styles.chat_athena_component}>
                        {loadingWorkspaceChat ? (
                          <div className={styles.loader_full_chat_box}>
                            <LoaderSpin />
                            <p
                              style={{
                                color: !isDarkMode ? '#343541' : 'white',
                              }}
                            >
                              Loading Chat....
                            </p>
                          </div>
                        ) : (
                          <ChatAthenaComponent
                            isDarkMode={isDarkMode}
                            setDarkMode={setDarkMode}
                            loading={loading}
                            setLoading={setLoading}
                            setWorkspacesSider={setWorkspacesSider}
                            showWorkspacesSider={showWorkspacesSider}
                            isMobile={isMobile}
                            selectedFileId={selectedFileId}
                            selectedToolId={selectedToolId}
                            setStarterFlow={setStarterFlow}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </WorkspacesContext.Provider>
          </SelectedImageUploadContext.Provider>
        </SelectedModelContext.Provider>
      </SelectedAppContext.Provider>
    </SelectedFileContext.Provider>
  );
};
export default ChatAthenaWeb;
