/* eslint-disable no-plusplus */
export const getMembersWithRole = (members) => {
  if (members) {
    let formattedMembers = [];
    for (let i = 0; i < members.length; i++) {
      const feilds = {
        name: '',
        scope: 'Team Member',
        email: '',
      };
      for (let j = 0; j < members[i].Attributes.length; j++) {
        if (members[i].Attributes[j].Name === 'email') {
          feilds.email = members[i].Attributes[j].Value;
        }
        if (members[i].Attributes[j].Name === 'custom:scope') {
          if (members[i].Attributes[j].Value === 'agency_client') {
            feilds.scope = 'Client';
          }
        }
        if (members[i].Attributes[j].Name === 'name') {
          feilds.name = members[i].Attributes[j].Value;
        }
        if (members[i].Attributes[j].Name === 'custom:is_admin') {
          if (members[i].Attributes[j].Value === 'True') {
            feilds.scope = 'Admin';
          }
        }
      }
      formattedMembers = [...formattedMembers, feilds];
    }
    formattedMembers.sort((a, b) => {
      if (a.scope < b.scope) return -1;
      if (a.scope > b.scope) return 1;
      return 0;
    });
    return formattedMembers;
  }

  return [];
};
