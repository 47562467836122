import { SourceFilter } from './enums/sourceFilter.enum';

const DefaultSourceStatus = {
  [SourceFilter.mrr]: true,
  [SourceFilter.serp]: true,
  [SourceFilter.quora]: false,
  [SourceFilter.reddit]: false,
  [SourceFilter.website]: false,
};

export const initialStateWorkspaceConfig = {
  name: '',
  site: '',
  fullName: '',
  lang: '',
  lang_engine: '',
  tags: [],
  users: [],
  product_name: '',
  founder_name: '',
  configurations: {
    level1: {
      sources: { ...DefaultSourceStatus },
      urls: [],
    },
    level2: {
      sources: { ...DefaultSourceStatus },
      urls: [],
    },
    level3: {
      sources: { ...DefaultSourceStatus },
      urls: [],
    },
  },
  onboardingData: {},
};
