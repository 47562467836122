/* eslint-disable indent */
/* eslint-disable no-confusing-arrow */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import React, { Fragment, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { useDispatch } from 'react-redux';
import './UserWorkspace.css';

// assets
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyRoundedIcon from '@mui/icons-material/FileCopyRounded';
import IosShareIcon from '@mui/icons-material/IosShare';
import FavoriteBorderRoundedIcon from '@mui/icons-material/FavoriteBorderRounded';
import FavoriteRoundedIcon from '@mui/icons-material/FavoriteRounded';
// components
import UserWorkspaceClone from './UserWorkspaceClone/UserWorkspaceClone';
import UserWorkspaceDelete from './UserWorkspaceDelete/UserWorkspaceDelete';

// logic function
import {
  getMainTopicOfIntrest,
  IsMinMarketResearchDone,
} from '../DemoWorkspace/DemoWorkspaceClone/DemoWorkspaceCloneLogic';
import setGoogleLanguageCookie from '../../../../Shared/Cookie_custom';
import { workspaceSelect } from '../../../../../redux';
import HelperVideoModalPopup from 'Component/Shared/HelperVideoPopUp/HelperVideoPop';
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment';
import LoaderSpin from 'Component/Shared/Loader';
import UserWorkspaceCopy from './UserWorkspaceCopy/UserWorkspaceCopy';
import { Tooltip } from 'antd';
import { WORKSPACE } from 'libs/constants';
import toggleWorkspaceAsFavourite from './UserWorkspaceLogic';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ErrorToastProps } from '../CreateNewWorkspace/parseConfigurations';

const ModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

/* Props :
        workspaces - list of projects in user account
 */
function UserWorkspace({
  workspaces,
  refetch_user_workspace,
  workspace_limit,
  searchInput,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [SelectedWorkspaceDetails, setSelectedWorkspaceDetails] = useState({
    workspace_name: '',
    workspace_domain: '',
    workspace_language: '',
  });

  const [IsDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [IsCloneModalOpen, setIsCloneModalOpen] = useState(false);
  const [IsCopyModalOpen, setIsCopyModalOpen] = useState(false);
  const [workSpaceNameToCopy, setWorkspaceNameToCopy] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);

  const [UserWorkspaceList, setUserWorkspaceList] = useState(workspaces);
  const [currentSortedKey, setCurrentSortedKey] = useState({
    key: null,
    asc: true,
  });
  const [isLoadingIndex, setIsLoadingIndex] = useState(-1);

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem('signedUserDetails'));
    setIsAdmin(userDetails?.admin === 'True');
    setUserWorkspaceList([...workspaces]);
    handleSorting('name');
  }, [workspaces]);

  const handleSorting = (key, asc = true) => {
    setCurrentSortedKey({ asc, key });
    const order = asc ? 1 : -1;
    const userWorkspaceList = [...UserWorkspaceList];

    const favoriteWorkspaces = userWorkspaceList.filter(
      (workspace) => workspace?.is_favourite,
    );
    const nonFavoriteWorkspaces = userWorkspaceList.filter(
      (workspace) => !workspace?.is_favourite,
    );

    // Sort the favorite workspaces among themselves
    const sortedFavoriteWorkspaces = favoriteWorkspaces.sort((a, b) => {
      if (key === 'date_created') {
        return new Date(a[key]) > new Date(b[key]) ? 1 * order : -1 * order;
      }
      return a[key].toLowerCase() > b[key].toLowerCase()
        ? 1 * order
        : -1 * order;
    });

    // Sort the non-favorite workspaces among themselves
    const sortedNonFavoriteWorkspaces = nonFavoriteWorkspaces.sort((a, b) => {
      if (key === 'date_created') {
        return new Date(a[key]) > new Date(b[key]) ? 1 * order : -1 * order;
      }
      return a[key].toLowerCase() > b[key].toLowerCase()
        ? 1 * order
        : -1 * order;
    });

    // Combine the sorted favorite and non-favorite workspaces
    const sortedUserWorkspaceList = [
      ...sortedFavoriteWorkspaces,
      ...sortedNonFavoriteWorkspaces,
    ];
    setUserWorkspaceList([...sortedUserWorkspaceList]);
  };

  const showSortingIcon = () =>
    currentSortedKey.asc ? (
      <ArrowDropUpRoundedIcon style={{ color: '#2a9df5', fontSize: '2rem' }} />
    ) : (
      <ArrowDropDownRoundedIcon
        style={{ color: '#2a9df5', fontSize: '2rem' }}
      />
    );

  function WorkspaceTableHeader() {
    return (
      <div className="workspace-table-column-header">
        <span
          className="column-header-name"
          onClick={() => handleSorting('name', !currentSortedKey.asc)}
        >
          NAME
          {currentSortedKey.key === 'name' && <>{showSortingIcon()}</>}
        </span>
        <span
          className="column-header-domain"
          onClick={() => handleSorting('site', !currentSortedKey.asc)}
        >
          DOMAIN
          {currentSortedKey.key === 'site' && <>{showSortingIcon()}</>}
        </span>
        <span
          className="column-header-language"
          onClick={() => handleSorting('Lang', !currentSortedKey.asc)}
        >
          LANGUAGE
          {currentSortedKey.key === 'Lang' && <>{showSortingIcon()}</>}
        </span>
        <span
          className="column-header-creation-date"
          onClick={() => handleSorting('date_created', !currentSortedKey.asc)}
        >
          CREATION
          {currentSortedKey.key === 'date_created' && <>{showSortingIcon()}</>}
          {/* <ImportExportIcon style={{ width: '20px', height: '20px' }} /> */}
        </span>
        <span className="column-header-edit" />
        <span className="column-header-delete" />
      </div>
    );
  }

  const handleSetWorkspaceAsFavorite = async (
    workspaceName,
    is_favourite,
    id,
  ) => {
    // Save the previous state
    const previousUserWorkspaceList = [...UserWorkspaceList];

    // Update the state immediately
    const updatedUserWorkspaceList = UserWorkspaceList.map(
      (workspace, index) => {
        if (index === id) {
          return { ...workspace, is_favourite: !is_favourite };
        }
        return workspace;
      },
    );

    setUserWorkspaceList([...updatedUserWorkspaceList]);

    try {
      const { error } = await toggleWorkspaceAsFavourite(
        workspaceName,
        is_favourite,
      );

      if (error) {
        // Revert back to the previous state if there is an error
        setUserWorkspaceList([...previousUserWorkspaceList]);
        const favoriteStatus = is_favourite ? 'as favorite' : 'as not favorite';
        toast.error(
          `Error in setting ${WORKSPACE}: ${workspaceName} ${favoriteStatus}`,
          ErrorToastProps,
        );
      }
    } catch (error) {
      // Revert back to the previous state if there is an error
      setUserWorkspaceList([...previousUserWorkspaceList]);
      const favoriteStatus = is_favourite ? 'as favorite' : 'as not favorite';
      toast.error(
        `Error in setting ${WORKSPACE}: ${workspaceName} ${favoriteStatus}`,
        ErrorToastProps,
      );
    }
  };

  function UserWorkspaceRows({
    name,
    domain,
    language,
    creation_date,
    id,
    language_engine,
    is_trained,
    is_favourite,
    last_trained,
  }) {
    let sources;
    if (UserWorkspaceList[id].train_sources) {
      sources = {
        train_sources: UserWorkspaceList[id].train_sources,
      };
    }

    return (
      <div className="user-workspace-table-row-data">
        <ReactTooltip />
        <ToastContainer />

        <div className="user-row-data-name">
          <span
            className="user-row-data-name"
            onClick={() =>
              handleOpenWorkspace(
                name,
                domain,
                language,
                language_engine,
                !!is_trained,
                last_trained,
                sources,
                id,
              )
            }
          >
            {name}
            {!!is_trained && (
              <span
                className="mx-2"
                style={{
                  marginBottom: '15px',
                  fontSize: '1rem',
                  textShadow: '1px 1px red',
                }}
              >
                💪
              </span>
            )}
          </span>
          {isLoadingIndex === id && <LoaderSpin width={30} />}
        </div>
        <span className="user-row-data-domain"> {domain} </span>
        <span className="user-row-data-language">{language}</span>

        <span className="user-row-data-creation-date">
          {moment(creation_date?.slice(0, 10)).format('MMM Do YYYY')}
        </span>

        <span
          onClick={() => handleSetWorkspaceAsFavorite(name, is_favourite, id)}
        >
          {is_favourite ? (
            <div
              className="mx-2"
              style={{
                color: 'red',
                marginRight: '5px',
              }}
            >
              <FavoriteRoundedIcon />
            </div>
          ) : (
            <div
              className="mx-2"
              style={{
                marginRight: '5px',
              }}
            >
              <FavoriteBorderRoundedIcon />
            </div>
          )}
        </span>

        {!workspace_limit ? (
          <span className="user-row-data-edit">
            <Tooltip
              placement="top"
              title={`Clone ${WORKSPACE}`}
              className="my-tooltip"
            >
              <FileCopyRoundedIcon
                style={{ width: '18px', height: '18px', color: '#2a9df5' }}
                onClick={() => handleCloneWorkspace(name, domain, language)}
              />
            </Tooltip>
            {id === 0 && (
              <div>
                <HelperVideoModalPopup
                  tooltipTitle="How to build multiple audience scenarios? Unleash the real power here!"
                  videoLink="https://athena-helper-videos.s3.us-east-2.amazonaws.com/Video+2+Clone+Audience+Persona-.mp4"
                />
              </div>
            )}
          </span>
        ) : (
          <span
            className="user-row-data-edit"
            data-tip={`${WORKSPACE} Limit Reached`}
          >
            <Tooltip
              placement="top"
              title={'Clone Workspace'}
              className="my-tooltip"
            >
              <FileCopyRoundedIcon
                style={{
                  width: '18px',
                  height: '18px',
                  color: ' rgb(139 135 135)',
                }}
              />
            </Tooltip>
            <div>
              <HelperVideoModalPopup
                tooltipTitle="How to train Athena? Create deep research about your target audience and their needs!"
                videoLink="https://athena-helper-videos.s3.us-east-2.amazonaws.com/Video+6+-+Athena+Training.mp4"
              />
            </div>
          </span>
        )}

        {isAdmin && (
          <span className="mx-2">
            <Tooltip
              placement="top"
              title={'Share among team members'}
              className="my-tooltip"
            >
              <IosShareIcon
                style={{ width: '18px', height: '18px', color: '#2a9df5' }}
                onClick={() => handleCopyWorkspace(name, domain, language)}
              />
            </Tooltip>
          </span>
        )}

        <span className="mx-2">
          <Tooltip
            placement="top"
            title={'Delete Workspace'}
            className="my-tooltip"
          >
            <DeleteIcon
              style={{ width: '18px', height: '18px', color: '#ff3838' }}
              onClick={() => handleDeleteWorkspace(name, domain)}
            />
          </Tooltip>
        </span>
      </div>
    );
  }

  const handleOpenWorkspace = async (
    name,
    domain,
    language,
    language_engine,
    is_trained,
    last_trained,
    sources,
    index,
  ) => {
    setIsLoadingIndex(index);
    const { destination, mrr_filled } = await IsMinMarketResearchDone(name);
    const mti = await getMainTopicOfIntrest(name);

    // commented language functionality, will remove the whole component once feature/Tabs is in production.
    // setGoogleLanguageCookie(`/en/${language}/`);
    localStorage.setItem('name', name);
    localStorage.setItem('url', domain);
    localStorage.removeItem('processPercentage');
    localStorage.setItem('project_name', name);
    localStorage.setItem('last_trained', last_trained);

    // set workspace details in redux-store
    dispatch(
      workspaceSelect({
        name,
        domain,
        language,
        language_engine,
        is_trained,
        last_trained,
        mti,
      }),
    );

    if (mrr_filled) {
      window.location.assign(`${destination}`);
    } else {
      window.location.assign(`${destination}`);
    }
    setIsLoadingIndex(index);
  };

  const handleCloseCloneModal = () => {
    setIsCloneModalOpen((IsCloneModalOpen) => !IsCloneModalOpen);
  };

  const handleCloneWorkspace = (name, domain, language) => {
    setIsCloneModalOpen(true);
    setSelectedWorkspaceDetails({
      ...SelectedWorkspaceDetails,
      workspace_name: name,
      workspace_domain: domain,
      workspace_language: language,
    });
  };

  const handleCloseCopyModal = () => {
    setIsCopyModalOpen((IsCopyModalOpen) => !IsCopyModalOpen);
  };

  const handleCopyWorkspace = (name) => {
    setIsCopyModalOpen(true);
    setWorkspaceNameToCopy(name);
  };

  const handleDeleteWorkspace = (name, domain) => {
    setIsDeleteModalOpen(true);

    setSelectedWorkspaceDetails({
      ...SelectedWorkspaceDetails,
      workspace_name: name,
      workspace_domain: domain,
    });
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen((IsDeleteModalOpen) => !IsDeleteModalOpen);
  };

  function DeleteWorkspaceModal() {
    return (
      <Modal
        isOpen={IsDeleteModalOpen}
        onRequestClose={handleCloseDeleteModal}
        style={ModalStyles}
        ariaHideApp={false}
      >
        <UserWorkspaceDelete
          workspace_detail={SelectedWorkspaceDetails}
          close_delete_modal={handleCloseDeleteModal}
          user_workspace_refetch={refetch_user_workspace}
        />
      </Modal>
    );
  }

  function CloneWorkspaceModal() {
    return (
      <Modal
        isOpen={IsCloneModalOpen}
        onRequestClose={handleCloseCloneModal}
        style={ModalStyles}
        ariaHideApp={false}
      >
        <UserWorkspaceClone
          workspace_detail={SelectedWorkspaceDetails}
          close_clone_modal={handleCloseCloneModal}
          user_workspace_refetch={refetch_user_workspace}
        />
      </Modal>
    );
  }

  function CopyWorkspaceModal() {
    return (
      <Modal
        isOpen={IsCopyModalOpen}
        onRequestClose={handleCloseCopyModal}
        style={ModalStyles}
        ariaHideApp={false}
      >
        <UserWorkspaceCopy
          workspace_name={workSpaceNameToCopy}
          close_copy_modal={handleCloseCopyModal}
        />
      </Modal>
    );
  }

  return (
    <div>
      <WorkspaceTableHeader />
      <CloneWorkspaceModal />
      <CopyWorkspaceModal />
      <DeleteWorkspaceModal />

      <div className="user-workspace-table-data-container">
        {workspaces &&
          UserWorkspaceList.map((workspace, id) => (
            <Fragment key={id}>
              {workspace.name
                .toLowerCase()
                .includes(searchInput.toLowerCase()) && (
                <>
                  <UserWorkspaceRows
                    id={id}
                    name={workspace.name}
                    domain={workspace.site}
                    language={workspace.Lang}
                    creation_date={workspace.date_created}
                    language_engine={workspace.lang_engine}
                    is_trained={workspace.is_trained}
                    is_favourite={workspace?.is_favourite}
                    last_trained={workspace.last_trained}
                    key={`${workspace.name}_${id}`}
                  />

                  <div className="row-data-divider" />
                </>
              )}
            </Fragment>
          ))}
      </div>
    </div>
  );
}

export default UserWorkspace;
