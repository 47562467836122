import CustomTooltip from 'Component/Shared/CustomTooltip';
import {
  LoaderTailSpin,
  PrimaryButton,
  SecondaryButton,
  ImportIcon,
} from 'components/common';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useMutateImportAssistant } from 'queries/assistants/mutate-import-assistant';
import styles from './model-styles.module.css';

const customStyles = {
  content: {
    width: '30rem',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const ImportAssistantLink = () => {
  const {
    mutate: importAssistant,
    isSuccess: isImportSuccess,
    isLoading: isImportingAssistant,
  } = useMutateImportAssistant();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [assistantLink, setAssistantLink] = useState('');

  const handleChange = (e) => {
    setAssistantLink(e.target.value);
  };

  const handleImport = () => {
    importAssistant(assistantLink);
  };

  const handleModalOpen = () => {
    setModalIsOpen(true);
  };

  const handleModalClose = () => {
    setModalIsOpen(false);
    setAssistantLink('');
  };

  useEffect(() => {
    if (isImportSuccess) {
      handleModalClose();
    }
  }, [isImportSuccess]);

  return (
    <div>
      <CustomTooltip placement="top" title="Share Assistant" arrow>
        <PrimaryButton className={styles.import_icon} onClick={handleModalOpen}>
          <ImportIcon /> <span> Import Assistant</span>
        </PrimaryButton>
      </CustomTooltip>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleModalClose}
        style={customStyles}
      >
        <div className={styles.modal_container}>
          <h5>Import Assistant</h5>
          <input
            onChange={handleChange}
            className={styles.input}
            value={assistantLink}
            placeholder="Enter assistant link to import"
          />
          <div className={styles.buttons_group}>
            <SecondaryButton
              onClick={handleModalClose}
              className={styles.button}
            >
              Close
            </SecondaryButton>
            <PrimaryButton
              disabled={isImportingAssistant}
              className={styles.button}
              onClick={handleImport}
            >
              <span>Import</span>
              {isImportingAssistant && (
                <LoaderTailSpin height={20} width={20} />
              )}
            </PrimaryButton>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ImportAssistantLink;
