/* eslint-disable indent */
/* eslint-disable no-undef */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import React, { Fragment, useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import PaymentIcon from '@mui/icons-material/Payment';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useSelector } from 'react-redux';
import DashboardOutline from '../../../assets/images/dashboard-outline.svg';
import DashboardIconActive from '../../../assets/images/dashboard-fill.svg';
import NewWorkspaceIcon from '../../../assets/images/new-workspace.svg';
import SearchOutline from '../../../assets/images/search-svg.svg';
import SearchOutlineActive from '../../../assets/images/crawlq-search-icon-active.svg';
import NotesIcon from '../../../assets/images/notes-icon.svg';
import NotesIconActive from '../../../assets/images/notes-icon-active.svg';
import marketResearchIcon from '../../../assets/images/market-research.svg';
import marketResearchIconActive from '../../../assets/images/market-research-icon-active.svg';
import personaIcon from '../../../assets/images/persona-engine.svg';
import WorkspaceIcon from '../../../assets/images/project-outline.svg';
import WorkspaceIconActive from '../../../assets/images/project-outline.svg';
import contentAutomationIcon from '../../../assets/images/content-automation.svg';
import contentAutomationIconActive from '../../../assets/images/content-automation-icon-active.svg';
import digitalAssetsIcon from '../../../assets/images/digital-assets.svg';
import digitalAssetsIconActive from '../../../assets/images/digital-assets-active.svg';
import SubscriptionsIconActive from '../../../assets/images/credit-card.svg';
import VideoTutsIcon from '../../../assets/images/video-side.svg';
import VideoTutsIconActive from '../../../assets/images/learn-video-icon-active.svg';
import ResourcesIcon from '../../../assets/images/resources.svg';
import ResourcesIconActive from '../../../assets/images/resources-icon-active.svg';
import templateIcon from '../../../assets/img/templateIcon.svg';
import templateIconActive from '../../../assets/img/templatesIconActive.svg';
import PlansPrice from '../../../assets/images/price-tag.svg';
import AthenaLogo from 'assets/images/Athena3.png';
import TeamMember from '../../../assets/images/add-team-outline.svg';
import FaqOutline from '../../../assets/images/faq-outline.svg';
import KnowledgeOutline from '../../../assets/images/knowledge-outline.svg';

import SlackOutline from '../../../assets/images/slack-outline.svg';
import WorkflowOutline from '../../../assets/images/workflow-outline.svg';

import smallLogo from '../../../assets/images/crawlQ-transparent-favicon.png';

import notionIcon from '../../../assets/img/icons8-notion.svg';
import './TopNav.css';
import setGoogleLanguageCookie from '../Cookie_custom';
import { BulbOutlined, WechatOutlined } from '@ant-design/icons';
import Flyout from '../Flyout/Flyout';
import TextImageComponent from 'Component/Pages/Content Automation/AIContentWriter/text_image_component';
import ChatAthenaComponent from 'Component/Pages/Content Automation/AIContentWriter/chat_athena_component';
import ChatAthena from 'Component/Pages/ChatAthena';
import { WORKSPACE, WORKSPACES } from 'libs/constants';

const activeStyle = { borderRight: '2px solid rgb(81 160 238)' };

function Topnav(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isProductExpanded, setIsProductExpanded] = useState(false);
  const [textImageWindow, setTextImageWindow] = useState(false);
  const [chatAthenaWindow, setChatAthenaWindow] = useState(false);

  const GROUP_NAME = useSelector((state) => state.user.group);

  const { mti } = useSelector((state) => state.workspace);

  const PUBLIC_URL = '';
  const { pathname } = useLocation();

  const domainName = window.location.hostname;

  const handlepassword = () => {
    navigate('/ChangePassword');
  };

  const handleLogout = async () => {
    // await Auth.signOut();
  };

  const handleClick = () => {
    localStorage.setItem('page', '/Market-Spying-Wizard');
  };

  const handleWorkspaceDirection = () => {
    setGoogleLanguageCookie('/en/en/');
    window.location.replace('./Workspace');
  };

  return (
    <>
      <Flyout
        open={textImageWindow}
        onClose={() => setTextImageWindow(false)}
        title="Athena Creative Builder"
      >
        <TextImageComponent />
      </Flyout>

      <Helmet>
        <title>CrawlQ |{pathname.slice(1)}</title>
      </Helmet>

      <nav
        className="dark-mode-css sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white"
        id="sidenav-main"
        style={
          !isExpanded
            ? {
                maxWidth: '80px',
                overflow: 'unset',
              }
            : { boxShadow: '0 0 50px grey' }
        }
        onMouseEnter={() => setIsExpanded(true)}
        onMouseLeave={() => {
          setIsExpanded(false);
          setIsProductExpanded(false);
        }}
      >
        <div className="scrollbar-inner">
          <div className="sidenav-header d-flex align-items-center justify-content-center">
            {isExpanded ? (
              <img width src="/img/crawlQ.png" className="navbar-brand-img" />
            ) : (
              <img width="40" src={smallLogo} />
            )}
          </div>

          <div className="navbar-inner-bottom">
            <div
              className="navbar-inner-bottom-container"
              id="sidenav-collapse-main"
            >
              <div className="navbar-ul-container">
                <ul className="navbar-nav-container">
                  <li className="nav-item">
                    <NavLink
                      className="nav-link-container"
                      replace
                      activeStyle={activeStyle}
                      exact
                      to="/chat-athena-flow"
                    >
                      <div className="nav-link-item-container">
                        <img
                          className="nav-link-item-image"
                          src={NewWorkspaceIcon}
                        />
                        {isExpanded && (
                          <span className="nav-link-item-text">
                            New {WORKSPACE}&nbsp;
                            <AddRoundedIcon style={{ color: '#2ca00f' }} />
                          </span>
                        )}
                      </div>
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink
                      className="nav-link-container"
                      replace
                      activeStyle={activeStyle}
                      exact
                      to="/Workspace"
                      onClick={() => handleWorkspaceDirection()}
                    >
                      <div className="nav-link-item-container">
                        <img
                          className="nav-link-item-image"
                          src={
                            location.pathname == '/Workspace'
                              ? WorkspaceIconActive
                              : WorkspaceIcon
                          }
                        />
                        {isExpanded && (
                          <span className="nav-link-item-text">
                            All {WORKSPACES}&nbsp;
                          </span>
                        )}
                      </div>
                    </NavLink>
                  </li>

                  {props.flag ? (
                    ''
                  ) : (
                    <li className="nav-item">
                      <NavLink
                        className="nav-link-container"
                        activeStyle={activeStyle}
                        replace
                        isActive={() =>
                          [
                            '/dashboard',
                            '/Market-Spying-Wizard',
                            '/Search-Intent',
                            // '/Market-resonace',
                            '/Competitoranalysiswizard',
                            '/OrganicCompetitionWizard',
                            '/CompetitorComparisionWizard',
                            // '/SalesCopyWritingWizard',
                            '/TextSummarizer',
                            '/AIContentRewriter',
                            '/AIConversionOptimizer',
                            '/AlContentWriter',
                            '/OverView',
                            '/TechnicalSEO',
                            '/SemanticSEO',
                            '/BackLinks',
                            // '/Market-resonace/SubCategory',
                            '/Topic_Modelling_OverView',
                            '/TopicClusters',
                            '/DominantTopicDiscovery',
                            '/TopicKeywords',
                            '/InternalUrlTopicDistribution',
                            '/TechInsights',
                            '/SchemaGenerator',
                            '/PerformanceSEO',
                            'https://crawlq.tawk.help/category/product',
                          ].includes(pathname)
                        }
                        exact
                        to="/dashboard"
                        onClick={handleClick}
                      >
                        <div className="nav-link-item-container">
                          <img
                            className="nav-link-item-image"
                            src={
                              location.pathname == '/dashboard'
                                ? DashboardIconActive
                                : DashboardOutline
                            }
                          />

                          {isExpanded && (
                            <span className="nav-link-item-text">
                              Dashboard
                            </span>
                          )}
                        </div>
                      </NavLink>
                    </li>
                  )}

                  <li className="nav-item">
                    <NavLink
                      className="nav-link-container"
                      replace
                      activeStyle={activeStyle}
                      exact
                      to="/build-workspace-status"
                    >
                      <div className="nav-link-item-container">
                        <img
                          className="nav-link-item-image"
                          src={
                            location.pathname == '/build-workspace-status'
                              ? personaIcon
                              : personaIcon
                          }
                        />

                        {isExpanded && (
                          <span className="nav-link-item-text">
                            Persona Engine
                          </span>
                        )}
                      </div>
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink
                      className="nav-link-container"
                      replace
                      activeStyle={activeStyle}
                      exact
                      to="/MRR-Get-Started"
                    >
                      <div className="nav-link-item-container">
                        <img
                          className="nav-link-item-image"
                          src={
                            location.pathname == '/MRR-Get-Started'
                              ? marketResearchIconActive
                              : marketResearchIcon
                          }
                        />

                        {isExpanded && (
                          <span className="nav-link-item-text">
                            Market Research
                          </span>
                        )}
                      </div>
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink
                      className="nav-link-container"
                      replace
                      activeStyle={activeStyle}
                      exact
                      // to="/create-drafts"
                      to="/chat-athena"
                    >
                      <div className="nav-link-item-container">
                        <img
                          className="nav-link-item-image"
                          src={
                            location.pathname == '/create-drafts'
                              ? contentAutomationIconActive
                              : contentAutomationIcon
                          }
                        />

                        {isExpanded && (
                          <span className="nav-link-item-text">
                            Market Message
                          </span>
                        )}
                      </div>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link-container"
                      replace
                      activeStyle={activeStyle}
                      exact
                      to="/digital-asset-management-blogposts"
                    >
                      <div className="nav-link-item-container">
                        <img
                          className="nav-link-item-image"
                          src={
                            location.pathname ==
                            '/digital-asset-management-blogposts'
                              ? digitalAssetsIconActive
                              : digitalAssetsIcon
                          }
                        />

                        {isExpanded && (
                          <span className="nav-link-item-text">
                            Digital Assets
                          </span>
                        )}
                      </div>
                    </NavLink>
                  </li>
                  {!!mti && (
                    <li className="nav-item">
                      <NavLink
                        className="nav-link-container"
                        replace
                        activeStyle={activeStyle}
                      >
                        <div
                          className="nav-link-item-container"
                          onClick={() => window.open('/chat-athena', '_blank')}
                        >
                          <WechatOutlined
                            style={{ fontSize: '25px', marginRight: '6px' }}
                          />

                          {isExpanded && (
                            <span className="nav-link-item-text">
                              ChatAthena
                            </span>
                          )}
                        </div>
                      </NavLink>
                    </li>
                  )}
                  {/* {!!mti && (
                    <li className="nav-item">
                      <NavLink className="nav-link-container" replace activeStyle={activeStyle}>
                        <div
                          className="nav-link-item-container"
                          onClick={() => setTextImageWindow(true)}
                        >
                          <BulbOutlined style={{ fontSize: '25px', marginRight: '6px' }} />

                          {isExpanded && (
                            <span className="nav-link-item-text">Athena Creatives</span>
                          )}
                        </div>
                      </NavLink>
                    </li>
                  )} */}

                  <li className="nav-item">
                    <a
                      className="nav-link-container"
                      activeStyle={activeStyle}
                      exact
                      href="/Manage-Templates"
                    >
                      <div className="nav-link-item-container">
                        <img
                          className="nav-link-item-image"
                          src={
                            location.pathname === '/Manage-Templates'
                              ? templateIconActive
                              : templateIcon
                          }
                        />

                        {isExpanded && (
                          <span className="nav-link-item-text">
                            Manage Templates
                            <span
                              className="ml-2"
                              style={{ fontSize: '10px', marginLeft: '12px' }}
                            />
                          </span>
                        )}
                      </div>
                    </a>
                  </li>
                </ul>

                <ul className="navbar-nav-container">
                  <p className="help">Help</p>
                  {GROUP_NAME === 'cus' ? (
                    <li
                      className="nav-item cursor-pointer"
                      onClick={() => navigate('/plans-and-pricing')}
                    >
                      <div className="nav-link-container">
                        <a style={{ color: 'inherit' }}>
                          <div className="nav-link-item-container">
                            <img
                              className="nav-link-item-image"
                              src={PlansPrice}
                            />

                            {isExpanded && (
                              <span className="nav-link-item-text">
                                Plans and Pricing
                              </span>
                            )}
                          </div>
                        </a>
                      </div>
                    </li>
                  ) : null}
                  <li
                    className="nav-item cursor-pointer"
                    onClick={() => setIsProductExpanded((prev) => !prev)}
                  >
                    <div className="nav-link-container">
                      <a
                        style={{ color: 'inherit' }}
                        // href="https://crawlq.tawk.help/category/product"
                        // target="_blank"
                      >
                        <div className="nav-link-item-container">
                          <img
                            className="nav-link-item-image"
                            src={ResourcesIcon}
                          />

                          {isExpanded && (
                            <span className="nav-link-item-text">
                              CrawlQ Resources
                            </span>
                          )}
                          {isExpanded &&
                            (isProductExpanded ? (
                              <ExpandLessRoundedIcon />
                            ) : (
                              <ExpandMoreRoundedIcon />
                            ))}
                        </div>
                      </a>
                    </div>
                  </li>
                  {isProductExpanded && (
                    <>
                      <li
                        className="nav-item nav-dropd-down"
                        onClick={() => setIsProductExpanded((prev) => !prev)}
                      >
                        <div className="nav-link-container">
                          <a
                            style={{ color: 'inherit' }}
                            href="https://crawlq.tawk.help/category/product"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <div className="nav-link-item-container">
                              <img
                                className="nav-link-item-image"
                                src={KnowledgeOutline}
                              />

                              {isExpanded && (
                                <span className="nav-link-item-text">
                                  Knowledge Base
                                </span>
                              )}
                            </div>
                          </a>
                        </div>
                      </li>
                      <li className="nav-item nav-dropd-down">
                        <NavLink
                          className="nav-link-container"
                          replace
                          activeStyle={activeStyle}
                          exact
                          to="/workflow"
                        >
                          <div className="nav-link-item-container">
                            <img
                              className="nav-link-item-image"
                              src={WorkflowOutline}
                            />
                            {isExpanded && (
                              <span className="nav-link-item-text">
                                Workflow
                              </span>
                            )}
                          </div>
                        </NavLink>
                      </li>

                      <li className="nav-item nav-dropd-down">
                        <NavLink
                          className="nav-link-container"
                          replace
                          activeStyle={activeStyle}
                          exact
                          to="/Faq"
                        >
                          <div className="nav-link-item-container">
                            <img
                              className="nav-link-item-image"
                              src={FaqOutline}
                            />

                            {isExpanded && (
                              <span className="nav-link-item-text">FAQs</span>
                            )}
                          </div>
                        </NavLink>
                      </li>
                      <li className="nav-item nav-dropd-down">
                        <NavLink
                          className="nav-link-container"
                          replace
                          activeStyle={activeStyle}
                          exact
                          to="/credit-pricing"
                        >
                          <div className="nav-link-item-container">
                            <img
                              className="nav-link-item-image"
                              src="./img/price-tag.png"
                            />

                            {isExpanded && (
                              <span className="nav-link-item-text">
                                Credit Pricing
                              </span>
                            )}
                          </div>
                        </NavLink>
                      </li>
                    </>
                  )}

                  <li className="nav-item">
                    <div
                      replace
                      activeStyle={activeStyle}
                      exact
                      // to="https://join.slack.com/t/crawlq-ai/shared_invite/zt-p4iindog-fucEgVI5g4sduQovApYKSg"
                      className="nav-link-container"
                    >
                      <a
                        style={{ color: 'inherit' }}
                        href="https://join.slack.com/t/crawlq-ai/shared_invite/zt-p4iindog-fucEgVI5g4sduQovApYKSg"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="nav-link-item-container">
                          <img
                            className="nav-link-item-image"
                            src={SlackOutline}
                          />

                          {isExpanded && (
                            <span className="nav-link-item-text">
                              Join Slack Community
                            </span>
                          )}
                        </div>
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Topnav;
