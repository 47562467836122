/* eslint-disable indent */
/* eslint-disable no-use-before-define */
/* eslint-disable quote-props */
/* eslint-disable no-extra-semi */
/* eslint-disable quotes */

export const languageDict = {
  English: 'en',
  Spanish: 'es',
  French: 'fr',
  Italian: 'it',
  Japanese: 'ja',
  German: 'de',
  Chinese: 'zh',
  Russian: 'ru',
  Portuguese: 'pt',
  Arabic: 'ar',
  Korean: 'ko',
  Dutch: 'nl',
  Turkish: 'tr',
  Hindi: 'hi',
  Swedish: 'sv',
  Polish: 'pl',
  Indonesian: 'id',
  Farsi: 'fa',
  Finnish: 'fi',
  Norwegian: 'no',
  Danish: 'da',
  Greek: 'el',
  Hebrew: 'he',
  Hungarian: 'hu',
  Czech: 'cs',
  Thai: 'th',
  Ukrainian: 'uk',
  Vietnamese: 'vi',
  Romanian: 'ro',
  Slovak: 'sk',
  Bulgarian: 'bg',
  Slovenian: 'sl',
  Croatian: 'hr',
  Lithuanian: 'lt',
  Latvian: 'lv',
  Estonian: 'et',
};

export function getCodeLanguage(code) {
  return languageDict[languageData[code]];
}

export function getCountries(code) {
  return Object.keys(languageData);
}

export const languageData = {
  US: 'English',
  ES: 'Spanish',
  FR: 'French',
  IT: 'Italian',
  JP: 'Japanese',
  DE: 'German',
  CN: 'Chinese',
  RU: 'Russian',
  PT: 'Portuguese',
  SA: 'Arabic',
  KO: 'Korean',
  NL: 'Dutch',
  TR: 'Turkish',
  IN: 'Hindi',
  SE: 'Swedish',
  PL: 'Polish',
  ID: 'Indonesian',
  IR: 'Farsi',
  FI: 'Finnish',
  NO: 'Norwegian',
  DA: 'Danish',
  EL: 'Greek',
  HE: 'Hebrew',
  HU: 'Hungarian',
  CZ: 'Czech',
  TH: 'Thai',
  UK: 'Ukrainian',
  VN: 'Vietnamese',
  RO: 'Romanian',
  SK: 'Slovak',
  BG: 'Bulgarian',
  SL: 'Slovenian',
  HR: 'Croatian',
  LT: 'Lithuanian',
  LV: 'Latvian',
  EE: 'Estonian',
};
