/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import { Amplify, Auth } from 'aws-amplify';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './redux/store';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import SuspenseWrapper from 'utils/suspense-wrapper';

import onBoardingQue from 'Component/Pages/Workspaces/onBoarding/onBoardingQue';
import Workspaces from 'Component/Pages/Workspaces/Workspace/Workspace';
import VerifyEmail from 'Component/Auth/SignUpFreeTrial/Verification/VerifyEmail';
import Pricing from 'Component/Pages/Pricing/Pricing';
import OnBoardingQueLevel2 from 'Component/Pages/Workspaces/onBoarding/onBoardingQueLeveTwo';
import WaysToGetStarted from 'Component/Pages/Workspaces/NewUserLandingPage/WaysToGetStarted';
import NotFound from 'Component/Pages/NotFound';
import useNetworks from 'hooks/useNetwork';
import NoInternet from 'Component/Shared/Nointernet';

// Components
import AppSumo from './Component/Auth/AppSumo';
import ChangePassword from './Component/Auth/ChangePassword';
import PitchGround from './Component/Auth/PitchGround';
import PrivateRoute from './Component/Auth/PrivateRoute';
import FreeTrialRegister from './Component/Auth/SignUpFreeTrial/SignUpFreeTrial';
import AIContentWriter from './Component/Pages/Content Automation/AIContentWriter/AIContentWriter';
import CrawlQSearchLayout from './Component/Pages/Content Automation/AIContentWriter/SemanticSearch/comp_semantic_engine';
import AicontentWriter from './Component/Pages/ContentAutomation/AIcontentWriter/AIcontentWriter';
import RedirectingRecreate from './Component/Pages/ContentAutomation/AIcontentWriter/redirectingRecreate';
import SalesCopyWritter from './Component/Pages/ContentAutomation/SalesCopyWriter/SalesCopyWriter';
import Dashboard from './Component/Pages/Dashboard/Dashboard';
import InsightsGlobal from './Component/Pages/InsightsGlobal/insightsGlobal';
import Login from './Component/Pages/Login/Login';
import Marketresonace from './Component/Pages/MarketReasearch/MarketResearchAndResonance/Marketresearchandresonace';
import SelectLevel from './Component/Pages/MarketReasearch/MarketResearchAndResonance/MarketResearchCategory/CheckLevel';
import GetStarted from './Component/Pages/MarketReasearch/MarketResearchAndResonance/MarketResearchCategory/MarketResearch-getStarted';
import MarketResearchCategory from './Component/Pages/MarketReasearch/MarketResearchAndResonance/MarketResearchCategory/MarketResearchCategory';
import Workflow from './Component/Pages/Workflow/Workflow';
import Faq from './Component/Shared/Faq';
import CreditPricing from './Component/Shared/Pricing/Pricing';
import VideoTuts from './Component/Shared/VideoTutorials/VideoTuts';
import CreateNewWorkspaceHolder from 'components/create-workspace/workspace-create-new/CreateNewWorkspaceHolder';
import NewWorkspcePage from 'Component/Pages/Workspaces/Workspace/NewWorkspacePage';
import NewWorkspceConfiguration from 'Component/Pages/Workspaces/Workspace/WorkspaceConfigutation';
import DigitalAssetsPageBlogPost from 'Component/Pages/ContentAutomation/AIcontentWriter/DigitalAssetsPageBlogPost';
import DigitalAssetsPageSales from 'Component/Pages/ContentAutomation/AIcontentWriter/DigitalAssetsPageSales';
import ContentEditor from 'Component/Pages/ContentEditor';
import CreateWorkspace from 'components/create-workspace/CreateWorkspace';

import { ROUTES } from 'constants/routes/index';

import PrivateRoutes from 'utils/PrivateRoutes';

// Non Suspense Pages
import MainMenu from 'components/common/MainMenu/MainMenu';
import PersonaLibrary from 'components/workspace/persona-library/PersonaLibrary/PersonaLibrary';
import WorkspacePersonaStatus from 'components/workspace/persona-library/WorkspacePersonaStatus/WorkspacePersonaStatus';
import AllWorkspace from 'components/workspace/all-workspace/AllWorkspace';

import MarketMessage from 'components/market-message/MarketMessage';
import GenerateBlogs from 'components/market-message/GenerateBlogs/GenerateBlogs';
import DigitalAssets from 'components/digital-assets-library/DigitalAssets';
import BlogsPost from 'components/digital-assets-library/BlogLibrary/BlogLibrary';
import SalesBlogsPost from 'components/digital-assets-library/SalesLibrary/SalesLibrary';
import ContentWrapper from 'components/content/ContentWrapper';
import BuildWorkspaceStatus from 'Component/Pages/Workspaces/Workspace/BuildWorkspaceStatus';
import WorkspaceBuildSatusDetails from 'Component/Pages/Workspaces/Workspace/BuildWorkspaceStatus/WorkspaceSatusDetails';
import TemplateSelector from 'Component/Pages/Workspaces/Workspace/WorkspaceConfigutation/TemplateSelector';
import TemplatesBlogGenerator from 'Component/Pages/ContentAutomation/AIcontentWriter/TemplatesBlogGenerator';
import ChatGPTButton from 'Component/Shared/BaseLayout/ChatGPTButton';
import { ChatAthena } from 'Component/Pages/ChatAthena';
import BrandVoice from 'components/brand-voice/BrandVoice';
import AnonymousRegister from 'Component/Pages/ChatAthena/ChatAthenaAnonymous/AnonymousRegister';
import DisplayTemplates from 'components/manage-templates/DisplayTemplates/DisplayTemplates';
import ManageTemplates from 'components/manage-templates/ManageTemplates';
import SalesBlogs from 'components/market-message/SalesBlogs/SalesBlogs';
import CreateTemplates from 'components/manage-templates/CreateTemplate/CreateTemplate';
import EditTemplates from 'components/manage-templates/CreateTemplate/EditTemplate';
import UserProfile from 'components/user-profile/UserProfile';
import BlogShare from 'components/blog-shared/BlogShare';
import TeamMember from 'components/team-member/TeamMember';

// Dashboard
import Dashboardd from 'components/dashboard/Dashboard';

// Market Research
import MarketResearch from 'components/market-research/MarketResearch';
import Attract from 'components/market-research/attract/Attract';
import Desire from 'components/market-research/desire/Desire';
import Convert from 'components/market-research/convert/ConvertCategory';
import SelectedConvert from 'components/market-research/convert/Convert';

// Assistants
import AssistantWrapper from 'components/assistants/AssistantWrapper';
import ShowAssistants from 'components/assistants/all-assistants/ShowAssistants';
import EditAssistant from 'components/assistants/edit-assistant/EditAssistant';
import CreateAssistant from 'components/assistants/create-assistant/CreateAssistant';

// Insights 360 ai
import Insights360AI from 'components/insights-ai/InsightsAI';

import { ToastContainer } from 'react-toastify';

// New Page Imports
const ForgotPassword = React.lazy(() =>
  import('components/forgot-password/ForgotPassword'),
);

const TemplateBuilder = React.lazy(() =>
  import('Component/Pages/TemplateBuilder/TemplateBuilder'),
);

Amplify.configure({
  Auth: {
    region: 'us-east-2',
    userPoolId: 'us-east-2_FRhf7xm7F',
    userPoolWebClientId: '5hh0ivul1m8npbeappgcq02udj',
  },
});

Auth.configure({
  Auth: {
    region: 'us-east-2',
    userPoolId: 'us-east-2_FRhf7xm7F',
    userPoolWebClientId: '5hh0ivul1m8npbeappgcq02udj',
  },
});

const queryClient = new QueryClient();

const {
  HOME,
  DONE_FOR_YOU,
  NEW_WORKSPACE,
  NEW_WORKSPACE_CONFIG,
  ALL_WORKSPACE_PERSONA_STATUS,
  WORKSPACE_PERSONA_STATUS,
  MARKET_RESEARCH_LEVEL_1,
  MARKET_RESEARCH_LEVEL_2,
  MARKET_RESEARCH_LEVEL_3,
  MARKET_RESEARCH,
  WORKSPACE,
  USER_WORKSPACE,
  MARKET_MESSAGE,
  DIGITAL_ASSETS,
  DIGITAL_ASSETS_BLOGS,
  DIGITAL_ASSETS_SALES,
  MANAGE_TEMPLATES,
  MARKET_MESSAGE_BLOGS,
  CREATE_TEMPLATES,
  USER_PROFILE,
  EDIT_TEMPLATES,
  DASHBOARD,
  ASSISTANTS,
  CREATE_ASSISTANTS,
  EDIT_ASSISTANTS,
  INSIGHTS360AI,
} = ROUTES;

function App() {
  // const isHeader = window.location.pathname === '/';
  const [isOffline] = useNetworks();

  if (isOffline) {
    return <NoInternet />;
  }

  return (
    <Provider store={store}>
      <ToastContainer />
      <ChatGPTButton />

      <QueryClientProvider client={queryClient}>
        <div className="App">
          <PersistGate persistor={persistor}>
            <Router>
              <Routes>
                <Route exact path="/AppSumo" replace element={<AppSumo />} />

                <Route
                  exact
                  path="/PitchGround"
                  replace
                  element={<PitchGround />}
                />

                <Route
                  exact
                  path="/sign-up"
                  replace
                  element={<FreeTrialRegister />}
                />

                <Route exact path="/" replace element={<Login />} />

                <Route exact path="/pricing" replace element={<Pricing />} />

                <Route
                  exact
                  path="/verify-email"
                  replace
                  element={<VerifyEmail />}
                />

                <Route
                  exact
                  path="/forgot-password"
                  replace
                  element={<SuspenseWrapper Component={ForgotPassword} />}
                />

                <Route
                  exact
                  path="/brand-voice"
                  replace
                  element={<BrandVoice />}
                />

                <Route
                  exact
                  path="/ai-template-builder"
                  replace
                  element={<SuspenseWrapper Component={TemplateBuilder} />}
                />

                <Route
                  exact
                  path="/manage-templates"
                  replace
                  element={<SuspenseWrapper Component={ManageTemplates} />}
                />

                <Route
                  exact
                  path="/dashboardd"
                  replace
                  element={<PrivateRoute Component={Dashboard} />}
                />

                <Route
                  exact
                  path="/Workspace"
                  replace
                  element={<PrivateRoute Component={Workspaces} />}
                />

                <Route
                  exact
                  path="/CrawlQ-Search"
                  replace
                  element={<PrivateRoute Component={CrawlQSearchLayout} />}
                />

                <Route
                  exact
                  path="/ChangePassword"
                  replace
                  element={<PrivateRoute Component={ChangePassword} />}
                />

                <Route
                  exact
                  path="/Market-Resonance-Level-3"
                  replace
                  element={<PrivateRoute Component={SelectLevel} />}
                />
                <Route
                  exact
                  path="/Market-Resonance/"
                  replace
                  element={<PrivateRoute Component={Marketresonace} />}
                />
                <Route
                  exact
                  path="/MarketResearchCategory"
                  replace
                  element={<PrivateRoute Component={MarketResearchCategory} />}
                />
                <Route
                  exact
                  path="/onboarding-qna"
                  replace
                  element={<PrivateRoute Component={onBoardingQue} />}
                />
                <Route
                  exact
                  path="/onboarding-qna-level-2"
                  replace
                  element={<PrivateRoute Component={OnBoardingQueLevel2} />}
                />
                <Route
                  exact
                  path="/MRR-Get-Started"
                  replace
                  element={<PrivateRoute Component={GetStarted} />}
                />

                <Route
                  exact
                  path="/chat-athena"
                  replace
                  element={<ChatAthena type="CHATATHENA" />}
                />

                <Route
                  exact
                  path="/chat-athena-flow"
                  replace
                  element={<ChatAthena type="FLOW" />}
                />

                <Route
                  exact
                  path="/start-now"
                  replace
                  element={<AnonymousRegister />}
                />

                <Route
                  exact
                  path="/SalesCopyWritingWizard"
                  replace
                  element={<PrivateRoute Component={SalesCopyWritter} />}
                />

                <Route
                  exact
                  path="/sales-ai-content-writer/app"
                  replace
                  element={<PrivateRoute Component={AIContentWriter} />}
                />
                <Route
                  exact
                  path="/create-drafts"
                  replace
                  element={<PrivateRoute Component={TemplatesBlogGenerator} />}
                />

                <Route
                  exact
                  path="/ai-content-writer/app"
                  replace
                  element={<PrivateRoute Component={AIContentWriter} />}
                />

                <Route
                  exact
                  path="/sales-ai-content-writer/editor"
                  replace
                  element={<PrivateRoute Component={ContentEditor} />}
                />

                <Route
                  exact
                  path="/ai-content-writer/editor"
                  replace
                  element={<PrivateRoute Component={ContentEditor} />}
                />

                <Route
                  exact
                  path="/redirectTest"
                  replace
                  element={<PrivateRoute Component={RedirectingRecreate} />}
                />

                <Route
                  exact
                  path="/AIContentWriter"
                  element={<PrivateRoute Component={AicontentWriter} />}
                />

                <Route
                  exact
                  path="/Faq"
                  replace
                  element={<PrivateRoute Component={Faq} />}
                />

                <Route
                  exact
                  path="/AI-insights"
                  replace
                  element={<PrivateRoute Component={InsightsGlobal} />}
                />

                <Route
                  exact
                  path="/workflow"
                  replace
                  element={<PrivateRoute Component={Workflow} />}
                />

                <Route
                  exact
                  path="/credit-pricing"
                  replace
                  element={<PrivateRoute Component={CreditPricing} />}
                />

                <Route
                  exact
                  path="/Learn"
                  replace
                  element={<PrivateRoute Component={VideoTuts} />}
                />

                <Route
                  exact
                  path={ROUTES.TEAM_MEMBER}
                  replace
                  element={<PrivateRoute Component={TeamMember} />}
                />

                <Route
                  exact
                  path={ROUTES.PUBLIC_BLOG}
                  element={<BlogShare />}
                />

                <Route
                  exact
                  path="/WorkspaceGetStarted"
                  element={<WaysToGetStarted />}
                />
                <Route
                  exact
                  path="/new-workspace"
                  element={<NewWorkspcePage />}
                />
                <Route
                  exact
                  path="/new-workspace-configuration"
                  element={<NewWorkspceConfiguration />}
                />
                <Route
                  exact
                  path="/new-workspace-template-selector"
                  element={<TemplateSelector />}
                />
                <Route
                  exact
                  path="/build-workspace-status"
                  element={<BuildWorkspaceStatus />}
                />

                <Route
                  exact
                  path="/build-workspace-status-detailed"
                  element={<WorkspaceBuildSatusDetails />}
                />
                <Route
                  exact
                  path="/digital-asset-management-blogposts"
                  element={<DigitalAssetsPageBlogPost />}
                />
                <Route
                  exact
                  path="/digital-asset-management-salesposts"
                  element={<DigitalAssetsPageSales />}
                />

                <Route element={<NotFound />} />

                <Route element={<PrivateRoutes />}>
                  <Route exact path={DASHBOARD} element={<Dashboardd />} />
                  <Route exact path={HOME} element={<MainMenu />} />

                  <Route exact path={USER_PROFILE} element={<UserProfile />} />

                  {/* New */}
                  <Route
                    exact
                    path={ALL_WORKSPACE_PERSONA_STATUS}
                    element={<PersonaLibrary />}
                  />
                  {/* New */}
                  <Route
                    exact
                    path="/create-drafts"
                    replace
                    element={
                      <PrivateRoute Component={TemplatesBlogGenerator} />
                    }
                  />

                  <Route
                    exact
                    path={WORKSPACE_PERSONA_STATUS}
                    element={<WorkspacePersonaStatus />}
                  />

                  <Route exact path="/campaigns" element={<AllWorkspace />} />

                  <Route
                    exact
                    path={MARKET_RESEARCH}
                    element={<MarketResearch />}
                  >
                    <Route index path={MARKET_RESEARCH} element={<Attract />} />

                    <Route
                      exact
                      path={MARKET_RESEARCH_LEVEL_2}
                      element={<Desire />}
                    />

                    <Route
                      exact
                      path={MARKET_RESEARCH_LEVEL_3}
                      element={<Convert />}
                    />

                    <Route
                      exact
                      path={`${MARKET_RESEARCH_LEVEL_3}/:category`}
                      element={<SelectedConvert />}
                    />
                  </Route>

                  <Route
                    exact
                    path={MARKET_MESSAGE}
                    element={<MarketMessage />}
                  >
                    <Route
                      index
                      path={MARKET_MESSAGE}
                      element={<GenerateBlogs />}
                    />
                    <Route
                      index
                      path={MARKET_MESSAGE_BLOGS}
                      element={<SalesBlogs />}
                    />
                  </Route>

                  <Route exact path={ASSISTANTS} element={<AssistantWrapper />}>
                    <Route
                      index
                      path={ASSISTANTS}
                      element={<ShowAssistants />}
                    />

                    <Route
                      path={CREATE_ASSISTANTS}
                      element={<CreateAssistant />}
                    />

                    <Route path={EDIT_ASSISTANTS} element={<EditAssistant />} />
                  </Route>

                  <Route
                    exact
                    path={MANAGE_TEMPLATES}
                    element={<ManageTemplates />}
                  >
                    <Route
                      index
                      path={MANAGE_TEMPLATES}
                      element={<DisplayTemplates />}
                    />
                    <Route
                      path={CREATE_TEMPLATES}
                      element={<CreateTemplates />}
                    />

                    <Route path={EDIT_TEMPLATES} element={<EditTemplates />} />
                  </Route>

                  <Route
                    exact
                    path={DIGITAL_ASSETS}
                    element={<DigitalAssets />}
                  >
                    <Route
                      index
                      path={DIGITAL_ASSETS}
                      element={<BlogsPost />}
                    />
                    <Route
                      index
                      path={DIGITAL_ASSETS_SALES}
                      element={<SalesBlogsPost />}
                    />
                  </Route>

                  <Route
                    exact
                    path={INSIGHTS360AI}
                    element={<Insights360AI />}
                  />

                  <Route
                    exact
                    path="/content/:id"
                    element={<ContentWrapper />}
                  />
                </Route>
                {/* New */}

                {/* New */}
                <Route
                  exact
                  path={NEW_WORKSPACE}
                  element={
                    <SuspenseWrapper Component={CreateWorkspace} secure />
                  }
                >
                  {/* New */}
                  <Route
                    path={NEW_WORKSPACE}
                    element={
                      <SuspenseWrapper
                        Component={CreateNewWorkspaceHolder}
                        secure
                      />
                    }
                  />

                  {/* New */}
                  <Route
                    path={NEW_WORKSPACE_CONFIG}
                    element={
                      <SuspenseWrapper
                        Component={NewWorkspceConfiguration}
                        secure
                      />
                    }
                  />
                </Route>

                {/* New */}
                <Route
                  exact
                  path={DONE_FOR_YOU}
                  element={
                    <SuspenseWrapper
                      Component={CreateNewWorkspaceHolder}
                      secure
                    />
                  }
                />

                <Route
                  exact
                  path="/WorkspaceGetStarted"
                  element={<WaysToGetStarted />}
                />
                <Route
                  exact
                  path="/new-workspace"
                  element={<NewWorkspcePage />}
                />
                <Route
                  exact
                  path="/new-workspace-configuration"
                  element={<NewWorkspceConfiguration />}
                />
                <Route
                  exact
                  path="/new-workspace-template-selector"
                  element={<TemplateSelector />}
                />
                <Route
                  exact
                  path="/build-workspace-status"
                  element={<BuildWorkspaceStatus />}
                />
              </Routes>
            </Router>
          </PersistGate>
        </div>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Provider>
  );
}

export default App;
