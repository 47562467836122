import { useNavigate } from 'react-router-dom';
import React from 'react';
import AvatarGeneratorPlaceholder from 'components/common/AvatarGenerator/AvatarGenerator';
import cx from 'classnames';
import { EditIcon, LoaderTailSpin, PrimaryButton } from 'components/common';
import { ROUTES } from 'constants/routes';
import CustomTooltip from 'Component/Shared/CustomTooltip';
import { getTrimmedString } from 'utils/helpers/string-service';
import { useCloneAssistant } from 'queries/assistants/mutate-clone-assistant';
import styles from './index.module.css';

const AssistantCard = ({ assistant, onUse }) => {
  const navigate = useNavigate();
  const {
    mutateAsync: cloneAssistant,
    isLoading: isCloningAssistant,
  } = useCloneAssistant(assistant.assistant_id);

  const handleEdit = () => {
    if (assistant.from_support) return;
    navigate(ROUTES.EDIT_ASSISTANTS, { state: assistant });
  };

  const handleCloneAssistant = async () => {
    try {
      const { data } = await cloneAssistant(assistant.assistant_id);
      onUse(data);
    } catch (ex) {
      console.error(ex);
    }
  };

  return (
    <div className={styles.container}>
      <div className={cx(styles.title, styles.section)}>
        <AvatarGeneratorPlaceholder avatarProp={assistant.avatar_props} />

        {!assistant?.from_support && (
          <CustomTooltip placement="top" title={'Edit Assistant'} arrow>
            <div className={cx(styles.edit_icon_wrapper)} onClick={handleEdit}>
              <EditIcon />
            </div>
          </CustomTooltip>
        )}
      </div>
      <div className={styles.section}>
        <CustomTooltip placement="top" title={assistant.assistant_name} arrow>
          <p className={styles.title}>
            {getTrimmedString(assistant.assistant_name, 21)}
          </p>
        </CustomTooltip>
      </div>
      <div className={styles.section}>
        <CustomTooltip placement="top" title={assistant.description} arrow>
          <small className={styles.description}>
            {getTrimmedString(assistant.description, 150)}
          </small>
        </CustomTooltip>
      </div>
      {!assistant.from_support ? (
        <div className={cx(styles.button, styles.section)}>
          <PrimaryButton onClick={() => onUse(assistant)}>Use</PrimaryButton>
        </div>
      ) : (
        <div className={cx(styles.button, styles.section)}>
          {isCloningAssistant ? (
            <LoaderTailSpin height={'25px'} />
          ) : (
            <PrimaryButton onClick={handleCloneAssistant}>Clone</PrimaryButton>
          )}
        </div>
      )}
    </div>
  );
};
export default AssistantCard;
