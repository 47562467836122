import { ProducePanelInfos } from 'constants/main-menu';
import React from 'react';
import './styles.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addNewTab } from 'redux/index';
// assets
import LaunchIcon from '@mui/icons-material/Launch';
import { getLastTabId } from 'redux/TabsManager/helper';

function ProducePanel() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tabs = useSelector((state) => state?.tabManager?.tabs);

  const handleOpen = (feature) => {
    const tabId = getLastTabId(tabs);
    dispatch(addNewTab({ id: tabId + 1,
      name: feature?.feature_name,
      path: feature?.feature_path },
    ));

    navigate(`${feature?.feature_path}`);
  };

  return (
    <div className="feature-detail-list">
      { ProducePanelInfos.map((producePanelInfo) => (
        <div className="feature-detail produce-panel" key={producePanelInfo.header_text}>
          <div className="feature-detail-header">
            <producePanelInfo.header_icon style={{ width: '18px', color: '#2a9df5', marginRight: '0.25rem' }} />
            <span className="">{producePanelInfo.header_text}</span>
          </div>
          <div className="produce-panel-features">
            {producePanelInfo?.features_list.map((feature) => (
              <div className="produce-panel-feaure-name" key={feature.feature_name} onClick={() => handleOpen(feature)}>
                <span>{feature.feature_name}</span>
                <LaunchIcon style={{ width: '14px', color: '#2a9df5', marginRight: '0.25rem', cursor: 'pointer' }} />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}
export default ProducePanel;
