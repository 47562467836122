/* eslint-disable default-param-last */
import { SET_RECENT_WORKSPACE } from './RecentWorkspacesTypes';
import { updateRecentWorkspace } from './helper';

const initialState = {
  workspaces: [],
};

const recentWorkspaceReducer = (state = initialState, action) => {
  switch (action?.type) {
    // Recent Workspaces will show top 5 recent workspace only
    case SET_RECENT_WORKSPACE: {
      const topFiveFrequentUsedWorkspaces =
      updateRecentWorkspace(state?.workspaces, action.payload);

      return {
        ...state,
        workspaces: topFiveFrequentUsedWorkspaces };
    }

    default:
      return state;
  }
};

export default recentWorkspaceReducer;
