/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import React from 'react';
import Topnav from '../Sidenav/Topnav';
import Topbar from '../Topbar/Topbar';
import './Pricing.css';
import coin from '../../../assets/images/coins.svg';
import {
  AI_CONTENT_REWRITER_COST,
  ATHENA_WRITE_ME_COST,
  FILL_WITH_AI_COST,
  GENERATE_ANSWER_COST,
  HEADLINE_INTRO_COST,
  MARKET_INSIGHTS_COST,
  MARKET_SPYING_WIZARD_COST,
  ONBOARDING_FILLWITH_AI_CREDITS,
  ONBOARDING_LEVEL_1_COST,
  SALES_COPY_WRITING_COST,
  TEXT_SUMMARIZER_COST,
  TRAIN_ATHENA_COST,
} from '../featuresCreditsCost';

function Pricing() {
  function FeaturePrice({ name, desc, price }) {
    return (
      <div className="feature__price__container">
        <div className="feature__price">
          <span>{name}</span>
          <p>{desc}</p>
        </div>
        <div className="feature__coin">
          <p style={{ fontSize: '16px' }}>{price}</p>
          <img src={coin} />
        </div>
      </div>
    );
  }

  return (
    <>
      <Topnav />
      <Topbar />
      <div className="pricing__body">
        <div className="pricing__card">
          <div className="feature__card">
            <div className="feature__price__heading">
              <span>Credits Pricing</span>
            </div>
            <div className="feature__box">
              <FeaturePrice
                name="Sales Copywriter"
                desc="Generate Awesome Sales Letter"
                price={SALES_COPY_WRITING_COST}
              />
              <FeaturePrice
                name="Blog Tools"
                desc="Create Unique Blogs"
                price="180"
              />
              <FeaturePrice
                name="Headline Intros"
                desc="Get Relevant Headlines"
                price={HEADLINE_INTRO_COST}
              />
              <FeaturePrice
                name="Generate Answers"
                desc="Get Unique Answers"
                price={GENERATE_ANSWER_COST}
              />
              <FeaturePrice
                name="Write Me Athena"
                desc=""
                price={ATHENA_WRITE_ME_COST}
              />

              <FeaturePrice
                name="AI Rewriter"
                desc="Get Crisp Words and Statements"
                price={AI_CONTENT_REWRITER_COST}
              />

              <FeaturePrice
                name="Text Summarizer"
                desc="Extract Dense Quality Statement"
                price={TEXT_SUMMARIZER_COST}
              />

              <FeaturePrice
                name="MRR Fill With AI"
                desc="CrawlQ helps your fill Market Research"
                price={FILL_WITH_AI_COST}
              />

              <FeaturePrice
                name="MRR Insights"
                desc="Your Niche Insights "
                price={MARKET_INSIGHTS_COST}
              />
              <FeaturePrice
                name="Market Spying Wizard"
                desc="Target your Domain"
                price={MARKET_SPYING_WIZARD_COST}
              />
              <FeaturePrice
                name="Search Intent"
                desc="Find your Domain"
                price="0"
              />
              <FeaturePrice
                name="Train Me Athena"
                desc="Power Up Athena"
                price={TRAIN_ATHENA_COST}
              />
              <FeaturePrice
                name="Market Reasearch Onboarding"
                desc="To onboard you and bootstrap Top of the Funnel"
                price={ONBOARDING_FILLWITH_AI_CREDITS}
              />
              <FeaturePrice
                name="Market Reasearch Level 2 Onboarding"
                desc="To onboard you and bootstrap Middle of the Funnel"
                price={ONBOARDING_FILLWITH_AI_CREDITS}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Pricing;
