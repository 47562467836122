import { SET_ACTIVE_WORKSPACE, SET_LANGUAGE_ENGINE, SET_QUEUED_WORKSPACE_FROM_START_CAMPAIGN } from './ActiveWorkspaceTypes';

export const workspaceSelect = (workspace) => ({
  type: SET_ACTIVE_WORKSPACE,
  payload: workspace,
});

export const changeLanguageEngine = (newEngine) => ({
  type: SET_LANGUAGE_ENGINE,
  payload: newEngine,
});

export const queuedWorkspaceSelect = (queuedWorkspace) => ({
  type: SET_QUEUED_WORKSPACE_FROM_START_CAMPAIGN,
  payload: queuedWorkspace,
});
