import React, { useState } from 'react';
import { useGetMarketInsightsGlobal } from 'queries/get-market-insights-global';
import { ScaffoldCard } from 'components/common/scaffold-card';
import { PlusIcon, MinusIcon } from 'components/common';
import styles from './market-insights.module.css';

function MarketInsights() {
  const { data: insightsData, isLoading } = useGetMarketInsightsGlobal();

  const [ShowIndex, setShowIndex] = useState([]);

  const handleShow = (index) => {

  };

  console.log('debug', insightsData);
  console.log('load', isLoading);
  return (
    <div className={styles.wrapper}>
      {insightsData && insightsData.map((insight, index) => (
        <>
          <ScaffoldCard className={styles.category_container}>
            <span>{insight?.category }</span>
            { !ShowIndex.includes(index) && (
            <PlusIcon
              onClick={() => setShowIndex((ShowIndex) => [...ShowIndex, index])}
            />
            )}
            {ShowIndex.includes(index) && (
            <MinusIcon
              onClick={() => setShowIndex((ShowIndex) => ShowIndex.filter((idx) => idx !== index))}
            />
            )}
          </ScaffoldCard>
          {ShowIndex.includes(index) && <div>SHow dATA</div>}
        </>
      ))}
    </div>
  );
}

export default MarketInsights;
