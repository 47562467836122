import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import fetchAllTemplates from 'Component/Pages/ManageTemplates/manageTemplatesLogic';
import {
  ButtonPrimary,
  ButtonSecondary,
  Divider,
  Flex,
} from 'Component/Shared/GlobalStyles';
import { Input } from 'antd';
import Checkbox from '@mui/material/Checkbox';
import { GetBlogTemplate } from 'Component/Pages/ContentAutomation/AIcontentWriter/AIcontentLogic';
import TabSwitcher from 'Component/Shared/TabSwitcher/TabSwitcher';
import { LoaderWrapper } from 'Component/Pages/ManageTemplates/styles';
import LoaderSpin from 'Component/Shared/Loader';
import { toast, ToastContainer } from 'react-toastify';
import {
  sortBlogs,
  sortTemplates,
} from 'Component/Pages/Workspaces/Workspace/WorkspaceConfigutation/template-selector-helper';
import BaseLayout from 'Component/Shared/BaseLayout/BaseLayout';
import CustomTooltip from 'Component/Shared/CustomTooltip';
import { CreditManage } from 'Component/Shared/CreditManager';
import TemplateCard from 'Component/Pages/Workspaces/Workspace/WorkspaceConfigutation/components/TemplateCard';
import { MANAGE_TEMPLATES_FILTER_OPTIONS } from 'components/constants';
import styles from './template_selector.module.css';
import { createBlogsDrafts } from './PrivateBlogTemplatesLogic';
import templateIconActive from '../../../../assets/img/templatesIconActive.svg';
import InnerTopnav from '../Shared/InnerTopNav';

const { Search } = Input;

function Tab({ tab, setTab, tabName }) {
  return (
    <small
      style={{
        fontSize: '14px',
        color: tab === tabName ? '#fff' : '#000',
        fontWeight: tabName === 'Hypnotic-NLP' ? 'bold' : 'normal',
      }}
      className={`btn btn-sm btn-outline-secondary ${
        tab === tabName ? 'active' : ''
      }`}
      onClick={() => setTab(tabName)}
    >
      {tabName}
    </small>
  );
}

const TableColumn = ({
  index,
  name,
  description,
  selectedIndexes,
  handleTemplateSelect,
}) => (
  <TemplateCard
    name={name}
    descrption={description}
    onBtnClick={() => {
      handleTemplateSelect((prev) => {
        if (!selectedIndexes.includes(index)) {
          return [...prev, index];
        }
        return prev.filter((val) => val !== index);
      });
    }}
    isSelected={selectedIndexes.includes(index)}
  />
);

const TEMPLATES_COST = 750;

const TemplatesBlogGenerator = () => {
  const navigate = useNavigate();

  const [privateTemplates, setPrivateTemplates] = useState([]);
  const [publicTemplates, setPublicTemplates] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [privateTemplatesIndexs, setPrivateTemplatesIndexs] = useState([]);
  const [publicTemplatesIndexs, setPublicTemplatesIndexs] = useState([]);
  const [isLoadingTemplates, setIsLoadingTemplates] = useState(true);
  const [isCreatingBlogs, setIsCreatingBlogs] = useState(false);
  const [searchKey, setSearchKey] = useState('');

  const [tab, setTab] = useState('');

  const ToastProps = {
    autoClose: 3000,
    position: 'bottom-center',
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    theme: 'colored',
  };

  const loadTemplates = () => {
    fetchAllTemplates()
      .then((data) => {
        if (data.error) {
          toast.error("Couldn't Load Private Templates", ToastProps);
          return;
        }
        if (data.length > 0) {
          const sortedTemplates = sortTemplates([...data]);
          setPrivateTemplates(sortTemplates([...sortedTemplates]));
        }
      })

      .finally(() => setIsLoadingTemplates(false));
    GetBlogTemplate().then(({ success, data }) => {
      if (!success) {
        toast.error("Couldn't Load Public Templates", ToastProps);
        return;
      }
      if (data.length > 0) {
        const sortedBlogs = sortBlogs(data);
        setPublicTemplates([...sortedBlogs]);
      }
    });
  };

  const handleTemplateSelect = async () => {
    const publicTemp = publicTemplatesIndexs.map((temp) => ({
      template_id: publicTemplates[temp].id,
      template_type: 'public',
    }));

    const privateTemp = privateTemplatesIndexs.map((temp) => ({
      template_id: privateTemplates[temp].template_id,
      template_type: 'private',
      from_support:
        privateTemplates[temp].username === 'support@quantamixsolutions.com',
    }));
    const default_templates = [...privateTemp, ...publicTemp];

    if (default_templates.length === 0) {
      toast.error('Select Atleast One Template', ToastProps);
      return;
    }
    setIsCreatingBlogs(true);
    const response = await createBlogsDrafts(default_templates);
    if (response.error) {
      toast.error(response.message, ToastProps);
      setIsCreatingBlogs(false);
      return;
    }
    setIsCreatingBlogs(false);
    CreditManage(default_templates.length * TEMPLATES_COST);
    toast.success('Blogs Drafted Successfully');
    navigate('/digital-asset-management-blogposts?selectedTab=allAssets');
  };

  const handleTabFilter = (template) => {
    const key = tab.trim().toLowerCase();

    if (key === '' || key === 'All') return true;

    if (
      !!template.blog_name &&
      template.blog_name.toLowerCase().includes(key)
    ) {
      return true;
    }

    if (
      !!template.info_description &&
      template.info_description.toLowerCase().includes(key)
    ) {
      return true;
    }

    if (
      !!template.template_name &&
      template.template_name.toLowerCase().includes(key)
    ) {
      return true;
    }

    if (
      !!template.template_description &&
      template.template_description.toLowerCase().includes(key)
    ) {
      return true;
    }

    return false;
  };

  const handleFilter = (template) => {
    const key = searchKey.trim().toLowerCase();
    if (key === '') return true;

    if (
      !!template.blog_name &&
      template.blog_name.toLowerCase().includes(key)
    ) {
      return true;
    }

    if (
      !!template.info_description &&
      template.info_description.toLowerCase().includes(key)
    ) {
      return true;
    }

    if (
      !!template.template_name &&
      template.template_name.toLowerCase().includes(key)
    ) {
      return true;
    }

    if (
      !!template.template_description &&
      template.template_description.toLowerCase().includes(key)
    ) {
      return true;
    }

    return false;
  };

  const handleSearch = (e) => {
    setSearchKey(e.target.value);
  };

  const handleOpenGeneralBlogOutline = () => {
    const data = {
      template_name: 'General Blog Outline',
      template_id: 'general_blog',
      credits: 180,
    };
    navigate('/AIContentWriter', { state: data });
  };

  useEffect(() => {
    loadTemplates();
  }, []);

  return (
    <BaseLayout>
      <ToastContainer />
      <Flex
        style={{
          flex: '1',
        }}
        className="aw-nav-container"
      >
        <InnerTopnav />
      </Flex>
      <Flex className="container py-4" flex={10}>
        <Flex
          direction={'column'}
          style={{
            overflow: 'scroll',
          }}
          className="shadow p-2"
        >
          <Flex flex={1} direction={'column'}>
            <h5 className="m-0 mb-1 text-center txt-primary">
              Select One or More Templates
            </h5>
            <Flex gap={'0.5rem'} className={styles.table_header}>
              <Flex justify={'flex-end'} flex={1} align={'center'}>
                <div
                  style={{
                    width: '250px',
                  }}
                  className="mx-1"
                >
                  <Search
                    placeholder="Search Templates"
                    onChange={handleSearch}
                    value={searchKey}
                  />
                </div>
                <CustomTooltip title="Manage Templates" placement="top" arrow>
                  <ButtonSecondary
                    style={{
                      width: 'fit-content',
                    }}
                    onClick={() => navigate('/Manage-Templates')}
                  >
                    <img height={'25px'} src={templateIconActive} alt="" />
                  </ButtonSecondary>
                </CustomTooltip>
                <ButtonSecondary
                  className="mx-2"
                  onClick={handleOpenGeneralBlogOutline}
                >
                  Generate Blog Outline
                </ButtonSecondary>
              </Flex>
            </Flex>
          </Flex>

          <Flex justify={'center'} style={{ marginTop: '10px' }}>
            <div
              className={'btn-group btn-group-toggle'}
              style={{
                padding: '0 1rem',
                flexWrap: 'wrap',
              }}
            >
              {MANAGE_TEMPLATES_FILTER_OPTIONS.map((TAB, tabIndex) => (
                <Tab
                  key={tabIndex + TAB}
                  tab={tab}
                  setTab={setTab}
                  tabIndex={tabIndex}
                  tabName={TAB}
                />
              ))}
            </div>
          </Flex>

          {isLoadingTemplates && (
            <LoaderWrapper>
              <LoaderSpin />
            </LoaderWrapper>
          )}
          {currentTab === 0 && (
            <Flex className={styles.table_columns}>
              {privateTemplates
                .filter(handleTabFilter)
                .filter(handleFilter)
                .map((template, index) => (
                  <TableColumn
                    name={template.template_name}
                    description={template.template_description}
                    index={template.index}
                    key={index}
                    selectedIndexes={privateTemplatesIndexs}
                    handleTemplateSelect={setPrivateTemplatesIndexs}
                  />
                ))}
            </Flex>
          )}
          <Flex flex={1}>
            <Flex flex={1} className="py-3" justify={'space-between'}>
              <ButtonSecondary onClick={() => navigate('/ai-template-builder')}>
                Create New Template
              </ButtonSecondary>
              {!isCreatingBlogs ? (
                <ButtonPrimary onClick={handleTemplateSelect}>
                  <span className="mr-2">Create Drafts</span>
                </ButtonPrimary>
              ) : (
                <ButtonPrimary>
                  <LoaderSpin height={'30px'} width={'50px'} />
                </ButtonPrimary>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </BaseLayout>
  );
};

export default TemplatesBlogGenerator;
