import React from 'react';
import cx from 'classnames';
import { useIsMobile } from 'Component/hooks/useIsMobile';
import styles from './index.module.css';

export const TrayButton = ({
  onClick,
  Icon,
  label,
  danger,
  disabled = false,
  isAttachedInMessage = false,
}) => {
  const isMobile = useIsMobile();
  return (
    <button
      type="button"
      className={cx(styles.tray_button, {
        [styles.danger]: danger,
        [styles.disabled]: disabled,
        [styles.attached]: isAttachedInMessage,
      })}
      onClick={!disabled ? onClick : null}
      disabled={disabled}
    >
      <Icon />
      {!isMobile && <span>{label}</span>}
    </button>
  );
};
