const JWT_KEY = 'id_token';

export function getAuthToken() {
  return localStorage.getItem(JWT_KEY);
}

export function setAuthToken(token) {
  return localStorage.setItem(JWT_KEY, token);
}

export function removeAuthToken() {
  return localStorage.removeItem(JWT_KEY);
}
