/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import React, { useState } from 'react';
import axios from 'axios';
import ClipLoader from 'react-spinners/BeatLoader';
import { css } from '@emotion/core';

const override = css`
  position: absolute;
  top: 40%;
  left: 40%;
  border-color: #f5b848;
  z-index: 10;
`;

function TopicClusters() {
  const [topics, setTopics] = useState({ show: false, allTopics: [] });
  const [showLoader, setShowLoader] = useState(false);
  const [searchURl, setSearchURl] = useState(localStorage.getItem('url'));

  const getAITopicModelling = async (url) => {
    const body = new FormData();

    await body.append('query_url', url);
    axios
      .post('https://api.crawlq.ai/v1/ai-topic-modelling/', body)
      .then(({ data }) => {
        setTopics({ show: true, allTopics: data });
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {showLoader && (
        <div className="sweet-loading">
          <ClipLoader
            css={override}
            size={20}
            color="#123abc"
            loading={!topics.show}
          />
        </div>
      )}
      <div className="border-rounded-light">
        <span>Search for your Domains Topic Clusters</span>
        <div className="d-flex  justify-content-between mt-2">
          <input
            value={searchURl}
            onChange={(e) => {
              e.preventDefault();
              setSearchURl(e.target.value);
            }}
            className="flex-grow-1 mr-5"
            style={{
              border: '1px solid ',
              borderRadius: '5px',
              padding: '10px',
            }}
          />
          <button
            onClick={(e) => {
              e.preventDefault();
              getAITopicModelling(searchURl);
              setShowLoader(true);
              setTopics({ show: false });
            }}
            className="btn-next float-right"
          >
            <span>Generate</span>
            <img
              src="/img/circular-arrow-right.svg"
              className="img-fluid ml-2"
              width="22"
            />
          </button>
        </div>
      </div>
      {topics.show && (
        <div className="topic-container">
          {topics.allTopics.topics.map((topic, index) => (
            <div className="eachtopic" key={index}>
              <h5 className="topicHead">
                Topic Cluster &nbsp;
                {index + 1}
              </h5>

              {topic[index].map((word, index) => (
                <>
                  <div key={index} className="details">
                    <div className="word">
                      {' '}
                      {word[0]}
                    </div>
                    <div className="scores">
                      {(word[1] * 100).toString().substring(0, 3)}
                    </div>
                  </div>
                  <br />
                </>
              ))}
            </div>
          ))}
        </div>
      )}
    </>
  );
}

export default TopicClusters;
