import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './header-card.module.css';
import { ScaffoldCard } from '../scaffold-card';
import { ArrowBackward } from '..';

export function HeaderCard({
  className,
  title,
  description,
  quickNav,
  quickNavLink,
  children,
}) {
  const navigate = useNavigate();
  const location = useLocation();

  const currentPath = location?.pathname;
  const isNestedRoute = currentPath.split('/')?.length === 3;
  const nestedMainRoute = currentPath.split('/')[1];
  return (
    <ScaffoldCard className={className}>
      <div className={styles.top_header_container}>
        <header className={styles.header_container}>
          <div className={styles.title_container}>
            {isNestedRoute && (
              <ArrowBackward
                width="20px"
                height="18px"
                marginRight="8px"
                onClick={() => navigate(`/${nestedMainRoute}`)}
              />
            )}
            <h3 className={styles.title}>{title ?? ''}</h3>
          </div>

          {description && (
            <span className={styles.description}>{description}</span>
          )}
        </header>
        {quickNav && quickNavLink && (
          <div className={styles.quick_nav_container}>
            <a href={quickNavLink} className={styles.quick_nav}>
              {quickNav}
            </a>
          </div>
        )}
      </div>
      {children}
    </ScaffoldCard>
  );
}
