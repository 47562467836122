import createInstance from 'Config/AxiosConfig';
import { store } from 'redux/store';

async function makeApiCall(configurations) {
  try {
    const instance = await createInstance();
    await instance.post('save-recent-configurations', configurations);
  } catch (error) {
    console.error('Error making API call:', error);
  }
}

export async function saveConfigurations(configurations) {
  const state = store.getState();
  const active_workspace_name = state.workspace?.name;

  // FIX-ME: Optmize this API call
  await makeApiCall({ name: active_workspace_name, ...configurations });
}
