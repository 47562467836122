/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-no-useless-fragment */
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { ChatAthenaModelNameEnum } from 'libs/chat-athena-constants';
import { useContext } from 'react';
import { showNotification } from 'libs/notification';
import { SelectedImageUploadContext } from '../contexts/SelectedImageUpload';
import { SelectedModelContext } from '../contexts/SelectedModel';
import styles from './image-upload.module.css';

export const ImageUpload = () => {
  const { uploadedImages, setUploadedImages } = useContext(
    SelectedImageUploadContext,
  );

  const { modelName } = useContext(SelectedModelContext);

  const handleFileChange = (event) => {
    const { files } = event.target;
    const nonSupportedFiles = Array.from(files).find(
      (file) =>
        (modelName === ChatAthenaModelNameEnum.gemini &&
          !(
            file.type.startsWith('image/') || file.type.startsWith('video/')
          )) ||
        (modelName === ChatAthenaModelNameEnum.gpt4 &&
          !file.type.startsWith('image/')),
    );

    if (nonSupportedFiles) {
      showNotification('error', 'Media not supported !');
      setUploadedImages([]);
      return;
    }

    let totalSize = 0;

    const imagePromises = Array.from(files).map(
      (imageFile) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
          reader.readAsDataURL(imageFile);
          if (imageFile.size > 5000000) {
            showNotification(
              'error',
              'File is too big, maximum 5MB is allowed !',
            );
            setUploadedImages([]);
          }
          totalSize += imageFile.size;
        }),
    );

    if (uploadedImages && uploadedImages.length > 0) {
      Array.from(uploadedImages).map(
        (_img) => {
          const buffer = Buffer.from(_img.substring(_img.indexOf(',') + 1));
          totalSize += buffer.length;
          return true;
        },
      );
    }

    if ((imagePromises?.length + uploadedImages?.length) > 5) {
      showNotification('error', 'Maxium 5 Images are allowed');
      setUploadedImages([]);
      return;
    }

    if (totalSize > 5000000) {
      showNotification('error', 'Files are too big, maximum 5MB is allowed in total !');
      setUploadedImages([]);
      return;
    }

    Promise.all(imagePromises)
      .then((base64Images) => {
        setUploadedImages([...uploadedImages, ...base64Images]);
      })
      .catch((error) =>
        console.error('Error converting images to base64:', error),
      );
  };

  if (
    modelName === ChatAthenaModelNameEnum.gemini ||
    modelName === ChatAthenaModelNameEnum.gpt4
  ) {
    return (
      <div className={styles.icon_wrapper}>
        <label className={styles.file_input_label}>
          <AttachFileIcon
            style={{
              color: '#808080',
            }}
          />
          <input
            className={styles.file_input}
            type="file"
            onChange={handleFileChange}
            multiple
          />
        </label>
      </div>
    );
  }

  return <></>;
};
