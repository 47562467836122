import 'assets/constants.css';
import styled from 'styled-components';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

export const CompleteIcon = styled(TaskAltRoundedIcon)`
  color: #1d8102;
  width: 2rem;
  height: 2rem;
`;

export const FailIcon = styled(CancelTwoToneIcon)`
  color: #f5222d;
  width: 2rem;
  height: 2rem;
`;

export const PendingIcon = styled(PendingOutlinedIcon)`
  color: #cfbd3c;
  width: 2rem;
  height: 2rem;
`;

export const LoadingIcon = styled(PendingOutlinedIcon)`
  color: var(--primary-color);
  width: 2rem;
  height: 2rem;
`;

export const CalendarIcon = styled(CalendarMonthIcon)`
  color: var(--primary-color);
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
`;
