import React from 'react';
import { Link, useLocation, Outlet } from 'react-router-dom';
import AppHeaderWrapper from 'components/common/AppHeader/AppHeaderWrapper';
import { ScaffoldCard } from 'components/common/scaffold-card';
import { ROUTES } from 'constants/routes';
import styles from './market-message.module.css';

const MarketMessage = () => {
  const { pathname } = useLocation();

  return (
    <AppHeaderWrapper className={styles.wrapper}>
      <ScaffoldCard className={styles.container}>
        <header className={styles.header_container}>
          <h3>Market Message</h3>
          <span>
            It was popularised in the 1960s with the release of Letraset sheets
            containing Lorem
          </span>
        </header>

        <div className={styles.links_wrapper}>
          <Link
            to={ROUTES.MARKET_MESSAGE}
            className={
              pathname === ROUTES.MARKET_MESSAGE
                ? styles.link_container_active
                : styles.link_container
            }
          >
            <span className={styles.link}>Generate Blogs</span>
          </Link>

          <Link
            to={ROUTES.MARKET_MESSAGE_BLOGS}
            replace
            className={
              pathname.includes(ROUTES.MARKET_MESSAGE_BLOGS)
                ? styles.link_container_active
                : styles.link_container
            }
          >
            <span className={styles.link}>Sales CopyWriter</span>
          </Link>
        </div>
      </ScaffoldCard>
      <div className={styles.outlet_container}>
        <Outlet />
      </div>
    </AppHeaderWrapper>
  );
};

export default MarketMessage;
