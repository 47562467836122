/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { motion } from 'framer-motion';
import React from 'react';
import ReactDom from 'react-dom';
import ClearIcon from '@mui/icons-material/Clear';
import './styles.css';

function Flyout({ open, children, onClose, title, subtitle, width, customStyles }) {
  const MODAL_STYLES = {
    width: width || '70vw',
    height: '100vh',
    position: 'fixed',
    right: 0,
    top: 0,
    backgroundColor: '#FFF',
    zIndex: 1000,
  };

  const OVERLAY_STYLES = {
    position: 'fixed',
    right: 0,
    top: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(0,0,0,.7)',
    zIndex: 1000,
  };

  if (!open) return null;

  return ReactDom.createPortal(
    <div>
      <div style={OVERLAY_STYLES} onClick={onClose} />
      <motion.div
        className="flyout-container"
        initial={{ opacity: 0, x: '+100vw' }}
        exit={{ x: '+100wv' }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5, ease: 'easeInOut' }}
        style={{ ...MODAL_STYLES, ...customStyles }}
      >
        <div className="flyout-close-container h-padding-a-small">
          <h2>
            {title}{' '}
            {subtitle ? (
              <span style={{ fontSize: '14px', color: '#13d613', fontWeight: '700' }}>Beta</span>
            ) : null}
          </h2>
          <ClearIcon className="flyout-close-button" onClick={onClose} />
        </div>
        <div className="flyout-content-container">{children}</div>
      </motion.div>
    </div>,
    document.getElementById('portal'),
  );
}

export default Flyout;
