/* eslint-disable max-len */
import createInstance from 'Config/AxiosConfig';
import { useQuery } from 'react-query';
import { getReduxUserState } from 'utils/helpers/redux-selectors';

export const getWorkspaceCreationActivity = async () => {
  const { email } = getReduxUserState();

  const instance = await createInstance();
  const response = await instance.get(
    `https://apis.crawlq.ai/get-graphs-data?username=${email}&graph_name=workspace_vs_time`,
  );

  if (response && response?.data?.statusCode === '200') {
    return response?.data?.data;
  }

  throw new Error('Could not fetch Workspace Creating Heat Map');
};

// Keep Query Value as File Name to maintain consistency and uniqueness in query index
const QUERY_KEY = 'get-workspace-creation-activity';

// Query Config Options
const queryOptions = {
  staleTime: 1 * 60 * 1000,
};

// QUERY
export const useGetWorkspaceCreationActivity = () =>
  useQuery([QUERY_KEY], () => getWorkspaceCreationActivity(), queryOptions);
