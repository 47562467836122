/* eslint-disable prefer-const */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
// assets
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import HelperVideoModalPopup from 'Component/Shared/HelperVideoPopUp/HelperVideoPop';
import thunderIcon from '../../../assets/dashboard-imgs/Icon metro-power.svg';
import projectIcon from '../../../assets/dashboard-imgs/swatchbook.svg';
import teamIcon from '../../../assets/dashboard-imgs/team-users.svg';
import userIcon from '../../../assets/dashboard-imgs/user-circle.svg';

// components
import LoaderSpin from '../../Shared/Loader';
import TopNav from '../../Shared/Sidenav/Topnav';
import Topbar from '../../Shared/Topbar/Topbar';
import UpgradeEntryplan from '../../Shared/UpdagradeEntryPlan/UpgradeEntryplan';
import './dashboard.css';
import CloudBox from './DashboardComponents/CloudBox';
import DashbaordTopItem, {
  DashbaordTopItemCredits,
} from './DashboardComponents/DashboardTopItems';
import MRRLevelOnDashboard from './DashboardComponents/MRRLevelOnDashboard';
import InAppPurChase from './InAppPurchase';
import TrainAthena from './TrainAthena/TrainAthena';

// login fn
import {
  getAthenaSources,
  getCreditCount,
  getProgressPercentage,
} from './dashboardLogic';

import { userWorkspaceSetAthenaSources, workspaceSelect } from '../../../redux';
import { WORKSPACE, WORKSPACES } from 'libs/constants';
import { getMainTopicOfIntrest } from '../Workspaces/Workspace/DemoWorkspace/DemoWorkspaceClone/DemoWorkspaceCloneLogic';

function Dashboard() {
  const dispatch = useDispatch();

  // store data
  let {
    plan,
    workspace_count,
    workspace_limit,
    member_count,
    member_limit,
    admin,
    scope,
  } = useSelector((state) => state?.user);
  const activeWorkspaceName = useSelector((state) => state.workspace?.name);

  // states
  const [progressPercentage, setProgressPercentage] = useState({});
  const [UserCredit, setUserCredit] = useState(0);
  const [TotalCredit, setTotalCredit] = useState(0);
  const [topUpCredits, setTopUpCredits] = useState(0);
  const [mti, setMti] = useState('');

  // condition rendering values
  let PRIVILEGE = admin === 'True' ? 'Admin' : 'Member';

  if (scope != undefined) {
    if (scope === 'agency_client') {
      PRIVILEGE = 'Client';
      member_count = 1;
    }
    if (scope === 'agency_user') {
      PRIVILEGE = 'Team Member';
      member_count = 1;
    }
  }

  useEffect(async () => {
    getProgressPercentage().then((progressPercentage) => {
      setProgressPercentage({ ...progressPercentage });
    });

    getCredit();
    setAthenaSourcesInReduxStore();
  }, []);

  const getCredit = async () => {
    const { user_credit, total_credit, topup_credits } = await getCreditCount();
    setUserCredit(user_credit);
    setTotalCredit(total_credit);
    setTopUpCredits(topup_credits);
  };

  const setAthenaSourcesInReduxStore = async () => {
    const sources = await getAthenaSources();

    dispatch(userWorkspaceSetAthenaSources(sources));
  };

  function CurrentLocationItem({ head, currentProject }) {
    return (
      <div>
        <span className="dashboard-font font-primary">{head}</span> <br />
        <div className="d-flex flex-column">
          <span className="font-accent">
            {WORKSPACE}:&nbsp;
            <span className="font-accent txt-primary"> {currentProject}</span>
          </span>

          {/* {plan.includes('AppSumo') ? (
            <span className="offer-wrapper">
              <a
                href="https://crawlq.ai/crawlq-ltd-upgrade-plan/"
                target="_blank"
              >
                Upgrade Plan Now
              </a>
              Limited Time Offer 🎉
            </span>
          ) : null} */}
          <div className="hide-mobile">
            <HelperVideoModalPopup
              tooltipTitle={
                "Wait! No...I'm not sure! I want to spice it up a little and make it juicier"
              }
              videoLink="https://athena-helper-videos.s3.us-east-2.amazonaws.com/Option+-+4.mp4"
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {/* <TopNav /> */}
      <motion.div
        initial={{ opacity: 0, x: '-100vw' }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1, ease: 'easeIn' }}
        className="container-dashboard"
      >
        <div className="container-box">
          <div className="dashboard-top-container">
            <div className="dashboard-top-item-left">
              <CurrentLocationItem
                head="Dashboard"
                currentProject={activeWorkspaceName}
              />
            </div>

            <div className="dashboard-top-item-right">
              {TotalCredit !== 0 && (
                <DashbaordTopItemCredits
                  type="Credits"
                  value={UserCredit}
                  total={TotalCredit}
                  iconsize="35"
                  topUp={topUpCredits}
                />
              )}
              <DashbaordTopItem
                type="Plan"
                value={plan}
                icon={userIcon}
                iconsize="18"
              />
              <DashbaordTopItem
                type="Privilege"
                value={PRIVILEGE}
                icon={thunderIcon}
                iconsize="18"
              />
            </div>
          </div>

          <div className="dashboard-middle-container">
            <motion.div
              initial={{ opacity: 0, x: '-100vw' }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 1.5, delay: 0.2, ease: 'easeInOut' }}
              className="dashboard-cloud-container"
            >
              <div className="cloud_box">
                <div className="dashboard-data-box">
                  <CloudBox
                    head={WORKSPACES}
                    value={workspace_count}
                    icon={projectIcon}
                    total={workspace_limit}
                  />
                </div>
                <div className="dashboard-data-box">
                  <CloudBox
                    head="Users"
                    value={member_count}
                    icon={teamIcon}
                    total={member_limit}
                  />
                </div>
              </div>
              <div>
                <div className="dashboard-features-container">
                  <div className="dashboard-stats-items">
                    <div className="dashboard-stats-box font-secondary">
                      <div className="my-3">
                        <Link
                          to="/market-research"
                          className="font-secondary dashboard-link"
                        >
                          Market Research
                        </Link>

                        <div className="font-accent dashboard-feature-content">
                          {Object.keys(progressPercentage).length > 0 ? (
                            <MRRLevelOnDashboard
                              progressPercentage={progressPercentage}
                            />
                          ) : (
                            <LoaderSpin
                              color="#2a9df5"
                              height="50"
                              width="50"
                            />
                          )}
                        </div>
                      </div>
                      <UpgradeEntryplan />
                      <div className="my-4">
                        <Link
                          to="/chat-athena"
                          className="font-secondary dashboard-link"
                        >
                          Market Message
                        </Link>

                        <p className="font-accent font-secondary-size mr-2">
                          <a href="/chat-athena">Start Here</a>
                        </p>
                      </div>
                    </div>

                    <div className="dashboard-advertisement">
                      <InAppPurChase
                        showPopUp={UserCredit / TotalCredit > 0.9}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
            <div className="hide-mobile">
              <TrainAthena
                progressPercentage={progressPercentage.percentage}
                search_tooltip={mti}
              />
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
}
export default Dashboard;
