/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import { InfoRounded } from '@mui/icons-material';
import CustomTooltip from 'Component/Shared/CustomTooltip';
import React from 'react';
import { Div, Input } from '../CreateTemplate/styles';
import styles from './template-selector.module.css';

function TextPromptInput({ defaultValue, index, updatePrompt }) {
  return (
    <Div className={styles.container}>
      <label className="global-fc-accent mb-0 tool-tip">
        Text Prompt
        <CustomTooltip
          title="Athena will use this Text Prompt to create context for your content creation"
          arrow
          placement="top"
        >
          <InfoRounded
            style={{
              height: '24px',
            }}
          />
        </CustomTooltip>
      </label>
      <Input
        defaultValue={defaultValue}
        onBlur={(e) => updatePrompt(e, index)}
      />
    </Div>
  );
}

export default TextPromptInput;
