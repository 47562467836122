import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const Message = styled.h1`
  font-family: 'Helvetica', sans-serif;
  font-size: 24px;
  color: #333;
  text-align: left;
`;

const BrandVoiceMessage = () => (
  <Container>
    <Message>
      You haven`t created a brand voice yet. Please create one, and it will
      be showcased here
    </Message>
  </Container>
);

export default BrandVoiceMessage;
