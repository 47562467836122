import React, { useState } from 'react';
import CustomTooltip from 'Component/Shared/CustomTooltip';
import { useGetRemainingCredits } from 'queries/chat-athena/get-remaining-credits';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import InAppPurChase from 'Component/Pages/Dashboard/InAppPurchase';
import coin from 'assets/images/coins.svg';
import styles from './components-styles.module.css';

const CreditManager = ({ style }) => {
  const { data: available_credits } = useGetRemainingCredits();

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className={styles.creditContainer}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={style}
    >
      <InAppPurChase showPopUp={false} />
      <CustomTooltip title="Credits Remaining" placement="top" arrow>
        <div className={styles.creditText}>
          <img src={coin} width={'35'} alt="icon" />
        </div>
      </CustomTooltip>
      <div className={styles.creditText}>{available_credits}</div>
      {isHovered && (
        <AddCircleOutlineRoundedIcon
          onClick={() => document.getElementById('buyCreditsPopUp').click()}
        />
      )}
    </div>
  );
};

export default CreditManager;
