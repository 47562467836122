const HOME = '/home';
const DONE_FOR_YOU = '/done-for-you';
const USER_PROFILE = '/user-profile';

// #region WORKSPACE
const WORKSPACE = '/workspace';
const USER_WORKSPACE = `${WORKSPACE}/user`;
// #endregion

// #region NEW WORKSPACE
const NEW_WORKSPACE = '/new-workspace';
const NEW_WORKSPACE_CONFIG = `${NEW_WORKSPACE}/configure`;
// #endregion

// #region PERSONA ROUTES
const ALL_WORKSPACE_PERSONA_STATUS = '/all-workspace-persona-status';
const WORKSPACE_PERSONA_STATUS = '/workspace-persona-status';
// #endregion

// #region MARKET RESEARCH
const MARKET_RESEARCH = '/market-research';
const MARKET_RESEARCH_LEVEL_1 = `${MARKET_RESEARCH}/attract`;
const MARKET_RESEARCH_LEVEL_2 = `${MARKET_RESEARCH}/desire`;
const MARKET_RESEARCH_LEVEL_3 = `${MARKET_RESEARCH}/convert`;
// #endregion

// #region MARKET MESSAGE
const MARKET_MESSAGE = '/market-message';
const MARKET_MESSAGE_BLOGS = `${MARKET_MESSAGE}/sales`;
// #endregion

// #region DIGITAL ASSETS LIBRARY
const DIGITAL_ASSETS = '/digital-assets-library';
const DIGITAL_ASSETS_BLOGS = `${DIGITAL_ASSETS}`;
const DIGITAL_ASSETS_SALES = `${DIGITAL_ASSETS}/sales`;
// #endregion

// #region Manage Templates
const MANAGE_TEMPLATES = '/manage-templates';
const CREATE_TEMPLATES = `${MANAGE_TEMPLATES}/new`;
const EDIT_TEMPLATES = `${MANAGE_TEMPLATES}/edit`;
// #endregion

// #region Assistants
const ASSISTANTS = '/assistants';
const CREATE_ASSISTANTS = `${ASSISTANTS}/create`;
const EDIT_ASSISTANTS = `${ASSISTANTS}/edit`;
// #endregion

// #region Insights 360 AI
const INSIGHTS360AI = '/insights-360-ai';
// #endregion

const FORGOT_PASSWORD = '/forgot-password';

const CHAT_ATHENA_FLOW = '/chat-athena-flow';
const CHAT_ATHENA = '/chat-athena';

const PUBLIC_BLOG = '/blog';

const DASHBOARD = '/dashboard';
const PRICING = '/pricing';
const TEAM_MEMBER = '/team-member';

const REGISTER = '/sign-up';

export const ROUTES = Object.freeze({
  HOME,
  DONE_FOR_YOU,
  USER_PROFILE,
  WORKSPACE,
  USER_WORKSPACE,
  NEW_WORKSPACE,
  NEW_WORKSPACE_CONFIG,
  ALL_WORKSPACE_PERSONA_STATUS,
  WORKSPACE_PERSONA_STATUS,
  MARKET_RESEARCH,
  MARKET_RESEARCH_LEVEL_1,
  MARKET_RESEARCH_LEVEL_2,
  MARKET_RESEARCH_LEVEL_3,
  MARKET_MESSAGE,
  MARKET_MESSAGE_BLOGS,
  DIGITAL_ASSETS,
  DIGITAL_ASSETS_BLOGS,
  DIGITAL_ASSETS_SALES,
  MANAGE_TEMPLATES,
  CREATE_TEMPLATES,
  FORGOT_PASSWORD,
  EDIT_TEMPLATES,
  CHAT_ATHENA_FLOW,
  PUBLIC_BLOG,
  DASHBOARD,
  CHAT_ATHENA,
  PRICING,
  TEAM_MEMBER,
  ASSISTANTS,
  CREATE_ASSISTANTS,
  EDIT_ASSISTANTS,
  REGISTER,
  INSIGHTS360AI,
});
