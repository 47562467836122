import { useQuery } from 'react-query';
import createInstance from 'Config/AxiosConfig';
import { store } from 'redux/store';

const getUser = () => {
  const state = store.getState();
  const { user } = state;
  return user;
};

const fetchUserWorkspace = async () => {
  try {
    const { username } = getUser();
    const instance = await createInstance();
    const { data } = await instance.get(`projects-all?username=${username}`);

    if (data.statusCode === '200') {
      return data.data.data;
    }
    return [];
  } catch (ex) {
    throw new Error('Error in Display Table', ex);
  }
};

const GET_ALL_WORKSPACES_QUERY_KEY = 'get-all-user-worksapces-query-key';

// Query Config Options
const queryOptions = {
  staleTime: 10 * 60 * 1000,
  // 10 mins
};

// QUERY
export const useGetAllUsersWorkspaces = () =>
  useQuery(
    [GET_ALL_WORKSPACES_QUERY_KEY],
    () => fetchUserWorkspace(),
    queryOptions,
  );
