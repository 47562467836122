import { NoDataToDisplayIcon } from 'assets/images-v2';
import React from 'react';
import styles from './index.module.css';

function NoData({ message }) {
  return (
    <div className={styles.not_found}>
      <img src={NoDataToDisplayIcon} width={200} alt="" />
      <b style={{ fontSize: '2rem', marginTop: '1.5rem' }}>{message}</b>
    </div>
  );
}

export default NoData;
