/* eslint-disable indent */
/* eslint-disable no-use-before-define */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoaderSpin from 'Component/Shared/Loader';
import { Tooltip } from 'antd';
import { openBlog } from './helpers';
import { BLOG_PATH } from './constant';

const EnhancedTable = ({ rows, from }) => {
  const navigate = useNavigate();

  const [blogs, setBlogs] = useState([...rows]);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('blog_name');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [blogOpening, setBlogOpening] = useState(false);

  const headCells = [
    {
      id: 'blog_name',
      label: 'Blog Name',
    },
    {
      id: 'blog_published',
      label: 'Published Date',
    },
    {
      id: 'blog_readtime',
      label: 'Read Time',
    },
    {
      id: 'blog_author',
      label: 'Author',
    },
  ];

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
    setBlogs((prevBlogs) => {
      const sortedBlogs = [...prevBlogs].sort(compareSort(property, isAsc));
      return sortedBlogs;
    });
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const compareSort = () => (a, b) => {
    if (orderBy === 'blog_published') {
      const partsA = (a[orderBy] || '').split('-');
      const partsB = (b[orderBy] || '').split('-');

      const dateA = new Date(`${partsA[2]}-${partsA[1]}-${partsA[0]}`);
      const dateB = new Date(`${partsB[2]}-${partsB[1]}-${partsB[0]}`);

      if (Number.isNaN(dateA) || Number.isNaN(dateB)) {
        return 0; // Invalid date format, consider them equal
      }

      if (dateA < dateB) {
        return order === 'asc' ? -1 : 1;
      }
      if (dateA > dateB) {
        return order === 'asc' ? 1 : -1;
      }
      return 0;
    }
    if (orderBy === 'blog_readtime') {
      const numberA = Number((a[orderBy] || '').match(/\d+/));
      const numberB = Number((b[orderBy] || '').match(/\d+/));

      if (Number.isNaN(numberA) || Number.isNaN(numberB)) {
        return 0; // Invalid number format, consider them equal
      }

      if (numberA < numberB) {
        return order === 'asc' ? -1 : 1;
      }
      if (numberA > numberB) {
        return order === 'asc' ? 1 : -1;
      }
      return 0;
    }
    const lowerCaseA = String(a[orderBy] || '').toLowerCase();
    const lowerCaseB = String(b[orderBy] || '').toLowerCase();

    if (lowerCaseA < lowerCaseB) {
      return order === 'asc' ? -1 : 1;
    }
    if (lowerCaseA > lowerCaseB) {
      return order === 'asc' ? 1 : -1;
    }
    return 0;
  };

  const getPathName = async () => {
    if (from === BLOG_PATH.SALES) {
      return '/sales-ai-content-writer/editor';
    }
    return '/ai-content-writer/editor';
  };

  // const handleOnClickBlog = async (docName) => {
  //   const { data, error } = await openBlog(docName, from);

  //   if (data) {
  //     setBlogOpening(false);
  //     localStorage.setItem('questionTopic', data.goolgeQuery);
  //     localStorage.setItem('initialHeading', data.query);
  //     localStorage.setItem('context', data.goolgeQuery);
  //     localStorage.setItem('save-doc-name-sales', data.query);
  //     navigate(await getPathName(), {
  //       state: {
  //         GoogleQuery: data.goolgeQuery,
  //         query: data.query,
  //         generatedOutputs: data.output,
  //         flag: 'ai-writer',
  //         lang: data.lang,
  //         save: data.save,
  //         blogId: data.blogId,
  //       },
  //     });
  //   } else {
  //     setBlogOpening(false);
  //   }
  // };

  const handleOnClickBlog = async (id) => {
    window.open(`https://app.crawlq.ai/Blog?id=${id}`, '_blank');
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, blogs.length - page * rowsPerPage);

  const getTrimmedString = (string, length) => {
    const THREE_DOTS = 3;
    if (string.length > length + THREE_DOTS) {
      return `${string.slice(0, length)}...`;
    }
    return string;
  };

  return (
    <div className="w-100">
      <table>
        <thead>
          <tr>
            {headCells.map((headCell) => (
              <th key={headCell.id}>
                <span
                  onClick={() => handleRequestSort(headCell.id)}
                  style={{ cursor: 'pointer' }}
                  className="p-2"
                >
                  {headCell.label}
                  {orderBy === headCell.id && (
                    <span>{order === 'desc' ? ' ▼' : ' ▲'}</span>
                  )}
                </span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {blogs
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => (
              <tr key={index}>
                <td
                  style={{
                    flex: 0.5,
                    cursor: 'pointer',
                  }}
                  onClick={() => handleOnClickBlog(row?.blog_id)}
                >
                  <Tooltip
                    title={row.blog_name}
                    color="blue"
                    key={`publish-${index}`}
                    placement="bottom"
                    overlayInnerStyle={{ width: '500px' }}
                  >
                    {getTrimmedString(row.blog_name, 100)}
                  </Tooltip>
                  {blogOpening && <LoaderSpin />}
                </td>
                <td>{row.blog_published}</td>
                <td>{row.blog_readtime}</td>
                <td>{row.blog_author}</td>
              </tr>
            ))}

          {emptyRows > 0 && (
            <tr style={{ height: 53 * emptyRows }}>
              <td colSpan={3} />
            </tr>
          )}
        </tbody>
      </table>

      <div>
        <span>
          Rows per page:
          <select
            className="ml-2"
            value={rowsPerPage}
            onChange={handleChangeRowsPerPage}
          >
            {blogs.length >= 10 && <option value={10}>10</option>}
            {blogs.length >= 20 && <option value={20}>20</option>}
            {(blogs.length > 20 || blogs.length < 10) && (
              <option value={Math.max(10, Math.min(blogs.length, 20))}>
                {Math.max(10, Math.min(blogs.length, 20))}
              </option>
            )}
          </select>
        </span>
        <span style={{ marginLeft: '1rem' }}>
          Page: {page + 1} of {Math.ceil(blogs.length / rowsPerPage)}
        </span>
        <button
          disabled={page === 0}
          onClick={() => handleChangePage(page - 1)}
          style={{ marginLeft: '1rem' }}
          className="btn-outline-primary"
        >
          Previous
        </button>
        <button
          disabled={page >= Math.ceil(blogs.length / rowsPerPage) - 1}
          onClick={() => handleChangePage(page + 1)}
          style={{ marginLeft: '0.5rem' }}
          className="btn-outline-primary"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default EnhancedTable;
