/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import React, { useState, useEffect, useCallback } from 'react';
import { PublishedArticlesWrapper, TagStatus } from './components/styles';
import ArticleCard from './components/ArticleCard';
import EnchancedTable from './components/EnchancedTable';
import { ARTICLES_STATUS, BLOG_PATH } from './components/constant';
import {
  deleteBlog,
  getSavedBlogs,
  sortBlogsByDate,
} from './components/helpers';
import { LoaderTailSpin } from 'Component/Shared/Loader';
import CalenderComp from './CalenderModel';
import TabSwitcher from 'Component/Shared/TabSwitcher/TabSwitcher';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Flex } from 'Component/Shared/GlobalStyles';

const label = { inputProps: { 'aria-label': 'Size switch demo' } };

function PublishedArticles({ from }) {
  const [ChoosenArticleStatus, setChoosenArticleStatus] = useState(
    ARTICLES_STATUS[0],
  );
  const [Blogs, setBlogs] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [LoadingBlogs, setLoadingBlogs] = useState(false);
  const [showCardVeiw, setShowCardVeiw] = useState('list');

  useEffect(() => {
    LoadBlogs();
    handleCurrentTab();
  }, []);

  const handleCurrentTab = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const selectedTab = searchParams.get('selectedTab');
    if (selectedTab === 'allAssets') {
      setActiveTab(1);
    }
  };

  const LoadBlogs = async () => {
    setLoadingBlogs(true);
    const { error, data } = await getSavedBlogs(from);

    if (!error) {
      const sortedBlogs = sortBlogsByDate(data);
      setBlogs(sortedBlogs);
      setLoadingBlogs(false);
    }
    setLoadingBlogs(false);
  };

  const handleDeleteBlog = async (blogName, from) => {
    const isFromSales = from === BLOG_PATH.SALES;
    const response = await deleteBlog(blogName, isFromSales);

    if (response) {
      const filteredBlogs = Blogs.filter((blog) => blog.blog_name !== blogName);
      setBlogs(filteredBlogs);
    }
  };

  const StatusTab = () =>
    ARTICLES_STATUS.map((status, index) => (
      <TagStatus
        active={ChoosenArticleStatus === status ? 'active-tag' : ''}
        key={index}
        onClick={() => setChoosenArticleStatus(status)}
      >
        {status}
      </TagStatus>
    ));

  if (LoadingBlogs) {
    return (
      <div className="h-margin-t-xlarge h-padding-a-small">
        <LoaderTailSpin />
      </div>
    );
  }
  if (Blogs.length === 0) {
    return (
      <div className="h-margin-t-xlarge h-padding-a-small">
        No Blogs Published
      </div>
    );
  }

  return (
    <div className="h-margin-t-xlarge h-padding-a-small">
      <div className="h-padding-b-large">
        <TabSwitcher
          tab1="Editorial Calendar"
          tab2="All Assets"
          setSwitcher={setActiveTab}
          currentActiveTab={activeTab}
          style={{ width: '20rem' }}
        />
      </div>
      {activeTab === 1 && (
        <Flex justify={'center'}>
          <ToggleButtonGroup
            orientation="horizontal"
            value={showCardVeiw}
            exclusive
            onChange={(e, val) => setShowCardVeiw(val)}
          >
            <ToggleButton value="list" aria-label="list">
              <ViewListIcon />
            </ToggleButton>
            <ToggleButton value="card" aria-label="module">
              <ViewModuleIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Flex>
      )}
      <PublishedArticlesWrapper className="h-margin-t-x2">
        {activeTab === 0 && (
          <CalenderComp
            blogs={Blogs}
            from={from}
            handleDeleteArticle={handleDeleteBlog}
          />
        )}

        {activeTab === 1 &&
          Blogs &&
          (showCardVeiw === 'card' ? (
            Blogs.map((article, index) => (
              <ArticleCard
                from={from}
                blogTime={article.blog_readtime}
                blogName={article.blog_name}
                blogImage={article.image_url || article.blog_image}
                blogAuthor={article.blog_author}
                blogScript={article.blog_script}
                handleDelete={handleDeleteBlog}
                id={article.blog_id}
                key={index}
              />
            ))
          ) : (
            <EnchancedTable rows={Blogs} from={from} />
          ))}
      </PublishedArticlesWrapper>
    </div>
  );
}

export default PublishedArticles;
