/* eslint-disable max-len */
/* eslint-disable prefer-template */
export const formatForCSV = (category, questionAnswersObject) => {
  const exportedFileName = `${category}.csv` || 'export.csv';

  const headers = ['Questions', 'Answers'];

  const structuredQuestionAnswers = [];

  structuredQuestionAnswers.push(headers);

  // example : [ ['Niche','A brand consultant'], ['Sub-Niche','Brand Consultants'], [],..]
  const questionAnswersArray = Object.entries(questionAnswersObject);

  // example : [['Questions', 'Answers'],['Niche','A brand consultant'], ['Sub-Niche','Brand Consultants'], [],]
  questionAnswersArray.forEach((questionAnswer) =>
    structuredQuestionAnswers.push(questionAnswer),
  );

  let csvContent = '';
  structuredQuestionAnswers.forEach((questionAnswer) => {
    csvContent += questionAnswer.join(',') + '\n';
  });

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8,' });
  const objUrl = URL.createObjectURL(blob);

  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFileName);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      link.setAttribute('href', objUrl);
      link.setAttribute('download', exportedFileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
