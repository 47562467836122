import 'assets/constants.css';
import styled from 'styled-components';

export const CloudBox = styled.div`
    width: fit-content;
    background-color: var(--primary-backdrop-color);
    border-radius: var(--card-border-radius);
    color: black;
    padding: 0.2rem 0.4rem;
    margin-right: 0.2rem;
`;
