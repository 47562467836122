import { useQuery } from 'react-query';
import createInstance from 'Config/AxiosConfig';

const getUsersAddOns = async () => {
  const instance = await createInstance();
  const { data } = await instance.post('/addon-status');
  if (data.statusCode !== '200') {
    throw new Error('Error getting AddOn data');
  }

  return data.data.add_on_details;
};
const USERS_ADDONS_QUERY_KEY = 'get-users-addons';

// Query Config Options
const queryOptions = {
  staleTime: 90000,
};

export const useGetUsersAddOns = () => {
  const queryKey = [USERS_ADDONS_QUERY_KEY];
  return useQuery(queryKey, getUsersAddOns, queryOptions);
};
