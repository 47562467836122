/* eslint-disable max-len */
import { getReduxUserState, getReduxWorkspaceState } from 'utils/helpers/redux-selectors';
import { useQuery } from 'react-query';
import createInstance from 'Config/AxiosConfig';
import { toast } from 'react-toastify';

export const getHeadlineIntros = async (topic) => {
  const { email } = getReduxUserState();
  const { name } = getReduxWorkspaceState();

  const payload = {
    name,
    username: email,
    query: topic,
  };

  const instance = await createInstance();
  const { data } = await instance.post('generate-paragraph', payload);

  if (data?.statusCode === '200') {
    return data?.data;
  }

  throw new Error('Unable to fetch Headline Intros');
};

// Keep Query Value as File Name to maintain consistency and uniqueness in query index
const QUERY_KEY = 'get-headline-intros';

// Query Config Options
const queryOptions = {
  cacheTime: 100,
  enabled: false,
  retry: false,
};

// QUERY
export const useGetHeadlineIntros = (topic) =>
  useQuery([QUERY_KEY, topic], () => getHeadlineIntros(topic), queryOptions);
