import LoaderSpin from 'Component/Shared/Loader';
import { useGetAllFiveTranApps } from 'queries/chat-athena/fivetran/get-all-fivetran-apps';
import React, { useState } from 'react';
import AddTaskRoundedIcon from '@mui/icons-material/AddTaskRounded';
import { SearchInput } from 'components/global/SearchInput';
import styles from './index.module.css';
import { CreateFivetranConnector } from './CreateFivetranConnector';

export const ListFiveTranApps = ({ setTab }) => {
  const {
    data: fivetranApps,
    isLoading,
    isError,
    error,
    isFetching,
  } = useGetAllFiveTranApps();

  const [selectedApp, setSelectedApp] = useState(null);
  const [searchKey, setSearchKey] = useState('');

  const handleSelectApp = (app) => {
    setSelectedApp(app);
  };

  const resetSelectedApp = () => {
    setSelectedApp(null);
  };

  if (isLoading || isFetching) {
    return (
      <div className={styles.parent_loader}>
        <LoaderSpin />
        <p>Loading Available Apps...</p>
      </div>
    );
  }

  if (fivetranApps?.length === 0) {
    return null;
  }

  return (
    <div className={styles.all_apps}>
      {!selectedApp && (
        <>
          <div className={styles.stats_bar}>
            <b>{fivetranApps?.length} apps available</b>
            <SearchInput
              value={searchKey}
              onChange={(e) => setSearchKey(e.target.value)}
            />
          </div>
          <div className={styles.apps_list_wapper}>
            {fivetranApps
              ?.filter((app) =>
                app.name?.toLowerCase().includes(searchKey?.toLowerCase()),
              )
              .map((app) => (
                <div className={styles.app_item_wapper} key={app.id}>
                  <div className={styles.app_item_left}>
                    <img
                      width={'30px'}
                      height={'30px'}
                      src={app.icon_url}
                      alt={app.name}
                    />
                    <p>{app.name}</p>
                  </div>
                  <div
                    className={styles.connect_button}
                    onClick={() => handleSelectApp(app)}
                  >
                    <AddTaskRoundedIcon />
                    <p>Connect </p>
                  </div>
                </div>
              ))}
          </div>
          {isError && (
            <p className="text-danger">Error in Loading App: {error}</p>
          )}
        </>
      )}
      {selectedApp && (
        <CreateFivetranConnector
          app={selectedApp}
          resetSelectedApp={resetSelectedApp}
          setTab={setTab}
        />
      )}
    </div>
  );
};
