import { useMutation, useQueryClient } from 'react-query';
import {
  getReduxUserState,
  getReduxWorkspaceState,
} from 'utils/helpers/redux-selectors';
import createInstance from 'Config/AxiosConfig';
import { toast } from 'react-toastify';

const fillWithAI = async ({ formattedQuestionAnswer, categoryName }) => {
  const { email } = getReduxUserState();
  const { name } = getReduxWorkspaceState();
  const payload = {
    username: email,
    name,
    mrr_data: formattedQuestionAnswer,
    subcategory: categoryName,
  };

  const instance = await createInstance();

  const response = await instance.post('mrr-ai-fill', payload);

  if (response?.data?.statusCode === '200') {
    return response?.data?.script_output;
  }

  throw new Error('Market Research data failed to fill, Try again !');
};

export const useMutateFillWithAI = () =>
  useMutation(fillWithAI, {
    onSuccess: (data) => {
      // toast.success('Sucessfully Saved', {
      //   position: 'bottom-center',
      //   theme: 'dark',
      // });
    },
    onError: (error) => {
      toast.error(`${error.message}`, {
        position: 'bottom-center',
        theme: 'dark',
      });
    },
    onMutate: () => {
      toast.info('Filling with A.I', {
        position: 'bottom-center',
        theme: 'dark',
      });
    },
  });
