/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import BaseLayout from 'Component/Shared/BaseLayout/BaseLayout';
import { Button } from 'Component/Shared/ButtonNext';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Description, Header, NotFoundWrapper } from './Components/style';

export default function NotFound() {
  const navigate = useNavigate();
  return (
    <BaseLayout>
      <NotFoundWrapper>
        <Header>404</Header>
        <h1>PAGE NOT FOUND </h1>
        <Description>
          THE PAGE YOU WERE LOOKING FOR COULD NOT BE FOUND
        </Description>
        <Button
          name="Go Back"
          onClick={() => {
            navigate(-1);
          }}
        />
      </NotFoundWrapper>
    </BaseLayout>
  );
}
