import React from 'react';
import RecentWorkspaces from './RecentWorkspace/RecentWorkspace';

const RecentWorkspace = ({ onClose }) => (
  <div className="quick_access_container">
    <RecentWorkspaces onClose={onClose} />
  </div>
);

export default RecentWorkspace;
