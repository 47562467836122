/* eslint-disable react/react-in-jsx-scope */
import MarketCardResearchInfoCard from '../dashboard-elements/MarketResearchInfoCard/MarketResearchInfoCard';
import ProfileCard from '../dashboard-elements/ProfileCard/ProfileCard';
import styles from './dashboard-left.module.css';

function DashboardLeft() {
  return (
    <div className={styles.dashboard_left_container}>
      <ProfileCard />
      <MarketCardResearchInfoCard />
    </div>
  );
}

export default DashboardLeft;
