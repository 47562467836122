/* eslint-disable max-len */
import createInstance from 'Config/AxiosConfig';
import { useQuery, useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { getReduxUserState, getReduxWorkspaceState } from 'utils/helpers/redux-selectors';

export const getEmpathyScore = async (user_term, user_country) => {
  const { email } = getReduxUserState();
  const { name } = getReduxWorkspaceState();
  const iframe = document.querySelector('#texteditor_ifr');
  const editorContent = iframe.contentWindow.document.getElementById('tinymce').innerText;

  const payload = {
    username: email,
    name,
    document: editorContent };

  const instance = await createInstance();
  const { data } = await instance.post('document-similarity-score', payload);

  if (data?.statusCode === 200) {
    return data;
  }

  throw new Error('Unable to fetch Empathy Score');
};

// Keep Query Value as File Name to maintain consistency and uniqueness in query index
const QUERY_KEY = 'get-empathy-score';

// Query Config Options
const queryOptions = {
  staleTime: 10000,
  cacheTime: 10000,
};

// QUERY
export const useGetEmpathyScore = () =>
  useQuery([QUERY_KEY], () => getEmpathyScore(), queryOptions);
