import React, { useState } from 'react';
import Modal from 'react-modal';
import SemanticEngine from '../../Content Automation/AIContentWriter/SemanticSearch/semantic_engine';

const customStyles = {
  height: '700px',
  top: '50%',
  left: '50%',
  right: 'auto',
  bottom: 'auto',
  marginRight: '-50%',
  transform: 'translate(-50%, -50%)',
  width: '85vw',
  maxWidth: '1200px',
  maxHeight: '85vh',
};

const defaultProps = {
  query: '',
  source: [
    {
      key: 'mrr',
      value: 'Market Research',
      checked: false,
    },
    {
      key: 'mrr_insight',
      value: 'Market Insights',
      checked: false,
    },
    {
      key: 'script',
      value: 'Sales Copywriting',
      checked: false,
    },
    {
      key: 'chat_athena',
      value: 'Chat Athena',
      checked: true,
    },
    {
      key: 'serp',
      value: 'Google',
      checked: false,
    },
    {
      key: 'quora',
      value: 'Quora',
      checked: false,
    },
    {
      key: 'reddit',
      value: 'Reddit',
      checked: false,
    },
    {
      key: 'website',
      value: 'URL',
      checked: false,
    },
  ],
};

function SearchPrompts({ darkMode, show, closeModal: onCloseModal }) {
  const [modalOpen, setModalOpen] = useState(show);

  const closeModal = () => {
    setModalOpen(false);
    onCloseModal();
  };

  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={closeModal}
      contentLabel="Search Prompts Modal"
      style={{
        content: {
          ...customStyles,
          backgroundColor: darkMode ? '#343641' : 'white',
          color: darkMode ? 'white' : '343641',
        },
      }}
    >
      <SemanticEngine
        query={defaultProps.query}
        source={defaultProps.source}
        darkMode={darkMode}
      />
    </Modal>
  );
}

export default SearchPrompts;
