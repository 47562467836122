/* eslint-disable camelcase */
/* eslint-disable import/no-unresolved */

import { getTranslatedText } from 'Component/Pages/MarketReasearch/MarketResearchAndResonance/MarketResearchCategory/fILLAiLangTrans';
import createInstance from 'Config/AxiosConfig';
import { store } from 'redux/store';

const getReduxActiveWorkspaceName = () => {
  const state = store.getState();
  const active_workspace = state.workspace;
  return active_workspace.name;
};

const getPainBenefitStory = async () => {
  try {
    const body = { name: getReduxActiveWorkspaceName() };
    const instance = await createInstance();
    const { data } = await instance.post('create-story-pain-benefit', body);
    const story = await getTranslatedText(data.data);
    return {
      data: story,
      error: false,
    };
  } catch (ex) {
    console.log('Error in gettting Product Story', ex);
    return {
      data: null,
      error: true,
    };
  }
};

export default getPainBenefitStory;
