import createInstance from 'Config/AxiosConfig';
import { useQuery } from 'react-query';
import {
  getReduxUserState,
  getReduxWorkspaceState,
} from 'utils/helpers/redux-selectors';

export const getPhotosFromPexel = async () => {
  const PEXEL_AUTH_KEY =
    'yuTdazo6oCYLuvLfoaRDyGqPw8iLUW1XyBodCH0NAixTXnWLbIaX3w65';
  const orientation = 'portait';
  try {
    const mti = getReduxWorkspaceState()?.mti || getReduxWorkspaceState()?.name;
    const res = await fetch(
      `https://api.pexels.com/v1/search?query=${mti}&orientation=${orientation}&per_page=${80}`,
      {
        headers: {
          Authorization: PEXEL_AUTH_KEY,
        },
      },
    );
    const data = await res.json();
    const photos = data.photos.map((photo) => photo.src.tiny);
    return photos;
  } catch (ex) {
    console.log('Cannot Load photo from pexel');
    return [];
  }
};

export const getSavedBlogs = async (from) => {
  const body = {
    username: getReduxUserState()?.username,
    name: getReduxWorkspaceState()?.name,
    source: from,
  };
  const instance = await createInstance();
  const fetchBlogsPromise = await instance.post('ai-writer-select', body);

  const fetchPhotosPromise = getPhotosFromPexel();

  const [savedBlogs, imageResult] = await Promise.all([
    fetchBlogsPromise,
    fetchPhotosPromise,
  ]);

  if (savedBlogs.data.statusCode !== '200') {
    throw new Error('Unable to fetch Questions, please try opening in new tab.');
  }

  return {
    savedBlogs: savedBlogs.data.blog,
    imageResult,
  };
};

// Keep Query Value as File Name to maintain consistency and uniqueness
const QUERY_KEY = 'get-save-blogs';

// Query Config Options
const queryOptions = {
  staleTime: 30 * 1000,
};

// QUERY
export const useGetSavedBlogs = (email, from) =>
  useQuery([QUERY_KEY, from], () => getSavedBlogs(from), queryOptions);
