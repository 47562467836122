/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
import { getTranslatedTextArrayEnglish } from './fILLAiLangTrans';

export const modelClearAnswer = (responseData) => {
  let InputData = {};
  for (let i = 0; i < responseData.length; i++) {
    InputData = { ...InputData, [responseData[i].question]: '' };
  }

  return InputData;
};

export const modelExampleAnswer = (responseData) => {
  let InputData = {};
  for (let i = 0; i < responseData.length; i++) {
    InputData = {
      ...InputData,
      [responseData[i].question]: responseData[i]['Example Input'],
    };
  }

  return InputData;
};

export const convertAnswerIntoPostFormat = async (SubCategoryAnswers) => {
  let finalOutput = [];

  let keys = [];
  let values = [];
  for (const [key, value] of Object.entries(SubCategoryAnswers)) {
    keys = [...keys, key];
    values = [...values, value];
  }

  const keysTranslated = [...keys];
  const valuesTranslated = await getTranslatedTextArrayEnglish(values);

  for (let index = 0; index < keysTranslated.length; index++) {
    finalOutput = [
      ...finalOutput,
      {
        question: keysTranslated[index],
        answer: valuesTranslated[index],
      },
    ];
  }

  return finalOutput;
};
