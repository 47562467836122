import createInstance from 'Config/AxiosConfig.js';
import { store } from 'redux/store';

export async function deleteBlog(blogName) {
  const state = store.getState();
  const { username } = state.user;
  const { name } = state.workspace;
  try {
    const instance = await createInstance();

    const body = {
      username,
      name,
      blog_name: blogName,
      source: '',
    };

    const { data } = await instance.post('/ai-writer-delete', body);
    if (data.statusCode === '200') {
      return true;
    }
    return false;
  } catch (error) {
    console.log(error);
    return false;
  }
}
