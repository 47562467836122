module.exports = {
  displyProject: ' https://api.crawlq.ai/projects/',
  // displyProject:"http://ec2-18-224-182-202.us-east-2.compute.amazonaws.com:9000/projects/",
  createProject: ' https://api.crawlq.ai/project/create',
  // createProject:" http://ec2-18-224-182-202.us-east-2.compute.amazonaws.com:9000/project/create",
  editProject: ' https://apis.crawlq.ai/edit-project',
  deleteProject: ' https://apis.crawlq.ai/delete-project',
  recentlyUsed: ' https://api.crawlq.ai/recently_used',
  selectProject: ' https://api.crawlq.ai/projects/to-do/',
  gethistory: ' https://api.crawlq.ai/history',
  getAllTask: ' https://api.crawlq.ai/projects/to-do/all',
  getInProgressTask: ' https://api.crawlq.ai/projects/to-do/in-progress',
  getPendingTask: ' https://api.crawlq.ai/projects/to-do/pending',
  getCompletedTask: ' https://api.crawlq.ai/projects/to-do/completed',
  changeStatus: ' https://api.crawlq.ai/projects/to-do/update/',
  // mrr_data_fill_with_ai: 'https://api.crawlq.ai/mrr_fill_with_ai',
  // mrr_data_fill_with_ai: 'https://apis.crawlq.ai/mrr-ai-fill',
  mrr_data_fill_with_ai: 'https://apis.crawlq.ai/mrr-ai-fill',
  mrr_data_generate_insights: 'https://apis.crawlq.ai/mrr-insights',
  // MRR Lambda endpoints
  marketResearchSubCategory: 'https://apis.crawlq.ai/subcategory',
  marketResearchQuestion: 'https://apis.crawlq.ai/load-questions',
  marketResearchDescription: 'https://apis.crawlq.ai/helper',
  marketResearchSave: 'https://apis.crawlq.ai/save-questions',

  marketSpyingResearch: ' https://apis.crawlq.ai/spying-wizard',
  // make change call.
  // marketResearch: " http://ec2-3-18-101-229.us-east-2.compute.amazonaws.com:9000/Market-Research",
  marketResearch: 'https://api.crawlq.ai/Market-Research',
  marketResearchSaveInsights: 'https://apis.crawlq.ai/mrr-insights-save',
  marketResearchAllQuestions:
    'https://api.crawlq.ai/Market-Research/load/questions/all',
  // marketResearchData:" https://api.crawlq.ai/Market-Research/data",
  // marketResearchSubCategory:" https://api.crawlq.ai/Market-Research/load/sub-category/data",
  // marketResearchSubCategory: " http://ec2-3-18-101-229.us-east-2.compute.amazonaws.com:9000/Market-Research/select/subcategory",

  // marketResearchSubCategory: "https://api.crawlq.ai/Market-Research/select/subcategory",

  // marketResearchSubCategory:"http://ec2-18-224-182-202.us-east-2.compute.amazonaws.com:9000/Market-Research/load/sub-category/data",
  // marketResearchQuestion:" https://api.crawlq.ai/Market-Research/load/questions/data",
  // marketResearchQuestion: "http://ec2-3-18-101-229.us-east-2.compute.amazonaws.com:9000/Market-Research/load/questions",

  //  marketResearchQuestion:"http://ec2-18-224-182-202.us-east-2.compute.amazonaws.com:9000/Market-Research/load/questions/data",
  // marketResearchDiscription: " https://api.crawlq.ai/Market-Research/load/sub-category/description",
  // marketResearchDiscription: " http://ec2-3-18-101-229.us-east-2.compute.amazonaws.com:9000/Market-Research/helper_education",

  // marketResearchDiscription:"http://ec2-18-224-182-202.us-east-2.compute.amazonaws.com:9000/Market-Research/load/sub-category/description",
  // marketResearchSave:" https://api.crawlq.ai/Market-Research/save/sub-category",
  // marketResearchSave: " http://ec2-3-18-101-229.us-east-2.compute.amazonaws.com:9000/Market-Research/save/questions",
  // marketResearchSave:"http://ec2-18-224-182-202.us-east-2.compute.amazonaws.com:9000/Market-Research/save/sub-category",

  contextualcomparisonwizard:
    ' https://api.crawlq.ai/contextual-comparison-wizard/',
  // salesCopywritingWizard:
  //   'https://apis.crawlq.ai/select-script',
  salesCopywritingWizard: 'https://apis.crawlq.ai/select-script',
  // salesCopywritingWizard:" https://api.crawlq.ai/Sales-Copywriting-Wizard/select",
  contetentRewritter: ' https://api.crawlq.ai/ai-content-rewriter',
  loadScriptTemplate:
    ' https://api.crawlq.ai/Sales-Copywriting-Wizard/load-script/',
  autoSuggestioin: 'https://api.crawlq.ai/content-suggestion',
  displayBacklists: 'https://api.crawlq.ai/load-backlinks',
  topicClustersApi: 'https://api.crawlq.ai/topic_authority_clusters',
  competitorAnalysisWizardApi:
    'https://api.crawlq.ai/competitor-analysis/keyword',
  dominantTopicsapi: 'https://api.crawlq.ai/dominant_topic_discovery',
  // TopicClustersApi:"https://18.224.182.202:9000/topic_authority_clusters",
  topicKeywordsApi: 'https://api.crawlq.ai/topic_keywords',
  aiContentData: 'https://api.crawlq.ai/v1/get-ai-content/',
  paraphraser: 'https://api.crawlq.ai/v1/paraphase/',
  generateContent: 'https://api.crawlq.ai/v1/generate-text/',
  emailExport: 'https://api.crawlq.ai/export-as-email/',
  trackProject: 'https://api.crawlq.ai/project',
  textSummarizeprocess: 'https://api.crawlq.ai/text-summarizer/process/',
  performanceSeo: 'https://api.crawlq.ai/performance-SEO',
  loadNotes: 'https://api.crawlq.ai/notes/load/all',
  createNotes: 'https://api.crawlq.ai/notes/save/all',
  baseAPIsUrl: 'https://apis.crawlq.ai', // this is lamda server
  // baseAPIsUrl: 'https://p8dapk4yf9.execute-api.us-east-2.amazonaws.com/devel',//this is devel
  awsAPIUrl: 'https://api.crawlq.ai', // this is normal server
  devAPIUrl: 'https://p8dapk4yf9.execute-api.us-east-2.amazonaws.com/devel',
};
// export default module
