import { COUNTRIES_LIST } from 'constants/index';
import React, { useState } from 'react';
import { useGetSalesCategoryList } from 'queries/market-message/get-sales-category-list';
import CategoryListFetchingLoading from 'components/skeleton/market-message/CategoryListFetchLoading';
import { ScaffoldCard } from 'components/common/scaffold-card';
import market_message_common_styles from '../market-message.module.css';
import styles from './sales-blog.module.css';

const SalesBlogs = () => {
  const { data: categoryList, isLoading, error } = useGetSalesCategoryList();

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [blogName, setBlogName] = useState('');
  const [initialContentIdea, setInitialContentIdea] = useState('');
  const [country, setCountry] = useState('');

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleSubCategoryChange = (event) => {
    setSelectedSubCategory(event.target.value);
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  const handleClickGenerate = () => {
    console.log(
      selectedCategory,
      selectedSubCategory,
      blogName,
      initialContentIdea,
      country,
    );
  };

  return (
    <div className={market_message_common_styles.child_wrapper}>
      <div className={market_message_common_styles.left_child}>
        <ScaffoldCard className={styles.page_wrapper}>
          {isLoading && <CategoryListFetchingLoading />}
          {!isLoading && (
            <>
              <div className={styles.input_group_wrapper}>
                <div className={styles.input_wrapper}>
                  <span>Category</span>
                  <select onChange={handleCategoryChange}>
                    {Object.keys(categoryList).map((category) => (
                      <option value={category}>{category}</option>
                    ))}
                  </select>
                </div>

                <div className={styles.input_wrapper}>
                  <span>Sub category</span>
                  <select onChange={handleSubCategoryChange}>
                    {selectedCategory &&
                      categoryList[selectedCategory].map((category) => (
                        <option
                          key={category.script_id}
                          value={category.script_id}
                        >
                          {category.script_name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className={styles.input_group_wrapper}>
                <div className={styles.input_wrapper}>
                  <span>Blog Name</span>
                  <input
                    className={styles.text_input}
                    type="text"
                    value={blogName}
                    onChange={(event) => setBlogName(event.target.value)}
                  />
                </div>
                <div className={styles.input_wrapper}>
                  <span>Initial Content Idea</span>
                  <input
                    className={styles.text_input}
                    type="text"
                    value={initialContentIdea}
                    onChange={(event) =>
                      setInitialContentIdea(event.target.value)
                    }
                  />
                </div>
                <div className={styles.input_wrapper}>
                  <span>Country</span>

                  <select onChange={handleCountryChange}>
                    {COUNTRIES_LIST.map((country) => (
                      <option key={country.name} value={country.name}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className={styles.input_group_wrapper}>
                <button
                  className={styles.input_wrapper}
                  type="button"
                  onClick={handleClickGenerate}
                >
                  Generate
                </button>
              </div>
            </>
          )}
        </ScaffoldCard>
      </div>
      <aside className={market_message_common_styles.aside_wrapper}>
        {/* {Array(5).fill(null).map((index) => (
          <ScaffoldCard className="market-research-info-container" key={index}>
            <div>Cars</div>
          </ScaffoldCard>
        ))} */}
      </aside>
    </div>
  );
};

export default SalesBlogs;
