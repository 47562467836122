/* eslint-disable camelcase */
/* eslint-disable import/no-unresolved */

import createInstance from 'Config/AxiosConfig';
import { getTranslatedTextEnglish } from 'Component/Shared/TranshelperStatic';

const getTLDR = async () => {
  const iframe = document.querySelector('#texteditor_ifr');
  const result_document = iframe.contentWindow.document.getElementById(
    'tinymce',
  ).innerText;

  const converted_result = await getTranslatedTextEnglish(result_document);

  const instance = await createInstance();

  const response = await instance.post('get-tldr', { text: converted_result });

  if (response.data.statusCode === '200') {
    if (response.data.answer !== '') {
      return ({
        success: true,
        data: response.data.answer,
      });
    }

    return ({
      success: false,
      data: null,
    });
  }
  return ({
    success: false,
    data: null,
  });
};

export default getTLDR;
