import styled from 'styled-components';

export const NotFoundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Header = styled.h1`
  font-size: 45px !important;
  line-height: 60px;
  font-weight: 600;
`;

export const Description = styled.p`
  font-size: 15px;
  line-height: 27px;
  font-weight: 400;
  color: #8492ae;
`;
