/* eslint-disable indent */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import { getDestinationForLevel } from 'Component/Pages/Dashboard/DashboardComponents/helper';
import createInstance from 'Config/AxiosConfig';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Loader from 'Component/Shared/Loader';
import Topnav from 'Component/Shared/Sidenav/Topnav';
import Topbar from 'Component/Shared/Topbar/Topbar';
import UpgradeEntryplan from 'Component/Shared/UpdagradeEntryPlan/UpgradeEntryplan';
import './MarketResearchAndResonance.css';
import OnBoardingQueLevel2 from 'Component/Pages/Workspaces/onBoarding/onBoardingQueLeveTwo';
import OnBoardingQueLevel3 from 'Component/Pages/Workspaces/onBoarding/onBoardingQueLevelThree';
import LockContentAutomation from 'Component/Pages/ContentAutomation/Shared/LockContentAutomation.js';
import LevelButtons from './LevelsButton';
import Level3Container from './Level3';
import MRR_TabSwitcher from 'Component/Shared/TabSwitcher/ThreeTabMRR';

const LEVELS_DESCRIPTION = {
  level1:
    '<p>Use CrawlQ to discover your FRED using:</p>\n          <ul>\n          <li>Demographics\n          </li>\n          <li>Semantics</li>\n          <li>Psychographics</li>\n          </ul>',
  level2:
    "<p>Use CrawlQ to discover your FRED using:</p>\n        <ul>\n        <li>Your product's emotion hook\n        </li>\n        <li>YouTube channels, Facebook groups your niche browses\n        </li>\n        <li>Dreams, Failures, Fears, Suspicions and Enemies of your niche.\n        </li>\n        </ul>",
  level3:
    '<p>Use CrawlQ to discover your FRED using:</p>\n        <ul>\n        <li>Current Environments and Trends\n        </li>\n        <li>Activities and UUIs\n        <li>Influencer Endorsements\n        </li>\n        <li>Founder Grounding Stories and many more\n        </li>\n        </ul>',
};

function Marketresearchandresonace(props) {
  const navigate = useNavigate();
  const [curentLoadingLevel, setCurentLoadingLevel] = useState(0); // 0 => not Loading
  const [isLoadingCategories, setIsLoadingCategories] = useState(true);
  const [level, setLevel] = useState(0);
  const [progressPercentage, setProgressPercentage] = useState({});
  const [mrrqna, setMrrqna] = useState({ isLoaded: false, data: {} });

  function NavFeatureItems({ feature, url, isActive }) {
    return (
      <span
        onClick={() => navigate(`${url}`, { replace: true })}
        className={`font-accent ${
          isActive ? 'mrr-navfeature-active' : 'mrr-navfeature'
        }`}
      >
        {feature}
      </span>
    );
  }

  const { plan, username } = useSelector((state) => state.user);
  const { name, is_trained: isWorkspaceTrained } = useSelector(
    (state) => state.workspace,
  );

  const hasFeatureLocked = () => {
    if (
      plan !== 'CrawlQ Entry Plan (Monthly)' &&
      plan !== 'CrawlQ Entry Plan (Yearly)'
    ) {
      return false;
    }
    return true;
  };

  const isLevelOneCompleted = () => progressPercentage?.percentage === 100;

  const isLevelTwoCompleted = () =>
    isLevelOneCompleted() && progressPercentage?.percentage2 === 100;

  const isLevelThreeCompleted = () => progressPercentage.percentage3 === 100;

  const handleLevelOne = () => {
    navigate('/MRR-Get-Started');
  };

  const handleLevelTwo = async () => {
    if (!isWorkspaceTrained) {
      document.getElementById('open-lockmodal-content-Automation').click();
      return;
    }

    if (hasFeatureLocked()) {
      document.getElementById('upgradePlan-popup').click();
      return;
    }
    if (!isLevelOneCompleted()) return;
    setCurentLoadingLevel(2);
    const {
      destination,
      mrr_filled,
      error,
      showPopup,
    } = await getDestinationForLevel(2);
    setCurentLoadingLevel(0);
    if (error) return;
    setMrrqna({ isLoaded: false, data: mrr_filled });
    if (showPopup) {
      const btn = document.getElementById('onBoarding_level_2_form');
      btn.click();
      return;
    }

    localStorage.setItem('category', 'Niche');
    navigate(destination);
  };

  const handleLevelThree = async () => {
    if (hasFeatureLocked()) {
      document.getElementById('upgradePlan-popup').click();
      return;
    }
    if (!isLevelTwoCompleted()) return;

    setCurentLoadingLevel(3);
    const { error, showPopup } = await getDestinationForLevel(3);
    setCurentLoadingLevel(0);
    if (error) return;
    if (showPopup) {
      const btn = document.getElementById('onBoarding_level_3_form');
      btn.click();
      return;
    }
    navigate('/Market-Resonance-Level-3', { state: { level: 3 } });
  };

  const getProgressPercentage = async () => {
    try {
      const body = {
        name,
        username,
      };
      const instance = await createInstance();
      const result = await instance.post('dashboard-mrr-progress', body);
      setProgressPercentage(result.data.data);
    } catch (ex) {
      console.log(`Cannot load ProgressPercentage ${ex}`);
    } finally {
      setIsLoadingCategories(false);
    }
  };

  useEffect(() => {
    if (props.level) {
      setLevel(props.level);
    }
    getProgressPercentage();
  }, [props]);

  return (
    <>
      <Topbar />
      <Topnav />
      <UpgradeEntryplan />
      <LockContentAutomation />
      <OnBoardingQueLevel2 answers={mrrqna.data} />
      <OnBoardingQueLevel3 />
      <div className="mrr-container">
        <div className="mrr-nav-container">
          <div className="mrr-heading">
            <div className="font-secondary">Market Research</div>
          </div>
          <MRR_TabSwitcher />
          <div className="heading-options">
            <Link to="/chat-athena">Market Message</Link>
          </div>
        </div>
        {!isLoadingCategories ? (
          <>
            {level === 0 && (
              <div
                className="mrr-main align-items-center"
                style={{ justifyContent: 'space-evenly' }}
              >
                <LevelButtons
                  level={1}
                  onClick={handleLevelOne}
                  desc={LEVELS_DESCRIPTION.level1}
                  isUnlocked // Level 1 is Unlocked.
                  showLoader={curentLoadingLevel === 1}
                />

                <LevelButtons
                  level={2}
                  onClick={handleLevelTwo}
                  desc={LEVELS_DESCRIPTION.level2}
                  isUnlocked={isLevelOneCompleted()} // Level 2 is Unlocked When Level 1 is Completed.
                  showLoader={curentLoadingLevel === 2}
                />
                <LevelButtons
                  level={3}
                  onClick={handleLevelThree}
                  desc={LEVELS_DESCRIPTION.level3}
                  isUnlocked={isLevelTwoCompleted()} // Level 3 is Unlocked When Level 2 is Completed.
                  showLoader={curentLoadingLevel === 3}
                />
              </div>
            )}
            {level !== 0 && <Level3Container />}
          </>
        ) : (
          <div className="mrr-main">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around',
                width: '100%',
                flexDirection: 'column',
              }}
            >
              <Loader width={500} height={500} />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Marketresearchandresonace;
