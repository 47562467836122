import React from 'react';
import './styles.css';
import { ScaffoldCard } from 'components/common/scaffold-card';
import { useGetAllWorkspacePersonaStatus } from 'queries/workspace/get-all-workspace-persona-status';
import { sortWorkspaceOnCreatedDate } from 'queries/workspace/selectors';
import { WorkspacePersonaStatusLoading } from 'components/skeleton/persona-library/WorkspacePersonaStatusLoading';
import AppHeaderWrapper from 'components/common/AppHeader/AppHeaderWrapper';
import { HeaderCard } from 'components/common/HeaderCard/HeaderCard';
import NoData from 'components/common/NoData';
import PersonaProgressStatus from '../PersonaProgressStatus';

const PersonaLibrary = () => {
  const { data, isLoading } = useGetAllWorkspacePersonaStatus();

  const sortedWorkspacePersonas = sortWorkspaceOnCreatedDate(data ?? []);

  return (
    <AppHeaderWrapper className="all-workspace-persona-status-wrapper">
      <div className="all-workspace-persona-status-container">
        <HeaderCard
          className="h-margin-b-large"
          title="Workspaces Persona Status"
          description="Turn AI-Powered Listening Into Actionable Insights"
        />

        {isLoading && (
          Array(3).fill(null).map(() => (
            <ScaffoldCard className="h-margin-b-small">
              <WorkspacePersonaStatusLoading />
            </ScaffoldCard>
          ))
        )}

        {sortedWorkspacePersonas && (
        <div className="h-margin-b-small">
          {sortedWorkspacePersonas.map((persona) => (
            <PersonaProgressStatus
              key={persona?.name}
              personaName={persona?.name}
              personaStatus={persona?.onboarding_status}
              isNewWorkspace={false}
            />
          ),
          )}
        </div>
        )}

        <div className="workspace-no-data-container">
          {sortedWorkspacePersonas.length === 0 && <NoData message="No Persona Data to showcase" />}
        </div>

      </div>
    </AppHeaderWrapper>
  );
};
export default PersonaLibrary;
