import React from 'react';
import { useInsightsContext } from 'context/assistants/InsightsContext';
import AppConnectors from '../app-connectors/AppConnectors';
import CustomQuery from '../custom-query/CustomQuery';

function Insights() {
  const { useSelectedConnectorInfo } = useInsightsContext();

  const { SelectedConnectorInfo } = useSelectedConnectorInfo;

  if (SelectedConnectorInfo) {
    return <CustomQuery />;
  }
  return <AppConnectors />;
}
export default Insights;
