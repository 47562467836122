export const DISPLAY_GENERATE_BLOGS_TABLE_HEADER = ['', 'NAME', 'DESCRIPTION'];

export const TABS = { RESEARCH: 'Research', WRITING: 'Writing' };

export const DIGITAL_ASSETS_VIEW = {
  CALANDER: 'Editorial Calendar',
  CARD: 'All Assets',
};

export const BLOG_TYPE = { SALES: 'Sales Copywriter', NON_SALES: '' };
