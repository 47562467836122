import Checkbox from '@mui/material/Checkbox';
import { Button } from 'Component/Shared/ButtonNext';
import Modal from 'react-modal';
import React from 'react';
import { Button as MateriaButton } from '@mui/material';
import styles from './index.module.css';

export const LEVEL = Object.freeze({
  Reddit: 'Reddit',
  Quora: 'Quora',
});

export const AddModalView = (props) => {
  const {
    isOpen,
    onRequestClose,
    customStyles,
    ariaHideApp,
    level,
    isBuyingBoth,
    handleIsBuyingBoth,
    handleBuyPlan,
    showLoader,
  } = props;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      ariaHideApp={ariaHideApp}
    >
      <div className={styles.modalContainer}>
        <p className={styles.modalTitle}>
          You are not subscribed to {level === 0 ? LEVEL.Reddit : LEVEL.Quora}
          &nbsp; Addon
        </p>
        <div className={styles.checkboxContainer}>
          <span>
            Additionally, would you like to subscribe to the following?
          </span>
          <span>
            <Checkbox checked={isBuyingBoth} onChange={handleIsBuyingBoth} />
            &nbsp;
            {level === 1 ? LEVEL.Reddit : LEVEL.Quora}
          </span>
        </div>
        <div className={styles.buttonContainer}>
          <MateriaButton
            variant="outlined"
            color="error"
            style={{ width: '100px' }}
            onClick={onRequestClose}
          >
            No
          </MateriaButton>
          <Button
            name="Buy Now"
            style={{ width: '103px' }}
            onClick={handleBuyPlan}
            showLoader={showLoader}
          >
            Buy Now
          </Button>
        </div>
      </div>
    </Modal>
  );
};
