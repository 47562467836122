import React, { useContext, useState } from 'react';
import LoaderSpin from 'Component/Shared/Loader';
import Checkbox from '@mui/material/Checkbox';
import {
  GET_INTEGRATED_APPS_KEY,
  useGetIntegratedAppsLists,
} from 'queries/chat-athena/get-saved-app-integrations';
import DeleteIcon from '@mui/icons-material/Delete';
import { showNotification } from 'libs/notification';
import { useQueryClient } from 'react-query';
import styles from '../styles.module.css';
import { SelectedAppContext } from '../../contexts/SelectedAppContext';
import { deleteAppIntegrationCreds } from '../service';

const AllApps = () => {
  const queryClient = useQueryClient();

  const {
    data: apps,
    isLoading,
    isError,
    error,
    isFetching,
  } = useGetIntegratedAppsLists();

  const {
    selectedToolId,
    setSelectedToolId,
    selectedFileId,
    setSelectedFileId,
  } = useContext(SelectedAppContext);
  const [isDeletingToolId, setIsDeletingToolId] = useState(null);

  const handleToolSelect = (toolId, status) => {
    if (status) {
      if (selectedFileId) {
        showNotification('success', 'App Mode Activated');

        setSelectedFileId(null);
      }
      setSelectedToolId(toolId);
    } else {
      setSelectedToolId(null);
    }
  };

  const handleDelete = async (toolId) => {
    setIsDeletingToolId(toolId);
    try {
      await deleteAppIntegrationCreds(toolId);
      showNotification('success', 'App Deleted');
      queryClient.invalidateQueries(GET_INTEGRATED_APPS_KEY);
    } catch (ex) {
      console.log('Error in deleting app');
    } finally {
      setIsDeletingToolId(null);
    }
  };

  if (isLoading || isFetching) {
    return (
      <div className={styles.all_documents}>
        <LoaderSpin />
        <p>Loading Apps...</p>
      </div>
    );
  }

  if (apps?.length === 0) {
    return null;
  }

  return (
    <div className={styles.container}>
      <h5>Select App</h5>
      <div className={styles.document_list}>
        {apps?.length >= 0 &&
          apps.map((doc) => (
            <div className={styles.app_row} key={doc.tool_id}>
              <div>
                <Checkbox
                  checked={doc.tool_id === selectedToolId}
                  onChange={(e, val) => handleToolSelect(doc.tool_id, val)}
                  name="gilad"
                  style={{ color: '#fff' }}
                />
                <label>{doc.display_name}</label>
              </div>
              <div style={{ color: 'red', cursor: 'pointer' }}>
                {isDeletingToolId === doc.tool_id ? (
                  <LoaderSpin height={'25px'} width={'25px'} />
                ) : (
                  <DeleteIcon onClick={() => handleDelete(doc.tool_id)} />
                )}
              </div>
            </div>
          ))}
      </div>
      {isError && <p className="text-danger">Error in Loading Apps: {error}</p>}
    </div>
  );
};

export default AllApps;
