/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import { FormControl, Select, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import DoubleArrowRoundedIcon from '@mui/icons-material/DoubleArrowRounded';
import CustomTooltip from 'Component/Shared/CustomTooltip';
import TimelineIcon from '@mui/icons-material/Timeline';
import Topbar from 'Component/Shared/Topbar/Topbar';
import { getListOfCountries } from '../../ContentAutomation/AIcontentWriter/AIcontentLogic';
import AiContentProvider from '../contexts/ai_content';
import EditorProvider from '../contexts/summary_Context.js';
import '../css/app/App.css';
import './editor-result-section.css';
import ResultSection from './result_Section.js';
import TextEditor from './text_editor_component.js';
import SpeedIcon from '@mui/icons-material/Speed';
import { getURLPathBeforeLastSegment } from 'Component/Shared/utils';

function AIContentWriter(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const [searchQuery, setsearchQuery] = useState(
    location && location.state.query,
  );
  const [lang, setlang] = useState(location.state && location.state.lang);
  const [flag, setFlag] = useState(location.state && location.state.flag);
  const [GoogleQuery, setGoogleQuery] = useState(location.state.GoogleQuery);
  const [generatedOutputs, setgeneratedOutputs] = useState(
    location.state && location.state.generatedOutputs,
  );
  const [save, setSave] = useState(location.state && location.state.save);
  const [countries, setCountries] = useState([]);
  const [showResultSection, setShowResultSection] = useState(true);

  useEffect(() => {
    console.log(location.state);
    getListOfCountries().then((data) => {
      setCountries(data);
    });
  }, []);

  const reGenerate = () => {
    const newQuery = localStorage.getItem('newQuery-editor') || GoogleQuery;
    const currentURL = window.location.pathname;
    navigate('/redirectTest', {
      state: {
        GoogleQuery: newQuery,
        query: newQuery,
        generatedOutputs,
        flag: 'ai-writer',
        lang,
        save,
        currentURL,
        regenerating: true,
        blogId: location.state.blogId,
      },
    });
  };

  return (
    <div className="App">
      <div className="app_body">
        <div
          style={{
            position: 'fixed',
            left: 0,
            top: 0,
            right: 0,
            zIndex: '11',
            background: '#fff',
          }}
        >
          <Topbar />
        </div>
        <AiContentProvider
          content={flag === 'sales' ? searchQuery : GoogleQuery}
          lang={lang}
        >
          <EditorProvider
            query={searchQuery}
            save={save}
            generatedText={generatedOutputs}
            lang={lang}
            flag={flag}
            regenerating={location.state.regenerating}
          >
            <div className="row modify-row mt-2">
              <div
                className="col"
                style={{ flex: 1.3, maxWidth: 'unset', paddingTop: '3rem' }}
              >
                <TextEditor
                  query={searchQuery}
                  topic={GoogleQuery}
                  blogId={location.state.blogId}
                />
              </div>

              <div
                style={{ flex: 1, maxWidth: 'unset', paddingTop: '3rem' }}
                className={showResultSection ? 'd-flex col' : 'd-none col'}
              >
                <div
                  className={
                    showResultSection
                      ? 'd-flex  mr-1 editor-showHideIcons-container'
                      : 'd-none'
                  }
                >
                  <div
                    style={{
                      borderRadius: '50%',
                      position: 'absolute',
                      top: '5rem',
                      marginLeft: '-7px',
                    }}
                    className="editor-showHideIcons-body"
                    onClick={() => navigate(-1)}
                  >
                    <DoubleArrowRoundedIcon />
                  </div>
                </div>
                <div
                  style={{
                    flex: 1,
                    boxShadow: '0 0 5px grey',
                    padding: '0.5rem 1rem',
                    borderTopLeftRadius: '1.4rem',
                    marginTop: '0.6rem',
                  }}
                >
                  <div className="aicontent-editor-searchbar">
                    <TextField
                      className="ai-writer-editor-input"
                      id="filled-basic"
                      label="Main Topic of Interest"
                      defaultValue={GoogleQuery}
                      onChange={(e) => {
                        localStorage.setItem('newQuery-editor', e.target.value);
                        localStorage.setItem('context', e.target.value);
                      }}
                    />

                    <FormControl variant="outlined" style={{ flex: 1 }}>
                      <Select
                        native
                        onChange={(e) => {
                          setlang(e.target.value);
                        }}
                        value={lang}
                      >
                        {countries.map((country, index) => (
                          <option key={index} value={country.alpha2Code}>
                            {country.name}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    <button
                      className="btn p-1 m-1"
                      style={{
                        paddingLeft: '0.5rem',
                        background: '#2a9df5',
                        color: '#fff',
                        minWidth: '0',
                      }}
                      onClick={reGenerate}
                    >
                      <SearchIcon />
                    </button>
                  </div>
                  <ResultSection
                    article={generatedOutputs}
                    blogTopic={GoogleQuery}
                    query={searchQuery}
                    country={lang}
                  />
                </div>
              </div>

              <div
                className={
                  !showResultSection
                    ? 'd-flex editor-showHideIcons-container'
                    : 'd-none'
                }
              >
                <div
                  style={{
                    borderTopLeftRadius: '0.5rem',
                    borderBottomLeftRadius: '0.5rem',
                    height: '2rem',
                    marginTop: '3rem',
                  }}
                  className="editor-showHideIcons-body"
                  onClick={() => setShowResultSection(true)}
                >
                  <CustomTooltip title="Show Content Research Tools" arrow>
                    <TimelineIcon />
                  </CustomTooltip>
                </div>
              </div>
            </div>
          </EditorProvider>
        </AiContentProvider>
      </div>
    </div>
  );
}
export default AIContentWriter;
