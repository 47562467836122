/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import LinearProgress from '@mui/material/LinearProgress';
import { withStyles } from '@mui/styles';
import ButtonNext from 'Component/Shared/ButtonNext';
import React, { useEffect, useState } from 'react';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 15,
    borderRadius: 5,
    display: 'flex',
  },
  colorPrimary: {
    backgroundColor: '#eeeeee',
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
}))(LinearProgress);

export default function Progressbar() {
  const [value, setValue] = useState(0);
  const [isCompleted, setIsCompleted] = useState(false);
  useEffect(() => {
    setValue(0);
    handleStartProgressing();
    return () => {
      setValue(100);
    };
  }, []);

  const handleStartProgressing = () => {
    const interval = setInterval(() => {
      setValue((prev) => {
        if (prev + 1.6 > 99) {
          clearInterval(interval);
          setIsCompleted(true);
          return 99;
        }
        return Math.round(prev + 1);
      });
    }, 1 * 1000);
  };

  return (
    <div className="mt-4">
      <p>
        <i>Athena is researching ...</i>
      </p>
      {!isCompleted ? (
        <div style={{ padding: '0.2rem 30%' }}>
          <BorderLinearProgress variant="determinate" value={value} />
        </div>
      ) : (
        <div>
          <span>✅ Completed</span>
          <ButtonNext />
        </div>
      )}
    </div>
  );
}
