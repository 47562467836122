/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import UnlockLevel from 'Component/Pages/MarketReasearch/MarketResearchAndResonance/MarketResearchCategory/TrainAthenaInMRR/UnlockedLevel/UnlockLevel';
import ButtonNext from 'Component/Shared/ButtonNext';
import React, { useEffect, useState } from 'react';
import { getProgressPercentageOfMRRLevels } from './TrainAthenaMRRLogic';

const LevelWithDescription = {
  1: {
    heading: 'Level 1 Completed',
    description:
      'Congratulations on discovering your FRED! Training Athena will help CrawlQ to understand the context and generate target-specific content for you.',
  },
  2: {
    heading: 'Level 2 Completed',
    description:
      'Congratulations on discovering your FRED more deeply. Now train Athena again! This will help CrawlQ to create highly optimised content for the middle of the funnel.',
  },
  3: {
    heading: 'Level 3 Completed',
    description:
      'Congratulations you are now in the final step of your Fred’s Customer Journey! Discover what motivates Fred to turn from a curious visitor to a paying customer!',
  },
};

function TrainAthenaMRR({ trainAthena, setTrainAthena, level }) {
  const [progressPercentage, setProgressPercentage] = useState({
    percentage: 0,
    percentage2: 0,
    percentage3: 0,
  });
  useEffect(() => {
    // handleSetProgressPercetage()
    console.log('level', level);
  }, []);

  const handleClick = async () => {
    if (level !== 1) {
      document.getElementsByClassName('train-athena')[0].click();
      return;
    }
    setTrainAthena({ isLoading: true, isError: false });
    const data = await getProgressPercentageOfMRRLevels();
    setTrainAthena({ isLoading: false, isError: false });
    const currentProgressPercentage = Object.values(data)[
      level - 1 // subtracting as we arei fetching from Array with Zero-as-first-index
    ];
    console.log(currentProgressPercentage);
    if (currentProgressPercentage < 100) {
      setTrainAthena({ ...trainAthena, isError: true });
      return;
    }
    document.getElementsByClassName('train-athena')[0].click();
  };

  return (
    <>
      <div className="mrr-category-card d-flex p-0 justify-content-center">
        <ButtonNext
          buttonName="Train Athena"
          onClick={handleClick}
          className="w-100"
          showLoader={trainAthena.isLoading}
        />
      </div>
      {trainAthena.isError && (
        <p className="text-danger text-center">
          Complete all answers of this level to train Athena
        </p>
      )}
      <div className="d-none">
        <UnlockLevel
          heading={LevelWithDescription[level].heading}
          description={LevelWithDescription[level].description}
        />
      </div>
    </>
  );
}

export default TrainAthenaMRR;
