import { LoadingPlaceholder } from 'components/skeleton/LoadingPlaceholder';
import React from 'react';

export const TLDRLoading = () => (
  <div>
    <LoadingPlaceholder height="15px" width="100%" marginTop="6px" />
    <LoadingPlaceholder height="15px" width="70%" marginTop="3px" />
    <LoadingPlaceholder height="15px" width="60%" marginTop="3px" />

  </div>
);
