import React from 'react';
import { LoadingPlaceholder } from '../LoadingPlaceholder';
import styles from './index.module.css';

export const ExistingFilesLoader = () => (
  <div>
    <div className={styles.loaders_wrapper}>
      {Array(10)
        .fill(null)
        .map((val, index) => (
          <LoadingPlaceholder width="200px" height="20px" />
        ))}
    </div>
  </div>
);
