/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unescaped-entities */
import { PlanPanelInfos } from 'constants/main-menu';
import React from 'react';
import './styles.css';
import { useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { addNewTab } from 'redux/index';

function PlanPanel() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tabs = useSelector((state) => state?.tabManager?.tabs);

  const handleOpenPlanComponent = (planPanelInfo) => {
    dispatch(addNewTab({ id: (tabs.length) + 1,
      name: 'Create Workspace',
      path: planPanelInfo?.plan_path },
    ));

    navigate(`${planPanelInfo?.plan_path}`);
  };
  return (
    <div className="feature-detail-list">
      { PlanPanelInfos.map((planPanelInfo) => (
        <div className="feature-detail plan-panel" key={planPanelInfo.id}>
          <div className="feature-detail-header">
            <planPanelInfo.plan_header_icon style={{ width: '18px', color: '#2a9df5', marginRight: '0.25rem' }} />
            <span className="">{planPanelInfo.plan_header}</span>
          </div>
          <div className="feature-detail-description">
            {planPanelInfo.plan_description}
          </div>
          <div className="feature-time-start-container">
            <div className="feature-time">
              <planPanelInfo.plan_time_icon className="h-margin-r-tiny" style={{ width: '14px', color: '#2a9df5' }} />
              <span>{planPanelInfo.plan_time}</span>
            </div>
            <div className="feature-start" onClick={() => handleOpenPlanComponent(planPanelInfo)}>
              <span className="h-margin-r-tiny">Start</span>
              <planPanelInfo.plan_start_text_icon style={{ width: '14px', color: '#2a9df5' }} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default PlanPanel;
