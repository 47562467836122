import { AUDIENCE_EMPATHY_SCORE_MESSAGE } from 'constants/content/content-tools/Overview';
import React from 'react';
import ReactSpeedometer from 'react-d3-speedometer';
import { useGetEmpathyScore } from 'queries/content/content-tools/get-empathy-score';
import { ScaffoldCard, InfoIcon } from 'components/common/';
import styles from '../overview.module.css';

function AudienceEmpathyScore() {
  const x = '';

  const { data, isSuccess } = useGetEmpathyScore();

  return (
    <div>
      <div className={styles.content_heading}>Audience Persona Empathy Score <InfoIcon className="h-margin-l-xsmall" /></div>
      <ScaffoldCard className={styles.content_wrapper}>
        <ReactSpeedometer
          minValue={0}
          maxValue={100}
          value={data?.body}
          currentValueText={`Score = ${data?.body}`}
          height={130}
          width={200}
          segmentColors={[
            '#03BFFF',
            '#0592D0',
            '#2A52BE',
            '#0047AB',
            '#0073CF',
          ]}
          ringWidth={35}
        />

        <span className={styles.content_message}>{AUDIENCE_EMPATHY_SCORE_MESSAGE[0]}</span>
      </ScaffoldCard>

    </div>
  );
}

export default AudienceEmpathyScore;
