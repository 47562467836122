import createInstance from 'Config/AxiosConfig';
import { FALLBACK_ACTIVE_WORKSPACE } from 'libs/chat-athena-constants';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';

export function useMutateBlog(blogData) {
  const user = useSelector((state) => state?.user);
  const workspace = useSelector((state) => state?.workspace);

  async function publishBlog() {
    const workspaceName = workspace?.name;
    const { question, answer, image_id = '', speech_file_id = '' } = blogData;
    const processedAnswer = `<br></br><br></br>${answer}`;

    const body = {
      username: user?.username,
      name: workspaceName ?? FALLBACK_ACTIVE_WORKSPACE?.name,
      text: processedAnswer,
      blog_name: question,
      main_topic: workspace.mti ?? FALLBACK_ACTIVE_WORKSPACE?.mti,
      image_id,
      speech_file_id,
    };
    const instance = await createInstance();

    instance.post('save-and-publish-blog', body);
  }

  return useMutation(publishBlog);
}
