/* eslint-disable max-len */
import React, { createContext, useContext, useMemo, useState } from 'react';
import { useGetIsCreditAvailable } from 'queries/get-credits';
import { useMRRProgress } from 'queries/dashboard/get-mrr-progress';
import { useAddOnStatus } from 'queries/dashboard/get-addon-status';

export const DashboardContext = createContext();

export const DashboardProvider = ({ children }) => {
  const { data: creditsInfo } = useGetIsCreditAvailable(0);
  const { data: mrrProgress, isLoading } = useMRRProgress();
  const { data: trainAthenaAddOn } = useAddOnStatus();

  const [VideoLink, setVideoLink] = useState();

  const [TrainAthenaOptions, setTrainAthenaOptions] = useState({
    niche: true,
    searchIntent: false,
    addLink: false,
    google: true,
    reddit: false,
    quora: false,
  });

  const [TrainLinks, setTrainLinks] = useState([]);

  const dashboardContextProviderValue = useMemo(
    () => ({
      videoState: [VideoLink, setVideoLink],
      trainAthenaState: [TrainAthenaOptions, setTrainAthenaOptions],
      trainLinkState: [TrainLinks, setTrainLinks],
      creditsInfo,
      mrrProgress,
      trainAthenaAddOn,
    }),
    [
      VideoLink,
      setVideoLink,
      TrainLinks,
      creditsInfo,
      mrrProgress,
      TrainAthenaOptions,
    ],
  );

  return (
    <DashboardContext.Provider value={dashboardContextProviderValue}>
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboardContext = () => {
  const context = useContext(DashboardContext);

  if (context === undefined) {
    throw new Error(
      'useContentContext must be used within a ContentContextProvider',
    );
  }

  return context;
};
