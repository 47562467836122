import { ScaffoldCard } from 'components/common';
import React from 'react';
import ReactPlayer from 'react-player';
import styles from './video-tuts.module.css';

function VideoTuts({ link }) {
  return (
    <ScaffoldCard className={styles.container}>
      {link && (
      <ReactPlayer
        url={link}
        playing
        width="100%"
        height="100%"
        controls
        loop
        config={{ file: { attributes: { controlsList: 'nodownload' } } }}
      />
      )}
    </ScaffoldCard>
  );
}

export default VideoTuts;
