/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';
import { ExpandMoreRounded } from '@mui/icons-material';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { selectTextAndCopy } from '../../Shared/copyToClipBoard';
import LoaderSpin from '../../Shared/Loader';
import search from '../../../assets/img/search.svg';

function InsightsGlobalDisplay() {
  const [insights, setInshights] = useState([]);
  const [insightsLoading, setInshightsLoading] = useState(false);

  useEffect(() => {
    getInsightsNotes();
  }, []);

  const getInsightsNotes = async () => {
    try {
      setInshightsLoading(true);
      const body = {
        username: localStorage.getItem('username'),
        name: localStorage.getItem('name'),
      };
      const { data } = await axios.post(
        'https://apis.crawlq.ai/insights-global',
        body,
      );
      setInshights(data.data.insights);
    } catch (ex) {
      console.log('Error in notes API', ex);
      setInshights([]);
    } finally {
      setInshightsLoading(false);
    }
  };

  const handleCopy = (elementId) => {
    selectTextAndCopy(elementId);
  };

  return (
    <>
      {!insightsLoading ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '1rem',
          }}
        >
          {insights.map((insight, index) => (
            <div className="d-flex">
              <Accordion className="w-100 mt-1">
                <AccordionSummary
                  expandIcon={<ExpandMoreRounded />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <p style={{ fontSize: '1.3rem' }}>{insight.category}</p>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    id={`insights-${index}`}
                    style={{ fontSize: '1rem' }}
                    dangerouslySetInnerHTML={{
                      __html: ` <hr />${insight.value}`,
                    }}
                  />
                  <FileCopyOutlinedIcon
                    onClick={() => handleCopy(`insights-${index}`)}
                    className="cursor-pointer mt-5"
                  />
                </AccordionDetails>
              </Accordion>
            </div>
          ))}
          {!insightsLoading && !insights.length && (
            <div className="text-center">
              <img src={search} />
              <h3 className="mt-3">
                Try Saving Insights in
                <Link to="/MRR-Get-Started"> Market Research</Link>
              </h3>
            </div>
          )}
        </div>
      ) : (
        <div className="text-center">
          <LoaderSpin />
        </div>
      )}
    </>
  );
}

export default InsightsGlobalDisplay;
