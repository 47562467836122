import React, { useMemo } from 'react';
import { useGetTopicsAndParapgraphs } from 'queries/content/content-tools/get-topics-paragraphs';
import { getTopics } from 'queries/content/content-tools/selectors';
import { useContentContext } from 'context/content/ContentContext';
import { TopicLoading } from 'components/skeleton/content/content-tools/topic/TopicLoading';
import CopyText, { handleCopy } from 'components/common/CopyText/CopyText';
import styles from './topics.module.css';

function Topics() {
  const { country, topic } = useContentContext();

  const [Topic] = topic;
  const [Country] = country;
  const { data, isLoading } = useGetTopicsAndParapgraphs(Topic, Country);

  const topicsList = useMemo(() => getTopics(data), [data]);

  if (isLoading) return <TopicLoading />;
  return (
    <div className={styles.wrapper}>
      <CopyText contentId="topic-keyword" />
      <div className={styles.container}>
        { topicsList && topicsList.map((topic) => (
          <div className={styles.topic_container}>
            <div
              id={topic.keyword}
              onClick={() => handleCopy(topic.keyword)}
              className={styles.topic_name}
            >{topic.keyword}
            </div>
            <div className={styles.topic_score}>{topic.score}</div>
          </div>
        ))}
      </div>
    </div>

  );
}

export default Topics;
