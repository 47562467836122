/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react';
import './upgradeEntryPlan.css';

//  Component import
import Modal from 'react-modal';
import UpgradeButton from './UpgradeButton';

const ModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    height: '300px',
    width: '500px',
  },
};

function UpgradeEntryplan() {
  const [isOpenUpgradePlanModal, setIsOpenUpgradeModal] = useState(false);

  function UpgradeEntryplanModal() {
    return (
      <Modal
        isOpen={isOpenUpgradePlanModal}
        onRequestClose={() => setIsOpenUpgradeModal(false)}
        style={ModalStyles}
        ariaHideApp={false}
      >
        <div className="upgradeModal_container">
          <div>
            <h4 className="font-primary text-primary">
              Upgrade Plan &#128640;
            </h4>
          </div>
          <div>
            <p className="text-center font-accent" style={{ flex: '1' }}>
              To dig deeper into your niche research and unlock the full
              potential of CrawlQ Athena
              <br />
              <br />
              {' '}
              Please upgrade your plan
            </p>
          </div>
          <div className="d-flex justify-content-between">
            <div style={{ display: 'flex', flex: 1 }}>
              <button
                className="upgradeModal_btns"
                onClick={() => setIsOpenUpgradeModal(false)}
              >
                Cancel
              </button>
            </div>
            <div style={{ flex: 0.2 }} />
            <div style={{ display: 'flex', flex: 1 }}>
              <UpgradeButton />
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <>
      <button
        id="upgradePlan-popup"
        className="d-none"
        onClick={() => setIsOpenUpgradeModal(true)}
      >
        Hey
      </button>

      <UpgradeEntryplanModal />
    </>
  );
}

export default UpgradeEntryplan;
