/* eslint-disable max-len */
import { CONVERT_CATEGORIES } from 'constants/market-research/convert';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ScaffoldCard } from 'components/common/scaffold-card';
import styles from './convert.module.css';
import { ConvertCategoryIcon } from '../styles';

const ConvertCategory = () => {
  const navigate = useNavigate();

  const handleCategoryClick = (categoryName) => {
    navigate(`/market-research/convert/${categoryName}`);
  };

  return (
    <div className={styles.convert_research_wrapper}>
      <div className={`${styles.convert_static_header} h-margin-b-xsmall`}>
        Select any of the following Convert Research Category
      </div>
      {CONVERT_CATEGORIES.map(({ name }, idx) => (
        <ScaffoldCard className={styles.convert_category_container} key={idx}>
          <ConvertCategoryIcon />
          <div
            className={styles.convert_category_text}
            onClick={() => handleCategoryClick(name)}
          >
            {name}
          </div>
        </ScaffoldCard>
      ))}
    </div>
  );
};

export default ConvertCategory;
