import React from 'react';
import { useIsMobile } from 'Component/hooks/useIsMobile';
import Topnav from '../Sidenav/Topnav';
import MinifiedTopbar from '../Topbar/MinifiedTopnav';
import { ChildrenWrapper } from './style';

export default function MinifiedLayout({ children }) {
  const isMobile = useIsMobile();

  return (
    <>
      {!isMobile && <Topnav />}
      <MinifiedTopbar />
      <ChildrenWrapper isMobile={isMobile}>{children}</ChildrenWrapper>
    </>
  );
}
