import LoaderSpin from 'Component/Shared/Loader';
import React from 'react';
import styles from '../chat-athena.module.css';

const ParentLoader = ({ isDarkMode }) => (
  <div
    style={{
      backgroundColor: isDarkMode ? '#343541' : 'white',
    }}
    className={styles.chat_athena_parent_loader}
  >
    <LoaderSpin />
    <p
      style={{
        color: !isDarkMode ? '#343541' : 'white',
      }}
    >
      Loading ChatAthena and preparing Workspaces....
    </p>
  </div>
);

export default ParentLoader;
