import styled from 'styled-components';

export const StatusIndicatorContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    
`;

export const StatusIndicatorWrapper = styled.div`
    display: flex;
    justify-content: space-around;
`;

export const ViewDetailsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

export const DateContainer = styled.div`
    display: flex;
    
`;
