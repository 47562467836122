/* eslint-disable no-useless-escape */
import moment from 'moment';

export const getFirstLetterCapital = (word) =>
  word[0].toUpperCase() + word.slice(1);

export const getQueryFromURL = (query) => {
  const urlString = window.location.href;
  const url = new URL(urlString);
  return url.searchParams.get(query);
};

export const getFormattedTime = (time) => {
  const convertedDate = new Date(time);
  const formattedTime = new Date(convertedDate.toUTCString());

  return formattedTime;
};

export const getFormattedTimeInDayMonthYear = (timeInLocaleString) => {
  const event = new Date(timeInLocaleString);
  return event.toLocaleDateString('en-us', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });
};

export const isValidTrainingUrl = (url) => {
  const expression = /(http|ftp|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:\/~+#-]*[\w@?^=%&amp;\/~+#-])?/;
  const regex = new RegExp(expression);

  if (url.match(regex)) return true;
  return false;
};

export const getMonthsFromTodayDate = (monthsBack) => {
  const todayDate = new Date();
  todayDate.setMonth(todayDate.getMonth() - monthsBack);

  return moment(todayDate).format('YYYY/MM/DD HH:MM:SS');
};

export const validateEmail = (email) =>
  String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

export const formatSQL = (sql) => {
  if (!sql) {
    return '';
  }
  const operators = [
    'SELECT', 'FROM', 'WHERE', 'GROUP BY', 'HAVING', 'ORDER BY',
    'LIMIT', 'OFFSET', 'JOIN', 'INNER JOIN', 'LEFT JOIN', 'RIGHT JOIN',
    'FULL JOIN', 'CROSS JOIN', 'NATURAL JOIN', 'UNION', 'UNION ALL',
    'EXCEPT', 'INTERSECT', 'INSERT INTO', 'VALUES', 'UPDATE', 'SET',
    'DELETE FROM', 'CREATE TABLE', 'ALTER TABLE', 'DROP TABLE',
    'CREATE INDEX', 'DROP INDEX', 'CREATE VIEW', 'DROP VIEW',
    'CREATE SCHEMA', 'DROP SCHEMA', 'CREATE DATABASE', 'DROP DATABASE',
    'USE', 'GRANT', 'REVOKE', 'TRUNCATE', 'DESCRIBE', 'EXPLAIN',
    'PRIMARY KEY', 'FOREIGN KEY', 'REFERENCES', 'CHECK', 'DEFAULT',
    'UNIQUE', 'NOT NULL', 'AND', 'OR', 'NOT', 'BETWEEN', 'IN',
    'LIKE', 'IS NULL', 'IS NOT NULL', 'CASE', 'WHEN', 'THEN',
    'ELSE', 'END', 'CAST', 'COALESCE', 'NULLIF', 'DISTINCT',
  ];

  const regex = new RegExp(`\\b(${operators.join('|')})\\b`, 'gi');
  return sql.replace(regex, ' $1 ').replace(/\s+/g, ' ').trim();
};
