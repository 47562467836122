import { ROUTES } from 'constants/routes';
import React, { useState } from 'react';
import Popover from '@mui/material/Popover';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Link, useNavigate } from 'react-router-dom';
import CustomTooltip from 'Component/Shared/CustomTooltip';
import { useSelector } from 'react-redux';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import styles from '../chat-athena.module.css';

export function MenuButton() {
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = () => {
    navigate(ROUTES.DASHBOARD);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className={styles.menu_button}>
        <CustomTooltip title="Dashboard" placement="top" arrow>
            <DashboardIcon aria-describedby={id} onClick={handleClick} />
            {/* <DynamicFormIcon onClick={() => navigate('/market-research')} /> */}
        </CustomTooltip>
          <CustomTooltip title="Market Research" placement="top" arrow>
            <DynamicFormIcon
              className="h-margin-l-x2"
              onClick={() => navigate('/market-research')}
            />
          </CustomTooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Link to="/dashboard" className={styles.menu_items}>
          Dashboard
        </Link>
      </Popover>
    </div>
  );
}
