import { useMemo } from 'react';
import { useGetUsersAddOns } from 'queries/user-account/services/get-user-addons';
import { PlanType } from 'libs/enums/planType.enum';

const useIsProActive = () => {
  const { data: plans, isLoading: isLoadingPlans } = useGetUsersAddOns();

  const isProActivated = useMemo(() => {
    if (isLoadingPlans) return false;
    if (plans[PlanType.chatAthena]?.status) return true;
    if (plans[PlanType.chatAthenaYearly]?.status) return true;
    return false;
  }, [plans, isLoadingPlans]);

  return isProActivated;
};

export default useIsProActive;
