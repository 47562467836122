import { baseAPIsUrl } from 'Config/Api';
import createInstance from 'Config/AxiosConfig';

export const shareAssistantWithEmail = async (id, email) => {
  try {
    const instance = await createInstance();
    const { data } = await instance.post(`${baseAPIsUrl}/share-assistant`, {
      assistant_id: id,
      destination_email: email,
    });
    if (data?.statusCode === '200') {
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};
