/* eslint-disable operator-assignment */
/* eslint-disable consistent-return */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import React, { useState } from 'react';
import Modal from 'react-modal';
import StripeCheckout from 'react-stripe-checkout';
import Checkmark from '../../Shared/CheckMark/Checkmark';
import './InAppPurchase.css';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import Loader from '../../Shared/Loader';

const customStyles = {
  content: {
    width: '800px',
    height: '600px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

function InAppPurChase({ showPopUp }) {
  const [show, setShow] = useState(false);
  const [product, setProduct] = useState({
    name: 'Top upCredits',
    price: '19',
    productBy: 'credits',
  });

  const [paymentStatus, setPaymentStatus] = useState(0);

  // 0 -> Not Started
  // 1 -> Processing
  // 2 -> Done
  // 3 -> Fails

  const makePayment = async (token) => {
    setPaymentStatus(1);
    const body = {
      token,
      product,
      group: localStorage.getItem('group_name'),
    };
    body.product.price = body.product.price * 100; // coverting dollars to cents
    const headers = {
      'Content-Type': 'application/json',
    };

    console.log(body);
    try {
      const response = await fetch(

        'https://apis.crawlq.ai/make-payment',
        {
          method: 'POST',
          headers,
          body: JSON.stringify(body),
        },
      );
      console.log('RESPONSE ', response);
      setPaymentStatus(2);
      setTimeout(() => window.location.reload(), 2000);
      const { status } = response;
    } catch (error) {
      return console.log(error);
    }
  };

  function TopUpOptions({ amount, credits }) {
    return (
      <div
        className="card-topup-option font-accent"
        style={
        amount == product.price
          ? { color: '#fff', backgroundColor: '#63a5f0' }
          : { color: 'darkgray', border: '1px solid darkgray' }
      }
        onClick={() => setProduct((prev) => ({ ...prev, price: amount }))}
      >
        <h5 className="ml-2 mt-1">
          $
          {amount}
        </h5>
        <div className="card-topup-credits">
          <p className="m-0">
            {credits}
            K
          </p>
          <span>Credits</span>
        </div>
      </div>
    );
  }

  function PaymentInitiate() {
    return (
      <div className="card-planCard">
        {/* <PlanCard amount={19} credits={5000} makePayment={makePayment} /> */}
        <div className="card-left">
          <div className="card-amount font-accent">
            <span>Pay</span>
            <p>
              $
              {product.price}
            </p>
          </div>
          <div className="card-cancel-payment">
            <span
              className="font-accent text-white custom_center"
              onClick={() => setShow(false)}
            >
              <ArrowBackIosRoundedIcon />
              {' '}
              Cancel Payment
            </span>
          </div>
        </div>
        <div className="card-right">
          <div className="card-right-top">
            <div className="card-order-summmary font-accent">
              <h2 className="font-primary mt-4">Order Summary</h2>
              <span className="font-accent txt-primary d-block mt-3">
                Top up Credits
              </span>
              <small className="text-muted d-block mb-3">
                This purchase do not increase your monthly limit
              </small>
              <span className="font-accent txt-primary d-block mt-4">
                Choose Your Top up
              </span>
            </div>
            <div className="card-options">
              <TopUpOptions amount={19} credits={5} />
              {/* <TopUpOptions amount={39} credits={10} /> */}
            </div>
          </div>
          <div className="card-pay-btn-container ">
            <span className="custom_center">
              <StripeCheckout
                stripeKey="pk_live_aEO8gt1KB36sENfwsJBeewC40041EnNgdE"
                token={makePayment}
                name="Buy Credits"
                amount={product.price * 100}
                email={localStorage.getItem('username')}
              >
                <button className="card-pay-btn custom_center">
                  Pay Now
                  {' '}
                  <ArrowForwardIosRoundedIcon />
                </button>
              </StripeCheckout>
            </span>
          </div>
        </div>
      </div>
    );
  }

  function ModalPurchase() {
    return (
      <Modal
        isOpen={show}
        onRequestClose={() => setShow(!show)}
        style={customStyles}
        ariaHideApp={false}
      >
        {paymentStatus === 0 && <PaymentInitiate />}
        {paymentStatus === 1 && (
        <div
          className="custom_center"
          style={{ height: '100%', flexDirection: 'column' }}
        >
          <Loader height={400} width="200" />
          <h2 className="font-primary">Payment Processing</h2>
        </div>
        )}
        {paymentStatus === 2 && <Checkmark message="Transaction Successfull" />}
      </Modal>
    );
  }

  return (
    <>
      <div
        className="athena__body"
        style={!showPopUp ? { display: 'none' } : {}}
      >
        <div className="athena__head">
          <h2 className="font-primary">Out of Credits ?</h2>
        </div>
        <div className="athena__input">
          <button
            id="buyCreditsPopUp"
            className="train-athena"
            onClick={() => setShow(true)}
          >
            Buy
          </button>
        </div>
      </div>
      <ModalPurchase />
    </>
  );
}

export default InAppPurChase;
