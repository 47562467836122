import { useMutation, useQueryClient } from 'react-query';
import createInstance from 'Config/AxiosConfig';
import { toast } from 'react-toastify';

const summarize = async ({ TextAreaValue, RangeValue }) => {
  const payload = {
    text: TextAreaValue,
    maxTokens: RangeValue,
  };

  const instance = await createInstance();

  const response = await instance.post('ai-summarize', payload);
  console.log(response);
  if (response?.data?.statusCode === '200') return response?.data?.output;

  throw new Error('Market Research data failed to fill, Try again !');
};

export const useMutateSummarize = () => useMutation(summarize, {

  onSuccess: (data) => {
    toast.success('Athena summarized the content', { position: 'bottom-center', theme: 'dark' });
  },
  onError: (error) => {
    toast.error(`${error.message}`, { position: 'bottom-center', theme: 'dark' });
  },
});
