/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import React from 'react';
import { Editor as TinyEditor } from '@tinymce/tinymce-react';

function Tinytextbox(props) {
  return (
    <TinyEditor
    // onInit={(evt, editor) => (editorRef.current = editor)}
      id="texteditor"
      tinymceScriptSrc="%PUBLIC_URL%/tinymce/js/tinymce/tinymce.min.js"
    // initialValue={globalEditor}
      init={{
        menubar: true,
        height: 600,
        toolbar: true,
        content_css: '/vendor/fontawesome-free/css/all.min.css',
        selector: 'textarea#full-featured',
        plugins: [
          'quickbars',
          ' autosave noneditable permanentpen pageembed tinycomments quickbars linkchecker emoticons advtable',
          'mediaembed  advlist checklist tinymcespellchecker powerpaste',
          'casechange importcss tinydrive searchreplace autosave advcode',
          ' autolink lists link image charmap print preview hr anchor pagebreak',
          'searchreplace wordcount visualblocks visualchars  fullscreen',
          'insertdatetime media nonbreaking save table contextmenu directionality',
          'emoticons template textcolor colorpicker textpattern imagetools codesample toc help',
          'ExportToDoc  export',
        ], // note the comma at the end of the line!
        statusbar: true,
        toolbar_mode: 'sliding',
        menu: {
          tc: {
            title: 'TinyComments',
            items: 'addcomment showcomments deleteallconversations',
          },
          media: {
            title: 'Media',
            items: 'insertfile image media pageembed template faicons',
          },
          more: {
            title: 'Features',
            items: 'casechange code',
          },
        },
        menubar: 'file edit view insert  format tools table tc media more   help',
        browser_spellcheck: true,
        quickbars_selection_toolbar:
        'bold italic underline | formatselect | bullist numlist | blockquote quicklink',
        // toolbar1: ' undo redo  | bold italic underline strikethrough ',
        // toolbar4: 'alignleft aligncenter alignright alignjustify ',
        // toolbar2: '  link anchor | codesample export  language | spellcheckdialog restoredraft | fullscreen  preview save print |  casechange permanentpen | pagebreak charmap emoticons ',
        // toolbar3: ' numlist bullist checklist | forecolor backcolor | outdent indent  ',
        autosave_ask_before_unload: true,
        tinycomments_mode: 'embedded',
        etd_file_name: 'tinymce-content',
        forced_root_block: '',
        force_br_newlines: true,
        force_p_newlines: false,
        spellchecker_language: 'en',
        // PowerPaste
        powerpaste_allow_local_images: true,
        powerpaste_word_import: 'prompt',
        powerpaste_html_import: 'prompt',
        // PowerPaste-end
        save_enablewhendirty: true,
        autosave_ask_before_unload: false,
        fixed_toolbar_container: '#css3-selector',
      }}
    />
  );
}

export function DistractionFreeTextBox(props) {
  return (
    <TinyEditor
    // onInit={(evt, editor) => (editorRef.current = editor)}
      id="texteditor"
      tinymceScriptSrc="%PUBLIC_URL%/tinymce/js/tinymce/tinymce.min.js"
      initialValue={props.text}
      init={{
        menubar: true,
        plugins: [
          'autolink',
          'codesample',
          'link',
          'lists',
          'media',
          'powerpaste',
          'table',
          'image',
          'quickbars',
          'codesample',
          'help',
          'ExportToDoc  export',
          'wordcount',
        ],
        toolbar: false,
        menubar: 'file',
        quickbars_insert_toolbar: 'quicktable image media codesample',
        quickbars_selection_toolbar:
        'file | bold italic underline | formatselect | blockquote quicklink',
        contextmenu:
        'undo redo | inserttable | cell row column deletetable | help',
        powerpaste_word_import: 'clean',
        powerpaste_html_import: 'clean',
      }}
      onEditorChange={(e) => props.onChange(e)}
    />
  );
}

export default Tinytextbox;
