/* eslint-disable indent */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable max-len */
/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable quotes */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/button-has-type */
/* eslint-disable no-return-assign */
/* eslint-disable no-use-before-define */

import { Button, Col, Form, Input, Row, Steps } from 'antd';
import React, { useEffect, useState } from 'react';
import AthenaLogo from 'assets/images/crawlQ-transparent-favicon.png';
import AthenaLogo2 from 'assets/images/Athena3.png';
import {
  CommentOutlined,
  LeftOutlined,
  PlusOutlined,
  RightOutlined,
} from '@ant-design/icons';
import LanguageMobileSelector from '../sharedMobile/LanguageMobileSelectors';
import NavigationSelectors from '../sharedMobile/NavigationSelectors';
import WaveLoader1 from '../sharedMobile/loaders/WaveLoader1';

const FirstScreen = (props) => {
  const { index, changeIndex, setStarterFlow } = props;
  const { data, setData } = props;
  return (
    <>
      {' '}
      <div
        style={{
          background: 'white',
        }}
      >
        <div
          style={{
            height: '10vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 'bold',
            fontSize: '25px',
          }}
        ></div>

        <div
          style={{
            height: '70vh',
            display: 'flex',
            justifyContent: 'center',
            // alignItems: 'center',
          }}
        >
          <div
            // align="middle"
            // justify="space-between"
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              // alignItems: 'center',
            }}
          >
            <Form
              name="control-hooks"
              // onFinish={onFinish}
              style={{ width: '85vw', color: 'white' }}
            >
              <Form.Item name="logo">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '65px',
                  }}
                >
                  <img
                    src={AthenaLogo}
                    alt="Athena Logo"
                    style={{
                      display: 'inline-block',
                      width: '45px',
                      // marginLeft: '10%',
                      marginBottom: '-20px',
                    }}
                  />
                </div>
              </Form.Item>
              <Form.Item
              // rules={[{ required: true }]}
              >
                <div style={{}}>
                  {' '}
                  <div
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    <div
                      style={{
                        fontSize: '25px',
                        fontWeight: 'bold',
                        marginBottom: '10px',
                      }}
                    >
                      Welcome to CrawlQ: <br />
                      Where Insights Emerge
                    </div>
                    <em
                      style={{
                        marginLeft: '10px',
                        color: '#4B79FF',
                        fontSize: '20px',
                        fontWeight: 'bold',
                      }}
                    >
                      Embark on a Journey of Discovery <br />
                      Choose Your Path
                    </em>
                  </div>
                  <div>
                    <Row style={{ width: '100%', marginTop: '75px' }}>
                      <Col
                        onClick={() => setStarterFlow('CHATATHENA')}
                        span={11}
                        style={{
                          background: '#4B79FF',
                          fontWeight: 'bold',
                          padding: '8px',
                          borderRadius: '5px',
                          fontSize: '13px',
                          color: 'black',
                        }}
                      >
                        <Row align="top">
                          <Col span={4}>
                            {' '}
                            <CommentOutlined
                              style={{
                                marginRight: '15px',
                                fontSize: '25px',
                                color: 'white',
                              }}
                            />
                          </Col>
                          <Col offset={3} span={17}>
                            Dive into Existing Campaign <br />{' '}
                            <span style={{ fontSize: '10px', color: 'black' }}>
                              Continue where you left off
                            </span>
                          </Col>
                        </Row>
                      </Col>
                      <Col
                        onClick={() => changeIndex('RIGHT')}
                        span={11}
                        offset={1}
                        style={{
                          background: '#4B79FF',
                          fontWeight: 'bold',
                          padding: '8px',
                          borderRadius: '5px',
                          fontSize: '13px',
                          color: 'black',
                        }}
                      >
                        <Row align="top">
                          <Col span={4}>
                            {' '}
                            <PlusOutlined
                              style={{
                                marginRight: '15px',
                                fontSize: '25px',
                                color: 'white',
                              }}
                            />
                          </Col>
                          <Col offset={3} span={17}>
                            Create a New Campaign <br />{' '}
                            <span style={{ fontSize: '10px', color: 'black' }}>
                              Start fresh and uncover new insights
                            </span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
        <div
          style={{
            height: '10vh',
            // backgroundColor: 'green',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        ></div>
      </div>
    </>
  );
};

export default FirstScreen;
