import React from 'react';
import { CopyFileIcon, MailIcon } from 'components/common';
import { toast } from 'react-toastify';
import styles from './contact-support.module.css';

const ContactSupport = () => {
  const handleCopy = () => {
    navigator.clipboard.writeText('support@quantamixsolutions.com');

    toast.info('Email Copied !', {
      position: 'bottom-center',
      theme: 'dark',
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.info_container}>
        <MailIcon primary marginRight="12px" />
        <span>
          <span className={styles.mailusat}>Mail us at :</span>{' '}
          support@quantamixsolutions.com
        </span>
        <CopyFileIcon
          secondary
          width="14px"
          marginLeft="8px"
          onClick={handleCopy}
        />
      </div>
    </div>
  );
};

export default ContactSupport;
