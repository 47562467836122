import React from 'react';
import styles from '../styles.module.css';

function SelectDropdown({ value, options, setValue }) {
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <select
      value={value}
      onChange={handleChange}
      className={styles.select_options}
    >
      <option value="">Select a Tool</option>
      {options.map((option) => (
        <option key={option.tool_name} value={option.tool_name}>
          {option.tool_label}
        </option>
      ))}
    </select>
  );
}

export default SelectDropdown;
