import { PlanType } from 'libs/enums/planType.enum';

export const ModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width: '850px',
    height: '480px',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'hidden',
    maxWidth: '98vw',
    maxHeight: '95vh',
  },
};

export const DATA_SOURCES = [
  { value: 'mrr', name: 'Market Research' },
  { value: 'mrr_insight', name: 'Market Insights' },
  { value: 'script', name: 'Sales Copywriting' },
  { value: 'serp', name: 'Google' },
  { value: 'quora', name: 'Quora', planRequired: PlanType.quora },
  { value: 'reddit', name: 'Reddit', planRequired: PlanType.reddit },
  { value: 'website', name: 'URL' },
];

export const CREATIVE_TOOLS = [
  { name: 'Generate Stats', value: 'stat_me' },
  { name: 'Generate Quotes', value: 'quote_me' },
  { name: 'Creative Copywriter', value: 'creative_brief' },
  { name: 'Long-form Blog Generator', value: 'seo_brief' },
];
