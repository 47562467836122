import createInstance from 'Config/AxiosConfig';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { FIVETRAN_CONNECTED_APPS_QUERY_KEY } from './get-connected-apps';

export default async function createConnector(connectorsData) {
  const { id: appId, label, config = {} } = connectorsData;

  if (Object.keys(config).length !== 0) {
    Object.keys(config).map((key) => {
      if (config[key].trim() === '') {
        throw new Error(`${key} Cannot be empty`);
      }
      return null;
    });
  }

  try {
    const instance = await createInstance();
    const body = {
      service: appId,
      display_name: label,
      config,
    };

    const { data } = await instance.post('/create-connector', body);
    if (data.statusCode === '200') {
      return data.data;
    }
    throw new Error(`Error in creating connector, ${data.body}`);
  } catch (ex) {
    console.log(ex);
    // throw new Error(`Error in Creating connector ${ex.message}`);
    toast.error(ex.message, {
      position: 'bottom-center',
      theme: 'dark',
      autoClose: 10000,
    });
    return null;
  }
}

export function useCreateConnectorMutation() {
  const queryClient = useQueryClient();

  return useMutation(createConnector, {
    onError: (error) => {
      toast.error(error.message, {
        position: 'bottom-center',
        theme: 'dark',
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(FIVETRAN_CONNECTED_APPS_QUERY_KEY);
    },
  });
}
