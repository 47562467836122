/* eslint-disable max-len */
import createInstance from 'Config/AxiosConfig';
import { useQuery } from 'react-query';
import {
  getReduxUserState,
  getReduxWorkspaceState,
} from 'utils/helpers/redux-selectors';
import { MARKET_RESEARCH_ENDPOINTS } from 'constants/services';

const SECTION_NAME = 'Niche';

export const getMarketCategories = async (section) => {
  const { email } = getReduxUserState();
  const { name } = getReduxWorkspaceState();

  const sectionName = section ?? SECTION_NAME;
  const instance = await createInstance();
  const response = await instance.get(
    `${MARKET_RESEARCH_ENDPOINTS.GET_MARKET_CATEGORIES}=${sectionName}&username=${email}&name=${name}`,
  );

  const categories = response?.data?.flags;

  return categories;
};

// Keep Query Value as File Name to maintain consistency and uniqueness in query index
export const GET_MARKET_CATEGORIES_QUERY_KEY = 'get-market-categories';

// Query Config Options
const queryOptions = {
  staleTime: 1 * 60 * 1000,
};

// QUERY
export const useGetMarketCategories = (section) =>
  useQuery(
    [GET_MARKET_CATEGORIES_QUERY_KEY, section],
    () => getMarketCategories(section),
    queryOptions,
  );
