/* eslint-disable react/no-unescaped-entities */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import { LoaderTailSpin } from 'Component/Shared/Loader';
import React, { useEffect, useState } from 'react';
import { PillWrapper, ShadowContainer, PillComponent } from '../styles';
import { getMemberslist } from '../TeamMemberLogic';
import ExpandedMenu from './ExpandedMenu';
import TableRow from './TableRow';

export default function MembersTable() {
  const [membersList, setMembersList] = useState([]);
  const [isLoading, setIsLoadingMembers] = useState(false);
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const [filterIndex, setFilterIndex] = useState(0);
  const filetersList = ['All', 'Client', 'Members'];

  useEffect(() => {
    setIsLoadingMembers(true);
    getMemberslist()
      .then((data) => {
        setMembersList(data);
      })
      .finally(() => {
        setIsLoadingMembers(false);
      });
  }, []);

  const handleExpansions = (index) => {
    setExpandedIndex((prev) => (prev === index ? -1 : index));
  };

  const handleFilter = (member) => {
    if (filterIndex === 0) return true;
    if (filterIndex === 1 && member.scope === 'Client') return true;
    if (filterIndex === 2 && member.scope === 'Team Member') return true;
    return false;
  };

  return (
    <ShadowContainer flex="4">
      <h3>
        <b>Collaborators & teams</b>
      </h3>
      {isLoading ? (
        <div className="text-center">
          <LoaderTailSpin />
        </div>
      ) : (
        <>
          {membersList.length > 0 ? (
            <div
              className="table table-striped"
              style={{
                overflow: 'hidden',
              }}
            >
              <PillWrapper>
                {[0, 1, 2].map((val) => (
                  <PillComponent
                    active={val === filterIndex}
                    onClick={() => setFilterIndex(val)}
                  >
                    {filetersList[val]}
                  </PillComponent>
                ))}
              </PillWrapper>
              <div>
                <tr className="row">
                  <th className="col-1 text-center">#</th>
                  <th className="col-4">Name</th>
                  <th className="col-4">Email</th>
                  <th className="col-3 text-center">Scope</th>
                </tr>
              </div>
              <div className="table_rows">
                {membersList
                  .filter((member) => handleFilter(member))
                  .map((member, index) => (
                    <div key={index + 1}>
                      <TableRow
                        index={index}
                        name={member.name}
                        email={member.email}
                        scope={member.scope}
                        handleExpansions={() => handleExpansions(index)}
                        isExpanded={expandedIndex === index}
                        isAdmin={index === 0}
                      />
                      {index !== 0 && expandedIndex === index && (
                        <ExpandedMenu email={member.email} />
                      )}
                    </div>
                  ))}
              </div>
            </div>
          ) : (
            <p className="text-center">You haven't added any member yet.</p>
          )}
        </>
      )}
    </ShadowContainer>
  );
}
