/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BarLoader from 'react-spinners/PulseLoader';
import PitchGroundCrawlQ from '../../assets/images/pitchground-crawlq.svg';
import LoaderSpin from '../Shared/Loader';

function PitchGroundegister() {
  const navigate = useNavigate();
  const [users, setUsers] = useState({
    email: '',
    password: '',
    appsumocode: '',
    firstName: '',
    lastName: '',

  });
  const [SignUpSuccess, setSignUpSucess] = useState(true);
  const [nameErr, setNameErr] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [IsAllDetails, setIsAllDetails] = useState(false);
  const [Agree, setAgree] = useState(false);

  useEffect(() => {
    checkAllDetailsField();
  }, [users]);

  const onRegisterSubmit = async (event) => {
    event.preventDefault();

    const {
      firstName, email, password, appsumocode, lastName,
    } = users;

    if (users.password.trim() === '' || users.email.trim() === '' || users.firstName.trim() === '') {
      setIsLoading(false);
      setNameErr('Fields can not be empty');
    } else if (!users.email.match(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/)) {
      setIsLoading(false);
      setNameErr('Invalid Email Address');
    } else {
      setIsLoading(true);
      const name = `${firstName} ${lastName}`;

      const body = {
        email,
        Name: name,
        Password: password,
        code: appsumocode,
      };

      pushData(
        'https://apis.crawlq.ai/pitchground-signup',
        body,
      );
    }
  };

  const pushData = (url, body) => {
    const checkboxTerms = document.getElementById('checkboxTerms');

    if (checkboxTerms.checked) {
      Axios.post(url, body)
        .then((res) => {
          setIsLoading(false);

          checkSucessful(res);
        })
        .catch((err) => {

          // console.log(err)
        });
    } else {
      setIsLoading(false);
      setNameErr('Please accept terms and conditions to complete Sign Up.');
    }
  };

  const checkSucessful = (res) => {
    if (res.data.result) {
      // if false then move to successful pafe for signup
      setSignUpSucess(false);
      setIsLoading(false);
    } else {
      setNameErr(res.data.message);
    }
  };

  const checkAllDetailsField = () => {
    let count = 0;
    for (const i in users) {
      if (users[i] != '') {
        count += 1;
      } else {
        count -= 1;
      }
    }

    // if all input field filled , enable signup
    if (count === 5) {
      setIsAllDetails(true);
    } else {
      setIsAllDetails(false);
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setNameErr('');
    setUsers({
      ...users,
      [name]: value,
    });

    // checkAllDetailsField()
  };

  function SignUpContainer() {
    return (
      <form onSubmit={onRegisterSubmit} className="loginfrm">
        <div className="row middleflx">
          <div className="col-sm-6">
            <div className="form-wrap">
              <h2 className="title">PitchGround</h2>

              <div className="input-field">
                <input
                  type="text"
                  name="firstName"
                  className="input"
                  value={users.firstName}
                  onChange={handleInput}
                  placeholder="First Name"
                />
              </div>

              <div className="input-field">
                <input
                  type="text"
                  name="lastName"
                  className="input"
                  value={users.lastName}
                  onChange={handleInput}
                  placeholder="Last Name"
                />
              </div>

              <div className="input-field">
                <input
                  type="email"
                  name="email"
                  className="input"
                  value={users.email}
                  onChange={handleInput}
                  placeholder="Email"
                />
              </div>

              <div className="input-field">
                <input
                  type="password"
                  name="password"
                  className="input"
                  value={users.password}
                  onChange={handleInput}
                  placeholder="Password"
                />
              </div>

              <div className="input-field">
                <input
                  type="text"
                  name="appsumocode"
                  className="input"
                  value={users.appsumocode}
                  onChange={handleInput}
                  placeholder="PitchGround Code"
                />
                <div style={{
                  margin: '0', alignItems: 'center', fontSize: '14px', display: 'flex',
                }}
                >
                  <input style={{ margin: '0', marginTop: '4px', width: '10%' }} value={Agree} id="checkboxTerms" type="checkbox" onChange={() => setAgree((Agree) => !Agree)} />
                  <label style={{ margin: '0' }}>
                    I agree to
                    <a target="_blank" href="https://crawlq.ai/privacy-policy/" rel="noreferrer">Terms Of Use</a>
                    {' '}
                    and the
                    <a target="_blank" href="https://crawlq.ai/privacy-policy/" rel="noreferrer">Privacy Policy</a>
                  </label>
                </div>

              </div>

              <div className="register-btn">

                {isLoading
                  ? <LoaderSpin height="30" width="30" />
                  : IsAllDetails && Agree
                    ? (
                      <button
                        style={{
                          width: '100%', borderRadius: '4px', marginLeft: '5px', letterSpacing: '2px',
                        }}
                        type="submit"
                        className="btnn solid"
                      >
                        Sign Up
                        {' '}
                      </button>
                    )
                    : (
                      <button
                        style={{
                          width: '100%', borderRadius: '4px', marginLeft: '5px', backgroundColor: '#d2d2d2', color: 'white', letterSpacing: '2px',
                        }}
                        type="submit"
                        className="btnn solid"
                        disabled
                      >
                        Sign Up
                      </button>
                    )}

                <div>
                  <p style={{ color: 'red', marginTop: 20, fontSize: '0.8rem' }}>{nameErr}</p>
                </div>
                <div style={{ marginTop: '1px', justifyContent: 'center', display: 'flex' }}>
                  <p style={{ fontSize: '12px', padding: '12px' }}><a style={{ color: '#9f9f9f' }} target="_blank" href="https://crawlq.ai/privacy-policy/" rel="noreferrer">Terms and Condition</a></p>
                  {' '}
                  <p style={{ fontSize: '12px', padding: '12px' }}><a style={{ color: '#9f9f9f' }} target="_blank" href="https://crawlq.ai/privacy-policy/" rel="noreferrer">Privacy Policy </a></p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-6">
            <div className="right-logo">
              <img src={PitchGroundCrawlQ} />
            </div>
          </div>
        </div>

      </form>
    );
  }

  function SignUpSuccessfulContainer() {
    return (
      <form onSubmit={onRegisterSubmit} className="loginfrm">
        <div className="row middleflx">
          <div className="col-sm-6">
            <div className="form-wrap">
              <h2 className="title">
                Hey
                {users.firstName}
              </h2>
              <p style={{ fontSize: '14px' }}>
                {' '}
                <strong>Step 1.</strong>
                {' '}
                You will receive an email with your login credentials.
              </p>
              <p style={{ fontSize: '14px' }}>
                <strong>Step 2.</strong>
                {' '}
                Log in and start with creating a Workspace and a Domain name.
              </p>
              <p style={{ fontSize: '14px' }}>
                <strong>Step 3.</strong>
                {' '}
                Please join our
                {' '}
                <a target="_blank" href="https://www.facebook.com/groups/crawlq" rel="noreferrer"> Facebook Group</a>
              </p>
              <p style={{ fontSize: '14px' }}>
                <strong>Step 4.</strong>
                {' '}
                Please check out our Knowledge Base articles here:
                {' '}
                <a target="_blank" href="https://crawlq.tawk.help/category/product" rel="noreferrer"> CrawlQ.ai Knowledge Base </a>
                {' '}
              </p>
              <p style={{ fontSize: '14px' }}>
                {' '}
                <strong>Step 5.</strong>
                {' '}
                Please check out our
                {' '}
                <a target="_blank " href="https://app.getbeamer.com/quantamixsolutions/en">Roadmap</a>
                {' '}
                .
                {' '}
              </p>
              <p style={{ marginTop: '40px' }}>Thank you so much for choosing us.</p>
              <p>Happy to have you on board.</p>

              <p style={{ fontWeight: 'bold', color: '#63a5f0' }}> ♥ CrawlQ Team</p>

              <h3 style={{ fontSize: '18px', marginTop: '40px', marginLeft: '12px' }}>Get Access Now 🏃‍♂️</h3>
              <div style={{
                backgroundColor: '#99FF99', height: '50px', borderRadius: '8px', border: '1px dashed green',
              }}
              >
                <p style={{ color: 'darkgreen', fontSize: '14px', padding: '12px' }}>Success, Voila! Check your mail to get login credentials.</p>
              </div>

              <div style={{ marginTop: '24px', justifyContent: 'center', display: 'flex' }}>
                <p style={{ fontSize: '12px', padding: '12px' }}><a style={{ color: '#9f9f9f' }} target="_blank" href="https://crawlq.ai/privacy-policy/" rel="noreferrer">Terms and Condition</a></p>
                {' '}
                <p style={{ fontSize: '12px', padding: '12px' }}><a style={{ color: '#9f9f9f' }} target="_blank" href="https://crawlq.ai/privacy-policy/" rel="noreferrer">Privacy Policy </a></p>
              </div>

            </div>
          </div>
          <div className="col-sm-6">
            <div className="right-logo">
              <img src={PitchGroundCrawlQ} />
            </div>
          </div>
        </div>

      </form>
    );
  }

  return (
    <div className="auth-container">
      <div className="container-wrap">
        <div className="panels-container">
          <div className="panel left-panel">
            <img src="img/appsumo.png" className="image" alt="" />
          </div>
          {/* {registermodal()} */}
          <div className="signin-signup">
            {SignUpSuccess ? SignUpContainer() : SignUpSuccessfulContainer()}
          </div>
        </div>
      </div>
      <BarLoader
        size={20}
        margin={2}
        color="#2C3E50"
        loading={isLoading}
        css={`
          position: absolute;
          top: 63%;
          left: 70%;
          border-color: #f5b848;
          z-index:"6"
        `}
      />
    </div>
  );
}

export default PitchGroundegister;
