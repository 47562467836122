import { Flex } from 'Component/Shared/GlobalStyles';
import React, { useState } from 'react';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { motion } from 'framer-motion';
import styles from '../index.module.css';
import { ExpandedContents, FormSubHeading } from '../styles';
import MarketResearchCommonInput from './MarketResearchCommonInput';

const MiddleOfTheFunnel = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <Flex direction={'column'}>
      <Flex gap={'0.5rem'} align={'center'} cursor={'pointer'}>
        {isExpanded ? (
          <KeyboardArrowDownRoundedIcon
            className={styles.icons_size_small}
            onClick={() => setIsExpanded(false)}
          />
        ) : (
          <KeyboardArrowRightRoundedIcon
            className={styles.icons_size_small}
            onClick={() => setIsExpanded(true)}
          />
        )}
        <FormSubHeading onClick={() => setIsExpanded((prev) => !prev)}>
          DESIRE <small>(Middle Of The Funnel)</small>
        </FormSubHeading>
      </Flex>
      {isExpanded && (
        <motion.div
          layout
          initial={{ opacity: 0, y: '-5vw', x: '0vw' }}
          animate={{ opacity: 1, y: 0, x: 0 }}
          transition={{ duration: 0.2, ease: 'easeInOut' }}
        >
          <ExpandedContents>
            <MarketResearchCommonInput level={'level2'} />
          </ExpandedContents>
        </motion.div>
      )}
    </Flex>
  );
};

export default MiddleOfTheFunnel;
