/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
import Axios from 'axios';
import { marketSpyingResearch } from 'Config/Api';

const getSearch = async (val) => {
  let finalResult = {};
  const faviconFetchUrl = 'https://s2.googleusercontent.com/s2/favicons?domain=';
  const keyWord = val;

  const body = { term: keyWord };
  if (keyWord === '') {
    return { error: 'Empty Search Query' };
  }

  try {
    const res = await Axios.post('https://apis.crawlq.ai/spying-wizard', body);

    const discover = [
      {
        results: res.data.data.Search,
        title: 'Search',
        src: `${faviconFetchUrl}https://www.Search.com`,
      },
      {
        results: res.data.data.Trends,
        title: 'Trend',
        src: `${faviconFetchUrl}https://www.Trend.com`,
      },
    ];
    finalResult = {
      ...finalResult,
      'Discover Semantics and Psychographics': discover,
    };
    const SocialMedia = [
      {
        results: res.data.data['Facebook General Search'],
        title: 'Facebook General',
        src: `${faviconFetchUrl}https://www.Facebook.com`,
      },
      {
        results: res.data.data['Facebook Pages'],
        title: 'Facebook Pages',
        src: `${faviconFetchUrl}https://www.Facebook.com`,
      },
      {
        results: res.data.data['Facebook Groups'],
        title: 'Facebook Groups',
        src: `${faviconFetchUrl}https://www.Facebook.com`,
      },
      {
        results: res.data.data.Twitter,
        title: 'Twitter',
        src: `${faviconFetchUrl}https://www.Twitter.com`,
      },
      {
        results: res.data.data.Pinterest,
        title: 'Pinterest',
        src: `${faviconFetchUrl}https://www.Pinterest.com`,
      },
      {
        results: res.data.data['Linked-In'],
        title: 'LinkedIn',
        src: `${faviconFetchUrl}https://www.LinkedIn.com`,
      },
      {
        results: res.data.data['Linked-In Groups'],
        title: 'LinkedIn Groups',
        src: `${faviconFetchUrl}https://www.LinkedIn.com`,
      },
      {
        results: res.data.data['YouTube Channels'],
        title: 'YouTube Channels',
        src: `${faviconFetchUrl}https://www.YouTube.com`,
      },
      {
        results: res.data.data['Social Media Trends'],
        title: 'Social Media',
        src: `${faviconFetchUrl}https://www.Social.com`,
      },
    ];

    finalResult = { ...finalResult, 'Social Media Research': SocialMedia };
    const videoSearch = [
      {
        title: 'YouTube',
        results: res.data.data.YouTube,
        src: `${faviconFetchUrl}https://www.YouTube.com`,
      },
      {
        title: 'Google',
        results: res.data.data['Google Video'],
        src: `${faviconFetchUrl}https://www.Google.com`,
      },
      {
        title: 'Google Video',
        results: res.data.data['Google Video'],
        src: `${faviconFetchUrl}https://www.Google.com`,
      },
      {
        title: 'YouTube Trends',
        results: res.data.data['YouTube Trends'],
        src: `${faviconFetchUrl}https://www.YouTube.com`,
      },
    ];
    finalResult = { ...finalResult, 'Video Search': videoSearch };

    const podCast = [
      {
        title: 'Google',
        results: res.data.data['Google Books'],
        src: `${faviconFetchUrl}https://www.Google.com`,
      },
      {
        title: 'Listen Notes',
        results: res.data.data['Listen Notes'],
        src: `${faviconFetchUrl}https://www.Listen.com`,
      },
    ];
    finalResult = {
      ...finalResult,
      'Podcasts and Podcast Directories': podCast,
    };
    const newsStoryReasearch = [
      {
        title: 'Google News',
        results: res.data.data['Google News'],
        src: `${faviconFetchUrl}https://www.Google.com`,
      },
      {
        title: 'Medium',
        results: res.data.data.Medium,
        src: `${faviconFetchUrl}https://www.Medium.com`,
      },
      {
        title: 'Bing',
        results: res.data.data.Bing,
        src: `${faviconFetchUrl}https://www.Bing.com`,
      },
      {
        title: 'Yahoo',
        results: res.data.data.Yahoo,
        src: `${faviconFetchUrl}https://www.Yahoo.com`,
      },
      {
        title: 'News Trends',
        results: res.data.data['News Trends'],
        src: `${faviconFetchUrl}https://www.Trends.com`,
      },
    ];
    finalResult = { ...finalResult, 'New Story Search': newsStoryReasearch };

    const question_and_information = [
      {
        title: 'Quora',
        results: res.data.data.Quora,
        src: `${faviconFetchUrl}https://www.Quora.com`,
      },
      {
        title: 'Wiki How',
        results: res.data.data['Wiki Hows'],
        src: `${faviconFetchUrl}https://www.Wiki.com`,
      },
      {
        title: 'Reference.com',
        results: res.data.data['Reference.com'],
        src: `${faviconFetchUrl}https://www.Reference.com`,
      },
    ];
    finalResult = {
      ...finalResult,
      'Question and Information Databases': question_and_information,
    };
    const booksServices = [
      {
        title: 'Amazon',
        results: res.data.data.Amazon,
        src: `${faviconFetchUrl}https://www.Amazon.com`,
      },
      {
        title: 'Product Hunt',
        results: res.data.data['Product Hunt'],
        src: `${faviconFetchUrl}https://www.Product.com`,
      },
      {
        title: 'Audible (Audio Books)',
        results: res.data.data['Audible (Audio Books)'],
        src: `${faviconFetchUrl}https://www.Audible.com`,
      },
      {
        title: 'Google Books',
        results: res.data.data['Google Books'],
        src: `${faviconFetchUrl}https://www.Google.com`,
      },
      {
        title: 'ClickBank',
        results: res.data.data.ClickBank,
        src: `${faviconFetchUrl}https://www.ClickBank.com`,
      },
    ];
    finalResult = { ...finalResult, 'Book and Product Searches': booksServices };

    const booksReviews = [
      {
        title: 'Ezine Articles',
        results: res.data.data['Ezine Articles'],
        src: `${faviconFetchUrl}https://www.Ezine.com`,
      },
      {
        title: 'Product',
        results: res.data.data['G2 Product Reviews'],
        src: `${faviconFetchUrl}https://www.Product.com`,
      },
    ];
    finalResult = { ...finalResult, 'Books and Product Reviews': booksReviews };

    return finalResult;
  } catch (ex) {
    console.log(ex);
  }
};

export default getSearch;
