import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import './Login.css';

// assets
import ReactPlayer from 'react-player';
import { validateUserAndRefreshToken } from 'Config/AxiosConfig';
import { setAuthToken } from 'Config/localstorageConfig';
import CrawlQLogo from '../../../assets/images/crawlQ.png';
import StarView from '../../../assets/Star.png';

// component
import LoaderSpin from '../../Shared/Loader';

// logic fn
import { LoginRequest, saveUserActivity } from './LoginLogic';
import { userLoginSuccess, setTabsEmpty } from '../../../redux';
import { fetchUserWorkspace } from '../Workspaces/Workspace/WorkspaceLogic';
import ValidatingUserLoader from './ValidateUserLoader';
import { ROUTES } from 'constants/routes';

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [Email, setEmail] = useState('');
  const [Password, setPassword] = useState('');
  const [EmptyField, setEmptyField] = useState({
    type: '',
    error: false,
  });
  const [LoginLoader, setLoginLoader] = useState(false);
  const [LoginError, setLoginError] = useState(null);
  const [ShowPassword, setShowPassword] = useState(false);
  const [isValidatingToken, setIsValidatingToken] = useState(true);

  useEffect(() => {
    localStorage.clear();
    dispatch(setTabsEmpty());
  }, []);

  // Input Field Binds
  const handleUserInput = (e) => {
    setEmptyField({
      ...EmptyField,
      type: '',
      error: false,
    });

    setLoginError(null);

    if (e.target.name === 'email') {
      setEmail(e.target.value);
    } else {
      setPassword(e.target.value);
    }
  };

  const handleWorkspaceCheck = async () => {
    const workspaces = await fetchUserWorkspace();
    if (workspaces?.result?.length > 0) {
      navigate('/chat-athena');
    } else {
      navigate(ROUTES.CHAT_ATHENA_FLOW);
    }
  };

  // Login User
  const handleSignInUser = async () => {
    const { success, data, jwt_token, destination } = await LoginRequest(
      Email,
      Password,
    );

    if (success) {
      // set users details in store
      dispatch(userLoginSuccess(data));

      // set jwt token
      setAuthToken(jwt_token);
      localStorage.setItem('username', data.username);
      localStorage.setItem('is_admin', data.admin);
      localStorage.setItem('signedUserDetails', JSON.stringify(data));
      await saveUserActivity(Password);
      await handleWorkspaceCheck();
      setLoginLoader(false);
    } else if (destination) {
      navigate(destination, {
        state: {
          email: data.email,
          name: data.name,
          old_user: data.old_user,
          location: 'login',
        },
      });
    } else {
      setLoginError(data);
      setLoginLoader(false);
    }
  };

  // Check input filled then login
  const handleCheckInput = () => {
    if (Email === '' || null) {
      setEmptyField({
        ...EmptyField,

        type: 'email',
        error: true,
      });
    } else if (Password === '' || null) {
      setEmptyField({
        ...EmptyField,
        type: 'password',
        error: true,
      });
    } else {
      setLoginLoader(true);
      handleSignInUser();
    }
  };

  useEffect(() => {
    setIsValidatingToken(true);
    validateUserAndRefreshToken()
      .then((loggedIn) => {
        if (loggedIn) {
          navigate('/chat-athena');
        }
        setIsValidatingToken(false);
      })
      .catch(() => {
        setIsValidatingToken(false);
      });
  }, []);

  if (isValidatingToken) {
    return <ValidatingUserLoader isDarkMode />;
  }

  return (
    isValidatingToken === false && (
      <div className="login-page-container">
        <div className="login-page-left-wrapper">
          <div className="login-page-left-upper-wrapper">
            <img className="logo" src={CrawlQLogo} alt="CrawlQ Logo" />
            <h5>Welcome to CrawlQ</h5>
            <span>Research and Write—100% Unique Content in 2 minutes</span>
          </div>

          <div className="login-page-left-lower-wrapper">
            <h3>
              <b>Login</b>
            </h3>

            <div className="inputbox-wrapper">
              <div className="input-container">
                <input
                  style={
                    EmptyField.type === 'email'
                      ? { border: '1.5px solid red' }
                      : null
                  }
                  name="email"
                  type="email"
                  placeholder="Email Id"
                  value={Email}
                  onChange={(e) => handleUserInput(e)}
                />
              </div>
              <div className="input-container">
                <input
                  style={
                    EmptyField.type === 'password'
                      ? { border: '1.5px solid red' }
                      : null
                  }
                  name="password"
                  type={ShowPassword ? 'text' : 'password'}
                  placeholder="Password"
                  value={Password}
                  onChange={(e) => handleUserInput(e)}
                />

                {ShowPassword ? (
                  <i
                    className="far fa-eye"
                    style={{ color: 'black' }}
                    onClick={() => setShowPassword(false)}
                  />
                ) : (
                  <i
                    className="fas fa-eye-slash"
                    style={{ color: 'black' }}
                    onClick={() => setShowPassword(true)}
                  />
                )}
              </div>
            </div>

            <p onClick={() => navigate('/forgot-password')}>
              Forgot Password ?
            </p>

            {LoginLoader ? (
              <button type="button">
                <LoaderSpin width="25" height="25" color="white" />
              </button>
            ) : (
              <button type="button" onClick={handleCheckInput}>
                Log In
              </button>
            )}

            {EmptyField.error ? <span>Fill Required Details</span> : null}

            {LoginError ? <span>{LoginError}</span> : null}
            <div>
              Don&apos;t have an account ?
              <span onClick={() => navigate(ROUTES.REGISTER)}>Register</span>
            </div>
          </div>
        </div>

        <div className="login-page-right-wrapper">
          <h3>
            Login to Dive Deep into the Subconscious Mindstates of Your Audience
          </h3>
          {/* <h3>copywriters and brands like Gartner, Dynata, and IQVIA.</h3> */}

          <div className="login-video-right">
            {/* <img src={Googlelogo} />
          <img style={{ height: '5rem', width: '7rem' }} src={DynataLogo} />
          <img style={{ height: '5rem', width: '9rem' }} src={IqviaLogo} />
          <img style={{ height: '6rem', width: '9rem' }} src={KabbaxLogo} />
          <img style={{ height: '5rem', width: '10rem' }} src={LeanCaseLogo} />
          <img style={{ height: '5rem', width: '10rem' }} src={GartnerLogo} />
          <img src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP
          ///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" />
          <img style={{ height: '3rem', width: '10rem' }} src={AmazonLogo} /> */}
            <ReactPlayer
              className="login-video"
              url="https://general-crawlq-videos.s3.us-east-2.amazonaws.com/Athena-Advance+Ai+Assistant.mp4"
              playing
              controls
              loop
              muted
              config={{
                file: {
                  attributes: { controlsList: 'nodownload' },
                },
              }}
            />
          </div>
          <div>
            <img className="review_star" src={StarView} alt="star-view" />
          </div>
        </div>
      </div>
    )
  );
}

export default Login;
