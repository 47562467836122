import { useMutation } from 'react-query';
import {
  getReduxUserState,
  getReduxWorkspaceState,
} from 'utils/helpers/redux-selectors';
import createInstance from 'Config/AxiosConfig';
import { toast } from 'react-toastify';

const marketInsights = async ({ categoryName }) => {
  const { email } = getReduxUserState();
  const { name } = getReduxWorkspaceState();

  const payload = {
    username: email,
    name,
    subcategory: categoryName,
  };

  const instance = await createInstance();

  const response = await instance.post('mrr-insights', payload);

  if (response && response?.data?.statusCode === '200') {
    return response?.data?.data;
  }

  throw new Error('Could not fetch insights');
};

export const useMutateMarketInsights = () =>
  useMutation(marketInsights, {
    onSuccess: (data) => {
      toast.success('Sucessfully Generated', {
        position: 'bottom-center',
        theme: 'dark',
      });
    },
    onError: (error) => {
      toast.error(
        'Our Insights engine is overloaded, Please try generating again',
        {
          position: 'bottom-center',
          theme: 'dark',
        },
      );
    },
  });
