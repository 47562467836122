import { ROUTES } from 'constants/routes';
import React, { useState } from 'react';
import Modal from 'react-modal';
import LoaderSpin from 'Component/Shared/Loader';
import { ButtonPrimary, Flex } from 'Component/Shared/GlobalStyles';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useIsMobile } from 'Component/hooks/useIsMobile';
import { useGetAllAssistants } from 'queries/assistants/get-assistants';
import { Colors } from 'libs/colors';
import { useNavigate } from 'react-router-dom';
import TabSwitcher from 'components/common/TabSwitcher/TabSwitcher';
import { SUPPORT_ACCOUNT_EMAIL } from 'libs/constants';
import { useSelector } from 'react-redux';
import AssistantCard from './AssistantCard';
import styles from '../../chat-athena.module.css';
import assistantStyle from './index.module.css';

const customStyles = {
  height: '700px',
  top: '50%',
  left: '50%',
  right: 'auto',
  bottom: 'auto',
  marginRight: '-50%',
  transform: 'translate(-50%, -50%)',
  width: '85vw',
  maxWidth: '1200px',
  maxHeight: '85vh',
  backgroundColor: Colors.midnightSlate,
  color: Colors.white,
};

const ASSISTANT_TABS = Object.freeze({
  Community: 0,
  Personal: 1,
});

const AssistantPicker = ({ show, setAssistant, closeModal: onCloseModal }) => {
  const user = useSelector((state) => state?.user);
  const [modalOpen, setModalOpen] = useState(show);
  const { data: assistants, isLoading } = useGetAllAssistants();
  const [currentTab, setCurrentTab] = useState(ASSISTANT_TABS.Community);
  const isMobile = useIsMobile();

  const navigate = useNavigate();

  const handleCreateNewAssistant = () => {
    navigate(ROUTES.CREATE_ASSISTANTS);
  };

  const closeModal = () => {
    setModalOpen(false);
    onCloseModal();
  };

  const handleUseAssistant = (assistant) => {
    setAssistant(assistant);
    closeModal();
  };

  const renderPromptCards = () => {
    if (!assistants || assistants.length === 0) {
      return null;
    }

    const filteredAssistants = assistants.filter((assistant) => {
      if (user?.username === SUPPORT_ACCOUNT_EMAIL) return true;
      if (currentTab === ASSISTANT_TABS.Community) {
        return assistant?.from_support;
      }
      return !assistant?.from_support;
    });

    return filteredAssistants.map((assistant) => (
      <AssistantCard assistant={assistant} onUse={handleUseAssistant} />
    ));
  };

  return (
    <Modal
      isOpen={modalOpen}
      onRequestClose={closeModal}
      contentLabel="Search Prompts Modal"
      style={{
        content: customStyles,
      }}
    >
      <h2 style={{ textAlign: 'center' }}>Assistant Picker</h2>

      {assistants && assistants.length > 0 && (
        <div className={assistantStyle.new_assistant}>
          <ButtonPrimary disabled={isMobile} onClick={handleCreateNewAssistant}>
            <span className="mx-2">New Assistant</span>
            <AddRoundedIcon />
          </ButtonPrimary>
        </div>
      )}
      <TabSwitcher
        tabs={Object.keys(ASSISTANT_TABS)}
        setSwitcher={setCurrentTab}
      />
      {isLoading ? (
        <Flex
          style={{ width: '100%', height: '400px' }}
          align="center"
          justify="center"
        >
          <LoaderSpin />
        </Flex>
      ) : (
        <div className={assistantStyle.assistant_wrapper}>
          {renderPromptCards()}
        </div>
      )}
      {!isLoading && assistants.length === 0 && (
        <div className={styles.not_found_message}>
          <h4>You have not created any assistant.</h4>
          <ButtonPrimary disabled={isMobile} onClick={handleCreateNewAssistant}>
            <span className="mx-2">New Assistant</span>
            <AddRoundedIcon />
          </ButtonPrimary>
        </div>
      )}
    </Modal>
  );
};

export default AssistantPicker;
