/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import lockIcon from 'Component/../assets/dashboard-imgs/locked.png';
import unlockIcon from 'Component/../assets/dashboard-imgs/unlocked.png';
import LockContentAutomation from 'Component/Pages/ContentAutomation/Shared/LockContentAutomation';
import OnBoardingQueLevel3 from 'Component/Pages/Workspaces/onBoarding/onBoardingQueLevelThree';
import OnBoardingQueLevel2 from 'Component/Pages/Workspaces/onBoarding/onBoardingQueLeveTwo';
import CustomTooltip from 'Component/Shared/CustomTooltip';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getDestinationForLevel } from './helper';

const LEVEL_NAME_MAP_TO_NUMBER = {
  1: 'ATTRACT',
  2: 'DESIRE',
  3: 'CONVERT',
};

function MRRLevelOnDashboard({ progressPercentage }) {
  const navigate = useNavigate();

  const { plan } = useSelector((state) => state.user);
  const { is_trained: isWorkspaceTrained } = useSelector(
    (state) => state.workspace,
  );
  const [mrrqna, setMrrqna] = useState({ isLoaded: false, data: {} });

  const hasFeatureLocked = () => {
    if (
      plan !== 'CrawlQ Entry Plan (Monthly)' &&
      plan !== 'CrawlQ Entry Plan (Yearly)'
    ) {
      return false;
    }
    return true;
  };

  const isLevelOneCompleted = () => progressPercentage.percentage === 100;

  const isLevelTwoCompleted = () =>
    isLevelOneCompleted() && progressPercentage.percentage2 === 100;

  const isLevelThreeCompleted = () => progressPercentage.percentage3 === 100;

  const handleLevelOne = () => {
    navigate('/market-research');
  };

  const handleLevelTwo = async () => {
    if (!isWorkspaceTrained) return;
    if (hasFeatureLocked()) {
      document.getElementById('upgradePlan-popup').click();
      return;
    }
    if (!isLevelOneCompleted()) return;
    const {
      destination,
      mrr_filled,
      error,
      showPopup,
    } = await getDestinationForLevel(2);

    if (error) return;
    setMrrqna({ isLoaded: false, data: mrr_filled });
    if (showPopup) {
      const btn = document.getElementById('onBoarding_level_2_form');
      btn.click();
      return;
    }
    localStorage.setItem('category', 'Niche');
    navigate(destination);
  };

  const handleLevelThree = async () => {
    if (hasFeatureLocked()) {
      document.getElementById('upgradePlan-popup').click();
      return;
    }
    if (!isLevelTwoCompleted()) return;

    // setCurentLoadingLevel(3)
    const { error, showPopup } = await getDestinationForLevel(3);
    // setCurentLoadingLevel(0)
    if (error) return;
    if (showPopup) {
      const btn = document.getElementById('onBoarding_level_3_form');
      btn.click();
      return;
    }
    navigate('/market-research/convert');
  };

  return (
    <div className="dashboard-progress-container my-2">
      <OnBoardingQueLevel2 answers={mrrqna.data} />
      <OnBoardingQueLevel3 />
      <div
        className="dashboard-completed dashboard-mrr-level-btns"
        style={{ width: '15rem' }}
      >
        <div onClick={handleLevelOne} style={{ cursor: 'pointer' }}>
          <span
            className={`${
              isLevelOneCompleted() ? 'text-success' : 'txt-primary'
            } font-primary`}
          >
            {LEVEL_NAME_MAP_TO_NUMBER[1]}
          </span>
          <img src={unlockIcon} />
        </div>
        <CustomTooltip
          title={
            isWorkspaceTrained
              ? ''
              : 'Uh-Oh! Looks like you have not trained Athena. Train Athena now, to unlock this level of Market Research'
          }
          arrow
          placement="top"
        >
          <div
            onClick={handleLevelTwo}
            style={
              isLevelOneCompleted()
                ? { cursor: 'pointer' }
                : { cursor: 'not-allowed' }
            }
          >
            <span
              className={`${
                isLevelOneCompleted()
                  ? isLevelTwoCompleted()
                    ? 'text-success'
                    : 'txt-primary'
                  : 'text-muted'
              } font-primary `}
            >
              {LEVEL_NAME_MAP_TO_NUMBER[2]}
            </span>

            <img src={isLevelOneCompleted() ? unlockIcon : lockIcon} />
          </div>
        </CustomTooltip>
        <div
          onClick={handleLevelThree}
          style={
            isLevelTwoCompleted()
              ? { cursor: 'pointer' }
              : { cursor: 'not-allowed' }
          }
        >
          <span
            className={`${
              isLevelTwoCompleted()
                ? isLevelThreeCompleted()
                  ? 'text-success'
                  : 'txt-primary'
                : 'text-muted'
            } font-primary`}
          >
            {LEVEL_NAME_MAP_TO_NUMBER[3]}
          </span>

          <img src={isLevelTwoCompleted() ? unlockIcon : lockIcon} />
        </div>
      </div>
    </div>
  );
}

export default React.memo(MRRLevelOnDashboard);
