import React, { useState } from 'react';
import Modal from 'react-modal';
import TipsAndUpdatesRoundedIcon from '@mui/icons-material/TipsAndUpdatesRounded';
import CustomTooltip from 'Component/Shared/CustomTooltip';
import { HelpCenterRounded, HelpRounded } from '@mui/icons-material';
import { DefaultPropmpts } from './DefaultPrompts';

const customStyles = {
  height: '600px',
  top: '50%',
  left: '50%',
  right: 'auto',
  bottom: 'auto',
  marginRight: '-50%',
  transform: 'translate(-50%, -50%)',
  width: '95vw',
  maxWidth: '1200px',
};

const PromptsModal = ({ sendMessage, darkMode = true }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div>
      <CustomTooltip title={'Guided Insights'} arrow placement="bottom">
        <TipsAndUpdatesRoundedIcon style={{ color: 'yellow' }} onClick={openModal} />
      </CustomTooltip>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            ...customStyles,
            backgroundColor: darkMode ? '#343641' : 'white',
            color: darkMode ? 'white' : '343641',
          },
        }}
      >
        <div
          style={{
            padding: '2rem',
          }}
        >
          <DefaultPropmpts sendMessage={sendMessage} closeModal={closeModal} />
        </div>
      </Modal>
    </div>
  );
};

export default PromptsModal;
