import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import createInstance from 'Config/AxiosConfig';

// Keep Query Value as File Name to maintain consistency and uniqueness in query index
export const FIVETRAN_CONNECTED_APPS_QUERY_KEY =
  'get-all-connected-apps-query-key';

// Query Config Options
const queryOptions = {
  staleTime: 10 * 60 * 1000,
  // 10 mins
};

// QUERY
export const useGetConnectedFivetranApps = () => {
  const user = useSelector((state) => state?.user);

  const fetchConnectedApps = async () => {
    try {
      const instance = await createInstance();
      const { data } = await instance.get(
        `/get-connectors?email=${user.username}&include_schema=True
      `,
      );
      if (data.statusCode === '200') {
        return data.data;
      }
      throw new Error('Error in fetching connector params');
    } catch (ex) {
      console.log(ex);
      throw ex;
    }
  };
  return useQuery(
    [FIVETRAN_CONNECTED_APPS_QUERY_KEY],
    fetchConnectedApps,
    queryOptions,
  );
};
