import React, { useState } from 'react';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import styles from '../../chat-athena-components.module.css';
import { PROMPTS } from '../../constants/prompts';

function propmtMapper(data) {
  return {
    name: data.template_name,
    description: data.template_description,
    message: data.sections
      .map((section) => section.template_text_prompt)
      .join('\n\n'),
    author_email: data.author_email,
    type: 'SEO',
  };
}

export function DefaultPropmpts({ sendMessage, closeModal = null }) {
  const [hoveredIndex, setHoveredIndex] = useState(-1);

  const handleSendMessage = (prompt) => {
    sendMessage(prompt.message);
    if (closeModal) {
      closeModal();
    }
  };

  return (
    <div className={styles.introduction_component}>
      <h2>Get Started</h2>
      <div className={styles.templates_wrapper}>
        {PROMPTS.map(propmtMapper).map((prompt, index) => (
          <div
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(-1)}
            onClick={() => handleSendMessage(prompt)}
            className={styles.message_wrapper}
          >
            <div className={styles.message}>
              <b>{prompt.name}</b>
              <p>{prompt.description}</p>
            </div>
            <div
              className={styles.icon}
              style={{ opacity: hoveredIndex === index ? 1 : 0 }}
            >
              <SendRoundedIcon />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
