/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable camelcase */
import axios from 'axios';
import { baseAPIsUrl, salesCopywritingWizard } from 'Config/Api';

const handleGetAllSubscripts = async () => {
  try {
    const { data } = await axios.get(salesCopywritingWizard);
    return { data: data.data, error: false };
  } catch (ex) {
    console.log('Error in getting all Subscripts', ex);
    return { data: null, error: true };
  }
};

export const getAllQuestionsSales = async (scriptID) => {
  try {
    const ls = localStorage;
    const { data } = await axios.get(
      `${baseAPIsUrl}/load-script?script_id=${scriptID}&username=${ls.getItem(
        'username',
      )}&name=${ls.getItem('name')}`,
    );
    const newData = data.data.map((oneData) => ({ ...oneData, answer: oneData.autofill_suggestion || '' }));
    return {
      data: newData,
      error: false,
    };
  } catch (ex) {
    console.log('Error in getting Que in Sales', ex);
    return {
      data: null,
      error: true,
    };
  }
};

export const getAllAnswerFromAI = async (
  questions,
  main_topic_ans,
  description,
) => {
  try {
    const axiosConfig = {
      multiValueHeaders: {
        'Content-Type': ['application/json'],
        'Access-Control-Allow-Origin': ['*'],
        'Access-Control-Allow-Headers': [
          'Content-Type',
          'X-Amz-Date',
          'Authorization',
          'X-Api-Key',
          'X-Amz-Security-Token',
        ],
      },
    };

    const userDesc = {
      username: localStorage.getItem('username'),
      name: localStorage.getItem('name'),
    };

    const script_description = [
      {
        answer: main_topic_ans,
        question: 'What is your Topic Of Interest?',
      },
      {
        main_topic: {
          answer: description,
          question:
            'What is your users Main Goal/ Objective/ Pain-Point/ Benefit/ Desire ?',
        },
      },
    ];

    const script_data = questions.map((question, index) => ({
      question: question.input_question,
      answer: question.answer || '',
      example: '',
      id: index,
    }));

    const script_data_part1 = script_data.slice(0, 50);
    const script_data_part2 = script_data.slice(50, 100);
    const script_data_part3 = script_data.slice(100, 150);

    const body1 = {
      ...userDesc,
      input_data: { script_data: script_data_part1, script_description },
    };

    const res1 = await axios.post(
      'https://semantic.crawlq.ai/',
      JSON.stringify(body1),
      axiosConfig,
    );

    let res2 = { data: [] };
    let res3 = { data: [] };

    if (script_data_part2.length > 0) {
      const body2 = {
        ...userDesc,
        input_data: { script_data: script_data_part2, script_description },
      };

      res2 = await axios.post(
        'https://semantic.crawlq.ai/',
        JSON.stringify(body2),
        axiosConfig,
      );
      if (script_data_part3.length > 0) {
        const body3 = {
          ...userDesc,
          input_data: { script_data: script_data_part3, script_description },
        };

        res3 = await axios.post(
          'https://semantic.crawlq.ai/',
          JSON.stringify(body3),
          axiosConfig,
        );
      }
    }

    const data = [...res1.data, ...res2.data, ...res3.data];
    data.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));
    return { data, error: false };
  } catch (ex) {
    console.log('Error in Fill With AI', ex);
    return { res: null, error: true };
  }
};

export const getAllAnswerFromAIEach = async (
  question,
  main_topic_ans,
  description,
) => {
  try {
    const userDesc = {
      username: localStorage.getItem('username'),
      name: localStorage.getItem('name'),
    };
    const script_data = [
      {
        question: question.input_question,
        answer: '',
        example: '',
      },
    ];
    const script_description = [
      {
        answer: main_topic_ans,
        question: 'What is your Topic Of Interest?',
      },
      {
        main_topic: {
          answer: description,
          question:
            'What is your users Main Goal/ Objective/ Pain-Point/ Benefit/ Desire ?',
        },
      },
    ];
    const body = {
      ...userDesc,
      input_data: { script_data, script_description },
    };
    console.log('body', body);
    const { data } = await axios.post(
      `${baseAPIsUrl}/scripts-fill-with-ai`,
      body,
    );
    return { data: data.script_output, error: false };
  } catch (ex) {
    console.log('Error in Fill With AI Each', ex);
    return { data: null, error: true };
  }
};

export const getGeneratedOutput = async (script_id, answers, script_name) => {
  try {
    // let body = {
    //   script_id,
    //   data: answers,
    // }
    let body = {};
    body.script_id = script_id.toString();
    body.data = answers;

    const { data } = await axios.post(`${baseAPIsUrl}/build-script`, body);

    body = {
      script_id,
      input_questions: answers,
      username: localStorage.getItem('username'),
      script_name,
      name: localStorage.getItem('name'),
    };
    // eslint-disable-next-line no-unused-vars
    const res = await axios.post(`${baseAPIsUrl}/save-script`, body);

    return {
      data: data.output,
      error: false,
    };
  } catch (ex) {
    console.log('Error in Generating Outut', ex);
    return {
      data: null,
      error: true,
    };
  }
};

// not in use to get blogs since it is independent in Publoshed articles
export const getSavedDocs = async () => {
  try {
    const body = {
      username: localStorage.getItem('username'),
      name: localStorage.getItem('name'),
      source: 'Sales Copywriter',
    };
    const { data } = await axios.post('https://apis.crawlq.ai/ai-writer-select', body);

    const result = data.blog;
    const docs = [];

    for (let i = 0; i < result.length; i++) {
      const obj = {
        blog_name: result[i].blog,
        blog_author: result[i].fullname,
        blog_readtime: result[i].reading_time,
        blog_script: result[i].script_name,
      };

      docs.push(obj);
    }

    return { error: false, docs };
  } catch (ex) {
    console.log('Error in getting SavedDoc', ex);
    return { error: true, docs: [] };
  }
};

// sHIFTED TO PUBLISHED ARTICLES HELPER FN

// export const openSavedDocument = async docName => {
//   try {
//     const body = {
//       username: localStorage.getItem('username'),
//       name: localStorage.getItem('name'),
//       blog_name: docName,
//       source: 'Sales Copywriter',
//     }
//     const { data } = await axios.post(
//       'https://apis.crawlq.ai/ai-writer-load',
//       body
//     )

//     return {
//       data: {
//         goolgeQuery: data.blog.topic,
//         query: docName,
//         output: data.blog.text,
//         lang: 'US',
//         save: true,
//         blogId: data.blog.id,
//       },
//       error: false,
//     }
//   } catch (ex) {
//     console.log('Error in Opening Document', ex)
//     return {
//       error: true,
//       data: null,
//     }
//   }
// }

export default handleGetAllSubscripts;
