import { MANAGE_TEMPLATES_FILTER_OPTIONS } from 'components/constants';
import React from 'react';
import styles from './display-templates.module.css';

export const FilterOptions = ({ tab, setTab }) => (
    <select
      className={styles.select_box}
      value={tab}
      onChange={(e) => setTab(e.target?.value)}
    >
      {MANAGE_TEMPLATES_FILTER_OPTIONS.map((TAB, tabIndex) => (
        <option key={tabIndex} value={TAB}>
          {TAB}
        </option>
      ))}
    </select>
);
