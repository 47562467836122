import createInstance from 'Config/AxiosConfig';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

async function syncConnector(connector) {
  const { connector_id } = connector;

  if (!connector_id) {
    throw new Error('Invalid Connector');
  }

  try {
    const instance = await createInstance();

    const body = {
      connector_id,
    };

    const { data } = await instance.post('/sync-connector', body);
    if (data.statusCode === '200') {
      return data.data;
    }
    throw new Error('Error in creating connector');
  } catch (ex) {
    console.log(ex);
    throw new Error(`Error in Creating connector ${ex.message}`);
  }
}

export function useSyncConnectorMutation() {
  return useMutation(syncConnector, {
    onError: (error) => {
      toast.error(error.message, {
        position: 'bottom-center',
        theme: 'dark',
      });
    },

    onSuccess: () => {
      toast.success('Connector Synced successfully', {
        position: 'bottom-center',
        theme: 'dark',
      });
    },
  });
}
