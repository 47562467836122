/* eslint-disable max-len */
export const AUDIENCE_VOICE = 'Audience Voice';
export const TRENDING_TOPICS = 'Trending Topics';
export const COMPETING_TOPICS = 'Competing Topics';
export const INTENT_QUESTION = 'Intent Question';
export const KEYWORD_RESEARCH = 'Keyword Research';
export const TABS = [AUDIENCE_VOICE, TRENDING_TOPICS, COMPETING_TOPICS, INTENT_QUESTION, KEYWORD_RESEARCH];

export const FILTER_OPTIONS = [
  { key: 'mrr', value: 'Market Research', checked: false },
  { key: 'mrr_insight', value: 'Market Insights', checked: false },
  { key: 'script', value: 'Sales Copywriting', checked: false },
  { key: 'serp', value: 'Google', checked: false },
  { key: 'quora', value: 'Quora', checked: false },
  { key: 'reddit', value: 'Reddit', checked: false },
  { key: 'website', value: 'URL', checked: false },
];
