/* eslint-disable max-len */
import React from 'react';
import Modal from 'react-modal';

const ModalStyles = {
  backgroundColor: 'red',
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width: 'max-content',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const ModalContainer = ({ isOpen, toggleClose, children, widthValue }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={() => toggleClose()}
    style={widthValue ? { ...ModalStyles, content: { ...ModalStyles.content, width: widthValue } } : ModalStyles}
    ariaHideApp={false}
  >
    {children}
  </Modal>
);

export default ModalContainer;
