import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1;
  align-items: baseline;
  width: 100%;
`;

export const HeadingName = styled.b`
  font-size: var(--text-size-xlarge);
`;

export const HeadingDesc = styled.span`
  color: var(--primary-color);
`;

export const TeamMemberWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const TeamMemberContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 840px;
  max-width: 1080px;
  height: 80vh;
  flex: 0.7 1;
  gap: var(--text-size-medium);
`;

export const ProfileComponents = styled.div`
  flex: 1 1;
  display: flex;
  align-items: center;
  padding: var(--text-size-small) var(--text-size-medium);
`;

export const ProfileImage = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--text-size-xlarge);
  font-weight: 400;
  cursor: pointer;
  color: var(--white-color);
  background-color: var(--primary-color);
`;

export const ProfileDetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1 1;
`;

export const ProfleDetails = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  margin-left: var(--text-size-medium);
  flex: 1 1;
  align-items: ${(props) => (props.end ? 'flex-end' : '')};
`;

export const ShadowContainer = styled.div`
  flex: ${(props) => props.flex};
  display: flex;
  flex-direction: column;
  padding: var(--text-size-default) var(--text-size-large);
  border-radius: 1rem;
  box-shadow: 0 0 5px var(--shadow-color);
  justify-content: space-around;
  &.table-striped {
    color: var(--danger-color);
  }
  gap: var(--text-size-medium);
`;

export const FeildsWrapper = styled.div`
  display: flex;
  justify-content: ${(props) => (props.center ? 'center' : 'space-between')};
  &.MuiRadio-colorPrimary.Mui-checked {
    color: var(--danger-color);
  }
`;

export const ButtonBasic = styled.button`
  border-radius: 4px;
  background-color: var(--white-color);
  padding: var(--text-size-small) var(--text-size-medium);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonPrimary = styled(ButtonBasic)`
  background-color: var(--primary-color);
  color: var(--white-color);
`;

export const ButtonSecondary = styled(ButtonBasic)`
  color: var(--secondary-color);
  background-color: var(--background-secondary-color);
  border: none;
`;

export const PillWrapper = styled.div`
  display: flex;
  gap: var(--text-size-medium);
  border-radius: 20px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  margin-bottom: var(--text-size-medium);
  width: fit-content;
  padding: var(--text-size-small);
  align-items: center;
  background: var(--background-secondary-color);
  cursor: pointer;
`;

export const PillComponent = styled.div`
  font-weight: 600;
  transition: 0.3s;
  border-radius: 20px;
  text-align: center;
  background-color: ${(props) =>
    props.active
      ? 'var(--primary-color)'
      : 'var(--background-secondary-color)'};
  color: ${(props) =>
    props.active ? 'var(--white-color)' : 'var(--secondary-color)'};
  width: 6rem;
`;

export const ExpandedMenuWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  padding: var(--text-size-default);
`;

export const ButtonDisabled = styled(ButtonBasic)`
  background-color: var(--white-color);
  color: gray;
`;

export const ButtonDanger = styled(ButtonBasic)`
  background-color: var(--danger-color);
  color: var(--secondary-color);
`;

export const Label = styled.span`
  color: var(--secondary-color);
`;
