import React, { useState, useContext } from 'react';
import { useGetOptionsForBrandVoice } from 'queries/brand-voice/get-options-of-brand-voice';
import saveBrandVoice from 'queries/brand-voice/save-brand-voice';
import { useSelector } from 'react-redux';
import LoaderSpin from 'Component/Shared/Loader';
import { SAVED_BRAND_VOICES } from 'components/constants';
import CreateRoundedIcon from '@mui/icons-material/CreateRounded';
import { showNotification } from 'libs/notification';
import { Divider } from 'antd';
// eslint-disable-next-line import/no-cycle
import { TabContext } from '../BrandVoice';
import DropdownOptions from './DropdownOptions';
import styles from '../style.module.css';

const NewBrandVoice = () => {
  const setChoosenTab = useContext(TabContext);

  const { data } = useGetOptionsForBrandVoice();

  const { name } = useSelector((state) => state.workspace);

  const [brandVoiceName, setBrandVoiceName] = useState('');

  const [selectedValues, setSelectedValues] = useState({
    creativeGoal: '<creativeGoal>',
    creativeTheme: '<creativeTheme>',
    tonality: '<tonality>',
    mimicStyle: '<mimicStyle>',
    personalityType: '<personalityType>',
    literaryDevice: '<literaryDevice>',
    audienceProfile: '<audienceProfile>',
    hypnoticTechnique: '<hypnoticTechnique>',
  });
  const [isSavingBrandVoice, setIsSavingBrandVoice] = useState(false);
  const [isEditingBrandVoice, setIsEditingBrandVoice] = useState('');
  const [brandVoicePara, setBrandVoicePara] = useState('');

  function generateParagraph(config) {
    return `Your goal is to ${config.creativeGoal} targeting ${config.audienceProfile}. The creative theme should revolve around ${config.creativeTheme}. Maintain a ${config.tonality} style throughout the content. Mimic the writing style of ${config.mimicStyle} and incorporate a ${config.personalityType} personality type. Make use of literary devices such as ${config.literaryDevice}. Additionally, incorporate the hypnotic-NLP conversational technique known as ${config.hypnoticTechnique}.`;
  }

  const handleDropdownChange = (value, dropdownName) => {
    setSelectedValues((prevState) => ({
      ...prevState,
      [dropdownName]: value,
    }));
    setBrandVoicePara(generateParagraph(selectedValues));
  };

  const handleSaveBrandVoice = () => {
    if (brandVoicePara.trim() === '') {
      showNotification('error', 'Brand Voice Description Cannot be Empty');
      return;
    }

    if (brandVoiceName.trim() === '') {
      showNotification('error', 'Brand Voice Name Cannot be Empty');
      return;
    }

    setIsSavingBrandVoice(true);
    const body = {
      brand_voice_name: brandVoiceName,
      configurations: {
        creative_goal: selectedValues.creativeGoal,
        audience_profile: selectedValues.audienceProfile,
        creative_theme: selectedValues.creativeTheme,
        tonality: selectedValues.tonality,
        mimic_style: selectedValues.mimicStyle,
        personality_type: selectedValues.personalityType,
        literary_device: selectedValues.literaryDevice,
        hypnotic_technique: selectedValues.hypnoticTechnique,
      },
      description: generateParagraph(selectedValues),
      name,
    };

    saveBrandVoice(body).then(() => {
      setIsSavingBrandVoice(false);
      setChoosenTab(SAVED_BRAND_VOICES);
    }).finally(() => {
      setIsSavingBrandVoice(false);
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.dropdown_group}>
        <DropdownOptions
          label="Creative Goal"
          options={data?.creative_goal || []}
          value={selectedValues.creativeGoal}
          onChange={(value) => handleDropdownChange(value, 'creativeGoal')}
        />

        <DropdownOptions
          label="Audience Profile"
          options={data?.audience_profile || []}
          value={selectedValues.audienceProfile}
          onChange={(value) => handleDropdownChange(value, 'audienceProfile')}
        />

        <DropdownOptions
          label="Creative Theme"
          options={data?.creative_theme || []}
          value={selectedValues.creativeTheme}
          onChange={(value) => handleDropdownChange(value, 'creativeTheme')}
        />

        <DropdownOptions
          label="Tonality"
          options={data?.tonality || []}
          value={selectedValues.tonality}
          onChange={(value) => handleDropdownChange(value, 'tonality')}
        />

        <div>
          <b style={{ color: '#9a9595' }}>Advanced</b>
          <Divider
            style={{
              margin: 0,
            }}
          />
        </div>

        <DropdownOptions
          label="Mimic Writing Style"
          options={data?.mimic_style || []}
          value={selectedValues.mimicStyle}
          onChange={(value) => handleDropdownChange(value, 'mimicStyle')}
        />

        <DropdownOptions
          label="Personality Type"
          options={data?.personality_type || []}
          value={selectedValues.personalityType}
          onChange={(value) => handleDropdownChange(value, 'personalityType')}
        />

        <DropdownOptions
          label="Literary Device"
          options={data?.literary_device || []}
          value={selectedValues.literaryDevice}
          onChange={(value) => handleDropdownChange(value, 'literaryDevice')}
        />

        <DropdownOptions
          label="Hypnotic Technique"
          options={data?.hypnotic_technique || []}
          value={selectedValues.hypnoticTechnique}
          onChange={(value) => handleDropdownChange(value, 'hypnoticTechnique')}
        />
      </div>
      <div className={styles.para}>
        <div style={{ display: 'flex', width: '100%' }}>
          {!isEditingBrandVoice ? (
            <p>
              Your goal is to
              <span>{selectedValues.creativeGoal}</span>
              targeting
              <span>{selectedValues.audienceProfile}</span> The creative theme
              should revolve around<span>{selectedValues.creativeTheme}.</span>
              Maintain a<span>{selectedValues.tonality}</span>
              style through the content. Mimic the writing style of
              <span>{selectedValues.mimicStyle}</span>
              and incorporate a<span>{selectedValues.personalityType}</span>
              personality type. Make use of literary devices such as
              <span>{selectedValues.literaryDevice}.</span> Additionally,
              incorporate the hypnotic-NLP conversational technique known as
              <span>{selectedValues.hypnoticTechnique}</span>
            </p>
          ) : (
            <textarea
              style={{ width: '100%', height: '10rem' }}
              onChange={(e) => setBrandVoicePara(e.target.value)}
              value={brandVoicePara}
            />
          )}
          <CreateRoundedIcon
            onClick={() => {
              setBrandVoicePara(generateParagraph(selectedValues));
              setIsEditingBrandVoice(!isEditingBrandVoice);
            }}
          />
        </div>
        <div className={styles.button_group}>
          <input
            value={brandVoiceName}
            onChange={(e) => setBrandVoiceName(e.target.value)}
            type="text"
            placeholder="Brand Voice Name"
          />
          {!isSavingBrandVoice ? (
            <button className={styles.create_button} onClick={handleSaveBrandVoice} type="button">
              Create Brand Voice
            </button>
          ) : (
            <LoaderSpin />
          )}
        </div>
      </div>
    </div>
  );
};

export default NewBrandVoice;
