/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  KeywordResearchWrapper, AddKeywordButton, KeywordInput, AddKeywordButtonDisabled,
} from './styles';

function KeywordResearch({ addToFinalList, limitReached }) {
  const [UserInput, setUserInput] = useState('');

  const handleAdd = () => {
    addToFinalList(UserInput);
    setUserInput('');
  };

  return (
    <KeywordResearchWrapper>
      <KeywordInput
        type="text"
        placeholder="Add your custom keyword"
        value={UserInput}
        onChange={(event) => setUserInput(event.target.value)}
      />

      {!limitReached
        ? <AddKeywordButton onClick={handleAdd}>Add</AddKeywordButton>
        : <AddKeywordButtonDisabled>Add</AddKeywordButtonDisabled>}
    </KeywordResearchWrapper>
  );
}

export default KeywordResearch;
