/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import './onBoarding.css';
import { useNavigate } from 'react-router-dom';
import aiJpg from '../../../../assets/images/AI.svg';

function OnBoardingLoader({ hasAPIFullfilled, savingError }) {
  const navigate = useNavigate();

  const [error, setError] = useState(false);

  const handleClick = () => {
    if (hasAPIFullfilled) {
      navigate('/MRR-Get-Started');
      return;
    }
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 3000);
  };
  return (
    <div className="onBoading_loader_container">
      <div className="onBoading_loader_message">
        <div>
          <b>Sit back and Relax!</b>
          <div className="mt-1">
            <span>
              Athena is researching for unique insights and answers so that you
              can cut down research time by 5x and deliver more content faster
              than you have ever imagined.
            </span>
          </div>
        </div>
        <div>
          <b>Next Steps</b>
          <ol>
            <li>
              You will be taken to Market Research where 55 answers to critical
              business questions are auto-filled.
            </li>
            <li> You can review them and generate new insights.</li>
            <li> Lastly, train Athena on your updated answers and insights.</li>
          </ol>
        </div>
        <div className="onBoarding_btn_container">
          <img width="300" src={aiJpg} />
          <button
            className="onBoarding_continue_btn font-accent"
            onClick={handleClick}
          >
            <span>Continue</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 74 74"
              height="34"
              width="34"
            >
              <circle
                strokeWidth="3"
                stroke="black"
                r="35.5"
                cy="37"
                cx="37"
              />
              <path
                fill="black"
                d="M25 35.5C24.1716 35.5 23.5 36.1716 23.5 37C23.5 37.8284 24.1716 38.5 25 38.5V35.5ZM49.0607 38.0607C49.6464 37.4749 49.6464 36.5251 49.0607 35.9393L39.5147 26.3934C38.9289 25.8076 37.9792 25.8076 37.3934 26.3934C36.8076 26.9792 36.8076 27.9289 37.3934 28.5147L45.8787 37L37.3934 45.4853C36.8076 46.0711 36.8076 47.0208 37.3934 47.6066C37.9792 48.1924 38.9289 48.1924 39.5147 47.6066L49.0607 38.0607ZM25 38.5L48 38.5V35.5L25 35.5V38.5Z"
              />
            </svg>
          </button>
          {error && (
            <p className="text-danger mt-3">
              Athena is still processing. Please wait for sometime.
            </p>
          )}
          {savingError !== '' && (
            <p className="text-danger mt-3">{savingError}</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default OnBoardingLoader;
