/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/no-distracting-elements */
import { onBoardingSteps } from 'libs/enums/onboarding-steps.enum';
import { Link } from 'react-router-dom';
import React from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Flex } from 'Component/Shared/GlobalStyles';
import { ProgressWrapper, GreenTick, Heading, ProgressBox } from '../styles';
import GetCurrentSatatusIcon from './GetIconForStatus';
import { WORKSPACE_CREATION_STAGES } from './helpers';

const ProgressComponent = ({
  workspaceName,
  data,
  viewMore,
  openMRR,
  fromStatusPage,
  date_created,
}) => (
  <ProgressWrapper>
    <Flex justify={'space-between'} align={'center'}>
      <Heading>{workspaceName}</Heading>
      <RefreshIcon />
    </Flex>
    <Flex justify={'space-between'}>
      <ProgressBox>
        <GreenTick />
        <b>{onBoardingSteps.Seed}</b>
      </ProgressBox>

      {
        WORKSPACE_CREATION_STAGES.map((stage) => (
          <GetCurrentSatatusIcon
            statusData={data}
            openMRR={openMRR}
            levelName={stage}
            fromstatusPage={fromStatusPage}
          />
        ),
        )
      }

      {/* <GetCurrentSatatusIcon
        data={data}
        openMRR={openMRR}
        level={onBoardingSteps.Attract}
        fromstatusPage={fromStatusPage}
      />
      <GetCurrentSatatusIcon
        data={data}
        openMRR={openMRR}
        level={onBoardingSteps.Listen}
        fromstatusPage={fromStatusPage}
      />
      <GetCurrentSatatusIcon
        data={data}
        openMRR={openMRR}
        level={onBoardingSteps.Desire}
        fromstatusPage={fromStatusPage}
      />
      <GetCurrentSatatusIcon
        data={data}
        openMRR={openMRR}
        level={onBoardingSteps.Learn}
        fromstatusPage={fromStatusPage}
      />
      <GetCurrentSatatusIcon
        data={data}
        openMRR={openMRR}
        level={onBoardingSteps.Convert}
        fromstatusPage={fromStatusPage}
      />
      <GetCurrentSatatusIcon
        data={data}
        openMRR={openMRR}
        level={onBoardingSteps.Triage}
        fromstatusPage={fromStatusPage}
      /> */}
    </Flex>
    {viewMore && (
      <Flex justify={'space-between'}>
        <p>
          <b>Created on:</b> {date_created}
        </p>
        <small>
          <Link
            to={`/build-workspace-status-detailed?from_status_page=${true}&workspace=${workspaceName}`}
          >
            View More
          </Link>
        </small>
      </Flex>
    )}
    {!viewMore && fromStatusPage !== 'true' && (
      <div>
        <marquee>
          Athena is researching through different stages of your Customer
          Decisions Journey.
        </marquee>
        <marquee direction="right">
          Don't leave this page until Athena triages and redirects to your
          Market Research.
        </marquee>
      </div>
    )}
  </ProgressWrapper>
);

export default ProgressComponent;
