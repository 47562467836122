import React from 'react';
import { useSelector } from 'react-redux';
import { ScaffoldCard } from 'components/common';
import { ResponsiveLine } from '@nivo/line';
import { useGetQueriesVsTimeActivity } from 'queries/graph-info/get-queries-vs-time-activity';
import styles from './queries-graph.module.css';

const THEME = {
  text: {
    fill: 'white',
  },
};

function QueriesGraph() {
  const workspaceName = useSelector((state) => state?.workspace?.name);
  const { data: graphInfo, isLoading } = useGetQueriesVsTimeActivity();

  // if (!graphInfo || graphInfo.length === 0) {
  //   return <div />;
  // }

  return (
    <ScaffoldCard className={styles.queries_container}>
      <div className={styles.queries_header_container}>
        <div className={styles.header}>
          Queries Generated for {workspaceName}
        </div>
        <span className={styles.subheader}>
          A graphical representation of queries generated
        </span>
      </div>
      <div className={styles.graph_container}>
        {graphInfo && (
          <ResponsiveLine
            data={[
              {
                id: 'fake corp. A',
                data: graphInfo && graphInfo.length > 0 ? graphInfo : [
                  {
                    x: '2024-01-01',
                    y: 1,
                  },
                ],
              },
            ]}
            animate
            enableArea
            axisBottom={{
              pointColor: 'white',
              format: '%b %d',
              legend: 'Time Scale',
              legendOffset: 60,
              tickValues: 'every 5 days',
              tickRotation: '90',
            }}
            axisLeft={{
              legend: 'Queries Created',
              legendOffset: -30,
            }}
            curve="monotoneX"
            enablePointLabel
            enableTouchCrosshair
            height={400}
            margin={{
              bottom: 60,
              left: 40,
              right: 20,
              top: 20,
            }}
            theme={THEME}
            pointBorderColor={{
              from: 'color',
              modifiers: [['darker', 0.3]],
            }}
            pointBorderWidth={1}
            pointSize={16}
            pointColor={'#70fff5'}
            xFormat="time:%Y-%m-%d"
            xScale={{
              format: '%Y-%m-%d',
              precision: 'day',
              type: 'time',
              useUTC: false,
            }}
            yScale={{
              type: 'linear',
            }}
          />
        )}
      </div>
    </ScaffoldCard>
  );
}

export default QueriesGraph;
