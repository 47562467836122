import { BLOG_TYPE } from 'constants/market-message/generate-blogs';
import NoData from 'components/common/NoData';
import SearchInput from 'components/common/SearchInput';
import { useGetSavedBlogs } from 'queries/digital-assets/get-save-blogs';
import { getSavedBlogsSelector } from 'queries/digital-assets/selectors';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Divider } from 'components/common';
import ArticleCard from '../ArticleCard/ArticleCard';
import digital_assets_common_styles from '../digital-assets.module.css';

const SalesBlogsPost = () => {
  const { email } = useSelector((state) => state?.user);
  const { data, isLoading } = useGetSavedBlogs(email, BLOG_TYPE.SALES);

  const blogs = getSavedBlogsSelector(data);
  const [searchKey, setSearchKey] = useState('');

  return (
    <div className={digital_assets_common_styles.child_wrapper}>
      <div className={digital_assets_common_styles.left_child}>
        <div className={digital_assets_common_styles.top_row}>
          <div className={digital_assets_common_styles.placeholder_div} />
          <div className={digital_assets_common_styles.search_wrapper}>
            <SearchInput
              placeholder="Search Blogs"
              onChange={(e) => setSearchKey(e.target.value)}
              value={searchKey}
            />
          </div>
        </div>
        <Divider />
        <div className={digital_assets_common_styles.article_card_wrapper}>
          {blogs &&
            blogs?.map((blog) => (
              <ArticleCard
                blogName={blog.blog_name}
                blogImage={blog.blog_image}
                blogAuthor={blog.blog_author}
                blogScript={blog.blog_script}
                blogTopic={blog?.blog_topic}
                id={blog.blog_id}
                key={blog.blog_id + blog.blog_name}
                from="Sales Copywriter"
              />
            ))}
        </div>

        {/* For Displaying Message When Search Query Returns Nothing */}
        {!isLoading && blogs?.length > 0 && blogs?.length === 0 && (
          <NoData
            message={`No results found for ${searchKey}. Please try a different search term.`}
          />
        )}
        {/* For Empty List When User Did Not Create Blogs */}
        {!isLoading && blogs?.length === 0 && (
          <NoData message={'You have not created any blogs yet.'} />
        )}
      </div>
      {/* <div className={digital_assets_common_styles.aside_wrapper}>aside</div> */}
    </div>
  );
};

export default SalesBlogsPost;
