import React, { useState } from 'react';
import { ScaffoldCard, Divider } from 'components/common';
import { useGetUploadedFilesLists } from 'queries/chat-athena/get-uploaded-files-lists';
import { ExistingFilesLoader } from 'components/skeleton/ExistingFiles/existing-files';
import styles from './existing-files.module.css';

const FILE_FILTERS = ['pdf', 'others'];

const ExistingFiles = ({ processedFiles, setProcessedFiles }) => {
  const { data: Files, isLoading: isLoadingFiles } = useGetUploadedFilesLists();

  const [FileType, setFileType] = useState(FILE_FILTERS[0]);

  const filteredFilesWithFileType = Files?.filter((file) => {
    const extension = file?.file_name
      .split('.')
      .pop()
      .toLowerCase();
    return FileType === FILE_FILTERS[0]
      ? extension === 'pdf'
      : extension !== 'pdf';
  });

  const handleAddFileForAssistantCreation = (selectedFile) => {
    const isPresentAlready = processedFiles?.find(
      (file) => file?.name === selectedFile?.file_name,
    );

    if (isPresentAlready) {
      const filteredFiles = processedFiles.filter(
        (file) => file?.name !== selectedFile?.file_name,
      );

      setProcessedFiles(filteredFiles);
      return;
    }

    setProcessedFiles([
      ...processedFiles,
      {
        name: selectedFile?.file_name,
        fileId: selectedFile?.file_id,
        state: true,
      },
    ]);
  };

  return (
    <ScaffoldCard className={styles.container}>
      <div className={styles.header_container}>
        <div className={styles.header}>Assets Library</div>
        <select
          className={styles.filter_container}
          onChange={(event) => {
            if (event.target.value === 'pdf') {
              setFileType('pdf');
            } else {
              setFileType('');
            }
          }}
        >
          {FILE_FILTERS?.map((filter) => (
            <option value={filter}>{filter}</option>
          ))}
        </select>
      </div>
      <Divider />

      {isLoadingFiles ? (
        <ExistingFilesLoader />
      ) : (
        filteredFilesWithFileType?.map((file) => (
          <div className={styles.file_container} key={file?.file_id}>
            <label className={styles.file_selector_container}>
              <input
                className={styles.file_selector_input}
                type="checkbox"
                checked={processedFiles.find(
                  (pFile) => pFile?.name === file?.file_name,
                )}
                onChange={() => handleAddFileForAssistantCreation(file)}
              />
              {file?.file_name}
            </label>
          </div>
        ))
      )}
    </ScaffoldCard>
  );
};

export default ExistingFiles;
