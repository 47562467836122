import {
  WORKSPACE_TABS,
  MY_WORKSPACES,
} from 'constants/workspace/all-workspace';
import React, { useState } from 'react';
import './styles.css';
import { ScaffoldCard } from 'components/common/scaffold-card';
import AppHeaderWrapper from 'components/common/AppHeader/AppHeaderWrapper';
import { HeaderCard } from 'components/common/HeaderCard/HeaderCard';
import MyWorkspace from './my-workspace/MyWorkspace';

const AllWorkspace = () => {
  const [ActiveTab, setActiveTab] = useState(WORKSPACE_TABS[0]?.name);

  return (
    <AppHeaderWrapper className="all-workspace-wrapper">
      <div className="all-workspace-container">
        <HeaderCard
          className="all-workspace-container-header"
          title="Campaigns"
          description="Choose campaign to begin your content journey"
        />

        <div className="all-workspace-main-wrapper">
          <section className="all-workspace-section-wrapper">
            <div className="all-workspace-section-container">
              {/* <ScaffoldCard className="all-workspace-section-tab-container h-margin-b-small">
                {WORKSPACE_TABS.map((workspaceTab) => (
                  <span
                    key={workspaceTab?.id}
                    className={
                      ActiveTab === workspaceTab?.name
                        ? 'all-workspace-section-tab active-workspace-tab'
                        : 'all-workspace-section-tab'
                    }
                    onClick={() => setActiveTab(workspaceTab?.name)}
                  >
                    {workspaceTab?.name}
                  </span>
                ))}
              </ScaffoldCard> */}
              <ScaffoldCard className="all-workspace-section-content-container">
                {/* {Array(100).fill(null).map((index) => (
                  <h3>Helllo</h3>
                ))} */}

                <MyWorkspace />
                {/* { ActiveTab === MY_PURCHASES && <MyPurchase />}
                { ActiveTab === MARKETPLACE && <MarketplaceWorkspace />}
                { ActiveTab === DFY_WORKSPACES && <DFYWorkspace />} */}
              </ScaffoldCard>
            </div>
          </section>

          {/* <aside className="all-workspace-aside-wrapper">
            <div className="all-workspace-aside-container">
              <ScaffoldCard className="all-workspace-aside-header-container h-margin-b-small ">
                <span className="all-workspace-aside-header">Your Activity</span>
              </ScaffoldCard>
              <ScaffoldCard className="all-workspace-aside-content-container">
                Hello
              </ScaffoldCard>
            </div>
          </aside> */}
        </div>
      </div>
    </AppHeaderWrapper>
  );
};

export default AllWorkspace;
