import React from 'react';
import { LoadingSkeleton } from './LoadingSkeleton';

export const LoadingPlaceholder = (props) => {
  const { height, width, marginLeft, marginTop, marginRight, marginBottom, borderRadius } = props;

  return (
    <LoadingSkeleton
      width={width}
      height={height}
      marginLeft={marginLeft}
      marginRight={marginRight}
      marginTop={marginTop}
      marginBottom={marginBottom}
      borderRadius={borderRadius}
      isAnimated
      animationDuration="2s"
    />
  );
};
