/* eslint-disable consistent-return */
export const getSelectedActiveTools = (tools) => {
  const activeToolsObjectArray = tools?.filter(
    (tool) => tool?.checked !== false,
  );
  const activeToolsArray = activeToolsObjectArray?.map((tool) => tool?.value);

  return activeToolsArray;
};

export const getSelectedActiveSources = (sources) => {
  const activeSourcesObjectArray = sources?.filter(
    (source) => source?.checked !== false,
  );
  const activeSourcesArray = activeSourcesObjectArray?.map(
    (tool) => tool?.value,
  );

  return activeSourcesArray;
};

export const getProcessedAndUploadedFileIds = (files) => {
  const fileIds = files?.map((file) => file?.fileId || file?.file_id);

  return fileIds;
};

export const setUploadingForSelectedFile = (files, selectedFile) => {
  // Find The Index of selected file
  const selectedFileIdx = files.findIndex(
    (file) => file?.name === selectedFile?.name,
  );

  // Toogle the selected file boolean value to true
  files[selectedFileIdx].uploading = true;

  return files;
};

export const validateCreateAssistantInputs = (
  assistantInputs,
  assistantAvatar,
) => {
  if (assistantInputs?.name === '') {
    return { fail: true, errorMessage: 'Please name your assistant' };
  }

  if (assistantInputs?.description === '') {
    return {
      fail: true,
      errorMessage: 'Please add an description for your assistant',
    };
  }

  if (assistantInputs?.instruction === '') {
    return {
      fail: true,
      errorMessage: 'Please add a instruction for your assistant',
    };
  }

  if (!assistantAvatar) {
    return {
      fail: true,
      errorMessage: 'Please select an avatar for your assistant.',
    };
  }

  return {
    fail: false,
    errorMessage: '',
  };
};

export const validateEditAssistantInputs = (assistantInputs) => {
  if (assistantInputs?.name === '') {
    return { fail: true, errorMessage: 'Please name your assistant' };
  }

  if (assistantInputs?.description === '') {
    return {
      fail: true,
      errorMessage: 'Please add an description for your assistant',
    };
  }

  if (assistantInputs?.instruction === '') {
    return {
      fail: true,
      errorMessage: 'Please add a instruction for your assistant',
    };
  }

  return {
    fail: false,
    errorMessage: '',
  };
};
