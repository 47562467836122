/* eslint-disable import/no-unresolved */
import createInstance from 'Config/AxiosConfig';

export const getSeoAthenaKeywords = async (username, name, query = '') => {
  const instance = await createInstance();

  const body = {
    username,
    name,
    source_name: [],
    q: query,
    cc: 'US',
  };

  const { data } = await instance.post('/get-seo-athena-keywords', body);

  return data.data;
};

export const getCrawlqSearch = async (userQuery, sources) => {
  const instance = await createInstance();

  const body = {
    username: localStorage.getItem('username'),
    name: localStorage.getItem('name'),
    query: userQuery,
    source_name: sources,
  };

  const result = await instance.post('/crawlq-search', body);

  const response = result?.data?.body?.data;

  if (response) {
    return response;
  }

  return [];
};

export const saveKeyword = async (saveKeywordPayload) => {
  const instance = await createInstance();
  const response = await instance.post('save-seo-athena-keywords', saveKeywordPayload);

  console.log(response.data.statusCode);
  if (response.data.statusCode === '200') return true;

  return false;
};
