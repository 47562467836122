import createInstance from 'Config/AxiosConfig';
import { useQuery } from 'react-query';

export const getUsersWorkspaces = async (email) => {
  const instance = await createInstance(true);
  const response = await instance.get(`projects-all?username=${email}`);

  return response?.data?.data?.data;
};

// Keep Query Value as File Name to maintain consistency and uniqueness
export const getWorkspaceQuery = 'get-users-all-workspaces';
const QUERY_KEY = [getWorkspaceQuery];

// Query Config Options
const queryOptions = {
  staleTime: 60000,
};

// QUERY
export const useGetUsersWorkspaces = (email) =>
  useQuery(QUERY_KEY, () => getUsersWorkspaces(email), queryOptions);
