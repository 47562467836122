import { ScaffoldCard } from 'components/common/scaffold-card';
import React from 'react';
import { LoadingPlaceholder } from '../LoadingPlaceholder';

export const MarketMessageBlogsLoading = () => (
  <div>
    {Array(5)
      .fill(null)
      .map((index) => (
        <ScaffoldCard className="my-workspace-table-content h-margin-b-small">
          <div className="table-content-box m-w-t-c-name-container">
            <LoadingPlaceholder
              width="100px"
              height="15px"
              marginBottom="8px"
            />
            <LoadingPlaceholder width="140px" height="12px" marginTop="4px" />
          </div>

          <div className="table-content-box m-w-t-c-creation-container">
            <div>
              <LoadingPlaceholder
                width="1000px"
                height="15px"
                marginBottom="8px"
              />
              <LoadingPlaceholder
                width="500px"
                height="15px"
              />
            </div>
          </div>

        </ScaffoldCard>
      ))}
  </div>
);
