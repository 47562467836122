import React from 'react';
import { LoadingPlaceholder } from '../LoadingPlaceholder';

const CategoryListFetchingLoading = () => (
  <div>
    <LoadingPlaceholder width="75px" height="12px" marginBottom="8px" />
    <LoadingPlaceholder width="400px" height="30px" marginBottom="50px" />

    <LoadingPlaceholder width="75px" height="12px" marginBottom="8px" />
    <LoadingPlaceholder width="400px" height="30px" marginBottom="50px" />

    <LoadingPlaceholder width="75px" height="12px" marginBottom="8px" />
    <LoadingPlaceholder width="400px" height="30px" marginBottom="50px" />

    <LoadingPlaceholder width="75px" height="12px" marginBottom="8px" />
    <LoadingPlaceholder width="400px" height="30px" marginBottom="50px" />

    <LoadingPlaceholder width="75px" height="15px" marginBottom="8px" />
    <LoadingPlaceholder width="400px" height="30px" marginBottom="0px" />
  </div>
);

export default CategoryListFetchingLoading;
