/* eslint-disable camelcase */
import {
  USER_LOGIN_SUCCESS,
  USER_WORKSPACE_COUNT_UPDATE,
  USER_LOGOUT,
  USER_WORKSPACE_SET_ATHENA_SOURCES,
  USER_EMAIL_UPDATE,
} from './UserTypes';

export const userLoginSuccess = (users) => ({
  type: USER_LOGIN_SUCCESS,
  payload: users,
});

export const userPlanUpdate = (plan_details) => ({
  type: USER_LOGIN_SUCCESS,
  payload: plan_details,
});

export const userWorkspaceCountUpdate = (workspace_count) => ({
  type: USER_WORKSPACE_COUNT_UPDATE,
  payload: workspace_count,
});

export const userWorkspaceSetAthenaSources = (sources) => ({
  type: USER_WORKSPACE_SET_ATHENA_SOURCES,
  payload: sources,
});

export const userEmailUpdate = (email) => ({
  type: USER_EMAIL_UPDATE,
  payload: email,
});

export const userLogOut = () => ({
  type: USER_LOGOUT,
});
