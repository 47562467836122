import {
  ButtonPrimary,
  Flex,
  Input,
  InputWrapper,
  Label,
} from 'Component/Shared/GlobalStyles';
import LoaderSpin from 'Component/Shared/Loader';
import { isValidURL, removeItemArray } from 'Component/Shared/utils';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import createNewWorkspace from '../../CreateNewWorkspace/CreateNewWorkspaceLogic';
import { startOnboarding } from '../../WorkspaceConfigutation/confuguration.service';

const MAX_VARIANT_ALLOWED = 5;
const MIN_VARIANT_ALLOWED = 1;

const PersonaVariant = ({ initialData, configurations }) => {
  const initialValue = {
    ...initialData,
  };

  const fullName = useSelector((state) => state?.user?.name);
  const username = useSelector((state) => state?.user?.username);
  const navigate = useNavigate();

  const [variants, setVariants] = useState([{ ...initialValue }]);
  const [currentlyShowingIndex, setCurrentlyShowingIndex] = useState(1);
  const [variantCount, setVariantCount] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [errorInURLIndexes, setErrorInURLIndexes] = useState([]);
  const [emptyError, setEmptyError] = useState(false);

  const increamentVariant = () => {
    setVariantCount((prev) => Math.min(prev + 1, MAX_VARIANT_ALLOWED));
    if (variants.length < MAX_VARIANT_ALLOWED) {
      setVariants([...variants, { ...initialValue }]);
    }
  };
  const decrementVariant = () => {
    if (variantCount === currentlyShowingIndex) {
      setCurrentlyShowingIndex(variantCount - 1);
    }
    const filteredVariant = removeItemArray(
      errorInURLIndexes,
      Math.max(variantCount - 1, MIN_VARIANT_ALLOWED),
    );
    setErrorInURLIndexes([...filteredVariant]);
    setVariantCount((prev) => Math.max(prev - 1, MIN_VARIANT_ALLOWED));
  };

  const handleChange = (e, name, index) => {
    const variant_arr = [...variants];
    variant_arr[index][name] = e.target.value;
    setVariants([...variant_arr]);
  };

  const handleURlCheck = (e, index) => {
    const { value } = e.target;
    if (!isValidURL(value)) {
      const filteredVariant = new Set([...errorInURLIndexes, index]);
      setErrorInURLIndexes([...filteredVariant]);
    } else {
      const filteredVariant = removeItemArray(errorInURLIndexes, index);
      setErrorInURLIndexes([...filteredVariant]);
    }
  };

  const createVariant = async (createPersonaPayload, onboardAthenaPayload) => {
    await createNewWorkspace(createPersonaPayload);
    startOnboarding(onboardAthenaPayload);
  };

  const createVariantPayload = async (variantDetails) => {
    const createPersonaPayload = {
      name: variantDetails.variantName,
      site: variantDetails.site,
      fullName,
      lang: variantDetails.Lang,
      lang_engine: variantDetails.lang_engine,
      tags: variantDetails.tags,
      users: variantDetails.users,
      product_name: variantDetails.product_name,
      founder_name: variantDetails.founder_name,
      configurations: variantDetails.configurations,
      target_persona: variantDetails.target,
    };

    const onboardAthenaPayload = {
      username,
      name: variantDetails.variantName,
      source_lang: variantDetails.Lang,
      input_data: [
        {
          question: 'Define your Niche',
          answer: '',
        },
        {
          question: 'Define your Sub-Niche',
          answer: '',
        },
        {
          question: 'Define your Micro-Niche',
          answer: '',
        },
        {
          question: 'Define your Industry Vertical',
          answer: '',
        },
        {
          question: 'Ideal Prospect Overview',
          answer: '',
        },
        {
          question: 'Your Website Domain Name',
          answer: variantDetails.site,
        },
        {
          question: 'Your Company Legal Name',
          answer: variantDetails.company_name,
        },
        {
          question: 'Main topic of interest',
          answer: variantDetails.mti,
        },
        {
          question: 'Long Tail Topic 1',
          answer: '',
        },
        {
          question: 'Single Big Shocking Statement',
          answer: '',
        },
        {
          question: 'Single Big Emotional Payoff',
          answer: '',
        },
        {
          question: 'Who you want to target',
          answer: variantDetails.target,
        },
      ],
      fullName: 'Shruti Agrawal',
    };

    await createVariant(createPersonaPayload, onboardAthenaPayload);
  };

  const checkIsAllFilled = (variant) => {
    let anyError = false;
    // eslint-disable-next-line array-callback-return
    Object.values(variant).map((data) => {
      if (data.trim() === '') {
        setEmptyError(true);
        setTimeout(() => {
          setEmptyError(false);
        }, 5 * 1000);
        anyError = true;
      }
    });
    return anyError;
  };

  const handleCreateAll = async () => {
    if (errorInURLIndexes.length > 0) {
      setCurrentlyShowingIndex(errorInURLIndexes[0] + 1);
      return;
    }

    let errorInFeilds = false;
    setIsLoading(true);
    const variants_promise = variants
      .slice(0, variantCount)
      .map(async (variant) => {
        const anyError = checkIsAllFilled(variant);
        if (anyError) {
          errorInFeilds = true;
          setIsLoading(false);
          return;
        }
        createVariantPayload({ ...configurations, ...variant });
      });
    if (errorInFeilds) return;
    await Promise.all(variants_promise);
    setTimeout(() => {
      navigate('/build-workspace-status');
    }, 15 * 1000);
  };

  return (
    <Flex direction={'column'} gap={'3rem'} className="p-3">
      <Flex gap={'2rem'} className="px-5">
        {variants.map(
          (variant, index) =>
            index + 1 === currentlyShowingIndex && (
              <Flex direction={'column'} gap={'1rem'} flex={1}>
                <h3 className="text-center">Persona Variant {index + 1}</h3>
                <Flex direction={'column'} gap={'1.2rem'}>
                  <InputWrapper>
                    <InputWrapper>
                      <Label>Enter New Vaiant Name</Label>
                      <Input
                        value={variant.variantName}
                        onChange={(e) => handleChange(e, 'variantName', index)}
                      />
                    </InputWrapper>
                  </InputWrapper>
                  <InputWrapper>
                    <InputWrapper>
                      <Label>Main Topic Of Intrest</Label>
                      <Input
                        value={variant.mti}
                        onChange={(e) => handleChange(e, 'mti', index)}
                      />
                    </InputWrapper>
                  </InputWrapper>
                  <InputWrapper>
                    <InputWrapper>
                      <Label>Who do you want to Target</Label>
                      <Input
                        value={variant.target}
                        onChange={(e) => handleChange(e, 'target', index)}
                      />
                    </InputWrapper>
                  </InputWrapper>
                  <InputWrapper>
                    <InputWrapper>
                      <Label>Company Name</Label>
                      <Input
                        value={variant.company_name}
                        onChange={(e) => handleChange(e, 'company_name', index)}
                      />
                      {errorInURLIndexes.includes(index) && (
                        <p className="text-danger">Enter Valid URL</p>
                      )}
                    </InputWrapper>
                  </InputWrapper>
                  <InputWrapper>
                    <InputWrapper>
                      <Label>Domain Name</Label>
                      <Input
                        value={variant.site}
                        onChange={(e) => handleChange(e, 'site', index)}
                        onBlur={(e) => handleURlCheck(e, index)}
                      />
                      {errorInURLIndexes.includes(index) && (
                        <p className="text-danger">Enter Valid URL</p>
                      )}
                    </InputWrapper>
                  </InputWrapper>
                  <InputWrapper>
                    <InputWrapper>
                      <Label>Founder Name</Label>
                      <Input
                        value={variant.founder_name}
                        onChange={(e) => handleChange(e, 'founder_name', index)}
                      />
                    </InputWrapper>
                  </InputWrapper>
                  <InputWrapper>
                    <InputWrapper>
                      <Label>Product Name</Label>
                      <Input
                        value={variant.product_name}
                        onChange={(e) => handleChange(e, 'product_name', index)}
                      />
                    </InputWrapper>
                  </InputWrapper>
                </Flex>
              </Flex>
            ),
        )}
      </Flex>
      <Flex className="px-5" justify={'space-between'} align={'baseline'}>
        <Flex direction={'column'} gap={'0.1rem'} flex={'0.3'}>
          <Flex flex={'1'}>
            <button
              type="button"
              className="btn-primary w-100 font-secondary-size"
              onClick={decrementVariant}
            >
              -
            </button>
            <button
              type="button"
              className="btn-primary w-100 font-secondary-size"
            >
              {variantCount}
            </button>
            <button
              type="button"
              className="btn-primary w-100 font-secondary-size"
              onClick={increamentVariant}
            >
              +
            </button>
          </Flex>
          <p>Variant Count</p>
        </Flex>
        <Flex direction={'column'} gap={'0.1rem'} flex={'0.3'}>
          <Flex flex={'1'}>
            {variants.map(
              (variant, index) =>
                index + 1 <= variantCount && (
                  <button
                    type="button"
                    className={
                      currentlyShowingIndex === index + 1
                        ? 'btn-primary w-100 font-secondary-size'
                        : 'btn-dark w-100 font-secondary-size'
                    }
                    onClick={() => setCurrentlyShowingIndex(index + 1)}
                  >
                    {index + 1}
                  </button>
                ),
            )}
          </Flex>
        </Flex>
        {isLoading ? (
          <LoaderSpin />
        ) : (
          <Flex direction={'column'}>
            <ButtonPrimary onClick={handleCreateAll} flex={'0.5'}>
              Create All
            </ButtonPrimary>
            {emptyError && (
              <p className="text-danger">*All Feilds are compulsory</p>
            )}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default PersonaVariant;
