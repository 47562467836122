/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import axios from 'axios';
import React, { useState } from 'react';
import Loader from 'react-loader-spinner';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import { withStyles } from '@mui/styles';
import { CreditManage } from 'Component/Shared/CreditManager';
import { HEADLINE_INTRO_COST } from 'Component/Shared/featuresCreditsCost';
import CreditUpdater from '../../../Shared/CreditManager';
import { getTranslatedTextEnglish } from '../../../Shared/TranshelperStatic';
import { getTranslatedTextArray } from '../../MarketReasearch/MarketResearchAndResonance/MarketResearchCategory/fILLAiLangTrans';
import { addInEditor } from 'Component/Shared/copyToClipBoard';

const WhiteonBlackTooltip = withStyles({
  tooltip: {
    color: 'white',
    backgroundColor: 'rgb(47 47 47 / 91%)',
    fontSize: '12px',
    fontWeight: 'normal',
    letterSpacing: '1px',
    padding: '10px 15px',
    textAlign: 'justify',
  },
})(Tooltip);

function HeadLineIntors(props) {
  const [loader, setLoader] = useState();

  const [headlineIntros, setHeadlinesIntros] = useState([]);
  const [isFetched, setIsFetched] = useState(false);
  const [IsCreditFail, setIsCreditFail] = useState(false);

  function copyClipboard(text) {
    console.log('asdads', text);
    const paragraph = document.createElement('textarea');
    document.body.appendChild(paragraph);
    const span_ = document.createElement('span');
    span_.innerHTML = text;
    paragraph.value = span_.innerText;

    paragraph.select();
    document.execCommand('copy');
    document.body.removeChild(paragraph);

    // alert.success('Copied')
  }

  const generateHeadlinesIntros = async () => {
    setLoader(true);

    const { success } = await CreditManage(HEADLINE_INTRO_COST, true);

    if (success) {
      const formData = {
        name: localStorage.getItem('name'),
        username: localStorage.getItem('username'),
        query: await getTranslatedTextEnglish(props.query),
      };

      axios
        .post('https://apis.crawlq.ai/generate-paragraph', formData)

        .then(async ({ data }) => {
          const temp = headlineIntros;

          const translatedTexts = await getTranslatedTextArray([
            data.data.headlines,
            data.data.intro,
          ]);

          const headlineTrans = translatedTexts[0];
          const introsTrans = translatedTexts[1];

          const { success } = await CreditManage(HEADLINE_INTRO_COST);

          if (success) {
            if (headlineIntros !== []) {
              temp.unshift({ headlines: headlineTrans, intros: introsTrans });
              setHeadlinesIntros(temp);
              setIsFetched(false);
            } else {
              setHeadlinesIntros([
                { headlines: headlineTrans, intros: introsTrans },
              ]);
            }
          } else {
            setIsCreditFail(true);
            setLoader(false);
          }
        })

        .catch((err) => {
          setLoader(false);
          console.log(err);
        })
        .finally(() => {
          setLoader(false);

          setIsFetched(true);
        });
    } else {
      setIsCreditFail(true);
      setLoader(false);
    }
  };

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h4 style={{ padding: '0 10px' }}>
          Headlines Intros
          <WhiteonBlackTooltip
            placement="top"
            title="Generate Headlines and Intros on click of a button."
          >
            <InfoOutlinedIcon className="mb-2 ml-1" />
          </WhiteonBlackTooltip>
        </h4>

        {loader ? (
          <Loader
            type="Rings"
            color="#00BFFF"
            height={40}
            width={40}
            visible="true"
            // 3 secs
          />
        ) : IsCreditFail ? (
          <button
            className=" p-1 px-2 rounded"
            style={{
              background: 'rgb(255 0 0 / 21%)',
              color: 'rgb(224 2 2 / 81%)',
            }}
          >
            Credits Over
          </button>
        ) : (
          <button
            className="text-white p-1 px-2 rounded"
            style={{ backgroundColor: '#63A5F0' }}
            onClick={() => {
              generateHeadlinesIntros();
            }}
          >
            Generate
          </button>
        )}
      </div>
      {isFetched ? (
        headlineIntros.map((inf) => (
          <div className="border-rounded-light mt-3">
            <h6>
              {inf.headlines}
              <FileCopyOutlinedIcon
                style={{ cursor: 'pointer', width: '15px' }}
                onClick={() => addInEditor(inf.headlines)}
                className="ml-3"
              />
            </h6>

            <small>
              {inf.intros}
              <FileCopyOutlinedIcon
                style={{ cursor: 'pointer', width: '15px' }}
                onClick={() => addInEditor(inf.intros)}
                className="ml-3"
              />
            </small>

            <br />
            <br />
          </div>
        ))
      ) : (
        <div />
      )}
    </div>
  );
}
export default HeadLineIntors;
