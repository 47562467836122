/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from 'react';
import './DemoWorkspacePreview.css';
import { motion } from 'framer-motion';

// Assets
import { withStyles } from '@mui/styles';
import LinearProgress from '@mui/material/LinearProgress';
import BlogsImage from '../../../../../../assets/images/docs-blogs.svg';

// Components
import LoaderSpin from '../../../../../Shared/Loader';

// Logic Fn
import { DemoPreviewRequest } from './DemoWorkspacePreviewLogic';
import { DoneForYouPreviewLogicRequest } from '../../DoneForYouWorkspace/DoneforyouPreviewLogic';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: '#eeeeee',
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
}))(LinearProgress);

const LEVEL_NAME_MAP_TO_NUMBER = {
  1: 'Top of the Funnel',
  2: 'Middle of the Funnel',
  3: 'Bottom of the Funnel',
};

function LevelProgressBar({ level, percentage }) {
  return (
    <>
      <div className="market-research-percentage-progress-label">
        <span>{LEVEL_NAME_MAP_TO_NUMBER[level]}</span>
        <span className="txt-primary font-primary">{percentage} %</span>
      </div>
      <div>
        <BorderLinearProgress variant="determinate" value={percentage} />
      </div>
    </>
  );
}

function DemoWorkspacePreview({ workspace_name, creator_email }) {
  useEffect(() => {
    console.log('creator_email', creator_email);
    handlePreview();
  }, []);

  const [PreviewData, setPreviewData] = useState();
  const [IsPreviewDataError, setIsPreviewDataError] = useState(false);

  const handlePreview = async () => {
    let response = null;
    if (!creator_email) {
      response = await DemoPreviewRequest(workspace_name);
    } else {
      response = await DoneForYouPreviewLogicRequest(
        workspace_name,
        creator_email,
      );
    }
    const { data, result } = response;
    if (data) {
      console.log(result);
      setPreviewData(result);
    } else {
      setIsPreviewDataError(true);
    }
  };

  if (PreviewData) {
    return (
      <motion.div
        layout
        initial={{ opacity: 0, y: '-5vw', x: '+10vw' }}
        animate={{ opacity: 1, y: 0, x: 0 }}
        transition={{ duration: 0.5, ease: 'easeInOut' }}
        className="demo-workspace-details-container"
      >
        <div className="demo-preview-left">
          <h4 className="preview-left-header">Coffee Points with FRED </h4>
          <div className="preview-left-data-container">
            <p>
              <span>Description :</span> {PreviewData && PreviewData.desc}
            </p>
            {PreviewData &&
              PreviewData.mrqna.map((mr_data) => (
                <div className="preview-left-data">
                  <p> Q :{mr_data.question}</p>
                  <span>A :{mr_data.answer}</span>
                </div>
              ))}
          </div>
        </div>
        <div className="demo-preview-middle">
          <h4 className="preview-middle-header">CrawlQ Content</h4>

          <div className="preview-middle-blog-scripts-wrapper">
            <div className="preview-middle-blog-scripts-container">
              <img src={BlogsImage} />
              <span>Blogs :{PreviewData && PreviewData.numblogs}</span>
            </div>
            <div className="preview-middle-blog-scripts-container">
              <img src={BlogsImage} />
              <span>Scripts :{PreviewData && PreviewData.numscripts}</span>
            </div>
          </div>
        </div>

        <div className="demo-preview-right">
          <div className="market-research-percentage-container">
            <div className="market-research-percentage-data">
              <h4>Market Research</h4>
              <div className="market-research-percentage-display">
                {PreviewData &&
                  Object.keys(PreviewData.mrrpercentage).map((key, index) => (
                    <div>
                      <LevelProgressBar
                        level={index + 1}
                        percentage={PreviewData.mrrpercentage[key]}
                      />
                    </div>
                  ))}
              </div>
            </div>
            <div className="market-research-image-container" />
          </div>
        </div>
      </motion.div>
    );
  }
  return (
    <motion.div
      layout
      initial={{ opacity: 0, y: '-5vw' }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
      className="demo-workspace-details-container"
    >
      <div className="demo-preview-left">
        <h4 className="preview-left-header">Coffee Points with FRED</h4>
        <div className="preview-left-data-container">
          <LoaderSpin />
        </div>
      </div>
      <div className="demo-preview-middle">
        <h4 className="preview-middle-header">CrawlQ Content</h4>

        <div className="preview-middle-blog-scripts-wrapper">
          <div className="preview-middle-blog-scripts-container">
            <img src={BlogsImage} />
            <span>
              <LoaderSpin width="25" height="25" />
            </span>
          </div>
          <div className="preview-middle-blog-scripts-container">
            <img src={BlogsImage} />
            <span>
              <LoaderSpin width="25" height="25" />
            </span>
          </div>
        </div>
      </div>

      <div className="demo-preview-right">
        <div className="market-research-percentage-container">
          <div className="market-research-percentage-data">
            <h4>Market Research</h4>
            <div>
              <LoaderSpin />
            </div>
          </div>
          <div className="market-research-image-container" />
        </div>
      </div>
    </motion.div>
  );
}

export default DemoWorkspacePreview;
