import styled from 'styled-components';

export const TabContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const TabSwitcheWrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: #2a95df;
  border-radius: 2px;
  overflow: hidden;
  border: 1px solid #2a95df;
  width: 15rem;
`;

const Button = styled.button`
  color: ${(props) => (!props.active ? '#2a95df' : '#fff')};
  box-shadow: none;
  flex: 1 1 0%;
  background-color: ${(props) => (!props.active ? '#fff' : '#2a95df')};
  padding: 0.2rem;
`;

export default Button;
