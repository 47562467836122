import { useMutation, useQueryClient } from 'react-query';
import createInstance from 'Config/AxiosConfig';

const deleteWorkspace = async (workspaceName) => {
  const instance = await createInstance();
  return instance.post('delete-project', workspaceName);
};

export const useMutateDeleteWorkspace = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteWorkspace, {
    onSuccess: (data) => {
      queryClient.setQueryData(
        ['get-users-all-workspaces'],
        (oldWorkspaceList) => {
          const deletedWorkspaceName = data?.data?.data?.name;
          const newWorkspaceList = oldWorkspaceList?.filter(
            (workspace) => workspace?.name !== deletedWorkspaceName,
          );

          return newWorkspaceList;
        },
      );
    },
  });
};
