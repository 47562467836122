import CustomTooltip from 'Component/Shared/CustomTooltip';
import {
  LoaderTailSpin,
  PrimaryButton,
  PublicIcon,
  SecondaryButton,
} from 'components/common';
import React, { useState } from 'react';
import Modal from 'react-modal';
import { showNotification } from 'libs/notification';
import styles from './model-styles.module.css';
import { shareAssistantWithEmail } from '../assistant.service';

const SUPPORT_ACCOUNT_EMAIL = 'support@quantamixsolutions.com';

const customStyles = {
  content: {
    width: '30rem',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const MakeAssistantPublic = ({ assistantId, isPublic }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [publishingAssistant, setPublishingAssistant] = useState(false);

  const handlePublish = async () => {
    setPublishingAssistant(true);
    const isShared = await shareAssistantWithEmail(
      assistantId,
      SUPPORT_ACCOUNT_EMAIL,
    );
    if (isShared) {
      showNotification('success', 'Assistant Published Successfully.');
      setModalIsOpen(false);
    }
    setPublishingAssistant(false);
  };

  const handleModalOpen = () => {
    if (isPublic) return;
    setModalIsOpen(true);
  };

  return (
    <div>
      <CustomTooltip
        placement="top"
        title={
          isPublic
            ? 'Assistant is already published'
            : 'Share Assistant With Other CrawlQ Account'
        }
        arrow
      >
        <PublicIcon disabled={isPublic} onClick={handleModalOpen} />
      </CustomTooltip>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={customStyles}
      >
        <div className={styles.modal_container}>
          <h5>Make Assistant Public</h5>
          <p>
            Are you sure you want to make this Assistant public? This will allow
            others to view and potentially use this Assistant
          </p>
          <div className={styles.buttons_group}>
            <SecondaryButton
              onClick={() => setModalIsOpen(false)}
              className={styles.button}
            >
              Close
            </SecondaryButton>
            <PrimaryButton className={styles.button} onClick={handlePublish}>
              Publish
              {publishingAssistant && <LoaderTailSpin height={20} width={20} />}
            </PrimaryButton>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MakeAssistantPublic;
