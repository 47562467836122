import createInstance from 'Config/AxiosConfig';

const fetchAllTemplates = async () => {
  try {
    const instance = await createInstance();
    const {
      data,
    } = await instance.post(
      'https://p8dapk4yf9.execute-api.us-east-2.amazonaws.com/prod/get-user-blog-template',
      { include_all: true },
    );
    if (data.statusCode === '200') return data.templates;
    return [];
  } catch (ex) {
    console.log('Error in Fetching users blogs');
    return { error: true, data: [] };
  }
};

export const getTrimmedString = (string, length) => {
  const THREE_DOTS = 3;
  if (string?.length > length + THREE_DOTS) {
    return `${string.slice(0, length)}...`;
  }
  return string;
};

export const delelteTemplate = async (id) => {
  try {
    const instance = await createInstance();
    const { data } = await instance.post(
      'https://p8dapk4yf9.execute-api.us-east-2.amazonaws.com/prod/delete-template',
      {
        template_id: id,
      },
    );
    if (data?.statusCode === '200') {
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};

export const shareTemplateWithEmail = async (id, email) => {
  try {
    const instance = await createInstance();
    const { data } = await instance.post(
      'https://p8dapk4yf9.execute-api.us-east-2.amazonaws.com/prod/share-user-blog-template',
      {
        template_id: id,
        destination_email: email,
      },
    );
    if (data?.statusCode === '200') {
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};

export const copyTemplateFromURL = async (templateUrl) => {
  try {
    const instance = await createInstance();
    const { data } = await instance.post(
      'https://p8dapk4yf9.execute-api.us-east-2.amazonaws.com/prod/copy-template-from-url',
      {
        url: templateUrl,
      },
    );
    if (data?.statusCode === '200') {
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};

export const generateTemplateURL = async (id) => {
  try {
    const instance = await createInstance();
    const { data } = await instance.post(
      'https://p8dapk4yf9.execute-api.us-east-2.amazonaws.com/prod/generate-template-url',
      {
        template_id: id,
      },
    );
    if (data?.statusCode === '200') {
      console.log('datadatadatadatadatadata', data);
      return data?.url;
    }
    return false;
  } catch (err) {
    return false;
  }
};

export const cloneTemplateWithId = async (id, name) => {
  try {
    const instance = await createInstance();
    const { data } = await instance.post(
      'https://p8dapk4yf9.execute-api.us-east-2.amazonaws.com/prod/copy-user-template',
      {
        template_id: id,
        template_name: name,
      },
    );
    if (data?.statusCode === '200') {
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};

export default fetchAllTemplates;
