import { store } from 'redux/store';

/**
 * Retrieves the user state from the Redux store.
 */
export const getReduxUserState = () => {
  const { user } = store.getState();
  return user;
};

export const getReduxWorkspaceState = () => {
  const { workspace } = store.getState();
  return workspace;
};
