import createInstance from 'Config/AxiosConfig';
import { getLocationDetails } from './location';

const US_DOLLAR = 'USD';

export async function addOnCheckout(plans) {
  const instance = await createInstance();

  const location = await getLocationDetails();

  const { data } = await instance.post('/addon-checkout', {
    plans,
    currency: location?.currency || US_DOLLAR,
  });

  if (data.url) {
    window.open(data.url, '_blank');
  }
}
