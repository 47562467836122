/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React from 'react';
import './Checkmark.css';

function Checkmark({ message }) {
  return (
    <div className="checkbox-container">
      <div className="circle__box">
        <div className="circle__wrapper circle__wrapper--right">
          <div className="circle__whole circle__right" />
        </div>
        <div className="circle__wrapper circle__wrapper--left">
          <div className="circle__whole circle__left" />
        </div>
        <div className="circle__checkmark" />
      </div>
      <h1 className="mt-4 font-primary text-success d-block">{message}</h1>
    </div>
  );
}

export default Checkmark;
