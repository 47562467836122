/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import Modal from 'react-modal';
import Topnav from '../Sidenav/Topnav';
import Topbar from '../Topbar/Topbar';
import './VideoTuts.css';

const VideoList = require('./VideoDetails');

function VideoTuts() {
  const [modalPop, setmodalPop] = useState(false);
  const [vidLink, setvidLink] = useState('');

  const customStyles = {
    content: {
      width: '1000px',
      height: '700px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  const handleModal = (link) => {
    setvidLink(link);
    // setmodalPop(!modalPop)
  };

  function VideoPop() {
    return (
      <Modal
        isOpen={modalPop}
        onRequestClose={handleModal}
        style={customStyles}
        ariaHideApp={false}
      >

        <iframe width="100%" height="100%" allowFullScreen="true" src={vidLink} title="W3Schools Free Online Web Tutorials" />

      </Modal>
    );
  }

  return (
    <>
      <Topbar />
      <Topnav />
      <div className="videotuts__body">
        <div className="videos__box_container">
          <span className="video_box_heading" onClick={() => handleModal()}>CrawlQ Video Library</span>

          {VideoPop()}
          <div className="video_box_list">

            <div className="video_box_list_left">
              {

                                VideoList.map((videolist) => (
                                  <div className="video_container">
                                    <ul>
                                      <span className="video__category">{videolist.category}</span>
                                      {

                                                videolist.child?.map((video) => (
                                                  <li className="video__name__link__container">
                                                    <img src="./img/play-button.png" onClick={() => handleModal(video.videoLink)} alt="" />
                                                    <p className={vidLink === video.videoLink ? 'selected' : 'not-selected'} onClick={() => handleModal(video.videoLink)}>{video.videoTitle}</p>

                                                    {/* <p onClick={()=>handleModal(video.videoLink)} >{video.videoLink}</p> */}

                                                  </li>
                                                ))
                                            }
                                    </ul>
                                  </div>
                                ))
                            }

            </div>

            <div className="video_box_list_right">
              <iframe width="100%" height="100%" allowFullScreen="true" src={vidLink || 'https://share.vidyard.com/watch/LP5mj7xUoWkcdnjVeHR8wR'} title="W3Schools Free Online Web Tutorials" />
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default VideoTuts;
