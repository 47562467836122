/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ButtonNext from 'Component/Shared/ButtonNext';
import { CreditManage } from 'Component/Shared/CreditManager';
import { ONBOARDING_LEVEL_1_COST } from 'Component/Shared/featuresCreditsCost';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import CustomTooltip from '../../../Shared/CustomTooltip';
import LoaderSpin, { LoaderTailSpin } from '../../../Shared/Loader';
import Topbar from '../../../Shared/Topbar/Topbar';
import './onBoarding.css';
import OnBoardingLoader from './onBoardingLoader';
import saveBasicAnswers, { getSavedAnswers } from './onBoardingLogic';
import questions from './onboardingQuestions.json';

const customStyles = {
  content: {
    width: '1000px',
    height: '700px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

function OnBoardingQue() {
  let ONBOARDING_LEVEL_1_COST_local = ONBOARDING_LEVEL_1_COST;

  const language = useSelector((state) => state.workspace.language);

  const [queList, setQueList] = useState([]);
  const [modalPop, setmodalPop] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isErrorInSubmission, setIsErrorInSubmission] = useState(false);
  const [savingError, setSavingError] = useState('');
  const [hasAPIFullfilled, setHasAPIFullfilled] = useState(false);
  const [isSavingCurrentStatus, setIsSavingCurrentStatus] = useState(false);
  const [savedSuccessfully, setSavedSuccessfully] = useState(false);
  const [isQuestionLoading, setIsQuestionLoading] = useState(true);
  const [credits, setCredits] = useState({ isOver: false, isChecking: false });

  useEffect(() => {
    handleSetAnswers();
    return () => {
      localStorage.removeItem('level1answers');
    };
  }, []);

  const handleSetAnswers = async () => {
    let { data } = await getSavedAnswers();
    data = Object.values(data);

    console.log(data);

    const level0questions = JSON.parse(localStorage.getItem('level1answers'));

    let aiFilledQuestionAns = [];
    for (const i in level0questions) {
      for (const j in questions) {
        if (questions[j].que === level0questions[i].question) {
          const isValid = checkEnoughCharacter(
            questions[j].example.length - 9, // substracting 9 because of "Example : "
            level0questions[i].ai_suggestion.trim(),
          );
          aiFilledQuestionAns = [
            ...aiFilledQuestionAns,
            {
              ...questions[j],
              answer: level0questions[i].ai_suggestion,
              isValid,
            },
          ];
        }
      }
    }
    if (aiFilledQuestionAns.length === 0) {
      aiFilledQuestionAns = [...questions];
    }
    aiFilledQuestionAns = aiFilledQuestionAns.map((question, i) => {
      if (data[i]) return { ...question, answer: data[i] };
      return { ...question };
    });

    console.log(aiFilledQuestionAns);
    setQueList(aiFilledQuestionAns);
    setIsQuestionLoading(false);
  };

  // handling user input

  const checkEnoughCharacter = (charactersLimit, answer) => {
    // const percentage = (100 * answer.length) / charactersLimit
    const charDiff = charactersLimit - answer.length;

    if (charDiff > -40 && charDiff <= 40) {
      return 2;
    }
    if (charDiff > -70 && charDiff <= 70) {
      return 1;
    }
    return 0;
  };

  const handleChange = async (e, i) => {
    const userInput = e.target.value;
    setSavedSuccessfully(false);
    setIsErrorInSubmission(false);
    setQueList((prev) => {
      prev[i].answer = userInput;
      if (prev[i].example) {
        const isValid = checkEnoughCharacter(
          prev[i].example.length - 9, // substracting 9 because of "Example : "
          userInput.trim(),
        );
        prev[i].isValid = isValid;
        return [...prev];
      }
      if (userInput !== '') {
        prev[i].isValid = 2;
      } else {
        prev[i].isValid = 0;
      }
      return [...prev];
    });
  };

  const checkIsAllAnswerField = () => {
    const questionsList = [...queList];
    let isAllAnswerFilled = true;
    for (const i in questionsList) {
      if (questionsList[i].answer.trim() === '') {
        questionsList[i].error = true;
        isAllAnswerFilled = false;
      } else {
        questionsList[i].error = false;
      }
    }
    setQueList([...questionsList]);
    return isAllAnswerFilled;
  };

  const handleSubmit = async () => {
    setSavedSuccessfully(false);
    const isAllAnswerFilled = checkIsAllAnswerField();
    if (!isAllAnswerFilled) {
      setIsErrorInSubmission(true);
      return;
    }
    setCredits({ ...credits, isChecking: true });
    if (language !== 'en') {
      ONBOARDING_LEVEL_1_COST_local += 1000;
    }
    const { success } = await CreditManage(ONBOARDING_LEVEL_1_COST_local, true);
    if (!success) {
      setCredits({ isOver: true, isChecking: false });
      return;
    }
    setIsSubmitting(true);
    saveBasicAnswers(queList).then((data) => {
      if (!data) {
        setSavingError('Something went wrong! Please try again..');
      }
      setHasAPIFullfilled(true);
      CreditManage(ONBOARDING_LEVEL_1_COST_local);
      localStorage.removeItem('level1answers');
    });
  };

  const handleSaveCurrentStatus = () => {
    setIsErrorInSubmission(false);
    setIsSavingCurrentStatus(true);
    saveBasicAnswers(queList).then((data) => {
      if (!data) {
        setSavingError('Something went wrong! Please try again..');
      }
      setIsSavingCurrentStatus(false);
      setSavedSuccessfully(true);
      localStorage.removeItem('level1answers');
    });
  };

  const handleModal = () => {
    setmodalPop(!modalPop);
  };
  function PopUpVideo() {
    return (
      <Modal
        isOpen={modalPop}
        onRequestClose={handleModal}
        style={customStyles}
        ariaHideApp={false}
      >
        <iframe
          width="100%"
          height="100%"
          allowFullScreen="true"
          src="https://share.vidyard.com/watch/M4UiDMqkrjpRx5S8bzBGbf"
          title="W3Schools Free Online Web Tutorials"
        />
      </Modal>
    );
  }

  if (isSubmitting) {
    return (
      <div className="containe">
        <Topbar flag />
        <div className="main-container">
          <OnBoardingLoader
            hasAPIFullfilled={hasAPIFullfilled}
            savingError={savingError}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <Topbar flag />
      <div className="mt-5">
        <PopUpVideo />
        <div className="main-container">
          <div onClick={handleModal} className="video__div">
            <img src="./img/play-button.png" />
            <span>Let Us Help</span>
          </div>
          <div className="page-container">
            {isQuestionLoading ? (
              <LoaderTailSpin />
            ) : (
              <div className="que-container">
                {queList.map((qa, i) => (
                  <div className="each-que-container">
                    <p className="question text-black">
                      {i + 1}
                      .
                      {qa.que}
                    </p>
                    <p className="helper">{qa.desc}</p>
                    {qa.example && (
                      <p className="helper on_boarding-example">
                        <span>{qa.example}</span>
                      </p>
                    )}
                    <div className="onBoading_textarea_container">
                      <textarea
                        className="answer onboarding_answer"
                        placeholder=""
                        value={qa.answer}
                        onChange={(e) => handleChange(e, i)}
                      />

                      {qa.isValid === 0 && (
                        <CustomTooltip
                          placement="top"
                          arrow
                          title="The input is too short or too long. It will badly affect the training of our AI. Try to increase/decrease the text."
                        >
                          <div className="text-danger">
                            <CheckCircleIcon
                              style={{
                                width: '20px',
                                height: '20px',
                                marginLeft: '5px',
                                cursor: 'pointer',
                              }}
                            />
                          </div>
                        </CustomTooltip>
                      )}
                      {qa.isValid === 1 && (
                        <CustomTooltip
                          placement="top"
                          arrow
                          title="Good Going! For best output generation try to match with the length of the example text."
                        >
                          <div className="text-warning">
                            <CheckCircleIcon
                              style={{
                                width: '20px',
                                height: '20px',
                                marginLeft: '5px',
                                cursor: 'pointer',
                              }}
                            />
                          </div>
                        </CustomTooltip>
                      )}
                      {qa.isValid === 2 && (
                        <CustomTooltip placement="top" arrow title="Perfect!">
                          <div className="text-success">
                            <CheckCircleIcon
                              style={{
                                width: '20px',
                                height: '20px',
                                marginLeft: '5px',
                                cursor: 'pointer',
                              }}
                            />
                          </div>
                        </CustomTooltip>
                      )}
                    </div>

                    {qa.error && isErrorInSubmission && (
                      <small className="text-danger">
                        *This field is compulsory
                      </small>
                    )}
                  </div>
                ))}
                <div className="onboading_submit_container">
                  {savedSuccessfully && (
                    <span className="mr-2 text-success">
                      Saved Successfully
                    </span>
                  )}
                  {isErrorInSubmission && (
                    <span className="mr-2 text-danger">
                      *All fields are compulsory
                    </span>
                  )}
                  {isSavingCurrentStatus ? (
                    <LoaderSpin height={32} width={32} />
                  ) : (
                    <button onClick={handleSaveCurrentStatus}>Save</button>
                  )}
                  {credits.isChecking ? (
                    <LoaderSpin />
                  ) : (
                    <>
                      {credits.isOver ? (
                        <ButtonNext disable className="ml-2" />
                      ) : (
                        <button onClick={handleSubmit} className="ml-2">
                          Get Started
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default OnBoardingQue;
