import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import SaveIcon from '@mui/icons-material/Save';
import GetAppIcon from '@mui/icons-material/GetApp';
import CloseIcon from '@mui/icons-material/Close';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import TextFormatIcon from '@mui/icons-material/TextFormat';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import styled from 'styled-components';

export const WriteIcon = styled(AutoFixHighIcon)`
    width: 24px !important;
    height: 24px !important;
    cursor: pointer;
`;

export const TextIcon = styled(TextFormatIcon)`
    width: 24px !important;
    height: 24px !important;
    color: ${(props) => (props.active ? 'var(--primary-color)' : 'grey')};
    cursor: pointer;
`;

export const VideoIcon = styled(PlayCircleFilledIcon)`
    width: 22px !important;
    height: 22px !important;
    color: ${(props) => (props.active ? 'var(--primary-color)' : 'grey')};
    cursor: pointer;
`;

export const ConvertCategoryIcon = styled(TrackChangesIcon)`
    width: 20px !important;
    height: 20px !important;
    color: var(--primary-color);
    cursor: pointer;
`;

export const CrossIcon = styled(CloseIcon)`
    width: 18px !important;
    height: 18px !important;
    cursor: pointer;
`;

export const DoneIcon = styled(CheckCircleIcon)`
    color: var(--active-color);
    width: 18px !important;
    height: 18px !important;
`;

export const IncompleteIcon = styled(CheckCircleOutlineIcon)`
    color: var(--secondary-color);
    width: 18px !important;
    height: 18px !important;
`;

export const ActiveCategoryIcon = styled(RadioButtonCheckedIcon)`
    color: var(--primary-color);
    width: 12px !important;
    height: 12px !important;
`;

export const SavedIcon = styled(SaveIcon)`
    color: var(--primary-color);
    width: 20px !important;
    height: 20px !important;
    cursor: pointer;

`;

export const DownloadIcon = styled(GetAppIcon)`
    color: var(--primary-color);
    width: 20px !important;
    height: 20px !important;
    cursor: pointer;

`;

export const DownloadResearchIcon = styled(GetAppIcon)`
    color: var(--active-color);
    width: 20px !important;
    height: 20px !important;
    cursor: pointer;

`;
