import styled from 'styled-components';

export const PublishedArticlesWrapper = styled.div`
  height:100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: scroll;
`;

export const TagStatus = styled.span.attrs((props) => ({

  active: props.active || null,
}))`
    cursor: default;
    margin: 0 0.2rem;
    font-size: 14px;
    padding-right:0.3rem;
    color: ${(props) => (props.active ? '#2a9df5' : null)};
    font-weight:  ${(props) => (props.active ? '700' : null)};
    border-bottom:  ${(props) => (props.active ? '2px solid #2a9df5' : null)};

`;

export const ArticleCardWrapper = styled.div`
    position: relative;
    display:flex;
    flex-direction: column;
    width: 290px;
    border-radius: 0.4rem;
    height: 310px;
    background-color: #fff;
    margin-right:24px;
    margin-bottom:24px;
    font-size: 14px;
    cursor:default;
    box-shadow: 0 0 5px #a9a9a9;
`;

export const ImageSection = styled.img` 
  height:220px;
  flex:0.6;
  background-color: grey;
  border-radius: 0.4rem 0.4rem 0 0;

`;
export const BodySection = styled.div`
  display:flex;
  flex-direction: column;
  flex:0.6;
  padding:6px;


`;

export const SubTitle = styled.div`

font-size: 10px;
`;

export const AuthorDetails = styled.div`

display:flex;
align-items: center;
flex:0.6;

`;

export const BlogReadingTime = styled.div`
  display:flex;
  align-items: center;
  flex:0.4;
  justify-content:flex-end;

`;

export const CalenderTagStatus = styled.span.attrs((props) => ({

  active: props.active || null,
}))`
    cursor: default;
    font-size: 14px;
    color: ${(props) => (props.active ? '#2a9df5' : null)};
    font-weight:  ${(props) => (props.active ? '700' : null)};
    border-bottom:  ${(props) => (props.active ? '2px solid #2a9df5' : null)};

`;

export const CalenderArticleCardWrapper = styled.div`
    position: relative;
    display:flex;
    flex-direction: column;
    width: 100%;
    border-radius: 0.4rem;
    height: 178px;
    // height: 250px;
    background-color: #fff;
    margin-bottom:12px;
    margin-top:12px;
    font-size: 14px;
    cursor:default;
    // box-shadow: 0 0 5px #a9a9a9;
`;

export const CalenderImageSection = styled.img` 
  height:100px;
  flex:0.6;
  background-color: grey;
  border-radius: 0.4rem 0.4rem 0 0;

`;
export const CalenderBodySection = styled.div`
  display:flex;
  flex-direction: column;
  flex:0.6;
  padding:4px;


`;

export const CalenderSubTitle = styled.div`

font-size: 10px;
`;

export const CalenderAuthorDetails = styled.div`

display:flex;
align-items: center;
flex:0.6;

`;

export const CalenderBlogReadingTime = styled.div`
  display:flex;
  align-items: top;
  flex:0.4;
`;
