/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
import { MarketFunnelPanelInfos } from 'constants/main-menu';
import React from 'react';
import './styles.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addNewTab } from 'redux/index';

function MarketFunnelPanel() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tabs = useSelector((state) => state?.tabManager?.tabs);

  const handleOpen = (marketInfo) => {
    const tabName = `Market : ${marketInfo?.header_text}`;

    dispatch(addNewTab({ id: (tabs.length) + 1,
      name: tabName,
      path: marketInfo?.path },
    ));

    navigate(`${marketInfo?.path}`);
  };

  return (
    <div className="feature-detail-list">
      { MarketFunnelPanelInfos.map((marketFunnelInfo) => (
        <div className="feature-detail market-funnel-panel" key={marketFunnelInfo.header_text}>
          <div className="feature-detail-header h-margin-b-tiny">
            <marketFunnelInfo.header_icon style={{ width: '18px', color: '#2a9df5', marginRight: '0.25rem' }} />
            <span className="">{marketFunnelInfo?.header_text}</span>
          </div>
          <div className="feature-detail-description">
            {marketFunnelInfo?.description_text}
          </div>
          <div className="feature-time-start-container">
            <div className="feature-time">
              <marketFunnelInfo.time_icon className="h-margin-r-tiny" style={{ width: '14px', color: '#2a9df5' }} />
              <span>{marketFunnelInfo.time_text}</span>
            </div>
            <div className="feature-start" onClick={() => handleOpen(marketFunnelInfo)}>
              <span className="h-margin-r-tiny">{marketFunnelInfo.start_text}</span>
              <marketFunnelInfo.start_icon style={{ width: '14px', color: '#2a9df5' }} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default MarketFunnelPanel;
