import React from 'react';

function IconChat({ size, className }) {
  return (
    <svg
      className={className ?? ''}
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? '300'}
      height={size * 0.8 ?? '170'}
      data-name="Layer 1"
      viewBox="0 0 783.86 582"
    >
      <path
        fill="#e6e6e6"
        d="M255.368 631.65a383.363 383.363 0 0017.91 105.973c.242.797.511 1.58.772 2.377h66.833c-.07-.714-.143-1.51-.213-2.377-4.456-51.228 21-359.303 51.72-412.623-2.692 4.325-142.204 143.873-137.022 306.65z"
        data-name="Path 133"
        transform="translate(-208.07 -159)"
      />
      <path
        fill="#e6e6e6"
        d="M260.383 737.623c.56.796 1.14 1.593 1.735 2.377h50.14c-.38-.678-.82-1.474-1.331-2.377-8.283-14.948-32.798-59.641-55.554-105.973-24.445-49.791-46.891-101.47-45.001-120.174-.586 4.216-17.509 132.817 50.011 226.147z"
        data-name="Path 134"
        transform="translate(-208.07 -159)"
      />
      <path
        fill="#9e616a"
        d="M266.73 398.376a11.575 11.575 0 00-.335 1.792l-48.28 25.355-11.21-7.06-12.865 15.526 18.63 14.426a8.797 8.797 0 0010.557.162l50.194-36.464a11.543 11.543 0 10-6.691-13.737z"
      />
      <path
        fill="#9e616a"
        d="M324.938 570.183L334.619 563.957 315.213 523.657 300.926 532.845 324.938 570.183z"
      />
      <path
        fill="#2f2e41"
        d="M320.437 568.61l19.063-12.26h.001a14.445 14.445 0 0119.962 4.336l.254.395-31.213 20.073zM185.47 500.96l74.307-47.576s13.653-1.318 21.847 14.83l49.49 71.088-21.115 14.287-54.916-57.021-37.683 37.268s55.52-9.105 53.642 27.507c-1.49 29.056-77.477 19.089-108.78 13.737a41.049 41.049 0 01-28.764-19.691c-3.358-5.823-5.162-12.435-2.342-18.685 7.52-16.667 54.314-35.745 54.314-35.745z"
      />
      <path
        fill="#6c63ff"
        d="M148.633 540.72l-21.414-4.334 6.102-105.101 24.64-30.18 14.68-2.6a17.736 17.736 0 0120.291 12.84l6.47 23.639c10.98 18.589-4.4 40.143-5.819 42.058l.8 32.599z"
      />
      <path
        fill="#6c63ff"
        d="M168.572 405.221l1.359-4.806s14.744-10.868 37.275 17.295l-10.796 19.245-23.47-.938z"
      />
      <path
        fill="#2f2e41"
        d="M202.49 367.818c4.178 17.124-12.288 18.396-31.82 23.16s-34.733 11.217-38.91-5.906-3.847-49.363 23.784-56.103c28.583-6.973 42.77 21.726 46.947 38.85z"
      />
      <circle cx="164.74" cy="367.182" r="24.495" fill="#9e616a" />
      <path
        fill="#2f2e41"
        d="M136.675 371.554l9.413-2.296 1.316-12.64 3.666 11.425 4.5-1.098.767-7.373 2.139 6.664 33.427-8.154a25.93 25.93 0 00-31.337-19.046l-4.844 1.181a25.93 25.93 0 00-19.047 31.337z"
      />
      <path
        fill="#9e616a"
        d="M206.69 488.31a11.574 11.574 0 00-1.721.598l-45.955-29.359.574-13.236-19.862-3.476-3.293 23.332a8.797 8.797 0 005.093 9.248l56.552 25.515a11.543 11.543 0 108.613-12.621z"
      />
      <path
        fill="#6c63ff"
        d="M161.677 405.902l-3.463-4.366s-13.906-4.479-21.416 14.297-.47 37.082-.47 37.082l32.857 2.816 2.347-30.51z"
      />
      <path
        fill="#e6e6e6"
        d="M527.283 130.684L783.86 130.684 783.86 214.914 774.142 214.914 774.142 236.943 752.112 214.914 527.283 214.914 527.283 130.684z"
      />
      <path
        fill="#fff"
        d="M534.41 138.886H776.7339999999999V206.26999999999998H534.41z"
      />
      <path fill="#6c63ff" d="M553.896 158.559H670.569V162.1H553.896z" />
      <path fill="#e6e6e6" d="M553.896 170.808H757.247V174.349H553.896z" />
      <path fill="#e6e6e6" d="M553.896 183.056H757.047V186.597H553.896z" />
      <path
        fill="#e6e6e6"
        d="M630.506 0L373.928 0 373.928 84.23 383.647 84.23 383.647 106.26 405.676 84.23 630.506 84.23 630.506 0z"
      />
      <path fill="#fff" d="M381.055 8.202H623.379V75.586H381.055z" />
      <path fill="#6c63ff" d="M402.485 28.524H519.158V32.065H402.485z" />
      <path
        fill="#e6e6e6"
        d="M402.485 40.772H605.836V44.312999999999995H402.485z"
      />
      <path fill="#e6e6e6" d="M402.485 53.021H605.636V56.562H402.485z" />
      <path
        fill="#3f3d56"
        d="M312.865 379.645h-.455v-12.443A7.202 7.202 0 00305.21 360h-26.363a7.202 7.202 0 00-7.202 7.202v68.264a7.202 7.202 0 007.202 7.202h26.363a7.202 7.202 0 007.201-7.202v-46.964h.455z"
      />
      <path
        fill="#fff"
        d="M305.5 361.874h-3.442a2.555 2.555 0 01-2.366 3.52H284.59a2.555 2.555 0 01-2.365-3.52h-3.214a5.378 5.378 0 00-5.379 5.378v68.164a5.378 5.378 0 005.379 5.378h26.49a5.378 5.378 0 005.377-5.378v-68.164a5.378 5.378 0 00-5.378-5.378z"
      />
      <path
        fill="#fff"
        d="M598.506 248.549L283.441 248.549 283.441 351.98 295.375 351.98 295.375 379.03 322.426 351.98 598.506 351.98 598.506 248.549z"
      />
      <path
        fill="#3f3d56"
        d="M294.375 381.444V352.98h-11.934V247.55h317.065v105.43H322.84zm-9.934-30.465h11.934v25.637l25.637-25.637h275.494V249.55H284.44z"
      />
      <path
        fill="#6c63ff"
        d="M318.508 283.575H461.77599999999995V287.923H318.508z"
      />
      <path fill="#e6e6e6" d="M318.508 298.615H568.212V302.963H318.508z" />
      <path fill="#e6e6e6" d="M318.508 313.656H567.968V318.004H318.508z" />
      <path fill="#3f3d56" d="M428.892 582h-381a1 1 0 010-2h381a1 1 0 110 2z" />
    </svg>
  );
}

export default React.memo(IconChat);
