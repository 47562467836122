/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import React from 'react';
import { useNavigate } from 'react-router-dom';
import nicheIcon from '../../../../assets/MarketResearchImages/nicheIconPrimary.svg';

function Level3Container() {
  const navigate = useNavigate();

  const handleMRRCategory = (mrr_category) => {
    localStorage.setItem('category', mrr_category);
    navigate(`/MarketResearchCategory?level=3&subcategory=${mrr_category}`);
  };

  function MRRCardContainer({ mrr_image, mrr_name }) {
    return (
      <div
        className="mrr-card-container"
        onClick={() => handleMRRCategory(mrr_name)}
      >
        <div className="mrr-card-left">
          <img src={mrr_image} />
        </div>
        <div className="mrr-card-right">
          <p>{mrr_name}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="mrr-main">
      <div className="mrr-main-left">
        <MRRCardContainer
          mrr_image={nicheIcon}
          mrr_name="Current Environment and Trends"
        />
        <MRRCardContainer
          mrr_image={nicheIcon}
          mrr_name="Activities and UUIs"
        />
        <MRRCardContainer mrr_image={nicheIcon} mrr_name="Promise" />
        {/* <MRRCardContainer mrr_image={nicheIcon} mrr_name="Activity Summary" />
        <MRRCardContainer
          mrr_image={nicheIcon}
          mrr_name="Option A and Option B"
        />
        <MRRCardContainer mrr_image={nicheIcon} mrr_name="Who is this for?" /> */}
        <MRRCardContainer mrr_image={nicheIcon} mrr_name="Case Study" />
        <MRRCardContainer
          mrr_image={nicheIcon}
          mrr_name="Benefits and Outcome"
        />
        <MRRCardContainer
          mrr_image={nicheIcon}
          mrr_name="How it Works and Features"
        />
        <MRRCardContainer
          mrr_image={nicheIcon}
          mrr_name="Offer and Call to Action"
        />
        {/* <MRRCardContainer
          mrr_image={nicheIcon}
          mrr_name="Elephants and Labels"
        />
        <MRRCardContainer
          mrr_image={nicheIcon}
          mrr_name="Market Type Hypothesis"
        /> */}
        <MRRCardContainer mrr_image={nicheIcon} mrr_name="Testimonials" />
        {/* <MRRCardContainer
          mrr_image={nicheIcon}
          mrr_name="Influencer Endorsement"
        />
        <MRRCardContainer
          mrr_image={nicheIcon}
          mrr_name="Credible Studies and Resources"
        />
        <MRRCardContainer mrr_image={nicheIcon} mrr_name="FAQs" />
        <MRRCardContainer mrr_image={nicheIcon} mrr_name="Team" /> */}
        <MRRCardContainer mrr_image={nicheIcon} mrr_name="Core Concept" />
        {/* <MRRCardContainer mrr_image={nicheIcon} mrr_name="Company Logos" />
        <MRRCardContainer
          mrr_image={nicheIcon}
          mrr_name="Trust and Expertise for Credibility"
        />
        <MRRCardContainer mrr_image={nicheIcon} mrr_name="Asset Links" />
        <MRRCardContainer mrr_image={nicheIcon} mrr_name="Top Niche Quotes" /> */}
        <MRRCardContainer
          mrr_image={nicheIcon}
          mrr_name="Offer and Bonus Stacking Together"
        />
        {/* <MRRCardContainer
          mrr_image={nicheIcon}
          mrr_name="Founder Grounding Story About me"
        /> */}
      </div>
    </div>
  );
}

export default Level3Container;
