/* eslint-disable react/no-unescaped-entities */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import { Button } from '@mui/material';
import { Calendar } from 'antd';
import CodeRoundedIcon from '@mui/icons-material/CodeRounded';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkIcon from '@mui/icons-material/Link';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import copyClipboard from 'Component/Shared/copyToClipBoard';
import createInstance from 'Config/AxiosConfig';
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import './Blogshare.css';
import _ from 'lodash';
import axios from 'axios';
import moment from 'moment';
import LoaderSpin from 'Component/Shared/Loader';
import { ButtonPrimary, ButtonSecondary } from 'Component/Shared/GlobalStyles';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CustomTooltip from 'Component/Shared/CustomTooltip';
import PublishedWithChangesRoundedIcon from '@mui/icons-material/PublishedWithChangesRounded';

const ModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    height: '300px',
    width: '800px',
  },
};

const ScheduleModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    height: '520px',
    width: '400px',
  },
};

// blog_id
export default function PublishButton({ blog_id, blog_name }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const [errorInPublish, setErrorInPublish] = useState(false);
  const [blogId, setBlogId] = useState(blog_id);
  // 0 = Normal Link , 1 = Embeded Link
  const [currentTab, setCurrentTab] = useState(0);
  const [socialMediaCaps, setSocialMediaCaps] = useState('');

  // const PUBLISH_URL = `https://${window.location.host}/Blog?id=${blog_id}`
  const STATIC_CAPTIONS = `I have created a blog on "${blog_name}" using CrawlQ. Checkout my blog by clicking on the link below.%0A`;
  const PUBLISH_URL = `https://app.crawlq.ai/Blog?id=${blogId}`;
  const IFRAME_URL = `<iframe src=${PUBLISH_URL} width="640" height="407" frameborder="0" marginheight="0" marginwidth="0">Loading...</iframe>`;

  const { name } = useSelector((state) => state.workspace);

  const currentDate = moment().format('DD-MM-YYYY');

  useEffect(() => {
    if (_.isUndefined(blog_id)) return;
    handlePublish();
  }, []);

  useEffect(() => {
    if (isScheduleModalOpen && !isModalOpen) {
      handlePublish(currentDate);
    }
  }, [isScheduleModalOpen]);

  const handlePublish = async (scheduledDate = null) => {
    console.log('Calling Publish .......');
    let source = '';
    if (window.location.pathname.includes('sales-ai-content-writer')) {
      source = 'Sales Copywriter';
    }

    const body = {
      name, // Workspace Name
      blog_name,
      source,
      blog_id: blogId,
      scheduled_date: scheduledDate,
    };
    const instance = await createInstance();
    console.log(body);
    if (_.isUndefined(blog_id)) {
      console.log('calling api..........');
      const { data } = await axios.post(
        'https://apis.crawlq.ai/ai-writer-load',
        {
          username: localStorage.getItem('username'),
          name: localStorage.getItem('name'),
          blog_name,
          source,
        },
      );
      if (data.statusCode === '500') {
        setErrorInPublish(true);
        return;
      }
      setBlogId(data.blog.id);
      body.blog_id = data.blog.id;
    }

    const { data } = await instance.post('publish-blog', body);
    if (data.statusCode !== '200') {
      setErrorInPublish(true);
    }
  };

  function NormalLink() {
    return (
      <div className="publish-modal-content">
        <p>
          <b>Anyone</b> with this link will be able to access this blog.
        </p>
        <div className="publish-modal-link-copy">
          <a href={PUBLISH_URL} target="_blank" rel="noreferrer">
            {PUBLISH_URL.slice(0, 62)}
            ...
          </a>
          <ButtonSecondary onClick={() => copyClipboard(PUBLISH_URL)}>
            Copy
          </ButtonSecondary>
        </div>
        <div>
          <hr />
          <small className="font-accent">
            The published link will always fetch the latest version of the blog.
            So, you don't need to re-publish it if you make any changes to the
            blog.
          </small>
        </div>
      </div>
    );
  }

  function EmbedLink() {
    return (
      <div className="publish-modal-content">
        <p>
          <b>Embed HTML</b>
        </p>
        <div className="publish-modal-link-copy">
          <code>
            {IFRAME_URL.slice(0, 62)}
            ...
          </code>
          <Button
            variant="outlined"
            color="#2a9df5"
            onClick={() => copyClipboard(IFRAME_URL)}
          >
            Copy
          </Button>
        </div>
        <div>
          <hr />
          <small className="font-accent">
            The published link will always fetch the latest version of the blog.
            So, you don't need to re-publish it if you make any changes to the
            blog.
          </small>
        </div>
      </div>
    );
  }

  function SocialMediaShareButtons() {
    return (
      <div className="publish-modal-nav">
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
            PUBLISH_URL, // BLog URL Goes Here  (except localhost )
          )}&quote=${socialMediaCaps}${STATIC_CAPTIONS}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FacebookIcon style={{ color: '#4267B2' }} />{' '}
        </a>
        <a
          href={`https://twitter.com/share?url=${encodeURI(
            PUBLISH_URL, // BLog URL Goes Here  (except localhost )
          )}&text=${socialMediaCaps}${STATIC_CAPTIONS}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <TwitterIcon style={{ color: '#00acee' }} />
        </a>
        <a
          href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURI(
            PUBLISH_URL, // BLog URL Goes Here  (except localhost )
          )}&mini=true&summary=Some Text`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkedInIcon style={{ color: '#0e76a8' }} />
        </a>
      </div>
    );
  }

  const onDateChange = async (value, mode) => {
    const newDate = value.format('DD-MM-YYYY');
    console.log('selectedDate', newDate);
    handlePublish(newDate);
  };

  function ScheduleModal() {
    return (
      <Modal
        isOpen={isScheduleModalOpen}
        onRequestClose={() => setIsScheduleModalOpen(false)}
        style={ScheduleModalStyles}
        ariaHideApp={false}
      >
        <div className="publish-modal-container">
          <div className="schedule-modal-header">
            <h2 className="font-accent txt-primary mb-0">Schedule Blog</h2>
          </div>
          <div className="publish-modal-tab" data-tab-anima="fade-left">
            <div className="site-calendar-demo-card">
              <Calendar fullscreen={false} onChange={onDateChange} />
            </div>
          </div>
          <ButtonPrimary
            onClick={() => {
              setIsScheduleModalOpen(false);
              window.location.assign('https://app.crawlq.ai/AIContentWriter');
            }}
            style={{ marginLeft: '130px', marginTop: '20px', width: '100px' }}
          >
            Schedule
          </ButtonPrimary>
          {errorInPublish && (
            <p className="text-danger">Error In Scheduling Blog</p>
          )}
        </div>
      </Modal>
    );
  }

  function PublishModal() {
    return (
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        style={ModalStyles}
        ariaHideApp={false}
      >
        <div className="publish-modal-container">
          <div className="publish-modal-header">
            <h2 className="font-accent txt-primary mb-0">Publish Blog</h2>
          </div>
          <div className="publish-modal-tab" data-tab-anima="fade-left">
            <ul className="publish-modal-nav">
              <li className={currentTab === 0 ? 'active ml-1' : 'ml-1'}>
                <div className="" onClick={() => setCurrentTab(0)}>
                  <LinkIcon />
                </div>
              </li>
              <li className={currentTab === 1 ? 'active ml-3' : 'ml-3'}>
                <div className="" onClick={() => setCurrentTab(1)}>
                  <CodeRoundedIcon />
                </div>
              </li>

              <li className={currentTab === 2 ? 'active ml-3' : 'ml-3'}>
                <div
                  onClick={() => {
                    setCurrentTab(2);
                    setIsModalOpen(false);
                    setIsScheduleModalOpen(true);
                  }}
                >
                  <CustomTooltip
                    title="Plan, Organize, and Track Your Content Initiatives"
                    placement="top"
                    arrow
                  >
                    <CalendarMonthIcon />
                  </CustomTooltip>
                </div>
              </li>
            </ul>

            <SocialMediaShareButtons />
          </div>
          {_.isUndefined(blogId) && !errorInPublish ? (
            <div className="d-flex justify-content-center">
              <LoaderSpin />
            </div>
          ) : (
            <div data-tab-anima="fade-left">
              {currentTab === 0 ? <NormalLink /> : <EmbedLink />}
            </div>
          )}
          {errorInPublish && (
            <p className="text-danger">Error In Publishing Blog</p>
          )}
        </div>
      </Modal>
    );
  }
  return (
    <>
      <div
        onClick={() => {
          handlePublish();
          setIsModalOpen(true);
        }}
        className="editor-nav-icons"
      >
        <CustomTooltip placement="top" arrow title="Publish Blog">
          <PublishedWithChangesRoundedIcon />
        </CustomTooltip>
      </div>
      <PublishModal />
      <ScheduleModal />
    </>
  );
}
