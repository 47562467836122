import styled from 'styled-components';

export const CategoryContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 1.2rem;
    margin-top: 0.8rem;
    
`;

export const MultiLoaderContainer = styled.div`
display:flex;
flex-direction: row;
margin-bottom: 6px;
`;
