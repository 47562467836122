import React, { createContext, useContext, useMemo, useState } from 'react';

export const InsightsContext = createContext();

export const InsightsProvider = ({ children }) => {
  const [SelectedConnectorInfo, setSelectedConnectorInfo] = useState('');

  const insightsValue = useMemo(
    () => ({
      useSelectedConnectorInfo: {
        SelectedConnectorInfo,
        setSelectedConnectorInfo,
      },
      random: '',
    }),
    [SelectedConnectorInfo],
  );

  return (
    <InsightsContext.Provider value={insightsValue}>
      {children}
    </InsightsContext.Provider>
  );
};

export const useInsightsContext = () => {
  const context = useContext(InsightsContext);

  if (context === undefined) {
    throw new Error(
      'useInsightsContext must be used within a Insights Provider',
    );
  }

  return context;
};
