/* eslint-disable react/require-default-props */
import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import FallbackLoader from 'components/common/FallbackLoader';
import { getAuthToken } from 'Config/localstorageConfig';

function SuspenseWrapper({ Component, secure }) {
  const isAuth = getAuthToken();
  const location = useLocation();

  if (secure) {
    if (isAuth) {
      return (
        <React.Suspense fallback={<FallbackLoader />}>
          <Component />
        </React.Suspense>
      );
    }
    <Navigate to="/" replace state={{ from: location.pathname }} />;
  }
  return (
    <React.Suspense fallback={<FallbackLoader />}>
      <Component />
    </React.Suspense>
  );
}

SuspenseWrapper.propTypes = {
  secure: PropTypes.bool,
  Component: PropTypes.elementType.isRequired,
};

export default SuspenseWrapper;
