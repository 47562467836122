/* eslint-disable default-param-last */
import { initialStateWorkspaceConfig } from 'libs/DefaultStateOfWorkspaceConfig';
import { WORKSPACE_FEILDS_UPDATED } from './WorkspaceActionTypes';

const initialState = {
  workspaces: {
    _currentWorkspace: {
      ...initialStateWorkspaceConfig,
    },
  },
};

const newWorkspaceDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case WORKSPACE_FEILDS_UPDATED:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default newWorkspaceDetailsReducer;
