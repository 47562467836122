/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */

import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import './DemoWorkspace.css';
// Components
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Modal from 'react-modal';
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';
import { useSelector } from 'react-redux';
import LoaderSpin from '../../../../Shared/Loader';

// Logic Functions
import { DemoBuyRequest } from './DemoWorkspaceLogic';
import DemoWorkspacePreview from './DemoWorkspacePreview/DemoWorkspacePreview';
import DemoCloneWorkspaceRequest from './DemoWorkspaceClone/DemoWorkspaceCloneLogic';
import DemoWorkspaceClone from './DemoWorkspaceClone/DemoWorkspaceClone';

const ModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

/* Props :
        workspaces - list of projects in demo account
        searchInput - to filter list of workspace according to given data
        workspace_limit - limit to check if user can clone more demo projects
 */
function DemoWorkspace({ workspaces, searchInput }) {
  const [IsDemoCloneOpen, setIsDemoCloneOpen] = useState(false);
  const [showStartUpModal, setShowStartUpModal] = useState(true);

  const [SelectedWorkspaceDetails, setSelectedWorkspaceDetails] = useState({
    workspace_name: '',
    workspace_domain: '',
  });

  const [SelectedWorkspaceIndex, setSelectedWorkspaceIndex] = useState(null);
  const [DemoWorkspaceList, setDemoWorkspaceList] = useState(workspaces);
  const [currentSortedKey, setCurrentSortedKey] = useState({
    key: null,
    asc: true,
  });
  const [isfetchingPayURL, setIsfetchingPayURL] = useState(false);
  const [isCopyingWorkspace, setIsCopyingWorkspace] = useState(false);
  const [errorInCopy, setErrorInCopy] = useState(false);
  const [showOnlyFree, setShowOnlyFree] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [showOffer, setShowOffer] = useState(false);
  const [notSelectedError, setNotSelectedError] = useState(false);
  const [workspaceLimitReachedError, setWorkspaceLimitReachedError] = useState(
    false,
  );
  const [copiedSuccessfully, setCopiedSuccessfully] = useState(false);

  const { workspace_count, workspace_limit } = useSelector((state) => state?.user);

  const getAvailableWorkspaceLimit = () => {
    const limit = workspace_limit - workspace_count;
    if (limit < 0) {
      return 0;
    }
    return limit;
  };

  useEffect(() => {
    workspaces = workspaces.map((workspace) => ({
      ...workspace,
      isSelected: false,
    }));
    handleToggler();
  }, []);

  const handleSorting = (key, asc = true) => {
    setCurrentSortedKey({ asc, key });
    const order = asc ? 1 : -1;
    const userWorkspaceList = [...DemoWorkspaceList];
    userWorkspaceList.sort((a, b) => {
      if (key === 'date_created') {
        return new Date(a[key]) > new Date(b[key]) ? 1 * order : -1 * order;
      }
      return String(a[key]).toLowerCase() > String(b[key]).toLowerCase()
        ? 1 * order
        : -1 * order;
    });
    setDemoWorkspaceList([...userWorkspaceList]);
    console.log(userWorkspaceList);
  };

  const handleSelectMultiple = (e, index) => {
    const demoWorkspaceTemp = [...DemoWorkspaceList];
    demoWorkspaceTemp[index].isSelected = !demoWorkspaceTemp[index].isSelected;
    setDemoWorkspaceList([...demoWorkspaceTemp]);
    setNotSelectedError(false);
    setWorkspaceLimitReachedError(false);
    setErrorInCopy(false);
    setCopiedSuccessfully(false);
    if (!showOnlyFree) {
      const selectedList = demoWorkspaceTemp.filter(
        (workspace) => workspace.isSelected,
      );
      if (selectedList.length > 0 && selectedList.length < 5) {
        setShowOffer(true);
      } else {
        setShowOffer(false);
      }
    }
  };

  const handleSelectAll = () => {
    let demoWorkspaceTemp = [...DemoWorkspaceList];
    demoWorkspaceTemp = demoWorkspaceTemp.map((workspace) => ({
      ...workspace,
      isSelected: !selectAll,
    }));
    setDemoWorkspaceList([...demoWorkspaceTemp]);
    setSelectAll((prev) => !prev);
    setNotSelectedError(false);
  };

  const handlePayMultiple = async () => {
    const selectedWorkspace = DemoWorkspaceList.filter(
      (workspace) => workspace.isSelected,
    );
    const workspace_name_arr = selectedWorkspace.map((workspace) => workspace.name);
    if (workspace_name_arr.length <= 0) {
      setNotSelectedError(true);
      return;
    }
    setIsfetchingPayURL(true);
    const { checkout_url, success } = await DemoBuyRequest(workspace_name_arr);
    if (success) {
      window.open(checkout_url, '_blank');
    }
    setIsfetchingPayURL(false);
  };

  const handleCopyMultiple = async () => {
    const selectedWorkspace = DemoWorkspaceList.filter(
      (workspace) => workspace.isSelected,
    );
    const workspace_name_arr = selectedWorkspace.map((workspace) => workspace.name);
    const workspace_site_arr = selectedWorkspace.map((workspace) => workspace.site);
    if (workspace_name_arr.length <= 0) {
      setNotSelectedError(true);
      return;
    }

    if (workspace_name_arr.length > getAvailableWorkspaceLimit()) {
      setWorkspaceLimitReachedError(true);
      return;
    }
    setIsCopyingWorkspace(true);
    const data = await DemoCloneWorkspaceRequest(
      workspace_name_arr,
      workspace_site_arr,
    );
    if (data.error) {
      setErrorInCopy(true);
    } else {
      setCopiedSuccessfully(true);
    }
    setIsCopyingWorkspace(false);
  };

  const showSortingIcon = () => (currentSortedKey.asc ? (
    <ArrowDropUpRoundedIcon style={{ color: '#2a9df5', fontSize: '2rem' }} />
  ) : (
    <ArrowDropDownRoundedIcon
      style={{ color: '#2a9df5', fontSize: '2rem' }}
    />
  ));

  function WorkspaceTableHeader() {
    return (
      <div className="demo-workspace-table-column-header">
        <span className="demo-column-header-more">
          <input
            type="checkbox"
            onChange={handleSelectAll}
            checked={selectAll}
          />
        </span>
        <span
          className="demo-column-header-name"
          onClick={() => handleSorting('name', !currentSortedKey.asc)}
        >
          NAME
          {currentSortedKey.key === 'name' && <>{showSortingIcon()}</>}
        </span>
        <span
          className="demo-column-header-domain"
          onClick={() => handleSorting('site', !currentSortedKey.asc)}
        >
          DOMAIN
          {currentSortedKey.key === 'site' && <>{showSortingIcon()}</>}
        </span>
        <span
          className="demo-column-header-language"
          onClick={() => handleSorting('Lang', !currentSortedKey.asc)}
        >
          LANGUAGE
          {currentSortedKey.key === 'Lang' && <>{showSortingIcon()}</>}
        </span>
        <span
          className="demo-column-header-creation-date"
          onClick={() => handleSorting('date_created', !currentSortedKey.asc)}
        >
          CREATION
          {currentSortedKey.key === 'date_created' && <>{showSortingIcon()}</>}
        </span>
        {/* <span className="demo-column-header-copy"></span> */}
        <span
          className="demo-column-header-price"
          onClick={() => handleSorting('cost', !currentSortedKey.asc)}
        >
          PROMO PRICE
          {currentSortedKey.key === 'cost' && <>{showSortingIcon()}</>}
        </span>

        <span className="demo-column-header-more">PREVIEW</span>
      </div>
    );
  }

  function DemoWorkspaceRows({
    index,
    name,
    domain,
    price,
    language,
    creation_date,
    isSelected,
  }) {
    return (
      <div className="demo-workspace-data-container">
        <div className="demo-workspace-table-row-data">
          <ReactTooltip />
          <span className="demo-row-data-serion-no">
            <input
              type="checkbox"
              checked={isSelected}
              onChange={(e) => handleSelectMultiple(e, index)}
            />
          </span>
          <span className="demo-row-data-name">
            {' '}
            {name}
            {' '}
          </span>
          <span className="demo-row-data-domain">
            {' '}
            {domain}
            {' '}
          </span>
          <span className="demo-row-data-language">
            {language}
            {' '}
          </span>
          <span className="demo-row-data-creation-date">
            {creation_date.slice(0, 10)}
          </span>
          {price !== 0 ? (
            <span className="demo-row-data-price">
              {' '}
              $
              {price}
            </span>
          ) : (
            <span className="demo-row-data-price2"> FREE </span>
          )}
          <span
            className="demo-row-data-more"
            onClick={() => handleWorkspaceDetailOpen(index)}
          >
            <UnfoldMoreIcon style={{ width: '20px', height: '20px' }} />
          </span>
        </div>

        {SelectedWorkspaceIndex === index ? (
          <DemoWorkspacePreview workspace_name={name} />
        ) : null}
      </div>
    );
  }

  const handleWorkspaceDetailOpen = (index) => {
    if (index === SelectedWorkspaceIndex) {
      setSelectedWorkspaceIndex(null);
    } else {
      setSelectedWorkspaceIndex(index);
    }
  };

  const handleCloseDemoCloneModal = () => {
    setIsDemoCloneOpen((IsDemoCloneOpen) => !IsDemoCloneOpen);
  };
  const handleToggler = (e) => {
    workspaces.map((workspace) => ({ ...workspace, isSelected: false }));
    if (showOnlyFree) {
      const workspaceToShow = workspaces.filter((workspace) => workspace.cost > 0);
      setShowOnlyFree(false);
      setDemoWorkspaceList([...workspaceToShow]);
    } else {
      const workspaceToShow = workspaces.filter(
        (workspace) => !(workspace.cost > 0),
      );
      setShowOnlyFree(true);
      setDemoWorkspaceList([...workspaceToShow]);
    }
    setSelectAll(false);
    setShowOffer(false);
    setNotSelectedError(false);
    setErrorInCopy(false);
    setCopiedSuccessfully(false);
  };

  function CloneDemoWorkspaceModal() {
    return (
      <Modal
        isOpen={IsDemoCloneOpen}
        onRequestClose={handleCloseDemoCloneModal}
        style={ModalStyles}
        ariaHideApp={false}
      >
        <DemoWorkspaceClone
          workspace_detail={SelectedWorkspaceDetails}
          close_clone_demo_modal={handleCloseDemoCloneModal}
        />
      </Modal>
    );
  }

  function ShowOnStartUpModal() {
    return (
      <Modal
        isOpen={false}
        onRequestClose={() => setShowStartUpModal(false)}
        style={ModalStyles}
      >
        <div
          style={{
            padding: '1rem',
            width: '750px',
            height: 'max-content',
          }}
        >
          <div
            className="onBoading_loader_container"
            style={{ width: 'unset', height: 'unset' }}
          >
            <div className="onBoading_loader_message">
              <div>
                <b>What are marketplace workspaces?</b>
                <div className="mt-1">
                  <span>
                    These workspaces are researched on a specific niche, have
                    pre-filled data in market research and blogs and scripts
                    already created and ready to be published.
                  </span>
                </div>
              </div>
              <br />
              <div>
                <b>What will they do for you?</b>
                <ul>
                  <li>
                    Cut down your research time by 1/10th as they are already
                    researched on a niche
                  </li>

                  <li className="mt-2">
                    Save 50% of your monthly AI Credits as they have
                    ready-to-publish content
                  </li>

                  <li className="mt-2">
                    Get highly optimised content as these workspaces are
                    designed, tweaked and refined to fit your niche
                  </li>
                </ul>
              </div>
              <br />
              <p>
                We are offering them in just
                {' '}
                <b>$25</b>
                {' '}
                promo price for a
                limited period of time.
              </p>
              <p>
                So go ahead, find one that fits your target niche and get your
                optimized audience research worth $10k in just
                {' '}
                <b>$25</b>
                .
              </p>
              <div className="onBoarding_btn_container">
                <button
                  className="btn btn-primary demo-workspace-btn"
                  onClick={() => setShowStartUpModal(false)}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <div>
      <div className="demo-workspace-table-pay-select-btn">
        {copiedSuccessfully && (
          <div className="mx-2 text-success">
            All Workspace Copied Successfully
          </div>
        )}
        {errorInCopy && (
          <div className="mx-2 text-danger">
            Some workspace(s) couldn’t be copied
          </div>
        )}
        {notSelectedError && (
          <div clasisName="mx-2 text-danger">Select a workspace to proceed</div>
        )}
        {workspaceLimitReachedError && (
          <div className="mx-2 text-danger">
            You can copy
            {' '}
            {getAvailableWorkspaceLimit()}
            {' '}
            Workspace(s)
          </div>
        )}
        {showOffer && (
          <div className="mx-2 text-warning text-bold">
            Buy 5 or more workspaces to get 15% off
          </div>
        )}
        <div>
          {!showOnlyFree ? (
            <>
              {!isfetchingPayURL ? (
                <button
                  className="demo-workspace-btn demo-workspace-btn-buy"
                  onClick={handlePayMultiple}
                >
                  <ShoppingCartRoundedIcon />
                  {' '}
                  Buy Now
                </button>
              ) : (
                <LoaderSpin />
              )}
            </>
          ) : (
            <>
              {!isCopyingWorkspace ? (
                <button
                  className="upgradeModal_fancy_btn demo-workspace-btn"
                  onClick={handleCopyMultiple}
                >
                  Copy
                </button>
              ) : (
                <LoaderSpin />
              )}
            </>
          )}
        </div>
        <div className="demo-workspace-switch-container">
          <label className="demo-workspace-switch">
            <input className="demo-workspace" type="checkbox" />
            <span
              className="demo-workspace slider round"
              onClick={handleToggler}
              style={{
                justifyContent: !showOnlyFree ? 'flex-end' : 'flex-start',
              }}
            >
              <b className="text-bold mx-2">
                {!showOnlyFree ? 'Paid' : 'Free'}
              </b>
            </span>
          </label>
        </div>
      </div>
      <WorkspaceTableHeader />
      <CloneDemoWorkspaceModal />
      <ShowOnStartUpModal />
      <div className="demo-workspace-table-data-body">
        <div className="demo-workspace-table-data-container">
          {workspaces
            && DemoWorkspaceList.map((workspace, index) => (
              <>
                {workspace.name
                  .toLowerCase()
                  .includes(searchInput.toLowerCase()) && (
                    <>
                      <DemoWorkspaceRows
                        index={index}
                        name={workspace.name}
                        price={workspace.cost}
                        domain={workspace.site}
                        language={workspace.Lang}
                        creation_date={workspace.date_created}
                        isSelected={workspace.isSelected}
                      />
                      <div className="row-data-divider" />
                    </>
                )}
              </>
            ))}
        </div>
      </div>
    </div>
  );
}

export default DemoWorkspace;
