/* eslint-disable import/no-extraneous-dependencies */
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import {
  Flex,
  Input,
  InputWrapper,
  Label,
} from 'Component/Shared/GlobalStyles';
import { motion } from 'framer-motion';
import { initialStateWorkspaceConfig } from 'libs/DefaultStateOfWorkspaceConfig';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateWorkspaceDetails } from '../../../../../../redux';
import styles from '../index.module.css';
import { FormHeading, ProductInputWrapper } from '../styles';

const ProductInput = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [productName, setProductName] = useState('');
  const [founderName, setFounderName] = useState('');

  const dispatch = useDispatch();
  const workspaces = useSelector(
    (state) => state?.updateWorkspaceConfiguration?.workspaces,
  );

  const updateNameInRedux = () => {
    const _currentWorkspaceDetails = workspaces?._currentWorkspace;
    const newDataForRedux = {
      ...initialStateWorkspaceConfig,
      ..._currentWorkspaceDetails,
      product_name: productName,
      founder_name: founderName,
    };

    dispatch(
      updateWorkspaceDetails({
        workspaces: {
          _currentWorkspace: {
            ...newDataForRedux,
          },
        },
      }),
    );
  };

  useEffect(() => {
    const { product_name, founder_name } = workspaces?._currentWorkspace ?? '';
    setProductName(product_name);
    setFounderName(founder_name);
  }, []);

  useEffect(() => {
    updateNameInRedux();
  }, [productName, founderName]);

  return (
    <ProductInputWrapper>
      <Flex gap={'0.5rem'} align={'center'}>
        {isExpanded ? (
          <KeyboardArrowDownRoundedIcon
            className={styles.icons_size}
            onClick={() => setIsExpanded(false)}
          />
        ) : (
          <KeyboardArrowRightRoundedIcon
            className={styles.icons_size}
            onClick={() => setIsExpanded(true)}
          />
        )}
        <FormHeading onClick={() => setIsExpanded((prev) => !prev)}>
          Product/Service Details
        </FormHeading>
      </Flex>
      {isExpanded && (
        <motion.div
          layout
          initial={{ opacity: 0, y: '-5vw', x: '0vw' }}
          animate={{ opacity: 1, y: 0, x: 0 }}
          transition={{ duration: 0.2, ease: 'easeInOut' }}
          className={styles.product_motion_parent}
        >
          <Flex
            className={styles.input_wrapper_parent}
            direction={'column'}
            gap={'1.2rem'}
          >
            <InputWrapper>
              <Label>
                <span className="text-danger">&#65121;</span>Product Name
              </Label>
              <Input
                value={productName}
                onChange={(e) => {
                  setProductName(e.target.value);
                }}
              />
            </InputWrapper>
            <InputWrapper>
              <Label>
                <span className="text-danger">&#65121;</span>Founder Name
              </Label>
              <Input
                value={founderName}
                onChange={(e) => {
                  setFounderName(e.target.value);
                }}
              />
            </InputWrapper>
          </Flex>
        </motion.div>
      )}
    </ProductInputWrapper>
  );
};

export default ProductInput;
