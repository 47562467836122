/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { getAuthToken, setAuthToken } from './localstorageConfig';

const DEV_URL = 'https://p8dapk4yf9.execute-api.us-east-2.amazonaws.com/devel/';
const BASE_URL = 'https://apis.crawlq.ai/';

export const validateUserAndRefreshToken = async () => {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const { refreshToken } = cognitoUser.getSignInUserSession();

    return new Promise((resolve, reject) => {
      cognitoUser.refreshSession(refreshToken, (err, session) => {
        if (session) {
          const { idToken } = session;
          setAuthToken(idToken.jwtToken);
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  } catch (error) {
    return false;
  }
};

const createInstance = async () => {
  const jwt_token = getAuthToken();
  const apiAxiosinstance = axios.create({
    baseURL: BASE_URL,
    headers: { Authorization: jwt_token },
  });

  const cognitoUser = await Auth.currentAuthenticatedUser();
  const { refreshToken } = cognitoUser.getSignInUserSession();

  let retryCounter = 0;
  const maxRetry = 3;

  // Response interceptor for API calls
  apiAxiosinstance.interceptors.response.use(
    (response) => {
      retryCounter = 0;
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      if (
        retryCounter < maxRetry &&
        error.response.status === 401 &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        cognitoUser.refreshSession(refreshToken, (err, session) => {
          if (session) {
            const { idToken } = session;
            setAuthToken(idToken.jwtToken);
            originalRequest.headers.Authorization = `${idToken.jwtToken}`;
            retryCounter += 1;
            return apiAxiosinstance(originalRequest);
          }
        });
      }
      return Promise.reject(error);
    },
  );
  return apiAxiosinstance;
};

export default createInstance;
