/* eslint-disable max-len */
import createInstance from 'Config/AxiosConfig';
import { useQuery } from 'react-query';
import { getMonthsFromTodayDate } from 'utils/helpers';
import {
  getReduxUserState,
  getReduxWorkspaceState,
} from 'utils/helpers/redux-selectors';

export const getQueriesVsTimeActivity = async () => {
  const { email } = getReduxUserState();
  const { name } = getReduxWorkspaceState();
  const startFrom = getMonthsFromTodayDate(2);

  const instance = await createInstance();
  const response = await instance.get(
    `https://apis.crawlq.ai/get-graphs-data?username=${email}&name=${name}&graph_name=queries_vs_time`,
  );

  if (response && response?.data?.statusCode === '200') {
    return response?.data?.data ?? [];
  }

  throw new Error('Could not fetch Workspace Creating Heat Map');
};

// Keep Query Value as File Name to maintain consistency and uniqueness in query index
const QUERY_KEY = 'get-queries-vstime';

// Query Config Options
const queryOptions = {
  staleTime: 1 * 60 * 1000,
};

// QUERY
export const useGetQueriesVsTimeActivity = () =>
  useQuery([QUERY_KEY], () => getQueriesVsTimeActivity(), queryOptions);
