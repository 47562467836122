import { baseAPIsUrl } from 'Config/Api';
import createInstance from 'Config/AxiosConfig';
import { useQuery } from 'react-query';

export const getSalesCategoryList = async () => {
  try {
    const instance = await createInstance();

    const { data } = await instance.get(`${baseAPIsUrl}/select-script`);

    if (data.statusCode === '200') return data.data;
    throw new Error('Could Fetch Category List');
  } catch (ex) {
    throw new Error('Could Fetch Category List');
  }
};

// Keep Query Value as File Name to maintain consistency and uniqueness
const QUERY_KEY = 'get-sales-category-list';

// Query Config Options
const queryOptions = {
  staleTime: 30000,
};

// QUERY
export const useGetSalesCategoryList = () =>
  useQuery(QUERY_KEY, getSalesCategoryList, queryOptions);
