/* eslint-disable no-return-assign */
import React, { useRef, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import './content-editor.css';
import { useSelector } from 'react-redux';
import { useContentContext } from 'context/content/ContentContext';
import { useGetBlogContent } from 'queries/content/content-editor/get-blog-content';
import { getBlogText } from 'queries/content/content-editor/selectors';

function ContentEditor() {
  const { blog_topic, blog_name, from } = useSelector((state) => state?.tabManager?.currentTab);
  const { contentToolsVisiblity, topic } = useContentContext();

  const { data, isLoading } = useGetBlogContent(blog_name, from);
  const editorValue = getBlogText(data);

  const [Topic, setTopic] = topic;

  const [ShowContentTools, setShowContentTools] = contentToolsVisiblity;

  const editorRef = useRef(null);

  useEffect(() => {
    setTopic(blog_topic);
  }, []);

  console.log({ blog_topic, blog_name, from });
  return (
    <Editor
      onInit={(evt, editor) => editorRef.current = editor}
      selector="textarea"
      id="texteditor"
      skin="snow"
      content_css="./content-editor.css"
      initialValue={isLoading ? 'fetching Contents' : editorValue}
      init={{
        skin: 'borderless',
        height: '100%',
        width: '100%',
        menubar: false,
        icons: 'material',
        toolbar: 'homeButton downloadButton customInsertButton templatesButton publishdButton my_button',
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        plugins: ['quickbars'],
        quickbars_selection_toolbar: 'bold italic underline | templatesButton writeMeButton',

        setup(editor) {
          editor.ui.registry.addMenuButton('my_button', {
            text: 'My button menu',
            icon: 'gamma',
            fetch: (callback) => {
              const items = [
                {
                  type: 'checkbox',
                  text: 'Menu item 1',
                  onAction: () => {
                    editor.insertContent('&nbsp;<strong>You clicked menu item 1!</strong>');
                  },
                },
                {
                  type: 'menuitem',
                  text: 'Menu item 2',
                  icon: 'user',
                  onAction: () => {
                    editor.insertContent('&nbsp;<em>You clicked menu item 2!</em>');
                  },
                },
              ];
              callback(items);
            },
          });
          editor.ui.registry.addButton('homeButton', {
            icon: 'home',
            tooltip: 'Home',
            enabled: false,
            onAction: () => console.log('Home'),
          });

          editor.ui.registry.addButton('downloadButton', {
            icon: 'save',
            tooltip: 'Download',
            enabled: false,
            onAction: () => console.log('Download'),

          });

          editor.ui.registry.addButton('templatesButton', {
            text: 'Templates',
            tooltip: 'Templates',
            enabled: false,
            onAction: () => console.log('Templates'),

          });

          editor.ui.registry.addButton('publishdButton', {
            text: 'Publish',
            icon: 'browse',
            tooltip: 'Publish',
            enabled: false,
            onAction: () => console.log('Publish'),

          });

          editor.ui.registry.addButton('writeMeButton', {
            text: 'Write Me Athena',
            tooltip: 'Write me Athena',
            icon: 'edit-block',
            onAction() {
              console.log('dance');
            },
          });

          editor.ui.registry.addButton('CompleteAthena', {
            text: 'Ask Me Athena',
            icon: 'ordered-list',
            tooltip: 'Ask me Athena',
            onAction() {

            },
          });

          editor.ui.registry.addButton('customInsertButton', {
            text: 'Content Tools',
            onAction: () => setShowContentTools((ShowContentTools) => !ShowContentTools),
          });
        },
      }}

    />

  );
}

export default ContentEditor;
