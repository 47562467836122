/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import axios from 'axios';

const PdfDownload = (data, category) => {
  const title = category;
  let htmlStr = `
  <title style={display:none}>${title}</title><style>
  table{
   font-size:9px
  }
  table{margin:auto} h2{margin-bottom:10px}</style><h2>${category}</h2><table>
  <tr><th>Question</th><th>Answer</th></tr>`;

  Object.keys(data).map((key) => {
    htmlStr += `<tr><td>${key}</td><td>${data[key]}</td></tr>`;
  });
  console.log(htmlStr);
  const pdf = new window.jsPDF();
  const specialElementHandlers = {
    '#editor': function (element, renderer) {
      return true;
    },
  };
  pdf.setFontSize(2);
  pdf.fromHTML(htmlStr, 30, 15, {
    elementHandlers: specialElementHandlers,
  });
  pdf.save(`${title}.pdf`);
};

export const exportAllAsPdf = async () => {
  const { data } = await getAllData();
  let htmlStr = '';
  const pagebrek = '<div style=page-break-before:always align="center"><span style="visibility: hidden;">-</span></div>';
  data.map((sub, index) => {
    let head = 'h2';
    console.log(sub.section.length);
    if (sub.section.length > 40) {
      head = 'h5';
    }
    htmlStr += `
    <style>
    table{
     font-size:9px
    }
    table{margin:auto} h3{margin-bottom:10px}</style><table>
    <${head}>${sub.section}</${head}>
    <tr><th>Question</th><th>Answer</th></tr>`;
    Object.keys(sub.qa).map((key) => {
      htmlStr += `<tr><td>${sub.qa[key].question}</td><td>${sub.qa[key].answer}</td></tr>`;
    });
    htmlStr += '</table>';
    console.log(data.length !== index + 1, data.length, index + 1);
    if (data.length !== index + 1) htmlStr += pagebrek;
  });
  console.log(htmlStr);
  const pdf = new window.jsPDF();
  const specialElementHandlers = {
    '#editor': function (element, renderer) {
      return true;
    },
  };
  pdf.fromHTML(htmlStr, 30, 15, {
    elementHandlers: specialElementHandlers,
  });
  pdf.save(`${localStorage.getItem('category')}.pdf`);
};

const loadScript = function (src) {
  const tag = document.createElement('script');
  tag.async = false;
  tag.src = src;
  const body = document.getElementsByTagName('body')[0];
  body.appendChild(tag);
};

export const loadPdfJS = () => {
  loadScript(
    'https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.3.2/jspdf.debug.js',
  );
};

const getAllData = async () => {
  const url = `https://api.crawlq.ai/Market-Research/load/questions/all?username=${localStorage.getItem('username')}&name=${localStorage.getItem('name')}&section=${localStorage.getItem(
    'category',
  )}`;
  const { data } = await axios.get(url);
  return data;
};

export default PdfDownload;
