import axios from 'axios';

const RegisterUserRequest = async (name, email, password) => {
  const body = {
    name,
    email,
    password,
  };

  try {
    const result = await axios.post('https://apis.crawlq.ai/register', body);

    console.log(result);

    if (result.data.statusCode === '500') {
      if (result.data.message.slice(-7) === 'exists.') {
        return ({
          error: true,
          group_name: null,
          message: 'Account exists with current email address',
          success: false,
        });
      }
    }

    return ({
      error: false,
      group_name: result.data.response.group_name,
      message: 'Sign Up successfull ',
      success: true,
    });
  } catch (err) {
    console.log(err);
    return ({
      error: true,
      message: 'Check Internet Connectivity',
      success: false,
    });
  }
};

export default RegisterUserRequest;
