/* eslint-disable max-len */
import { useQuery } from 'react-query';
import createInstance from 'Config/AxiosConfig';
import { getReduxUserState, getReduxWorkspaceState } from 'utils/helpers/redux-selectors';

const getMarketInsightsGlobal = async () => {
  const instance = await createInstance();

  const { email } = getReduxUserState();
  const { name } = getReduxWorkspaceState();

  const payload = {
    username: email,
    name,
  };

  const { data } = await instance.post('insights-global', payload);

  console.log(data);

  if (data?.statusCode === '200' && data?.status === 'success') return data?.data?.insights;

  throw new Error('Unable to fetch Global Market Insights Data');
};

// Keep Query Value as File Name to maintain consistency and uniqueness in query index
const QUERY_KEY = 'get-market-insights-global';

// Query Config Options
const queryOptions = {
  staleTime: 60000,
  cacheTime: 90000,

};

// QUERY
export const useGetMarketInsightsGlobal = () =>
  useQuery(QUERY_KEY, getMarketInsightsGlobal, queryOptions);
