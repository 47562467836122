/* eslint-disable react/no-unescaped-entities */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import CustomTooltip from 'Component/Shared/CustomTooltip';
import React from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import option1img from 'assets/landingpage-imgs/idea.svg';
import option2img from 'assets/landingpage-imgs/target.svg';
import {
  BannerComponent,
  BannerContent,
  BannerImage,
  BannerWrapper,
  DescriptionStep,
  HeadingStep,
  NumberBullet,
  OptionComponent,
  StepsWrapper,
} from './components/styles';
import DividerBar from './components/DividerBar';

function StepsFornew() {
  return (
    <StepsWrapper>
      <DividerBar />
      <BannerWrapper>
        <BannerComponent>
          <NumberBullet>2</NumberBullet>
          <BannerImage src={option1img} />
          <BannerContent>
            <HeadingStep>Create Your Own Unique Audience Persona</HeadingStep>
            <CustomTooltip
              placement="top"
              arrow
              title={`Enter your persona workspace name, Company Name, Domain Name,
                  Your Initial Main Topic of Interest to your Persona and Say
                  roughly who you want to target.`}
            >
              <InfoOutlinedIcon color="grey" fontSize="small" />
            </CustomTooltip>
          </BannerContent>
        </BannerComponent>
        <BannerComponent>
          <OptionComponent>
            <span>Option</span>
            <NumberBullet>3</NumberBullet>
          </OptionComponent>
          <BannerImage src={option2img} />
          <BannerContent>
            <HeadingStep>
              You want to sit back and relax while we pick up your "Done For You
              Content Uber"!
            </HeadingStep>
            <CustomTooltip
              placement="top"
              arrow
              title={'You can request your "Done For You Content Uber" with CrawlQ AI Research and Blogging-as-a-Service. You can also register your side gigs as Creator to help others. For more information visit this page. https://crawlq.ai/blogging-with-ai/'}
            >
              <InfoOutlinedIcon color="grey" fontSize="small" />
            </CustomTooltip>
            {/*

             Just click the button Done For You, enter few details and our team
              will start filling the fuel tank and get you on the road to
              discover your next destination.
            */}
          </BannerContent>
        </BannerComponent>
      </BannerWrapper>
    </StepsWrapper>
  );
}

export default StepsFornew;
