/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useMutateFetchAnswerForQuestion } from 'queries/content/content-tools/question-answer';
import { useContentContext } from 'context/content/ContentContext';
import { AnswerLoading } from 'components/skeleton/content/content-tools/question/AnswerLoading';
import { QuestionIcon, AnswerIcon } from '../styles';
import styles from './questions.module.css';

function QuestionAnswer({ question }) {
  const { topic } = useContentContext();

  const [Topic] = topic;
  const { data: answer, mutateAsync, isLoading: isFetchingAnswer } = useMutateFetchAnswerForQuestion();

  const [Answer, setAnswer] = useState('');
  const handleFetchAnswer = () => {
    mutateAsync({ Topic, question }).then((data) => setAnswer(data));
  };

  return (
    <div className={styles.question_container}>
      <div className={styles.question}>
        <QuestionIcon /> {question}
        <span
          className={styles.generate_answer}
          onClick={handleFetchAnswer}
        >Generate Answer
        </span>
      </div>
      { isFetchingAnswer && <AnswerLoading /> }
      { Answer && <div className={styles.answer}><AnswerIcon />{Answer}</div> }
    </div>
  );
}

export default QuestionAnswer;
