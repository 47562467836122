import { useQuery } from 'react-query';
import createInstance from 'Config/AxiosConfig';
import { store } from 'redux/store';

const getSavedConfigurations = async () => {
  const state = store.getState();
  const username = state.user?.username;

  const instance = await createInstance();

  const { data } = await instance.get(
    `get-recent-configurations?username=${username}`,
  );
  if (data.statusCode === '200') {
    return data.response;
  }
  throw new Error('Failed to Fetch Documents List');
};

export const GET_SAVED_CONFIGURATIONS_KEY = 'get-saved-configurations';

// Query Config Options
const queryOptions = {
  staleTime: 90000,
};

export const useGetSavedConfigurations = () => {
  const queryKey = [GET_SAVED_CONFIGURATIONS_KEY];
  return useQuery(queryKey, getSavedConfigurations, queryOptions);
};
