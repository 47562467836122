/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { ShareIcon, LoaderTailSpin } from 'components/common';
import { showNotification } from 'libs/notification';
import { shareTemplateWithEmail } from '../../template.service';
import styles from '../action.module.css';

function ShareTemplate({ templateId, closeModal }) {
  const [isLoading, setIsLoading] = useState(false);
  const [Email, setEmail] = useState('');

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleShare = async () => {
    setIsLoading(true);
    const isShared = await shareTemplateWithEmail(templateId, Email);
    if (isShared) {
      showNotification('success', 'Template Shared successfully');
      setIsLoading(false);
      return;
    }
    setIsLoading(false);
    showNotification('error', 'Error in sharing Template');
  };

  return (
    <div className={styles.container}>
      <div className={styles.header_container}>
        <ShareIcon width="32px" height="36px" />
        <div className={styles.header}>Share Template</div>
      </div>
      <div className={styles.content}>
        <input
          onChange={handleChange}
          className={styles.input}
          value={Email}
          placeholder="Enter Email Id CrawlQ User"
        />

        <div className={styles.buttons_container}>
          <div className={styles.button_wrapper}>
            {isLoading ? (
              <LoaderTailSpin width={20} />
            ) : (
              <button
                disabled={!(Email.length > 0)}
                className={styles.submit}
                type="button"
                onClick={handleShare}
              >
                Share
              </button>
            )}
          </div>
          <div className={styles.button_wrapper}>
            <button
              className={styles.cancel}
              type="button"
              onClick={() => closeModal()}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShareTemplate;
