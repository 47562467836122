import { useNavigate } from 'react-router-dom';
import { Divider } from 'components/common/divider';
import { useSelector } from 'react-redux';
import { ScaffoldCard } from 'components/common/scaffold-card';
import Avatar from '@mui/material/Avatar';
import BoltIcon from '@mui/icons-material/Bolt';
import PaidIcon from '@mui/icons-material/Paid';
import {
  CampaignListIcon,
  PersonIcon,
  GroupNameIcon,
} from 'components/common/common-icons';
import { ROUTES } from 'constants/routes';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { useDashboardContext } from 'context/dashboard/DashboardContext';
import styles from './profile-card.module.css';
/* eslint-disable react/react-in-jsx-scope */

function ProfileCard() {
  const navigate = useNavigate();
  const userInfo = useSelector((state) => state?.user);

  const { creditsInfo } = useDashboardContext();

  const { credits, total_credits } = creditsInfo ?? {};
  const {
    name,
    email,
    plan,
    admin,
    workspace_count,
    member_count,
    member_limit,
    group,
    workspace_limit,
  } = userInfo;

  return (
    <ScaffoldCard className={styles.profilecard_container}>
      <Avatar sx={{ width: 72, height: 72 }}>
        {userInfo && name.charAt(0)}
      </Avatar>
      <span className={styles.user_fullname}>{userInfo && name}</span>
      <Divider />

      {/* Plan */}
      <div className={styles.account_info_container}>
        <span className={styles.info_type}>
          <BoltIcon />
          <span className={styles.info_type_value}>Plan</span>
        </span>
        <span className={styles.info_value}>{userInfo && plan}</span>
        <button
          className={styles.upgrade_button}
          type="button"
          onClick={() =>
            navigate(ROUTES.PRICING, {
              state: {
                email,
                name,
                old_user: true,
                origin: 'dashboard',
              },
            })
          }
        >
          Manage Plan
        </button>
      </div>

      {/* Admin or Member */}
      <div className={styles.account_info_container}>
        <span className={styles.info_type}>
          <PersonIcon />{' '}
          <span className={styles.info_type_value}>Privilege</span>
        </span>
        <span className={styles.info_value}>
          {userInfo && admin === 'True' ? 'Admin' : 'Member'}
        </span>
      </div>

      {/* Group Name */}
      {group && (
        <div className={styles.account_info_container}>
          <span className={styles.info_type}>
            <GroupNameIcon />
            <span className={styles.info_type_value}>Group</span>
          </span>
          <span className={styles.info_value}>{group}</span>
        </div>
      )}

      {/* Credits Info */}
      <div className={styles.account_info_container}>
        <span className={styles.info_type}>
          <PaidIcon />
          <span className={styles.info_type_value}>Credits</span>
        </span>
        <div className={styles.info_value}>
          <span className={styles.credits}>
            {credits} / {total_credits}
          </span>
        </div>
      </div>

      {/* Campaigns Count */}
      <div className={styles.account_info_container}>
        <span className={styles.info_type}>
          <CampaignListIcon />
          <span className={styles.info_type_value}>Campaigns</span>
        </span>
        <div className={styles.info_value}>
          {workspace_count ?? 0} / {workspace_limit}
        </div>
      </div>

      {/* Members Count */}
      <div className={styles.account_info_container}>
        <span className={styles.info_type}>
          <SupervisorAccountIcon />
          <span className={styles.info_type_value}>Members</span>
        </span>
        <div className={styles.info_value}>
          {member_count} / {member_limit}
        </div>
      </div>
    </ScaffoldCard>
  );
}

export default ProfileCard;
