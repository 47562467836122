/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable prefer-template */

export const getHeaders = (bigQueryData) => {
  // Fetch Headers from first response by default
  const headers = Object.keys(bigQueryData[0]);

  return headers;
};

export const getFormmattedQueryResponse = (bigQueryData) => {
  const formattedResponse = [];
  const res = bigQueryData?.forEach((queryData) => {
    const subResponse = [];
    for (const [_, value] of Object.entries(queryData)) {
      subResponse.push({ value });
    }
    formattedResponse.push(subResponse);
  });

  return formattedResponse;
};
export const formatBigQueryJsonForCSV = (query, bigQueryData) => {
  const exportedFileName = `CrawlQ-${query}.csv` || 'export.csv';

  const structuredResponse = [];

  // Fetch Headers from first response by default
  const headers = Object.keys(bigQueryData[0]);

  // setting it as headers
  structuredResponse.push(headers);

  // Looping through original query response and pushing all values into strcutrued response
  bigQueryData.forEach((queryObject) =>
    structuredResponse.push(Object.values(queryObject)),
  );

  let csvContent = '';
  structuredResponse.forEach((queryResponseInfo) => {
    csvContent += queryResponseInfo.join(',') + '\n';
  });

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8,' });
  const objUrl = URL.createObjectURL(blob);

  console.log(blob);
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFileName);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      link.setAttribute('href', objUrl);
      link.setAttribute('download', exportedFileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
