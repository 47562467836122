import React from 'react';
import { Outlet } from 'react-router-dom';
import AppHeaderWrapper from 'components/common/AppHeader/AppHeaderWrapper';

import { HeaderCard } from 'components/common/HeaderCard/HeaderCard';
import styles from './digital-assets.module.css';

const DigitalAssets = () => (
  <AppHeaderWrapper className={styles.wrapper}>
    <HeaderCard
      className={styles.header_container}
      title="Digital Assets"
      description=" Valuable assets for disseminating information, engaging audiences, and building brand authority in the ever-evolving landscape of content consumption."
    />

    <div className={styles.outlet_container}>
      <Outlet />
    </div>
  </AppHeaderWrapper>
);

export default DigitalAssets;
