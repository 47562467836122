import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import FeedIcon from '@mui/icons-material/Feed';
import HelpIcon from '@mui/icons-material/Help';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import styled from 'styled-components';

export const CopyIcon = styled(ContentCopyIcon)`
    width:14px !important;
    height:14px !important;
    color: var(--primary-color);
    cursor:pointer;
`;

export const MarketCategoryIcon = styled(BubbleChartIcon)`
    width:18px !important;
    height:18px !important;
    color: var(--primary-color);
    cursor:pointer;
`;

export const HeadlineIcon = styled(FeedIcon)`

width:18px !important;
    height:18px !important;
    color: var(--primary-color);

`;

export const QuestionIcon = styled(HelpIcon)`
    width: 18px !important;
    height: 18px !important;
    color: var(--primary-color);

`;

export const AnswerIcon = styled(KeyboardDoubleArrowRightIcon)`

width: 18px !important;
    height: 18px !important;
    color: var(--primary-color);

`;
