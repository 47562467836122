/* eslint-disable no-console */
/* eslint-disable consistent-return */
/* eslint-disable camelcase */
/* eslint-disable import/extensions */
import createInstance from '../../../../../../Config/AxiosConfig.js';

const deleteWorkspaceRequest = async (workspace_name) => {
  try {
    const body = {
      name: workspace_name,
    };
    const instance = await createInstance();

    const result = await instance.post('delete-project', body);

    if (result.data.statusCode === '200') {
      return ({ result: true });
    }
  } catch (error) {
    console.log('Delete Workspace API error');
    return ({ result: false });
  }
};

export default deleteWorkspaceRequest;
