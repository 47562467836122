/* eslint-disable consistent-return */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import ButtonNext from 'Component/Shared/ButtonNext';
import { CreditManage } from 'Component/Shared/CreditManager';
import CustomTooltip from 'Component/Shared/CustomTooltip';
import { ONBOARDING_FILLWITH_AI_CREDITS } from 'Component/Shared/featuresCreditsCost';
import LoaderSpin, { LoaderTailSpin } from 'Component/Shared/Loader';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import '../onBoarding.css';
import { onBoardingFillWithAILevel2, saveAnswersLevel2 } from './Level2Logic';
import questions from './onboardingQuestionsLevel2.json';
import Progressbar from './Progressbar';

function LevelTwoPopUpBody({ answers }) {
  const navigate = useNavigate();
  const athena_sources = useSelector(
    (state) => state.user.athena_sources?.train_option,
  );

  const [queList, setQueList] = useState([]);
  const [isErrorInSubmission, setIsErrorInSubmission] = useState(false);
  const [savingError, setSavingError] = useState('');
  const [isSavingCurrentStatus, setIsSavingCurrentStatus] = useState(false);
  const [savedSuccessfully, setSavedSuccessfully] = useState(false);
  const [showProgressbar, setShowProgressbar] = useState(false);
  const [apiFullfilledSucessfully, setApiFullfilledSucessfully] = useState(
    false,
  );
  const [filterOptions, setFilterOptions] = useState([
    { key: 'mrr', value: 'Market Research', checked: true },
    { key: 'mrr_insight', value: 'Market Insights', checked: true },
    { key: 'script', value: 'Sales Copywriting', checked: true },
    { key: 'serp', value: 'Google', checked: true },
    { key: 'quora', value: 'Quora', checked: true },
    { key: 'reddit', value: 'Reddit', checked: true },
    { key: 'website', value: 'URL', checked: true },
  ]);
  const [isCreditsOver, setIsCreditsOver] = useState(false);

  const checkEnoughCharacter = (charactersLimit, answer) => {
    // const percentage = (100 * answer.length) / charactersLimit
    const charDiff = charactersLimit - answer.length;

    if (charDiff > -20 && charDiff <= 20) {
      return 2;
    }
    if (charDiff > -30 && charDiff <= 30) {
      return 1;
    }
    return 0;
  };
  const handleChange = async (e, i) => {
    if (showProgressbar) return;
    const userInput = e.target.value;
    setSavedSuccessfully(false);
    setIsErrorInSubmission(false);
    setSavingError('');
    setQueList((prev) => {
      prev[i].answer = userInput;
      if (prev[i].example) {
        const isValid = checkEnoughCharacter(
          prev[i].example.length - 9, // substracting 9 because of "Example : "
          userInput.trim(),
        );
        prev[i].isValid = isValid;
        return [...prev];
      }
      if (userInput !== '') {
        prev[i].isValid = 2;
      } else {
        prev[i].isValid = 0;
      }
      return [...prev];
    });
  };
  const checkIsAllAnswerField = () => {
    const questionsList = [...queList];
    let isAllAnswerFilled = true;
    for (const i in questionsList) {
      if (questionsList[i].answer.trim() === '') {
        questionsList[i].error = true;
        isAllAnswerFilled = false;
      } else {
        questionsList[i].error = false;
      }
    }
    setQueList([...questionsList]);
    return isAllAnswerFilled;
  };

  const handleSaveLevel2 = async () => {
    setSavingError('');
    const isAllAnswerFilled = checkIsAllAnswerField();
    if (!isAllAnswerFilled) {
      setIsErrorInSubmission(true);
      return;
    }
    setIsSavingCurrentStatus(true);
    const { error } = await saveAnswersLevel2(
      queList[0].answer,
      queList[1].answer,
    );
    setIsSavingCurrentStatus(false);
    if (!error) {
      setSavedSuccessfully(true);
    }
  };

  const handleSubmitLevel2 = async () => {
    setSavedSuccessfully(false);
    const isAllAnswerFilled = checkIsAllAnswerField();
    if (!isAllAnswerFilled) {
      setIsErrorInSubmission(true);
      return;
    }
    setShowProgressbar(true);
    const sources = filterOptions
      .map((option) => {
        if (
          option.checked
          && !!athena_sources
          && athena_sources.includes(option.key)
        ) return option.key;
      })
      .filter((option) => !!option);

    const { success } = await CreditManage(ONBOARDING_FILLWITH_AI_CREDITS, true);
    if (!success) {
      setIsCreditsOver(true);
      setApiFullfilledSucessfully(false);
      setShowProgressbar(false);
      return;
    }
    const response = await onBoardingFillWithAILevel2(
      queList[0].answer,
      queList[1].answer,
      sources,
    );
    if (!response || response.error) {
      setSavingError('Something went wrong! Please try again..');
    } else {
      setApiFullfilledSucessfully(true);
      CreditManage(ONBOARDING_FILLWITH_AI_CREDITS);
    }
    setShowProgressbar(false);
  };

  const handleSourceFilterSelect = (index) => {
    if (showProgressbar) return;
    const filters = [...filterOptions];
    filters[index].checked = !filters[index].checked;
    setFilterOptions([...filterOptions]);
  };

  function SourceFilterSelecter() {
    return (
      <div>
        <div className="question text-black d-flex">
          <span className="mx-1">Athena Sources</span>
          <CustomTooltip
            title="Athena will use the following sources on which it is trained to search and fill the answers."
            placement="top"
            arrow
          >
            <InfoOutlinedIcon color="grey" fontSize="small" />
          </CustomTooltip>
        </div>
        {athena_sources ? (
          <div className="onboarding-qna-source-filter">
            {filterOptions.map(
              (option, index) => athena_sources.includes(option.key) && (
              <span
                onClick={() => handleSourceFilterSelect(index)}
                key={index}
              >
                {option.checked ? (
                  <CheckCircleOutlineRoundedIcon />
                ) : (
                  <RadioButtonUncheckedRoundedIcon />
                )}
                {option.value}
              </span>
              ),
            )}
          </div>
        ) : (
          <div className="text-center">
            <b className="text-warning">
              No sources selected as Athena is not trained
            </b>
          </div>
        )}
      </div>
    );
  }

  const handleQuestionsAnswer = () => {
    setIsErrorInSubmission(false);
    setApiFullfilledSucessfully(false);
    setSavingError(false);
    setIsSavingCurrentStatus(false);
    if (!!answers && Object.keys(answers).length === 0) return;
    questions.map((que) => {
      que.answer = answers[que.que];
    });
    setQueList([...questions]);
  };

  useEffect(() => {
    if (answers) {
      console.log(answers);
      handleQuestionsAnswer();
    }
  }, [answers]);

  function CheckIconComponent({ title, className }) {
    return (
      <CustomTooltip placement="top" arrow title={title}>
        <div className={className}>
          <CheckCircleIcon
            style={{
              width: '20px',
              height: '20px',
              marginLeft: '5px',
              cursor: 'pointer',
            }}
          />
        </div>
      </CustomTooltip>
    );
  }
  return (
    <div>
      <div className="onBoarding_level2_header">
        <h3 className="text-center font-primary" style={{ flex: '1' }}>
          Onboarding: Middle of the funnel
        </h3>
        {/* <CloseRoundedIcon onClick={() => setShowModal(false)} /> */}
      </div>
      {queList.length > 0 ? (
        <div className="page-container">
          <div
            className="que-container"
            style={{ width: '100%', boxShadow: 'none' }}
          >
            {queList.map((qa, i) => (
              <div className="each-que-container" key={i}>
                <p className="question text-black">
                  {i + 1}
                  .
                  {qa.que}
                </p>
                <p className="helper">{qa.desc}</p>
                {qa.example && (
                  <p className="helper on_boarding-example">
                    <span>{qa.example}</span>
                  </p>
                )}
                <div className="onBoading_textarea_container">
                  <textarea
                    className="answer onboarding_answer"
                    placeholder=""
                    defaultValue={qa.answer}
                    onChange={(e) => handleChange(e, i)}
                  />

                  {qa.isValid === 0 && (
                    <CheckIconComponent
                      title="The input is too short or too long. It will badly affect the training of our AI. Try to increase/decrease the text."
                      className="text-danger"
                    />
                  )}
                  {qa.isValid === 1 && (
                    <CheckIconComponent
                      className="text-warning"
                      title="Good Going! For best output generation try to match with the length of the example text."
                    />
                  )}
                  {qa.isValid === 2 && (
                    <CheckIconComponent
                      className="text-success"
                      title="Perfect!"
                    />
                  )}
                </div>

                {qa.error && isErrorInSubmission && (
                  <small className="text-danger">
                    *This field is compulsory
                  </small>
                )}
              </div>
            ))}
            <SourceFilterSelecter />
            {showProgressbar ? (
              <Progressbar />
            ) : (
              <>
                {apiFullfilledSucessfully ? (
                  <div className="onboarding_api_fullfill_container">
                    <span>✅ Completed</span>
                    <ButtonNext
                      onClick={() => {
                        navigate('/MarketResearchCategory?level=2');
                      }}
                    />
                  </div>
                ) : (
                  <div
                    className="onboading_submit_container"
                    style={{ marginTop: '3.5rem' }}
                  >
                    {savingError !== '' && (
                      <span className="mr-2 text-danger">{savingError}</span>
                    )}
                    {savedSuccessfully && (
                      <span className="mr-2 text-success">
                        Saved Successfully
                      </span>
                    )}
                    {isErrorInSubmission && (
                      <span className="mr-2 text-danger">
                        *All fields are compulsory
                      </span>
                    )}
                    <div className="onBoading_save_submit_container">
                      {isSavingCurrentStatus ? (
                        <LoaderSpin />
                      ) : (
                        <button onClick={handleSaveLevel2} className="ml-2">
                          Save
                        </button>
                      )}
                      {isCreditsOver ? (
                        <ButtonNext className="mx-2" disable />
                      ) : (
                        <button onClick={handleSubmitLevel2} className="ml-2">
                          Get Started
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="page-container main-container">
          {/* Added Two classes in above div to keep Loder in center of page on both x and y axis */}
          <LoaderTailSpin />
        </div>
      )}
    </div>
  );
}

export default LevelTwoPopUpBody;
