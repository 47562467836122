/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable quotes */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/button-has-type */
/* eslint-disable no-return-assign */
/* eslint-disable no-use-before-define */

import { Button, Col, Form, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import AthenaLogo from 'assets/images/crawlQ-transparent-favicon.png';
import {
  LeftOutlined,
  LoadingOutlined,
  RightOutlined,
} from '@ant-design/icons';

const PreparingWorkspacesScreen = (props) => {
  const [onBoarding, setOnBoarding] = useState(false);

  return (
    <>
      {' '}
      <div
        style={{
          background: 'white',
        }}
      >
        <div
          style={{
            height: '10vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontWeight: 'bold',
            fontSize: '25px',
          }}
        ></div>

        <div
          style={{
            height: '70vh',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Form
              name="control-hooks"
              style={{ width: '85vw', color: 'white' }}
            >
              <Form.Item name="logo">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '25px',
                  }}
                >
                  <img
                    src={AthenaLogo}
                    alt="Athena Logo"
                    style={{
                      display: 'inline-block',
                      width: '45px',
                      // marginLeft: '10%',
                      marginBottom: '-20px',
                    }}
                  />
                </div>
              </Form.Item>
              <Form.Item>
                <div style={{}}>
                  {' '}
                  <div
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    <div
                      style={{
                        color: '#4B79FF',
                        fontSize: '25px',
                        fontWeight: 'bold',
                        marginBottom: '70px',
                      }}
                    >
                      Loading and Preparing ChatAthena <br />
                    </div>
                    <div>
                      <Spin size="large" />
                    </div>
                  </div>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
        <div
          style={{
            height: '10vh',
            // backgroundColor: 'green',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        ></div>
      </div>
    </>
  );
};

export default PreparingWorkspacesScreen;
