import { HEADERS } from 'constants/content/content-tools';
import { COUNTRIES_LIST } from 'constants/index';
import React, { useState } from 'react';
import { useContentContext } from 'context/content/ContentContext';
import SearchInput from 'components/common/SearchInput';
import { Divider } from 'components/common/divider';
import Market from './market/Market';
import Overview from './overview/Overview';
import Questions from './questions/Questions';
import News from './news/News';
import Topics from './topic/Topics';
import SERP from './serp/SERP';
import styles from './content-tools.module.css';

const SelectedTab = ({ header, val }) => {
  // Refer constant HEADERS to know order of the tabs
  switch (header) {
    case HEADERS[0]:
      return <Overview />;

    case HEADERS[1]:
      return <Market />;

    case HEADERS[2]:
      return <SERP />;

    case HEADERS[3]:
      return <Topics />;

    case HEADERS[4]:
      return <Questions />;

    case HEADERS[5]:
      return <News />;

    default:
      return <Overview />;
  }
};

function ContentTools() {
  const { country, topic } = useContentContext();

  const [Country, setCountry] = country;
  const [Topic, setTopic] = topic;
  const [UserQuery, setUserQuery] = useState();
  const [SelectedHeader, setSelectedHeader] = useState(HEADERS[0]);

  console.log(Topic);
  return (
    <div className={styles.wrapper}>

      <div className={styles.static_container}>

        <div className={styles.static_user_interaction_container}>
          <select
            className={styles.country_selector}
            value={Country}
            onChange={(event) => setCountry(event.target.value)}
          >
            { COUNTRIES_LIST.map((country) =>
              <option value={country?.alpha2Code}>{country?.name}</option>,
            )}

          </select>

          <SearchInput
            className={styles.search_container}
            value={UserQuery ?? Topic}
            onChange={(event) => setUserQuery(event.target.value)}
            placeholder="Main Topic of Interest"
            onClickSearch={() => setTopic(UserQuery)}
          />

        </div>

        <div className={styles.headers_container}>
          {HEADERS.map((header) => (
            <span
              key={header}
              className={SelectedHeader === header ? styles.active_header : styles.header}
              onClick={() => setSelectedHeader(header)}
            >{header}
            </span>
          ))}
        </div>

      </div>

      <Divider />

      <div className={styles.content_container}>
        <SelectedTab header={SelectedHeader} val={Topic} />
      </div>
    </div>
  );
}

export default ContentTools;
