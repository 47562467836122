import Modal from 'react-modal';
import React, { useState } from 'react';
import { FivetranTab } from 'libs/chat-athena-constants';
import TabSwitcher from 'components/common/TabSwitcher/TabSwitcher';
import { Colors } from 'libs/colors';
import { ListFiveTranApps } from './ListFiveTranApps';
import { ListConnectedApps } from './ListConnectedApps';

const customStyles = {
  height: '700px',
  top: '50%',
  left: '50%',
  right: 'auto',

  bottom: 'auto',
  marginRight: '-50%',
  transform: 'translate(-50%, -50%)',
  width: '95vw',
  maxWidth: '1200px',
  overflow: 'hidden',
  maxHeight: '80vh',
};

export const ALL_APPS_OPEN_KEY = 'All Apps';
export const CONNECTED_APPS_KEY = 'Connected Apps';

const FiveTranModal = ({ visible, onClose, selectedTab }) => {
  const [tab, setTab] = useState(FivetranTab[selectedTab ?? ALL_APPS_OPEN_KEY]);

  return (
    <Modal
      isOpen={visible}
      onRequestClose={() => onClose()}
      contentLabel="Search Prompts Modal"
      style={{
        content: {
          ...customStyles,
          backgroundColor: Colors.midnightSlate,
          color: Colors.white,
        },
      }}
    >
      <h2 style={{ textAlign: 'center' }}>Manage Integrations</h2>
      <TabSwitcher
        tabs={Object.keys(FivetranTab)}
        setSwitcher={setTab}
        activeTab={tab}
      />

      {tab === 1 ? <ListConnectedApps /> : <ListFiveTranApps setTab={setTab} />}
    </Modal>
  );
};

export default FiveTranModal;
