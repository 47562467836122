/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable new-cap */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-unknown-property */
/* eslint-disable eqeqeq */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-dupe-keys */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-constant-condition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-danger */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import React from 'react';
import LoaderSpin from './Loader';
import { ButtonPrimary } from './GlobalStyles';

const style = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#2a9df5',
  border: 'none',
  color: '#fff',
  fontWeight: '500',
  borderRadius: '7px',
  position: 'relative',
  cursor: 'pointer',
  padding: '7px',
};

const CreditOverStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'rgb(255 0 0 / 21%)',
  border: 'none',
  color: 'rgb(224 2 2 / 81%)',
  fontWeight: '500',
  borderRadius: '7px',
  position: 'relative',
  cursor: 'pointer',
  padding: '7px',
};

const ButtonStyle = {
  borderRadius: '4px',
  backgroundColor: 'rgb(42, 157, 245)',
  padding: '4px 12px',
  color: 'white',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
};

function ButtonNext({
  onClick,
  buttonName,
  className,
  disable,
  showLoader,
}) {
  if (!buttonName) {
    buttonName = 'Next';
  }
  return (
    // eslint-disable-next-line react/button-has-type
    <button
      disabled={disable}
      className={className}
      onClick={onClick}
      style={disable ? CreditOverStyle : style}
    >
      <span>{disable ? 'Credits Over' : buttonName}</span>
      {!showLoader ? (
        <>
          {!disable && (
            <img
              src="/img/circular-arrow-right.svg"
              className="img-fluid ml-2"
              width="18"
            />
          )}
        </>
      ) : (
        <LoaderSpin height={25} color="#fff" />
      )}
    </button>
  );
}

export function ButtonPrevious({ onClick, buttonName }) {
  if (!buttonName) {
    buttonName = 'Previous';
  }
  return (
    <button onClick={onClick} style={style}>
      <img src="/img/left-arrow.svg" className="img-fluid mr-2" width="18" />
      <span>{buttonName}</span>
    </button>
  );
}

export function Button({ onClick, name = 'Get Started', showLoader }) {
  return (
    <ButtonPrimary onClick={onClick}>
      {name}
      {showLoader && <LoaderSpin height={25} color="#fff" width={35} />}
    </ButtonPrimary>
  );
}

export default ButtonNext;
