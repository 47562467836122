/* eslint-disable no-plusplus */
/* eslint-disable no-console */
/* eslint-disable import/no-unresolved */
import axios from 'axios';
import { baseAPIsUrl } from 'Config/Api';
import createInstance from 'Config/AxiosConfig';

export const addTeamMember = async (name, email, scope) => {
  try {
    const body = {
      Name: name,
      email,
      scope,
    };

    const instance = await createInstance();

    const res = await instance.post('user-group-add', body);

    if (res.data.statusCode === '200') {
      return {
        error: false,
      };
    }
    return {
      error: true,
      message: res.data.body,
    };
  } catch {
    console.log('Error in Adding TeamMember');
    return {
      error: true,
      data: null,
    };
  }
};

export const removeTeamMember = async (user_email) => {
  try {
    const body = {
      user_email,
    };

    const instance = await createInstance();
    const res = await instance.post('remove-user-from-group', body);
    return res.data;
  } catch {
    console.log('Error in removing Team Member');
    return {
      error: true,
      data: null,
    };
  }
};

export const getMemberslist = async () => {
  const { data } = await axios.get(
    `${baseAPIsUrl}/dashboard-teammembers?username=${localStorage.getItem(
      'username',
    )}`,
  );
  const members = data.data;
  let modifiedMembers = [];
  for (let i = 0; i < members.length; i++) {
    const feilds = {
      name: '',
      scope: 'Team Member',
      email: '',
    };
    for (let j = 0; j < members[i].Attributes.length; j++) {
      if (members[i].Attributes[j].Name === 'email') {
        feilds.email = members[i].Attributes[j].Value;
      }
      if (members[i].Attributes[j].Name === 'custom:scope') {
        if (members[i].Attributes[j].Value === 'agency_client') {
          feilds.scope = 'Client';
        }
      }
      if (members[i].Attributes[j].Name === 'name') {
        feilds.name = members[i].Attributes[j].Value;
      }
      if (members[i].Attributes[j].Name === 'custom:is_admin') {
        if (members[i].Attributes[j].Value === 'True') {
          feilds.scope = 'Admin';
        }
      }
    }
    modifiedMembers = [...modifiedMembers, feilds];
  }
  modifiedMembers.sort((a, b) => {
    if (a.scope < b.scope) return -1;
    if (a.scope > b.scope) return 1;
    return 0;
  });
  return modifiedMembers;
};
