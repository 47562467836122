import CustomTooltip from 'Component/Shared/CustomTooltip';
import Flyout from 'Component/Shared/Flyout/Flyout';
import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ExtensionIcon from '@mui/icons-material/Extension';
import AddAppCreds from './components/AddAppCreds';
import styles from './styles.module.css';
import AllApps from './components/AllAppsList';

const AppIntegration = ({ showAppIntegration, setShowAppIntegration }) => {
  const [tabId, setTabId] = useState(0);

  const handleChange = (_event, newTabId) => {
    setTabId(newTabId);
  };

  const tabColor = tabId;

  return (
    <div>
      <CustomTooltip
        title="App Integration"
        arrow
        placement="top"
        onClick={() => setShowAppIntegration((prev) => !prev)}
      >
        <ExtensionIcon />
      </CustomTooltip>

      <Flyout
        open={showAppIntegration}
        onClose={() => setShowAppIntegration(false)}
        title={'App Integration'}
        width={'max(25vw, 320px)'}
        customStyles={{
          backgroundColor: '#343541',
          color: '#ffffff',
        }}
      >
        <Tabs value={tabId} onChange={handleChange}>
          <Tab className={styles.tab} style={{ color: tabId === 1 ? 'white' : null }} label="All Apps" />
          <Tab className={styles.tab} style={{ color: tabId === 0 ? 'white' : null }} label="New App" />
        </Tabs>
        {tabId === 0 && <AllApps setTabId={setTabId} />}
        {tabId === 1 && <AddAppCreds setTabId={setTabId} />}
      </Flyout>
    </div>
  );
};
export default AppIntegration;
