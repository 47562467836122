/* eslint-disable no-plusplus */
/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable eqeqeq */
/* eslint-disable no-console */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */

import axios from 'axios';
import { CreditManage } from 'Component/Shared/CreditManager';
import createInstance from 'Config/AxiosConfig.js';
import { ATHENA_WRITE_ME_COST } from 'Component/Shared/featuresCreditsCost';
import { baseAPIsUrl } from 'Config/Api';
import countriesList from '../../../Shared/countries.json';
import { getTranslatedText } from '../../../Shared/TranshelperStatic';
import { getTranslatedTextArrayEnglish } from '../../MarketReasearch/MarketResearchAndResonance/MarketResearchCategory/fILLAiLangTrans';

const getSavedDocs = async () => {
  try {
    const body = {
      username: localStorage.getItem('username'),
      name: localStorage.getItem('name'),
      source: '',
    };

    const instance = await createInstance();
    const { data } = await instance.post('ai-writer-select', body);

    const result = data.blog;
    const docs = [];

    for (let i = 0; i < result.length; i++) {
      const obj = {
        blog_name: result[i].blog,
        blog_author: result[i].fullname,
        blog_readtime: result[i].reading_time,
        blog_script: result[i].script_name,
      };

      docs.push(obj);
    }
    return { error: false, docs };
  } catch (ex) {
    console.log('Error in getting SavedDoc', ex);
    return { error: true, docs: [] };
  }
};

export const getListOfCountries = async () => {
  try {
    const data = countriesList.map((country) => {
      const { name, alpha2Code } = country;
      return { name, alpha2Code };
    });
    return data;
  } catch (ex) {
    console.log('Error in getting Country List', ex);
    return [];
  }
};

// NOT IN USE - MOVED TO HELPER IN PUBLISHED ARTICLES
export const deleteDocument = async (docName, isFromSales = false) => {
  try {
    let data = {
      username: localStorage.getItem('username'),
      name: localStorage.getItem('name'),
      blog_name: docName,
      source: '',
    };
    if (isFromSales) {
      data = { ...data, source: 'Sales Copywriter' };
    }
    const res = await axios.post(
      'https://apis.crawlq.ai/ai-writer-delete',
      data,
    );
    if (res.data.statusCode == 200) return { error: false };
    return { error: true };
  } catch (ex) {
    console.log('Error in deleting Docs', ex);
    return { error: true };
  }
};

export const getQuestions = async () => {
  try {
    const { data } = await axios.get(
      `${baseAPIsUrl}/ai-load-script/?username=${localStorage.getItem(
        'username',
      )}&name=${localStorage.getItem('project_name')}&script_id=65`,
    );
    return { error: false, data: data.script.questions };
  } catch (ex) {
    console.log('Error in Loading Questions', ex);
    return { error: true, data: null };
  }
};

export const fillWithExample = (queAnswers) => {
  queAnswers.forEach((queAns) => {
    queAns.answer = queAns.example_mapping;
  });
  return queAnswers;
};

// NOT IN USE FOR OPENING ARTICLES IN FLYOUT NOW
export const openSavedDocument = async (docName, source) => {
  try {
    const body = {
      username: localStorage.getItem('username'),
      name: localStorage.getItem('name'),
      blog_name: docName,
      source,
    };

    // if (window.location.pathname === '/sales-ai-content-writer/app') {
    //   body = { ...body, source: 'Sales Copywriter' }
    // }
    const { data } = await axios.post(
      'https://apis.crawlq.ai/ai-writer-load',
      body,
    );

    return {
      data: {
        blogId: data.blog.id,
        goolgeQuery: data.blog.topic,
        query: docName,
        output: data.blog.text,
        lang: 'US',
        save: true,
      },
      error: false,
    };
  } catch (ex) {
    console.log('Error in Opening Document', ex);
    return {
      error: true,
      data: null,
    };
  }
};

export const createNewDocument = async (body) => {
  try {
    body = {
      ...body,
      username: localStorage.getItem('username'),
      name: localStorage.getItem('name'),
    };
    // const { data } = await axios.post(`${baseAPIsUrl}/generate-outline`, body)

    const { data } = await axios.post(
      'https://apis.crawlq.ai/generate-outline',
      body,
    );
    if (data.statusCode === '200') {
      return {
        error: false,
        data: data.data,
      };
    }
    return {
      error: true,
      data: null,
    };
  } catch (ex) {
    console.log('Error in Creating New Doc', ex);
    return {
      error: true,
      data: null,
    };
  }
};

export const getLimitOfCreatingNewDocs = () => {
  let planStructure = localStorage.getItem('planStructure');
  if (!planStructure) return 10;
  planStructure = JSON.parse(atob(planStructure));
  let currentUserPlan = localStorage.getItem('userPlan');
  currentUserPlan = currentUserPlan.replace(/^"(.+(?="$))"$/, '$1');
  return planStructure[currentUserPlan].documents;
};
export const socialMeAthenaHelper = async (inputVal) => {
  const { success } = await CreditManage(ATHENA_WRITE_ME_COST, true);

  if (success) {
    try {
      const selected_sources = JSON.parse(localStorage.getItem('sources'));

      const translatedTexts = await getTranslatedTextArrayEnglish([
        inputVal.replaceAll('\n', '').replaceAll(/<\/?[^>]+(>|$)/g, ''),
        localStorage.getItem('context'),
      ]);
      const body = {
        text: translatedTexts[0],
        context: translatedTexts[1],
        username: localStorage.getItem('username'),
        name: localStorage.getItem('name'),
        sources: selected_sources,
      };

      const { data } = await axios.post(
        'https://apis.crawlq.ai/social-me-athena',
        body,
      );
      console.log('soial', data);
      const resultIntoTargetLanguage = await getTranslatedText(data.answer);

      const { success } = await CreditManage(ATHENA_WRITE_ME_COST);

      if (success) {
        return {
          credits: true,
          error: false,
          data: resultIntoTargetLanguage,
        };
      }
      return { credits: false, error: true, data: null };
    } catch (ex) {
      console.log('Error in Que', ex);
      return {
        credits: true,
        error: true,
        data: null,
      };
    }
  } else {
    return { credits: false, error: true, data: null };
  }
};
export const outlineMeAthenaHelper = async (inputVal, mainTopic) => {
  const { success } = await CreditManage(ATHENA_WRITE_ME_COST, true);
  if (success) {
    try {
      // const main_topic = JSON.parse(localStorage.getItem('main_topic'));

      const translatedTexts = await getTranslatedTextArrayEnglish([
        inputVal.replaceAll('\n', '').replaceAll(/<\/?[^>]+(>|$)/g, ''),
        localStorage.getItem('context'),
      ]);
      const body = {
        selected_text: translatedTexts[0],
        username: localStorage.getItem('username'),
        name: localStorage.getItem('name'),
        main_topic: mainTopic,
      };
      const { data } = await axios.post(
        'https://apis.crawlq.ai/outline-me',
        body,
      );
      console.log('outline_me_athena', data);
      const resultIntoTargetLanguage = await getTranslatedText(data.answer);

      const { success } = await CreditManage(ATHENA_WRITE_ME_COST);

      if (success) {
        return {
          credits: true,
          error: false,
          data: resultIntoTargetLanguage,
        };
      }
      return { credits: false, error: true, data: null };
    } catch (ex) {
      console.log('Error in Que', ex);
      return {
        credits: true,
        error: true,
        data: null,
      };
    }
  } else {
    return { credits: false, error: true, data: null };
  }
};

export const quoteMeAthenaHelper = async (inputVal, mainTopic) => {
  const { success } = await CreditManage(ATHENA_WRITE_ME_COST, true);
  if (success) {
    try {
      // const main_topic = JSON.parse(localStorage.getItem('main_topic'));

      const translatedTexts = await getTranslatedTextArrayEnglish([
        inputVal.replaceAll('\n', '').replaceAll(/<\/?[^>]+(>|$)/g, ''),
        localStorage.getItem('context'),
      ]);
      const body = {
        selected_text: translatedTexts[0],
        username: localStorage.getItem('username'),
        name: localStorage.getItem('name'),
        main_topic: mainTopic,
      };
      const { data } = await axios.post(
        'https://apis.crawlq.ai/quote-me',
        body,
      );
      console.log('quote_me_athena', data);
      const resultIntoTargetLanguage = await getTranslatedText(data.answer);

      const { success } = await CreditManage(ATHENA_WRITE_ME_COST);

      if (success) {
        return {
          credits: true,
          error: false,
          data: resultIntoTargetLanguage,
        };
      }
      return { credits: false, error: true, data: null };
    } catch (ex) {
      console.log('Error in Que', ex);
      return {
        credits: true,
        error: true,
        data: null,
      };
    }
  } else {
    return { credits: false, error: true, data: null };
  }
};

export const statMeAthenaHelper = async (inputVal, mainTopic) => {
  const { success } = await CreditManage(ATHENA_WRITE_ME_COST, true);
  if (success) {
    try {
      // const main_topic = JSON.parse(localStorage.getItem('main_topic'));

      const translatedTexts = await getTranslatedTextArrayEnglish([
        inputVal.replaceAll('\n', '').replaceAll(/<\/?[^>]+(>|$)/g, ''),
        localStorage.getItem('context'),
      ]);
      const body = {
        selected_text: translatedTexts[0],
        username: localStorage.getItem('username'),
        name: localStorage.getItem('name'),
        main_topic: mainTopic,
      };
      const { data } = await axios.post('https://apis.crawlq.ai/stat-me', body);
      console.log('stat_me_athena', data);
      const resultIntoTargetLanguage = await getTranslatedText(data.answer);

      const { success } = await CreditManage(ATHENA_WRITE_ME_COST);

      if (success) {
        return {
          credits: true,
          error: false,
          data: resultIntoTargetLanguage,
        };
      }
      return { credits: false, error: true, data: null };
    } catch (ex) {
      console.log('Error in Que', ex);
      return {
        credits: true,
        error: true,
        data: null,
      };
    }
  } else {
    return { credits: false, error: true, data: null };
  }
};

export const seoMeAthenaHelper = async (inputVal) => {
  const { success } = await CreditManage(ATHENA_WRITE_ME_COST, true);

  if (success) {
    try {
      const selected_sources = JSON.parse(localStorage.getItem('sources'));

      const translatedTexts = await getTranslatedTextArrayEnglish([
        inputVal.replaceAll('\n', '').replaceAll(/<\/?[^>]+(>|$)/g, ''),
        localStorage.getItem('context'),
      ]);
      const body = {
        text: translatedTexts[0],
        context: translatedTexts[1],
        username: localStorage.getItem('username'),
        name: localStorage.getItem('name'),
        sources: selected_sources,
        seo_keywords: ['product', 'hunt', 'big obstacles', 'saas'],
      };

      const instance = await createInstance();

      const { data } = await instance.post(
        'https://apis.crawlq.ai/seo-me-athena',
        body,
      );
      console.log('soial', data);
      const resultIntoTargetLanguage = await getTranslatedText(
        data.output.answer,
      );

      const { success } = await CreditManage(ATHENA_WRITE_ME_COST);

      if (success) {
        return {
          credits: true,
          error: false,
          data: resultIntoTargetLanguage,
        };
      }
      return { credits: false, error: true, data: null };
    } catch (ex) {
      console.log('Error in Que', ex);
      return {
        credits: true,
        error: true,
        data: null,
      };
    }
  } else {
    return { credits: false, error: true, data: null };
  }
};

export const athenaAuthorityBuilder = async (inputVal) => {
  const { success } = await CreditManage(ATHENA_WRITE_ME_COST, true);

  if (success) {
    try {
      const selected_sources = JSON.parse(localStorage.getItem('sources')) ?? [];
      const translatedTexts = await getTranslatedTextArrayEnglish([
        inputVal.replaceAll('\n', '').replaceAll(/<\/?[^>]+(>|$)/g, ''),
        localStorage.getItem('context'),
      ]);
      const body = {
        text: translatedTexts[0],
        context: translatedTexts[1],
        username: localStorage.getItem('username'),
        name: localStorage.getItem('name'),
        sources: selected_sources,
      };

      const instance = await createInstance();

      const { data } = await instance.post(
        'https://apis.crawlq.ai/seo-me-athena',
        body,
      );

      const { success } = await CreditManage(ATHENA_WRITE_ME_COST);

      if (success) {
        return {
          credits: true,
          error: false,
          data: data.output,
        };
      }
      return { credits: false, error: true, data: null };
    } catch (ex) {
      console.log('Error in Que', ex);
      return {
        credits: true,
        error: true,
        data: null,
      };
    }
  } else {
    return { credits: false, error: true, data: null };
  }
};

export const writeMeAthenaHelper = async (inputVal, engine_type) => {
  // engine_type => 0 : Complete , 1 : Instruct

  const { success } = await CreditManage(ATHENA_WRITE_ME_COST, true);

  if (success) {
    try {
      const selected_sources = JSON.parse(localStorage.getItem('sources'));

      const translatedTexts = await getTranslatedTextArrayEnglish([
        inputVal.replaceAll('\n', '').replaceAll(/<\/?[^>]+(>|$)/g, ''),
        localStorage.getItem('context'),
      ]);
      const body = {
        text: translatedTexts[0],
        context: translatedTexts[1],
        username: localStorage.getItem('username'),
        name: localStorage.getItem('name'),
        engine: engine_type,
        sources: selected_sources,
      };

      const { data } = await axios.post(
        'https://apis.crawlq.ai/write-me',
        body,
      );

      const resultIntoTargetLanguage = await getTranslatedText(data.answer);

      const { success } = await CreditManage(ATHENA_WRITE_ME_COST);

      if (success) {
        return {
          credits: true,
          error: false,
          data: resultIntoTargetLanguage,
        };
      }
      return { credits: false, error: true, data: null };
    } catch (ex) {
      console.log('Error in Que', ex);
      return {
        credits: true,
        error: true,
        data: null,
      };
    }
  } else {
    return { credits: false, error: true, data: null };
  }
};

export const GetBlogTemplate = async () => {
  try {
    const instance = await createInstance();

    const response = await instance.post('select-blog', {});

    if (response.data.statusCode === 200) {
      return {
        success: true,
        data: response.data.data,
      };
    }

    return {
      success: false,
      data: null,
    };
  } catch (err) {
    console.log(err);
    return {
      success: false,
      data: null,
    };
  }
};

export const CreateBlogTemplate = async (body) => {
  try {
    const instance = await createInstance();

    const response = await instance.post('generate-blog-template', body);

    if (response.data.statusCode === '200') {
      return {
        success: true,
        data: response.data.blog,
      };
    }
    return {
      success: false,
      data: null,
    };
  } catch (err) {
    console.log(err);
    return {
      success: false,
      data: null,
    };
  }
};

export default getSavedDocs;
