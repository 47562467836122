import { Flex } from 'Component/Shared/GlobalStyles';
import { Colors } from 'libs/colors';
import styled from 'styled-components';

export const ProductInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`;

export const FormHeading = styled.span`
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    text-decoration-color: ${Colors.blue};
    text-decoration-thickness: 0.2rem;
  }
`;

export const FormSubHeading = styled(FormHeading)`
  font-size: 0.9rem;
`;

export const ExpandedContents = styled(Flex)`
  padding: 0.1rem 2rem;
  opacity: 1;
`;

export const CustomGrid = styled.div`
  display: grid;
  grid-template: auto / 210px 210px 210px;
  gap: 1rem 2rem;
  ${({ isMobile }) =>
    isMobile &&
    `
    display: flex;
    flex-direction: column;
    align-items: center;
  `}
`;

export const CheckBoxWraper = styled.div`
  display: flex;
  gap: 0.5rem;
  font-size: 0.8rem;
  align-items: center;
  input {
    width: 20px;
    height: 20px;
  }
  padding: 0.5rem;
  border: solid;
  border-radius: 0.5rem;
  width: 100%;
`;

export const URLItems = styled.div`
  color: #fff;
  padding: 0.4rem;
  height: fit-content;
  background: #2a95df;
  border-radius: 13px;
  flex: 3;
  max-width: 270px;
  height: 45px;
  overflow: hidden;
  gap: 1.5rem;
`;

export const URLsWrapper = styled(Flex)`
  gap: 0.5rem;
  flex-wrap: wrap;
`;
