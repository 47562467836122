import axios from 'axios';

export const getLocationDetails = async () => {
  try {
    const locationData = await axios.get('https://ipapi.co/json/');
    return locationData?.data;
  } catch (e) {
    console.log('Could not get location details', e);
    return {};
  }
};
