import React, { useState } from 'react';
import { MinusIcon, PlusIcon } from 'components/common';
import { Divider } from 'components/common/divider';
import styles from './serp-headers.module.css';

function SerpHeadContent({ serpHeadData, type }) {
  const [ShowContent, setShowContent] = useState(false);

  return (
    <div className={styles.header_content_container}>
      {Object.keys(serpHeadData).map((head, index) => (
        <>
          {index > 0 && <Divider />}
          <div className={styles.header_container}>
            <div className={styles.head_type_container}>
              <span className={styles.type}>{type}</span>
              <span className={styles.head}>{head}</span>
            </div>

            {ShowContent !== index && <PlusIcon onClick={() => setShowContent(index)} />}
            {ShowContent === index && <MinusIcon onClick={() => setShowContent(false)} />}
          </div>

          {index === ShowContent && (
          <div>
            {serpHeadData[head]}
          </div>
          )}
        </>
      ))}

    </div>
  );
}

export default SerpHeadContent;
